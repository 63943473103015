import { readOnlyFileUI } from "./commons";

const form = {
    title: "Revisión de Pre-Desembolso",
    displayName: 'RiskCaseReviewReport',
    page: 'risk-case-review-report',
    commentFieldsMap: {
        "Comentarios Pre-Desembolso": "caseRiskReviewAndReport.comments",
    },
    isSentBack(object) {
        return object.caseRiskReviewAndReport.riskReviewResult === 2;
    } // Devuelto
};


form.schema = {
    type: "object",
    properties: {
        "validacionPreDesembolso": {
            title: "Validación Pre Desembolso",
            type: "object",
            properties: {
                "hojaAprobacionCredito": {
                    title: "Hoja de Aprobación de Crédito",
                    type: "number",
                },
                "checklistAdmCartera": {
                    title: "Checklist Adm. de Cartera",
                    type: "number",
                },
                "contratoFirmasInternas": {
                    title: "Contrato con firmas internas",
                    type: "number",
                },
                "inscripcionGarantiaSolicitada": {
                    title: "Inscripción de Garantia solicitada",
                    type: "boolean",
                },
                "resultadoRevisionRiesgo": {
                    title: "Resultado Revisión Riesgo",
                    type: "number",
                    enumNames: [
                        "Validado para Inscripción de Garantía",
                        "Validado para Desembolso",
                        "Desembolso con excepción",
                        "Devuelto"
                    ],
                    enum: [1, 3, 4, 2],
                },
                "comentarios": {
                    title: "Comentarios Internos o Devolución",
                    type: "string",
                },
            },
            required: ["resultadoRevisionRiesgo"],
            dependencies: {
                "resultadoRevisionRiesgo": {
                    allOf: [
                        {
                            if: {
                                properties: {
                                    "resultadoRevisionRiesgo": {
                                        const: 4,
                                    },
                                }
                            },
                            then: {
                                properties: {
                                    "soporteExcepcion": {
                                        title: "Soporte de Excepción",
                                        type: "number"
                                    },
                                },
                                required: ["soporteExcepcion"]
                            }
                        },
                        {
                            if: {
                                properties: {
                                    "resultadoRevisionRiesgo": {
                                        const: 2,
                                    },
                                }
                            },
                            then: {
                                properties: {
                                    "razonDevolucion": {
                                        title: "Razon de Devolución",
                                        type: "string",
                                        enum: [
                                            "Documento cargado no cumple con requerimientos",
                                            "Documento o información faltante",
                                            "Error en digitación de información",
                                            "Información incorrecta",
                                            "Otros",
                                        ],
                                    },
                                },
                                required: ["razonDevolucion", "comentarios"]
                            }
                        }
                    ]
                },
            }
        },
    },
    required: [],
    dependencies: {}
};



form.uiSchema = {
    "informacionPreviaSolicitudPrestamo": {

    },
    "validacionPreDesembolso": {
        "hojaAprobacionCredito": { ...readOnlyFileUI },
        "checklistAdmCartera": { "ui:field": "FileField" },
        "contratoFirmasInternas": { "ui:field": "FileField" },
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "soporteExcepcion": { "ui:field": "FileField" },
    }
}

form.objectMap = {
    "generatedFiles.creditapproval_0_Aprobacion1PrestamoPersonal": "validacionPreDesembolso.hojaAprobacionCredito",
    "caseRiskReviewAndReport.admWalletFileId": "validacionPreDesembolso.checklistAdmCartera",
    "caseRiskReviewAndReport.internalSigningContractFileId": "validacionPreDesembolso.contratoFirmasInternas",
    "caseRiskReviewAndReport.riskReviewResult": "validacionPreDesembolso.resultadoRevisionRiesgo",
    "caseRiskReviewAndReport.comments": "validacionPreDesembolso.comentarios",
    "caseRiskReviewAndReport.exceptionFileId": "validacionPreDesembolso.soporteExcepcion",
    "caseRiskReviewAndReport.caseReturnReason": "validacionPreDesembolso.razonDevolucion",
    "caseRiskReviewAndReport.warrantryInscription": "validacionPreDesembolso.inscripcionGarantiaSolicitada"
}


export default form;