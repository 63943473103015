import React from "react";
import { useHistory, useParams } from "react-router";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import SLASetting from "./SLASetting";
import StaticFiles from "./StaticFiles";
import AlarmSetting from "./AlarmSetting";


const TABS = {
    'static-files': {title: 'Archivos Estáticos', component: StaticFiles},
    'sla': {title: 'SLA', component: SLASetting},
    'alarms': {title: 'Alarmas', component: AlarmSetting},
}

function SystemSettings(){
    const history = useHistory();
    const { setting='static-files' } = useParams();

    return <div>
        <Nav tabs>{Object.entries(TABS).map(([key, {title}]) => (
            <NavItem key={key} onClick={() => history.push(`/system-settings/${key}`)}>
                <NavLink active={setting === key}>{title}</NavLink>
            </NavItem>
        ))}</Nav>
        <TabContent activeTab={setting}>{Object.entries(TABS).map(([key, {component: Component}]) => (
            <TabPane key={key} tabId={key}>{
                setting === key ?  <Component /> : null
            }</TabPane>
        ))}</TabContent>
    </div>
}


export default SystemSettings;