import React, {useMemo} from 'react';


const RequestReloadContext = React.createContext();

RequestReloadContext.useHook = function(onReload, dependencies){
    return useMemo(() => ({
        reload: onReload
    }), [...dependencies]);
}


export default RequestReloadContext;