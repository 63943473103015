const form = {
    title: "Aprobación Desembolso",
    displayName: 'OperationsCaseReview',
    page: 'operations-case-review',
    commentFieldsMap: {
        "Desembolso": "caseOperationsReview.comments",
    },
    isSentBack(object) {
        return object?.caseOperationsReview?.operationsReviewResult === 2;
    } // Devuelto
};


form.schema = {
    title: "Aprobación de Desembolso de Préstamo",
    type: "object",
    properties: {
        "formularioTramitacionFirmado": {
            title: "Cargar Formulario de Tramitación Firmado",
            type: "number",
        },
        "resultadoRevisionOperaciones": {
            title: "Resultado Revisión Operaciones",
            type: "number",
            enumNames: ["Aprobado en T24", "Devuelto"],
            enum: [1, 2]
        },
        "comentarios": {
            title: "Comentarios",
            type: "string",
        },
    },
    required: []
};



form.uiSchema = {
    "formularioTramitacionFirmado": { "ui:field": "FileField" },
    "comentarios": {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 4
        },
    },
}

form.objectMap = {
    // Fields not implemented:
    //      
    //      
    //  
    //  
    //  Fields with wrong type in db:
    //      
    //  
    "caseOperationsReview.signedTransactionFileId": "formularioTramitacionFirmado",
    "caseOperationsReview.operationsReviewResult": "resultadoRevisionOperaciones",
    "caseOperationsReview.comments": "comentarios"
}


export default form;