import {useMemo, useEffect} from "react";


function useUnmountDetection(){
    const flags = useMemo(() => ({}), []);
    useEffect(() => () => {flags.unmounted = true;}, [])
    return flags;
}


export default useUnmountDetection;