import React, { useState, useCallback, useEffect } from 'react';
import { FormGroup } from "reactstrap";
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";

export default function CedulaInput({
    id,
    schema,
    uiSchema,
    value,
    placeholder,
    required,
    disabled,
    readonly,
    autofocus,
    onChange: onChangeForm,
    onKeyChange,
    onBlur,
    onFocus,
}) {
    const [current, setCurrent] = useState(value);
    useDebouncedEffect(() => {
        if (current !== value) {
            onChangeForm(current)
        }
    }, [current], 450);
    useEffect(() => setCurrent(value), [value]);

    return (
        <FormGroup>
            <InputMask
                mask='999-9999999-9'
                disabled={disabled}
                className="form-control"
                value={current}
                required={required}
                onChange={(e) => setCurrent(e.target.value)}
                readOnly={readonly}
                autoFocus={autofocus}
                id={id}
            />
        </FormGroup>
    );
}