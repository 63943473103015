import React, { useState, useRef } from "react";
import "./FileInput.scss";
import {
    InputGroup,
    InputGroupText,
    Button
} from "reactstrap";
import useUID from "../util/useUID";


const ICONS = {
    pdf: "fa fa-file-pdf",
    doc: "fa fa-file-word",
    txt: "fa fa-file-lines",
    png: "fa fa-file-image",
    jpg: "fa fa-file-image",
    jpeg: "fa fa-file-image",
    default: "fa fa-file",
}


function FileInput({
    file,
    disabled,
    viewable = true,
    viewButtonText,
    uploadButtonText,
    onFileSelected,
    resource = "File",
    accept = "*",
    readonly = false,
    required = false,
}) {
    const ref = useRef();
    const fieldId = useUID('fileinput-');

    const [clearCt, setClearCt] = useState(0);
    const [dragTarget, setDragTarget] = useState();
    // console.log("file", file)

    async function onChange({ target: { files: [selectedFile] } }) {
        if (disabled) return;
        if (selectedFile) {
            await onFileSelected(selectedFile);
            setClearCt(clearCt + 1);
        }
    }

    function onKeyPress(e) {
        if (disabled) return;
        if (e.which === 32 || e.which === 13) {
            e.stopPropagation();
            e.preventDefault();
            if (ref.current) ref.current.click();
        }
    }

    function onDrop(event) {
        if (disabled) return;
        setDragTarget(false);
        event.preventDefault();
        const { dataTransfer: { files: dtFiles, items: dtItems } } = event;
        const file = (dtItems ? Array.prototype.filter.call(dtItems, i => i.kind === 'file').map(i => i.getAsFile()) : dtFiles)[0];
        if (file) {
            onChange({ target: { files: [file] } });
        }
    }

    function onDragOver(event) {
        if (disabled) return;
        event.preventDefault();
        const { dataTransfer: { files: dtFiles, items: dtItems } } = event;
        const file = (dtItems ? Array.prototype.filter.call(dtItems, i => i.kind === 'file') : dtFiles)[0];
        setDragTarget(!!file);
    }

    function onDragLeave() {
        if (disabled) return;
        setDragTarget(false);
    }

    const extension = ((file && file.name) || '').split('.').pop();

    return (
        <InputGroup
            className={`file-input ${dragTarget ? 'border-success' : ''}`}
            onDrop={!readonly ? onDrop : null}
            onDragOver={!readonly ? onDragOver : null}
            onDragLeave={!readonly ? onDragLeave : null}
        >
            {file && file.name ? (
                <InputGroupText title={file.name}><i className={ICONS[extension] || ICONS.default} />{' '}{file.name}</InputGroupText>
            ) : null}
            {viewable && file && file.url ? (
                <Button tag="a" href={file.url} target="_blank">{viewButtonText || (<i className="fa fa-search" />)}</Button>
            ) : null}
            {!readonly ? (
                dragTarget ? (
                    <Button color="success" className="file-input-label">Drop File</Button>
                ) : (
                    <Button disabled={disabled} className="file-input-label" tag="label" htmlFor={fieldId} tabIndex="0" onKeyPress={onKeyPress}>
                        <input key={clearCt}
                            id={fieldId}
                            ref={ref}
                            type="file"
                            accept={accept} required={required && !(file && file.url)}
                            onChange={onChange}
                        />
                        {uploadButtonText || (<i className="fa fa-upload" />)}
                    </Button>
                )
            ) : null}
        </InputGroup>
    );
}


export default FileInput;