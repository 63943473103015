import fecthApi from '../util/fetchApi';
import checkErrors from './checkErrors';


export const AuthApi = {
    async login({ email, password }) {
        return (await checkErrors(await fecthApi.api.post('security/login', {
            data: {
                email: email || "",
                password: password || ""
            }
        }))).data;
    },

    async verifyToken() {
        return checkErrors(await fecthApi.api.post('security/check'));
    },

    async recoverPassword({ email }) {
        return checkErrors(await fecthApi.api.post(`security/password-recovery/${email}`));
    },

    async resetPassword({ token, password, passwordConfirm }) {
        return checkErrors(await fecthApi.api.post(`security/password-reset/${token}`, {
            data: {
                password,
                passwordConfirm
            }
        }));
    },

    async changeSelfPassword({ password, passwordConfirm }) {
        return (await checkErrors(await fecthApi.api.post(`security/password/self`, {
            data: {
                password,
                passwordConfirm
            }
        }))).data;
    },

    async getSelf() {
        return (await checkErrors(await fecthApi.api.get(`security/self`)))?.data;
    },

    async updateSelf(data) {
        return checkErrors(await fecthApi.api.post(`security/self`, { data }));
    },

}


export default AuthApi;
