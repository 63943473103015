import { Trans } from '@lingui/react';
import _ from "lodash";
import React, { useContext, useState } from 'react';
import {
    Table,
    Button,
    Col,
    Row,
} from 'reactstrap';

import {AuthContext} from '../../context/AuthContext';
import useResourceLoader from '../../util/useResourceLoader';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import useTitle from '../../util/useTitle';
import UserApi from "../../api/UserApi";
import SortButton from "../../components/SortButton";
import useSearchParams from '../../util/useSearchParams';
import Pagination from '../../components/Pagination';
import SearchBar from '../../components/Search/SearchBar';
import EditUser from './EditUser';
import UserSearchBar from "../../components/Search/UserSearchBar";


const searchFormDefinition = (({schema, uiSchema}) => ({
    schema: {
        type: "object",
        properties: {
            firstName: { title: "Nombre", type: "string" },
            lastName: { title: "Apellido", type: "string" },
            email: { title: "Email", type: "string" },
            roleId: {
                title: "Rol",
                type: "number",
                lookup: { api: 'roles', resource: "Roles", id: 'id', label: 'displayName'}
            },
            branchId: {
                title: "Oficina",
                type: "number",
                lookup: { api: 'branches', resource: "Oficinas", id: 'id', label: 'name' }
            },
            isActive: {
                title: "Activo",
                type: "number",
                enum: [1, 0],
                enumNames: ["Si", "No"]
            }            
        },
    },
    uiSchema: {
        "classNames": "smallThreeRows",
        "branchId": { "ui:field": "LookupFormField" },
        "roleId": { "ui:field": "LookupFormField" },
    },
}))(EditUser.formDefinition);


export function UserTable({ history }) {
    useTitle("JOLI :: Users");

    const { user:{permissions:{manageUser} = {}} = {} } = useContext(AuthContext) || {};
    const [showFilter, setShowFilter] = useState();

    const [
        [offset, setOffset],
        [size, setSize],
        [sortBy, setSortBy],
    ] = useSearchParams({
        offset: 0,
        size: 10,
        sortBy: 'id'
    });

    const [filters, setFilters] = useState({
        firstName: undefined,
        lastName:undefined,
        roleId: undefined,
        branch: undefined,
        active: 1
    });

    const [paging, loadingUsers, error] = useResourceLoader(async () => {
        const users = await UserApi.searchUsers({ offset, size, sortBy, ...filters });
        return Array.isArray(users) ? {
          from: 0,
          to: users.length,
          count: users.length,
          items: users
        } : users;
      }, [size, offset, sortBy, filters]);
    
    const {
        from = 0,
        to = 0,
        count = 0,
        items: users = [],
    } = paging || {};

    function onSearch(update) {
        setFilters({...filters, ...update});
    }

    function onClearSearch(){ setFilters({}); }


    function select(user){
        if (user) history.push(`/users/${user.id}`);
    }

    return (<>
        {loadingUsers ? <Loader fullscreen /> : null}
        {error ? <Notification color="danger">{JSON.stringify(error)}</Notification> : null}
        <div>
            <div className="d-flex flex-row justify-content-between align-items-center mb-4">
                <h1>Usuarios</h1>
            </div>
            <Row>
                <Col xs="11" sm="6" className="order-sm-2">
                    {manageUser ? (
                        <Button color="primary" onClick={() => history.push("/users/create")} >Registrar Usuario</Button>
                    ) : null}
                </Col>
                <Col xs="1" className="order-sm-3">
                    <Button onClick={() => setShowFilter(!showFilter)} type="button" outline color="secondary" active={showFilter}>
                        <i className="glyphicon glyphicon-filter" />
                    </Button>
                </Col>
                <Col sm="5" className="order-sm-1">
                    <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
                </Col>
            </Row>
            {showFilter && <UserSearchBar resource="Usuarios" formDefinition={searchFormDefinition} filters={filters} onSearch={onSearch} onClearSearch={onClearSearch} />}
            <Table>
                <thead><tr>
                    <SortButton tag="th" sortKey="id" sortBy={sortBy} setSortBy={setSortBy}>Id</SortButton>
                    <SortButton tag="th" sortKey="name" sortBy={sortBy} setSortBy={setSortBy}>Nombre</SortButton>
                    <SortButton tag="th" sortKey="branch" sortBy={sortBy} setSortBy={setSortBy}>Oficinas</SortButton>
                    <SortButton tag="th" sortKey="role" sortBy={sortBy} setSortBy={setSortBy}>Rol</SortButton>
                    <SortButton tag="th" sortKey="active" sortBy={sortBy} setSortBy={setSortBy}>Activo</SortButton>
                    <td />
                </tr></thead>
                <tbody>{
                    (users || []).map((user, idx) => (
                        <UserEntry key={idx} user={user} onSelect={select} />
                    ))
                }</tbody>
            </Table>
        </div>
    </>);
}


function UserEntry({user, onSelect}){
    const { id, firstName, lastName, email, role, branch, isActive } = user;
    return (<tr className="clickable">
        <td>{id}</td>
        <td>{firstName} {lastName}<div className="small">{email}</div></td>
        <td>{branch.name}</td>
        <td>{role.displayName}</td>
        <td>{isActive ? 'Sí' : 'No'}</td>
        <td>
            <Button color="primary" onClick={() => onSelect(user)}>Ver</Button>
        </td>
    </tr>)
}


export default UserTable;