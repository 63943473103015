const form = {
    title: "Creación & Activación Cliente PB",
    displayName: 'InvestmentClientCreationConfirmationPuesto',
    page: 'investment-client-creation-confirmation-puesto',
};


form.schema = {
    title: "Activación de cliente en Puesto",
    type: "object",
    properties: {
        // "enviarCorreoCliente": {
        //     "buttonText": "Enviar Correo a Cliente",
        //     subject: "¡Te damos la bienvenida a JMMB!",
        //     body: "¡TE DAMOS LA BIENVENIDA A LA FAMILIA JMMB! \n\n\n\n Estamos contentos porque contigo nuestro Grupo Puesto de Bolsa sigue creciendo. \n\nNuestra cultura está basada en el amor incondicional teniendo tu mejor interés en nuestros corazones. Somos tu socio financiero brindándote de soluciones integrales e innovadoras que junto a nuestra asesoría personalizada te empoderarán para lograr tus sueños. \n\n\n Le pedimos que se tome unos minutos y llene la siguiente encuesta para evaluar su experiencia de vinculación con el grupo JMMB.",
        //     type: "null",
        // },
        "infCliDIMSA": {
            title: " ",
            description: "Informaciones Cliente DIMSA",
            type: "object",
            properties: {
                "cliDIMSA": {
                    title: "Cliente creado en DIMSA",
                    type: "boolean",
                },
                "numCliDIMSA":
                {
                    title: "Número de cliente en DIMSA",
                    type: "string"
                },
                "numRNT":
                {
                    title: "Número RNT",
                    type: "string"
                },
                "titleDIMSA": {
                    title: " ",
                    description: "Activación del Cliente en DIMSA",
                    type: "null"
                },
                "pantConfDIMSA": {
                    title: "Pantalla Confirmación DIMSA",
                    type: "number"
                },
                "cliActivadoDIMSA": {
                    title: "Cliente Activado en DIMSA",
                    type: "boolean",
                },
            },
            required: ["numCliDIMSA"],
            dependencies: {
            }
        },
    }
}

form.uiSchema = {
    // "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "infCliDIMSA": {
        "pantConfDIMSA": {
            "ui:field": "FileField"
        },
    },
}
form.objectMap = {
    "clients[$idx].retailClientInvestorProfile.clientCreatedDIMSA": "infCliDIMSA.cliDIMSA",
    "clients[$idx].retailClientInvestorProfile.dimsaClientId": "infCliDIMSA.numCliDIMSA",
    "clients[$idx].retailClientInvestorProfile.clientActivatedDIMSA": "infCliDIMSA.cliActivadoDIMSA",
    "clients[$idx].retailClientInvestorProfile.rntNumber": "infCliDIMSA.numRNT",
    "clients[$idx].retailClientInvestorProfile.confDIMSAFileId": "infCliDIMSA.pantConfDIMSA",
}


export default form;