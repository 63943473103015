import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const StaticFileApi = {
    async list() {
        return checkErrors(
            await fetchApi.api.get(`static-file`)
        )
    },

    async uploadFile({ file, key }) {
        return checkErrors(
            await fetchApi.api.post(`static-file`, {formdata: {file, key}})
        )
    },

    async getFile({ key }) {
        return checkErrors(
            await fetchApi.api.get(`static-file/${key}`)
        )
    },
}


export default StaticFileApi;
