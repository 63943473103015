function withSignature(signature, fn){
    fn.signature = signature;
    return fn;
}

const CHARSETS = {
    'w': 'abcdefghijklmnopqrstuvwxyz',
    'W': 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    '0': '0123456789',
    '!': '!@#$%^&*(),<.>;:[{]}\\/?|',
};

const jsonataFunctions = {
    charSet: withSignature(
        '<s:a<s>>',
        (cstype) => cstype.split('').reduce((_, cstype) => {
            const CS = CHARSETS[cstype];
            if (CS && !_.sets[cstype]) _.list.push(...CS);
            return _;
        }, {list: [], sets: {}}).list
    ),
    fromCharCode: withSignature(
        '<n:s>',
        String.fromCharCode
    ),
    fromCodePoint: withSignature(
        '<n:s>',
        String.fromCodePoint
    ),
    charAt: withSignature(
        '<sn:s>',
        (str, index) => str.charAt(index)
    ),
    charCodeAt: withSignature(
        '<sn:n>',
        (str, index) => str.charCodeAt(index)
    ),
    codePointAt: withSignature(
        '<sn:n>',
        (str, index) => str.codePointAt(index)
    ),
    range: withSignature(
        '<nn?n?:a<n>>',
        (from, to=undefined, step=1) => {
            if (to === undefined) {
                to = from;
                from = 0;
            }

            const arr = [];
            for(let i=from; i < to; i += step) arr.push(i);

            return arr;
        }
    ),
    copy: data => {
        navigator.clipboard.writeText(`${data}`);
    },
    log: (...args) => { console.log(...args); },
    alert: (text, title) => { alert(title ? `${title}\n${text}` : text); return text; },
    isJson: text => {
        try {
            JSON.parse(text);
            return true;
        } catch(e) {
            return false;
        }
    },
    isTruthy: value => !!value,
    isFalsy: value => !value,
    isEmpty: value => {
        switch (typeof value) {
            case 'string': return value.trim() === '';
            case 'object': return !value;
            case 'undefined': return true;
            default: return false;
        }
    },
    toJson: object => JSON.stringify(object),
    fromJson: text => JSON.parse(text),
    throw: message => { throw new Error(message);},
    try: (action, onError, onSuccess) => {
        let p = Promise.resolve().then(action);
        if (onSuccess) p = p.then(onSuccess);
        return p.catch(onError);
    },
    random: withSignature('<:n>', Math.random),
    sample: withSignature(
        '<a:x>',
        (arr) => {
            const ridx = ((Math.random() * arr.length) | 0) % arr.length;
            return arr[ridx];
        }
    )
};


export default jsonataFunctions;