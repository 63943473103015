import {
    clientTypeId,
    preferredBranchId,
    boolYesNoType,
    tipoIdentificacionPersonal
} from './commons';


const form = {
    title: "Aprobación de Crédito",
    displayName: 'CreditAnalysisDecision',
    page: 'credit-analysis-decision',
    commentFieldsMap: {
        "Aprobacion Credito": "caseLoanInfo.creditAnalysisDecisionComments",
    },
    isSentBack(object) {
        return object.caseLoanInfo.creditAnalysisResult === "Devuelto";
    }
};

const condicionesPropuestasRetail = {
    type: "array",
    title: "Condiciones propuestas según análisis de crédito",
    minItems: 1,
    items: {
        type: "object",
        properties: {
            "numFacilidad": {
                title: "No. de Facilidad",
                type: "string",
            },
            "descripcion": {
                title: "Descripción",
                type: "string"
            },
            "tipoFacilidad": {
                title: "Tipo de Facilidad",
                type: "string",
            },
            "montoAprobado": {
                title: "Monto Aprobado (si aplica)",
                type: "string",
            },
            "montoUtilizado": {
                title: "Monto Utilizado (si aplica)",
                type: "string",
            },
            "montoPropuesto": {
                title: "Monto Propuesto",
                type: "string",
            },
            "tasa": {
                title: "Tasa",
                type: "number",
            },
            "formaPago": {
                title: "Forma de Pago",
                type: "string",
            },
            "cuotaEstimada": {
                title: "Cuota Estimada",
                type: "string",
            },
            "seraCancelado": {
                title: "¿Será Cancelado?",
                ...boolYesNoType
            }
        }
    }
}

const condicionesPropuestasBusiness = {
    type: "array",
    title: "Condiciones propuestas según análisis de crédito",
    minItems: 1,
    items: {
        type: "object",
        properties: {
            "numFacilidad": {
                title: "No. de Facilidad",
                type: "string",
            },
            "descripcion": {
                title: "Descripción",
                type: "string",
            },
            "tipoFacilidad": {
                title: "Tipo de Facilidad",
                type: "string",
                enumNames: [
                    "Consumo",
                    "Comercial- Micro",
                    "Descuento nómina",
                    "Hipotecario",
                ],
                enum: [
                    "Consumo",
                    "Comercial- Micro",
                    "Descuento nomina",
                    "Hipotecario",
                ],
            },
            "montoAprobado": {
                title: "Monto Aprobado (si aplica)",
                type: "string",
            },
            "montoUtilizado": {
                title: "Monto Utilizado (si aplica)",
                type: "string",
            },
            "montoPropuesto": {
                title: "Monto Propuesto",
                type: "string",
            },
            "tasa": {
                title: "Tasa",
                type: "number",
            },
            "formaPago": {
                title: "Forma de Pago",
                type: "string",
                enumNames: [
                    "Ingresos por negocio",
                    "Ingresos por salario",
                    "Intereses generados",
                    "Otro",
                ],
                enum: [
                    "Ingresos por negocio",
                    "Ingresos por salario",
                    "Intereses generados",
                    "Otro",
                ],
            },
            "cuotaEstimada": {
                title: "Cuota Estimada",
                type: "string",
            },
            "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
        },
    },
}


const comentariosSchema = {
    title: " ",
    type: "object",
    properties: {
        "comentarios": {
            title: "Comentarios",
            type: "string"
        }
    }
}

form.schema = {
    title: "Aprobación de Crédito",
    type: "object",
    properties: {
        "clientTypeId": {
            type: "number"
        },
        "enviarCorreoCliente": {
            "buttonText": "Enviar Correo a Cliente",
            subject: "¡Préstamo Aprobado!",
            body: "Buenas tardes Sr/Sra. {nombreCliente} \n\nSu préstamo ha sido aprobado por la unidad de Análisis de Crédito. Le estaremos contactando para la firma de la documentación requerida.",
            type: "null",
        },
        "informeAnalisis": {
            title: "Informe de Análisis",
            type: "number"
        },
        "aprobacionComiteTitulo": {
            title: "Aprobación del Comité de Crédito o Ejecutivo Asignado",
            type: "object",
            properties: {
                "tieneGarantia": {
                    type: "boolean",
                },
                "montoAprobado": {
                    title: "Monto Aprobado:",
                    type: "string"
                },
                "tasaAprobada": {
                    title: "Tasa Aprobada:",
                    type: "number"
                },
                "plazoMeses": {
                    title: "Plazo (Meses):",
                    type: "string"
                },
                "cuota": {
                    title: "Cuota:",
                    type: "string"
                },
                "otrasObs": {
                    title: "Otras Observaciones",
                    type: "string"
                },
            },
            dependencies: {
                "tieneGarantia": {
                    "oneOf": [
                        {
                            "properties": {
                                "tieneGarantia": { const: true },
                                "garantia": {
                                    title: "Valor de la Garantía:",
                                    type: "string"
                                },
                            }
                        },
                        {
                            "properties": {
                                "tieneGarantia": { const: false },
                            }
                        }
                    ]
                }
            }
        },
        "actaComite": {
            title: "Acta de Comité firmada",
            type: "number"
        },

        "resultadoAnalisisCredito": {
            title: "Resultado Análisis de Crédito",
            type: "string",
            enum: [
                "Aprobado",
                "Devuelto",
                "Declinado",
                "Pospuesto"
            ]
        }
    },
    dependencies: {
        "resultadoAnalisisCredito": {
            oneOf: [
                {
                    properties: {
                        "resultadoAnalisisCredito": {
                            const: "Devuelto",
                        },
                        "comentariosSchema": comentariosSchema
                    }
                },
                {
                    properties: {
                        "resultadoAnalisisCredito": {
                            const: "Declinado",
                        },
                        "comentariosSchema": comentariosSchema
                    },
                },
                {
                    properties: {
                        "resultadoAnalisisCredito": {
                            const: "Pospuesto",
                        },
                        "comentariosSchema": comentariosSchema
                    },
                },
                {
                    properties: {
                        "resultadoAnalisisCredito": {
                            const: "Aprobado",
                        },
                    },
                }
            ]

        },
        "clientTypeId": {
            oneOf: [
                {
                    properties: {
                        "clientTypeId": {
                            const: 1
                        },
                        "condicionesPropuestas": condicionesPropuestasRetail
                    },
                }, {
                    properties: {
                        "clientTypeId": {
                            const: 2
                        },
                        "condicionesPropuestasBusiness": condicionesPropuestasBusiness
                    },
                }, {
                    properties: {
                        "clientTypeId": {
                            const: 3
                        },
                        "condicionesPropuestasBusiness": condicionesPropuestasBusiness
                    },
                }, {
                    properties: {
                        "clientTypeId": {
                            const: 4
                        },
                        "condicionesPropuestas": condicionesPropuestasRetail
                    }
                }
            ]
        }
    },
    required: ["resultadoAnalisisCredito"]
}


form.uiSchema = {
    "clientTypeId": { "classNames": "d-none" },
    "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "informeAnalisis": { "ui:field": "FileField" },
    "actaComite": { "ui:field": "FileField" },
    "comentariosSchema": {
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        }
    },
    "otrasObs": {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 4
        },
    },
    "aprobacionComiteTitulo": {
        "tieneGarantia": { "classNames": "d-none" },
    },
    "condicionesPropuestas": {
        "ui:options": {
            "orderable": false,
        }
    },
    "ui:order": [
        "enviarCorreoCliente",
        "informeAnalisis",
        "actaComite",
        "condicionesPropuestas",
        "condicionesPropuestasBusiness",
        "*"
    ]
}

form.objectMap = {
    "clients[0].clientTypeId": "clientTypeId",

    "caseLoanInfo.creditAnalysisDecisionComments": "comentariosSchema.comentarios",
    "caseLoanInfo.analysisReportFileId": "informeAnalisis",
    "caseLoanInfo.comitteLetterFileId": "actaComite",
    "caseLoanInfo.creditAnalysisResult": "resultadoAnalisisCredito",
    "caseLoanInfo.includesCollateral": "aprobacionComiteTitulo.tieneGarantia",

    "clients[0].retailClientLoanInfo.committeeApproval.approvedAmount": "aprobacionComiteTitulo.montoAprobado",
    "clients[0].retailClientLoanInfo.committeeApproval.approvedRate": "aprobacionComiteTitulo.tasaAprobada",
    "clients[0].retailClientLoanInfo.committeeApproval.monthlyTerms": "aprobacionComiteTitulo.plazoMeses",
    "clients[0].retailClientLoanInfo.committeeApproval.share": "aprobacionComiteTitulo.cuota",
    "clients[0].retailClientLoanInfo.committeeApproval.warranty": "aprobacionComiteTitulo.garantia",
    "clients[0].retailClientLoanInfo.committeeApproval.otherObservations": "aprobacionComiteTitulo.otrasObs",

    "clients[0].retailClientLoanInfo.loanFacilities": {
        array: "condicionesPropuestas",
        map: {
            "number": "numFacilidad",
            "description": "descripcion",
            "facilityType": "tipoFacilidad",
            "amountApproved": "montoAprobado",
            "amountUsed": "montoUtilizado",
            "amountBudgeted": "montoPropuesto",
            "rate": "tasa",
            "payment": "formaPago",
            "estimatedQuote": "cuotaEstimada",
            "willBeCancelled": "seraCancelado"
        }
    },

    "clients[0].businessClientLoanInfo.businessLoanFacilities": {
        array: "condicionesPropuestasBusiness",
        map: {
            "number": "numFacilidad",
            "description": "descripcion",
            "facilityType": "tipoFacilidad",
            "amountApproved": "montoAprobado",
            "amountUsed": "montoUtilizado",
            "amountBudgeted": "montoPropuesto",
            "rate": "tasa",
            "payment": "formaPago",
            "estimatedQuote": "cuotaEstimada",
            "willBeCancelled": "seraCancelado"
        }
    },
}


export default form;