import React from 'react';
import './style.scss';


const Loader = ({big}) => {
    return (
        <div className={`case-loader ${big ? 'big' : ''}`}>
            <div className="spinner">
                <div className="bubble bubble-1" />
                <div className="bubble bubble-2" />
                <div className="bubble bubble-3" />
            </div>
        </div>
    )
}

export default Loader;