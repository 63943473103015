import rjsfValidateFormData from "@rjsf/core/lib/validate";

import deduplicate from './deduplicate';
import { getObject, invertMap, mapObject, setObject } from './mapObject';
import { makeJnxValidator, appendError, ArrayConsciousObjectPath, ERRORS_KEY } from "./useJnxFormValidation";
import Jnx from "./jnx";
import dfsSearch from "./dfsSearch";



function formValidation(formObject, schema, uiSchema, objectMap, customFormats, context) {
    const formData = mapObject(formObject, invertMap(objectMap), undefined, context);
    const errors = rjsfValidateFormData(formData, schema, undefined, undefined, undefined, customFormats).errorSchema;
    const jnxValidator = makeJnxValidator(schema, uiSchema, null);
    const formFields = computedVisibleFields(schema, uiSchema, formData);
    if (jnxValidator) {
        cleanErrors(jnxValidator(formData, errors, null, formFields));
    }
    return cleanErrors(errors);
}

function computedVisibleFields(schema,uiSchema, formData) {
    const fields = {};
    const bindings = {};

    dfsSearch([[
        '', schema, uiSchema, formData
    ]], ([currentPath, currentSchema, currentUISchema, currentData]) => {

        if(!currentSchema) return;
        
        const { type, properties } = currentSchema;

        const { 'ui:showIf': showIf } = currentUISchema || {};

        const isVisible = showIf ? new Jnx(showIf).eval(formData, currentPath, bindings) : true;


        if (!isVisible) return;

        fields[currentPath] = true;

        const currentPathPrefix = currentPath ? `${currentPath}.` : '';

        switch (type) {
            case "object": {
                return Object.entries(properties).map(([propName, propSchema]) => [
                    `${currentPathPrefix}${propName}`,
                    propSchema,
                    currentUISchema?.[propName],
                    currentData?.[propName]
                ]);
            }
            case "array": {
                return (Array.isArray(currentData) ? currentData : []).map((item, idx) => [
                    `${currentPathPrefix}${idx}`,
                    currentSchema.items,
                    currentUISchema.items,
                    item
                ]);
            }
            default: break;
        }
    });

    return fields;
}

function cleanErrors(errorSchema) {
    if (!errorSchema) return [];

    const cleaned = {};
    const stack = [["", cleaned, errorSchema]];
    while (stack.length) {
        const [prefix, dst, current] = stack.shift();
        Object.entries(current).forEach(([key, value]) => {
            if (key === ERRORS_KEY) {
                value = value.filter(x => x !== 'should be equal to constant');
                if (prefix && value.length) {
                    dst[key] = deduplicate(value);
                }
            } else {
                dst[key] = {};
                stack.push([key, dst[key], value]);
            }
        });
    }

    return cleaned;
}



export function errorSchemaToFlatList(errorSchema) {
    const list = []
    const stack = [['', errorSchema]];
    while (stack.length) {
        const [path, current] = stack.shift();
        const prefix = path === '' ? '' : `${path}.`;
        if (current && typeof current === 'object' && !Array.isArray(current)) {
            Object.entries(current).forEach(([k, v]) => {
                if (k === ERRORS_KEY) {
                    v.forEach(e => list.push([path, e]));
                } else {
                    stack.unshift([`${prefix}${k}`, v]);
                }
            });
        }
    }
    return list;
}

export function mergeFlatListToErrorSchema(flatErrorList, errorSchema) {
    errorSchema = errorSchema || {};
    flatErrorList.forEach(([path, error]) => appendError(errorSchema, path, error));
    return errorSchema;
}


export default formValidation;