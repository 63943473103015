import { Trans } from '@lingui/react';
import React, { useContext, useMemo, useState } from 'react';
import {
    Table,
    Button,
} from 'reactstrap';
import { useParams } from "react-router-dom";

import useResourceLoader from '../../util/useResourceLoader';
import CasesApi from '../../api/CasesApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import "./CaseAssignments.scss"
import ReassignModal from '../../blc/ReassignModal';
import { AuthContext } from '../../context/AuthContext';
import { toDrDate, toDrDateTime } from "../../util/dates";


export function CaseAssignments({
    readonly
}) {
    const { id } = useParams();
    const [updateCt, setUpdateCt] = useState(0);
    const [allSlots, setAllSlots] = useState();
    const auth = useContext(AuthContext);
    const [currentCase, loadingCC, errorLCC] = useResourceLoader(() => CasesApi.getCaseAssignments({ caseId: id }), [updateCt], null);
    const [roleAssignments, loadingRA, errorLRA] = useResourceLoader(() => CasesApi.getRoleAssignments({ caseId: id }), [], null);

    const loading = loadingCC || loadingRA;
    const error = errorLCC || errorLRA;

    const allAssignments = useMemo(() => {
        const byPage = (currentCase?.assignments || []).reduce((_, ca) => {
            _[ca.page] = ca;
            return _;
        }, {});

        return Object.keys(roleAssignments || {}).map(page => ({
            ...(byPage[page] || {}),
            page,
        }));
    }, [roleAssignments, currentCase?.assignments]);

    const [reassignIdx, setReassignIdx] = useState();

    const assignments = allSlots ? allAssignments : (currentCase?.assignments || []);

    const currentAssignment = assignments[reassignIdx];
    // const currentAssignment = ((currentCase || {}).assignments || [])[reassignIdx];

    async function onReassign(page, userId) {
        
        await CasesApi.assignUserToCase({ caseId: id, userId, page });
        setReassignIdx();
        setUpdateCt(updateCt + 1);
    }

    const canReassign = !readonly && auth?.user?.permissions?.reassignCases;
    const canReassignBOA = !readonly && auth?.user?.permissions?.reassignCasesBOA;
    const canReassignPOA = !readonly && auth?.user?.permissions?.reassignCasesPOA;
    const canReassignCA = !readonly && auth?.user?.permissions?.reassignCasesCA;
    const canReassignFA = !readonly && auth?.user?.permissions?.reassignCasesFA;
    const canReassignRO = !readonly && auth?.user?.permissions?.reassignCasesRO;
    

    

    return (loading ? (
        <Loader fullscreen />
    ) : (error ? (
        <Notification color="danger">{JSON.stringify(error)}</Notification>
    ) : (!currentCase ? (
        <Notification color="danger">Case Not Found (This must be an error, please contact the administration).</Notification>
    ) : (<>
        <Table>
            <thead>
                <tr>
                    <th>Rol</th>
                    <th>Usuario</th>
                    <th>Asignado</th>
                    <th>{canReassign ? (
                        <Button onClick={() => setAllSlots(!allSlots)}>{allSlots ? 'Todas las Posiciones' : 'Posiciones Asignadas'}</Button>
                    ) : null}</th>
                </tr>
            </thead>
            <tbody>{assignments.sort((a, b) => {
                const da = new Date(a.assignedAt);
                const db = new Date(b.assignedAt);
                return db - da;
            }).map((
                { user, page, assignedAt }, idx
            ) => (
                <tr key={idx}>
                    <td>{(roleAssignments[page] || {}).assignment}</td>
                    <td>{user ? `${user.firstName} ${user.lastName}` : '------'}</td>
                    <td>{assignedAt ? toDrDateTime(assignedAt).format('MM/DD/YYYY hh:mm a') : ''}</td>
                    {canReassign || (page === "assigned.boa" && canReassignBOA) || (page === "assigned.poa" && canReassignPOA) || (page === "assigned.ca" && canReassignCA) || (page === "assigned.fa" && canReassignFA) || (page === "assigned.ro" && canReassignRO) ? (
                        <td>
                            <Button color="primary" onClick={() => setReassignIdx(idx)}>Reasignar</Button>
                        </td>
                    ) : null}
                </tr>
            ))
            }</tbody>
        </Table>
        {Number.isFinite(reassignIdx) ? (<ReassignModal
            page={currentAssignment.page}
            roleAssignment={roleAssignments[currentAssignment.page]}
            assignedUser={currentAssignment.user}
            onReassign={onReassign}
            onCloseModal={() => setReassignIdx()}
        />) : null}
    </>))));
}


export default CaseAssignments;