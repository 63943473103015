const form = {
    title: 'Desestimar Caso',
    displayName: 'CloseCase',
    page: 'case-close',
    hasSaveProgressButton: false,
    schema: {
        title: "Desestimar Caso",
        type: "object",
        properties: {
            "razonCerrar": {
                title: "Razon para desestimar el caso",
                type: "string",
            },
            "deleteCheck": {
                title: "Para confirmar la desestimación de este caso, ingrese \"CERRAR\" en el siguiente campo",
                type: "string",
                const: "CERRAR"
            }
        },
        required: ["razonCerrar", "deleteCheck"],
    },
    uiSchema: {
        "razonCerrar": {
            "ui:widget": "textarea",
        },
    },
    objectMap: {
        closeReason: 'razonCerrar'
    }
};


export default form;