import { object } from "yup";
import {
    entitySADocuments,
    UIDocumentosIdentificacionEmpresa,
    documentosRepAccionistas,
    documentosAccionistasJuridicas,
    entityForeignDocuments,
    entityEIRLDocuments,
    entityNonProfit,
    entityCooperative,
    entityGovAgency,
    entityConsorcio
} from "./BusinessEntityTypeUploadFiles";
import { tipoDeEntidad } from './commons';

const form = {
    title: "Carga de Documentos",
    displayName: 'RequirementsUploadBusinessClient',
    page: 'requirements-upload-business-client',
    determineCanEdit: ({ currentCase }) => (true),
};

const docConsorcio = {
    title: "Documentos de identificación de Empresa - Consorcio",
    type: "object",
    properties: {
        "registroMercantil": {
            title: "Registro Mercantil Vigente",
            type: "number",
        },
        "fechaDeVencimientoRegistroMercantil": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "estatutosSociales": {
            title: "Estatutos Sociales y modificaciones",
            type: "number",
        },
        "actaNominaAsambleaOrdinaria": {
            title: "Acta y Nómina última Asamblea Ordinaria que desiga autoridades",
            type: "number",

        },
        "fechaDeEmisionActaNomina": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "listadoSuscriptores": {
            title: "Listado de Suscriptores y/o socios",
            type: "number",
        },
        "fechaDeEmisionListadoSuscriptores": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "certRegistroMercantil": {
            title: "Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero).",
            type: "number",
        },
        "actaAsamblea": {
            title: "Acta de Asamblea que designe la persona física representante.",
            type: "number",
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },
        "certRNCporDGII": {
            title: "Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales.",
            type: "number",
        },
    }
}
const formFondInversionSchema = {
    title: "Informe Persona Jurídica Alto Riesgo Fondos De Inversión",
    type: "object",
    properties:{
        "formAltoRiesgoFondosInv":{
            title: " ",
            type: "number"
        }
    }
}


form.schema = {
    title: "Solicitud de Vinculación Cliente Empresa",
    type: "object",
    properties: {
        "tipoEntidad": {
            ...tipoDeEntidad,
            readOnly: true
        },
        "isLoan": {
            "type": "boolean",
            description: ""
        },
        "formularios": {
            title: "Formularios",
            type: "object",
            properties: {
                "conozcaSuClienteBanco": {
                    title: "Formulario Conozca Su Cliente (Banco)",
                    type: "number",
                },
                "conozcaSuClientePuesto": {
                    title: "Formulario Conozca Su Cliente (Puesto)",
                    type: "number",
                },
                "conozcaSuClienteSafi": {
                    title: "Formulario Conozca Su Cliente (Safi)",
                    type: "number",
                },
                "formularioSujetoObligadoNoFinanciero": {
                    title: "Formulario Sujeto Obligado No Financiero",
                    type: "number",
                },
                "formularioIdentificacionBeneficiarioFinal": {
                    title: "Formulario Identificación Beneficiario Final",
                    type: "number",
                },
                "listadoRequisitos": {
                    title: "Listado de Requisitos",
                    type: "string",
                    staticFile: "requirements-business"
                },
                "enviarCorreoCliente": {
                    "buttonText": "Enviar Correo a Cliente",
                    subject: "Documentos requeridos para vinculación en JMMB",
                    body: `
                        !!eval[].concat([
                            \`Buenas tardes Sr/Sra. \${formContext.clientFirstName} \${formContext.clientLastName}\`,
                            "",
                            (
                                "Gracias por su interés en visitarnos, de verdad para nosotros es un placer que inicien con nosotros y " +
                                "poder rentabilizar sus fondos. Para iniciar primero debemos crearlos como cliente. Anexo documentos " + 
                                "requeridos para firmar y los requisitos según el tipo de cliente:"
                            )
                        ],
                        "",
                        [
                            "- Persona Jurídica",
                            "    Formulario Conozca su Cliente",
                            "    Formulario W8",
                            "    Matriz de Riesgo de Cliente",
                            "    Sujetos Obligados",
                            "    Beneficiario Final"
                        ], ((
                            formData.isSA
                        ) ? [
                            "- Documentos",
                            "    Registro Mercantil Vigente",
                            "    Estatutos Sociales y sus Notificaciones",
                            "    Acta de la última Asamblea Ordinaria Anual que designa los miembros del Consejo de Administración, Gerentes o Junta de Miembros, vigente.",
                            "    Acta que designa las personas autorizadas a firmar",
                            "    Acta y Nómina de Asamblea Constitutiva",
                            "    Lista de Suscriptores y/o socios vigente",
                            "    De los directores, socios o accionistas, representantes legales y otros firmantes",
                            "    - Copia de cédula (ambos lados)",
                            "    - Pasaprte y segunda identificación (en caso de extranjeros)",
                            "    De los accionistas personas jurídicas",
                            "    - Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero)",
                            "    - Estatutos",
                            "    - Sociales, lista de accionistas y copia de la identificación de los representantes o accionistas, hasta el beneficiario final",
                            "    Acta de Asamblea que designe la persona física representante",
                            "    Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta",
                            "    Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales"
                        ] : []), ((
                            formData.isForeignCompany
                        ) ? [
                            "- Documentos",
                            "    Documentos de incorporación (Acta Constitutiva, Estatutos Sociales, Lista de accionistas)",
                            "    Certificado de Incorporación, Good Standing o su equivalente, atendiendo a la legislación de origen.",
                            "    Acta que designa los Directores y sus poderes de representación.",
                            "    Acta que designa las personas autorizadas a firmar",
                            "    Acta y Nómina de Asamblea Constitutiva",
                            "    Lista de Suscriptores y/o socios vigente",
                            "    De los directores, socios o accionistas, representantes legales y otros firmantes",
                            "    - Copia de cédula (ambos lados)",
                            "    - Pasaprte y segunda identificación (en caso de extranjeros)",
                            "    De los accionistas personas jurídicas",
                            "    - Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero)",
                            "    - Estatutos",
                            "    - Sociales, lista de accionistas y copia de la identificación de los representantes o accionistas, hasta el beneficiario final",
                            "    Acta de Asamblea que designe la persona física representante",
                            "    Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta",
                            "    Copia de la identificación tributaria de acuerdo a la legislación de origen y Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales"
                        ] : [])((
                            formData.isEIRL
                        ) ? [
                            "- Documentos",
                            "    Registro Mercantil vigente",
                            "    Acto donde consten los Estatutos Sociales y sus modificaciones",
                            "    Acto que designa la Gerencia, vigente",
                            "    Acto que designa las personas autorizadas a firmar",
                            "    Acto Constitutivo donde conste el Propietario",
                            "    De los directores, socios o accionistas, representantes legales y otros firmantes",
                            "    - Copia de cédula (ambos lados)",
                            "    - Pasaprte y segunda identificación (en caso de extranjeros)",
                            "    Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta",
                            "    Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales."
                        ] : []),((
                            formData.isNGO
                        ) ? [
                            "- Documentos",
                            "    Resolución o Autorización del Registro de Incorporación de la Procuraduría General de la República",
                            "    Estatutos y sus modificaciones",
                            "    Acta de la última reunión de la Junta General que designa los miembros actuales de la Junta Directiva",
                            "    Acta que designa las personas autorizadas a firmar",
                            "    Acta Constitutiva",
                            "    De los directores, representantes legales y otros firmantes:",
                            "    - Copia de cédula (ambos lados)",
                            "    - Pasaprte y segunda identificación (en caso de extranjeros)",
                            "    De los socios personas jurídicas:",
                            "    - Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero)",
                            "    - Estatutos",
                            "    - Sociales, lista de accionistas y copia de la identificación de los representantes o accionistas, hasta el beneficiario final",
                            "    Estados financieros o última Declaración Jurada",
                            "    Copia del documento emitido por la DGII, mediante el cual se asigna el RNC",
                            "    En los casos de Cooperativas, en adición a los documentos antes indicados, deberá requerirse el Certificado de Incorporación en el Instituto de Desarrollo y Crédito Cooperativo (IDECOOP)"
                        ] : []),((
                            formData.isGovCompany
                        ) ? [
                            "- Documentos",
                            "    Copia del Decreto que evidencia su creación o incorporación",
                            "    Certificación de la resolución del ente u órgano competente mediante el cual se designa el representante legal, Ministro en curso o Director, según sea el caso",
                            "    Según el tipo de entidad (si posee órganos de gestión internos), Certificación de la resolución del ente u órgano competente mediante el cual se autoriza la apertura de cuentas de valores a través de los intermediarios de valores y las personas autorizadas a dar instrucciones al efecto, o copia del Decreto que consigna dichos poderes",
                            "    De los representantes legales y otros firmantes, copia de copia de Cédula (ambos lados)",
                            "    Certificación del RNC emitida por la DGII, en la cual se establezca que la empresa se encuentra al día en sus obligaciones fiscales"
                        ] : []),((
                            formData.isConsortium
                        ) ? [
                            "- Documentos Del Consorcio",
                            "    Contrato o Convenio de Consorcio, incluyendo su objeto, las obligaciones de las partes, su duración, la capacidad de ejercicio de cada miembro del Consorcio, la designación de los representantes y el periodo por el cual son nombrados.",
                            "    Acta de inscripción al RNC",
                            "- De las empresas que integran el Consorcio:",
                            "    Estatutos Sociales y sus modificaciones",
                            "    Acta de la última Asamblea Ordinaria Anual que designa los miembros del Consejo de Administración, Gerentes o Junta de Miembros, vigente",
                            "    Acta que designa las personas autorizadas a firmar",
                            "    Acta y Nómina de Asamblea Constitutiva",
                            "    Lista de Suscriptores y/o socios vigente",
                            "    De los directores, socios o accionistas, representantes legales y otros firmantes:",
                            "    - Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero)",
                            "    - Estatutos Sociales, lista de accionistas y copia de la identificación de los representantes o accionistas, hasta el beneficiario final",
                            "    Acta de Asamblea que designe la persona física representante",
                            "    Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta",
                        ] : []), ((
                            formData.isFideicomiso
                        ) ? [
                            "- Del Fideicomiso:",
                            "    Contrato de Fideicomiso suscrito entre la fiduciaria y el (los) fideicomitentes",
                            "    Documentos societarios de la Fiduciaria",
                            "- De los Fideicomitentes:",
                            "    Estatutos Sociales y sus modificaciones",
                            "    Acta de la última Asamblea Ordinaria Anual que designa los miembros del Consejo de Administración, Gerentes o Junta de Miembros, vigente",
                            "    Acta que designa las personas autorizadas a firmar",
                            "    Acta y Nómina de Asamblea Constitutiva",
                            "    Lista de Suscriptores y/o socios vigente",
                            "    De los directores, socios o accionistas, representantes legales y otros firmantes:",
                            "    - Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero)",
                            "    - Estatutos Sociales, lista de accionistas y copia de la identificación de los representantes o accionistas, hasta el beneficiario final",
                            "    Acta de Asamblea que designe la persona física representante",
                            "    Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta",
                            "    Certificación del RNC emitida por la DGII."
                        ] : [])
                    ).filter(l => l !== null).join("\\n")`,
                    type: "null",
                },
            }
        },
        "soporteFuenteIngresos": {
            title: "Soporte fuente de ingresos",
            type: "object",
            properties: {
                "estFinAudRent1": {
                    title: "Est. financiero auditado o Decl. Renta 1",
                    type: "number",

                },
                "anoEstFinAudRent1": {
                    title: "Año",
                    type: "number",
                },
                "estFinAudRent2": {
                    title: "Est. financiero auditado o Decl. Renta 2",
                    type: "number",

                },
                "anoEstFinAudRent2": {
                    title: "Año",
                    type: "number",
                },
                "booleanDocCorrectos": {
                    type: "boolean",
                    title: "Documentos emitidos en el extranjero están correctamente apostillados",
                }
            }
        },
        "additionalFiles": {
            title: "Archivos adicionales",
            type: "array",
            items: {
                "properties": {
                    "documentName": {
                        title: "Nombre archivo",
                        type: "string"
                    },
                    "document": {
                        title: "Archivo",
                        type: "number"
                    },
                }
            },
        },
    },
    required: [],
    dependencies: {
        "isLoan": {
            "oneOf": [
                {
                    properties: {
                        "isLoan": { const: true },
                        "soporteSolicitudPrestamo": {
                            title: "Soporte Solicitud de Préstamo",
                            type: "object",
                            properties: {
                                "sopGarantia": {
                                    title: "Soporte Solicitud de Préstamo",
                                    type: "number",
                                },
                            }

                        }
                    }
                },
                {
                    properties: { "isLoan": { const: false } },
                }
            ]
        },
        "tipoEntidad": {
            "oneOf": [
                {
                    properties: {
                        "tipoEntidad": { enum: ["S.A., S.A.S. o S.R.L."] },
                        "documentosIdentificacionEmpresa": entitySADocuments,
                        documentosRepAccionistas,
                        documentosAccionistasJuridicas
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["Empresa extranjera"] },
                        "documentosIdentificacionEmpresa": entityForeignDocuments,
                        documentosRepAccionistas,
                        documentosAccionistasJuridicas
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["E.I.R.L."] },
                        "documentosIdentificacionEmpresa": entityEIRLDocuments,
                        documentosRepAccionistas: {
                            ...documentosRepAccionistas,
                            "title": "De los directores, socios o accionistas, representantes legales y otros firmantes"
                        }
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["Asociacion sin fines de lucro / ONG"] },
                        "documentosIdentificacionEmpresa": entityNonProfit,
                        documentosRepAccionistas,
                        documentosAccionistasJuridicas
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["Cooperativa"] },
                        "documentosIdentificacionEmpresa": entityCooperative
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["Empresa Gubernamental"] },
                        "documentosIdentificacionEmpresa": entityGovAgency
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["Consorcio"] },
                        "documentosIdentificacionEmpresa": entityConsorcio,
                        documentosAccionistasJuridicas
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["Fideicomiso"] },
                        "documentosIdentificacionEmpresa": entitySADocuments
                    }
                },
                {

                    properties: {
                        "tipoEntidad": { enum: ["Fondos de inversion"] },
                        "formFondInversion": formFondInversionSchema
                    }
                }
            ]
        }
    }
};


form.uiSchema = {
    "formularios": {
        "ui:field": "HideObjectField",
        "conozcaSuClienteBanco": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
        "conozcaSuClientePuesto": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
        "conozcaSuClienteSafi": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
        "formularioSujetoObligadoNoFinanciero": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
        "formularioIdentificacionBeneficiarioFinal": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
        "listadoRequisitos": { "ui:field": "FileField" },
        "enviarCorreoCliente": { "ui:field": "SendEmail" },
    },
    "formFondInversion":{
        "ui:field": "HideObjectField",
        "formAltoRiesgoFondosInv": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
    },
    "documentosIdentificacionEmpresa": UIDocumentosIdentificacionEmpresa,
    "documentosRepAccionistas": {
        "documentoIdentRepFirmantes": { "ui:field": "FileField", "joli:required": true },
        "segundaIdentRepFirmantes": { "ui:field": "FileField", "joli:required": true },
    },
    "documentosAccionistasJuridicas": {
        "classNames": "smallTwoRows",
        "certInc": { "ui:field": "FileField", "joli:required": true },
        "copiaIdAccInd": { "ui:field": "FileField", "joli:required": true },
        "estatutos": { "ui:field": "FileField", "joli:required": true },
        "listaAccionistas": { "ui:field": "FileField", "joli:required": true },
    },
    "soporteFuenteIngresos": {
        "classNames": "smallTwoRows",
        "estFinAudRent1": { "ui:field": "FileField", "joli:required": true },
        "estFinAudRent2": { "ui:field": "FileField", "joli:required": true },

    },
    "isLoan": { "classNames": "d-none" },
    "soporteSolicitudPrestamo": {
        "classNames": "smallTwoRows",
        "sopGarantia": { "ui:field": "FileField", "joli:required": true, "classNames": "soporteGarantiaFile" },
    },
    "additionalFiles": {
        "classNames": "additionalFiles",
        "items": { "document": { "ui:field": "FileField" } },
        "ui:options": {
            "orderable": false,
        }
    },
    "ui:order": [
        "tipoEntidad",
        "formularios",
        "formFondInversion",
        "documentosIdentificacionEmpresa",
        "documentosRepAccionistas",
        "*",
        "soporteSolicitudPrestamo",
        "additionalFiles"
    ]
}
form.objectMap = {
    "clients[0].businessClientBasicInfo.entityType": "tipoEntidad",


    "generatedFiles.shortkyc_0_FormularioConozcaSuClienteBanco": "formularios.conozcaSuClienteBanco",
    "generatedFiles.shortkyc_0_FormularioConozcaSuClientePuesto": "formularios.conozcaSuClientePuesto",
    "generatedFiles.shortkyc_0_FormularioConozcaSuClienteSafi": "formularios.conozcaSuClienteSafi",
    "generatedFiles.shortkyc_0_InformePersonaJurídicaAltoRiesgoFondosDeInversion": "formFondInversion.formAltoRiesgoFondosInv",
    "generatedFiles.shortkyc_0_FormularioSujetoObligadoNoFinanciero": "formularios.formularioSujetoObligadoNoFinanciero",
    "generatedFiles.shortkyc_0_FormularioIdentificacionBeneficiarioFinal": "formularios.formularioIdentificacionBeneficiarioFinal",
    "generatedFiles.shortkyc_0_ListadoRequisitos": "formularios.listadoRequisitos",

    "clients[0].businessClientBasicInfo.commercialRegisterFileId": "documentosIdentificacionEmpresa.registroMercantil",
    "clients[0].businessClientBasicInfo.commercialRegisterExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoRegistroMercantil",

    "clients[0].businessClientBasicInfo.certificationRNCFileId": "documentosIdentificacionEmpresa.certificacionRNC",
    "clients[0].businessClientBasicInfo.certificationRNCExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoCertificacionRNC",

    "clients[0].businessClientBasicInfo.bylawsAndModsFileId": "documentosIdentificacionEmpresa.estatutosSociales",

    "clients[0].businessClientBasicInfo.certPayrollOrdinaryAssemblyFileId": "documentosIdentificacionEmpresa.actaNominaAsambleaOrdinaria",
    "clients[0].businessClientBasicInfo.certPayrollOrdinaryAssemblyReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionActaNomina",

    "clients[0].businessClientBasicInfo.actDesignatingAuthSignatoriesFileId": "documentosIdentificacionEmpresa.actaDesignaFirmantesAutorizados",
    "clients[0].businessClientBasicInfo.actDesignatingAuthSignatoriesExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoActaDesignaFirmantesAutorizados",

    "clients[0].businessClientBasicInfo.certConstituentAssemblyFileId": "documentosIdentificacionEmpresa.actaAsambleaConstitutiva",
    "clients[0].businessClientBasicInfo.certConstituentAssemblyReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionActaAsambleaConstitutiva",

    "clients[0].businessClientBasicInfo.listOfSubscribersFileId": "documentosIdentificacionEmpresa.listadoSuscriptores",
    "clients[0].businessClientBasicInfo.listOfSubscribersReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionListadoSuscriptores",

    "clients[0].businessClientBasicInfo.assemblyActLegalFileId": "documentosIdentificacionEmpresa.actaAsambleaRepresentanteLegal",
    "clients[0].businessClientBasicInfo.assemblyActLegalReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionActaAsambleaRepresentanteLegal",

    "clients[0].businessClientBasicInfo.certIncIDECOOPFileId": "documentosIdentificacionEmpresa.certificadoIncorporacionIDECOOP",
    "clients[0].businessClientBasicInfo.certIncIDECOOPReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionCertificadoIncorporacionIDECOOP",


    "clients[0].businessClientBasicInfo.contractConsortiumTrustFileId": "documentosIdentificacionEmpresa.contratoConsorcioFideicomiso",
    "clients[0].businessClientBasicInfo.contractConsortiumTrustExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoContratoConsorcioFideicomiso",

    "clients[0].businessClientBasicInfo.corporateTrustDocumentsFileId": "documentosIdentificacionEmpresa.documentosSocietariosFiduciaria",
    "clients[0].businessClientBasicInfo.corporateTrustDocumentsYear": "documentosIdentificacionEmpresa.añoDocumentosSocietariosFiduciaria",

    "clients[0].businessClientBasicInfo.actDecreeConsigningPowersFileId": "documentosIdentificacionEmpresa.actaDecretoPoderes",
    "clients[0].businessClientBasicInfo.actDecreeConsigningPowersExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoActaDecretoPoderes",

    "clients[0].businessClientBasicInfo.idDocRepresentativesSignersFileId": "documentosRepAccionistas.documentoIdentRepFirmantes",
    "clients[0].businessClientBasicInfo.secondIdRepresentativesForegnSignersFileId": "documentosRepAccionistas.segundaIdentRepFirmantes",

    "clients[0].businessClientBasicInfo.authorizedSigners": {
        array: "documentosIdentificacionEmpresa.firmantesAutorizadosNestedList",
        map: {
            "name": "nombre",
            "position": "posicion",
        }
    },

    "clients[0].businessClientBasicInfo.shareholderListFileId": "documentosAccionistasJuridicas.listaAccionistas",


    "clients[0].businessClientBasicInfo.incorporationCertFileId": "documentosAccionistasJuridicas.certInc",
    "clients[0].businessClientBasicInfo.incorporationCertExpirationDate": "documentosAccionistasJuridicas.fechaDeVencimientoCertInc",

    "clients[0].businessClientBasicInfo.idCopyIndividualShareholdersFileId": "documentosAccionistasJuridicas.copiaIdAccInd",
    "clients[0].businessClientBasicInfo.idCopyIndividualShareholdersExpirationDate": "documentosAccionistasJuridicas.fechaDeVencimientoCopiaIdAccInd",

    "clients[0].businessClientBasicInfo.statutesFileId": "documentosAccionistasJuridicas.estatutos",
    "clients[0].businessClientBasicInfo.statutesExpirationDate": "documentosAccionistasJuridicas.fechaDeVencimientoEstatutos",

    "clients[0].businessClientBasicInfo.auditedFinancialStatementFileId1": "soporteFuenteIngresos.estFinAudRent1",
    "clients[0].businessClientBasicInfo.auditedFinancialStatement1Year": "soporteFuenteIngresos.anoEstFinAudRent1",

    "clients[0].businessClientBasicInfo.auditedFinancialStatementFileId2": "soporteFuenteIngresos.estFinAudRent2",
    "clients[0].businessClientBasicInfo.auditedFinancialStatement2Year": "soporteFuenteIngresos.anoEstFinAudRent2",

    "clients[0].businessClientBasicInfo.areDocsCorrect": "soporteFuenteIngresos.booleanDocCorrectos",
    "clients[0].businessClientBasicInfo.supportValueGuaranteeReleaseDate": "soporteSolicitudPrestamo.fechaEmision",
    "clients[0].businessClientBasicInfo.supportValueGuaranteeFileId": "soporteSolicitudPrestamo.sopGarantia",

    "clients[0].businessClientBasicInfo.incorporationDocumentFileId": "documentosIdentificacionEmpresa.incorporationDocument",
    "clients[0].businessClientBasicInfo.incoporationCertificateFileId": "documentosIdentificacionEmpresa.incorporationCertificate",
    "clients[0].businessClientBasicInfo.directorsPowerCertificateFileId": "documentosIdentificacionEmpresa.actaDirectores",
    "clients[0].businessClientBasicInfo.merchantRegistrationCertificateFileId": "documentosIdentificacionEmpresa.certRegistroMercantil",
    "clients[0].businessClientBasicInfo.assemblyCertificateFileId": "documentosIdentificacionEmpresa.actaAsamblea",
    "clients[0].businessClientBasicInfo.financialStatusFileId": "documentosIdentificacionEmpresa.estadosFinancieros",
    "clients[0].businessClientBasicInfo.rncIssueCertificateFileId": "documentosIdentificacionEmpresa.certRNCporDGII",
    "clients[0].businessClientBasicInfo.managementDesignationCertificateFileId": "documentosIdentificacionEmpresa.actaDesignacionGerencia",
    "clients[0].businessClientBasicInfo.ownerCertificateFileId": "documentosIdentificacionEmpresa.actaPropietarios",
    "clients[0].businessClientBasicInfo.countryRegistrationFileId": "documentosIdentificacionEmpresa.registroEnRepublica",
    "clients[0].businessClientBasicInfo.constitutionCertificateFileId": "documentosIdentificacionEmpresa.actaConstitutiva",
    "clients[0].businessClientBasicInfo.membersListFileId": "documentosIdentificacionEmpresa.listaDeMiembros",

    "clients[0].businessClientDueDiligenceReport.additionalFilesList.items": {
        array: "additionalFiles",
        map: {
            "name": "documentName",
            "fileId": "document",
        }
    },

    // "documentosClienteAdicional"
    "flags.isSA": "isSA",
    "flags.isForeignCompany": "isForeignCompany",
    "flags.isEIRL": "isEIRL",
    "flags.isNGO": "isNGO",
    "flags.isCooperative": "isCooperative",
    "flags.isGovCompany": "isGovCompany",
    "flags.isConsortium": "isConsortium",
    "flags.isFideicomiso": "isFideicomiso",
    "flags.isLoan": "isLoan",
}


export default form;