import React, { useState, useMemo, useEffect, useRef, useCallback, forwardRef, useContext } from 'react';
import { useHistory } from 'react-router';
import { Card, CardBody } from "reactstrap";
import _ from 'lodash';

import Loader from './Loader';
import Notification from './Notification';
import customFields from './customFields';
import Form from "./customFields/Form";
import widgets from './customWidgets';

import useLoader from '../util/useLoader';
import { invertMap, mapObject } from '../util/mapObject';
import useTitle from '../util/useTitle';
import { useSideChannel } from '../util/useSideChannel';
import ErrorListTemplate, { transformErrors } from "./ErrorListTemplate";
import useUpdateDetection from "../util/useUpdateDetection";
import useStateUpdateRequestPromise from "../util/useStateUpdateRequestPromise";
import clone from "../util/clone"
import SchemaFieldTemplate from './customFields/SchemaFieldTemplate';
import useJnxFormValidation from '../util/useJnxFormValidation';
import parseUISchemaFromSchema from '../util/parseUISchemaFromSchema';
import Help from './Help';




/** Higher level component for creating a form component customized by the given arguments. 
 *  @param {Objec} formDefinition - definition object of the form component.
 *  @param {string} displayName - displayed component name in react component tree
 *  @param {object} schema - a schema used to render the dynamic form (@see rjsf )
 *  @param {object} uiSchema - a uiSchema used to render the dynamic form (@see rjsf )
 *  @param {function(object): object} parseProps - callback that parses component props into a flat property mapping.
 *                  This mapping gets passed to the other callbacks.
 *                  Can also call React hooks and pass then in the mapping.
 *  @param {function(object): Promise} onSubmit - callback that handles form submission. Receives rjsf's onSubmit argument
 *                  and the parsed props as arguments, should return a promise that resolves if the submission was successful.
 *  @param {string} validate - Optional callback that handles form validation. Receives rjsf's onSubmit argument
 *                  and the parsed props as arguments.
 *  @param {string} renderFormChildren - callback for rendering the form component's children. Receives a props object with
 *                  the component's render props merged with the following attributes:
 *                      {object} scope - the flat property mapping created in parseProps
 *                      {object} formDefinition - the definition object passed to this function
 *  @param {string} renderFormSubmitted - callback for rendering a post submission component. Receives a props object with
 *                  the component's render props merged with the following attributes:
 *                      {object} scope - the flat property mapping created in parseProps
 *                      {object} formDefinition - the definition object passed to this function
 * 
 */
function FormComponent(formDefinition) {
    const {
        displayName = "FormComponent",
        title,
        parseProps = () => ({}),
        loadData = undefined,
        schema: fdSchema = {},
        uiSchema: fdUiSchemaProp = {},
        determineCanEdit,
        commentFieldsMap: fdCommentFieldsMap = null,
        buildFormContext = undefined,
        objectMap: fdObjectMap,
        customFormats = undefined,
        submitButtons: constSubmitButtons = null,
        onSubmit: propOnSubmit,
        validate: propValidate,
        noHtml5Validate,
        beforeRenderHook = () => { },
        renderNavigation,
        renderFormDetails,
        renderFormSubmitted,
        renderFormChildren,
    } = formDefinition;

    const fdUiSchema = parseUISchemaFromSchema(fdSchema, fdUiSchemaProp);

    let {
        staticFormData
    } = formDefinition;

    const staticUnmappedFormData = staticFormData;

    if (staticFormData && fdObjectMap) {
        staticFormData = mapObject(staticFormData, invertMap(fdObjectMap));
    }

    const staticEffects = [
        title && (() => { useTitle(title) })
    ];

    function Component(props) {
        const currentFormDef = useRef();
        const formCompRef = useRef();
        const currentFormData = useRef();
        const navRef = useRef();
        const formRef = useRef();
        const submitState = useRef();
        const [extraErrors, setExtraErrors] = useState();

        const [loading, errorLoading, loadFn] = useLoader();
        const [formSubmitted, setFormSubmitted] = useState();
        const [formSubmitResult, setFormSubmitResult] = useState();
        const [errorLoadingInitialFormData, setErrorLoadingInitialFormData] = useState();
        const [loadingInitialFormData, setLoadingInitialFormData] = useState();
        const [initialFormObject, setInitialFormObject] = useState(staticUnmappedFormData || {});
        const [initialFormData, setInitialFormData] = useState(staticFormData);
        const [
            _formDefinition,
            _setFormDefinition
        ] = useState([fdSchema, fdUiSchema, fdObjectMap, fdCommentFieldsMap, 0]);

        const [schema, uiSchema, objectMap, commentFieldsMap, curClientIdx] = _formDefinition;

        const error = errorLoading || errorLoadingInitialFormData;

        const sideChannel = useSideChannel(initialFormData);

        currentFormDef.current = [schema, uiSchema, objectMap];

        const getCurrentFormObject = useCallback(() => {
            const objectMap = currentFormDef.current[2];
            const cfo = getFormObjectFromData(currentFormData.current, objectMap, initialFormObject);
            return cfo;
        }, [currentFormData, initialFormObject]);

        const setFormObject = useCallback(formObject => {
            const objectMap = currentFormDef.current[2];
            const formData = getFormDataFromObject(formObject, objectMap);
            setInitialFormData(formData);
            setInitialFormObject(formObject);
            sideChannel.publish(formData, formObject);
        }, [sideChannel]);

        const saveCurrentFormObject = useStateUpdateRequestPromise(
            initialFormObject, setFormObject, getCurrentFormObject
        );

        const parsedProps = { ...props, ...parseProps(props) };

        const propsSignature = JSON.stringify(parsedProps);

        const {
            readonly,
            submitButtons: propSubmitButtons
        } = parsedProps;

        const setFormDefinition = useCallback((schema, uiSchema, objectMap, commentFieldsMap, clientIdx) => {
            schema = schema || fdSchema;
            uiSchema = uiSchema || fdUiSchema;
            objectMap = objectMap || fdObjectMap;
            commentFieldsMap = commentFieldsMap || fdCommentFieldsMap;
            _setFormDefinition([schema, uiSchema, objectMap, commentFieldsMap, clientIdx || 0]);
            if (initialFormObject) {
                const formData = getFormDataFromObject(initialFormObject, objectMap);
                setInitialFormData(formData);
                currentFormData.current = formData;
                sideChannel.publish(formData, initialFormObject);
            }
        }, [
            fdSchema,
            initialFormObject,
            fdUiSchema,
            fdObjectMap,
        ]);

        const history = useHistory();

        staticEffects.forEach(fn => !!fn && fn());

        const scope = useMemo(() => ({
            props: parsedProps,
            initialFormObject,
            initialFormData,
        }), [
            propsSignature,
            initialFormObject,
            initialFormData
        ]);
        const setFormDataValues = useCallback(dataValues => {
            console.log("setFormDataValues", dataValues);
            const formC = formCompRef.current;
            const newFormData = clone.set(formC.state.formData, dataValues);
            formC.setState(
                { formData: newFormData },
                () => formC.props.onChange && formC.props.onChange(formC.state)
            );
        }, [formCompRef]);
        const renderScope = useMemo(() => ({
            ...scope,
            loading, error, loadFn
        }), [scope, loading, error, loadFn]);

        const formContext = useMemo(() => ({
            sideChannel,
            setFormDataValues,
            formDefinition: {
                schema,
                uiSchema,
                objectMap,
                commentFieldsMap,
                curClientIdx,
                invObjectMap: objectMap ? invertMap(objectMap) : undefined,
            },
            formFields: { current: {} },
            ...(buildFormContext ? buildFormContext(scope) : {})
        }), [buildFormContext, scope, sideChannel, _formDefinition, setFormDataValues]);
      
        const submitButtons = propSubmitButtons || constSubmitButtons;

        const onSubmit = useMemo(() => {
            const timestamp = new Date().getTime();
            const fn = readonly ? (() => { }) : (args) => {

                const submitButton = (submitButtons || {})[submitState.current] || {};

                return loadFn(async () => {
                    const { formData } = args;
                    const formObject = getFormObjectFromData(formData, objectMap, initialFormObject);
                    if (formObject) {
                        args.object = formObject;
                        args.clientIdx = curClientIdx;
                        args.commentFieldsMap = commentFieldsMap;
                        args.generateDocuments = parsedProps.generateDocuments;
                        if (submitButton.onProcessSubmit) {
                            submitButton.onProcessSubmit(args, scope);
                        }
                    }

                    setInitialFormData(formData);
                    setInitialFormObject(formObject);
                    const result = await propOnSubmit(args, scope);
                    setFormSubmitResult(result);
                    setFormSubmitted(true);
                });
            }
            fn.timestamp = timestamp;
            return fn;
        }, [readonly, propOnSubmit, setFormSubmitted, scope, initialFormObject, setExtraErrors]);

        const jnxValidation = useJnxFormValidation(schema, uiSchema);

        const validate = useMemo(() => {
            function validate(formData, errors) {

                [jnxValidation, propValidate].reduce((errors, valFn) => {
                    return valFn ? valFn(formData, errors, scope, formContext.formFields?.current) : errors;
                }, errors);

                return errors;
            }

            return (propValidate || jnxValidation) ? validate : undefined;
        }, [propValidate, jnxValidation, scope])

        useEffect(() => {
            const flags = {};
            async function initialFormDataLoader(parsedProps) {
                setErrorLoadingInitialFormData();
                setLoadingInitialFormData(true);
                try {
                    const formObject = await loadData(parsedProps);
                    if (!flags.canceled) {
                        setFormObject(formObject);
                    }
                } catch (e) {
                    console.error(e);
                    if (!flags.canceled) setErrorLoadingInitialFormData(e.message);
                }
                setLoadingInitialFormData();
            }

            if (loadData) {
                initialFormDataLoader(parsedProps);
                return () => {
                    // cancel load if component is unloaded or props change
                    flags.canceled = true;
                }
            }
            return undefined;
        }, [
            loadData,
            propsSignature,
        ]);

        const propagateChangeRef = useRef();
        const onChange = useMemo(() => {
            const propagateChange = _.debounce(() => {
                // kill any old propagations
                if (propagateChangeRef.current !== propagateChange) { return; }
                const formData = currentFormData.current;
                const formObject = getCurrentFormObject();
                sideChannel.publish(formData, formObject);

                if (navRef && navRef.current) {
                    navRef.current.updateNav();
                }
            }, 200);
            propagateChangeRef.current = propagateChange;

            return ({ formData }) => {
                currentFormData.current = formData;
                propagateChange();
            }
        }, [sideChannel, currentFormData, getCurrentFormObject, navRef]);

        useEffect(() => {
            if (navRef && navRef.current) {
                navRef.current.updateNav();
            }
        }, [initialFormData]);

        const onSubmitRef = useRef();
        onSubmitRef.current = onSubmit;

        function makeSubmitButtonHandler({
            key,
            ignoreValidation,
        }) {
            function submitButtonHandler(event) {

                submitState.current = key;

                const onSubmit = onSubmitRef.current;

                const currentFormData = formCompRef?.current?.state?.formData;
                if (ignoreValidation) {
                    if (event) {
                        console.log("Click Persist Submit");
                        event.preventDefault();
                        event.persist();
                        event.target.disabled = true;
                    }
                    onSubmit({ formData: currentFormData });
                    setTimeout(() => {
                        console.log("Dentro del timer Submit");
                        event.target.disabled = false
                    }, 5000);
                }
            };

            return submitButtonHandler;
        }

        const brh = beforeRenderHook({
            parsedProps,
            sideChannel,
            setFormDefinition,
            saveCurrentFormObject,
            setFormObject,
            setExtraErrors,
            navRef,
            onSubmitRef,
            getCurrentFormObject,
            initialFormObject,
            currentFormData,
            submitButtons,
        });

        const transformErrorsCb = useCallback((errors) => transformErrors(errors, schema), [schema]);

        useEffect(() => {
            currentFormData.current = formCompRef?.current?.state?.formData;
        }, [formCompRef?.current?.state?.formData]);

        const formClassName = "";

        return (
            <>
                {loading ? <Loader fullscreen /> : null}
                {error ? <Notification color="danger" show="true" >{error}</Notification> : null}
                {(formSubmitted && renderFormSubmitted) ? (
                    renderFormSubmitted({
                        ...props,
                        history,
                        scope: renderScope,
                        formDefinition,
                        formSubmitResult,
                    })
                ) : (
                    <>
                        {renderNavigation ? renderNavigation({ title, navRef, formRef }) : null}
                        <div className={`${formClassName}`} ref={formRef}>
                            {renderFormDetails ? renderFormDetails({
                                brh,
                                parsedProps,
                                setFormObject,
                                sideChannel
                            }) : null}
                            <Card>
                                <CardBody>
                                    <Form
                                        ref={formCompRef}
                                        schema={schema}
                                        disabled={readonly}
                                        uiSchema={uiSchema}
                                        customFormats={customFormats}
                                        fields={customFields}
                                        FieldTemplate={SchemaFieldTemplate}
                                        transformErrors={transformErrorsCb}
                                        // formData={currentFormData.current || initialFormData}
                                        formData={initialFormData}
                                        formContext={formContext}
                                        noHtml5Validate={noHtml5Validate}
                                        onSubmit={onSubmit}
                                        extraErrors={extraErrors}
                                        validate={validate}
                                        onChange={loadingInitialFormData ? undefined : onChange}
                                        widgets={widgets}
                                        ErrorList={ErrorListTemplate}
                                    ><>
                                            {renderFormChildren ? renderFormChildren({
                                                ...props,
                                                scope,
                                                formDefinition,
                                            }) : null}
                                            {!readonly && submitButtons ? (<div className="form-submit-buttons">
                                                {Object.entries(submitButtons).map(([key, { text, className, Component = "button", btnProps, ...props }]) => (
                                                    <Component key={key}
                                                        type="submit"
                                                        onClick={makeSubmitButtonHandler({ key, ...props })}
                                                        {...(typeof Component === 'string' ? {} : { sideChannel })}
                                                        className={`btn ${className || ''}`}
                                                        {...(btnProps || {})}
                                                    >{text}</Component>
                                                ))}
                                            </div>) : null}
                                        </></Form>
                                </CardBody>
                            </Card></div>
                    </>
                )}
            </>
        );
    }

    Component.displayName = displayName;
    Component.formDefinition = formDefinition;

    Component.determineCanEdit = determineCanEdit;

    return Component
}

function getFormObjectFromData(formData, objectMap, initialFormObject) {
    return objectMap ? mapObject(
        formData,
        objectMap,
        JSON.parse(JSON.stringify(initialFormObject || {})),
    ) : undefined;
}

function getFormDataFromObject(formObject, objectMap) {
    return objectMap ? mapObject(
        formObject,
        invertMap(objectMap)
    ) : formObject;
}

export const ExtendedForm = forwardRef((props, ref) => {
    return (<Form
        {...props}
        ref={ref}
        fields={customFields}
        transformErrors={transformErrors}
        widgets={widgets}
        ErrorList={ErrorListTemplate}
    />);
});


export default FormComponent;