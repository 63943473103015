import React from "react";
import Avatar from "react-avatar";


function UserIcon({user}){
    const name = `${user?.firstName || ""}  ${user?.lastName || ""}`;

    return <Avatar
        name={name}
        size="42px"
        round
    />;
}


export default UserIcon;