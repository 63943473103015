import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';


function useSearchParams(params){
    const { search, pathname } = useLocation();
    const history = useHistory();
    const parsed = useMemo(() => queryString.parse(search || ""), [search]);

    function setQueryString(qsparams) {
        history.push(`${pathname}?${queryString.stringify(qsparams)}`);
    }

    return useMemo(() => [...Object.entries(params).map(([param, defaultValue]) => [
        parsed[param] || defaultValue,
        (value) => setQueryString({...parsed, [param]: value})
    ]), [
        parsed,
        (newparams) => setQueryString({...newparams})
    ]], [params, parsed])
}


export default useSearchParams;