import React, { useState } from "react";
import {
    FormGroup,
    Button
} from "reactstrap";
import useResourceLoader from '../../util/useResourceLoader';
import Notification from "../Notification";
import Loader from "../Loader";
import FileApi from "../../api/FileApi";
import StaticFileApi from "../../api/StaticFileApi";
import FileInput from "../FileInput";


function FileField(props) {
    const {
        formData,
        formContext,
        name,
        disabled,
        schema: {
            title,
            buttonText,
            resource = "File",
            accept = "*",
            type,  // has to be number (the file id)
            "const": constValue,
            staticFile,
        },
        idSchema: { '$id': fieldId },
        uiSchema: {
            'ui:titleAsLink': titleAsLink,
            'ui:hideOnEmpty': hideOnEmpty,
            'ui:readonly': uiReadonly,
            'ui:hideAttr': uiHideAttr
        },
        // title,
        required,
        onChange: propOnChange
    } = props;
    const labelId = `${fieldId}-label`;
    const postArgs = ((formContext || {}).fileField || {}).postArgs;

    const useFileId = type === "number";
    const useStaticFile = !!staticFile;
    const value = formData || constValue || staticFile;

    const readonly = uiReadonly || !!constValue || useStaticFile;

    const [file, loadingFile, errorLoadingFile] = useResourceLoader(() => (
        (useFileId) ? (
            value ? FileApi.getFile({ fileId: value }) : {}
        ) : (useStaticFile ? (
            StaticFileApi.getFile({ key: staticFile })
        ) : (
            { url: value, name: (value || '').split('/').pop() }
        ))
    ), [useFileId, value]);

    const [uploadingFile, setUploadingFile] = useState();
    const [errorUploadingFile, setErrorUploadingFile] = useState();
    const error = (
        errorLoadingFile ||
        errorUploadingFile ||
        ((!loadingFile && (
            !file || (readonly && !file.url)
        )) ? { message: `No ${resource} to show` } : null)
    );

    async function onChange(file) {
        if (file) {
            const extension = file.name.indexOf('.') >= 0 ? `.${file.name.split('.').pop()}` : '';
            setUploadingFile(true);
            let result;
            try {  
                result = await FileApi.uploadFile({ ...(postArgs || {}), file, filename: `${name}${extension}` });
                await propOnChange(useFileId ? result.id : result.url);
                setTimeout(()=> {setUploadingFile(false)}, 2000);
            } catch (e) {
                setErrorUploadingFile(e);
                setTimeout(()=> {setUploadingFile(false)}, 2000);
            }    
        }
    }

    if (hideOnEmpty && readonly && !value) {
        return null;
    }

    if (uiHideAttr && readonly) {
        return null;
    }

    return (
        <FormGroup className="file-field" disabled={readonly}>
            {titleAsLink && title && readonly && file && file.url ? (
                <Button tag="a" href={file.url} target="_blank">{title}</Button>
            ) : (<>
                {title ? (<label className="control-label" htmlFor={labelId}>
                    {title}{required ? <span className="required">*</span> : null}
                </label>) : null}
                {uploadingFile ? (<div>
                    <Loader fullscreen >Uploading {resource}</Loader>
                </div>) : (loadingFile ? (<div>
                    <Loader>Loading {resource}</Loader>
                </div>) : (<>
                    {error ? (<Notification color="danger">
                        <div>{error.message}</div>
                    </Notification>) : null}
                    <FileInput
                        file={file}
                        disabled={disabled}
                        viewButtonText={buttonText}
                        onFileSelected={onChange}
                        resource={resource}
                        accept={accept}
                        readonly={readonly}
                        required={required}
                    />
                </>))}
            </>)}
        </FormGroup>
    );
}


export default FileField;