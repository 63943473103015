import React, { useMemo } from "react";
import { FormGroup, Input } from "reactstrap";
import useResourceLoader from '../../util/useResourceLoader';
import fetchApi from "../../util/fetchApi";
import checkErrors from "../../api/checkErrors";
import Notification from "../Notification";
import Loader from "../Loader";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import { getObject } from "../../util/mapObject";


function LookupFormField(props) {
    const {
        formData,
        formContext: { sideChannel },
        disabled,
        schema: {
            title,
            lookup,
            type
        },
        uiSchema: {
            'akc:requiredIfVisible': akcRequiredIfVisible,
            'ui:readonly': readonly
        },
        // title,
        required: requiredProp,
        onChange: propOnChange
    } = props;

    const required = requiredProp || akcRequiredIfVisible;

    const {
        resource = "Options",
        api,
        params,
        id,
        label,
        noValue = ''
    } = lookup || {};

    const rootFormData = useSideChannelSubscription(sideChannel, 0);
    const paramsString = useMemo(() => (
        rootFormData && params ? Object.entries(params).map(([k, v]) => {
            if (v && v.field) v = getObject(rootFormData, v.field);
            return v ? `${k}=${encodeURIComponent(v)}` : null;
        }).filter(s => !!s).join("&") : ""
    ), [rootFormData, params]);

    const [options, loadingOptions, errorLoadingOptions] = useResourceLoader(
        () => api ? fetchApi.lookup.get(`${api}${paramsString ? `?${paramsString}` : ''}`).then(checkErrors) : Promise.reject(new Error("Schema error: Missing lookup data")),
        [api, paramsString]
    );

    function onChange({ target: { value } }) {
        if (type === "number") {
            value = (value | 0);
        }
        propOnChange(value);
    }

    return (
        <FormGroup disabled={readonly || disabled}>
            <label className="control-label" htmlFor="root_preferredBranchId">
                {title}{required ? <span className="required">*</span> : null}
            </label>
            {loadingOptions ? (<div>
                <Loader>Loading {resource}</Loader>
            </div>) : (errorLoadingOptions ? (<Notification color="danger">
                <div>{errorLoadingOptions.message}</div>
            </Notification>) : (
                <Input type="select" disabled={readonly || disabled} required={required} value={formData} onChange={onChange}>
                    <option value={undefined}>{noValue}</option>
                    {(options || []).map((option, idx) => (
                        <option key={idx} value={option[id]}>{option[label]}</option>
                    ))}
                </Input>
            ))}
            {/* <select id="root_preferredBranchId" className="form-control" required="">
                <option value=""></option><option value="1">Santo Domingo</option><option value="2">Punta Cana</option>
            </select> */}
        </FormGroup>
    );
}


export default LookupFormField;