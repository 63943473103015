import dayjs from 'dayjs';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');

dayjs.extend(utc);
dayjs.extend(timezone);


const DOMINICAN_TZ = 'America/Dominica'; // AST

dayjs.tz.setDefault(DOMINICAN_TZ)

export const toDrDateTime = (datetime) => {
    // This is to make all datetime fields consistent, some come with a Timezone.
    if (typeof datetime === 'string') datetime = datetime.replace('Z', '');
    const inUTC = dayjs(datetime).tz("UCT", true);
    return inUTC.tz(DOMINICAN_TZ);
}

export const toDrDate = (date) => {
    return dayjs(date).tz(DOMINICAN_TZ, true);
}

export const getBusinessDatesCount = (startDate, endDate) => {
    let count = 0;
    let curDate = parseInt(+startDate / 86400000, 10) * 86400000;
    while (curDate < parseInt(+endDate / 86400000, 10) * 86400000) {
        const dayOfWeek = new Date(curDate).getDay();
        const isWeekend = (dayOfWeek === 6) || (dayOfWeek === 0);
        if (!isWeekend) {
            count += 1;
        }
        curDate = curDate + 24 * 60 * 60 * 1000
    }
    return count;
}