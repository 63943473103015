import { readOnlyFileUI } from "../commons";

const form = {
    title: "Carga Contratos",
    displayName: 'NonCreditContractsDownloadPersonalClient',
    page: 'non-credit-contracts-download-personal-client',
};


form.schema = {
    title: "Solicitud de Vinculación Cliente Personal",
    type: "object",
    properties: {
        "enviarCorreoCliente": {
            "buttonText": "Enviar Correo a Cliente",
            subject: "Documentación completada para revisión",
            body: "Buenas tardes Sr/Sra. {nombreCliente} \n\nHemos recibido la documentación para continuar el proceso de vinculación a JMMB. Le estaremos contactando para la firma de los documentos. \n\nFavor indicarme que día y hora tienes disponible para reunirnos y proceder a la firma de la documentación.",
            type: "null",
        },
        "dct": {
            title: "Documentos Cliente Titular",
            description: "Documentos a firmar por el cliente",
            type: "object",
            properties: {
                "titlePuesto": { title: "Puesto", type: "null", const: null },
                "titleBanco": { title: "Banco", type: "null", const: null },
                "titleSafi": { title: "Safi", type: "null", const: null },
                "tarjetaDeFirmaPuesto": {
                    title: "Tarjeta de Firma",
                    type: "number",
                },
                "informeInversionistaPuesto": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoPuesto": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "contratoCuentaPuesto": {
                    title: "Contrato Apertura Cuenta",
                    type: "number",
                },
                "cartaAutorizacionCuentaPuesto": {
                    title: "Carta Autorización Apertura Cuenta",
                    type: "number",
                },
                "formularioClientePuesto": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioW8BenPuesto": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formularioW9Puesto": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formularioMoneylinePuesto": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
                "tarjetaFirmaSafi": {
                    title: "Tarjeta de Firma",
                    type: "number",
                },
                "informeInversionistaSafi": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoSafi": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formularioClienteSafi": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioW8BenSafi": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formularioW9Safi": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formularioMoneylineSafi": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
                "tarjetaFirmaBanco": {
                    title: "Tarjeta de Firma",
                    type: "number",
                },
                "formularioClienteBanco": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "contratoMarcoBanco": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "contratoCuentaBanco": {
                    title: "Contrato Apertura Cuenta",
                    type: "number",
                },
                "formularioW8BenBanco": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formularioW9Banco": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formularioMoneylineBanco": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
                "formularioSujetoObligado": {
                    title: "Formulario Sujeto Obligado No Financiero Persona Física",
                    type: "number",
                },
                "solicitudPrestamoDL": {
                    title: "Solicitud de Préstamo",
                    type: "number"
                }
            }
        },
    },
    required: [],
};



form.uiSchema = {
    "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "dct": {
        "classNames": "personalNonCCD-dct",
        "ui:field": "HideObjectField",
        "titlePuesto": { classNames: "title                   puesto" },
        "titleBanco": { classNames: "title                   banco" },
        "titleSafi": { classNames: "title                   safi" },
        "tarjetaDeFirmaPuesto": { ...readOnlyFileUI, classNames: "tarjetaDeFirma          puesto" },
        "informeInversionistaPuesto": { ...readOnlyFileUI, classNames: "informeInversionista    puesto" },
        "contratoMarcoPuesto": { ...readOnlyFileUI, classNames: "contratoMarco           puesto" },
        "contratoCuentaPuesto": { ...readOnlyFileUI, classNames: "contratoCuenta          puesto" },
        "cartaAutorizacionCuentaPuesto": { ...readOnlyFileUI, classNames: "cartaAutorizacionCuenta puesto" },
        "formularioClientePuesto": { ...readOnlyFileUI, classNames: "formularioCliente       puesto" },
        "formularioW8BenPuesto": { ...readOnlyFileUI, classNames: "formularioW8Ben         puesto" },
        "formularioW9Puesto": { ...readOnlyFileUI, classNames: "formularioW9            puesto" },
        "formularioMoneylinePuesto": { ...readOnlyFileUI, classNames: "formularioMoneyline     puesto" },
        "tarjetaFirmaSafi": { ...readOnlyFileUI, classNames: "tarjetaFirma            safi" },
        "informeInversionistaSafi": { ...readOnlyFileUI, classNames: "informeInversionista    safi" },
        "contratoMarcoSafi": { ...readOnlyFileUI, classNames: "contratoMarco           safi" },
        "formularioClienteSafi": { ...readOnlyFileUI, classNames: "formularioCliente       safi" },
        "formularioW8BenSafi": { ...readOnlyFileUI, classNames: "formularioW8Ben         safi" },
        "formularioW9Safi": { ...readOnlyFileUI, classNames: "formularioW9            safi" },
        "formularioMoneylineSafi": { ...readOnlyFileUI, classNames: "formularioMoneyline     safi" },
        "tarjetaFirmaBanco": { ...readOnlyFileUI, classNames: "tarjetaFirma            banco" },
        "formularioClienteBanco": { ...readOnlyFileUI, classNames: "formularioCliente       banco" },
        "contratoMarcoBanco": { ...readOnlyFileUI, classNames: "contratoMarco           banco" },
        "contratoCuentaBanco": { ...readOnlyFileUI, classNames: "contratoCuenta          banco" },
        "formularioW8BenBanco": { ...readOnlyFileUI, classNames: "formularioW8Ben         banco" },
        "formularioW9Banco": { ...readOnlyFileUI, classNames: "formularioW9            banco" },
        "formularioMoneylineBanco": { ...readOnlyFileUI, classNames: "formularioMoneyline     banco" },
        "formularioSujetoObligado": { ...readOnlyFileUI, classNames: "formularioSujetoObligado" },
        "solicitudPrestamoDL": { ...readOnlyFileUI, classNames: "solicitudPrestamoDL" },
    },
};

form.objectMap = {
    // Puesto
    "generatedFiles.fullkyc_$idx_TarjetaDeFirmaPuesto": "dct.tarjetaDeFirmaPuesto",
    "generatedFiles.fullkyc_$idx_InformePerfilDeInversionistaPuesto": "dct.informeInversionistaPuesto",
    "generatedFiles.fullkyc_$idx_ContratoMarcoPuesto": "dct.contratoMarcoPuesto",
    "generatedFiles.fullkyc_$idx_ContratoAperturaCuentaPuesto": "dct.contratoCuentaPuesto",
    "generatedFiles.fullkyc_$idx_CartaAutorizaciónAperturaCuentaPuesto": "dct.cartaAutorizacionCuentaPuesto",
    "generatedFiles.fullkyc_$idx_FormularioConozcaSuClientePuesto": "dct.formularioClientePuesto",
    "generatedFiles.fullkyc_$idx_FormularioSolicitudMoneylinePuesto": "dct.formularioMoneylinePuesto",
    "generatedFiles.fullkyc_$idx_IrsW8benPuesto": "dct.formularioW8BenPuesto",
    "generatedFiles.fullkyc_$idx_IrsW9SpPuesto": "dct.formularioW9Puesto",
    // SAFI
    "generatedFiles.fullkyc_$idx_TarjetaDeFirmaSafi": "dct.tarjetaFirmaSafi",
    "generatedFiles.fullkyc_$idx_InformePerfilDeInversionistaSafi": "dct.informeInversionistaSafi",
    "generatedFiles.fullkyc_$idx_ContratoMarcoSafi": "dct.contratoMarcoSafi",
    "generatedFiles.fullkyc_$idx_FormularioConozcaSuClienteSafi": "dct.formularioClienteSafi",
    "generatedFiles.fullkyc_$idx_FormularioSolicitudMoneylineSafi": "dct.formularioMoneylineSafi",
    "generatedFiles.fullkyc_$idx_IrsW8benSafi": "dct.formularioW8BenSafi",
    "generatedFiles.fullkyc_$idx_IrsW9SpSafi": "dct.formularioW9Safi",

    // Banco
    "generatedFiles.fullkyc_$idx_TarjetaDeFirmaBanco": "dct.tarjetaFirmaBanco",
    "generatedFiles.fullkyc_$idx_ContratoMarcoBanco": "dct.contratoMarcoBanco",
    "generatedFiles.fullkyc_$idx_ContratoAperturaCuentaBanco": "dct.contratoCuentaBanco",
    "generatedFiles.fullkyc_$idx_FormularioConozcaSuClienteBanco": "dct.formularioClienteBanco",
    "generatedFiles.fullkyc_$idx_FormularioSolicitudMoneylineBanco": "dct.formularioMoneylineBanco",
    "generatedFiles.fullkyc_$idx_IrsW8benBanco": "dct.formularioW8BenBanco",
    "generatedFiles.fullkyc_$idx_IrsW9SpBanco": "dct.formularioW9Banco",

    "generatedFiles.fullkyc_$idx_FormularioSujetoObligadoNoFinancieroPersonal": "dct.formularioSujetoObligado",

    // Loan
    "generatedFiles.fullkyc_$idx_SolicitudDePrestamoPersonal": "dct.solicitudPrestamoDL",
}


export default form;