import React, { useMemo, useState } from "react";
import { Table, Button, Input } from "reactstrap";
import StaticFileApi from "../../api/StaticFileApi";
import FileInput from "../../components/FileInput";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import useLoader from "../../util/useLoader";
import useResourceLoader from "../../util/useResourceLoader";


function StaticFiles(){
    const [fileList, loadingFileList, errorLoadingFileList, setFileList] = useResourceLoader(
        () => StaticFileApi.list(),
        [],
        []
    );

    const [resetCt, setResetCt] = useState(0);

    function onNewStaticFile(newFile){
        setFileList([...fileList, newFile]);
        setResetCt(resetCt + 1);
    }

    function onUpdateStaticFile(index, newFile){
        const newFL = [...fileList];
        newFL[index] = newFile;
        setFileList(newFL);
    }

    return loadingFileList  ? (
        <Loader/>
    ) : (errorLoadingFileList ? (
        <Notification>{errorLoadingFileList.message}</Notification>
    ) : (<Table>
        <thead>
            <tr>
                <th>Clave</th>
                <th>Archivo</th>
            </tr>
        </thead>
        <tbody>
            {fileList.map((file, index) => (
                <StaticFileEntry key={index} entry={file} onEntryChanged={(file) => onUpdateStaticFile(index, file)} />
            ))}
            <NewStaticFileInput key={resetCt} onNewStaticFile={onNewStaticFile}/>
        </tbody>
    </Table>));
}

function StaticFileEntry({
    entry,
    onEntryChanged
}) {
    const [loading, error, loadFn] = useLoader();

    function onChangeFile(file){
        loadFn(async () => {
            const newEntry = await StaticFileApi.uploadFile({file, key: entry.key});
            onEntryChanged(newEntry);
        })
    }

    return (<tr>
        <td>{entry.key}</td>
        <td colSpan="2">{loading ? (
            <Loader />
        ) : (
            <FileInput
                file={entry}
                onFileSelected={(file) => onChangeFile(file)}
            />
        )}
        {error ? (
            <Notification>{error.message}</Notification>
        ): null}
        </td>
    </tr>);
}


function NewStaticFileInput({
    onNewStaticFile
}){
    const [key, setKey] = useState();
    const [file, setFile] = useState();
    const [loading, error, loadFn] = useLoader();

    function onAddNewStaticFile(key, file){
        loadFn(async () => {
            const newFile = await StaticFileApi.uploadFile({file, key});
            onNewStaticFile(newFile);
        })
    }

    return (<tr>
        <td>
            <Input placeholder="clave" value={key || ""} onChange={({target: {value}}) => setKey(value)} />
        </td>
        <td>
            {loading ? (
                <Loader />
            ) : (
                <FileInput
                    file={file}
                    viewable={false}
                    uploadButtonText="Buscar Archivo"
                    notifyNoFile={false}
                    onFileSelected={(file) => setFile({name: file.name, file})}
                />
            )}
        </td>
        <td>
            <Button
                color="primary"
                disabled={loading || !file || !key}
                onClick={() => onAddNewStaticFile(key, file.file)}
            >A&ntilde;adir Archivo Est&aacute;tico</Button>
            {error ? (
                <Notification>{error.message}</Notification>
            ): null}
        </td>
    </tr>);
}

export default StaticFiles;