import React, { useState, useEffect, useContext } from 'react';
import { FormGroup, Label, Row, Col, Input, Button, Card, CardHeader, CardBody, Form } from "reactstrap";

import LookupApi from "../../api/LookupApi";
import CasesApi from "../../api/CasesApi";
import { AuthContext } from "../../context/AuthContext";
import useResourceLoader from "../../util/useResourceLoader";

const CaseSearchBar = ({
    filters,
    onSearch,
    onClearSearch,
}) => {
    const { isAdmin } = useContext(AuthContext);
    const [current, setCurrent] = useState(filters);
    const [resetCt, setResetCt] = useState(0);
    const auth = useContext(AuthContext);
    const permissions = ((auth || {}).user || {}).permissions || {};

    useEffect(() => setCurrent(filters), [filters]);

    const [[institutions, clientTypes, roles, stages, branches, owners, users], loading, error] = useResourceLoader(() => Promise.all([
        LookupApi.getInstitutions(),
        LookupApi.getClientTypes(),
        LookupApi.getRoles(),
        CasesApi.getWorkflowStates().then(list => list.filter(s => s.hasForm)),
        LookupApi.getBranches(),
        LookupApi.getUsers({ canOwn: 1 }),
        LookupApi.getUsers({ canOwn: 0 }),
    ]), [], [[], [], []]);

    // console.log("owners", owners)

    const handleChange = (e) => {
        const update = { [e.target.name]: e.target.value };
        setCurrent(Object.assign({}, current, update));
    }

    const handleCompleted = (e) => {
        const update = { 'completed': !current.completed };
        setCurrent(Object.assign({}, current, update));
    }

    const handleClosed = (e) => {
        const update = { 'closed': !current.closed };
        setCurrent(Object.assign({}, current, update));
    }

    const handleShowAllAssigned = (e) => {
        const update = { 'showAllAssigned': !current.showAllAssigned };
        setCurrent(Object.assign({}, current, update));
    }

    const search = (e) => {
        e.preventDefault();
        onSearch(current);
    }

    function clearSearch(e) {
        e.preventDefault();
        setCurrent({});
        setResetCt(resetCt + 1);
        onClearSearch();
    }
    const products = institutions.reduce((p, c) => p.concat(c.products.map(prod => ({ id: prod.id, option: `${prod.name} (${c.name})` }))), []);
    
    return (<Form onSubmit={search}>
        <Card className="search-bar">
            <CardHeader>Filtrar Casos</CardHeader>
            <CardBody>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="case">Id Caso</Label>
                            <Input key={resetCt} value={current.case} onChange={handleChange} id="case" name="case" type="number" />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="all">Visibilidad de Casos</Label>
                            <Input key={resetCt} value={current.all} onChange={handleChange} id="all" name="all" type="select">
                                <option value="">Mis casos</option>
                                <option value="1">Todos los casos</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="branch">Oficina</Label>
                            <Input value={current.branch} onChange={handleChange} id="branch" name="branch" type="select">
                                <option value="">Todos</option>
                                {(branches || []).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="client">Cliente</Label>
                            <Input key={resetCt} value={current.client} onChange={handleChange} id="client" name="client" type="text" />
                        </FormGroup>
                    </Col>
                    {permissions.limitColumnAndFilterView ? (<></>): <><Col md={4}>
                        <FormGroup>
                            <Label for="clienType">Tipo de Cliente</Label>
                            <Input key={resetCt} value={current.clientType} onChange={handleChange} id="clientType" name="clientType" type="select">
                                <option value="">Todos</option>
                                {(clientTypes || []).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col><Col md={4}>
                            <FormGroup>
                                <Label for="product">Producto</Label>
                                <Input key={resetCt} value={current.product} onChange={handleChange} id="product" name="product" type="select">
                                    <option value="">Todos</option>
                                    {(products || []).map((elem, idx) => <option key={elem.id} value={elem.id}>{elem.option}</option>)}
                                </Input>
                            </FormGroup>
                        </Col></>}
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="after">Desde</Label>
                            <Input key={resetCt} value={current.after} onChange={handleChange} id="after" name="after" type="date" />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="before">Hasta</Label>
                            <Input key={resetCt} value={current.before} onChange={handleChange} id="before" name="before" type="date" />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="stage">Etapa</Label>
                            <Input key={resetCt} value={current.stage} onChange={handleChange} id="stage" name="stage" type="select">
                                <option value="">Todos</option>
                                {(stages || []).map((elem, idx) => <option value={elem.name}>{elem.title}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="role">Encargado Caso</Label>
                            <Input key={resetCt} value={current.owner} onChange={handleChange} id="owner" name="owner" type="select">
                                <option value="">Todos</option>
                                {(owners || []).sort((a,b) => (a.completeName > b.completeName) ? 1 : ((b.completeName > a.completeName) ? -1 : 0)).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.firstName} {ct.lastName}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    {permissions.limitColumnAndFilterView ? (<></>): <Col md={4}>
                        <FormGroup>
                            <Label for="institution">Entidad</Label>
                            <Input key={resetCt} value={current.institution} onChange={handleChange} id="institution" name="institution" type="select">
                                <option value="">Todos</option>
                                {(institutions || []).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>}
                    <Col md={4}>
                        <FormGroup>
                            <Label for="role">Rol</Label>
                            <Input key={resetCt} value={current.role} onChange={handleChange} id="role" name="role" type="select">
                                <option value="">Todos</option>
                                {(roles || []).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.displayName}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                {filters.disableShowAllAssigned ? (<>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="role">Usuario</Label>
                            <Input key={resetCt} value={current.user} onChange={handleChange} id="user" name="user" type="select">
                                <option value="">Todos</option>
                                {(users || []).sort((a,b) => (a.completeName > b.completeName) ? 1 : ((b.completeName > a.completeName) ? -1 : 0)).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.firstName} {ct.lastName}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={4} className="mb-3">
                        <FormGroup check>
                            <Input
                                id="completed"
                                type="checkbox"
                                checked={current.completed}
                                onChange={handleCompleted}
                                name="completed"
                            />
                            <Label check for="completed" >Casos Completado</Label>
                        </FormGroup>
                    </Col>
                    <Col md={4} className="mb-3">
                        <FormGroup check>
                            <Input
                                id="closed"
                                type="checkbox"
                                checked={current.closed}
                                onChange={handleClosed}
                                name="closed"
                            />
                            <Label check for="closed" >Caso Desestimado</Label>
                        </FormGroup>
                    </Col>
                </Row>
                </>): (<>
                <Row>
                    <Col md={4} className="mb-3">
                        <FormGroup check>
                            <Input
                                id="completed"
                                type="checkbox"
                                checked={current.completed}
                                onChange={handleCompleted}
                                name="completed"
                            />
                            <Label check for="completed" >Casos Completado</Label>
                        </FormGroup>
                    </Col>
                    <Col md={4} className="mb-3">
                        <FormGroup check>
                            <Input
                                id="closed"
                                type="checkbox"
                                checked={current.closed}
                                onChange={handleClosed}
                                name="closed"
                            />
                            <Label check for="closed" >Caso Desestimado</Label>
                        </FormGroup>
                    </Col>
                        <Col md={4} className="mb-3">
                        <FormGroup check>
                            <Input
                                id="showAllAssigned"
                                type="checkbox"
                                checked={current.showAllAssigned}
                                onChange={handleShowAllAssigned}
                                name="showAllAssigned"
                            />
                            <Label check for="showAllAssigned" >Mostrar Casos Trabajados</Label>
                        </FormGroup>
                    </Col>
                </Row>
                </>)}
                <Row>
                    <Col>
                        <Button color="primary" type="submit">Filtrar</Button>
                        {" "}
                        <Button color="secondary" onClick={clearSearch}>Limpiar Filtro</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Form>);
}

export default CaseSearchBar;