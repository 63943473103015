

export function invertMap(map, context) {
    if (!Array.isArray(map)) {
        map = Object.entries(map);
    }

    return map.map(([key, value]) => {
        const pv = parseMapKey(value);

        if (pv.type === "array") {
            const imap = invertMap(pv.map);
            return [
                pv.array,
                {
                    array: key,
                    map: imap,
                    initialObject: mapObject(pv.initialObject, pv.map, undefined, context)
                }
            ]
        }

        return [value, key];
    });
}


export function interpolate(text, scope) {
    return text.replace(/\$(([a-zA-Z]+)|{([\w.]+)})/g, (...m) => getObject(scope, m[2] || m[3]) || '');
};


export function interpolateMap(map, scope) {
    map = Array.isArray(map) ? map : Object.entries(map);
    return map.map(kv => kv.map(k => {
        k = parseMapKey(k);
        switch (k.type) {
            case "path": return { type: "path", value: interpolate(k.value, scope) };
            case "const": return k;
            case "array": return {
                type: "array",
                array: interpolate(k.array, scope),
                map: interpolateMap(k.map, scope),
                initialObject: k.initialObject,
            };
            default: return k;
        }
    }));
};


export function mapObject(object, map, initialObject, context) {
    if (!Array.isArray(map)) {
        map = Object.entries(map);
    }

    const result = initialObject || {};

    map.forEach(([key, value]) => {
        if (!key || !value) return;

        let pk = parseMapKey(key);
        const pv = parseMapKey(value);

        while (pk.type === 'default' || pk.type === 'writeonly') pk = pk.path;

        if (pk.type !== 'path' && pk.type !== 'default') {
            return; // only perform assign for path keys
        }

        const mappedValue = resolvePath(pv, pk, object, initialObject, context);

        if (mappedValue !== null && mappedValue !== undefined) {
            setObject(result, pk.value, mappedValue);
        }
    });

    return result;
}


export const CUSTOM_PATHS = {};


export function resolvePath(pv, pk, object, initialObject, context){
    let result = null;
    switch (pv.type) {
        case 'path':
            result = getObject(object, pv.value);
        break;
        case 'default':
            result = resolvePath(pv.path, pk, object, initialObject, context);
            if (!result) {
                result = resolvePath(pv.default, pk, object, initialObject, context);;
            }
        break;
        case 'interpolate':
            result = interpolate(pv.value, {object, initialObject, context});;
        break;
        case 'context':
            result = getObject(context, pv.value);
        break;
        case 'writeonly':
            result = null;
        break;
        case 'readonly':
            result = resolvePath(pv.value, pk, object, initialObject, context)
        break;
        case 'array': {
            let array = getObject(object, pv.array);
            if (array === undefined) { array = []; }
            else if (!Array.isArray(array)) { array = [array]; }
            let ioArray = getObject(initialObject, pk.value) || [];
            if (ioArray === undefined) { ioArray = []; }
            else if (!Array.isArray(ioArray)) { ioArray = [ioArray]; }

            while (array.length < ioArray.length) {
                array.push(undefined);
            }

            result = array.map(
                (item, idx) => mapObject(item, pv.map, ioArray[idx], context)
            ).filter(item => !isEmptyObject(item));

            if (result.length === 0) {
                result = null;
            }
        } break;
        default:
            if (CUSTOM_PATHS[pv.type]) {
                result = CUSTOM_PATHS[pv.type].resolve(pv, pk, object, initialObject, context);
            } else {
                result = pv.value;
            }
        break;
    }
    return result;
} 


export function parseMapKey(mapKey) {
    if (typeof (mapKey) === 'string') {
        return { type: 'path', value: mapKey };
    }

    if (mapKey.const) {
        return { type: 'const', value: mapKey.const };
    } else if (mapKey.interpolate) {
        return { type: 'interpolate', value: mapKey.interpolate };
    } else if (mapKey.context) {
        return { type: 'context', value: mapKey.context };
    } else if (mapKey.readonly) {
        return { type: 'readonly', value: parseMapKey(mapKey.readonly) };
    } else if (mapKey.writeonly) {
        return { type: 'writeonly', value: parseMapKey(mapKey.writeonly) };
    } else if (mapKey.default) {
        return { type: 'default', default: parseMapKey(mapKey.default), path: parseMapKey(mapKey.path) };
    } else if (mapKey.array && mapKey.map) {
        return { type: 'array', array: mapKey.array, map: mapKey.map, initialObject: mapKey.initialObject };
    } else {
        return Object.keys(mapKey).reduce((_, type) => {
            if (CUSTOM_PATHS[type]) return { type, ...CUSTOM_PATHS[type].parse(mapKey)};
            return _;
        }, null) || mapKey;
    }
}


export function parsePath(path) {
    if (path === "") return [];
    if (path.type) {
        if (path.type === 'path') {
            path = path.value;
        } else {
            throw new Error("given path key is not a path key.");
        }
    }

    if (typeof path.split !== 'function') {
        console.log("parsePath", path);
    }
    const parsed = path.split(".").reduce((_, component) => {
        try {
            component.split('[').forEach((item, index) => {
                const attr = item.split(']')[0];
                const accessType = index ? 'array' : 'object';
                _.push({ accessType, attr });
            })
            return _;
        } catch (err) {
            console.log('ERROR WITH SPLIT', err);
            throw err;
        }
    }, []);

    parsed.forEach((component, index) => {
        component.nextAccessType = (parsed[index + 1] || {}).accessType;
    })

    return parsed;
}


export function parseRelPath(relpath){
    if (relpath.valid) return relpath;
    const m  = /^((\$\.)|(\.+))?(\w+(\.\w+)*)?$/.exec(relpath);
    if (m) {
        const p2 = m[4] ? m[4].split('.') : [];
        if (m[2]) {
            return {valid: true, absolute: true, path: p2};
        } else {
            return {valid: true, relative: true, path: p2, ancestor: m[3] ? m[3].length - 1 : 0};
        }
    } else {
        return {valid: false};
    }
}


export function concatenatePaths(...paths) {
    if (!paths.length) { return ''; }
    return (paths || []).reduce((_, relpath) => {
        if (relpath === undefined || relpath === null || relpath === '') return _;
        const parsed = parseRelPath(relpath);
        if (parsed.valid) {
            if (parsed.absolute) return parsed.path;
            let i = parsed.ancestor;
            while (i > 0 && _.length > 0) {
                _.pop();
                i -= 1;
            }
            _.push(...parsed.path);
        }
        return _;
    }, []).join('.');
}


export function getObject(object, path) {
    const components = parsePath(path);

    while (components.length) {
        const { attr } = components.shift();
        if (object !== undefined && object !== null) {
            object = object[attr];
        }
    }

    return object;
}


export function setObject(object, path, value) {
    const components = parsePath(path);
    const lastComponent = components.pop();

    while (components.length) {
        const { attr, nextAccessType } = components.shift();
        if (object[attr] === undefined || object[attr] === null) {
            if (nextAccessType === 'array') {
                object[attr] = [];
            } else { // if(nextAccessType === 'object'){
                object[attr] = {};
            }
        }
        object = object[attr];
    }

    const { attr } = lastComponent;
    object[attr] = value;
}


function isObject(value) {
    return value !== null && !Array.isArray(value) && (typeof value === 'object');
}

function isEmptyObject(value) {
    return isObject(value) && Object.keys(value).length === 0;
}
