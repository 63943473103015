import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const SettingsManagementApi = {
    async setSLASetting(data) {
        return checkErrors(await fetchApi.api.post('manage/settings/sla', { data }));
    },
    async setAlarms(data) {
        return checkErrors(await fetchApi.api.post('manage/settings/alarms', { data }));
    },

}


export default SettingsManagementApi;
