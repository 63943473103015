import React, { useState, useMemo, useRef, useContext } from "react";
import {
    FormGroup,
    Table
} from "reactstrap";
import { getObject } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import xor from "../../util/xor";
import Jnx from "../../util/jnx";


function HideObjectField(props) {
    const {
        formContext,
        idPrefix, idSeparator,
        registry,
        disabled, readonly,
        hideError, formData,
        onBlur, onFocus,
        onChange,
        uiSchema, errorSchema, idSchema, schema,
    } = props;
    const { sideChannel } = formContext;
    const { description, title, properties: childFields } = schema;
    const { fields: { SchemaField } } = registry;
    const { $id } = idSchema;
    const {
        'ui:className': className,
        'ui:newSection': uiNewSection,
    } = uiSchema;

    const [wasPropertyKeyModified, setWasPropertyKeyModified] = useState();

    const [rootFormData, formObject] = useSideChannelSubscription(sideChannel, [0, 1]) || [{}];

    const formDataRef = useRef();
    formDataRef.current = formData;

    function onKeyChange(oldValue, value, errorSchema) {
        if (oldValue === value) {
            return;
        }

        value = getAvailableKey(value, formData);
        const _formData = formDataRef.current || {};
        const newKeys = { [oldValue]: value };
        const renamed = Object.keys(_formData).reduce((_, key) => {
            _[newKeys[key] || key] = _formData[key];
            return _;
        }, {});

        setWasPropertyKeyModified(true);

        onChange(
            renamed,
            errorSchema &&
            props.errorSchema &&
            { ...props.errorSchema, [value]: errorSchema }
        );
    }

    function onDropPropertyClick(key) {
        return (event) => {
            event.preventDefault();
            const copiedFormData = { ...formDataRef.current };
            delete copiedFormData[key];
            onChange(copiedFormData);
        };
    }

    function onPropertyChange(name, value, propErrorSchema) {
        const newFormData = { ...formDataRef.current };
        newFormData[name] = value;
        onChange(
            newFormData,
            propErrorSchema && errorSchema &&
            { ...errorSchema, [name]: propErrorSchema }
        );
    }

    const route = useMemo(() => getPathFromId($id), [$id]);

    const bindings = useMemo(() => ({
        formContext,
        rootFormData,
        formObject
    }), [
        formContext,
        rootFormData,
        formObject
    ]);

    const showIfJnxs = useMemo(() => Object.keys(childFields).reduce((_, name) => {
        const childUISchema = uiSchema[name] || {};
        const showIf = childUISchema['ui:showIf'];

        if (showIf) {
            _[name] = [{ jnx: compileShowIf(showIf) }, true];
        }

        return _;
    }, {}), [childFields])

    const [visibleCount, hide] = useMemo(() => {
        const {
            showCount,
            nonEmptyCount,
            emptyChecks,
            aloneChecks
        } = Object.keys(childFields).reduce((_, name) => {
            const childUISchema = uiSchema[name] || {};
            const [
                hideAttr, invertLogic
            ] = showIfJnxs[name] || (
                childUISchema['joli:hideAttr'] ? [childUISchema['joli:hideAttr'], false] : [childUISchema['joli:showAttr'], true]
            );

            if (hideAttr === 'ifAlone') {
                _.aloneChecks.push([name, invertLogic]);
            } else if (hideAttr && hideAttr.ifEmpty) {
                const isEmpty = xor(!getObject(rootFormData, hideAttr.ifEmpty), invertLogic);
                _.emptyChecks[name] = isEmpty;
                _.nonEmptyCount += !isEmpty;
                _.showCount += !isEmpty;
            } else if (hideAttr && hideAttr.caseData) {
                const hide = xor(formObject && formObject.id ? hideFieldBaseOnData(hideAttr.caseData, formObject) : true, invertLogic);
                _.emptyChecks[name] = hide;
                _.nonEmptyCount += !hide;
                _.showCount += !hide;
            } else if (hideAttr && hideAttr.formData) {
                const hide = xor(rootFormData ? hideFieldBaseOnData(hideAttr.formData, rootFormData) : true, invertLogic);
                _.emptyChecks[name] = hide;
                _.nonEmptyCount += !hide;
                _.showCount += !hide;
            } else if (hideAttr && hideAttr.formContext) {
                const hide = xor(rootFormData ? hideFieldBaseOnData(hideAttr.formContext, formContext) : true, invertLogic);
                _.emptyChecks[name] = hide;
                _.nonEmptyCount += !hide;
                _.showCount += !hide;
            } else if (hideAttr && hideAttr.jnx) {
                const hide = xor(evalShowIf(rootFormData, route, hideAttr.jnx, bindings), invertLogic);
                _.emptyChecks[name] = hide;
                _.nonEmptyCount += !hide;
                _.showCount += !hide;
            } else if (childUISchema['ui:hideOnEmpty'] && !(formData || {})[name]) {
                _.emptyChecks[name] = true;
            } else if (hideAttr === 'hide') {
                _.emptyChecks[name] = true;
            } else {
                _.showCount += 1;
            }

            return _;
        }, { showCount: 0, nonEmptyCount: 0, emptyChecks: {}, aloneChecks: [] });

        const isEmpty = (nonEmptyCount === 0);

        return [
            showCount,
            {
                ...emptyChecks,
                ...aloneChecks.reduce((_, [c, inverLogic]) => { _[c] = xor(isEmpty, inverLogic); return _; }, {}),
            }
        ]
    }, [childFields, rootFormData, uiSchema, showIfJnxs]);

    return visibleCount === 0 ? null : (
        <FormGroup className={className}>
            {uiNewSection ? (
                <>
                    <legend id={`${$id}__title`}>{title}</legend>
                    {description ? <p className="field-description">{description}</p> : null}
                </>
            ) : (
                <>
                    <legend>{title}</legend>
                    {description ? <p className="field-description">{description}</p> : null}
                </>
            )}
            <div className="prop-fields">
                {Object.entries(childFields).map(([name, fieldDef]) => hide[name] ? null : (
                    <SchemaField
                        key={name}
                        name={name}
                        required={isRequired(name, schema, hide[name], uiSchema)}
                        schema={fieldDef}
                        uiSchema={uiSchema[name]}
                        errorSchema={errorSchema[name]}
                        idSchema={idSchema[name]}
                        idPrefix={idPrefix}
                        idSeparator={idSeparator}
                        formData={(formData || {})[name]}
                        wasPropertyKeyModified={wasPropertyKeyModified}
                        onKeyChange={(value, errorSchema) => onKeyChange(name, value, errorSchema)}
                        onChange={(value, errorSchema) => onPropertyChange(name, value, errorSchema)}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        registry={registry}
                        disabled={disabled}
                        readonly={readonly}
                        hideError={hideError}
                        onDropPropertyClick={onDropPropertyClick}
                    />
                ))}
            </div>
        </FormGroup>
    );
}


function hideFieldBaseOnData(attr, data) {
    if (!data) return true;
    const conditions = Object.entries(attr).map(([attrName, attrVal]) => {
        const fieldValue = getObject(data, attrName);

        if (Array.isArray(fieldValue)) {
            if ((attrVal || {}).intersects) {
                return attrVal.intersects.some(val => fieldValue.indexOf(val) !== -1);
            }

            if (attrName === 'productInstitutions') {
                return !(fieldValue.some(x => x === attrVal));
            }

            return fieldValue.some(x => x === attrVal);

        } else if (Array.isArray(attrVal)) {
            return attrVal.some(x => x === fieldValue);

        } else if (fieldValue !== undefined) {
            return fieldValue === attrVal;
        } else {
            return false;
        }
    });

    return conditions.some(cond => cond);
}

export function getPathFromId(id) {
    return id.split('_').slice(1).join('.');
}

export function compileShowIf(objectShowIf) {
    return (objectShowIf instanceof Jnx) ? objectShowIf : new Jnx(objectShowIf);
}

export function evalShowIf(formData, route, objectShowIf, bindings) {
    return compileShowIf(objectShowIf).eval(formData, route, bindings);
}


function getAvailableKey(preferredKey, formData) {
    let index = 0;
    let newKey = preferredKey;

    while (Object.prototype.hasOwnProperty.call(formData, newKey)) {
        index += 1;
        newKey = "".concat(preferredKey, "-").concat(index);
    }

    return newKey;
}


function isRequired(name, schema, hideField, uiSchema) {
    const childUISchema = uiSchema[name] || {};
    return (Array.isArray(schema.required) && schema.required.indexOf(name) !== -1 && !hideField) || (childUISchema['joli:required'] && !hideField);
}


export default HideObjectField;