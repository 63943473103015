import { readOnlyFileUI } from "./commons";

const form = {
    title: "Carga Contratos",
    displayName: 'NonCreditContractsDownloadBusinessClient',
    page: 'non-credit-contracts-download-business-client',
};


form.schema = {
    title: "Solicitud de Vinculación Cliente Empresa",
    type: "object",
    properties: {
        "enviarCorreoCliente": {
            "buttonText": "Enviar Correo a Cliente",
            subject: "Documentación completada para revisión",
            body: "Buenas tardes Sr/Sra. {nombreCliente} \n\nHemos recibido la documentación para continuar el proceso de vinculación a JMMB. Le estaremos contactando para la firma de los documentos. \n\nFavor indicarme que día y hora tienes disponible para reunirnos y proceder a la firma de la documentación.",
            type: "null",
        },
        "dct": {
            title: "Documentos Cliente Titular",
            description: "Documentos a firmar por el cliente",
            type: "object",
            properties: {
                "titlePuesto": { title: "Puesto", type: "null", const: null },
                "titleBanco": { title: "Banco", type: "null", const: null },
                "titleSafi": { title: "Safi", type: "null", const: null },
                "informeInversionistaPuesto": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoPuesto": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formularioClientePuesto": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioW8BeneBanco": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
                "formularioW8BenePuesto": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
                "formularioW8BeneSAFI": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
                "informeInversionistaSafi": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoSafi": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formularioClienteSafi": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioClienteBanco": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "cartaCuentaCelvadom": {
                    title: "Carta Autorización Apertura Cuenta Celvadom",
                    type: "number",
                },
                "contratoCuentaCelvadom": {
                    title: "Contrato Apertura Cuenta Valores Celvadom",
                    type: "number",
                },
                // "informeOng": {
                //     title: "Informe ONG",
                //     type: "number",
                // },
                // "informePersJuridicaAltoRiesgo": {
                //     title: "Informe Persona Jurídica Alto Riesgo",
                //     type: "number",
                // },
                // "informePersJuridicaAltoRiesgoConsorcios": {
                //     title: "Informe Persona Jurídica Alto Riesgo Consorcios",
                //     type: "number",
                // },
                // "informePersJuridicaAltoRiesgoFideicomisos": {
                //     title: "Informe Persona Jurídica Alto Riesgo Fideicomisos",
                //     type: "number",
                // },
                // "informePersJuridicaAltoRiesgoFondosInvers": {
                //     title: "Informe Persona Jurídica Alto Riesgo Fondos de Inversión",
                //     type: "number",
                // },
                "registroFirmaPersJuridicasBanco": {
                    title: "Registro Firma Persona Jurídicas",
                    type: "number",
                },
                "registroFirmaPersJuridicasPuesto": {
                    title: "Registro Firma Persona Jurídicas",
                    type: "number",
                },
                "registroFirmaPersJuridicasSafi": {
                    title: "Registro Firma Persona Jurídicas",
                    type: "number",
                },
                "solicitudPrestamo": {
                    title: "Solicitud de Préstamo",
                    type: "number",
                },
                "formularioSujetoObligado": {
                    title: "Formulario Sujeto Obligado No Financiero Empresa",
                    type: "number",
                },
            }
        },
    },
    required: [],
};



form.uiSchema = {
    "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "dct": {
        "classNames": "commercialNonCCD-dct",
        "ui:field": "HideObjectField",
        "titlePuesto": { classNames: "title                   puesto" },
        "titleBanco": { classNames: "title                   banco" },
        "titleSafi": { classNames: "title                   safi" },

        "formularioClienteBanco": { ...readOnlyFileUI, classNames: "tarjetaFirma       banco" },
        "formularioClientePuesto": { ...readOnlyFileUI, classNames: "tarjetaFirma       puesto" },
        "formularioClienteSafi": { ...readOnlyFileUI, classNames: "tarjetaFirma       safi" },

        "registroFirmaPersJuridicasBanco": { ...readOnlyFileUI, classNames: "formularioCliente       banco" },
        "registroFirmaPersJuridicasPuesto": { ...readOnlyFileUI, classNames: "formularioCliente       puesto" },
        "registroFirmaPersJuridicasSafi": { ...readOnlyFileUI, classNames: "formularioCliente       safi" },

        "formularioW8BeneBanco": { ...readOnlyFileUI, classNames: "formularioW8Ben       banco" },
        "formularioW8BenePuesto": { ...readOnlyFileUI, classNames: "formularioW8Ben       puesto" },
        "formularioW8BeneSAFI": { ...readOnlyFileUI, classNames: "formularioW8Ben       safi" },

        "cartaCuentaCelvadom": { ...readOnlyFileUI, classNames: "cartaAutorizacionCuenta      puesto" },
        "contratoCuentaCelvadom": { ...readOnlyFileUI, classNames: "contratoCuenta       puesto" },

        "informeInversionistaPuesto": { ...readOnlyFileUI, classNames: "informeInversionista    puesto" },
        "informeInversionistaSafi": { ...readOnlyFileUI, classNames: "informeInversionista    safi" },

        "contratoMarcoPuesto": { ...readOnlyFileUI, classNames: "contratoMarco           puesto" },
        "contratoMarcoSafi": { ...readOnlyFileUI, classNames: "contratoMarco           safi" },

        "solicitudPrestamo": { ...readOnlyFileUI, classNames: "formularioCliente       banco" },

        "formularioSujetoObligado": { ...readOnlyFileUI, classNames: "formularioSujetoObligado puesto" },
        
        // MOVE TO DUE DILIGENCE DOC GENERATION =====================
        // "informeOng": { ...readOnlyFileUI, classNames: "formularioMoneyline       safi" },
        // "informePersJuridicaAltoRiesgo": { ...readOnlyFileUI, classNames: "informePersJuridica        puesto" },
        // "informePersJuridicaAltoRiesgoConsorcios": { ...readOnlyFileUI, classNames: "informePersJuridica2       puesto" },
        // "informePersJuridicaAltoRiesgoFideicomisos": { ...readOnlyFileUI, classNames: "informePersJuridica3       puesto" },
        // "informePersJuridicaAltoRiesgoFondosInvers": { ...readOnlyFileUI, classNames: "informePersJuridica4       puesto" },
        // ========================================================
        
    },
};

form.objectMap = {

    "generatedFiles.fullkyc_0_CartaAutorizaciónAperturaCuentaCelvadomEmpresa": "dct.cartaCuentaCelvadom",
    "generatedFiles.fullkyc_0_ContratoAperturaCuentasValoresCelvadomEmpresa": "dct.contratoCuentaCelvadom",
    "generatedFiles.fullkyc_0_ContratoMarcoPuesto": "dct.contratoMarcoPuesto",
    "generatedFiles.fullkyc_0_ContratoMarcoSafi": "dct.contratoMarcoSafi",
    "generatedFiles.fullkyc_0_FormularioConozcaSuClienteBanco": "dct.formularioClienteBanco",
    "generatedFiles.fullkyc_0_FormularioConozcaSuClientePuesto": "dct.formularioClientePuesto",
    "generatedFiles.fullkyc_0_FormularioConozcaSuClienteSafi": "dct.formularioClienteSafi",
    "generatedFiles.fullkyc_0_InformePerfilDelInversionistaComercialPuesto": "dct.informeInversionistaPuesto",
    "generatedFiles.fullkyc_0_InformePerfilInversionistaPersonaComercialSafi": "dct.informeInversionistaSafi",
    "generatedFiles.fullkyc_0_IrsW8benEBanco": "dct.formularioW8BeneBanco",
    "generatedFiles.fullkyc_0_IrsW8benEPuesto": "dct.formularioW8BenePuesto",
    "generatedFiles.fullkyc_0_IrsW8benESafi": "dct.formularioW8BeneSAFI",
    "generatedFiles.fullkyc_0_SolicitudPrestamoComercial": "dct.solicitudPrestamo",

    "generatedFiles.fullkyc_0_RegistroDeFirmasClientesPersonasJurídicasBanco": "dct.registroFirmaPersJuridicasBanco",
    "generatedFiles.fullkyc_0_RegistroDeFirmasClientesPersonasJurídicasPuesto": "dct.registroFirmaPersJuridicasPuesto",
    "generatedFiles.fullkyc_0_RegistroFirmasClientesPersonasJurídicasSafi": "dct.registroFirmaPersJuridicasSafi",

    "generatedFiles.fullkyc_0_FormularioSujetoObligadoNoFinanciero": "dct.formularioSujetoObligado",

    // MOVE TO DUE DILIGENCE DOC GENERATION =====================
    // "generatedFiles.fullkyc_0_InformeOng": "dct.informeOng",
    // "generatedFiles.fullkyc_0_InformePersonaJurídicaAltoRiesgo": "dct.informePersJuridicaAltoRiesgo",
    // "generatedFiles.fullkyc_0_InformePersonaJurídicaAltoRiesgoConsoricios": "dct.informePersJuridicaAltoRiesgoConsorcios",
    // "generatedFiles.fullkyc_0_InformePersonaJurídicaAltoRiesgoFideicomisos": "dct.informePersJuridicaAltoRiesgoFideicomisos",
    // "generatedFiles.fullkyc_0_InformePersonaJurídicaAltoRiesgoFondosDeInversion": "dct.informePersJuridicaAltoRiesgoFondosInvers",
    // ========================================================
    
    
}


export default form;