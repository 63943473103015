import 'bootstrap/dist/css/bootstrap.css';
import './scss/style.scss';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import App from './App';
import registerServiceWorker from './registerServiceWorker';
import fetchApi from './util/fetchApi';
import { AuthProvider, getToken } from './context/AuthContext';

import { messages } from './locales/en/messages';
import { ENDPOINT, SENTRY_DSN } from './constants';
import { useZohoDeskWidget } from './util/useZohoDeskWidget';


i18n.load('en', messages);
i18n.activate('en');


fetchApi.configure({
  defaults: { baseurl: ENDPOINT },
  verbs: ['get', 'post', 'put', 'delete'],
  helpers: {
    api: (url, options, fetchApi) => fetchApi(url, {
      auth: { type: 'Bearer', token: getToken() },
      json: true,
      ...options
    }, fetchApi),
    lookup: (url, options, fetchApi) => fetchApi(`form-lookup/${url}`, {
      auth: { type: 'Bearer', token: getToken() },
      json: true,
      useCache(url, options) { return options.method === 'get'},
      ...options
    }, fetchApi)
  }
});


if (SENTRY_DSN) {
  Sentry.init({
      dsn: SENTRY_DSN,
      // integrations: [new BrowserTracing()],
      // tracesSampleRate: 1.0,
  });  
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');


function MainApp() {
  useZohoDeskWidget();

  return (
    <I18nProvider i18n={i18n}>
      <BrowserRouter basename={baseUrl}>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </I18nProvider>
  )
}

ReactDOM.render(
  <MainApp />,
  rootElement);

registerServiceWorker();

