import dropdownOptions from '../../util/dropdownOptions';
import {
    clientTypeId,
    tipoIdentificacionPersonal,
    preferredBranchId,
    boolYesNoType,
    leadChannel,
} from '../commons';

const form = {
    title: "Orientación",
    displayName: 'ShortKYCPersonalClient',
    page: 'short-kyc-personal',
    commentFieldsMap: {
        "Facilidades Solicitadas": "caseLoanInfo.comments",
    },
};


const prestamosSchema = {
    title: " ",
    description: "Préstamos",
    type: "object",
    properties: {
        "montoPrestamo": {
            title: "Monto deseado de préstamo",
            type: "number",
        },
        "destinoFondos": {
            title: "Destino de los fondos",
            type: "string",
        }
    },
}

const solicitudPrestamoSchema = {
    title: " ",
    description: "Solicitud Préstamo",
    type: "object",
    properties: {
        "facilidadesSolicitadas": {
            title: " ",
            description: "Facilidades solicitadas",
            type: "object",
            properties: {
                "proposito": {
                    title: "Propósito",
                    type: "string",
                },
                "descripcionFacilidades": {
                    title: " ",
                    description: "Descripción de facilidades (sólo aplica para líneas de crédito)",
                    type: "array",
                    maxItems: 5,
                    "items": {
                        "type": "object",
                        properties: {
                            "numFacilidad": {
                                title: "No. de Facilidad",
                                type: "string",
                            },
                            "descripcion": {
                                title: "Descripción",
                                type: "string",
                            },
                            "tipoFacilidad": {
                                title: "Tipo de Facilidad",
                                type: "string",
                                enumNames: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nómina",
                                    "Hipotecario",
                                ],
                                enum: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nomina",
                                    "Hipotecario",
                                ],
                            },
                            "montoAprobado": {
                                title: "Monto Aprobado (si aplica)",
                                type: "number",
                            },
                            "montoUtilizado": {
                                title: "Monto Utilizado (si aplica)",
                                type: "number",
                            },
                            "montoPropuesto": {
                                title: "Monto Propuesto",
                                type: "number",
                            },
                            "tasa": {
                                title: "Tasa",
                                type: "number",
                            },
                            "formaPago": {
                                title: "Forma de Pago",
                                type: "string",
                                enumNames: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                                enum: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                            },
                            "cuotaEstimada": {
                                title: "Cuota Estimada",
                                type: "string",
                            },
                            "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
                        },
                    },
                },
                "incluyeGarantia": { title: "¿Incluye garantía?", ...boolYesNoType },
                "comentarios": {
                    title: "Comentarios",
                    type: "string",
                }
            },
            dependencies: {
                "incluyeGarantia": {
                    allOf: [{
                        if: { properties: { "incluyeGarantia": { const: true } } },
                        then: {
                            properties: {
                                "incluyeGarantiaSolidaria": { title: "¿Incluye garantía solidaria?", ...boolYesNoType },
                                "tipoGarantia": {
                                    title: "Tipo de Garantía",
                                    type: "string",
                                    enumNames: ["Garantía Solidaria", "Descuento de Nómina", "Hipotecario", "Prendaria", "Múltiples", "Otra"],
                                    enum: ["Garantia Solidaria", "Descuento de Nomina", "Hipotecario", "Prendaria", "Multiples", "Otra"],
                                },
                            }
                        }
                    }]
                },
            }
        },
    },
}
const financialCertSchema = {
    title: " ",
    description: "Certificado Financiero",
    type: "object",
    properties: {
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Certificados en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        }, required: ["origenFondosRDOtro"]

                    }
                }
            ]
        },
    },
    required: ["inversionEstimadaRD", "origenFondosRD", "tasaCambio"]
}

const savingsAccountSchema = {
    title: " ",
    description: "Cuenta de Ahorro",
    type: "object",
    properties: {
        "depositoEstimadoRD": {
            title: "Monto estimado primer depósito en RD",
            type: "number",
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
    },
    dependencies: {
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["depositoEstimadoRD", "origenFondosRD"]
}

const inversionesSchema = {
    title: " ",
    description: "Inversiones en Puesto",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión Puesto en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para Puesto en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Puesto en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para Puesto en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const safiSchema = {
    title: " ",
    description: "Inversiones en SAFI",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión SAFI en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para SAFI en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión SAFI en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para SAFI en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const clientSchema = {
    title: "Información Cliente",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombres",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
        },
        tipoIdentificacionPersonal,
        "sexo": {
            title: "Sexo",
            type: "string",
            enum: ["Masculino", "Femenino"],
            // oneOf: [
            //     { title: "Masculino", const: 1 },
            //     { title: "Femenino", const: 2 },
            // ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            enumNames: ["Soltero", "Casado", "Unión Libre"],
            enum: ["Soltero", "Casado", "Union Libre"]
            // oneOf: [
            //     { title: "Soltero", const: 1 },
            //     { title: "Casado", const: 2 },
            //     { title: "Unión Libre", const: 3 },
            // ],
        },
        "celular": {
            title: "Celular",
            type: "string",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
        },
        "otro": {
            title: "Otro",
            type: "string",
        },
        "comentarios": leadChannel
    },
    // required: ["nombre", "apellidos", "email", "tipoIdentificacion",
    //     "numIdentificacion", "sexo", "estadoCivil",
    //     "celular"
    // ],
    dependencies: {
        "tipoIdentificacionPersonal": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",

                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionCedula"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 2,
                        },
                        "numIdentificacionPasaporte": {
                            title: "No. de identificación Pasaporte",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionPasaporte"]
                },
                {
                    properties: {
                        "tipoIdentificacionPersonal": {
                            const: 3,
                        },
                        "numIdentificacionIdExtranjero": {
                            title: "No. de identificación Id Extranjero",
                            type: "string",
                        },
                        "buscarDataPersonal":{
                            title:"Buscar datos de usuario por ID",
                            type:"string",
                        },
                    },
                    required: ["numIdentificacionIdExtranjero"]
                },
            ],
        }
    },
}

const clientAditionalInfoSchema = {
    title: " ",
    type: "object",
    properties: {
        "fechaNacimiento": {
            title: "Fecha de Nacimiento",
            type: "string",
            format: "date",
        },
        "lugarNacimiento": {
            title: "Lugar de Nacimiento",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidad": {
            title: "Nacionalidad",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidadOtroPais": { title: "¿Tiene nacionalidad o ciudadanía de otro país?", ...boolYesNoType },
        "direccionResidencia": {
            title: "Dirección de residencia",
            type: "string",
        },
        "sector": {
            title: "Sector",
            type: "string",
        },
        "ciudad": {
            title: "Ciudad",
            type: "string",
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "residenteRD": { title: "¿Es residente de la República Dominicana?", ...boolYesNoType, default: true },
        "residenciaFisicaUSA": { title: "¿Es Estados Unidos su país de residencia física?", ...boolYesNoType },
        "zonaGeograficaNacional": {
            title: "Zona Geografica Nacional",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional
        },
        "estatusMigratorio": {
            title: "¿Tiene algún estatus migratorio en los Estados Unidos?",
            type: "string",
            enumNames: ["No", "Ciudadanía", "Residencia Fiscal", "Residencia Permanente"],
            enum: ["No", "Ciudadania", "Residencia Fiscal", "Residencia Permanente"],
            // oneOf: [
            //     {
            //         title: "No",
            //         const: 1
            //     },
            //     {
            //         title: "Ciudadanía",
            //         const: 2
            //     },
            //     {
            //         title: "Residencia Fiscal",
            //         const: 3
            //     },
            //     {
            //         title: "Residencia Permanente",
            //         const: 4
            //     },
            // ],
        },
        "profesion": { title: "Profesión", type: "string" },
        "ocupacion": {
            title: "Ocupación",
            type: "string",
            enumNames: ["Empleado Privado", "Empleado Público", "Independiente", "Retirado", "Pensionado", "Otro"],
            enum: ["Empleado Privado", "Empleado Publico", "Independiente", "Retirado", "Pensionado", "Otro"],
            // anyOf: [
            //     { title: "Empleado Privado", const: 1 },
            //     { title: "Empleado Público", const: 2 },
            //     { title: "Independiente", const: 3 },
            //     { title: "Retirado", const: 4 },
            //     { title: "Pensionado", const: 5 },
            //     { title: "Otro", const: 6 },
            // ],
        },
        "ingresosMensuales": {
            title: "Ingresos Mensuales",
            type: "number",
        },
        "sujetoObligadoLey155": { title: "¿Es Sujeto Obligado de la Ley 155-17?", ...boolYesNoType },
        "personaExpuestaPoliticamente": { title: "¿Es o ha sido una persona expuesta políticamente (PEP)?", ...boolYesNoType },
        "parentescoPersonaExpuestaPoliticamente": {
            title: "¿Tiene algún parentesco, es asociado cercano o realiza una operación en nombre de una persona expuesta políticamente (PEP)?",
            ...boolYesNoType
        },
        "beneficiariosDeTransaccion": {
            title: "¿Existen beneficiarios directos o indirectos de la transaccion diferentes de usted?",
            ...boolYesNoType
        },
        "vinculadoJMMB": { title: "¿Es vinculado o relacionado a un vinculado de JMMB?", ...boolYesNoType },
    },
    dependencies: {
        "nacionalidadOtroPais": {
            allOf: [{
                if: {
                    properties: {
                        "nacionalidadOtroPais": { const: true },
                    }
                },
                then: {
                    properties: {
                        "indicarPaises": { title: "Indicar Países", type: "string" }
                    }
                },
            }]
        },
        "ocupacion": {
            allOf: [
                {
                    if: {
                        properties: {
                            "ocupacion": { const: "Independiente" },
                        }
                    },
                    then: {
                        properties: {
                            "inscritoCedulaAnteDGII": {
                                title: "¿Está inscrito con su cédula ante la DGII?",
                                ...boolYesNoType
                            },
                            "otraFuenteIngresosFormales": {
                                title: "¿Tiene otras fuentes de ingresos formales?",
                                ...boolYesNoType
                            },
                        }
                    }
                },
                {
                    if: {
                        properties: {
                            "ocupacion": { const: "Otro", },
                        }
                    },
                    then: {
                        properties: {
                            "ocupacionOtro": { title: "Otro", type: "string" },
                            "inscritoCedulaAnteDGII": {
                                title: "¿Está inscrito con su cédula ante la DGII?",
                                ...boolYesNoType
                            },
                            "otraFuenteIngresosFormales": {
                                title: "¿Tiene otras fuentes de ingresos formales?",
                                ...boolYesNoType
                            },
                        }
                    },
                },
            ]
        },
        "beneficiariosDeTransaccion": {
            allOf: [{
                if: {
                    properties: {
                        "beneficiariosDeTransaccion": { const: true },
                    }
                },
                then: {
                    properties: {
                        "nombreBeneficiario": { title: "Nombre del beneficiario", type: "string" },
                        "apellidoBeneficiario": { title: "Apellido del beneficiario", type: "string" },
                        "idBeneficiario": { title: "Número de identificación del beneficiario", type: "number" },
                    }
                },
            }]
        },
    },
    required: [
        "fechaNacimiento", "lugarNacimiento", "nacionalidad", "nacionalidadOtroPais", "direccionResidencia",
        "sector", "ciudad", "pais", "residenteRD", "residenciaFisicaUSA", "zonaGeograficaNacional",
        "estatusMigratorio", "profesion", "ocupacion", "ingresosMensuales", "sujetoObligadoLey155",
        "personaExpuestaPoliticamente", "parentescoPersonaExpuestaPoliticamente",
        "beneficiariosDeTransaccion", "vinculadoJMMB",
    ],
}

form.schema = {
    title: "Solicitud de Vinculación Cliente Personal",
    type: "object",
    properties: {
        clientTypeId,
        preferredBranchId,
        "clientSchema": clientSchema,
        "clientAditionalInfoSchema": clientAditionalInfoSchema,
    },
};



form.uiSchema = {
    "preferredBranchId": { "ui:field": "LookupFormField", "ui:readonly": true },
    "clientTypeId": {
        "ui:readonly": true,
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "vinculacionMancomunada": {
        "ui:widget": "radio",
        "ui:readonly": true,
        "ui:options": {
            inline: true
        },
    },
    "kycPersonalClientSchema": {
        "classNames": "smallThreeRows",
        "ui:order": [
            "institucionVincularse",
            "productosBanco",
            "productosPuesto",
            "productosSAFI",
            "savingsAccountSchema",
            "cuentaAhorroBasica",
            "soloMantieneEstaCuenta",
            "excepcionCuentaAhorro",
            "financialCertSchema",
            "inversionesSchema",
            "safiSchema",
            "prestamos",
            "solicitudPrestamoSchema",
            "*",
        ],
        "institucionVincularse": {
            "classNames": "from-col-1 to-col-4",
            "ui:widget": "checkboxes",
            "ui:options": { inline: true },
        },
        "cuentaAhorroBasica": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "excepcionCuentaAhorro": {
            "classNames": "from-col-1",
        },
        "savingsAccountSchema": {
            "classNames": "from-col-1 to-col-4 smallTwoRows",
        },
        "financialCertSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
        },
        "soloMantieneEstaCuenta": {
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "productosBanco": {
            "classNames": "from-col-1",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": {
                inline: false
            },
        },
        "productosPuesto": {
            "classNames": "from-col-2",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },
        "productosSAFI": {
            "classNames": "from-col-3",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },

        "inversionesSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio",
            ],
            "inversionEstimadaUSD": { "classNames": "title-2-lines" },
            "origenFondosUSD": { "classNames": "title-2-lines" },
            "origenFondosUSDOtro": { "classNames": "title-2-lines" },
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }

        },
        "safiSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "*",
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio"
            ],
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "prestamos": {
            "classNames": "smallTwoRows from-col-1 to-col-4",
        },
        "solicitudPrestamoSchema": {
            "classNames": "from-col-1 to-col-4",
            "facilidadesSolicitadas": {
                "ui:order": [
                    "*",
                    "incluyeGarantia",
                    "incluyeGarantiaSolidaria",
                    "tipoGarantia",
                    "comentarios"
                ],
                "descripcionFacilidades": {
                    "ui:options": {
                        "orderable": false,
                    }
                },
                "proposito": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 3
                    },
                },
                "incluyeGarantia": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "comentarios": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 3
                    },
                },
                "incluyeGarantiaSolidaria": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
            },
        },
    },
    "clientSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "*",
            "tipoIdentificacionPersonal",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "buscarDataPersonal",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",
        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
        "tipoIdentificacionPersonal": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "buscarDataPersonal":{
            "ui:field": "FetchClientInfoButton",
        },
        "sexo": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "clientAditionalInfoSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "lugarNacimiento",
            "nacionalidad",
            "nacionalidadOtroPais",
            "indicarPaises",
            "*",
            "ocupacion",
            "ocupacionOtro",
            "inscritoCedulaAnteDGII",
            "otraFuenteIngresosFormales",
            "ingresosMensuales",
            "sujetoObligadoLey155",
            "tipoSujetoObligado",
            "personaExpuestaPoliticamente",
            "parentescoPersonaExpuestaPoliticamente",
            "beneficiariosDeTransaccion",
            "vinculadoJMMB"
        ],
        "nacionalidadOtroPais": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenteRD": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenciaFisicaUSA": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estatusMigratorio": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "ingresosActividadesFormales": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "otraFuenteIngresosFormales": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sujetoObligadoLey155": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tipoSujetoObligado": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "personaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "parentescoPersonaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "beneficiariosDeTransaccion": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "vinculadoJMMB": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
}

form.objectMap = {
    "clients[$idx].retailClientBasicInfo.hasBasicSaving": "kycPersonalClientSchema.cuentaAhorroBasica",
    "clients[$idx].retailClientBasicInfo.onlyMaintainsThisAccount": "kycPersonalClientSchema.soloMantieneEstaCuenta",
    "clients[$idx].retailClientBasicInfo.savingsException": "kycPersonalClientSchema.excepcionCuentaAhorro",

    "clients[$idx].clientTypeId": "clientTypeId",
    "clients[$idx].retailClientBasicInfo.firstName": "clientSchema.nombre",
    "clients[$idx].retailClientBasicInfo.lastName": "clientSchema.apellidos",
    "clients[$idx].retailClientBasicInfo.email": "clientSchema.email",
    "clients[$idx].retailClientBasicInfo.identificationType": "clientSchema.tipoIdentificacionPersonal",
    "clients[$idx].retailClientBasicInfo.identificationNumber": "clientSchema.numIdentificacionCedula",
    "clients[$idx].retailClientBasicInfo.passportNumber": "clientSchema.numIdentificacionPasaporte",
    "clients[$idx].retailClientBasicInfo.foreignIdNumber": "clientSchema.numIdentificacionIdExtranjero",
    "clients[$idx].retailClientBasicInfo.subscribedAgainstDGII": "clientAditionalInfoSchema.inscritoCedulaAnteDGII",
    "clients[$idx].retailClientBasicInfo.sex": "clientSchema.sexo",
    "clients[$idx].retailClientBasicInfo.civilStatus": "clientSchema.estadoCivil",
    "clients[$idx].retailClientBasicInfo.phone": "clientSchema.telefono",
    "clients[$idx].retailClientBasicInfo.phoneMobile": "clientSchema.celular",
    "clients[$idx].retailClientBasicInfo.phoneOther": "clientSchema.otro",
    "clients[$idx].retailClientBasicInfo.dateOfBirth": "clientAditionalInfoSchema.fechaNacimiento",
    "clients[$idx].retailClientBasicInfo.countryOfBirth": "clientAditionalInfoSchema.lugarNacimiento",
    "clients[$idx].retailClientBasicInfo.nationality": "clientAditionalInfoSchema.nacionalidad",
    "clients[$idx].retailClientBasicInfo.address": "clientAditionalInfoSchema.direccionResidencia",
    "clients[$idx].retailClientBasicInfo.addressSector": "clientAditionalInfoSchema.sector",
    "clients[$idx].retailClientBasicInfo.city": "clientAditionalInfoSchema.ciudad",
    "clients[$idx].retailClientBasicInfo.country": "clientAditionalInfoSchema.pais",
    "clients[$idx].retailClientBasicInfo.residentOfDominicanRepublic": "clientAditionalInfoSchema.residenteRD",
    "clients[$idx].retailClientBasicInfo.isAmericanResident": "clientAditionalInfoSchema.residenciaFisicaUSA",
    "clients[$idx].retailClientBasicInfo.unitedStatesImmigrationStatus": "clientAditionalInfoSchema.estatusMigratorio",
    "clients[$idx].retailClientBasicInfo.profession": "clientAditionalInfoSchema.profesion",
    "clients[$idx].retailClientBasicInfo.occupation": "clientAditionalInfoSchema.ocupacion",
    "clients[$idx].retailClientBasicInfo.isIncomeFromFormalActivities": "clientAditionalInfoSchema.ingresosActividadesFormales",
    "clients[$idx].retailClientBasicInfo.hasOtherSourcesOfIncome": "clientAditionalInfoSchema.otraFuenteIngresosFormales",
    "clients[$idx].retailClientBasicInfo.monthlyIncome": "clientAditionalInfoSchema.ingresosMensuales",
    "clients[$idx].retailClientBasicInfo.isSubjectToLaw15517": "clientAditionalInfoSchema.sujetoObligadoLey155",
    "clients[$idx].retailClientBasicInfo.obligationType": "clientAditionalInfoSchema.tipoSujetoObligado",
    "clients[$idx].retailClientBasicInfo.hasBeneficiaries": "clientAditionalInfoSchema.beneficiariosDeTransaccion",
    "clients[$idx].retailClientBasicInfo.beneficiaryName": "clientAditionalInfoSchema.nombreBeneficiario",
    "clients[$idx].retailClientBasicInfo.beneficiaryLastName": "clientAditionalInfoSchema.apellidoBeneficiario",
    "clients[$idx].retailClientBasicInfo.beneficiaryId": "clientAditionalInfoSchema.idBeneficiario",
    "clients[$idx].retailClientBasicInfo.isRelatedToFranchise": "clientAditionalInfoSchema.vinculadoJMMB",
    "clients[$idx].retailClientBasicInfo.comments": "clientSchema.comentarios",
    "clients[$idx].retailClientFatcaInfo.hasOtherNationality": "clientAditionalInfoSchema.nacionalidadOtroPais",
    "clients[$idx].retailClientFatcaInfo.otherNationalities": "clientAditionalInfoSchema.indicarPaises",
    "clients[$idx].retailClientPoliticalExposureInfo.hasPoliticalExposure": "clientAditionalInfoSchema.personaExpuestaPoliticamente",
    "clients[$idx].retailClientPoliticalExposureInfo.hasPoliticalExposureRelative": "clientAditionalInfoSchema.parentescoPersonaExpuestaPoliticamente",
    "clients[$idx].retailClientBasicInfo.nationalGeographicZone": "clientAditionalInfoSchema.zonaGeograficaNacional",
    "clients[$idx].retailClientBasicInfo.otherOccupation": "clientAditionalInfoSchema.ocupacionOtro",

}


export default form;