// import { Trans } from '@lingui/react';
import React from 'react';
import { Redirect } from 'react-router';
import UserApi from "../../api/UserApi";
import FormComponent from '../../components/FormComponent';
import EditUser from "./EditUser";

const CreateNewUser = FormComponent({
    displayName: 'Nuevo Usuario',
    schema: {
        ...EditUser.formDefinition.schema,
        title: "Nuevo Usuario",
    },
    uiSchema: EditUser.formDefinition.uiSchema,
    validate: EditUser.formDefinition.validate,
    submitButtons: {
        submit: {text: "Crear Usuario", className: "btn-primary"},
    },
    async onSubmit({ formData }) {
        await UserApi.createUser(formData);
    },
    renderFormSubmitted: () => (
        <Redirect to="/users" />
    )
});

export default CreateNewUser;