const env = process.env;

export const DEV_MODE = !!env.REACT_APP_DEV_MODE;
export const ENDPOINT = env.REACT_APP_API_ENDPOINT;

export const SENTRY_DSN = (env.REACT_APP_SENTRY_DSN || '').trim();


if (DEV_MODE) {
    console.log("DEV_MODE");
}

export const WaitingForDocumentState = {
    AVAILABLE: 0,
    NOT_FOUND: 1,
    PROCESSING: 2,
    ERROR: 3,
}

export const STATIC_FILE_URLS = {
    KYC_CLEAN: "https://joli-public-documents.s3.amazonaws.com/FICHA+REGISTRO+DEL+CLIENTE+PERSONA+FISICA+(KYC+BANCO).pdf",
    W8: "https://joli-public-documents.s3.amazonaws.com/W-8BEN.pdf",
    W9: "https://joli-public-documents.s3.amazonaws.com/11.+formulario+W-9+(Actualizado+2018).pdf",
    SUJETO_OBLIGADO: "https://joli-public-documents.s3.amazonaws.com/PF-+CUESTIONARIO+PLA-FT+PARA+SUJETOS+OBLIGADOS+NO+FINANCIEROS.docx",
    REQUIREMENTS: "https://joli-public-documents.s3.amazonaws.com/Listado+de+Requerimientos.docx",
    ContractTemplate: "https://joli-public-documents.s3.amazonaws.com/Listado+de+Requerimientos.docx",
    SOLICITUD_PRESTAMO: "https://joli-public-documents.s3.amazonaws.com/SOLICITUD+DE+PRESTAMO+(Propuesta+3+V2A).doc",
}


export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "h:mm a";
export const DATETIME_FORMAT = "DD/MM/YYYY h:mm A";


export const FORM_SUBMIT_BUTTONS = {
    submit: { text: "Someter", className: "btn-primary" },
    saveOnly: {
        text: "Solo Grabar",
        className: "btn-secondary",
        onProcessSubmit(args) {
            (args.object || {}).onlySave = true;
        },
        ignoreValidation: true,
    }
};

export const FORM_ONLY_SAVE_BUTTON = {
    saveOnly: {
        text: "Solo Grabar",
        className: "btn-secondary",
        onProcessSubmit(args) {
            (args.object || {}).onlySave = true;
        },
        ignoreValidation: true,
    }
};

export const FORM_BUTTONS_GENERATE_DOCS = {
    generateDocs: {
        text: "Generar Documentos",
        className: "btn-secondary",
        onProcessSubmit(args) {
            (args.object || {}).onlySave = true;
            (args.object || {}).generateDocs = args.generateDocuments;
        },
        ignoreValidation: true
    }
}

export const FORM_ONLY_SUBMIT_BUTTON = {
    submit: { text: "Someter", className: "btn-primary" },
};

export const FORM_UPDATE_SUBMIT_BUTTON = {
    submit: { text: "Actualizar", className: "btn-primary" },
};


export const NO_FORM = '!!!NO_FORM!!!';


export const DD_SENT_BACK_MAP = {
    RETURNED: { id: 2, title: "Devuelto" },
    RETURNED_ADDITIONAL_REQUIREMENTS: { id: 6, title: "Requerimiento de información adicional" },
};


export const SLA_COLORS = ["success", "warning", "danger", ""];

export const SLACOLOR = (lapsedTime, sla) => {
    sla = sla || [10, 20];
    if (lapsedTime < sla[0]) {
        return SLA_COLORS[0]
    }
    else if (lapsedTime < sla[1]) {
        return SLA_COLORS[1]
    }
    return SLA_COLORS[2];
};

export const TASA_CAMBIO_MIN = 40;