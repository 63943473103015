import React from 'react';
import Notification from '../components/Notification';
import forms from "../views/forms/forms";
import WaitForCaseFiles from "../components/WaitForCaseFiles";


function CurrentCaseForm({
    hide,
    currentCase,
    currentStatus,
    onDirty,
}) {
    const { page, awaitDocuments } = currentStatus || {};
    const FormComponent = forms[page];
    return <div className={`case-form ${hide ? 'hidden' : ''}`}>{awaitDocuments ? (
        <WaitForCaseFiles currentCase={currentCase} page={page} />
    ) : (FormComponent ? (
        <FormComponent currentCase={currentCase} page={page} onDirty={onDirty} />
    ) : (page ? (
        <Notification color="warning">Form not fully implemented yet</Notification>
    ) : (
        <Notification color="warning">The case does not have any forms that you can view.</Notification>
    )))}</div>;
}



export default CurrentCaseForm;