import dropdownOptions from '../../util/dropdownOptions';
import {
    clientTypeId,
    preferredBranchId,
    boolYesNoType,
    strYesNoNAType,
    tipoIdentificacionPersonal,
    mergeObjects
} from '../commons';


const form = {
    title: "Full KYC",
    displayName: 'KYCPersonalClient',
    page: 'kyc-personal',
    commentFieldsMap: {
        "Fatca": "clients[$idx].retailClientFatcaInfo.comments",
        "Prestamos": "clients[$idx].retailClientLoanInfo.comments",
        "Datos Laborales": "clients[$idx].retailClientWorkInfo.comments",
    },
};


const datosLaboralesSchema = {
    title: "Datos Laborales/ Actividad Económica",
    type: "object",
    properties: {
        "empresa": {
            title: "Empresa",
            type: "string",
        },
        "telefonoEmpresa": {
            title: "Teléfono del trabajo",
            type: "string",
        },
        "faxEmpresa": {
            title: "Fax",
            type: "string"
        },
        "emailLaboral": {
            title: "Email laboral",
            type: "string",
        },
        "direccionEmpresa": {
            title: "Dirección de empresa",
            type: "string",
        },
        "sector": {
            title: "Sector",
            type: "string",
        },
        "ciudad": {
            title: "Ciudad",
            type: "string",
        },
        "provincia": {
            title: "Provincia",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional,
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "actividadPrincipal": {
            title: "Actividad Principal",
            type: "string",
            enum: dropdownOptions.actividades,
        },
        "cargo": {
            title: "Cargo Ocupado",
            type: "string",
        },
        "salarioMensual": {
            title: "Ingresos Mensuales:",
            type: "number"
        },
        "fechaIngreso": {
            title: "Fecha Ingreso",
            type: "string",
            format: "date",
        },
        "nombreEmpleoAnterior": {
            title: "Empresa Empleo Anterior:",
            type: "string"
        },
        "cargoEmpleoAnterior": {
            title: "Cargo Empleo Anterior:",
            type: "string"
        },
        "tiempoEmpleoAnterior":
        {
            title: "Tiempo Empleo Anterior:",
            type: "string"
        },
        "telefonoEmpleoAnterior": {
            title: "Teléfono Empleo Anterior:",
            type: "string"
        },
        "emailEmpleoAnterior": {
            title: "Email Empleo Anterior:",
            type: "string"
        },
        "comentarios": {
            title: "Comentarios",
            type: "string",
        },
    },
    required: ["actividadPrincipal"],
}
const fatca = {
    title: "FATCA",
    type: "object",
    properties: {
        "nacionalidadOtroPais": {
            title: "¿Tiene nacionalidad o ciudadanía de otro país?",
            ...boolYesNoType
        },
        "residenciaFisicaUSA": {
            title: "¿Es Estados Unidos su país de residencia física?",
            ...boolYesNoType
        },
        "estatusMigratorio": {
            title: "¿Tiene algún estatus migratorio en los Estados Unidos?",
            type: "string",
            enumNames: ["No", "Ciudadanía", "Residencia Fiscal", "Residencia Permanente"],
            enum: ["No", "Ciudadania", "Residencia Fiscal", "Residencia Permanente"],
            // oneOf: [
            //     {
            //         title: "No",
            //         const: 1
            //     },
            //     {
            //         title: "Ciudadanía",
            //         const: 2
            //     },
            //     {
            //         title: "Residencia Fiscal",
            //         const: 3
            //     },
            //     {
            //         title: "Residencia Permanente",
            //         const: 4
            //     },
            // ],
        },
        "multiplesPaisesResidenciaFiscal": {
            title: "¿Tiene más de un país de residencia fiscal?",
            ...boolYesNoType
        },
        "greenCard": {
            title: "¿Tiene Green Card?",
            ...boolYesNoType
        },
        "direccionEnvioEstadosUnidos": {
            title: "¿Tiene dirección de envío en los Estados Unidos?",
            ...boolYesNoType
        },
        "tieneDireccionResidenciaEstadosUnidos": {
            title: "¿Tiene dirección de residencia en los Estados Unidos?",
            ...boolYesNoType
        },
        "telefonoFueraPais": {
            title: "¿Tiene número telefónico fuera del país?",
            ...boolYesNoType
        },
        "telefonoEstadoUnidos": {
            title: "Si tiene número telefónico en Estados Unidos favor indicar:",
            type: "string",
        },
        "comentarios": {
            title: "Comentarios (Si se encuentra en proceso de adquirir una residencia o ciudadanía en otro país, indique)",
            type: "string",
        },

    },
    dependencies: {
        "estatusMigratorio": {
            oneOf: [
                {
                    properties: {
                        "estatusMigratorio": {
                            const: "No"
                        }
                    }
                },
                {
                    properties: {
                        "estatusMigratorio": {
                            const: "Ciudadania"
                        },
                        "numIDFiscal": {
                            title: "Número ID Fiscal",
                            type: "string",
                        },
                    }
                },
                {
                    properties: {
                        "estatusMigratorio": {
                            const: "Residencia Fiscal"
                        },
                        "numIDFiscal": {
                            title: "Número ID Fiscal",
                            type: "string",
                        },
                    }
                },
                {
                    properties: {
                        "estatusMigratorio": {
                            const: "Residencia Permanente"
                        },
                        "numIDFiscal": {
                            title: "Número ID Fiscal",
                            type: "string",
                        },
                    }
                },
            ]
        },
        "nacionalidadOtroPais": {
            oneOf: [
                {
                    properties: {
                        "nacionalidadOtroPais": {
                            const: true,
                        },
                        "indicarPaises": {
                            title: "Indicar países",
                            type: "string",
                        }
                    }
                },
                {
                    properties: {
                        "nacionalidadOtroPais": {
                            const: false,
                        },
                    }
                }
            ]
        },
        "multiplesPaisesResidenciaFiscal": {
            oneOf: [
                {
                    properties: {
                        "multiplesPaisesResidenciaFiscal": {
                            const: true,
                        },
                        "paisesResidenciaFiscal": {
                            title: "Países con residencia fiscal",
                            type: "string",
                        }
                    }
                },
                {
                    properties: {
                        "multiplesPaisesResidenciaFiscal": {
                            const: false,
                        },
                    }
                }
            ]
        },
        "tieneDireccionResidenciaEstadosUnidos": {
            oneOf: [
                {
                    properties: {
                        "tieneDireccionResidenciaEstadosUnidos": {
                            const: true,
                        },
                        "direccionResidenciaEstadosUnidos": {
                            title: "Dirección de residencia en Estados Unidos:",
                            type: "string",
                        }
                    }
                },
                {
                    properties: {
                        "tieneDireccionResidenciaEstadosUnidos": {
                            const: false,
                        },
                    }
                }
            ]
        },
    },
    required: ["nacionalidadOtroPais", "residenciaFisicaUSA", "estatusMigratorio",
        "multiplesPaisesResidenciaFiscal", "greenCard", "direccionEnvioEstadosUnidos",
        "tieneDireccionResidenciaEstadosUnidos", "telefonoFueraPais",
    ],
}
const declaracionJurada = {
    title: "Declaración Jurada",
    type: "object",
    properties: {
        "declaracionJurada1": {
            "title": "Que las informaciones contenidas en este formulario respecto de su perfil, origen de fondos e ingresos mensuales, son veraces, certeras y comprobables, incluyendo pero no limitado a las referentes a su empleo y/o profesión, según aplique, para cuyas actividades o labores, se encuentra debidamente habilitado o autorizado, si aplica.",
            "type": "boolean",
        },
        "declaracionJurada2": {
            "title": "Que reconoce que la Entidad es un sujeto obligado bajo las leyes, normas y regulaciones aplicables para la prevención de lavado de activos provenientes de actividades ilícitas, por lo que el Cliente autoriza a que la Entidad ejecute todos los pasos tendentes al cumplimiento de la debida diligencia, comprometiéndose el Cliente a cooperar para el buen desenvolvimiento de dicho proceso. A tales fines, el cliente autoriza a la Entidad realizar cualquier verificación en cualquier base de dato pública o privada. Específicamente, autoriza a la Entidad a verificar la información crediticia del Cliente a través de los centros de información crediticia, así como a suministrar a dichos centros de información crediticia, la información patrimonial y extra patrimonial que la Entidad considere relevante para los fines de evaluación crediticia, de conformidad con la Ley Organiza sobre Protección de Datos de Carácter Personal No. 172-13 del 13 de diciembre de 2013 y cualquier regulación aplicable.",
            "type": "boolean",
        },
        "declaracionJurada3": {
            "title": "Que los recursos, patrimonio y activos que posee y que serán utilizados en las operaciones de mercado de valores con la Entidad provienen de actividades lícitas y son destinados a actividades de lícito comercio, y no son el resultado de ninguna violación o contravención a las leyes vigentes en el país o en el extranjero. Igualmente, las rentas o frutos sean obtenidos a través de las operaciones de mercado de valores a través o con la intermediación de la Entidad serán destinados a un fin lícito, y no serán destinados a ninguna actividad que constituya una infracción a las leyes vigentes en el país o en el extranjero.",
            "type": "boolean",
        },
        "declaracionJurada4": {
            "title": "Que autoriza a la Entidad a crear una base de datos con mi información personal, bien sea la suministrada por el Cliente o bien sea la recolectada en los procesos de debida diligencia, y a compartir la misma con sus empresas vinculadas, así como a obtener toda la información y documentación que figuren en los expedientes del Cliente en empresas vinculadas a la Entidad; específicamente autorizo a la Entidad y empresas vinculadas a utilizar esta información para fines mercadológicos, publicitario y de ventas, incluyendo pero no limitado para la remisión correos electrónicos y mensajes telefónicos. El Cliente reconoce que la Entidad ha revelado que la información en la base de datos se encuentra sujeta al derecho de rectificación en los términos de la Ley Organiza sobre Protección de Datos de Carácter Personal No. 172-13 del 13 de diciembre de 2013 y cualquier regulación aplicable.",
            "type": "boolean",
        },
    },
    required: ["declaracionJurada1", "declaracionJurada2",
        "declaracionJurada3", "declaracionJurada4",
    ],
}
const personaPoliticamenteExpuesta = {
    title: "Persona Políticamente Expuesta",
    type: "object",
    properties: {
        "personaExpuestaPoliticamente": {
            title: "¿Es o ha sido una persona expuesta políticamente (PEP)?",
            ...boolYesNoType
        },
        "parentescoConPersonaExpuestaPoliticamente": {
            title: "¿Tiene algún parentesco, es asociado cercano o realiza una operación en nombre de una persona expuesta políticamente (PEP)?",
            ...boolYesNoType
        },
    },
    dependencies: {
        "personaExpuestaPoliticamente": {
            oneOf: [{
                properties: {
                    "personaExpuestaPoliticamente": {
                        const: true,
                    },
                    "cargo": {
                        title: "Cargo",
                        type: "string",
                    },
                    "pais": {
                        title: "País",
                        type: "string",
                        enum: dropdownOptions.pais,
                    },
                    "fechaDesignacion": {
                        title: "Fecha de designación",
                        type: "string",
                        format: "date",
                    },
                    "fechaRemocion": {
                        title: "Fecha de remoción",
                        type: "string",
                        format: "date",
                    },
                },
                required: ["cargo", "pais",
                    "fechaDesignacion",
                ],
            },
            {
                properties: {
                    "personaExpuestaPoliticamente": {
                        const: false,
                    },
                }
            }
            ],
        },
        "parentescoConPersonaExpuestaPoliticamente": {
            oneOf: [{
                properties: {
                    "parentescoConPersonaExpuestaPoliticamente": {
                        const: true,
                    },
                    "nombrePEP": {
                        title: "Nombre del PEP",
                        type: "string",
                    },
                    "parentescoPEP": {
                        title: "Parentesco o Afinidad",
                        type: "string",
                    },
                    "cargoPEP": {
                        title: "CargoPEP",
                        type: "string",

                    },
                    "paisPEP": {
                        title: "País",
                        type: "string",
                        enum: dropdownOptions.pais,
                    },
                    "fechaDesignacionPEP": {
                        title: "Fecha de designación",
                        type: "string",
                        format: "date",
                    },
                    "fechaRemocionPEP": {
                        title: "Fecha de remoción",
                        type: "string",
                        format: "date",
                    },
                },
                required: ["nombrePEP", "parentescoPEP", "cargoPEP",
                    "paisPEP", "fechaDesignacionPEP",
                ],
            },
            {
                properties: {
                    "parentescoConPersonaExpuestaPoliticamente": {
                        const: false,
                    },
                }
            }],
        },
    },
    required: ["personaExpuestaPoliticamente", "parentescoConPersonaExpuestaPoliticamente",
    ],
}

const camposBancoSchema = {
    title: " ",
    type: "object",
    properties: {
        "beneficiariosDeTransaccionDiferentes": {
            title: "¿Existen beneficiarios directos o indirectos de la transaccion diferentes de usted?",
            ...boolYesNoType
        },
        "vinculadoJMMB": {
            title: "¿Es vinculado o relacionado a un vinculado de JMMB?",
            ...boolYesNoType
        },
    },
    dependencies: {
        "beneficiariosDeTransaccionDiferentes": {
            oneOf: [
                {
                    properties: {
                        "beneficiariosDeTransaccionDiferentes": {
                            const: true
                        },
                        "nombreBeneficiario": {
                            title: "Nombre de beneficiario",
                            type: "string"
                        }
                    }
                },
                {
                    properties: {
                        "beneficiariosDeTransaccionDiferentes": {
                            const: false
                        },
                    }
                }
            ]
        },
        "vinculadoJMMB": {
            oneOf: [
                {
                    properties: {
                        "vinculadoJMMB": {
                            const: true
                        },
                        "empresaVinculada": {
                            title: "Empresa vinculada",
                            type: "string"
                        }
                    }
                },
                {
                    properties: {
                        "vinculadoJMMB": {
                            const: false
                        },
                    }
                }
            ]
        },
    }

}

const transaccionesEsperadasSchema = {
    title: " ",
    description: "Transacciones esperadas",
    type: "object",
    properties: {
        "cantidadOperacionesMensuales": {
            title: "Cantidad de operaciones mensuales esperadas:",
            type: "string",
            enumNames: ["De 0 a 5", "De 5 a 10", "Más de 10", "Otro"],
            enum: ["De 0 a 5", "De 5 a 10", "Mas de 10", "Otro"],
            // oneOf: [
            //     { title: "De 0 a 5", const: 1 },
            //     { title: "De 5 a 10", const: 2 },
            //     { title: "Más de 10", const: 3 },
            //     { title: "Otro", const: 4 },
            // ],
        },
        "formaMasFrecuenteTransacciones": {
            title: "Forma más frecuente de las transacciones:",
            type: "string",
            enum: ["Efectivo", "Cheque", "Transferencias Nacionales", "Transferencias Internacionales"],
            // oneOf: [
            //     {
            //         title: "Efectivo",
            //         const: 1
            //     },
            //     {
            //         title: "Cheque",
            //         const: 2
            //     },
            //     {
            //         title: "Transferencias Nacionales",
            //         const: 3
            //     },
            //     {
            //         title: "Transferencias Internacionales",
            //         const: 4
            //     },
            // ],
        },

    },
    dependencies: {
        "cantidadOperacionesMensuales": {
            oneOf: [
                {
                    properties: {
                        "cantidadOperacionesMensuales": {
                            const: "Otro",
                        },
                        "cantidadOperacionesMensualesOtro": {
                            title: "Indicar otro",
                            type: "number"
                        }
                    },
                },
                {
                    properties: {
                        "cantidadOperacionesMensuales": {
                            const: "De 0 a 5",
                        },
                    }
                },
                {
                    properties: {
                        "cantidadOperacionesMensuales": {
                            const: "De 5 a 10",
                        },
                    }
                },
                {
                    properties: {
                        "cantidadOperacionesMensuales": {
                            const: "Mas de 10",
                        },
                    }
                }
            ]
        },
    }
}

const financialCertSchema = {
    title: " ",
    description: "Certificado Financiero",
    type: "object",
    properties: {
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Certificados en RD",
            type: "number",
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
    },
    dependencies: {
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["inversionEstimadaRD", "origenFondosRD"]
}

const safiSchema = {
    title: " ",
    description: "Inversiones en SAFI",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión SAFI en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para SAFI en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión SAFI en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para SAFI en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const savingsAccountSchema = {
    title: " ",
    description: "Cuenta de Ahorro",
    type: "object",
    properties: {
        "depositoEstimadoRD": {
            title: "Monto estimado primer depósito en RD",
            type: "number",
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
    },
    dependencies: {
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["depositoEstimadoRD", "origenFondosRD"]
}

const inversionesSchema = {
    title: " ",
    description: "Inversiones en Puesto",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión Puesto en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Puesto en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            oneOf: [
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Otro",
                        },
                        "origenFondosUSDOtro": {
                            title: "Otro",
                            type: "string"
                        }
                    },
                    required: ["origenFondosUSDOtro"]
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Ahorros provenientes del salario",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Ahorros por ingresos regulares",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Herencia",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Regalo",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Venta de inmueble",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Venta de vehiculo",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "Liquidacion de otra inversion",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosUSD": {
                            const: "No aplica",
                        },
                    }
                }
            ]
        },
        "origenFondosRD": {
            oneOf: [
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Otro",
                        },
                        "origenFondosRDOtro": {
                            title: "Otro",
                            type: "string"
                        }
                    },
                    required: ["origenFondosRDOtro"]
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Ahorros provenientes del salario",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Ahorros por ingresos regulares",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Herencia",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Regalo",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Venta de inmueble",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Venta de vehiculo",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "Liquidacion de otra inversion",
                        },
                    }
                },
                {
                    properties: {
                        "origenFondosRD": {
                            const: "No aplica",
                        },
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}
const perfilInversionista = {
    title: "Perfil del Inversionista",
    type: "object",
    properties: {
        "riskProdServTitle": {
            title: "Riesgo de productos y servicios",
            type: "null",
        },
        "riesgoProductosServiciosEdad": {
            title: "Edad",
            type: "string",
            enumNames: ["Mayor de 55 años", "De 35 a 55 años", "De 25 a 34 años", "Menor de 25 años"],
            enum: ["Mayor de 55 anos", "De 35 a 55 anos", "De 25 a 34 anos", "Menor de 25 anos"],
            // oneOf: [
            //     {
            //         title: "Mayor de 55 años",
            //         const: 1
            //     },
            //     {
            //         title: "De 35 a 55 años",
            //         const: 2
            //     },
            //     {
            //         title: "De 25 a 35 años",
            //         const: 3
            //     },
            //     {
            //         title: "Menor de 25 años",
            //         const: 4
            //     },
            // ],
        },
        "nivelAcademico": {
            title: "Nivel Académico",
            type: "string",
            enumNames: ["Doctorado", "Maestría", "Postgrado", "Universitario", "Carrera Técnica", "Bachillerato", "Primaria", "Ninguno"],
            enum: ["Doctorado", "Maestria", "Postgrado", "Universitario", "Carrera Tecnica", "Bachillerato", "Primaria", "Ninguno"],
            // oneOf: [
            //     {
            //         title: "Doctorado",
            //         const: 1
            //     },
            //     {
            //         title: "Maestría",
            //         const: 2
            //     },
            //     {
            //         title: "Postgrado",
            //         const: 3
            //     },
            //     {
            //         title: "Universitario",
            //         const: 4
            //     },
            //     {
            //         title: "Carrera Técnica",
            //         const: 5
            //     },
            //     {
            //         title: "Bachillerato",
            //         const: 6
            //     },
            //     {
            //         title: "Primaria",
            //         const: 7
            //     },
            //     {
            //         title: "Ninguno",
            //         const: 8
            //     },
            // ],
        },
        "operacionesFinancierasNegociado": {
            title: " ",
            description: "Operaciones financieras que ha negociado",
            type: "object",
            properties: {
                "titles": {
                    title: " ",
                    type: "object",
                    properties: {
                        col1: { title: "Años manejando el producto", type: "null" },
                        col2: { title: "Número de operaciones anuales", type: "null" },
                        col3: { title: "Nivel de conocimiento", type: "null" },
                    },
                },
                "inversionCertificadosFinancieros": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Inversión en Certificados Financieros",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "bonosSoberanos": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Bonos soberanos y libres de riesgo",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "bonosCorporativos": {
                    title: "",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Bonos Corporativos",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "fondosInversionesMutuos": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Fondos de inversiones mutuos, opciones y futuros",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "fondosCerrados": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Fondos Cerrados, Préstamos de Margen, Operaciones de Préstamos de Títulos Valores, Compra-Venta de Operaciones a Plazo (Forwards) y Acciones",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "otrosDerivados": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Otros derivados",
                            type: "null",
                        },
                        "indicar": {
                            title: " ",
                            type: "string"
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
            },
        },
    },
}
const garantiaSolidariaSchema = {
    title: " ",
    type: "object",
    properties: {
        "garantiaSolidaria": {
            title: "¿Incluye garantía solidaria?",
            ...boolYesNoType
        },
        "descGarantias": {
            type: "array",
            title: " ",
            description: "Descripción de garantías",
            minItems: 0,
            items: {
                type: "object",
                properties: {
                    "noDeGarantia": {
                        title: "No. de Garantía",
                        type: "string",
                    },
                    "noFacilidadAsociada":
                    {
                        title: "No. Facilidad asociada",
                        type: "string"
                    },
                    "tipoDeGarantia":
                    {
                        title: "Tipo de Garantía",
                        type: "string",
                        enumNames: ["Garantía Solidaria", "Descuento de Nómina", "Hipotecario", "Prendaria", "Múltiples", "Otra"],
                        enum: ["Garantia Solidaria", "Descuento de Nomina", "Hipotecario", "Prendaria", "Multiples", "Otra"],
                        // oneOf: [
                        //     {
                        //         title: "Garantía Solidaria",
                        //         const: 1
                        //     },
                        //     {
                        //         title: "Descuento de Nómina",
                        //         const: 2
                        //     },
                        //     {
                        //         title: "Hipotecario",
                        //         const: 3
                        //     },
                        //     {
                        //         title: "Prendaria",
                        //         const: 4
                        //     },
                        //     {
                        //         title: "Múltiples",
                        //         const: 5
                        //     },
                        //     {
                        //         title: "Otra",
                        //         const: 6
                        //     },
                        // ],
                    },
                    "descGarantia":
                    {
                        title: "Descripción de garantía",
                        type: "string",
                    },
                    "valGarantia":
                    {
                        title: "Valor de Garantía en Tasación",
                        type: "string",
                    },
                }
            }
        },
    }

}
const datosVivienda = {
    title: " ",
    description: "Datos Vivienda",
    type: "object",
    properties: {
        "tipoVivienda": {
            title: "Tipo de Vivienda: ",
            type: "string",
            enum: ["Propia", "Alquilada", "Financiada", "Familiar"],
            // oneOf: [
            //     {
            //         title: "Propia",
            //         const: 1
            //     },
            //     {
            //         title: "Alquilada",
            //         const: 2
            //     },
            //     {
            //         title: "Financiada",
            //         const: 3
            //     },
            //     {
            //         title: "Familiar",
            //         const: 4
            //     },
            // ],
        },
        "tiempoResidiendo": {
            title: "Tiempo residiendo",
            type: "string"
        }

    },
    dependencies: {
        "tipoVivienda": {
            allOf: [
                {
                    if: {
                        properties: {
                            "tipoVivienda": {
                                const: "Alquilada"
                            }
                        }
                    },
                    then: {
                        properties: {
                            "nombrePropietario": {
                                title: "Nombre propietario",
                                type: "string",
                            },
                        }
                    },
                }
            ]
        }

    },
    required: [

    ],
}

const datosCodeudorFiador = {
    title: " ",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombre:",
            type: "string"
        },
        "apellido": {
            title: "Apellido:",
            type: "string"
        },
        "cedula": {
            title: "Cédula:",
            type: "string"
        },
        "fechaNacimiento": {
            title: "Fecha de Nacimiento:",
            type: "string",
            format: "date"
        },
        "lugarNacimiento": {
            title: "Lugar de Nacimiento",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidad": {
            title: "Nacionalidad:",
            type: "string",
            enum: dropdownOptions.pais,
        },
        // "nacionalidadUSA":{
        //     title: "Nacionalidad USA",
        //     ...boolYesNoType
        // },
        "numeroPasaporte": {
            title: "No. Pasaporte:",
            type: "string"
        },
        "residenteUSA": {
            title: "Residente USA",
            ...boolYesNoType
        },
        "tiempoPais": {
            title: "Tiempo en el País:",
            type: "string"
        },
        "telefono": {
            title: "Tel. Casa:",
            type: "string"
        },
        "celular": {
            title: "Celular:",
            type: "string"
        },
        "otro": {
            title: "Otro:",
            type: "string"
        },
        "email": {
            title: "Email:",
            type: "string"
        },
        "direccion": {
            title: "Dirección:",
            type: "string"
        },
        "sector": {
            title: "Sector:",
            type: "string"
        },
        "ciudad": {
            title: "Ciudad:",
            type: "string"
        },
        "provincia": {
            title: "Provincia:",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional,
        },
        "pais": {
            title: "País:",
            type: "string"
        },
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "dependientes": {
            title: "No. Dependientes",
            type: "string"
        },
        "nivelAcademico": {
            title: "Nivel Académico",
            type: "string",
            enumNames: ["Doctorado", "Maestría", "Postgrado", "Universitario", "Carrera Técnica", "Bachillerato", "Primaria", "Ninguno"],
            enum: ["Doctorado", "Maestria", "Postgrado", "Universitario", "Carrera Tecnica", "Bachillerato", "Primaria", "Ninguno"],
        },
        "fuentesIngreso": {
            title: "Fuentes de Ingreso:",
            type: "string",
            enumNames: ["Empleado Privado", "Empleado Público", "Independiente", "Comerciante", "Otro"],
            enum: ["Empleado Privado", "Empleado Publico", "Independiente", "Comerciante", "Otro"],
        },
        "ingresosMensuales": {
            title: "Ingresos mensuales:",
            type: "string"
        },
        "tiempoLaborando": {
            title: "Tiempo laborando:",
            type: "string"
        },
        "tipoVinculacion": {
            title: "Tipo de vinculación con el solicitante:",
            type: "string"
        },
        "tipoVivienda": {
            title: "Tipo de Vivienda: ",
            type: "string",
            enum: ["Propia", "Alquilada", "Financiada", "Familiar"],
            // oneOf: [
            //     {
            //         title: "Propia",
            //         const: 1
            //     },
            //     {
            //         title: "Alquilada",
            //         const: 2
            //     },
            //     {
            //         title: "Financiada",
            //         const: 3
            //     },
            //     {
            //         title: "Familiar",
            //         const: 4
            //     },
            // ],
        },

        "tiempoResidiendo": {
            title: "Tiempo residiendo",
            type: "string"
        },
        "porcientoDeudas": {
            title: "% Deudas",
            type: "number",
        },
    },
}

const capacidadFinanciera = {
    title: "Capacidad Financiera",
    type: "object",
    properties: {
        "ingresosAnuales": {
            title: "Ingresos Anuales",
            type: "string",
            enumNames: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$5.7 Millones", "Entre RD$7.0 y RD$20 Millones", "Más de RD$25.0 Millones"],
            enum: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$5.7 Millones", "Entre RD$7.0 y RD$20 Millones", "Mas de RD$25.0 Millones"],
            // oneOf: [
            //     {
            //         title: "Menos de RD$2 Millones",
            //         const: 1
            //     },
            //     {
            //         title: "Entre RD$2.0 y RD$5.7 Millones",
            //         const: 2
            //     },
            //     {
            //         title: "Entre RD$7.0 y RD$20 Millones",
            //         const: 3
            //     },
            //     {
            //         title: "Más de RD$25.0 Millones",
            //         const: 4
            //     },
            // ],
        },
        "capacidadAhorro": {
            title: "% Capacidad de ahorro",
            type: "number",
        },
        "totalActivosLiquidos": {
            title: "Total Activos Líquidos",
            type: "string",
            enumNames: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Más de RD$20.0 Millones"],
            enum: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Mas de RD$20.0 Millones"],
            // oneOf: [
            //     {
            //         title: "Menos de RD$2 Millones",
            //         const: 1
            //     },
            //     {
            //         title: "Entre RD$2.0 y RD$7.0 Millones",
            //         const: 2
            //     },
            //     {
            //         title: "Entre RD$10.0 y RD$15.0 Millones",
            //         const: 3
            //     },
            //     {
            //         title: "Más de RD$20.0 Millones",
            //         const: 4
            //     },
            // ],
        },
        "porcientoCuentasAhorroOCorriente": {
            title: "% en Cuentas Ahorro o Corriente",
            type: "number",
        },
        "porcientoOtro": {
            title: "% en Otro",
            type: "number",
        },
        "patrimonioTotal": {
            title: "Patrimonio Total",
            type: "string",
            enumNames: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Más de RD$20.0 Millones"],
            enum: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Mas de RD$20.0 Millones"],
            // oneOf: [
            //     {
            //         title: "Menos de RD$2 Millones",
            //         const: 1
            //     },
            //     {
            //         title: "Entre RD$2.0 y RD$7.0 Millones",
            //         const: 2
            //     },
            //     {
            //         title: "Entre RD$10.0 y RD$15.0 Millones",
            //         const: 3
            //     },
            //     {
            //         title: "Más de RD$20.0 Millones",
            //         const: 4
            //     },
            // ],
        },
        "porcientoDeudas": {
            title: "% Deudas",
            type: "number",
        },
        "referenciasBancarias": {
            type: "array",
            title: " ",
            description: "Referencias Bancarias",
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "banco": {
                        title: "Banco",
                        type: "string",
                    },
                    "tipoCuenta": {
                        title: "Tipo de Cuenta",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "oficial": {
                        title: "Oficial",
                        type: "string",
                    },
                },
            },
            required: [],
        },
        "referenciasComerciales": {
            type: "array",
            title: " ",
            description: "Referencias Comerciales",
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "direccion": {
                        title: "Dirección",
                        type: "string",
                    },
                },
            },
            required: [],
        },

    },
    required: ["ingresosAnuales", "totalActivosLiquidos", "patrimonioTotal"],
}
const resultadoPerfil = {
    title: "Resultado del Perfil",
    type: "object",
    properties: {
        "fechaActualizacionExpediente": {
            title: "Fecha de actualización expediente",
            type: "string",
            format: "date",
        },
        "indicarInformacionActualizadaYFuente": {
            title: "Indicar Información actualizada y fuente de la misma",
            type: "string",
        },
        "relacionCaraCara": {
            title: "¿Relación Cara a Cara?",
            ...boolYesNoType
        },
        "resumenCliente": {
            title: "Resumen del cliente",
            type: "string"
        },
        "productoSolicitadoAjustaCliente": {
            title: "¿Se ajusta el producto solicitado/ofrecido a las características y objetivos del cliente?",
            ...boolYesNoType
        },
        "clienteCorredorValores": {
            title: "El cliente/ prospecto es corredor de valores, ha ocupado posiciones de dirección en las áreas de negocios o ha sido miembro del Consejo de una entidad financiera, de valores u otro sujeto obligado, por un periodo mayor o igual a 2 años?",
            ...boolYesNoType
        },
        "clienteActivosLiquidosMayor25m": {
            title: "El cliente/prospecto, ha indicado que sus activos líquidos, esto es, efectivo y equivalente de efectivo (inversiones en el sistema financiero y de valores a nivel nacional e internacional), es igual o mayor al equivalente en pesos dominicanos de RD$ 25,000,000.00?",
            ...boolYesNoType
        },
        "actualizacionExpedienteCliente": {
            title: "Si esta es una actualización del expediente del cliente; El cliente ha mantenido inversiones mayores a RD$3,000,000.00 en el último año y ha realizado al menos 12 transacciones (3 operaciones por trimestre)?",
            ...strYesNoNAType
        },
        "clienteProfesional": {
            title: "El cliente/prospecto es",
            type: "string",
            enum: ["Profesional", "No Profesional"],
        },
        "tolerancia": {
            title: "Tolerancia",
            type: "string",
            enum: ["Conservador", "Moderado", " Agresivo"],
        },
        "clienteBancarizado": {
            title: "El cliente/prospecto es",
            type: "string",
            enum: ["Bancarizado", "No Bancarizado"],
        },
        "acuerdoConPerfilObtenido": {
            title: "¿Está usted de acuerdo con el Perfil obtenido en la evaluación?",
            ...boolYesNoType
        },
    },
    dependencies: {
        "relacionCaraCara": {
            oneOf: [
                {
                    properties: {
                        "relacionCaraCara": {
                            const: false
                        },
                        "canalNoCaraCara": {
                            title: "Canal No cara a cara",
                            type: "string",
                            enumNames: ["Correo Electrónico", "Teléfono", "Videoconferencia", "Otro"],
                            enum: ["Correo Electronico", "Telefono", "Videoconferencia", "Otro"],
                        }
                    },
                    dependencies: {
                        "canalNoCaraCara": {
                            oneOf: [
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Otro"
                                        },
                                        "otroCanalNoCaraCara": {
                                            title: "Indique",
                                            type: "string"
                                        }
                                    }
                                },
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Correo Electronico"
                                        },
                                    }
                                },
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Telefono"
                                        },
                                    }
                                },
                                {
                                    properties: {
                                        "canalNoCaraCara": {
                                            const: "Videoconferencia"
                                        },
                                    }
                                }
                            ]
                        }
                    }
                },
                {
                    properties: {
                        "relacionCaraCara": {
                            const: true
                        },
                    }
                }
            ]
        },
        "clienteProfesional": {
            oneOf: [
                {
                    properties: {
                        "clienteProfesional": {
                            const: "Profesional"
                        },
                        "consideradoNoProfesional": {
                            title: "Cliente desea ser considerado No Profesional",
                            type: "boolean"
                        }
                    }
                },
                {
                    properties: {
                        "clienteProfesional": {
                            const: "No Profesional"
                        },
                    }
                }
            ]
        },
    },
    required: ["relacionCaraCara", "productoSolicitadoAjustaCliente",
        "clienteProfesional", "tolerancia", "clienteBancarizado"
    ],
}

const analisisPatrimonio = {
    title: "Análisis Patrimonio",
    description: "Cambios de los activos y pasivos registrados en los últimos 2 años",
    type: "object",
    properties: {
        "PeriodosFiscales1": {
            title: " ",
            type: "object",
            properties: {
                "anosPeriodosFiscales1": {
                    title: "Años o Periodos Fiscales",
                    type: "number"
                },
                "totalPatrimonio1": {
                    title: "Total Patrimonio RD$",
                    type: "number"
                },
                "totalActivosLiquidos1": {
                    title: "Total Activos Líquidos RD$",
                    type: "number"
                },
                "resultadoAnalisisPatrimonio1": {
                    title: "Resultado",
                    type: "string",
                    enum: ["No cambios significativos", "Cambios significativos (comentar)"]
                },
            }
        },
        "PeriodosFiscales2": {
            title: " ",
            type: "object",
            properties: {
                "anosPeriodosFiscales2": {
                    title: "Años o Periodos Fiscales",
                    type: "number"
                },
                "totalPatrimonio2": {
                    title: "Total Patrimonio RD$",
                    type: "number"
                },
                "totalActivosLiquidos2": {
                    title: "Total Activos Líquidos RD$",
                    type: "number"
                },
                "resultadoAnalisisPatrimonio2": {
                    title: "Resultado",
                    type: "string",
                    enum: ["No cambios significativos", "Cambios significativos (comentar)"]

                },
            }

        },
    },
    dependencies: {
        "fuentesIngreso": {
            allOf: [
                {
                    if: {
                        properties: {
                            "fuentesIngreso": {
                                const: "Otro",
                            },
                        }
                    }, then: {
                        properties: {
                            "ocupacionOtro": {
                                title: "Explique:",
                                type: "string",
                            },
                        }
                    }
                },
            ]
        },
        "tipoVivienda": {
            allOf: [
                {
                    if: {
                        properties: {
                            "tipoVivienda": {
                                const: "Alquilada"
                            }
                        }
                    },
                    then: {
                        properties: {
                            "nombrePropietario": {
                                title: "Nombre propietario",
                                type: "string",
                            },
                        }
                    },
                }
            ]
        }
    }
}

const codeudorFiador = {
    title: " ",
    description: "Datos del Co-Deudor o Fiador",
    type: "object",
    properties: {
        "codeudorFiadorSelection": {
            title: "Relación con cliente adicional",
            type: "string",
            oneOf: [
                {
                    title: "Co-Deudor",
                    const: "Co-Deudor"
                },
                {
                    title: "Fiador",
                    const: "Fiador"
                },
                {
                    title: "Garante",
                    const: "Garante"
                },
            ],
        },

    },
    dependencies: {
        "codeudorFiadorSelection": {
            oneOf: [
                {
                    properties: {
                        "codeudorFiadorSelection": {
                            const: "Co-Deudor"
                        },
                        "datosCodeudorFiador": datosCodeudorFiador
                    }

                },
                {
                    properties: {
                        "codeudorFiadorSelection": {
                            const: "Fiador"
                        },
                        "datosCodeudorFiador": datosCodeudorFiador
                    }

                },
                {
                    properties: {
                        "codeudorFiadorSelection": {
                            const: "Garante"
                        },
                        // show nothing
                    }

                },
            ]
        }

    }
}

const refPersonales = {
    title: " ",
    description: "Referencias Personales",
    type: "object",
    properties: {
        "refPersonalesArray": {
            type: "array",
            title: " ",
            minItems: 0,
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                    "parentesco": {
                        title: "Parentesco",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "celular": {
                        title: "Celular",
                        type: "string",
                    },
                },
            }
        },
    }
}

const datosFamiliares = {
    title: " ",
    description: "Datos de dos (2) familiares (que no vivan con usted)",
    type: "object",
    properties: {
        "refFamiliares": {
            type: "array",
            title: " ",
            minItems: 0,
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                    "parentesco": {
                        title: "Parentesco",
                        type: "string",
                    },
                    "direccion": {
                        title: "Dirección",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "celular": {
                        title: "Celular",
                        type: "string",
                    },
                },
            }
        },
    }
}

const informacionIntencionVinculacion = {
    title: "Información de intención de vinculación",
    // description: "",
    type: "object",
    properties: {
        "institucionVincularse": {
            title: "Institución a vincularse",
            type: "array",
            items: {
                type: "number",
                oneOf: [
                    { title: "Banco", const: 1 },
                    { title: "Puesto", const: 2 },
                    { title: "SAFI", const: 3 },
                ],
            },
            minItems: 1,
            uniqueItems: true,
        },
    },
    dependencies: {
        "institucionVincularse": {
            allOf: [
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 1 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosBanco": {
                                title: "Productos de interés Banco",
                                type: "array",
                                lookup: {
                                    api: 'products/1',
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                items: { type: "number" },
                                minItems: 1,
                                uniqueItems: true,
                                readOnly: true
                            },
                        },
                        // "transaccionesEsperadasSchema": transaccionesEsperadasSchema,
                        "camposBancoSchema": camposBancoSchema
                    },
                },
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 2 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosPuesto": {
                                title: "Productos de interés Puesto",
                                type: "array",
                                lookup: {
                                    api: 'products/2',
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                items: { type: "number" },
                                minItems: 1,
                                uniqueItems: true,
                                readOnly: true
                            },
                            "perfilInversionista": perfilInversionista,
                            "analisisPatrimonio": analisisPatrimonio,
                            "resultadoPerfil": resultadoPerfil,
                        }
                    },
                },
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 3 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosSAFI": {
                                title: "Productos de interés SAFI",
                                type: "array",
                                lookup: {
                                    api: 'products/3',
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                items: { type: "number" },
                                minItems: 1,
                                uniqueItems: true,
                                readOnly: true
                            },
                            "perfilInversionista": perfilInversionista,
                            "analisisPatrimonio": analisisPatrimonio,
                            "resultadoPerfil": resultadoPerfil,
                        },

                    },
                },
            ]
        }
    }
}

const datosConyuge = {
    title: "Datos Cónyuge",
    type: "object",
    properties: {
        "nombres": {
            title: "Nombres",
            type: "string"
        },
        "apellidos": {
            title: "Apellidos",
            type: "string"
        },
        "email": {
            title: "Email",
            type: "string"
        },
        "edad": {
            title: "Edad",
            type: "number"
        },
        "tipoIdent": tipoIdentificacionPersonal,
        "numIdent": {
            title: "No. de identificación",
            type: "string"
        },
        "labora": {
            title: "Institución donde labora",
            type: "string"
        },
        "cargo": {
            title: "Cargo",
            type: "string"
        },
        "celular": {
            title: "Celular",
            type: "string"
        },
        "telefono": {
            title: "Teléfono",
            type: "string"
        },
        "ingMens": {
            title: "Ingresos mensuales",
            type: "number"
        },


    },
    dependencies: {

    },
    // required: ["nombres", "apellidos", "email", "tipoIdent", "numIdent", "cargo", "celular"],
}



const matrizRiesgo = {
    title: "Resumen guia para el llenado de Matriz de Riesgo",
    type: "null",
    // type: "object",
    // properties: {
    //     "tipoCliente": {
    //         title: "Tipo de Cliente",
    //         type: "string",
    //         enum: ["Opcion 1", "Opcion 2", "Opcion 3"]
    //     },
    //     "categoria": {
    //         title: "Categoria",
    //         type: "string"
    //     },
    //     "actividadEconomica": {
    //         title: "Actividad económica",
    //         type: "string"
    //     },
    //     "pep": {
    //         title: "PEP",
    //         type: "string"
    //     },
    //     "duracionRelacion": {
    //         title: "Duración de la relación",
    //         type: "string"
    //     },
    //     "productoSolicitado": {
    //         title: "Producto Solicitado",
    //         type: "string"
    //     },
    //     "montoProyectado": {
    //         title: "Monto proyectado",
    //         type: "string"
    //     },
    //     "nivelIngresos": {
    //         title: "Nivel Ingresos",
    //         type: "string"
    //     },
    //     "zonaGeograficaNacional": {
    //         title: "Zona Geográfica Nacional",
    //         type: "string"
    //     },
    //     "paisOrigen": {
    //         title: "País de Origen",
    //         type: "string"
    //     },
    //     "paisResidencia": {
    //         title: "País de Residencia",
    //         type: "string"
    //     },
    //     "canalDistribucion": {
    //         title: "Canal de Distribución",
    //         type: "string"
    //     },
    // },
}
const soporteDebidaDiligencia = {
    title: "Soportes Debida Diligencia",
    type: "object",
    properties: {
        "revisionListaOFAC": {
            title: "Revisión en lista OFAC",
            type: "number",
        },
        "tieneOFAC": {
            title: "¿Tiene coincidencia en Lista OFAC?",
            ...boolYesNoType
        },
        "revisionListaONU": {
            title: "Revisión en lista ONU",
            type: "number",
        },
        "tieneONU": {
            title: "¿Tiene coincidencia en Lista ONU?",
            ...boolYesNoType
        },
        "matrizRiesgo": {
            title: "Matriz de Riesgo",
            type: "number",
        },
        "nivelRiesgo": {
            title: "Nivel de Riesgo",
            type: "number",
            enumNames: ["Alto", "Medio", "Bajo"],
            enum: [3, 2, 1]
        },
        "revisionBuroCredito": {
            title: "Revisión Buró de Crédito",
            type: "number",
        },
        "sentBack": {
            title: " ",
            type: "string"
        },
    },
    dependencies: {
        "sentBack": {
            allOf: [
                {
                    if: {
                        properties: {
                            "sentBack": {
                                const: "Devuelto"
                            },
                        }
                    },
                    then: {
                        properties: {
                            "aditionalFiles": {
                                title: " ",
                                description: "Archivos Adicionales",
                                type: "array",
                                items: {
                                    "properties": {
                                        "documentName": {
                                            title: "Nombre Archivo",
                                            type: "string"
                                        },
                                        "document": {
                                            title: "Archivo",
                                            type: "number"
                                        },
                                    }
                                },
                            },
                        }
                    }
                },
                {
                    if: {
                        properties: {
                            "sentBack": {
                                const: "Requerimiento de información adicional"
                            },
                        }
                    },
                    then: {
                        properties: {
                            "aditionalFiles": {
                                title: " ",
                                description: "Archivos Adicionales",
                                type: "array",
                                items: {
                                    "properties": {
                                        "documentName": {
                                            title: "Nombre Archivo",
                                            type: "string"
                                        },
                                        "document": {
                                            title: "Archivo",
                                            type: "number"
                                        },
                                    }
                                },
                            },
                        }
                    }
                }
            ]
        },
    },
    required: ["revisionListaOFAC", "revisionBuroCredito",
        "revisionListaONU", "matrizRiesgo", "nivelRiesgo"
    ],
}



const clientSchema = {
    title: "Información Cliente",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombres",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
        },
        "tipoIdentificacion": tipoIdentificacionPersonal,
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
        },
        "otro": {
            title: "Otro Número de Teléfono",
            type: "string",
        },
        // "numeroCliente": {
        //     title: "No. Cliente",
        //     type: "number",
        // },
        "comentarios": {
            title: "Comentarios",
            type: "string",
            enumNames: ["Instagram", "LinkedIn", "Página Web", "SMS", "Teléfono", "Twitter", "Visita Presencial", "Whatsapp"],
            enum: ["Instagram", "LinkedIn", "Pagina Web", "SMS", "Telefono", "Twitter", "Visita Presencial", "Whatsapp"],
        },
    },
    required: ["nombre", "apellidos", "email",
        "sexo", "estadoCivil",
        "celular"
    ],
    dependencies: {
        "tipoIdentificacion": {
            oneOf: [
                {
                    properties: {
                        "tipoIdentificacion": {
                            const: 1,
                        },
                        "numIdentificacionCedula": {
                            title: "No. de identificación Cédula",
                            type: "string",
                        },
                    },
                },
                {
                    properties: {
                        "tipoIdentificacion": {
                            const: 2,
                        },
                        "numIdentificacionPasaporte": {
                            title: "No. de identificación Pasaporte",
                            type: "string",
                        },
                    },
                },
                {
                    properties: {
                        "tipoIdentificacion": {
                            const: 3,
                        },
                        "numIdentificacionIdExtranjero": {
                            title: "No. de identificación Id Extranjero",
                            type: "string",
                        },
                    },
                },
            ],
        },

        "estadoCivil": {
            oneOf: [
                {
                    properties: {
                        "estadoCivil": {
                            const: "Soltero"
                        },
                    }
                },
                {
                    properties: {
                        "estadoCivil": {
                            const: "Casado"
                        },
                        // "datosConyuge": datosConyuge,
                    }
                },
                {
                    properties: {
                        "estadoCivil": {
                            const: "Union Libre"
                        },
                        // "datosConyuge": datosConyuge,
                    }
                },
            ]
        },
    },
}

const secondClientSchema = {
    title: "Información Cliente Adicional",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombres",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
        },
        tipoIdentificacionPersonal,
        "numIdentificacion": {
            title: "No. de identificación",
            type: "string",
        },
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
        },
        "otro": {
            title: "Otro",
            type: "string",
        },
        "comentarios": {
            title: "Comentarios",
            type: "string",
            enumNames: ["Instagram", "LinkedIn", "Página Web", "SMS", "Teléfono", "Twitter", "Visita Presencial", "Whatsapp"],
            enum: ["Instagram", "LinkedIn", "Pagina Web", "SMS", "Telefono", "Twitter", "Visita Presencial", "Whatsapp"],
        },
    },
    required: ["nombre", "apellidos", "email", "sexo", "estadoCivil",
        "celular"
    ],
}

const clientAditionalInfoSchema = {
    title: " ",
    type: "object",
    properties: {
        "fechaNacimiento": {
            title: "Fecha de Nacimiento",
            type: "string",
            format: "date",
        },
        "lugarNacimiento": {
            title: "Lugar de Nacimiento",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidad": {
            title: "Nacionalidad",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "direccionResidencia": {
            title: "Dirección de residencia",
            type: "string",
        },
        "sector": {
            title: "Sector",
            type: "string",
        },
        "ciudad": {
            title: "Ciudad",
            type: "string",
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "residenteRD": {
            title: "¿Es residente de la República Dominicana?",
            ...boolYesNoType,
            default: true
        },
        "zonaGeograficaNacional": {
            title: "Zona Geográfica Nacional",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional,
        },
        "profesion": {
            title: "Profesión",
            type: "string",
        },
        "ocupacion": {
            title: "Ocupación",
            type: "string",
            enumNames: ["Empleado Privado", "Empleado Público", "Independiente", "Retirado", "Pensionado", "Otro"],
            enum: ["Empleado Privado", "Empleado Publico", "Independiente", "Retirado", "Pensionado", "Otro"],
        },
        "otraFuenteIngresos": {
            title: "¿Tiene otras fuentes de ingresos formales?",
            ...boolYesNoType
        },
        "sujetoObligadoLey155": {
            title: "¿Es Sujeto Obligado de la Ley 155-17?",
            ...boolYesNoType
        },
    },
    required: [
        "fechaNacimiento", "lugarNacimiento", "nacionalidad", "direccionResidencia",
        "sector", "ciudad", "pais", "residenteRD", "zonaGeograficaNacional",
        "profesion", "ocupacion", "sujetoObligadoLey155",
    ],
    dependencies: {
        "ocupacion": {
            oneOf: [
                {
                    properties: {
                        "ocupacion": {
                            const: "Independiente",
                        },
                        "inscritoCedulaAnteDGII": {
                            title: "¿Está inscrito con su cédula ante la DGII?",
                            ...boolYesNoType
                        },
                    },
                },
                {
                    properties: {
                        "ocupacion": {
                            const: "Otro",
                        },
                        "inscritoCedulaAnteDGII": {
                            title: "¿Está inscrito con su cédula ante la DGII?",
                            ...boolYesNoType
                        },
                        "ocupacionOtro": {
                            title: "Explique:",
                            type: "string",
                        },
                    },
                },
                { properties: { "ocupacion": { const: "Empleado Privado", } } },
                { properties: { "ocupacion": { const: "Empleado Publico", } } },
                { properties: { "ocupacion": { const: "Retirado", } } },
                { properties: { "ocupacion": { const: "Pensionado", } } },
            ]
        },
    },
}

form.schema = {
    title: "Solicitud de Vinculación Cliente Personal",
    type: "object",
    properties: {
        "tipoCliente": clientTypeId,
        "tipoVinculacion": {
            title: "¿Tipo de Vinculación?",
            type: "string",
            oneOf: [
                {
                    title: "Cliente Nuevo JMMB",
                    const: "Cliente Nuevo JMMB"
                },
                {
                    title: "Vinculación a filial adicional",
                    const: "Vinculacion a filial adicional"
                },
                {
                    title: "Cliente existente solo Solicitud de Préstamo",
                    const: "Cliente existente solo Solicitud de Prestamo"
                },
            ],
        },
        "duracionRelacion": {
            title: "Duración de la relación",
            type: "string",
            enumNames: [
                "Cliente Nuevo ( Menos de 1 año)",
                "Más de 1 año menos de 3 años",
                "Más de 3 años menos de 5 años",
                "Más de 5 años"
            ],
            enum: [
                "Cliente Nuevo ( Menos de 1 ano)",
                "Mas de 1 ano menos de 3 anos",
                "Mas de 3 anos menos de 5 anos",
                "Mas de 5 anos"
            ],
        },
        "clientSchema": mergeObjects(clientSchema, clientAditionalInfoSchema),
        "datosLaboralesSchema": datosLaboralesSchema,
        "fatca": fatca,
        "declaracionJurada": declaracionJurada,
        "personaPoliticamenteExpuesta": personaPoliticamenteExpuesta,
        "informacionIntencionVinculacion": informacionIntencionVinculacion,
        "capacidadFinanciera": capacidadFinanciera,
        "matrizRiesgo": matrizRiesgo,
        "soporteDebidaDiligencia": soporteDebidaDiligencia,
    },
    required: ["tipoCliente", "oficinaPreferencia"],
};



form.uiSchema = {
    "ui:order": [
        "*",
        "datosConyuge",

        "matrizRiesgo",
        "soporteDebidaDiligencia"
    ],
    "datosConyuge": {
        "classNames": "smallTwoRows",
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
    },
    "refPersonales": {
        "refPersonalesArray": {
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
                "celular": {
                    "ui:widget": "PhoneWidget",
                },
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "celular": {
                "ui:widget": "PhoneWidget",
            },
        }
    },
    "datosFamiliares": {
        "refFamiliares": {
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
                "celular": {
                    "ui:widget": "PhoneWidget",
                },
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "celular": {
                "ui:widget": "PhoneWidget",
            },
        }

    },
    "matrizRiesgo": { "ui:field": "RiskMatrixField" },
    "tipoCliente": {
        "ui:readonly": true,
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "oficinaPreferencia": { "ui:field": "LookupFormField" },
    "tipoVinculacion": {
        // "ui:readonly": true,
    },
    "duracionRelacion": {
        // "ui:readonly": true,
    },
    "vinculacionMancomunada": {
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "clientSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [

            "nombre",
            "apellidos",
            "email",
            // "numeroCliente",

            "tipoIdentificacion",
            "numIdentificacionCedula",
            "numIdentificacionPasaporte",
            "numIdentificacionIdExtranjero",
            "sexo",
            "estadoCivil",
            "celular",
            "telefono",
            "otro",
            "comentarios",


            "fechaNacimiento",
            "lugarNacimiento",
            "direccionResidencia",
            "sector",
            "ciudad",
            "pais",
            "zonaGeograficaNacional",
            "residenteRD",
            "nacionalidad",
            "profesion",

            "ocupacion",
            "ocupacionOtro",
            "inscritoCedulaAnteDGII",
            "otraFuenteIngresos",
            "sujetoObligadoLey155",
            "tipoSujetoObligado",



            "datosConyuge",

        ],
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "datosConyuge": {
            "classNames": "from-col-1 to-col-3 smallTwoRows",
            "celular": {
                "ui:widget": "PhoneWidget",
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "tipoIdent": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
        },
        "tipoIdentificacion": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sexo": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "productosActuales": {
            "ui:widget": "checkboxes",
            "ui:options": {
                inline: false
            },
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },

        "fechaNacimiento": { yearsRange: [1900, 2030], },
        "nacionalidad": {
            "classNames": "from-col-1",
        },
        "residenteRD": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "otraFuenteIngresos": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "inscritoCedulaAnteDGII": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sujetoObligadoLey155": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tipoSujetoObligado": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },

    },
    "datosLaboralesSchema": {
        "classNames": "smallTwoRows",
        "fechaIngreso": { yearsRange: [1900, 2030], },
        "telefonoEmpresa": {
            "ui:widget": "PhoneWidget",
        },
        "faxEmpresa": {
            "ui:widget": "PhoneWidget",
        },
        "telefonoEmpleoAnterior": {
            "ui:widget": "PhoneWidget",
        },
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 3
            },
        },
    },
    "fatca": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "nacionalidadOtroPais",
            "indicarPaises",
            "residenciaFisicaUSA",
            "estatusMigratorio",
            "numIDFiscal",
            "multiplesPaisesResidenciaFiscal",
            "paisesResidenciaFiscal",
            "*",
        ],
        "nacionalidadOtroPais": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenciaFisicaUSA": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estatusMigratorio": {
            "classNames": "radio-column",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "multiplesPaisesResidenciaFiscal": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "paisesResidenciaFiscal": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 3
            },
        },
        "greenCard": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "direccionEnvioEstadosUnidos": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tieneDireccionResidenciaEstadosUnidos": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "telefonoFueraPais": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "telefonoEstadoUnidos": {
            "ui:widget": "PhoneWidget",
        },
    },
    "personaPoliticamenteExpuesta": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "personaExpuestaPoliticamente",
            "cargo",
            "pais",
            "fechaDesignacion",
            "fechaRemocion",
            "*"
        ],
        "personaExpuestaPoliticamente": {
            "classNames": "from-col-1 to-col-3",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "parentescoConPersonaExpuestaPoliticamente": {
            "classNames": "from-col-1 to-col-3",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "fechaDesignacion": { yearsRange: [1900, 2030], },
        "fechaRemocion": { yearsRange: [1900, 2030], },
        "fechaDesignacionPEP": { yearsRange: [1900, 2030], },
        "fechaRemocionPEP": { yearsRange: [1900, 2030], },
    },
    "informacionIntencionVinculacion": {
        "classNames": "smallThreeRows",
        "ui:order": [
            "institucionVincularse",
            "productosBanco",
            "productosPuesto",
            "productosSAFI",
            "savingsAccountSchema",
            "cuentaAhorroBasica",
            "soloMantieneEstaCuenta",
            "financialCertSchema",
            "inversionesSchema",
            "safiSchema",
            "*",
            "beneficiariosDeTransaccionDiferentes",
            "vinculadoJMMB",
            "empresaVinculada",
        ],
        "institucionVincularse": {
            "ui:widget": "checkboxes",
            "ui:options": {
                inline: true
            },
            "ui:readonly": true,
        },

        "productosBanco": {
            "classNames": "from-col-1",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": {
                inline: false
            },
            "ui:readonly": true,

        },
        "productosPuesto": {
            "classNames": "from-col-2",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
            "ui:readonly": true,
        },
        "productosSAFI": {
            "classNames": "from-col-3",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
            "ui:readonly": true,
        },

        "prestamos": {
            "classNames": "from-col-1 to-col-4",
            "proposito": {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 4
                },
            },
            "incluyeGarantia": {
                "garantiaSelection": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "garantiaSolidariaSchema": {
                    "garantiaSolidaria": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "descGarantias": {
                        "ui:options": {
                            "orderable": false,
                        }
                    },
                },
                // "codFiadFlag": { "ui:field": "ComputedField" },   
            },
            "codeudorFiador": {
                "codeudorFiadorSelection": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "datosCodeudorFiador": {
                    "classNames": "smallTwoRows",
                    "cedula": {
                        "ui:widget": "CedulaWidget",
                    },
                    "sexo": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "estadoCivil": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "tipoVivienda": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "fechaNacimiento": { yearsRange: [1900, 2030], },
                    "celular": {
                        "ui:widget": "PhoneWidget",
                    },
                    "telefono": {
                        "ui:widget": "PhoneWidget",
                    },
                    "otro": {
                        "ui:widget": "PhoneWidget",
                    },
                },
            },
            "formaDesembolso": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "comentarios": {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 4
                },
            },
            "descFacilidades": {
                "ui:options": {
                    "orderable": false,
                }
            },
            "productosActuales": {
                "ui:widget": "checkboxes",
                "ui:options": {
                    inline: false
                },
            },
            "datosVivienda": {
                "tipoVivienda": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "ui:order": [
                    "*", "tipoVivienda", "nombrePropietario", "tiempoResidiendo",
                ],

            },
            "refPersonales": {
                "refPersonalesArray": {
                    "ui:options": {
                        "orderable": false,
                    },
                    "items": {
                        "telefono": {
                            "ui:widget": "PhoneWidget",
                        },
                        "celular": {
                            "ui:widget": "PhoneWidget",
                        },
                    },
                }
            },
            "datosFamiliares": {
                "refFamiliares": {
                    "ui:options": {
                        "orderable": false,
                    },
                    "items": {
                        "telefono": {
                            "ui:widget": "PhoneWidget",
                        },
                        "celular": {
                            "ui:widget": "PhoneWidget",
                        },
                    },
                },
            },
            "ui:order": [
                "*", "garantiaSelection", "garantiaSolidariaSchema", "codeudorFiador", "datosCodeudorFiador", "descGarantias", "datosVivienda", "refPersonales",
                "datosFamiliares", "formaDesembolso", "banco", "tipoCuenta", "numeroCuenta", "comentarios",
            ],
        },

        "savingsAccountSchema": {
            "classNames": "smallThreeRows from-col-1 to-col-4",
        },

        "cuentaAhorroBasica": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },

        "soloMantieneEstaCuenta": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "financialCertSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
        },
        "inversionesSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "*",
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio"
            ],
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "safiSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "*",
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio"
            ],
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "transaccionesEsperadasSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "cantidadOperacionesMensuales": {
                "classNames": "from-col-1 to-col-3",
                "ui:widget": "radio",
            },
            "cantidadOperacionesMensualesOtro": {
                "classNames": "from-col-3",
            },
            "formaMasFrecuenteTransacciones": {
                "classNames": "from-col-1 to-col-4",
                "ui:widget": "radio",
            },
            "ui:order": [
                "cantidadOperacionesMensuales",
                "cantidadOperacionesMensualesOtro",
                "*",
                "formaMasFrecuenteTransacciones"
            ]
        },
        "camposBancoSchema": {
            "classNames": "from-col-1 to-col-4",
            "beneficiariosDeTransaccionDiferentes": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "vinculadoJMMB": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "ui:order": [
                "beneficiariosDeTransaccionDiferentes",
                "nombreBeneficiario",
                "vinculadoJMMB",
                "empresaVinculada",
                "*",
            ]

        },
        "perfilInversionista": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "riskProdServTitle",
                "riesgoProductosServiciosEdad",
                "riesgoProductosServiciosObjetivo",
                "criteriosIdeasRentabilidad",
                "nivelAcademico",
                "*",
            ],
            "riesgoProductosServiciosEdad": {
                "classNames": "from-col-1 radio-column",
                "ui:widget": "radio",
            },
            "riesgoProductosServiciosObjetivo": {
                "classNames": "from-col-2 pad-right radio-column",
                "ui:widget": "radio",
            },
            "criteriosIdeasRentabilidad": {
                "classNames": "radio-column",
                "ui:widget": "radio",
            },
            "nivelAcademico": {
                "classNames": "from-col-1 to-col-4",
                "ui:widget": "radio",
            },
            "plazoDeseaCumplirObjetivos": {
                "ui:widget": "radio",
            },
            "plazoAnticipaNecesitarInversiones": {
                "ui:widget": "radio",
            },
            "capacidadAbsorverPerdidas": {
                "ui:widget": "radio",
            },
            "operacionesFinancierasNegociado": {
                "classNames": "from-col-1 to-col-4",
                "titles": {
                    "classNames": "smallFiveRows",
                    "col1": { "classNames": "from-col-3" },
                },
                "inversionCertificadosFinancieros": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "bonosSoberanos": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "bonosCorporativos": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "fondosInversionesMutuos": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "fondosCerrados": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "otrosDerivados": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1" },
                },
            },
        },
        "analisisPatrimonio": {
            "classNames": "from-col-1 to-col-4",
            PeriodosFiscales1: {
                "classNames": "smallFourRows",
            },
            PeriodosFiscales2: {
                "classNames": "smallFourRows",
            },
        },
        "resultadoPerfil": {
            "classNames": "from-col-1 to-col-4",
            "ui:order": [
                "fechaActualizacionExpediente",
                "indicarInformacionActualizadaYFuente",
                "relacionCaraCara",
                "canalNoCaraCara",
                "otroCanalNoCaraCara",
                "*",
                "clienteProfesional",
                "consideradoNoProfesional",
                "tolerancia",
                "clienteBancarizado"
            ],
            "fechaActualizacionExpediente": { yearsRange: [1900, 2030], },
            "relacionCaraCara": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "resumenCliente": {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 3
                },
            },
            "productoSolicitadoAjustaCliente": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteCorredorValores": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteActivosLiquidosMayor25m": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "actualizacionExpedienteCliente": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "acuerdoConPerfilObtenido": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteProfesional": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteBancarizado": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
        },
    },
    "capacidadFinanciera": {
        "classNames": "from-col-1 to-col-4 smallThreeRows",
        "ui:order": [
            "ingresosAnuales",
            "totalActivosLiquidos",
            "patrimonioTotal",

            "capacidadAhorro",
            "porcientoCuentasAhorroOCorriente",
            "porcientoDeudas",
            "porcientoOtro",

            "referenciasBancarias",
            "referenciasComerciales",
        ],
        "ingresosAnuales": {
            "ui:widget": "radio",
        },
        "totalActivosLiquidos": {
            "ui:widget": "radio",
        },
        "patrimonioTotal": {
            "ui:widget": "radio",
        },
        "porcientoOtro": {
            "classNames": "from-col-2",
        },
        "referenciasBancarias": {
            "classNames": "from-col-1 to-col-4",
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
            },
        },
        "referenciasComerciales": {
            "classNames": "from-col-1 to-col-4",
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
            },
        },
    },
    "datosVivienda": {
        "classNames": "smallTwoRows",
        "tipoVivienda": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "ui:order": [
            "*", "tipoVivienda", "nombrePropietario", "tiempoResidiendo",
        ],

    },
    "soporteDebidaDiligencia": {
        "ui:field": "HideObjectField",
        "classNames": "soporteDebidaDiligencia",
        "revisionListaOFAC": { "ui:field": "FileField" },
        "revisionBuroCredito": { "ui:field": "FileField" },
        "revisionListaONU": { "ui:field": "FileField" },
        "matrizRiesgo": { "ui:field": "FileField" },
        "tieneOFAC": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tieneONU": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sentBack": {
            "joli:hideAttr": "hide",
        },
        "aditionalFiles": {
            "classNames": "aditionalFiles",
            "items": { "document": { "ui:field": "FileField" } }
        },
    },
    "codeudorFiador": {
        "codeudorFiadorSelection": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "datosCodeudorFiador": {
            "classNames": "smallTwoRows",
            "sexo": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "estadoCivil": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "tipoVivienda": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "fechaNacimiento": { yearsRange: [1900, 2030], },
            "celular": {
                "ui:widget": "PhoneWidget",
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "otro": {
                "ui:widget": "PhoneWidget",
            },
        }
    },
}


form.objectMap = {
    // Fields not implemented:
    //  Fields with wrong type in db:
    // 
    // 
    "productInstitutions": "informacionIntencionVinculacion.institucionVincularse",
    "productIds.1": "informacionIntencionVinculacion.productosBanco",
    "productIds.2": "informacionIntencionVinculacion.productosPuesto",
    "productIds.3": "informacionIntencionVinculacion.productosSAFI",
    "clients[$idx].relationshipType": "tipoVinculacion",
    "clients[$idx].relationshipDuration": "duracionRelacion",
    "clients[$idx].clientTypeId": "tipoCliente",
    "clients[$idx].preferredBranchId": "oficinaPreferencia",
    "clients[$idx].retailClientBasicInfo.firstName": "clientSchema.nombre",
    "clients[$idx].retailClientBasicInfo.lastName": "clientSchema.apellidos",
    "clients[$idx].retailClientBasicInfo.email": "clientSchema.email",
    "clients[$idx].retailClientBasicInfo.identificationType": "clientSchema.tipoIdentificacion",
    "clients[$idx].retailClientBasicInfo.identificationNumber": "clientSchema.numIdentificacionCedula",
    "clients[$idx].retailClientBasicInfo.passportNumber": "clientSchema.numIdentificacionPasaporte",
    "clients[$idx].retailClientBasicInfo.foreignIdNumber": "clientSchema.numIdentificacionIdExtranjero",
    "clients[$idx].retailClientBasicInfo.sex": "clientSchema.sexo",
    "clients[$idx].retailClientBasicInfo.civilStatus": "clientSchema.estadoCivil",
    "clients[$idx].retailClientBasicInfo.phone": "clientSchema.telefono",
    "clients[$idx].retailClientBasicInfo.phoneMobile": "clientSchema.celular",
    "clients[$idx].retailClientBasicInfo.phoneOther": "clientSchema.otro",

    "clients[$idx].retailClientBasicInfo.dateOfBirth": "clientSchema.fechaNacimiento",
    "clients[$idx].retailClientBasicInfo.countryOfBirth": "clientSchema.lugarNacimiento",
    "clients[$idx].retailClientBasicInfo.nationality": "clientSchema.nacionalidad",
    "clients[$idx].retailClientBasicInfo.address": "clientSchema.direccionResidencia",
    "clients[$idx].retailClientBasicInfo.addressSector": "clientSchema.sector",
    "clients[$idx].retailClientBasicInfo.city": "clientSchema.ciudad",
    "clients[$idx].retailClientBasicInfo.country": "clientSchema.pais",
    "clients[$idx].retailClientBasicInfo.residentOfDominicanRepublic": "clientSchema.residenteRD",
    "clients[$idx].retailClientBasicInfo.profession": "clientSchema.profesion",
    "clients[$idx].retailClientBasicInfo.occupation": "clientSchema.ocupacion",
    "clients[$idx].retailClientBasicInfo.isSubjectToLaw15517": "clientSchema.sujetoObligadoLey155",
    "clients[$idx].retailClientBasicInfo.obligationType": "clientSchema.tipoSujetoObligado",
    "clients[$idx].retailClientBasicInfo.nationalGeographicZone": "clientSchema.zonaGeograficaNacional",
    "clients[$idx].retailClientBasicInfo.hasOtherSourcesOfIncome": "clientSchema.otraFuenteIngresos",
    "clients[$idx].retailClientBasicInfo.otherOccupation": "clientSchema.ocupacionOtro",
    "clients[$idx].retailClientBasicInfo.subscribedAgainstDGII": "clientSchema.inscritoCedulaAnteDGII",
    "clients[$idx].retailClientBasicInfo.comments": "clientSchema.comentarios",
    "clients[$idx].retailClientBasicInfo.hasBeneficiaries": "informacionIntencionVinculacion.camposBancoSchema.beneficiariosDeTransaccionDiferentes",
    "clients[$idx].retailClientBasicInfo.isRelatedToFranchise": "informacionIntencionVinculacion.camposBancoSchema.vinculadoJMMB",
    "clients[$idx].retailClientBasicInfo.hasBasicSaving": "informacionIntencionVinculacion.cuentaAhorroBasica",
    "clients[$idx].retailClientBasicInfo.fundDestination": "informacionIntencionVinculacion.camposBancoSchema.destinoFondos",
    "clients[$idx].retailClientBasicInfo.onlyMaintainsThisAccount": "informacionIntencionVinculacion.soloMantieneEstaCuenta",
    "clients[$idx].retailClientBasicInfo.beneficiaryName": "informacionIntencionVinculacion.camposBancoSchema.nombreBeneficiario",
    "clients[$idx].retailClientBasicInfo.linkedBusiness": "informacionIntencionVinculacion.camposBancoSchema.empresaVinculada",
    "clients[$idx].retailClientBasicInfo.monthlyTransactions": "informacionIntencionVinculacion.transaccionesEsperadasSchema.cantidadOperacionesMensuales",
    "clients[$idx].retailClientBasicInfo.frequentTransactionTypes": "informacionIntencionVinculacion.transaccionesEsperadasSchema.formaMasFrecuenteTransacciones",
    "clients[$idx].retailClientBasicInfo.otherMonthlyTransactions": "informacionIntencionVinculacion.transaccionesEsperadasSchema.cantidadOperacionesMensualesOtro",

    "clients[$idx].retailClientBasicInfo.paymentCapacityIndicator": "informacionIntencionVinculacion.prestamos.indicCapacidadPago",
    "clients[$idx].retailClientBasicInfo.cem": "informacionIntencionVinculacion.prestamos.CEM",

    "clients[$idx].retailClientFatcaInfo.hasOtherNationality": "fatca.nacionalidadOtroPais",
    "clients[$idx].retailClientFatcaInfo.otherNationalities": "fatca.indicarPaises",
    "clients[$idx].retailClientFatcaInfo.isUnitedStatesResident": "fatca.residenciaFisicaUSA",
    "clients[$idx].retailClientFatcaInfo.unitedStatesImmigrationStatus": "fatca.estatusMigratorio",
    "clients[$idx].retailClientFatcaInfo.fiscalId": "fatca.numIDFiscal",
    "clients[$idx].retailClientFatcaInfo.hasOtherFiscalResidency": "fatca.multiplesPaisesResidenciaFiscal",
    "clients[$idx].retailClientFatcaInfo.otherFiscalResidencies": "fatca.paisesResidenciaFiscal",
    "clients[$idx].retailClientFatcaInfo.hasGreencard": "fatca.greenCard",
    "clients[$idx].retailClientFatcaInfo.hasUnitedStatesMailingAddress": "fatca.direccionEnvioEstadosUnidos",
    "clients[$idx].retailClientFatcaInfo.hasInternationalPhoneNumber": "fatca.telefonoFueraPais",
    "clients[$idx].retailClientFatcaInfo.unitedStatesPhone": "fatca.telefonoEstadoUnidos",
    "clients[$idx].retailClientFatcaInfo.comments": "fatca.comentarios",
    "clients[$idx].retailClientFatcaInfo.hasUnitedStatesResidenceAddress": "fatca.tieneDireccionResidenciaEstadosUnidos",
    "clients[$idx].retailClientFatcaInfo.unitedStatesAddress": "fatca.direccionResidenciaEstadosUnidos",


    "clients[$idx].retailClientFinancialCapacity.yearlyIncome": "capacidadFinanciera.ingresosAnuales",
    "clients[$idx].retailClientFinancialCapacity.savingCapacityPct": "capacidadFinanciera.capacidadAhorro",
    "clients[$idx].retailClientFinancialCapacity.liquidAssets": "capacidadFinanciera.totalActivosLiquidos",
    "clients[$idx].retailClientFinancialCapacity.liquidAssetsInCheckingsPct": "capacidadFinanciera.porcientoCuentasAhorroOCorriente",
    "clients[$idx].retailClientFinancialCapacity.netWorth": "capacidadFinanciera.patrimonioTotal",
    "clients[$idx].retailClientFinancialCapacity.debtPct": "capacidadFinanciera.porcientoDeudas",
    "clients[$idx].retailClientFinancialCapacity.otherPct": "capacidadFinanciera.porcientoOtro",
    "clients[$idx].retailClientFinancialCapacity.bankingReferences": {
        array: "capacidadFinanciera.referenciasBancarias",
        map: {
            "bank": "banco",
            "typeOfAccount": "tipoCuenta",
            "phone": "telefono",
            "oficial": "oficial",
        }
    },

    "clients[$idx].retailClientFinancialCapacity.commercialReferences": {
        array: "capacidadFinanciera.referenciasComerciales",
        map: {
            "name": "nombre",
            "phone": "telefono",
            "address": "direccion",
        }
    },

    "clients[$idx].retailClientInvestorProfile.clientAge": "informacionIntencionVinculacion.perfilInversionista.riesgoProductosServiciosEdad",
    "clients[$idx].retailClientInvestorProfile.mainGoal": "informacionIntencionVinculacion.perfilInversionista.riesgoProductosServiciosObjetivo",
    "clients[$idx].retailClientInvestorProfile.riskProfile": "informacionIntencionVinculacion.perfilInversionista.criteriosIdeasRentabilidad",
    "clients[$idx].retailClientInvestorProfile.academicLevel": "informacionIntencionVinculacion.perfilInversionista.nivelAcademico",
    // "clients[$idx].retailClientInvestorProfile.financialOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado",
    "clients[$idx].retailClientInvestorProfile.investmentInFinanceCertificatesYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.inversionCertificadosFinancieros.anosManejandoProducto",
    "clients[$idx].retailClientInvestorProfile.investmentInFinanceCertificatesYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.inversionCertificadosFinancieros.numOperacionesAnuales",
    "clients[$idx].retailClientInvestorProfile.investmentInFinanceCertificatesKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.inversionCertificadosFinancieros.nivelConocimiento",
    "clients[$idx].retailClientInvestorProfile.riskFreeBondsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosSoberanos.anosManejandoProducto",
    "clients[$idx].retailClientInvestorProfile.riskFreeBondsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosSoberanos.numOperacionesAnuales",
    "clients[$idx].retailClientInvestorProfile.riskFreeBondsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosSoberanos.nivelConocimiento",
    "clients[$idx].retailClientInvestorProfile.corporateBondsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosCorporativos.anosManejandoProducto",
    "clients[$idx].retailClientInvestorProfile.corporateBondsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosCorporativos.numOperacionesAnuales",
    "clients[$idx].retailClientInvestorProfile.corporateBondsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosCorporativos.nivelConocimiento",
    "clients[$idx].retailClientInvestorProfile.mutualFundsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosInversionesMutuos.anosManejandoProducto",
    "clients[$idx].retailClientInvestorProfile.mutualFundsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosInversionesMutuos.numOperacionesAnuales",
    "clients[$idx].retailClientInvestorProfile.mutualFundsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosInversionesMutuos.nivelConocimiento",
    "clients[$idx].retailClientInvestorProfile.closedFundsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosCerrados.anosManejandoProducto",
    "clients[$idx].retailClientInvestorProfile.closedFundsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosCerrados.numOperacionesAnuales",
    "clients[$idx].retailClientInvestorProfile.closedFundsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosCerrados.nivelConocimiento",
    "clients[$idx].retailClientInvestorProfile.otherDerivatives": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.indicar",
    "clients[$idx].retailClientInvestorProfile.otherDerivativesYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.anosManejandoProducto",
    "clients[$idx].retailClientInvestorProfile.otherDerivativesYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.numOperacionesAnuales",
    "clients[$idx].retailClientInvestorProfile.otherDerivativesKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.nivelConocimiento",

    "clients[$idx].retailClientInvestorProfile.expectedGoalTerm": "informacionIntencionVinculacion.perfilInversionista.plazoDeseaCumplirObjetivos",
    "clients[$idx].retailClientInvestorProfile.needOfInvestmentTerm": "informacionIntencionVinculacion.perfilInversionista.plazoAnticipaNecesitarInversiones",
    "clients[$idx].retailClientInvestorProfile.abilityToAbsorbLoses": "informacionIntencionVinculacion.perfilInversionista.capacidadAbsorverPerdidas",

    // "clients[$idx].retailClientLoanInfo.warrantyType": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.tipoGarantia",
    "clients[$idx].retailClientLoanInfo.typeOfRelationshipToOtherClient": "informacionIntencionVinculacion.prestamos.codeudorFiador.codeudorFiadorSelection",
    "clients[$idx].retailClientLoanInfo.relationshipToRequestClient": "informacionIntencionVinculacion.prestamos.incluyeGarantia.tipoVinculacion",
    "clients[$idx].retailClientLoanInfo.purpose": "informacionIntencionVinculacion.prestamos.proposito",
    "clients[$idx].retailClientLoanInfo.comments": "informacionIntencionVinculacion.prestamos.comentarios",
    "clients[$idx].retailClientBasicInfo.timeLivingInCountry": "informacionIntencionVinculacion.prestamos.tiempoPais",
    "clients[$idx].retailClientBasicInfo.dependants": "informacionIntencionVinculacion.prestamos.dependientes",

    "clients[$idx].retailClientBasicInfo.actualProductsArray": "informacionIntencionVinculacion.prestamos.productosActuales",
    "clients[$idx].retailClientLoanInfo.warrantyDescriptions": {
        array: "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias",
        map: {
            "warrantyNumber": "noDeGarantia",
            "numAssociatedFacilities": "noFacilidadAsociada",
            "typeOfWarranty": "tipoDeGarantia",
            "warrantyDescription": "descGarantia",
            "value": "valGarantia",
        }
    },
    "clients[$idx].retailClientLoanInfo.currentResidenceType": "informacionIntencionVinculacion.prestamos.datosVivienda.tipoVivienda",
    "clients[$idx].retailClientLoanInfo.residenceOwner": "informacionIntencionVinculacion.prestamos.datosVivienda.nombrePropietario",
    "clients[$idx].retailClientLoanInfo.timeInResidence": "informacionIntencionVinculacion.prestamos.datosVivienda.tiempoResidiendo",
    "clients[$idx].retailClientLoanInfo.spouseFirstName": "clientSchema.datosConyuge.nombres",
    "clients[$idx].retailClientLoanInfo.spouseLastName": "clientSchema.datosConyuge.apellidos",
    "clients[$idx].retailClientLoanInfo.spouseEmail": "clientSchema.datosConyuge.email",
    "clients[$idx].retailClientLoanInfo.spouseIdentificationType": "clientSchema.datosConyuge.tipoIdent", // Need to change tipoIdent to int
    "clients[$idx].retailClientLoanInfo.spouseIdentificationNumber": "clientSchema.datosConyuge.numIdent",
    "clients[$idx].retailClientLoanInfo.spousePhoneMobile": "clientSchema.datosConyuge.celular",
    "clients[$idx].retailClientLoanInfo.spousePhone": "clientSchema.datosConyuge.telefono",
    "clients[$idx].retailClientLoanInfo.spouseCompany": "clientSchema.datosConyuge.labora",
    "clients[$idx].retailClientLoanInfo.spouseJobTitle": "clientSchema.datosConyuge.cargo",
    "clients[$idx].retailClientLoanInfo.spouseMonthlyIncome": "clientSchema.datosConyuge.ingMens",
    "clients[$idx].retailClientLoanInfo.spouseAge": "clientSchema.datosConyuge.edad",
    "clients[$idx].retailClientLoanInfo.personalReferences": {
        array: "informacionIntencionVinculacion.prestamos.refPersonales.refPersonalesArray",
        map: {
            "name": "nombre",
            "relationship": "parentesco",
            "phone": "telefono",
            "phoneMobile": "celular",
        }
    },
    "clients[$idx].retailClientLoanInfo.familyReferences": {
        array: "informacionIntencionVinculacion.prestamos.datosFamiliares.refFamiliares",
        map: {
            "name": "nombre",
            "relationship": "parentesco",
            "address": "direccion",
            "phone": "telefono",
            "cellphone": "celular",
        }
    },

    "clients[$idx].retailClientLoanInfo.lifeInsurance": "informacionIntencionVinculacion.prestamos.seguroVida",
    "clients[$idx].retailClientLoanInfo.processing": "informacionIntencionVinculacion.prestamos.tramitacion",
    "clients[$idx].retailClientLoanInfo.executive": "informacionIntencionVinculacion.prestamos.ejecutivo",
    "clients[$idx].retailClientLoanInfo.referredBy": "informacionIntencionVinculacion.prestamos.referidoPor",
    "clients[$idx].retailClientLoanInfo.disbursementForm": "informacionIntencionVinculacion.prestamos.formaDesembolso",
    "clients[$idx].retailClientLoanInfo.transferBankName": "informacionIntencionVinculacion.prestamos.banco",
    "clients[$idx].retailClientLoanInfo.transferAccountType": "informacionIntencionVinculacion.prestamos.tipoCuenta",
    "clients[$idx].retailClientLoanInfo.transferAccountNumber": "informacionIntencionVinculacion.prestamos.numeroCuenta",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.coDebtorOrGuarantor": "informacionIntencionVinculacion.prestamos.codeudorFiador.codeudorFiadorSelection",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.firstName": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nombre",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.lastName": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.apellido",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.identificationCard": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.cedula",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.birthDate": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.fechaNacimiento",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.birthPlace": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.lugarNacimiento",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.nationality": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nacionalidad",
    // "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.isUSANationality": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nacionalidadUSA",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.passportNumber": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.numeroPasaporte",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.isUSAResident": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.residenteUSA",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.timeLivingInCountry": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tiempoPais",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.phone": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.telefono",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.cellphone": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.celular",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.otherNumber": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.otro",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.email": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.email",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.address": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.direccion",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.sector": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.sector",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.city": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.ciudad",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.province": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.provincia",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.country": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.pais",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.timeResiding": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tiempoResidiendo",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.gender": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.sexo",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.civilStatus": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.estadoCivil",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.dependants": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.dependientes",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.typePlace": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tipoVivienda",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.academicLevel": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nivelAcademico",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.srcIncome": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.fuentesIngreso",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.monthlyIncome": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.ingresosMensuales",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.workingDuration": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tiempoLaborando",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.linkageType": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tipoVinculacion",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.otherOccupation": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.ocupacionOtro",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.ownerName": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nombrePropietario",
    "clients[$idx].retailClientLoanInfo.coDebtorGuarantor.debtPercentage": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.porcientoDeudas",

    "clients[$idx].retailClientPoliticalExposureInfo.hasPoliticalExposure": "personaPoliticamenteExpuesta.personaExpuestaPoliticamente",
    "clients[$idx].retailClientPoliticalExposureInfo.jobTitle": "personaPoliticamenteExpuesta.cargo",
    "clients[$idx].retailClientPoliticalExposureInfo.country": "personaPoliticamenteExpuesta.pais",
    "clients[$idx].retailClientPoliticalExposureInfo.startDate": "personaPoliticamenteExpuesta.fechaDesignacion",
    "clients[$idx].retailClientPoliticalExposureInfo.endDate": "personaPoliticamenteExpuesta.fechaRemocion",
    "clients[$idx].retailClientPoliticalExposureInfo.hasPoliticalExposureRelative": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente",
    "clients[$idx].retailClientPoliticalExposureInfo.relativeName": "personaPoliticamenteExpuesta.nombrePEP",
    "clients[$idx].retailClientPoliticalExposureInfo.relativeAfinity": "personaPoliticamenteExpuesta.parentescoPEP",
    "clients[$idx].retailClientPoliticalExposureInfo.relativeJobTitle": "personaPoliticamenteExpuesta.cargoPEP",
    "clients[$idx].retailClientPoliticalExposureInfo.relativeCountry": "personaPoliticamenteExpuesta.paisPEP",
    "clients[$idx].retailClientPoliticalExposureInfo.relativeStartDate": "personaPoliticamenteExpuesta.fechaDesignacionPEP",
    "clients[$idx].retailClientPoliticalExposureInfo.relativeEndDate": "personaPoliticamenteExpuesta.fechaRemocionPEP",

    "clients[$idx].retailClientWorkInfo.companyName": "datosLaboralesSchema.empresa",
    "clients[$idx].retailClientWorkInfo.companyPhone": "datosLaboralesSchema.telefonoEmpresa",
    "clients[$idx].retailClientWorkInfo.companyEmail": "datosLaboralesSchema.emailLaboral",
    "clients[$idx].retailClientWorkInfo.address": "datosLaboralesSchema.direccionEmpresa",
    "clients[$idx].retailClientWorkInfo.sector": "datosLaboralesSchema.sector",
    "clients[$idx].retailClientWorkInfo.city": "datosLaboralesSchema.ciudad",
    "clients[$idx].retailClientWorkInfo.country": "datosLaboralesSchema.pais",
    "clients[$idx].retailClientWorkInfo.mainActivity": "datosLaboralesSchema.actividadPrincipal",
    "clients[$idx].retailClientWorkInfo.position": "datosLaboralesSchema.cargo",
    "clients[$idx].retailClientWorkInfo.startDate": "datosLaboralesSchema.fechaIngreso",
    "clients[$idx].retailClientWorkInfo.comments": "datosLaboralesSchema.comentarios",
    "clients[$idx].retailClientWorkInfo.province": "datosLaboralesSchema.provincia",
    "clients[$idx].retailClientBasicInfo.monthlyIncome": "datosLaboralesSchema.salarioMensual",  // NOTE: RETAIL CLIENT BASIC INFO
    "clients[$idx].retailClientWorkInfo.companyFax": "datosLaboralesSchema.faxEmpresa",
    "clients[$idx].retailClientWorkInfo.previousEmploymentPosition": "datosLaboralesSchema.cargoEmpleoAnterior",
    "clients[$idx].retailClientWorkInfo.previousEmploymentDuration": "datosLaboralesSchema.tiempoEmpleoAnterior",
    "clients[$idx].retailClientWorkInfo.previousEmploymentPhone": "datosLaboralesSchema.telefonoEmpleoAnterior",
    "clients[$idx].retailClientWorkInfo.previousEmploymentEmail": "datosLaboralesSchema.emailEmpleoAnterior",
    "clients[$idx].retailClientWorkInfo.previousEmploymentName": "datosLaboralesSchema.nombreEmpleoAnterior",


    "clients[$idx].retailClientProfileResult.fileUpdated": "informacionIntencionVinculacion.resultadoPerfil.fechaActualizacionExpediente",
    "clients[$idx].retailClientProfileResult.formUpdates": "informacionIntencionVinculacion.resultadoPerfil.indicarInformacionActualizadaYFuente",
    "clients[$idx].retailClientProfileResult.isFaceToFace": "informacionIntencionVinculacion.resultadoPerfil.relacionCaraCara",
    "clients[$idx].retailClientProfileResult.relationshipChannel": "informacionIntencionVinculacion.resultadoPerfil.canalNoCaraCara",
    "clients[$idx].retailClientProfileResult.summary": "informacionIntencionVinculacion.resultadoPerfil.resumenCliente",
    "clients[$idx].retailClientProfileResult.isProductAdjusted": "informacionIntencionVinculacion.resultadoPerfil.productoSolicitadoAjustaCliente",
    "clients[$idx].retailClientProfileResult.isFinancialClient": "informacionIntencionVinculacion.resultadoPerfil.clienteCorredorValores",
    "clients[$idx].retailClientProfileResult.isHighNetWorth": "informacionIntencionVinculacion.resultadoPerfil.clienteActivosLiquidosMayor25m",
    "clients[$idx].retailClientProfileResult.typeOfProspect": "informacionIntencionVinculacion.resultadoPerfil.clienteProfesional",
    "clients[$idx].retailClientProfileResult.isNotProfessional": "informacionIntencionVinculacion.resultadoPerfil.consideradoNoProfesional",
    "clients[$idx].retailClientProfileResult.relationshipChannelOther": "informacionIntencionVinculacion.resultadoPerfil.otroCanalNoCaraCara",
    "clients[$idx].retailClientProfileResult.tolerance": "informacionIntencionVinculacion.resultadoPerfil.tolerancia",
    "clients[$idx].retailClientProfileResult.banked": "informacionIntencionVinculacion.resultadoPerfil.clienteBancarizado",
    "clients[$idx].retailClientProfileResult.acknowledgement": "informacionIntencionVinculacion.resultadoPerfil.acuerdoConPerfilObtenido",
    "clients[$idx].retailClientProfileResult.updatedFileClient": "informacionIntencionVinculacion.resultadoPerfil.actualizacionExpedienteCliente",

    "clients[$idx].retailClientDueDiligenceReport.inOFACList": "soporteDebidaDiligencia.tieneOFAC",
    "clients[$idx].retailClientDueDiligenceReport.inONUList": "soporteDebidaDiligencia.tieneONU",
    "clients[$idx].retailClientDueDiligenceReport.riskLevel": "soporteDebidaDiligencia.nivelRiesgo",

    "clients[$idx].retailClientDueDiligenceReport.ofacRevisionFileId": "soporteDebidaDiligencia.revisionListaOFAC",
    "clients[$idx].retailClientDueDiligenceReport.creditBuroRevisionFileId": "soporteDebidaDiligencia.revisionBuroCredito",
    "clients[$idx].retailClientDueDiligenceReport.listRevisionFileId": "soporteDebidaDiligencia.revisionListaONU",
    "clients[$idx].retailClientDueDiligenceReport.riskMatrixFileId": "soporteDebidaDiligencia.matrizRiesgo",
    "clients[$idx].retailClientDueDiligenceReport.dueDiligenceResult": "soporteDebidaDiligencia.sentBack",
    "clients[$idx].retailClientDueDiligenceReport.additionalFilesList.items": {
        array: "soporteDebidaDiligencia.aditionalFiles",
        map: {
            "name": "documentName",
            "fileId": "document",
        }
    },

    // Cuenta Ahorro

    "clients[$idx].retailClientBasicInfo.rdsAmountSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.depositoEstimadoRD",
    "clients[$idx].retailClientBasicInfo.rdsFundsOriginSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.origenFondosRD",
    "clients[$idx].retailClientBasicInfo.rdsFundsOriginOtherSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.origenFondosRDOtro",

    "clients[$idx].retailClientBasicInfo.swornDeclarationArray[0]": "declaracionJurada.declaracionJurada1",
    "clients[$idx].retailClientBasicInfo.swornDeclarationArray[1]": "declaracionJurada.declaracionJurada2",
    "clients[$idx].retailClientBasicInfo.swornDeclarationArray[2]": "declaracionJurada.declaracionJurada3",
    "clients[$idx].retailClientBasicInfo.swornDeclarationArray[3]": "declaracionJurada.declaracionJurada4",

    "clients[$idx].retailClientNetWorthInfo.fiscalYearOne": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.anosPeriodosFiscales1",
    "clients[$idx].retailClientNetWorthInfo.yearOneRDSNetWorth": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.totalPatrimonio1",
    "clients[$idx].retailClientNetWorthInfo.yearOneRDSLiquidAssets": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.totalActivosLiquidos1",
    "clients[$idx].retailClientNetWorthInfo.yearOneEquityAnalisis": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.resultadoAnalisisPatrimonio1",

    "clients[$idx].retailClientNetWorthInfo.fiscalYearTwo": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales2.anosPeriodosFiscales2",
    "clients[$idx].retailClientNetWorthInfo.yearTwoRDSNetWorth": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales2.totalPatrimonio2",
    "clients[$idx].retailClientNetWorthInfo.yearTwoRDSLiquidAssets": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales2.totalActivosLiquidos2",
    "clients[$idx].retailClientNetWorthInfo.yearTwoEquityAnalisis": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.resultadoAnalisisPatrimonio2",

}


export default form;