import secondaryClientForm from '../secondary/LastScreenSchema';

const form = {
    title: "Información del Caso",
    displayName: 'LastScreen',
    page: 'end',
    noSubmit: true,
    secondaryClientForm
};


form.schema = {
    title: "Cierre de vinculación de cliente en JMMB",
    type: "object",
    properties: {
        "infCliUCS": {
            title: "Informaciones Cliente UCS",
            type: "object",
            properties: {
                "numCliUCS": {
                    title: "Número de cliente en UCS",
                    type: "string"
                },
                "infCliBanco": {
                    title: "Informaciones Cliente T24 Banco",
                    type: "object",
                    properties: {
                        "numCliT24Banco": {
                            title: "Número de cliente en T24 Banco",
                            type: "string"
                        },
                        "numCtaT24Banco": {
                            title: "Número de cuenta en T24 Banco",
                            type: "string"
                        },
                    },
                },
                "actCliT24": {
                    title: "Activación del Cliente en T24",
                    type: "object",
                    properties: {
                        "pantConfT24": {
                            title: "Pantalla Confirmación T24",
                            type: "number"
                        },
                    },
                },
            },

        },
        "infCliDIMSA": {
            title: "Informaciones Cliente DIMSA",
            type: "object",
            properties: {
                "numCliDIMSA": {
                    title: "Número de cliente en DIMSA",
                    type: "string"
                },
                "numRNT": {
                    title: "Número RNT",
                    type: "string"
                },
                "ctaValoresPosturaPropia": {
                    title: "Cuenta Valores Postura Propia",
                    type: "string"
                },
                "ctaValoresSureInv": {
                    title: "Cuenta Valores Sure Investor",
                    type: "string"
                },
            },

        },
        "infCliT24SAFI": {
            title: " ",
            description: "Informaciones Cliente T24SAFI",
            type: "object",
            properties: {
                "numCliT24SAFI": {
                    title: "Número de cliente en T24 SAFI",
                    type: "string"
                },
                "numEncargoDineroDOP": {
                    title: "Número de Aportante FM Mercado de Dinero (DOP)",
                    type: "string",
                },
                "numEncargoDineroUSD": {
                    title: "Número de Aportante FM Mercado de Dinero (USD)",
                    type: "string",
                },
                "numEncargoPlazoDOP": {
                    title: "Número de Aportante FM Plazo 90 días (DOP)",
                    type: "string",
                },
            },

        },
    },
}

form.uiSchema = {
    "ui:field": "HideObjectField",
    "infCliUCS": {
        "actCliT24": {
            "pantConfT24": {
                "ui:field": "FileField"
            },
        },
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 1
            }
        }
    },
    "infCliDIMSA": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 2
            }
        }
    },
    "infCliT24SAFI": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 3
            }
        }
    },
}

form.objectMap = {
    "clients[0].retailClientInvestorProfile.uscClientNumber": "infCliUCS.numCliUCS",
    "clients[0].retailClientInvestorProfile.t24BankClientId": "infCliUCS.infCliBanco.numCliT24Banco",
    "clients[0].retailClientInvestorProfile.t24BankAccountNumber": "infCliUCS.infCliBanco.numCtaT24Banco",
    "clients[0].retailClientInvestorProfile.t24ConfirmationFileId": "infCliUCS.actCliT24.pantConfT24",

    "clients[0].retailClientInvestorProfile.dimsaClientId": "infCliDIMSA.numCliDIMSA",
    "clients[0].retailClientInvestorProfile.rntNumber": "infCliDIMSA.numRNT",
    "clients[0].retailClientInvestorProfile.ownAccordSecuritiesAccount": "infCliDIMSA.ctaValoresPosturaPropia",
    "clients[0].retailClientInvestorProfile.sureInvestorAccountDIMSA": "infCliDIMSA.ctaValoresSureInv",

    "clients[0].retailClientInvestorProfile.t24safiClientId": "infCliT24SAFI.numCliT24SAFI",
    "clients[0].retailClientInvestorProfile.moneyMarketDOPNumber": "infCliT24SAFI.numEncargoDineroDOP",
    "clients[0].retailClientInvestorProfile.moneyMarketUSDNumber": "infCliT24SAFI.numEncargoDineroUSD",
    "clients[0].retailClientInvestorProfile.term90DayDOPsNumber": "infCliT24SAFI.numEncargoPlazoDOP",
}


export default form;