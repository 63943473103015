const form = {
    title: "DD Legal",
    displayName: 'LegalCaseReviewReport',
    page: 'legal-case-review-report',
    isSentBack(object) {
        return [
            "Devuelto"
        ].indexOf(object.clients[0].businessClientDueDiligenceReport.legalReviewResult) !== -1;
    },
    commentFieldsMap: {
        "Comment": "clients[0].businessClientDueDiligenceReport.legalReviewComments",
    },
};


form.schema = {
    title: "Solicitud de Vinculación Cliente",
    type: "object",
    properties: {
        "formularioReporteLegal": {
            title: "Cargar Formulario de Validación Persona Juridica",
            type: "number",
        },
        "memoRevLegal":{
            title: "Memo Revisión Legal",
            type:"string"
        },
        "resRevLegal":{
            title:"Resultado Revisión Legal",
            type:"string",
            enum:[
                "Aprobado",
                "Aprobado con excepción",
                "Devuelto",
            ]
        },
        "comentarios":{
            title:"Comentarios",
            type:"string"
        },
    },
    required: ["resRevLegal"]
}
           

form.uiSchema = {
    "formularioReporteLegal": { "ui:field": "FileField" },
    "memoRevLegal":{
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
    },
    "comentarios":{
        "ui:widget": "textarea",
        "ui:options": {
            rows: 4
        },
    }
}

form.objectMap = {
    "clients[0].businessClientDueDiligenceReport.signedLegalValidationFileId": "formularioReporteLegal",
    "clients[0].businessClientDueDiligenceReport.legalReviewMemo": "memoRevLegal",
    "clients[0].businessClientDueDiligenceReport.legalReviewResult": "resRevLegal",
    "clients[0].businessClientDueDiligenceReport.legalReviewComments": "comentarios"
}


export default form;