import nonccdb from "./NonCreditContractsDownloadBusinessClientSchema";


const form = {
    title: "Carga Contratos / Firmas",
    displayName: 'NonCreditContractsUploadBusinessClient',
    page: 'non-credit-contracts-business-client',
    determineCanEdit: ({ currentCase }) => (true),
};


form.schema = {
    title: "Solicitud de Vinculación Cliente Empresa",
    type: "object",
    properties: {
        "dct": nonccdb.schema.properties.dct,
        "dfp": {
            title: "Documentos firmados Puesto",
            type: "object",
            properties: {
                "tarjetaFirmaPuesto": {
                    title: "Registro Firma Persona Jurídica",
                    type: "number",
                },
                "informeInversionistaPuesto": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoPuesto": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "contratoCuentaAperturaPuesto": {
                    title: "Contrato Apertura Cuenta",
                    type: "number",
                },
                "cartaAutoApertCuentaPuesto": {
                    title: "Carta Autorización Apertura Cuenta",
                    type: "number",
                },
                "formClientePuesto": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formW8BenPuesto": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
            },
            required: [
            ]
        },
        "dfs": {
            title: "Documentos firmados SAFI",
            type: "object",
            properties: {
                "tarjetaFirmaSafi": {
                    title: "Registro Firma Persona Jurídica",
                    type: "number",
                },
                "informeInversionistaSafi": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoSafi": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formClienteSafi": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formW8BenSafi": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
            },
            required: [
            ]
        },
        "dfb": {
            title: "Documentos Firmados Banco",
            type: "object",
            properties: {
                "tarjetaFirmaBanco": {
                    title: "Registro Firma Persona Jurídica",
                    type: "number",
                },
                "formClienteBanco": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formW8BenBanco": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
                "solicitudPrestamo": {
                    title: "Solicitud de Préstamo",
                    type: "number",
                }
            },
            required: [
            ]
        },
        // "sso": {
        //     title: "Soporte Sujeto Obligado",
        //     type: "object",
        //     properties: {
        //         "soporteSujetoObligadoFile": {
        //             title: "Soporte de Sujeto Obligado No Financiero Personal",
        //             type: "number",
        //         },
        //     }
        // }
    },
    required: [],
};



form.uiSchema = {
    "dct": nonccdb.uiSchema.dct,
    dfp: {
        "classNames": "personalNonCCD-upload",
        "ui:field": "HideObjectField",
        "tarjetaFirmaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.registroFirmaPersJuridicasPuesto" } },
        "informeInversionistaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.informeInversionistaPuesto" } },
        "contratoMarcoPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoMarcoPuesto" } },
        "contratoCuentaAperturaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoCuentaCelvadom" } },
        "cartaAutoApertCuentaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.cartaCuentaCelvadom" } },
        "formClientePuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioClientePuesto" } },
        "formW8BenPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW8BenePuesto" } },        
    },
    dfs: {
        "classNames": "personalNonCCD-upload",
        "ui:field": "HideObjectField",
        "tarjetaFirmaSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.registroFirmaPersJuridicasSafi" } },
        "informeInversionistaSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.informeInversionistaSafi" } },
        "contratoMarcoSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoMarcoSafi" } },
        "formClienteSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioClienteSafi" } },
        "formW8BenSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW8BeneSAFI" } },
    },
    dfb: {
        "classNames": "personalNonCCD-upload",
        "ui:field": "HideObjectField",
        "tarjetaFirmaBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.registroFirmaPersJuridicasBanco" } },
        "formClienteBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioClienteBanco" } },
        "formW8BenBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW8BeneBanco" } },
        "solicitudPrestamo": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.solicitudPrestamo" } },
    },
    // sso: {
    //     "classNames": "personalNonCCD-upload",
    //     "ui:field": "HideObjectField",
    //     "soporteSujetoObligadoFile": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioSujetoObligado" } },
    // }
};

form.objectMap = {
    ...nonccdb.objectMap,

    "caseLoanInfo.loanApplicationFileId": "dfb.solicitudPrestamo",
    //    puesto
    "clients[0].businessClientDueDiligenceReport.puestoCardSignatureFileId": "dfp.tarjetaFirmaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoInvestorReportFileId": "dfp.informeInversionistaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoMarkContractFileId": "dfp.contratoMarcoPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoAccountContractFileId": "dfp.contratoCuentaAperturaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoAccountOpeningLetterFileId": "dfp.cartaAutoApertCuentaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoClientFormFileId": "dfp.formClientePuesto",
    "clients[0].businessClientDueDiligenceReport.puestoW8BenFileId": "dfp.formW8BenPuesto",
    //    safi
    "clients[0].businessClientDueDiligenceReport.safiCardSignatureFileId": "dfs.tarjetaFirmaSafi",
    "clients[0].businessClientDueDiligenceReport.safiInvestorReportFileId": "dfs.informeInversionistaSafi",
    "clients[0].businessClientDueDiligenceReport.safiMarkContractFileId": "dfs.contratoMarcoSafi",
    "clients[0].businessClientDueDiligenceReport.safiClientFormFileId": "dfs.formClienteSafi",
    "clients[0].businessClientDueDiligenceReport.safiW8BenFileId": "dfs.formW8BenSafi",

    //    banco
    "clients[0].businessClientDueDiligenceReport.bancoCardsignatureFileId": "dfb.tarjetaFirmaBanco",
    "clients[0].businessClientDueDiligenceReport.bancoClientFormFileId": "dfb.formClienteBanco",
    "clients[0].businessClientDueDiligenceReport.bancoW8BenFileId": "dfb.formW8BenBanco",

}


export default form;