import { readOnlyFileUI } from "./commons";


const form = {
    page: 'case-files-business',
    title: "Archivos de Caso",
    displayName: 'CaseFiles',
    enableOnlySave: true,
    hasSaveProgressButton: false,
};


const documentosIdentificacionEmpresa = {
    title: "Documentos de identificación de Empresa",
    type: "object",
    properties: {
        "registroMercantil": {
            title: "Registro Mercantil o documento de incorporación",
            type: "number",
        },
        "certificacionRNC": {
            title: "Certificación RNC",
            type: "number",
        },
        "estatutosSociales": {
            title: "Estatutos sociales y modificaciones",
            type: "number",

        },
        "actaNominaAsambleaOrdinaria": {
            title: "Acta y Nómina última Asamblea Ordinaria que desiga autoridades",
            type: "number",

        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },

        "actaAsambleaConstitutiva": {
            title: "Acta de Asamblea Constitutiva",
            type: "number",

        },
        "listadoSuscriptores": {
            title: "Listado de Suscriptores y/o socios",
            type: "number",

        },
        "actaAsambleaRepresentanteLegal": {
            title: "Acta Asamblea que designa representante legal",
            type: "number",

        },
        "certificadoIncorporacionIDECOOP": {
            title: "Certificado de Incorporación IDECOOP",
            type: "number",

        },
        "contratoConsorcioFideicomiso": {
            title: "Contrato del Consorcio o Fideicomiso",
            type: "number",

        },
        "documentosSocietariosFiduciaria": {
            title: "Documentos Societarios Fiduciaria",
            type: "number",

        },
        "actaDecretoPoderes": {
            title: "Acta o decreto que consigna poderes",
            type: "number",

        }
    }
}

const soporteFuenteIngresos = {
    title: "Soporte Fuente de Ingresos",
    type: "object",
    properties: {
        "evidenciaIngresosRegulares": {
            title: "Evidencia Ingresos Regulares",
            type: "number",
        },
        "declaracionImpuestosUltimoAno": {
            title: "Declaración de Impuestos del ultimo año",
            type: "number",
        },
        "soporteNaturalezaActividades": {
            title: "Soporte de naturaleza de actividades",
            type: "number",
        },
        "declaracionIR1": {
            title: "Declaración IR1- Ingresos adicionales",
            type: "number",
        },
        "estadosIngresosGastos": {
            title: "Estados de ingresos y gastos - Act. informales",
            type: "number",
        },
    },

}

const soporteOrigenProcedenciaFondos = {
    title: "Soporte Origen y procedencia de fondos",
    type: "object",
    properties: {
        "soporteOrigenFondos": {
            title: "Soporte origen de fondos",
            type: "number",
        },
        "soporteProcedenciaFondos": {
            title: "Soporte procedencia de fondos",
            type: "number",
        },
    },

}

const soportesAdicionalesPEP = {
    title: "Soportes Adicionales Personas Expuestas Políticamente",
    type: "object",
    properties: {
        "declaracionJuradaPatrimonioDesignacion": {
            title: "Declaración Jurada de Patrimonio o decreto de designación",
            type: "number",
        },
        "declaracionIRParticipacionEmpresas": {
            title: "Declaración IR-1 - Participación en empresas",
            type: "number",
        },
    },

}

const soporteSolicitudPrestamo = {
    title: "Soporte Solicitud de Préstamo",
    type: "object",
    properties: {
        "soporteGarantia": {
            title: "Soporte de Valor de Garantía",
            type: "number",
        },

    },

}

const soporteDebidaDiligencia = {
    title: "Soportes Debida Diligencia",
    type: "object",
    properties: {
        "revisionListaOFAC": {
            title: "Revisión en lista OFAC",
            type: "number",
        },
        "revisionListaONU": {
            title: "Revisión en lista ONU",
            type: "number",
        },
        "matrizRiesgo": {
            title: "Matriz de Riesgo",
            type: "number",
        },
        "revisionBuroCredito": {
            title: "Revisión Buró de Crédito",
            type: "number",
        },
    },
}

const aditionalFiles = {
    title: "Archivos Adicionales",
    type: "array",
    items: {
        "properties": {
            "documentName": {
                title: "Nombre Archivo",
                type: "string"
            },
            "document": {
                title: "Archivo",
                type: "number"
            },
        }
    },
}

const reportesDebidaDiligencia = {
    title: "Reportes Debida Diligencia",
    type: "object",
    properties: {
        "informeONG": {
            title: "Informe ONG",
            type: "number",
        },
        "infPersJurAltRiesgo": {
            title: "Informe de persona jurídica de alto riesgo",
            type: "number",
        },
        "infPersJurAltRiesgoCons": {
            title: "Informe de persona jurídica de alto riesgo consorcios",
            type: "number",
        },
        "infPersJurAltRiesgoFide": {
            title: "Informe de persona jurídica de alto riesgo fideicomisos",
            type: "number",
        },
        "infPersJurAltRiesgoFondInv": {
            title: "Informe de persona jurídica de alto riesgo fondos de inversión",
            type: "number",
        },
    }
}
const reportesDebidaDiligenciaLegal = {
    title: "Reportes Debida Diligencia Legal",
    type: "object",
    properties: {
        "validacionPersJuridica": {
            title: "Validación Persona Jurídica",
            type: "number",
        },
    }
}

const reportesDebidaDiligenciaFirmados = {
    title: "Reportes Debida Diligencia Firmados",
    type: "object",
    properties: {
        "informeONGFirmado": {
            title: "Informe ONG firmado",
            type: "number",
        },
        "infPersJurAltRiesgoFirmado": {
            title: "Informe de persona jurídica de alto riesgo firmado",
            type: "number",
        },
        "infPersJurAltRiesgoConsFirmado": {
            title: "Informe de persona jurídica de alto riesgo consorcios firmado",
            type: "number",
        },
        "infPersJurAltRiesgoFideFirmado": {
            title: "Informe de persona jurídica de alto riesgo fideicomisos firmado",
            type: "number",
        },
        "infPersJurAltRiesgoFondInvFirmado": {
            title: "Informe de persona jurídica de alto riesgo fondos de inversión firmado",
            type: "number",
        },
    }
}

form.schema = {
    title: "",
    type: "object",
    properties: {
        "t": {
            title: "Archivos de Caso",
            type: "object",
            properties: {}
        },
        "documentosGenerados": {
            title: "Documentos Generados por Sistema",
            type: "object",
            properties: {
                "titlePuesto": { title: "Puesto", type: "null", const: null },
                "titleBanco": { title: "Banco", type: "null", const: null },
                "titleSafi": { title: "Safi", type: "null", const: null },
                "informeInversionistaPuesto": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoPuesto": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formularioClientePuesto": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioW8BeneBanco": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
                "formularioW8BenePuesto": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
                "formularioW8BeneSAFI": {
                    title: "Formulario W8-BEN-E",
                    type: "number",
                },
                "informeInversionistaSafi": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoSafi": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formularioClienteSafi": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioClienteBanco": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "cartaCuentaCelvadom": {
                    title: "Carta Autorización Apertura Cuenta Celvadom",
                    type: "number",
                },
                "contratoCuentaCelvadom": {
                    title: "Contrato Apertura Cuenta Valores Celvadom",
                    type: "number",
                },
                "registroFirmaPersJuridicasBanco": {
                    title: "Registro Firma Persona Jurídicas",
                    type: "number",
                },
                "registroFirmaPersJuridicasPuesto": {
                    title: "Registro Firma Persona Jurídicas",
                    type: "number",
                },
                "registroFirmaPersJuridicasSafi": {
                    title: "Registro Firma Persona Jurídicas",
                    type: "number",
                },
                "solicitudPrestamo": {
                    title: "Solicitud de Préstamo",
                    type: "number",
                },
                "formularioSujetoObligado": {
                    title: "Formulario Sujeto Obligado No Financiero Empresa",
                    type: "number",
                },
            }
        },
        reportesDebidaDiligencia,
        reportesDebidaDiligenciaLegal,
        "documentosIdentificacionEmpresa": documentosIdentificacionEmpresa,
        "documentosRepAccionistas": {
            title: "Documentos de identificación de representantes, accionistas o firmantes individuales",
            type: "object",
            properties: {
                "documentoIdentRepFirmantes": {
                    title: "Documento de Identidad representantes y firmantes (ambos lados)",
                    type: "number",
                },
                "segundaIdentRepFirmantes": {
                    title: "Segunda identificación representantes y firmantes extranjeros",
                    type: "number",
                },
            }
        },
        "documentosAccionistasJuridicas": {
            title: "Documentos de identificación accionistas personas jurídicas",
            type: "object",
            properties: {
                "certInc": {
                    title: "Certificado de incorporación (Reg. Mercantil o equiv.)",
                    type: "number",

                },
                "copiaIdAccInd": {
                    title: "Copia identificación de accionistas individuales",
                    type: "number",

                },
                "estatutos": {
                    title: "Estatutos",
                    type: "number",

                },
                "listaAccionistas": {
                    title: "Lista de accionistas",
                    type: "number",

                },
            }
        },
        "soporteFuenteIngresos": {
            title: "Documentos Soporte fuente de ingresos",
            type: "object",
            properties: {
                "estFinAudRent1": {
                    title: "Est. financiero auditado o Decl. Renta 1",
                    type: "number",

                },
                "estFinAudRent2": {
                    title: "Est. financiero auditado o Decl. Renta 2",
                    type: "number",

                }
            }
        },
        // "soporteSolicitudPrestamo": {
        //     title: "Documentos Soporte Solicitud de Préstamo",
        //     type: "object",
        //     properties: {
        //         "sopGarantia": {
        //             title: "Soporte Valor de Garantía",
        //             type: "number",

        //         },
        //     }
        // },
        "documentosFirmados": {
            title: "Documentos Subidos al Sistema",
            type: "object",
            properties: {
                "documentosClienteTitular": {
                    title: "Documentos Cliente Titular",
                    type: "object",
                    properties: {
                        "soporteFuenteIngresos": soporteFuenteIngresos,
                        "soporteOrigenProcedenciaFondos": soporteOrigenProcedenciaFondos,
                        "soportesAdicionalesPEP": soportesAdicionalesPEP,
                        // "soporteSolicitudPrestamo": soporteSolicitudPrestamo,
                    },
                },
                "documentosFirmadosPuesto": {
                    title: "Documentos firmados Puesto",
                    type: "object",
                    properties: {
                        "tarjetaFirmaPuesto": {
                            title: "Registro Firma Persona Jurídica",
                            type: "number",
                        },
                        "informeInversionistaPuesto": {
                            title: "Informe Perfil de Inversionista",
                            type: "number",
                        },
                        "contratoMarcoPuesto": {
                            title: "Contrato Marco",
                            type: "number",
                        },
                        "contratoCuentaAperturaPuesto": {
                            title: "Contrato Apertura Cuenta",
                            type: "number",
                        },
                        "cartaAutoApertCuentaPuesto": {
                            title: "Carta Autorización Apertura Cuenta",
                            type: "number",
                        },
                        "formClientePuesto": {
                            title: "Formulario Conozca su Cliente",
                            type: "number",
                        },
                        "formW8BenPuesto": {
                            title: "Formulario W8-BEN-E",
                            type: "number",
                        },
                    },
                },
                "documentosFirmadosSafi": {
                    title: "Documentos firmados SAFI",
                    type: "object",
                    properties: {
                        "tarjetaFirmaSafi": {
                            title: "Registro Firma Persona Jurídica",
                            type: "number",
                        },
                        "informeInversionistaSafi": {
                            title: "Informe Perfil de Inversionista",
                            type: "number",
                        },
                        "contratoMarcoSafi": {
                            title: "Contrato Marco",
                            type: "number",
                        },
                        "formClienteSafi": {
                            title: "Formulario Conozca su Cliente",
                            type: "number",
                        },
                        "formW8BenSafi": {
                            title: "Formulario W8-BEN-E",
                            type: "number",
                        },
                    },
                },
                "documentosFirmadosBanco": {
                    title: "Documentos firmados Banco",
                    type: "object",
                    properties: {
                        "tarjetaFirmaBanco": {
                            title: "Registro Firma Persona Jurídica",
                            type: "number",
                        },
                        "formClienteBanco": {
                            title: "Formulario Conozca su Cliente",
                            type: "number",
                        },
                        "formW8BenBanco": {
                            title: "Formulario W8-BEN-E",
                            type: "number",
                        },
                        "soportePrestamo": {
                            title: "Soporte Préstamo",
                            type: "number",
                        },
                    }
                },

                "soporteSujetoObligado": {
                    title: "Soporte Sujeto Obligado",
                    type: "object",
                    properties: {
                        "soporteSujetoObligadoFile": {
                            title: "Soporte de Sujeto Obligado No Financiero Personal",
                            type: "number",
                        },
                    }
                },
                "soporteDebidaDiligencia": soporteDebidaDiligencia,
                "reportesDebidaDiligenciaFirmados": reportesDebidaDiligenciaFirmados,
            }
        },
        "cargarDocumentos": {
            title: "Descarga Masiva de Documentos",
            type: "object",
            properties: {
                "safi": { title: "Descargar Documentos De SAFI", type: "string", filter: "safi" },
                "puesto": { title: "Descargar Documentos De Puesto", type: "string", filter: "puesto" },
                "banco": { title: "Descargar Documentos De Banco", type: "string", filter: "banco" },
                "todos": { title: "Descargar Todos los Documentos", type: "string" }
            }
        },
        "aditionalFiles": aditionalFiles,
    },
    required: [],
};




form.uiSchema = {
    "ui:field": "HideObjectField",
    "ui:newSection": true,
    "t": {
        "joli:showAttr": { 'formContext': { 'auth.permissions.downloadAllFiles': 1 } }
    },
    "documentosGenerados": {
        "classNames": "commercialNonCCD-dct",
        "ui:field": "HideObjectField",
        "titlePuesto": { classNames: "title                   puesto" },
        "titleBanco": { classNames: "title                   banco" },
        "titleSafi": { classNames: "title                   safi" },
        "formularioClienteBanco": { ...readOnlyFileUI, classNames: "tarjetaFirma       banco" },
        "formularioClientePuesto": { ...readOnlyFileUI, classNames: "tarjetaFirma       puesto" },
        "formularioClienteSafi": { ...readOnlyFileUI, classNames: "tarjetaFirma       safi" },

        "registroFirmaPersJuridicasBanco": { ...readOnlyFileUI, classNames: "formularioCliente       banco" },
        "registroFirmaPersJuridicasPuesto": { ...readOnlyFileUI, classNames: "formularioCliente       puesto" },
        "registroFirmaPersJuridicasSafi": { ...readOnlyFileUI, classNames: "formularioCliente       safi" },

        "formularioW8BeneBanco": { ...readOnlyFileUI, classNames: "formularioW8Ben       banco" },
        "formularioW8BenePuesto": { ...readOnlyFileUI, classNames: "formularioW8Ben       puesto" },
        "formularioW8BeneSAFI": { ...readOnlyFileUI, classNames: "formularioW8Ben       safi" },

        "cartaCuentaCelvadom": { ...readOnlyFileUI, classNames: "cartaAutorizacionCuenta      puesto" },
        "contratoCuentaCelvadom": { ...readOnlyFileUI, classNames: "contratoCuenta       puesto" },

        "informeInversionistaPuesto": { ...readOnlyFileUI, classNames: "informeInversionista    puesto" },
        "informeInversionistaSafi": { ...readOnlyFileUI, classNames: "informeInversionista    safi" },

        "contratoMarcoPuesto": { ...readOnlyFileUI, classNames: "contratoMarco           puesto" },
        "contratoMarcoSafi": { ...readOnlyFileUI, classNames: "contratoMarco           safi" },

        "solicitudPrestamo": { ...readOnlyFileUI, classNames: "formularioCliente       banco" },

        "formularioSujetoObligado": { ...readOnlyFileUI, classNames: "formularioSujetoObligado  puesto" },

        "joli:showAttr": { 'formContext': { 'auth.permissions.downloadAllFiles': 1 } },
    },
    "reportesDebidaDiligencia": {
        "ui:field": "HideObjectField",
        "classNames": "businessComplianceUploads-bdd",
        "informeONG": { ...readOnlyFileUI, classNames: "row1 col1" },
        "infPersJurAltRiesgo": { ...readOnlyFileUI, classNames: "row1 col2" },
        "infPersJurAltRiesgoCons": { ...readOnlyFileUI, classNames: "row1 col3" },
        "infPersJurAltRiesgoFide": { ...readOnlyFileUI, classNames: "row2 col1" },
        "infPersJurAltRiesgoFondInv": { ...readOnlyFileUI, classNames: "row2 col2" },
    },
    "reportesDebidaDiligenciaLegal": {
        "ui:field": "HideObjectField",
        "classNames": "businessComplianceUploads-bdd",
        "validacionPersJuridica": { ...readOnlyFileUI, classNames: "row1 col1" }
    },
    "documentosIdentificacionEmpresa": {
        "ui:field": "HideObjectField",
        "classNames": "smallTwoRows",
        "registroMercantil": { ...readOnlyFileUI },
        "certificacionRNC": { ...readOnlyFileUI },
        "estatutosSociales": { ...readOnlyFileUI },
        "actaNominaAsambleaOrdinaria": { ...readOnlyFileUI },
        "actaDesignaFirmantesAutorizados": { ...readOnlyFileUI },
        "actaAsambleaConstitutiva": { ...readOnlyFileUI },
        "listadoSuscriptores": { ...readOnlyFileUI },
        "actaAsambleaRepresentanteLegal": { ...readOnlyFileUI },
        "certificadoIncorporacionIDECOOP": { ...readOnlyFileUI },
        "contratoConsorcioFideicomiso": { ...readOnlyFileUI },
        "documentosSocietariosFiduciaria": { ...readOnlyFileUI },
        "actaDecretoPoderes": { ...readOnlyFileUI },
    },
    "documentosRepAccionistas": {
        "ui:field": "HideObjectField",
        "documentoIdentRepFirmantes": { ...readOnlyFileUI, },
        "segundaIdentRepFirmantes": { ...readOnlyFileUI, },
    },
    "documentosAccionistasJuridicas": {
        "ui:field": "HideObjectField",
        "classNames": "smallTwoRows",
        "certInc": { ...readOnlyFileUI, },
        "copiaIdAccInd": { ...readOnlyFileUI, },
        "estatutos": { ...readOnlyFileUI, },
        "listaAccionistas": { ...readOnlyFileUI, },
    },
    "soporteFuenteIngresos": {
        "ui:field": "HideObjectField",
        "classNames": "smallTwoRows",
        "estFinAudRent1": { ...readOnlyFileUI },
        "estFinAudRent2": { ...readOnlyFileUI },
    },
    // "soporteSolicitudPrestamo": {
    //     "classNames": "smallTwoRows",
    //     "sopGarantia": { ...readOnlyFileUI },
    // },
    "documentosFirmados": {
        "documentosClienteTitular": {
            "ui:field": "HideObjectField",
            "documentosIdentificacion": {
                "ui:field": "HideObjectField",
                "cedulaIdentidadAnverso": {
                    ...readOnlyFileUI,
                },
                "cedulaIdentidadReverso": {
                    ...readOnlyFileUI,
                },
                "pasaporte": {
                    ...readOnlyFileUI,
                },
                "documentoIdentidadPaisOrigen": {
                    ...readOnlyFileUI,
                },
                "documentoGestionMigratoria": {
                    ...readOnlyFileUI,
                },
            },
            "soporteFuenteIngresos": {
                "ui:field": "HideObjectField",
                "evidenciaIngresosRegulares": {
                    ...readOnlyFileUI,
                },
                "declaracionImpuestosUltimoAno": {
                    ...readOnlyFileUI,
                },
                "soporteNaturalezaActividades": {
                    ...readOnlyFileUI,
                },
                "declaracionIR1": {
                    ...readOnlyFileUI,
                },
                "estadosIngresosGastos": {
                    ...readOnlyFileUI,
                },
            },
            "soporteOrigenProcedenciaFondos": {
                "ui:field": "HideObjectField",
                "soporteOrigenFondos": {
                    ...readOnlyFileUI,
                },
                "soporteProcedenciaFondos": {
                    ...readOnlyFileUI,
                },
            },
            "soportesAdicionalesPEP": {
                "ui:field": "HideObjectField",
                "declaracionJuradaPatrimonioDesignacion": {
                    ...readOnlyFileUI,
                },
                "declaracionIRParticipacionEmpresas": {
                    ...readOnlyFileUI,
                },
            },
            "soporteSolicitudPrestamo": {
                "ui:field": "HideObjectField",
                "soporteGarantia": {
                    ...readOnlyFileUI,
                },
            }

        },
        documentosFirmadosPuesto: {
            "ui:field": "HideObjectField",
            "tarjetaFirmaPuesto": { ...readOnlyFileUI },
            "informeInversionistaPuesto": { ...readOnlyFileUI },
            "contratoMarcoPuesto": { ...readOnlyFileUI },
            "contratoCuentaAperturaPuesto": { ...readOnlyFileUI },
            "cartaAutoApertCuentaPuesto": { ...readOnlyFileUI },
            "formClientePuesto": { ...readOnlyFileUI },
            "formW8BenPuesto": { ...readOnlyFileUI },
        },
        documentosFirmadosSafi: {
            "classNames": "personalNonCCD-upload",
            "ui:field": "HideObjectField",
            "tarjetaFirmaSafi": { ...readOnlyFileUI },
            "informeInversionistaSafi": { ...readOnlyFileUI },
            "contratoMarcoSafi": { ...readOnlyFileUI },
            "formClienteSafi": { ...readOnlyFileUI },
            "formW8BenSafi": { ...readOnlyFileUI },
        },
        documentosFirmadosBanco: {
            "classNames": "personalNonCCD-upload",
            "ui:field": "HideObjectField",
            "tarjetaFirmaBanco": { ...readOnlyFileUI },
            "formClienteBanco": { ...readOnlyFileUI },
            "formW8BenBanco": { ...readOnlyFileUI },
            "soportePrestamo": { ...readOnlyFileUI },
        },
        soporteSujetoObligado: {
            "ui:field": "HideObjectField",
            "soporteSujetoObligadoFile": {
                ...readOnlyFileUI,
            },
        },
        "soporteDebidaDiligencia": {
            "ui:field": "HideObjectField",
            "revisionListaOFAC": { ...readOnlyFileUI, },
            "revisionBuroCredito": { ...readOnlyFileUI, },
            "revisionListaONU": { ...readOnlyFileUI, },
            "matrizRiesgo": { ...readOnlyFileUI, },
        },
        "reportesDebidaDiligenciaFirmados": {
            "classNames": "businessComplianceUploads-upload",
            "ui:field": "HideObjectField",
            "informeONGFirmado": { ...readOnlyFileUI },
            "infPersJurAltRiesgoFirmado": { ...readOnlyFileUI },
            "infPersJurAltRiesgoConsFirmado": { ...readOnlyFileUI },
            "infPersJurAltRiesgoFideFirmado": { ...readOnlyFileUI },
            "infPersJurAltRiesgoFondInvFirmado": { ...readOnlyFileUI },
        },
        "joli:showAttr": { 'formContext': { 'auth.permissions.downloadAllFiles': 1 } },
    },
    "cargarDocumentos": {
        "ui:field": "HideObjectField",
        "ui:newSection": true,
        "classNames": "smallThreeRows",
        "safi": { "ui:field": "CaseZipDownloadField", "joli:showAttr": { 'formContext': { 'auth.permissions.downloadSafiFiles': 1 } } },
        "puesto": { "ui:field": "CaseZipDownloadField", "joli:showAttr": { 'formContext': { 'auth.permissions.downloadPuestoFiles': 1 } } },
        "banco": { "ui:field": "CaseZipDownloadField", "joli:showAttr": { 'formContext': { 'auth.permissions.downloadBancoFiles': 1 } } },
        "todos": { "ui:field": "CaseZipDownloadField", "joli:showAttr": { 'formContext': { 'auth.permissions.downloadAllFiles': 1 } } },
    },
    "aditionalFiles": {
        "classNames": "aditionalFiles",
        "items": { "document": { "ui:field": "FileField" } },
    },
};

form.objectMap = {

    // Files generated in system
    "generatedFiles.fullkyc_0_CartaAutorizaciónAperturaCuentaCelvadomEmpresa": "documentosGenerados.cartaCuentaCelvadom",
    "generatedFiles.fullkyc_0_ContratoAperturaCuentasValoresCelvadomEmpresa": "documentosGenerados.contratoCuentaCelvadom",
    "generatedFiles.fullkyc_0_ContratoMarcoPuesto": "documentosGenerados.contratoMarcoPuesto",
    "generatedFiles.fullkyc_0_ContratoMarcoSafi": "documentosGenerados.contratoMarcoSafi",
    "generatedFiles.fullkyc_0_FormularioConozcaSuClienteBanco": "documentosGenerados.formularioClienteBanco",
    "generatedFiles.fullkyc_0_FormularioConozcaSuClientePuesto": "documentosGenerados.formularioClientePuesto",
    "generatedFiles.fullkyc_0_FormularioConozcaSuClienteSafi": "documentosGenerados.formularioClienteSafi",
    "generatedFiles.fullkyc_0_InformePerfilDelInversionistaComercialPuesto": "documentosGenerados.informeInversionistaPuesto",
    "generatedFiles.fullkyc_0_InformePerfilInversionistaPersonaComercialSafi": "documentosGenerados.informeInversionistaSafi",
    "generatedFiles.fullkyc_0_IrsW8benEBanco": "documentosGenerados.formularioW8BeneBanco",
    "generatedFiles.fullkyc_0_IrsW8benEPuesto": "documentosGenerados.formularioW8BenePuesto",
    "generatedFiles.fullkyc_0_IrsW8benESafi": "documentosGenerados.formularioW8BeneSAFI",
    "generatedFiles.fullkyc_0_SolicitudPrestamoComercial": "documentosGenerados.solicitudPrestamo",
    "generatedFiles.fullkyc_0_FormularioSujetoObligadoNoFinanciero": "documentosGenerados.formularioSujetoObligado",

    "generatedFiles.fullkyc_0_RegistroDeFirmasClientesPersonasJurídicasBanco": "documentosGenerados.registroFirmaPersJuridicasBanco",
    "generatedFiles.fullkyc_0_RegistroDeFirmasClientesPersonasJurídicasPuesto": "documentosGenerados.registroFirmaPersJuridicasPuesto",
    "generatedFiles.fullkyc_0_RegistroFirmasClientesPersonasJurídicasSafi": "documentosGenerados.registroFirmaPersJuridicasSafi",
    // Loan
    "generatedFiles.fullkyc_0_SolicitudDePrestamoPersonal": "documentosGenerados.solicitudPrestamoDL",


    // Files uploaded
    "caseLoanInfo.loanApplicationFileId": "documentosFirmados.documentosFirmadosBanco.soportePrestamo",

    "clients[0].businessClientBasicInfo.commercialRegisterFileId": "documentosIdentificacionEmpresa.registroMercantil",
    "clients[0].businessClientBasicInfo.commercialRegisterExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoRegistroMercantil",

    "clients[0].businessClientBasicInfo.certificationRNCFileId": "documentosIdentificacionEmpresa.certificacionRNC",
    "clients[0].businessClientBasicInfo.certificationRNCExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoCertificacionRNC",

    "clients[0].businessClientBasicInfo.bylawsAndModsFileId": "documentosIdentificacionEmpresa.estatutosSociales",

    "clients[0].businessClientBasicInfo.certPayrollOrdinaryAssemblyFileId": "documentosIdentificacionEmpresa.actaNominaAsambleaOrdinaria",
    "clients[0].businessClientBasicInfo.certPayrollOrdinaryAssemblyReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionActaNomina",

    "clients[0].businessClientBasicInfo.actDesignatingAuthSignatoriesFileId": "documentosIdentificacionEmpresa.actaDesignaFirmantesAutorizados",
    "clients[0].businessClientBasicInfo.actDesignatingAuthSignatoriesExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoActaDesignaFirmantesAutorizados",

    "clients[0].businessClientBasicInfo.certConstituentAssemblyFileId": "documentosIdentificacionEmpresa.actaAsambleaConstitutiva",
    "clients[0].businessClientBasicInfo.certConstituentAssemblyReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionActaAsambleaConstitutiva",

    "clients[0].businessClientBasicInfo.listOfSubscribersFileId": "documentosIdentificacionEmpresa.listadoSuscriptores",
    "clients[0].businessClientBasicInfo.listOfSubscribersReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionListadoSuscriptores",

    "clients[0].businessClientBasicInfo.assemblyActLegalFileId": "documentosIdentificacionEmpresa.actaAsambleaRepresentanteLegal",
    "clients[0].businessClientBasicInfo.assemblyActLegalReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionActaAsambleaRepresentanteLegal",

    "clients[0].businessClientBasicInfo.certIncIDECOOPFileId": "documentosIdentificacionEmpresa.certificadoIncorporacionIDECOOP",
    "clients[0].businessClientBasicInfo.certIncIDECOOPReleaseDate": "documentosIdentificacionEmpresa.fechaDeEmisionCertificadoIncorporacionIDECOOP",


    "clients[0].businessClientBasicInfo.contractConsortiumTrustFileId": "documentosIdentificacionEmpresa.contratoConsorcioFideicomiso",
    "clients[0].businessClientBasicInfo.contractConsortiumTrustExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoContratoConsorcioFideicomiso",

    "clients[0].businessClientBasicInfo.corporateTrustDocumentsFileId": "documentosIdentificacionEmpresa.documentosSocietariosFiduciaria",
    "clients[0].businessClientBasicInfo.corporateTrustDocumentsYear": "documentosIdentificacionEmpresa.añoDocumentosSocietariosFiduciaria",

    "clients[0].businessClientBasicInfo.actDecreeConsigningPowersFileId": "documentosIdentificacionEmpresa.actaDecretoPoderes",
    "clients[0].businessClientBasicInfo.actDecreeConsigningPowersExpirationDate": "documentosIdentificacionEmpresa.fechaDeVencimientoActaDecretoPoderes",

    "clients[0].businessClientBasicInfo.idDocRepresentativesSignersFileId": "documentosRepAccionistas.documentoIdentRepFirmantes",
    "clients[0].businessClientBasicInfo.secondIdRepresentativesForegnSignersFileId": "documentosRepAccionistas.segundaIdentRepFirmantes",

    "clients[0].businessClientBasicInfo.authorizedSigners": {
        array: "documentosIdentificacionEmpresa.firmantesAutorizadosNestedList",
        map: {
            "name": "nombre",
            "position": "posicion",
        }
    },

    "clients[0].businessClientBasicInfo.shareholderListFileId": "documentosAccionistasJuridicas.listaAccionistas",


    "clients[0].businessClientBasicInfo.incorporationCertFileId": "documentosAccionistasJuridicas.certInc",
    "clients[0].businessClientBasicInfo.incorporationCertExpirationDate": "documentosAccionistasJuridicas.fechaDeVencimientoCertInc",

    "clients[0].businessClientBasicInfo.idCopyIndividualShareholdersFileId": "documentosAccionistasJuridicas.copiaIdAccInd",
    "clients[0].businessClientBasicInfo.idCopyIndividualShareholdersExpirationDate": "documentosAccionistasJuridicas.fechaDeVencimientoCopiaIdAccInd",

    "clients[0].businessClientBasicInfo.statutesFileId": "documentosAccionistasJuridicas.estatutos",
    "clients[0].businessClientBasicInfo.statutesExpirationDate": "documentosAccionistasJuridicas.fechaDeVencimientoEstatutos",

    "clients[0].businessClientBasicInfo.auditedFinancialStatementFileId1": "soporteFuenteIngresos.estFinAudRent1",
    "cnients[0].businessClientBasicInfo.auditedFinancialStatement1Year": "soporteFuenteIngresos.añoEstFinAudRent1",

    "clients[0].businessClientBasicInfo.auditedFinancialStatementFileId2": "soporteFuenteIngresos.estFinAudRent2",
    "clients[0].businessClientBasicInfo.auditedFinancialStatement2Year": "soporteFuenteIngresos.anoEstFinAudRent2",

    "clients[0].businessClientBasicInfo.areDocsCorrect": "soporteFuenteIngresos.booleanDocCorrectos",
    "clients[0].businessClientBasicInfo.supportValueGuaranteeReleaseDate": "soporteSolicitudPrestamo.fechaEmision",
    "clients[0].businessClientBasicInfo.supportValueGuaranteeFileId": "soporteSolicitudPrestamo.sopGarantia",

    //    puesto
    "clients[0].businessClientDueDiligenceReport.puestoCardSignatureFileId": "documentosFirmados.documentosFirmadosPuesto.tarjetaFirmaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoInvestorReportFileId": "documentosFirmados.documentosFirmadosPuesto.informeInversionistaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoMarkContractFileId": "documentosFirmados.documentosFirmadosPuesto.contratoMarcoPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoAccountContractFileId": "documentosFirmados.documentosFirmadosPuesto.contratoCuentaAperturaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoAccountOpeningLetterFileId": "documentosFirmados.documentosFirmadosPuesto.cartaAutoApertCuentaPuesto",
    "clients[0].businessClientDueDiligenceReport.puestoClientFormFileId": "documentosFirmados.documentosFirmadosPuesto.formClientePuesto",
    "clients[0].businessClientDueDiligenceReport.puestoW8BenFileId": "documentosFirmados.documentosFirmadosPuesto.formW8BenPuesto",
    //    safi
    "clients[0].businessClientDueDiligenceReport.safiCardSignatureFileId": "documentosFirmados.documentosFirmadosSafi.tarjetaFirmaSafi",
    "clients[0].businessClientDueDiligenceReport.safiInvestorReportFileId": "documentosFirmados.documentosFirmadosSafi.informeInversionistaSafi",
    "clients[0].businessClientDueDiligenceReport.safiMarkContractFileId": "documentosFirmados.documentosFirmadosSafi.contratoMarcoSafi",
    "clients[0].businessClientDueDiligenceReport.safiClientFormFileId": "documentosFirmados.documentosFirmadosSafi.formClienteSafi",
    "clients[0].businessClientDueDiligenceReport.safiW8BenFileId": "documentosFirmados.documentosFirmadosSafi.formW8BenSafi",

    //    banco
    "clients[0].businessClientDueDiligenceReport.bancoCardsignatureFileId": "documentosFirmados.documentosFirmadosBanco.tarjetaFirmaBanco",
    "clients[0].businessClientDueDiligenceReport.bancoClientFormFileId": "documentosFirmados.documentosFirmadosBanco.formClienteBanco",
    "clients[0].businessClientDueDiligenceReport.bancoW8BenFileId": "documentosFirmados.documentosFirmadosBanco.formW8BenBanco",

    // Due Diligence
    "generatedFiles.compliance_0_InformeOng": "reportesDebidaDiligencia.informeONG",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgo": "reportesDebidaDiligencia.infPersJurAltRiesgo",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoConsorcios": "reportesDebidaDiligencia.infPersJurAltRiesgoCons",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFideicomisos": "reportesDebidaDiligencia.infPersJurAltRiesgoFide",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFondosDeInversion": "reportesDebidaDiligencia.infPersJurAltRiesgoFondInv",

    "clients[0].businessClientDueDiligenceReport.signedONGReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.informeONGFirmado",
    "clients[0].businessClientDueDiligenceReport.signedLegalReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFirmado",
    "clients[0].businessClientDueDiligenceReport.signedLegalConsortiumReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoConsFirmado",
    "clients[0].businessClientDueDiligenceReport.signedLegalEscrowReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFideFirmado",
    "clients[0].businessClientDueDiligenceReport.signedLegalInvestmentFundsReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFondInvFirmado",

    // DD Legal
    "clients[0].businessClientDueDiligenceReport.signedLegalValidationFileId": "reportesDebidaDiligenciaLegal.validacionPersJuridica",

    "clients[0].businessClientDueDiligenceReport.additionalFilesList.items": {
        array: "aditionalFiles",
        map: {
            "name": "documentName",
            "fileId": "document",
        }
    },
}


export default form;