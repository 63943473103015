import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const UserApi = {
    async createUser(data) {
        return checkErrors(await fetchApi.api.post('manage/users/', { data }));
    },

    async getUsers() {
        return checkErrors(await fetchApi.api.get('form-lookup/users'));
    },

    async searchUsers({ offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get('manage/users', {
            query: { offset, size, sortBy, ...filters }
        }));
    },

    async getUser({ id }) {
        return checkErrors(await fetchApi.api.get(`manage/users/${id}`));
    },

    async editUser({ id, ...data }) {
        return checkErrors(await fetchApi.api.post(`manage/users/${id}`, { data }));
    },

}


export default UserApi;
