import React, { useState, useMemo, useContext } from 'react';
import { Trans } from '@lingui/react';
import dayjs from 'dayjs';

import { 
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardSubtitle,
    CardText,
} from 'reactstrap';
import { useParams } from "react-router-dom";

import "./CaseHistory.css";

import useResourceLoader from '../../util/useResourceLoader';
import CasesApi from '../../api/CasesApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import { DATETIME_FORMAT } from '../../constants';
import RequestReloadContext from '../../context/RequestReloadContext';


export function CaseHistory() {
    const { id } = useParams();
    const [updateCt, setUpateCt] = useState(0);
    const [currentCase, loadingCase, errorLoadingCase] = useResourceLoader(() => CasesApi.getCaseHistory({ caseId: id }), [updateCt], null);

    const [statusMap, loadingStates, errorLoadingStates] = useResourceLoader(() => CasesApi.getWorkflowStatusMap({ caseId: id }), {}, null);

    const status = useMemo(() => currentCase && JSON.parse(currentCase.status), [(currentCase || {}).status]);

    const loading = loadingCase || loadingStates;
    const errors = errorLoadingCase || errorLoadingStates;

    const log = useMemo(() => ((currentCase || {}).log || []).map((entry, index) => ({
        index,
        ...entry
    })).reverse(), [currentCase])

    const requestReload = useContext(RequestReloadContext);

    async function teleportTo(idx){
        await CasesApi.teleportCaseToHistoryStep({ caseId: id, stepIdx: idx});
        setUpateCt(updateCt + 1);
        requestReload();
    }

    return (loading ? (
        <Loader fullscreen />
    ) : (errors ? (
        <Notification error={errors} />
    ) : (!currentCase ? (
        <Notification color="danger">Case Not Found (This must be an error, please contact the administration).</Notification>
    ) : (
        <div>
            <h3>Current Status</h3>
            <CollapsableCard defaultExpanded title={
                    <span className="status">{
                        status.map((st, idx) => <div key={idx}>{statusMap[st]}</div>)
                    }</span>
            }>
                <CardBody>
                    <div className="state">{JSON.stringify(sortKeys(JSON.parse(currentCase.state)), null, 2)}</div>
                </CardBody>
            </CollapsableCard>
            <h3>Log</h3>
            {log.map(entry => (
                <LogEntry
                    key={entry.index}
                    isLast={entry.index === log.length - 1}
                    onTeleport={() => teleportTo(entry.index)}
                    entry={entry}
                    statusMap={statusMap}
                />
            ))}
        </div>
    ))));
}


function sortKeys(obj, fn){
    return Object.keys(obj).sort(fn).reduce((_, k) => {
        _[k] = obj[k];
        return _;
    }, {})
}

function CollapsableCard({
    title, children, defaultExpanded
}) {
    const [expanded, setExpanded] = useState(defaultExpanded);
    return (<Card className={`log-entry ${expanded ? "selected border-info" : ""}`}>
        <CardHeader className={expanded ? "bg-primary" : ""} onClick={() => setExpanded(!expanded)}>{title}</CardHeader>
        {expanded ? (children) : null}
    </Card>);
}

function LogEntry({
    entry: {createdAt, action, status, state, user: {firstName, lastName}, index},
    statusMap,
    onTeleport,
    isLast
}){
    return (<CollapsableCard
        title={<>
            <span className="index">{index + 1}</span>
            <span className="status">{statusMap[status]}</span>
            <span className="action">{action}</span>
        </>}
    >
        <CardBody>
            <div>
                <div>User: {firstName} {lastName}</div>
            </div>
            <div className="state">{JSON.stringify(JSON.parse(state), null, 2)}</div>
            {!isLast ? (
                <Button onClick={onTeleport}>Teleport Here</Button>
            ): null}
        </CardBody>
        <CardFooter className="text-muted text-center timestamp">
            {dayjs(createdAt).format(DATETIME_FORMAT)}
        </CardFooter>
    </CollapsableCard>);
}



export default CaseHistory;