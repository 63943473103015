import { readOnlyFileUI } from "../commons";


const form = {
    page: 'case-files',
    title: "Archivos de Caso",
    displayName: 'CaseFiles',
    noSubmit: true,
};


const documentosIdentificacion = {
    title: "Documentos de Identificación",
    type: "object",
    properties: {
        "cedulaIdentidadAnverso": {
            title: "Cédula de Identidad (anverso)",
            type: "number"
        },
        "cedulaIdentidadReverso": {
            title: "Cédula de Identidad (reverso)",
            type: "number"
        },
        "pasaporte": {
            title: "Pasaporte",
            type: "number",
        },
        "documentoIdentidadPaisOrigen": {
            title: "Documento de Identidad Pais de Origen",
            type: "number",
        },
        "documentoGestionMigratoria": {
            title: "Documento Gestión Migratoria",
            type: "number",
        },
    },

}

const soporteFuenteIngresos = {
    title: "Soporte Fuente de Ingresos",
    type: "object",
    properties: {
        "evidenciaIngresosRegulares": {
            title: "Evidencia Ingresos Regulares",
            type: "number",
        },
        "declaracionImpuestosUltimoAno": {
            title: "Declaración de Impuestos del ultimo año",
            type: "number",
        },
        "soporteNaturalezaActividades": {
            title: "Soporte de naturaleza de actividades",
            type: "number",
        },
        "declaracionIR1": {
            title: "Declaración IR1- Ingresos adicionales",
            type: "number",
        },
        "estadosIngresosGastos": {
            title: "Estados de ingresos y gastos - Act. informales",
            type: "number",
        },
    },

}

const soporteOrigenProcedenciaFondos = {
    title: "Soporte Origen y procedencia de fondos",
    type: "object",
    properties: {
        "soporteOrigenFondos": {
            title: "Soporte origen de fondos",
            type: "number",
        },
        "soporteProcedenciaFondos": {
            title: "Soporte procedencia de fondos",
            type: "number",
        },
    },

}

const soportesAdicionalesPEP = {
    title: "Soportes Adicionales Personas Expuestas Políticamente",
    type: "object",
    properties: {
        "declaracionJuradaPatrimonioDesignacion": {
            title: "Declaración Jurada de Patrimonio o decreto de designación",
            type: "number",
        },
        "declaracionIRParticipacionEmpresas": {
            title: "Declaración IR-1 - Participación en empresas",
            type: "number",
        },
    },

}

const soporteSolicitudPrestamo = {
    title: "Soporte Solicitud de Préstamo",
    type: "object",
    properties: {
        "soporteGarantia": {
            title: "Soporte de Valor de Garantía",
            type: "number",
        },

    },

}

const soporteDebidaDiligencia = {
    title: "Soportes Debida Diligencia",
    type: "object",
    properties: {
        "revisionListaOFAC": {
            title: "Revisión en lista OFAC",
            type: "number",
        },
        "revisionListaONU": {
            title: "Revisión en lista ONU",
            type: "number",
        },
        "matrizRiesgo": {
            title: "Matriz de Riesgo",
            type: "number",
        },
        "revisionBuroCredito": {
            title: "Revisión Buró de Crédito",
            type: "number",
        },
    },
}

const reportesDebidaDiligenciaFirmados = {
    title: "Reportes Debida Diligencia Firmados",
    type: "object",
    properties: {
        "reporteBancoFirmado": {
            title: "Reporte de BANCO Firmado",
            type: "number",
        },
        "reportePuestoFirmado": {
            title: "Reporte de PUESTO Firmado",
            type: "number",
        },
        "reporteSAFIFirmado": {
            title: "Reporte de SAFI Firmado",
            type: "number",
        },
        "aprobacionAltaGerencia": {
            title: "Aprobación de Alta Gerencia",
            type: "number",
        },
    }
}

const aditionalFiles = {
    title: " ",
    description: "Archivos Adicionales",
    type: "array",
    items: {
        "properties": {
            "documentName": {
                title: "Nombre Archivo",
                type: "string"
            },
            "document": {
                title: "Archivo",
                type: "number"
            },
        }
    },
}

form.schema = {
    type: "object",
    properties: {
        "t": {
            title: "Archivos de Caso",
            type: "object",
            properties: {},
        },
        "documentosGenerados": {
            title: "Documentos Generados por Sistema",
            type: "object",
            properties: {
                "titlePuesto": { title: "Puesto", type: "null", const: null },
                "titleBanco": { title: "Banco", type: "null", const: null },
                "titleSafi": { title: "Safi", type: "null", const: null },
                "tarjetaDeFirmaPuesto": {
                    title: "Tarjeta de Firma",
                    type: "number",
                },
                "informeInversionistaPuesto": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoPuesto": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "contratoCuentaPuesto": {
                    title: "Contrato Apertura Cuenta",
                    type: "number",
                },
                "cartaAutorizacionCuentaPuesto": {
                    title: "Carta Autorización Apertura Cuenta",
                    type: "number",
                },
                "formularioClientePuesto": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioW8BenPuesto": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formularioW9Puesto": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formularioMoneylinePuesto": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
                "tarjetaFirmaSafi": {
                    title: "Tarjeta de Firma",
                    type: "number",
                },
                "informeInversionistaSafi": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoSafi": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formularioClienteSafi": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formularioW8BenSafi": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formularioW9Safi": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formularioMoneylineSafi": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
                "tarjetaFirmaBanco": {
                    title: "Tarjeta de Firma",
                    type: "number",
                },
                "formularioClienteBanco": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "contratoMarcoBanco": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "contratoCuentaBanco": {
                    title: "Contrato Apertura Cuenta",
                    type: "number",
                },
                "formularioW8BenBanco": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formularioW9Banco": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formularioMoneylineBanco": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
                "formularioSujetoObligado": {
                    title: "Formulario Sujeto Obligado No Financiero Persona Física",
                    type: "number",
                },
                "solicitudPrestamoDL": {
                    title: "Solicitud de Préstamo",
                    type: "number"
                }
            }
        },
        "documentosFirmados": {
            title: "Documentos Subidos al Sistema",
            type: "object",
            properties: {
                "documentosClienteTitular": {
                    title: "Documentos Cliente Titular",
                    type: "object",
                    properties: {
                        "documentosIdentificacion": documentosIdentificacion,
                        "soporteFuenteIngresos": soporteFuenteIngresos,
                        "soporteOrigenProcedenciaFondos": soporteOrigenProcedenciaFondos,
                        "soportesAdicionalesPEP": soportesAdicionalesPEP,
                        "soporteSolicitudPrestamo": soporteSolicitudPrestamo,
                    },
                },
                "documentosFirmadosPuesto": {
                    title: "Documentos firmados Puesto",
                    type: "object",
                    properties: {
                        "tarjetaFirmaPuesto": {
                            title: "Tarjeta Firma - Puesto",
                            type: "number",
                        },
                        "informeInversionistaPuesto": {
                            title: "Informe Perfil de Inversionista - Puesto",
                            type: "number",
                        },
                        "contratoMarcoPuesto": {
                            title: "Contrato Marco - Puesto",
                            type: "number",
                        },
                        "contratoCuentaAperturaPuesto": {
                            title: "Contrato Apertura Cuenta - Puesto",
                            type: "number",
                        },
                        "cartaAutoApertCuentaPuesto": {
                            title: "Carta Autorización Apertura Cuenta - Puesto",
                            type: "number",
                        },
                        "formClientePuesto": {
                            title: "Formulario Conozca su Cliente - Puesto",
                            type: "number",
                        },
                        "formW8BenPuesto": {
                            title: "Formulario W8-BEN - Puesto",
                            type: "number",
                        },
                        "formW9Puesto": {
                            title: "Formulario W9 - Puesto",
                            type: "number",
                        },
                        "formMoneylinePuesto": {
                            title: "Formulario Solicitud Moneyline - Puesto",
                            type: "number",
                        },
                    }
                },
                "documentosFirmadosSafi": {
                    title: "Documentos firmados SAFI",
                    type: "object",
                    properties: {
                        "tarjetaFirmaSafi": {
                            title: "Tarjeta Firma - SAFI",
                            type: "number",
                        },
                        "informeInversionistaSafi": {
                            title: "Informe Perfil de Inversionista - SAFI",
                            type: "number",
                        },
                        "contratoMarcoSafi": {
                            title: "Contrato Marco - Safi",
                            type: "number",
                        },
                        "formClienteSafi": {
                            title: "Formulario Conozca su Cliente - SAFI",
                            type: "number",
                        },
                        "formW8BenSafi": {
                            title: "Formulario W8-BEN - SAFI",
                            type: "number",
                        },
                        "formW9Safi": {
                            title: "Formulario W9 - SAFI",
                            type: "number",
                        },
                        "formMoneylineSafi": {
                            title: "Formulario Solicitud Moneyline - SAFI",
                            type: "number",
                        },
                    }
                },
                "documentosFirmadosBanco": {
                    title: "Documentos firmados Banco",
                    type: "object",
                    properties: {
                        "tarjetaFirmaBanco": {
                            title: "Tarjeta Firma - Banco",
                            type: "number",
                        },
                        "contratoMarcoBanco": {
                            title: "Contrato Marco - Banco",
                            type: "number",
                        },
                        "formClienteBanco": {
                            title: "Formulario Conozca su Cliente - Banco",
                            type: "number",
                        },
                        "formW8BenBanco": {
                            title: "Formulario W8-BEN - Banco",
                            type: "number",
                        },
                        "formW9Banco": {
                            title: "Formulario W9 - Banco",
                            type: "number",
                        },
                        "formMoneylineBanco": {
                            title: "Formulario Solicitud Moneyline - Banco",
                            type: "number",
                        },
                        "solicitudPrestamo": {
                            title: "Solicitud de Préstamo",
                            type: "number",
                        },
                    }
                },
                "soporteSujetoObligado": {
                    title: "Soporte Sujeto Obligado",
                    type: "object",
                    properties: {
                        "soporteSujetoObligadoFile": {
                            title: "Soporte de Sujeto Obligado No Financiero Personal",
                            type: "number",
                        },
                    }
                },
                "soporteDebidaDiligencia": soporteDebidaDiligencia,
                "reportesDebidaDiligenciaFirmados": reportesDebidaDiligenciaFirmados,
                "aditionalFiles": aditionalFiles
            }
        },
    },
    required: [],
};



form.uiSchema = {
    "ui:field": "HideObjectField",
    "ui:newSection": true,
    "t": {
        "joli:showAttr": {'formContext': { 'auth.permissions.downloadAllFiles'    : 1 } },
    },
    "documentosGenerados": {
        "classNames": "personalNonCCD-dct",
        "ui:field": "HideObjectField",
        "titlePuesto": { classNames: "title                   puesto" },
        "titleBanco": { classNames: "title                   banco" },
        "titleSafi": { classNames: "title                   safi" },
        "tarjetaDeFirmaPuesto": { ...readOnlyFileUI, classNames: "tarjetaDeFirma          puesto" },
        "informeInversionistaPuesto": { ...readOnlyFileUI, classNames: "informeInversionista    puesto" },
        "contratoMarcoPuesto": { ...readOnlyFileUI, classNames: "contratoMarco           puesto" },
        "contratoCuentaPuesto": { ...readOnlyFileUI, classNames: "contratoCuenta          puesto" },
        "cartaAutorizacionCuentaPuesto": { ...readOnlyFileUI, classNames: "cartaAutorizacionCuenta puesto" },
        "formularioClientePuesto": { ...readOnlyFileUI, classNames: "formularioCliente       puesto" },
        "formularioW8BenPuesto": { ...readOnlyFileUI, classNames: "formularioW8Ben         puesto" },
        "formularioW9Puesto": { ...readOnlyFileUI, classNames: "formularioW9            puesto" },
        "formularioMoneylinePuesto": { ...readOnlyFileUI, classNames: "formularioMoneyline     puesto" },
        "tarjetaFirmaSafi": { ...readOnlyFileUI, classNames: "tarjetaFirma            safi" },
        "informeInversionistaSafi": { ...readOnlyFileUI, classNames: "informeInversionista    safi" },
        "contratoMarcoSafi": { ...readOnlyFileUI, classNames: "contratoMarco           safi" },
        "formularioClienteSafi": { ...readOnlyFileUI, classNames: "formularioCliente       safi" },
        "formularioW8BenSafi": { ...readOnlyFileUI, classNames: "formularioW8Ben         safi" },
        "formularioW9Safi": { ...readOnlyFileUI, classNames: "formularioW9            safi" },
        "formularioMoneylineSafi": { ...readOnlyFileUI, classNames: "formularioMoneyline     safi" },
        "tarjetaFirmaBanco": { ...readOnlyFileUI, classNames: "tarjetaFirma            banco" },
        "formularioClienteBanco": { ...readOnlyFileUI, classNames: "formularioCliente       banco" },
        "contratoMarcoBanco": { ...readOnlyFileUI, classNames: "contratoMarco           banco" },
        "contratoCuentaBanco": { ...readOnlyFileUI, classNames: "contratoCuenta          banco" },
        "formularioW8BenBanco": { ...readOnlyFileUI, classNames: "formularioW8Ben         banco" },
        "formularioW9Banco": { ...readOnlyFileUI, classNames: "formularioW9            banco" },
        "formularioMoneylineBanco": { ...readOnlyFileUI, classNames: "formularioMoneyline     banco" },
        "formularioSujetoObligado": { ...readOnlyFileUI, classNames: "formularioSujetoObligado puesto" },
        "solicitudPrestamoDL": { ...readOnlyFileUI, classNames: "solicitudPrestamoDL" },
        "joli:showAttr": {'formContext': { 'auth.permissions.downloadAllFiles'    : 1 } },
    },
    "documentosFirmados": {
        "classNames": "smallTwoRows",
        "documentosClienteTitular": {
            "ui:field": "HideObjectField",
            "documentosIdentificacion": {
                "ui:field": "HideObjectField",
                "cedulaIdentidadAnverso": {
                    ...readOnlyFileUI,
                },
                "cedulaIdentidadReverso": {
                    ...readOnlyFileUI,
                },
                "pasaporte": {
                    ...readOnlyFileUI,
                },
                "documentoIdentidadPaisOrigen": {
                    ...readOnlyFileUI,
                },
                "documentoGestionMigratoria": {
                    ...readOnlyFileUI,
                },
            },
            "soporteFuenteIngresos": {
                "ui:field": "HideObjectField",
                "evidenciaIngresosRegulares": {
                    ...readOnlyFileUI,
                },
                "declaracionImpuestosUltimoAno": {
                    ...readOnlyFileUI,
                },
                "soporteNaturalezaActividades": {
                    ...readOnlyFileUI,
                },
                "declaracionIR1": {
                    ...readOnlyFileUI,
                },
                "estadosIngresosGastos": {
                    ...readOnlyFileUI,
                },
            },
            "soporteOrigenProcedenciaFondos": {
                "ui:field": "HideObjectField",
                "soporteOrigenFondos": {
                    ...readOnlyFileUI,
                },
                "soporteProcedenciaFondos": {
                    ...readOnlyFileUI,
                },
            },
            "soportesAdicionalesPEP": {
                "ui:field": "HideObjectField",
                "declaracionJuradaPatrimonioDesignacion": {
                    ...readOnlyFileUI,
                },
                "declaracionIRParticipacionEmpresas": {
                    ...readOnlyFileUI,
                },
            },
            "soporteSolicitudPrestamo": {
                "ui:field": "HideObjectField",
                "soporteGarantia": {
                    ...readOnlyFileUI,
                },
            }

        },
        documentosFirmadosPuesto: {
            "ui:field": "HideObjectField",
            "tarjetaFirmaPuesto": {
                ...readOnlyFileUI,
            },
            "informeInversionistaPuesto": {
                ...readOnlyFileUI,
            },
            "contratoMarcoPuesto": {
                ...readOnlyFileUI,
            },
            "contratoCuentaAperturaPuesto": {
                ...readOnlyFileUI,
            },
            "cartaAutoApertCuentaPuesto": {
                ...readOnlyFileUI,
            },
            "formClientePuesto": {
                ...readOnlyFileUI,
            },
            "formW8BenPuesto": {
                ...readOnlyFileUI,
            },
            "formW9Puesto": {
                ...readOnlyFileUI,
            },
            "formMoneylinePuesto": {
                ...readOnlyFileUI,
            },
        },
        documentosFirmadosSafi: {
            "ui:field": "HideObjectField",
            "tarjetaFirmaSafi": {
                ...readOnlyFileUI,
            },
            "informeInversionistaSafi": {
                ...readOnlyFileUI,
            },
            "contratoMarcoSafi": {
                ...readOnlyFileUI,
            },
            "formClienteSafi": {
                ...readOnlyFileUI,
            },
            "formW8BenSafi": {
                ...readOnlyFileUI,
            },
            "formW9Safi": {
                ...readOnlyFileUI,
            },
            "formMoneylineSafi": {
                ...readOnlyFileUI,
            },
        },
        documentosFirmadosBanco: {
            "ui:field": "HideObjectField",
            "tarjetaFirmaBanco": {
                ...readOnlyFileUI,
            },
            "contratoMarcoBanco": {
                ...readOnlyFileUI,
            },
            "formClienteBanco": {
                ...readOnlyFileUI,
            },
            "formW8BenBanco": {
                ...readOnlyFileUI,
            },
            "formW9Banco": {
                ...readOnlyFileUI,
            },
            "formMoneylineBanco": {
                ...readOnlyFileUI,
            },
            "solicitudPrestamo": {
                ...readOnlyFileUI,
            },
        },
        soporteSujetoObligado: {
            "ui:field": "HideObjectField",
            "soporteSujetoObligadoFile": {
                ...readOnlyFileUI,
            },
        },
        "soporteDebidaDiligencia": {
            "ui:field": "HideObjectField",
            "revisionListaOFAC": { ...readOnlyFileUI, },
            "revisionBuroCredito": { ...readOnlyFileUI, },
            "revisionListaONU": { ...readOnlyFileUI, },
            "matrizRiesgo": { ...readOnlyFileUI, },
        },
        "reportesDebidaDiligenciaFirmados": {
            "ui:field": "HideObjectField",
            "reportePuestoFirmado": { ...readOnlyFileUI, },
            "reporteBancoFirmado": { ...readOnlyFileUI, },
            "reporteSAFIFirmado": { ...readOnlyFileUI, },
            "aprobacionAltaGerencia": { ...readOnlyFileUI, },
        },
        "aditionalFiles": {
            "classNames": "aditionalFiles",
            "items": { "document": { ...readOnlyFileUI, } },
            "ui:options": {
                "addable": false
            }
        },
        "joli:showAttr": {'formContext': { 'auth.permissions.downloadAllFiles'    : 1 } },
    },
};

form.objectMap = {

    // Files generated in system
    // Puesto
    "generatedFiles.fullkyc_$idx_TarjetaDeFirmaPuesto": "documentosGenerados.tarjetaDeFirmaPuesto",
    "generatedFiles.fullkyc_$idx_InformePerfilDeInversionistaPuesto": "documentosGenerados.informeInversionistaPuesto",
    "generatedFiles.fullkyc_$idx_ContratoMarcoPuesto": "documentosGenerados.contratoMarcoPuesto",
    "generatedFiles.fullkyc_$idx_ContratoAperturaCuentaPuesto": "documentosGenerados.contratoCuentaPuesto",
    "generatedFiles.fullkyc_$idx_CartaAutorizaciónAperturaCuentaPuesto": "documentosGenerados.cartaAutorizacionCuentaPuesto",
    "generatedFiles.fullkyc_$idx_FormularioConozcaSuClientePuesto": "documentosGenerados.formularioClientePuesto",
    "generatedFiles.fullkyc_$idx_FormularioSolicitudMoneylinePuesto": "documentosGenerados.formularioMoneylinePuesto",
    "generatedFiles.fullkyc_$idx_IrsW8benPuesto": "documentosGenerados.formularioW8BenPuesto",
    "generatedFiles.fullkyc_$idx_IrsW9SpPuesto": "documentosGenerados.formularioW9Puesto",
    // SAFI
    "generatedFiles.fullkyc_$idx_TarjetaDeFirmaSafi": "documentosGenerados.tarjetaFirmaSafi",
    "generatedFiles.fullkyc_$idx_InformePerfilDeInversionistaSafi": "documentosGenerados.informeInversionistaSafi",
    "generatedFiles.fullkyc_$idx_ContratoMarcoSafi": "documentosGenerados.contratoMarcoSafi",
    "generatedFiles.fullkyc_$idx_FormularioConozcaSuClienteSafi": "documentosGenerados.formularioClienteSafi",
    "generatedFiles.fullkyc_$idx_FormularioSolicitudMoneylineSafi": "documentosGenerados.formularioMoneylineSafi",
    "generatedFiles.fullkyc_$idx_IrsW8benSafi": "documentosGenerados.formularioW8BenSafi",
    "generatedFiles.fullkyc_$idx_IrsW9SpSafi": "documentosGenerados.formularioW9Safi",
    // Banco
    "generatedFiles.fullkyc_$idx_TarjetaDeFirmaBanco": "documentosGenerados.tarjetaFirmaBanco",
    "generatedFiles.fullkyc_$idx_ContratoMarcoBanco": "documentosGenerados.contratoMarcoBanco",
    "generatedFiles.fullkyc_$idx_ContratoAperturaCuentaBanco": "documentosGenerados.contratoCuentaBanco",
    "generatedFiles.fullkyc_$idx_FormularioConozcaSuClienteBanco": "documentosGenerados.formularioClienteBanco",
    "generatedFiles.fullkyc_$idx_FormularioSolicitudMoneylineBanco": "documentosGenerados.formularioMoneylineBanco",
    "generatedFiles.fullkyc_$idx_IrsW8benBanco": "documentosGenerados.formularioW8BenBanco",
    "generatedFiles.fullkyc_$idx_IrsW9SpBanco": "documentosGenerados.formularioW9Banco",
    "generatedFiles.fullkyc_$idx_SujetoObligadoPersonal": "documentosGenerados.formularioSujetoObligado",
    // Loan
    "generatedFiles.fullkyc_$idx_SolicitudDePrestamoPersonal": "documentosGenerados.solicitudPrestamoDL",


    // Files uploaded
    "clients[$idx].retailClientDueDiligenceReport.puestoCardSignatureFileId": "documentosFirmados.documentosFirmadosPuesto.tarjetaFirmaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoInvestorReportFileId": "documentosFirmados.documentosFirmadosPuesto.informeInversionistaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoMarkContractFileId": "documentosFirmados.documentosFirmadosPuesto.contratoMarcoPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoAccountContractFileId": "documentosFirmados.documentosFirmadosPuesto.contratoCuentaAperturaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoAccountOpeningLetterFileId": "documentosFirmados.documentosFirmadosPuesto.cartaAutoApertCuentaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoClientFormFileId": "documentosFirmados.documentosFirmadosPuesto.formClientePuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoW8BenFileId": "documentosFirmados.documentosFirmadosPuesto.formW8BenPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoW9FileId": "documentosFirmados.documentosFirmadosPuesto.formW9Puesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoMoneylineFileId": "documentosFirmados.documentosFirmadosPuesto.formMoneylinePuesto",
    "clients[$idx].retailClientDueDiligenceReport.safiCardSignatureFileId": "documentosFirmados.documentosFirmadosSafi.tarjetaFirmaSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiInvestorReportFileId": "documentosFirmados.documentosFirmadosSafi.informeInversionistaSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiMarkContractFileId": "documentosFirmados.documentosFirmadosSafi.contratoMarcoSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiClientFormFileId": "documentosFirmados.documentosFirmadosSafi.formClienteSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiW8BenFileId": "documentosFirmados.documentosFirmadosSafi.formW8BenSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiW9FileId": "documentosFirmados.documentosFirmadosSafi.formW9Safi",
    "clients[$idx].retailClientDueDiligenceReport.safiMoneylineFileId": "documentosFirmados.documentosFirmadosSafi.formMoneylineSafi",
    "clients[$idx].retailClientDueDiligenceReport.bancoCardsignatureFileId": "documentosFirmados.documentosFirmadosBanco.tarjetaFirmaBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoMarkContractFileId": "documentosFirmados.documentosFirmadosBanco.contratoMarcoBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoClientFormFileId": "documentosFirmados.documentosFirmadosBanco.formClienteBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoW8BenFileId": "documentosFirmados.documentosFirmadosBanco.formW8BenBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoW9FileId": "documentosFirmados.documentosFirmadosBanco.formW9Banco",
    "clients[$idx].retailClientDueDiligenceReport.bancoMoneylineFileId": "documentosFirmados.documentosFirmadosBanco.formMoneylineBanco",
    "clients[$idx].retailClientDueDiligenceReport.soporteSujetoObligadoFileId": "documentosFirmados.soporteSujetoObligado.soporteSujetoObligadoFile",
    "clients[$idx].retailClientLoanInfo.applicationFileId": "documentosFirmados.soporteSujetoObligado.soporteSujetoObligadoFile",
    "clients[$idx].retailClientBasicInfo.idCardFrontFileId": "documentosFirmados.documentosClienteTitular.documentosIdentificacion.cedulaIdentidadAnverso",
    "clients[$idx].retailClientBasicInfo.idCardBackFileId": "documentosFirmados.documentosClienteTitular.documentosIdentificacion.cedulaIdentidadReverso",
    "clients[$idx].retailClientBasicInfo.passportFileId": "documentosFirmados.documentosClienteTitular.documentosIdentificacion.pasaporte",
    "clients[$idx].retailClientBasicInfo.countryOfOriginIdFileId": "documentosFirmados.documentosClienteTitular.documentosIdentificacion.documentoIdentidadPaisOrigen",
    "clients[$idx].retailClientBasicInfo.emmigrationDocumentFileId": "documentosFirmados.documentosClienteTitular.documentosIdentificacion.documentoGestionMigratoria",

    "clients[$idx].retailClientBasicInfo.regularIncomeEvidenceFileId": "documentosFirmados.documentosClienteTitular.soporteFuenteIngresos.evidenciaIngresosRegulares",
    "clients[$idx].retailClientBasicInfo.lastYearTaxDecarationFileId": "documentosFirmados.documentosClienteTitular.soporteFuenteIngresos.declaracionImpuestosUltimoAno",
    "clients[$idx].retailClientBasicInfo.naturalActivitiesSupportFileId": "documentosFirmados.documentosClienteTitular.soporteFuenteIngresos.soporteNaturalezaActividades",
    "clients[$idx].retailClientBasicInfo.ir1DeclarationFileId": "documentosFirmados.documentosClienteTitular.soporteFuenteIngresos.declaracionIR1",
    "clients[$idx].retailClientBasicInfo.incomeExpenseStateFileId": "documentosFirmados.documentosClienteTitular.soporteFuenteIngresos.estadosIngresosGastos",

    "clients[$idx].retailClientBasicInfo.fundsOriginSupportFileId": "documentosFirmados.documentosClienteTitular.soporteOrigenProcedenciaFondos.soporteOrigenFondos",
    "clients[$idx].retailClientBasicInfo.fundsProcedenceSupportFileId": "documentosFirmados.documentosClienteTitular.soporteOrigenProcedenciaFondos.soporteProcedenciaFondos",

    "clients[$idx].retailClientBasicInfo.designationHeritageFileId": "documentosFirmados.documentosClienteTitular.soportesAdicionalesPEP.declaracionJuradaPatrimonioDesignacion",
    "clients[$idx].retailClientBasicInfo.enterpriseParticipationIRDeclarationFileId": "documentosFirmados.documentosClienteTitular.soportesAdicionalesPEP.declaracionIRParticipacionEmpresas",

    "clients[$idx].retailClientBasicInfo.guarranteeSupportFileId": "documentosFirmados.documentosClienteTitular.soporteSolicitudPrestamo.soporteGarantia",

    "generatedFiles.shortkyc_$idx_FormularioConozcaSuClienteBanco": "documentosFirmados.documentosClienteTitular.formularios.conozcaSuCliente",

    "clients[$idx].retailClientDueDiligenceReport.ofacRevisionFileId": "documentosFirmados.soporteDebidaDiligencia.revisionListaOFAC",
    "clients[$idx].retailClientDueDiligenceReport.creditBuroRevisionFileId": "documentosFirmados.soporteDebidaDiligencia.revisionBuroCredito",
    "clients[$idx].retailClientDueDiligenceReport.listRevisionFileId": "documentosFirmados.soporteDebidaDiligencia.revisionListaONU",
    "clients[$idx].retailClientDueDiligenceReport.riskMatrixFileId": "documentosFirmados.soporteDebidaDiligencia.matrizRiesgo",

    "clients[$idx].retailClientDueDiligenceReport.SignedPuestoReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.reportePuestoFirmado",
    "clients[$idx].retailClientDueDiligenceReport.SignedBancoReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.reporteBancoFirmado",
    "clients[$idx].retailClientDueDiligenceReport.SignedSAFIReportFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.reporteSAFIFirmado",
    "clients[$idx].retailClientDueDiligenceReport.HighManagementApprovalFileId": "documentosFirmados.reportesDebidaDiligenciaFirmados.aprobacionAltaGerencia",
    "clients[$idx].retailClientDueDiligenceReport.additionalFilesList.items": {
        array: "documentosFirmados.aditionalFiles",
        map: {
            "name": "documentName",
            "fileId": "document",
        }
    },
}


export default form;