import dropdownOptions from '../../util/dropdownOptions';
import {
    clientTypeId,
    preferredBranchId,
    boolYesNoType,
    strYesNoNAType,
    tipoIdentificacionPersonal,
    mergeObjects,
    contractSigner
} from '../commons';
import { TASA_CAMBIO_MIN } from "../../constants";

import secondaryClientForm from '../secondary/KYCPersonalClientSchema';


const form = {
    title: "Full KYC",
    displayName: 'KYCPersonalClient',
    page: 'kyc-personal',
    commentFieldsMap: {
        "Fatca": "clients[0].retailClientFatcaInfo.comments",
        "Prestamos": "clients[0].retailClientLoanInfo.comments",
        "Datos Laborales": "clients[0].retailClientWorkInfo.comments",
    },
    secondaryClientForm,
    generateDocuments: "generateFullKYCDocuments"
};


const datosLaboralesSchema = {
    title: "Datos Laborales/ Actividad Económica",
    type: "object",
    properties: {
        "empresa": {
            title: "Empresa",
            type: "string",
        },
        "telefonoEmpresa": {
            title: "Teléfono del trabajo",
            type: "string",
        },
        "faxEmpresa": {
            title: "Fax",
            type: "string"
        },
        "emailLaboral": {
            title: "Email laboral",
            type: "string",
        },
        "direccionEmpresa": {
            title: "Dirección de empresa",
            type: "string",
        },
        "sector": {
            title: "Sector",
            type: "string",
        },
        "ciudad": {
            title: "Ciudad",
            type: "string",
        },
        "provincia": {
            title: "Provincia",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional,
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "actividadPrincipal": {
            title: "Actividad Principal",
            type: "string",
            enum: dropdownOptions.actividades,
        },
        "cargo": {
            title: "Cargo Ocupado",
            type: "string",
        },
        "salarioMensual": {
            title: "Ingresos Mensuales:",
            type: "number"
        },
        "fechaIngreso": {
            title: "Fecha Ingreso",
            type: "string",
            format: "date",
        },
        "nombreEmpleoAnterior": {
            title: "Empresa Empleo Anterior:",
            type: "string"
        },
        "cargoEmpleoAnterior": {
            title: "Cargo Empleo Anterior:",
            type: "string"
        },
        "tiempoEmpleoAnterior":
        {
            title: "Tiempo Empleo Anterior:",
            type: "string"
        },
        "telefonoEmpleoAnterior": {
            title: "Teléfono Empleo Anterior:",
            type: "string"
        },
        "emailEmpleoAnterior": {
            title: "Email Empleo Anterior:",
            type: "string"
        },
        "comentarios": {
            title: "Comentarios",
            type: "string",
        },
    },
    required: ["actividadPrincipal"],
}
const fatca = {
    title: "FATCA",
    type: "object",
    properties: {
        "nacionalidadOtroPais": {
            title: "¿Tiene nacionalidad o ciudadanía de otro país?",
            ...boolYesNoType
        },
        "indicarPaises": {
            title: "Indicar países",
            type: "string",
        },
        "residenciaFisicaUSA": {
            title: "¿Es Estados Unidos su país de residencia física?",
            ...boolYesNoType
        },
        "estatusMigratorio": {
            title: "¿Tiene algún estatus migratorio en los Estados Unidos?",
            type: "string",
            enumNames: ["No", "Ciudadanía", "Residencia Fiscal", "Residencia Permanente"],
            enum: ["No", "Ciudadania", "Residencia Fiscal", "Residencia Permanente"],
        },
        "numIDFiscal": {
            title: "Número ID Fiscal",
            type: "string",
        },
        "multiplesPaisesResidenciaFiscal": {
            title: "¿Tiene más de un país de residencia fiscal?",
            ...boolYesNoType
        },
        "paisesResidenciaFiscal": {
            title: "Países con residencia fiscal",
            type: "string",
        },
        "greenCard": {
            title: "¿Tiene Green Card?",
            ...boolYesNoType
        },
        "direccionEnvioEstadosUnidos": {
            title: "¿Tiene dirección de envío en los Estados Unidos?",
            ...boolYesNoType
        },
        "tieneDireccionResidenciaEstadosUnidos": {
            title: "¿Tiene dirección de residencia en los Estados Unidos?",
            ...boolYesNoType
        },
        "telefonoFueraPais": {
            title: "¿Tiene número telefónico fuera del país?",
            ...boolYesNoType
        },
        "telefonoEstadoUnidos": {
            title: "Si tiene número telefónico en Estados Unidos favor indicar:",
            type: "string",
        },
        "comentarios": {
            title: "Comentarios (Si se encuentra en proceso de adquirir una residencia o ciudadanía en otro país, indique)",
            type: "string",
        },
        "direccionResidenciaEstadosUnidos": {
            title: "Dirección de residencia en Estados Unidos:",
            type: "string",
        },
    },
    required: ["nacionalidadOtroPais", "residenciaFisicaUSA", "estatusMigratorio",
        "multiplesPaisesResidenciaFiscal", "greenCard", "direccionEnvioEstadosUnidos",
        "tieneDireccionResidenciaEstadosUnidos", "telefonoFueraPais",
    ],
}
const declaracionJurada = {
    title: "Declaración Jurada",
    type: "object",
    properties: {
        "declaracionJurada1": {
            "title": "Que las informaciones contenidas en este formulario respecto de su perfil, origen de fondos e ingresos mensuales, son veraces, certeras y comprobables, incluyendo pero no limitado a las referentes a su empleo y/o profesión, según aplique, para cuyas actividades o labores, se encuentra debidamente habilitado o autorizado, si aplica.",
            "type": "boolean",
        },
        "declaracionJurada2": {
            "title": "Que reconoce que la Entidad es un sujeto obligado bajo las leyes, normas y regulaciones aplicables para la prevención de lavado de activos provenientes de actividades ilícitas, por lo que el Cliente autoriza a que la Entidad ejecute todos los pasos tendentes al cumplimiento de la debida diligencia, comprometiéndose el Cliente a cooperar para el buen desenvolvimiento de dicho proceso. A tales fines, el cliente autoriza a la Entidad realizar cualquier verificación en cualquier base de dato pública o privada. Específicamente, autoriza a la Entidad a verificar la información crediticia del Cliente a través de los centros de información crediticia, así como a suministrar a dichos centros de información crediticia, la información patrimonial y extra patrimonial que la Entidad considere relevante para los fines de evaluación crediticia, de conformidad con la Ley Organiza sobre Protección de Datos de Carácter Personal No. 172-13 del 13 de diciembre de 2013 y cualquier regulación aplicable.",
            "type": "boolean",
        },
        "declaracionJurada3": {
            "title": "Que los recursos, patrimonio y activos que posee y que serán utilizados en las operaciones de mercado de valores con la Entidad provienen de actividades lícitas y son destinados a actividades de lícito comercio, y no son el resultado de ninguna violación o contravención a las leyes vigentes en el país o en el extranjero. Igualmente, las rentas o frutos sean obtenidos a través de las operaciones de mercado de valores a través o con la intermediación de la Entidad serán destinados a un fin lícito, y no serán destinados a ninguna actividad que constituya una infracción a las leyes vigentes en el país o en el extranjero.",
            "type": "boolean",
        },
        "declaracionJurada4": {
            "title": "Que autoriza a la Entidad a crear una base de datos con mi información personal, bien sea la suministrada por el Cliente o bien sea la recolectada en los procesos de debida diligencia, y a compartir la misma con sus empresas vinculadas, así como a obtener toda la información y documentación que figuren en los expedientes del Cliente en empresas vinculadas a la Entidad; específicamente autorizo a la Entidad y empresas vinculadas a utilizar esta información para fines mercadológicos, publicitario y de ventas, incluyendo pero no limitado para la remisión correos electrónicos y mensajes telefónicos. El Cliente reconoce que la Entidad ha revelado que la información en la base de datos se encuentra sujeta al derecho de rectificación en los términos de la Ley Organiza sobre Protección de Datos de Carácter Personal No. 172-13 del 13 de diciembre de 2013 y cualquier regulación aplicable.",
            "type": "boolean",
        },
    },
    required: ["declaracionJurada1", "declaracionJurada2",
        "declaracionJurada3", "declaracionJurada4",
    ],
}
const personaPoliticamenteExpuesta = {
    title: "Persona Políticamente Expuesta",
    type: "object",
    properties: {
        "personaExpuestaPoliticamente": {
            title: "¿Es o ha sido una persona expuesta políticamente (PEP)?",
            ...boolYesNoType
        },
        "cargo": {
            title: "Cargo",
            type: "string",
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "fechaDesignacion": {
            title: "Fecha de designación",
            type: "string",
            format: "date",
        },
        "fechaRemocion": {
            title: "Fecha de remoción",
            type: "string",
            format: "date",
        },
        "parentescoConPersonaExpuestaPoliticamente": {
            title: "¿Tiene algún parentesco, es asociado cercano o realiza una operación en nombre de una persona expuesta políticamente (PEP)?",
            ...boolYesNoType
        },
        "nombrePEP": {
            title: "Nombre del PEP",
            type: "string",
        },
        "parentescoPEP": {
            title: "Parentesco o Afinidad",
            type: "string",
        },
        "cargoPEP": {
            title: "CargoPEP",
            type: "string",

        },
        "paisPEP": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "fechaDesignacionPEP": {
            title: "Fecha de designación",
            type: "string",
            format: "date",
        },
        "fechaRemocionPEP": {
            title: "Fecha de remoción",
            type: "string",
            format: "date",
        },
    },
    required: ["personaExpuestaPoliticamente", "parentescoConPersonaExpuestaPoliticamente",
    ],
}

const camposBancoSchema = {
    title: " ",
    type: "object",
    properties: {
        "beneficiariosDeTransaccionDiferentes": {
            title: "¿Existen beneficiarios directos o indirectos de la transaccion diferentes de usted?",
            ...boolYesNoType
        },
        "nombreBeneficiario": {
            title: "Nombre de beneficiario",
            type: "string"
        },
        "vinculadoJMMB": {
            title: "¿Es vinculado o relacionado a un vinculado de JMMB?",
            ...boolYesNoType
        },
        "empresaVinculada": {
            title: "Empresa vinculada",
            type: "string"
        },
        "numClienteBancoUCS": {
            title: "Número de cliente en UCS Banco",
            type: "string"
        },
    }
}

const transaccionesEsperadasSchema = {
    title: " ",
    description: "Transacciones esperadas",
    type: "object",
    properties: {
        "cantidadOperacionesMensuales": {
            title: "Cantidad de operaciones mensuales esperadas:",
            type: "string",
            enumNames: ["De 0 a 5", "De 5 a 10", "Más de 10", "Otro"],
            enum: ["De 0 a 5", "De 5 a 10", "Mas de 10", "Otro"],
        },
        "cantidadOperacionesMensualesOtro": {
            title: "Indicar otro",
            type: "number"
        },
        "formaMasFrecuenteTransacciones": {
            title: "Forma más frecuente de las transacciones:",
            type: "string",
            enum: ["Efectivo", "Cheque", "Transferencias Nacionales", "Transferencias Internacionales"],
        }
    }
}

const financialCertSchema = {
    title: "Certificado Financiero",
    type: "object",
    properties: {
        "inversionEstimadaRD": {
            title: "Monto estimado inversión en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "origenFondosRDOtro": {
            title: "Otro",
            type: "string"
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: TASA_CAMBIO_MIN
        },
    }
}

const safiSchema = {
    title: " ",
    description: "Inversiones en SAFI",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión SAFI en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para SAFI en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "origenFondosUSDOtro": {
            title: "Otro",
            type: "string"
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión SAFI en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para SAFI en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "origenFondosRDOtro": {
            title: "Otro",
            type: "string"
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: TASA_CAMBIO_MIN
        },
        "numClienteSAFIUCS": {
            title: "Número de cliente en UCS SAFI",
            type: "string"
        },
    }
}

const savingsAccountSchema = {
    title: " ",
    description: "Cuenta de Ahorro",
    type: "object",
    properties: {
        "rdCurrency": {
            title: "RD$",
            type: "boolean",
        },
        "usCurrency": {
            title: "US$",
            type: "boolean",
        },
        "depositoEstimadoRD": {
            title: "Monto estimado primer depósito en RD",
            type: "number",
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "origenFondosRDOtro": {
            title: "Otro",
            type: "string"
        },
        "depositoEstimadoUSD": {
            title: "Monto estimado primer depósito en USD",
            type: "number",
        },
        "origenFondosUSD": {
            title: "Origen de fondos en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "origenFondosUSDOtro": {
            title: "Otro",
            type: "string"
        },

    }
};

const inversionesSchema = {
    title: " ",
    description: "Inversiones en Puesto",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión Puesto en USD",
            type: "number",
            minimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "origenFondosUSDOtro": {
            title: "Otro",
            type: "string"
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Puesto en RD",
            type: "number",
            minimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "origenFondosRDOtro": {
            title: "Otro",
            type: "string"
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: TASA_CAMBIO_MIN
        },
    },
}
const perfilInversionista = {
    title: "Perfil del Inversionista",
    type: "object",
    properties: {
        "riskProdServTitle": {
            title: "Riesgo de productos y servicios",
            type: "null",
        },
        "riesgoProductosServiciosEdad": {
            title: "Edad",
            type: "string",
            enumNames: ["Mayor de 55 años", "De 35 a 55 años", "De 25 a 34 años", "Menor de 25 años"],
            enum: ["Mayor de 55 anos", "De 35 a 55 anos", "De 25 a 34 anos", "Menor de 25 anos"],
            // oneOf: [
            //     {
            //         title: "Mayor de 55 años",
            //         const: 1
            //     },
            //     {
            //         title: "De 35 a 55 años",
            //         const: 2
            //     },
            //     {
            //         title: "De 25 a 35 años",
            //         const: 3
            //     },
            //     {
            //         title: "Menor de 25 años",
            //         const: 4
            //     },
            // ],
        },
        "riesgoProductosServiciosObjetivo": {
            title: "Objetivo principal de su capital de inversión",
            type: "string",
            enumNames: ["Rentabilidad", "Seguridad", "Apreciación de Capital"],
            enum: ["Rentabilidad", "Seguridad", "Apreciacion de Capital"],
            // oneOf: [
            //     {
            //         title: "Rentabilidad",
            //         const: 1
            //     },
            //     {
            //         title: "Seguridad",
            //         const: 2
            //     },
            //     {
            //         title: "Apreciación de Capital",
            //         const: 3
            //     },
            // ],
        },
        "criteriosIdeasRentabilidad": {
            title: "Criterio que se ajusta mejor a sus ideas de rentabilidad/riesgo",
            type: "string",
            enum: ["Conservador (bajo riesgo)", "Moderado (riesgo medio)", "Agresivo (alto riesgo)"]
            // oneOf: [
            //     {
            //         title: "Conservador (bajo riesgo)",
            //         const: 1
            //     },
            //     {
            //         title: "Moderado (riesgo medio)",
            //         const: 2
            //     },
            //     {
            //         title: "Agresivo (alto riesgo)",
            //         const: 3
            //     },
            // ],
        },
        "nivelAcademico": {
            title: "Nivel Académico",
            type: "string",
            enumNames: ["Doctorado", "Maestría", "Postgrado", "Universitario", "Carrera Técnica", "Bachillerato", "Primaria", "Ninguno"],
            enum: ["Doctorado", "Maestria", "Postgrado", "Universitario", "Carrera Tecnica", "Bachillerato", "Primaria", "Ninguno"],
            // oneOf: [
            //     {
            //         title: "Doctorado",
            //         const: 1
            //     },
            //     {
            //         title: "Maestría",
            //         const: 2
            //     },
            //     {
            //         title: "Postgrado",
            //         const: 3
            //     },
            //     {
            //         title: "Universitario",
            //         const: 4
            //     },
            //     {
            //         title: "Carrera Técnica",
            //         const: 5
            //     },
            //     {
            //         title: "Bachillerato",
            //         const: 6
            //     },
            //     {
            //         title: "Primaria",
            //         const: 7
            //     },
            //     {
            //         title: "Ninguno",
            //         const: 8
            //     },
            // ],
        },
        "operacionesFinancierasNegociado": {
            title: " ",
            description: "Operaciones financieras que ha negociado",
            type: "object",
            properties: {
                "titles": {
                    title: " ",
                    type: "object",
                    properties: {
                        col1: { title: "Años manejando el producto", type: "null" },
                        col2: { title: "Número de operaciones anuales", type: "null" },
                        col3: { title: "Nivel de conocimiento", type: "null" },
                    },
                },
                "inversionCertificadosFinancieros": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Inversión en Certificados Financieros",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "bonosSoberanos": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Bonos soberanos y libres de riesgo",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "bonosCorporativos": {
                    title: "",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Bonos Corporativos",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "fondosInversionesMutuos": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Fondos de inversiones mutuos, opciones y futuros",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "fondosCerrados": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Fondos Cerrados, Préstamos de Margen, Operaciones de Préstamos de Títulos Valores, Compra-Venta de Operaciones a Plazo (Forwards) y Acciones",
                            type: "null",
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
                "otrosDerivados": {
                    title: " ",
                    type: "object",
                    properties: {
                        "title": {
                            title: "Otros derivados",
                            type: "null",
                        },
                        "indicar": {
                            title: " ",
                            type: "string"
                        },
                        "anosManejandoProducto": {
                            title: " ",
                            type: "string"
                        },
                        "numOperacionesAnuales": {
                            title: " ",
                            type: "string"
                        },
                        "nivelConocimiento": {
                            title: " ",
                            type: "string",
                            enum: ["Básico", "Intermedio", "Avanzado", "Ninguno"],
                        }
                    },
                },
            },
        },
        "plazoDeseaCumplirObjetivos": {
            title: "Plazo en que desea cumplir sus objetivos",
            type: "string",
            enumNames: ["Menos de 1 año", "Entre 1 año y 3 años", "Más de 3 años"],
            enum: ["Menos de 1 ano", "Entre 1 ano y 3 anos", "Mas de 3 anos"],
            // oneOf: [
            //     {
            //         title: "Menos de 1 año",
            //         const: 1
            //     },
            //     {
            //         title: "Entre 1 año y 3 años",
            //         const: 2
            //     },
            //     {
            //         title: "Más de 3 años",
            //         const: 3
            //     },
            // ],
        },
        "plazoAnticipaNecesitarInversiones": {
            title: "Plazo en que anticipa podría necesitar parcial o totalmente sus inversiones",
            type: "string",
            enumNames: ["Menos de 1 año", "Entre 1 año y 3 años", "Más de 3 años"],
            enum: ["Menos de 1 ano", "Entre 1 ano y 3 anos", "Mas de 3 anos"],
            // oneOf: [
            //     {
            //         title: "Menos de 1 año",
            //         const: 1
            //     },
            //     {
            //         title: "Entre 1 año y 3 años",
            //         const: 2
            //     },
            //     {
            //         title: "Más de 3 años",
            //         const: 3
            //     },
            // ],
        },
        "capacidadAbsorverPerdidas": {
            title: "Capacidad para absorber pérdidas",
            type: "string",
            enum: ["0%", "De 1% a 15%", "Mayor a 15%"]
            // oneOf: [
            //     {
            //         title: "0%",
            //         const: 1
            //     },
            //     {
            //         title: "De 1% a 15%",
            //         const: 2
            //     },
            //     {
            //         title: "Mayor a 15%",
            //         const: 3
            //     },
            // ],
        },
    },
}
const garantiaSolidariaSchema = {
    title: " ",
    type: "object",
    properties: {
        "garantiaSolidaria": {
            title: "¿Incluye garantía solidaria?",
            ...boolYesNoType
        },
        "descGarantias": {
            title: "Descripción de garantía",
            type: "object",
            properties: {
                "tipoDeGarantia": {
                    title: " ",
                    description: "Tipo de Garantía",
                    type: "array",
                    items: {
                        type: "number",
                        oneOf: [
                            { title: "Garantía Solidaria", const: 1 },
                            { title: "Descuento de Nómina", const: 2 },
                            { title: "Hipotecario", const: 3 },
                            { title: "Prendaria", const: 4 },
                            { title: "Certificado Financiero", const: 5 },
                            { title: "Vehículo", const: 6 },
                            { title: "Otra", const: 7 },
                        ],
                    },
                    //  minItems: 1,
                    uniqueItems: true,
                },
                "contratoElaboradoPor": contractSigner,
                "noDeGarantia": {
                    title: "No. de Garantía",
                    type: "string",
                },
                "noFacilidadAsociada":
                {
                    title: "No. Facilidad asociada",
                    type: "string"
                },
                "descGarantia":
                {
                    title: "Descripción de garantía",
                    type: "string",
                },
                "valGarantia":
                {
                    title: "Valor de Garantía en Tasación",
                    type: "string",
                },
            },

        },
    }

}
const datosVivienda = {
    title: " ",
    description: "Datos Vivienda",
    type: "object",
    properties: {
        "tipoVivienda": {
            title: "Tipo de Vivienda: ",
            type: "string",
            enum: ["Propia", "Alquilada", "Financiada", "Familiar"],
        },
        "nombrePropietario": {
            title: "Nombre propietario",
            type: "string",
        },
        "tiempoResidiendo": {
            title: "Tiempo residiendo",
            type: "string"
        }
    },
}

const datosCodeudorFiador = {
    title: " ",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombre:",
            type: "string"
        },
        "apellido": {
            title: "Apellido:",
            type: "string"
        },
        "cedula": {
            title: "Cédula:",
            type: "string"
        },
        "fechaNacimiento": {
            title: "Fecha de Nacimiento:",
            type: "string",
            format: "date"
        },
        "lugarNacimiento": {
            title: "Lugar de Nacimiento",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidad": {
            title: "Nacionalidad:",
            type: "string",
            enum: dropdownOptions.pais,
        },
        // "nacionalidadUSA":{
        //     title: "Nacionalidad USA",
        //     ...boolYesNoType
        // },
        "numeroPasaporte": {
            title: "No. Pasaporte:",
            type: "string"
        },
        "residenteUSA": {
            title: "Residente USA",
            ...boolYesNoType
        },
        "tiempoPais": {
            title: "Tiempo en el País:",
            type: "string"
        },
        "telefono": {
            title: "Tel. Casa:",
            type: "string"
        },
        "celular": {
            title: "Celular:",
            type: "string"
        },
        "otro": {
            title: "Otro:",
            type: "string"
        },
        "email": {
            title: "Email:",
            type: "string"
        },
        "direccion": {
            title: "Dirección:",
            type: "string"
        },
        "sector": {
            title: "Sector:",
            type: "string"
        },
        "ciudad": {
            title: "Ciudad:",
            type: "string"
        },
        "provincia": {
            title: "Provincia:",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional,
        },
        "pais": {
            title: "País:",
            type: "string"
        },
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "dependientes": {
            title: "No. Dependientes",
            type: "string"
        },
        "nivelAcademico": {
            title: "Nivel Académico",
            type: "string",
            enumNames: ["Doctorado", "Maestría", "Postgrado", "Universitario", "Carrera Técnica", "Bachillerato", "Primaria", "Ninguno"],
            enum: ["Doctorado", "Maestria", "Postgrado", "Universitario", "Carrera Tecnica", "Bachillerato", "Primaria", "Ninguno"],
        },
        "fuentesIngreso": {
            title: "Fuentes de Ingreso:",
            type: "string",
            enumNames: ["Empleado Privado", "Empleado Público", "Independiente", "Comerciante", "Otro"],
            enum: ["Empleado Privado", "Empleado Publico", "Independiente", "Comerciante", "Otro"],
        },
        "ingresosMensuales": {
            title: "Ingresos mensuales:",
            type: "string"
        },
        "tiempoLaborando": {
            title: "Tiempo laborando:",
            type: "string"
        },
        "tipoVinculacion": {
            title: "Tipo de vinculación con el solicitante:",
            type: "string"
        },
        "tipoVivienda": {
            title: "Tipo de Vivienda: ",
            type: "string",
            enum: ["Propia", "Alquilada", "Financiada", "Familiar"],
            // oneOf: [
            //     {
            //         title: "Propia",
            //         const: 1
            //     },
            //     {
            //         title: "Alquilada",
            //         const: 2
            //     },
            //     {
            //         title: "Financiada",
            //         const: 3
            //     },
            //     {
            //         title: "Familiar",
            //         const: 4
            //     },
            // ],
        },

        "tiempoResidiendo": {
            title: "Tiempo residiendo",
            type: "string"
        },
        "porcientoDeudas": {
            title: "% Deudas",
            type: "number",
        },
    },
}

const capacidadFinanciera = {
    title: "Capacidad Financiera",
    type: "object",
    properties: {
        "ingresosAnuales": {
            title: "Ingresos Anuales",
            type: "string",
            enumNames: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$5.7 Millones", "Entre RD$7.0 y RD$20 Millones", "Más de RD$25.0 Millones"],
            enum: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$5.7 Millones", "Entre RD$7.0 y RD$20 Millones", "Mas de RD$25.0 Millones"],
            // oneOf: [
            //     {
            //         title: "Menos de RD$2 Millones",
            //         const: 1
            //     },
            //     {
            //         title: "Entre RD$2.0 y RD$5.7 Millones",
            //         const: 2
            //     },
            //     {
            //         title: "Entre RD$7.0 y RD$20 Millones",
            //         const: 3
            //     },
            //     {
            //         title: "Más de RD$25.0 Millones",
            //         const: 4
            //     },
            // ],
        },
        "capacidadAhorro": {
            title: "% Capacidad de ahorro",
            type: "number",
        },
        "totalActivosLiquidos": {
            title: "Total Activos Líquidos",
            type: "string",
            enumNames: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Más de RD$20.0 Millones"],
            enum: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Mas de RD$20.0 Millones"],
            // oneOf: [
            //     {
            //         title: "Menos de RD$2 Millones",
            //         const: 1
            //     },
            //     {
            //         title: "Entre RD$2.0 y RD$7.0 Millones",
            //         const: 2
            //     },
            //     {
            //         title: "Entre RD$10.0 y RD$15.0 Millones",
            //         const: 3
            //     },
            //     {
            //         title: "Más de RD$20.0 Millones",
            //         const: 4
            //     },
            // ],
        },
        "porcientoCuentasAhorroOCorriente": {
            title: "% en Cuentas Ahorro o Corriente",
            type: "number",
        },
        "porcientoOtro": {
            title: "% en Otro",
            type: "number",
        },
        "patrimonioTotal": {
            title: "Patrimonio Total",
            type: "string",
            enumNames: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Más de RD$20.0 Millones"],
            enum: ["Menos de RD$2 Millones", "Entre RD$2.0 y RD$7.0 Millones", "Entre RD$10.0 y RD$15.0 Millones", "Mas de RD$20.0 Millones"],
            // oneOf: [
            //     {
            //         title: "Menos de RD$2 Millones",
            //         const: 1
            //     },
            //     {
            //         title: "Entre RD$2.0 y RD$7.0 Millones",
            //         const: 2
            //     },
            //     {
            //         title: "Entre RD$10.0 y RD$15.0 Millones",
            //         const: 3
            //     },
            //     {
            //         title: "Más de RD$20.0 Millones",
            //         const: 4
            //     },
            // ],
        },
        "porcientoDeudas": {
            title: "% Deudas",
            type: "number",
        },
        "referenciasBancarias": {
            type: "array",
            title: " ",
            description: "Referencias Bancarias",
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "banco": {
                        title: "Banco",
                        type: "string",
                    },
                    "tipoCuenta": {
                        title: "Tipo de Cuenta",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "oficial": {
                        title: "Oficial",
                        type: "string",
                    },
                },
            },
            required: [],
        },
        "referenciasComerciales": {
            type: "array",
            title: " ",
            description: "Referencias Comerciales",
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "direccion": {
                        title: "Dirección",
                        type: "string",
                    },
                },
            },
            required: [],
        },

    },
    required: ["ingresosAnuales", "totalActivosLiquidos", "patrimonioTotal"],
}
const resultadoPerfil = {
    title: "Resultado del Perfil",
    type: "object",
    properties: {
        "fechaActualizacionExpediente": {
            title: "Fecha de actualización expediente",
            type: "string",
            format: "date",
        },
        "indicarInformacionActualizadaYFuente": {
            title: "Indicar Información actualizada y fuente de la misma",
            type: "string",
        },
        "relacionCaraCara": {
            title: "¿Relación Cara a Cara?",
            ...boolYesNoType
        },
        "canalNoCaraCara": {
            title: "Canal No cara a cara",
            type: "string",
            enumNames: ["Correo Electrónico", "Teléfono", "Videoconferencia", "Otro"],
            enum: ["Correo Electronico", "Telefono", "Videoconferencia", "Otro"],
        },
        "otroCanalNoCaraCara": {
            title: "Indique",
            type: "string"
        },
        "resumenCliente": {
            title: "Resumen del cliente",
            type: "string"
        },
        "productoSolicitadoAjustaCliente": {
            title: "¿Se ajusta el producto solicitado/ofrecido a las características y objetivos del cliente?",
            ...boolYesNoType
        },
        "clienteCorredorValores": {
            title: "El cliente/ prospecto es corredor de valores, ha ocupado posiciones de dirección en las áreas de negocios o ha sido miembro del Consejo de una entidad financiera, de valores u otro sujeto obligado, por un periodo mayor o igual a 2 años?",
            ...boolYesNoType
        },
        "clienteActivosLiquidosMayor25m": {
            title: "El cliente/prospecto, ha indicado que sus activos líquidos, esto es, efectivo y equivalente de efectivo (inversiones en el sistema financiero y de valores a nivel nacional e internacional), es igual o mayor al equivalente en pesos dominicanos de RD$ 25,000,000.00?",
            ...boolYesNoType
        },
        "actualizacionExpedienteCliente": {
            title: "Si esta es una actualización del expediente del cliente; El cliente ha mantenido inversiones mayores a RD$3,000,000.00 en el último año y ha realizado al menos 12 transacciones (3 operaciones por trimestre)?",
            ...strYesNoNAType
        },
        "clienteProfesional": {
            title: "El cliente/prospecto es",
            type: "string",
            enum: ["Profesional", "No Profesional"],
        },
        "consideradoNoProfesional": {
            title: "Cliente desea ser considerado No Profesional",
            type: "boolean"
        },
        "tolerancia": {
            title: "Tolerancia",
            type: "string",
            enum: ["Conservador", "Moderado", " Agresivo"],
        },
        "clienteBancarizado": {
            title: "El cliente/prospecto es",
            type: "string",
            enum: ["Bancarizado", "No Bancarizado"],
        },
        "acuerdoConPerfilObtenido": {
            title: "¿Está usted de acuerdo con el Perfil obtenido en la evaluación?",
            ...boolYesNoType
        },
    },
    // required: ["relacionCaraCara", "productoSolicitadoAjustaCliente",
    //     "clienteProfesional", "tolerancia", "clienteBancarizado"
    // ], // aca
}

const analisisPatrimonio = {
    title: "Análisis Patrimonio",
    description: "Cambios de los activos y pasivos registrados en los últimos 2 años",
    type: "object",
    properties: {
        "PeriodosFiscales1": {
            title: " ",
            type: "object",
            properties: {
                "anosPeriodosFiscales1": {
                    title: "Años o Periodos Fiscales",
                    type: "number"
                },
                "totalPatrimonio1": {
                    title: "Total Patrimonio RD$",
                    type: "number"
                },
                "totalActivosLiquidos1": {
                    title: "Total Activos Líquidos RD$",
                    type: "number"
                },
                "resultadoAnalisisPatrimonio1": {
                    title: "Resultado",
                    type: "string",
                    enum: ["No cambios significativos", "Cambios significativos (comentar)"]
                },
            }
        },
        "PeriodosFiscales2": {
            title: " ",
            type: "object",
            properties: {
                "anosPeriodosFiscales2": {
                    title: "Años o Periodos Fiscales",
                    type: "number"
                },
                "totalPatrimonio2": {
                    title: "Total Patrimonio RD$",
                    type: "number"
                },
                "totalActivosLiquidos2": {
                    title: "Total Activos Líquidos RD$",
                    type: "number"
                },
                "resultadoAnalisisPatrimonio2": {
                    title: "Resultado",
                    type: "string",
                    enum: ["No cambios significativos", "Cambios significativos (comentar)"]

                },
            }
        },
    },
}

const codeudorFiador = {
    title: " ",
    description: "Datos del Co-Deudor o Fiador",
    type: "object",
    properties: {
        "codeudorFiadorSelection": {
            title: "Relación con cliente adicional",
            type: "string",
            oneOf: [
                {
                    title: "Co-Deudor",
                    const: "Co-Deudor"
                },
                {
                    title: "Fiador",
                    const: "Fiador"
                },
                {
                    title: "Garante",
                    const: "Garante"
                },
            ],
        },
        "datosCodeudorFiador": datosCodeudorFiador
    },

}

const refPersonales = {
    title: " ",
    description: "Referencias Personales",
    type: "object",
    properties: {
        "refPersonalesArray": {
            type: "array",
            title: " ",
            minItems: 0,
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                    "parentesco": {
                        title: "Parentesco",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "celular": {
                        title: "Celular",
                        type: "string",
                    },
                },
            }
        },
    }
}

const datosFamiliares = {
    title: " ",
    description: "Datos de dos (2) familiares (que no vivan con usted)",
    type: "object",
    properties: {
        "refFamiliares": {
            type: "array",
            title: " ",
            minItems: 0,
            maxItems: 2,
            items: {
                type: "object",
                properties: {
                    "nombre": {
                        title: "Nombre",
                        type: "string",
                    },
                    "parentesco": {
                        title: "Parentesco",
                        type: "string",
                    },
                    "direccion": {
                        title: "Dirección",
                        type: "string",
                    },
                    "telefono": {
                        title: "Teléfono",
                        type: "string",
                    },
                    "celular": {
                        title: "Celular",
                        type: "string",
                    },
                },
            }
        },
    }
}
const solicitudPrestamo = {
    title: "Solicitud de Préstamo",
    type: "object",
    properties: {
        "montoDeseadoPrestamo": {
            title: "Monto deseado de préstamo",
            type: "number",
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            minimum: TASA_CAMBIO_MIN
        },
        "destinoFondos": {
            title: "Destino de los fondos",
            type: "string",
        },
        "proposito": {
            title: "Propósito",
            type: "string"
        },
        "productosActuales": {
            title: "Productos Actuales",
            type: "array",
            items: {
                type: "number",
                oneOf: [
                    { title: "Nuevo", const: 1 },
                    { title: "Cuenta de Ahorros", const: 2 },
                    { title: "Certificado a Plazo", const: 3 },
                    { title: "Divisas", const: 4 },
                ],
            },
            uniqueItems: true,
        },
        "indicCapacidadPago": {
            title: "Indicadores de Capacidad de Pago:",
            type: "string"
        },
        "CEM": {
            title: "CEM:",
            type: "string"
        },
        "tiempoPais": {
            title: "Tiempo en el País:",
            type: "string",
        },
        "dependientes": {
            title: "No. Dependientes",
            type: "string"
        },
        "descFacilidades": {
            type: "array",
            title: " ",
            description: "Descripción de facilidades (sólo aplica para líneas de crédito)",
            minItems: 0,
            maxItems: 5,
            items: {
                type: "object",
                properties: {
                    "numFacilidad": {
                        title: "No. de Facilidad",
                        type: "string",
                    },
                    "descripcion": {
                        title: "Descripción",
                        type: "string"
                    },
                    "tipoFacilidad": {
                        title: "Tipo de Facilidad",
                        type: "string",
                    },
                    "montoAprobado": {
                        title: "Monto Aprobado (si aplica)",
                        type: "string",
                    },
                    "montoUtilizado": {
                        title: "Monto Utilizado (si aplica)",
                        type: "string",
                    },
                    "montoPropuesto": {
                        title: "Monto Propuesto",
                        type: "string",
                    },
                    "tasa": {
                        title: "Tasa",
                        type: "number",
                    },
                    "formaPago": {
                        title: "Forma de Pago",
                        type: "string",
                    },
                    "cuotaEstimada": {
                        title: "Cuota Estimada",
                        type: "string",
                    },
                    "seraCancelado": {
                        title: "¿Será Cancelado?",
                        ...boolYesNoType
                    }
                }
            }
        },
        "seguroVida": {
            title: "Seguro de Vida:",
            type: "string"
        },
        "tramitacion": {
            title: "Tramitación:",
            type: "string"
        },
        "incluyeGarantia": {
            title: " ",
            type: "object",
            properties: {
                "garantiaSelection": {
                    title: "¿Incluye garantía?",
                    ...boolYesNoType
                },
                "garantiaSolidariaSchema": garantiaSolidariaSchema,
            },
        },
        "codeudorFiador": codeudorFiador,
        "ejecutivo": {
            title: "Ejecutivo:",
            type: "string"
        },
        "referidoPor": {
            title: "Referido por:",
            type: "string"
        },
        "formaDesembolso": {
            title: "Forma de Desembolso",
            type: "string",
            enum: ["Cheque", "Transferencia"],
        },
        "formaDesembolsoOptions": {
            title: " ",
            type: "object",
            properties: {
                "banco": {
                    title: "Banco:",
                    type: "string",
                },
                "tipoCuenta": {
                    title: "Tipo de Cuenta:",
                    type: "string",
                },
                "numeroCuenta": {
                    title: "No. Cuenta:",
                    type: "string",
                },
            },
        },
        "comentarios": {
            title: "Comentarios",
            type: "string"
        },
        "datosVivienda": datosVivienda,
        "refPersonales": refPersonales,
        "datosFamiliares": datosFamiliares,
    }
}
const informacionIntencionVinculacion = {
    title: "Información de intención de vinculación",
    type: "object",
    properties: {
        "institucionVincularse": {
            title: "Institución a vincularse",
            type: "array",
            items: {
                type: "number",
                oneOf: [
                    { title: "Banco", const: 1 },
                    { title: "Puesto", const: 2 },
                    { title: "SAFI", const: 3 },
                ],
            },
            minItems: 1,
            uniqueItems: true,
        },
        "productosBanco": {
            title: "Productos de interés Banco",
            type: "array",
            lookup: {
                api: 'products/1',
                params: {
                    clientType: { field: 'tipoCliente' },
                },
                resource: "Productos",
                id: 'id',
                label: 'name'
            },
            items: { type: "number" },
            uniqueItems: true,
        },
        "productosPuesto": {
            title: "Productos de interés Puesto",
            type: "array",
            lookup: {
                api: 'products/2',
                params: {
                    clientType: { field: 'tipoCliente' },
                },
                resource: "Productos",
                id: 'id',
                label: 'name'
            },
            items: { type: "number" },
            //    minItems: 1,
            uniqueItems: true,
        },
        "productosSAFI": {
            title: "Productos de interés SAFI",
            type: "array",
            lookup: {
                api: 'products/3',
                params: {
                    clientType: { field: 'tipoCliente' },
                },
                resource: "Productos",
                id: 'id',
                label: 'name'
            },
            items: { type: "number" },
          //  minItems: 1,
            uniqueItems: true,
        },
        "inversionesSchema": inversionesSchema,
        "safiSchema": safiSchema,
        "savingsAccountSchema": savingsAccountSchema,
        "cuentaAhorroBasica": {
            title: "¿Es Cuenta de Ahorro Básica?",
            ...boolYesNoType
        },
        "soloMantieneEstaCuenta": {
            title: "¿Cliente solo mantiene esta cuenta?",
            ...boolYesNoType
        },
        "financialCertSchema": financialCertSchema,
        "transaccionesEsperadasSchema": transaccionesEsperadasSchema,
        "camposBancoSchema": camposBancoSchema,
        "prestamos": solicitudPrestamo,

        "perfilInversionista": perfilInversionista,
        "analisisPatrimonio": analisisPatrimonio,
        "resultadoPerfil": resultadoPerfil,
    }
}


const datosConyuge = {
    title: "Datos Cónyuge",
    type: "object",
    properties: {
        "nombres": {
            title: "Nombres",
            type: "string"
        },
        "apellidos": {
            title: "Apellidos",
            type: "string"
        },
        "email": {
            title: "Email",
            type: "string"
        },
        "edad": {
            title: "Edad",
            type: "number"
        },
        "tipoIdent": tipoIdentificacionPersonal,
        "numIdent": {
            title: "No. de identificación",
            type: "string"
        },
        "labora": {
            title: "Institución donde labora",
            type: "string"
        },
        "cargo": {
            title: "Cargo",
            type: "string"
        },
        "celular": {
            title: "Celular",
            type: "string"
        },
        "telefono": {
            title: "Teléfono",
            type: "string"
        },
        "ingMens": {
            title: "Ingresos mensuales",
            type: "number"
        },


    },

    // required: ["nombres", "apellidos", "email", "tipoIdent", "numIdent", "cargo", "celular"],
}



const matrizRiesgo = {
    title: "Resumen guia para el llenado de Matriz de Riesgo",
    type: "null",
    // type: "object",
    // properties: {
    //     "tipoCliente": {
    //         title: "Tipo de Cliente",
    //         type: "string",
    //         enum: ["Opcion 1", "Opcion 2", "Opcion 3"]
    //     },
    //     "categoria": {
    //         title: "Categoria",
    //         type: "string"
    //     },
    //     "actividadEconomica": {
    //         title: "Actividad económica",
    //         type: "string"
    //     },
    //     "pep": {
    //         title: "PEP",
    //         type: "string"
    //     },
    //     "duracionRelacion": {
    //         title: "Duración de la relación",
    //         type: "string"
    //     },
    //     "productoSolicitado": {
    //         title: "Producto Solicitado",
    //         type: "string"
    //     },
    //     "montoProyectado": {
    //         title: "Monto proyectado",
    //         type: "string"
    //     },
    //     "nivelIngresos": {
    //         title: "Nivel Ingresos",
    //         type: "string"
    //     },
    //     "zonaGeograficaNacional": {
    //         title: "Zona Geográfica Nacional",
    //         type: "string"
    //     },
    //     "paisOrigen": {
    //         title: "País de Origen",
    //         type: "string"
    //     },
    //     "paisResidencia": {
    //         title: "País de Residencia",
    //         type: "string"
    //     },
    //     "canalDistribucion": {
    //         title: "Canal de Distribución",
    //         type: "string"
    //     },
    // },
}
const soporteDebidaDiligencia = {
    title: "Soportes Debida Diligencia",
    type: "object",
    properties: {
        "revisionListaOFAC": {
            title: "Revisión en lista OFAC",
            type: "number",
        },
        "tieneOFAC": {
            title: "¿Tiene coincidencia en Lista OFAC?",
            ...boolYesNoType
        },
        "revisionListaONU": {
            title: "Revisión en lista ONU",
            type: "number",
        },
        "tieneONU": {
            title: "¿Tiene coincidencia en Lista ONU?",
            ...boolYesNoType
        },
        "matrizRiesgo": {
            title: "Matriz de Riesgo",
            type: "number",
        },
        "nivelRiesgo": {
            title: "Nivel de Riesgo",
            type: "number",
            enumNames: ["Alto", "Medio", "Bajo"],
            enum: [3, 2, 1]
        },
        "revisionBuroCredito": {
            title: "Revisión Buró de Crédito",
            type: "number",
        },
        "sentBack": {
            title: " ",
            type: "string"
        },
        "additionalFiles": {
            title: " ",
            description: "Archivos Adicionales",
            type: "array",
            items: {
                "properties": {
                    "documentName": {
                        title: "Nombre Archivo",
                        type: "string"
                    },
                    "document": {
                        title: "Archivo",
                        type: "number"
                    },
                }
            },
        }
    },
    required: ["revisionListaOFAC", "revisionBuroCredito",
        "revisionListaONU", "matrizRiesgo", "nivelRiesgo"
    ],
}



const clientSchema = {
    title: "Información Cliente",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombre",
            type: "string",
        },
        "segundoNombre": {
            title: "Segundo Nombre",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
        },
        "tipoIdentificacion": {
            title: "Tipo de Identificación",
            type: "number",
            lookup: {
                api: 'identificationType',
                resource: "Oficinas",
                params: {
                    clientType: { field: 'tipoCliente' },
                },
                id: 'id',
                label: 'value'
            }
        },
        "numIdentificacionCedula": {
            title: "No. de identificación Cédula",
            type: "string",
        },
        "numIdentificacionPasaporte": {
            title: "No. de identificación Pasaporte",
            type: "string",
        },
        "numIdentificacionIdExtranjero": {
            title: "No. de identificación Id Extranjero",
            type: "string",
        },
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
        },
        "otro": {
            title: "Otro Número de Teléfono",
            type: "string",
        },
        "comentarios": {
            title: "Comentarios",
            type: "string",
            enumNames: ["Instagram", "LinkedIn", "Página Web", "SMS", "Teléfono", "Twitter", "Visita Presencial", "Whatsapp"],
            enum: ["Instagram", "LinkedIn", "Pagina Web", "SMS", "Telefono", "Twitter", "Visita Presencial", "Whatsapp"],
        },
    },
    required: ["nombre", "apellidos", "email",
        "sexo", "estadoCivil",
        "celular"
    ]
}

const smbClientSchema = {
    title: "Información Cliente",
    type: "object",
    properties: {
        "nombre": {
            title: "Nombres",
            type: "string",
        },
        "apellidos": {
            title: "Apellidos",
            type: "string",
        },
        "email": {
            title: "Email",
            type: "string",
        },
        "tipoIdentificacion": {
            title: "Tipo de Identificación",
            type: "number",
            oneOf: [
                { title: "Cédula", const: 1 },
            ],
            default: 1
        },
        "numIdentificacionCedula": {
            title: "No. de identificación Cédula",
            type: "string",
        },
        "sexo": {
            title: "Sexo",
            type: "string",
            oneOf: [
                {
                    title: "Masculino",
                    const: "Masculino"
                },
                {
                    title: "Femenino",
                    const: "Femenino"
                },
            ],
        },
        "estadoCivil": {
            title: "Estado Civil",
            type: "string",
            oneOf: [
                {
                    title: "Soltero",
                    const: "Soltero"
                },
                {
                    title: "Casado",
                    const: "Casado"
                },
                {
                    title: "Unión Libre",
                    const: "Union Libre"
                },
            ],
        },
        "celular": {
            title: "Celular",
            type: "string",
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
        },
        "otro": {
            title: "Otro Número de Teléfono",
            type: "string",
        },
        // "numeroCliente": {
        //     title: "No. Cliente",
        //     type: "number",
        // },
        "comentarios": {
            title: "Comentarios",
            type: "string",
            enumNames: ["Instagram", "LinkedIn", "Página Web", "SMS", "Teléfono", "Twitter", "Visita Presencial", "Whatsapp"],
            enum: ["Instagram", "LinkedIn", "Pagina Web", "SMS", "Telefono", "Twitter", "Visita Presencial", "Whatsapp"],
        }
    },
    required: ["nombre", "apellidos", "email",
        "sexo", "estadoCivil",
        "celular"
    ]
}

const clientAditionalInfoSchema = {
    title: " ",
    type: "object",
    properties: {
        "fechaNacimiento": {
            title: "Fecha de Nacimiento",
            type: "string",
            format: "date",
        },
        "lugarNacimiento": {
            title: "Lugar de Nacimiento",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "nacionalidad": {
            title: "Nacionalidad",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "direccionResidencia": {
            title: "Dirección de residencia",
            type: "string",
        },
        "sector": {
            title: "Sector",
            type: "string",
        },
        "ciudad": {
            title: "Ciudad",
            type: "string",
        },
        "pais": {
            title: "País",
            type: "string",
            enum: dropdownOptions.pais,
        },
        "residenteRD": {
            title: "¿Es residente de la República Dominicana?",
            ...boolYesNoType,
            default: true
        },
        "zonaGeograficaNacional": {
            title: "Zona Geográfica Nacional",
            type: "string",
            enum: dropdownOptions.zonaGeograficaNacional,
        },
        "profesion": {
            title: "Profesión",
            type: "string",
        },
        "ocupacion": {
            title: "Ocupación",
            type: "string",
            enumNames: ["Empleado Privado", "Empleado Público", "Independiente", "Retirado", "Pensionado", "Otro"],
            enum: ["Empleado Privado", "Empleado Publico", "Independiente", "Retirado", "Pensionado", "Otro"],
        },
        "inscritoCedulaAnteDGII": {
            title: "¿Está inscrito con su cédula ante la DGII?",
            ...boolYesNoType
        },
        "ocupacionOtro": {
            title: "Explique:",
            type: "string",
        },
        "otraFuenteIngresos": {
            title: "¿Tiene otras fuentes de ingresos formales?",
            ...boolYesNoType
        },
        "sujetoObligadoLey155": {
            title: "¿Es Sujeto Obligado de la Ley 155-17?",
            ...boolYesNoType
        },
        "datosConyuge": datosConyuge,
    },
    required: [
        "fechaNacimiento", "lugarNacimiento", "nacionalidad", "direccionResidencia",
        "sector", "ciudad", "pais", "residenteRD", "zonaGeograficaNacional",
        "profesion", "ocupacion", "sujetoObligadoLey155",
    ]
}



form.schema = {
    title: "Solicitud de Vinculación Cliente Personal",
    type: "object",
    properties: {
        "tipoCliente": clientTypeId,
        "vinculacionMancomunada": {
            title: "¿Solicitud de vinculación mancomunada?",
            ...boolYesNoType,
            readOnly: true
        },
        "oficinaPreferencia": preferredBranchId,
        "tipoVinculacion": {
            title: "¿Tipo de Vinculación?",
            type: "string",
            oneOf: [
                {
                    title: "Cliente Nuevo JMMB",
                    const: "Cliente Nuevo JMMB"
                },
                {
                    title: "Vinculación a filial adicional",
                    const: "Vinculacion a filial adicional"
                },
                {
                    title: "Cliente existente solo Solicitud de Préstamo",
                    const: "Cliente existente solo Solicitud de Prestamo"
                },
            ],
        },
        "duracionRelacion": {
            title: "Duración de la relación",
            type: "string",
            enumNames: [
                "Cliente Nuevo ( Menos de 1 año)",
                "Más de 1 año menos de 3 años",
                "Más de 3 años menos de 5 años",
                "Más de 5 años"
            ],
            enum: [
                "Cliente Nuevo ( Menos de 1 ano)",
                "Mas de 1 ano menos de 3 anos",
                "Mas de 3 anos menos de 5 anos",
                "Mas de 5 anos"
            ],
        },
        "clientSchema": mergeObjects(clientSchema, clientAditionalInfoSchema),
        "datosLaboralesSchema": datosLaboralesSchema,
        "fatca": fatca,
        "declaracionJurada": declaracionJurada,
        "personaPoliticamenteExpuesta": personaPoliticamenteExpuesta,
        "informacionIntencionVinculacion": informacionIntencionVinculacion,
        "capacidadFinanciera": capacidadFinanciera,
        "matrizRiesgo": matrizRiesgo,
        "soporteDebidaDiligencia": soporteDebidaDiligencia,
    },
    required: ["tipoCliente", "oficinaPreferencia"],
};



form.uiSchema = {
    "ui:field": "HideObjectField",
    "ui:className": "hideObjectField_title",
    // "ui:order": [
    //     "*",
    //     "datosConyuge",

    //     "matrizRiesgo",
    //     "soporteDebidaDiligencia"
    // ],
    "tipoCliente": {
        "ui:readonly": true,
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "datosConyuge": {
        "classNames": "smallTwoRows",
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
    },
    "refPersonales": {
        "refPersonalesArray": {
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
                "celular": {
                    "ui:widget": "PhoneWidget",
                },
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "celular": {
                "ui:widget": "PhoneWidget",
            },
        }
    },
    "datosFamiliares": {
        "refFamiliares": {
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
                "celular": {
                    "ui:widget": "PhoneWidget",
                },
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "celular": {
                "ui:widget": "PhoneWidget",
            },
        }

    },
    "matrizRiesgo": { "ui:field": "RiskMatrixField" },

    "oficinaPreferencia": { "ui:field": "LookupFormField" },
    "tipoVinculacion": {
        // "ui:readonly": true,
    },
    "duracionRelacion": {
        // "ui:readonly": true,
    },
    "vinculacionMancomunada": {
        "akc:requiredIfVisible": true,
        "ui:showIf": {
            "expr": "tipoCliente = 1",
        },
        "ui:widget": "radio",
        "ui:options": {
            inline: true
        },
    },
    "clientSchema": {
        "ui:field": "HideObjectField",
        "ui:className": "hideObjectField_title",
        "classNames": "smallTwoRows",
        "ui:showIf": {
            "expr": "tipoCliente = 1 or tipoCliente = 4",
        },
        // "numIdentificacionPasaporte": {
        //     title: "No. de identificación Pasaporte",
        //     type: "string",
        // },
        // "numIdentificacionIdExtranjero": {
        //     title: "No. de identificación Id Extranjero",
        //     type: "string",
        // },
        // "tipoIdentificacion": {
        //     "ui:widget": "radio",
        //     "ui:options": {
        //         inline: true
        //     },
        // },
        "tipoIdentificacion": {
            title: "Tipo de Identificación",
            "ui:field": "LookupFormField",
            // oneOf: [
            //     { title: "Cédula", const: 1 },
            //     { title: "Pasaporte", const: 2 },
            //     { title: "Id Extranjero", const: 3 },
            // ],
        },
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
            "ui:showIf": {
                "expr": "clientSchema.tipoIdentificacion = 1"
            },
        },
        "numIdentificacionPasaporte": {
            "ui:showIf": {
                "expr": "clientSchema.tipoIdentificacion = 2"
            },
        },
        "numIdentificacionIdExtranjero": {
            "ui:showIf": {
                "expr": "clientSchema.tipoIdentificacion = 2"
            },
        },
        "datosConyuge": {
            "ui:showIf": {
                "expr": "clientSchema.estadoCivil != 'Soltero'",
            },
            "classNames": "from-col-1 to-col-3 smallTwoRows",
            "celular": {
                "ui:widget": "PhoneWidget",
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "tipoIdent": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
        },
        "sexo": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "productosActuales": {
            "ui:widget": "checkboxes",
            "ui:options": {
                inline: false
            },
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },

        "fechaNacimiento": { yearsRange: [1900, 2030], },
        "nacionalidad": {
            "classNames": "from-col-1",
        },
        "residenteRD": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "otraFuenteIngresos": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "inscritoCedulaAnteDGII": {
            "classNames": "from-col-1",
            "ui:showIf": {
                "expr": "clientSchema.ocupacion = 'Independiente' or clientSchema.ocupacion = 'Otro'",
            },
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "ocupacionOtro": {
            "ui:showIf": {
                "expr": "clientSchema.ocupacion = 'Otro'"
            },
        },
        "sujetoObligadoLey155": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tipoSujetoObligado": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },

    },
    "secondClientSchema": {
        "classNames": "smallTwoRows",
        "tipoIdentificacion": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sexo": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estadoCivil": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        },
        "otro": {
            "ui:widget": "PhoneWidget",
        },
    },
    // "clientAditionalInfoSchema": {
    //     "classNames": "smallTwoRows",
    //     "ui:order": [
    //         "*",
    //         "ocupacion",
    //         "ocupacionOtro",
    //         "inscritoCedulaAnteDGII",
    //         "otraFuenteIngresos",
    //         "sujetoObligadoLey155",
    //         "tipoSujetoObligado",
    //     ],
    //     "fechaNacimiento": {yearsRange: [1900, 2030],},
    //     "residenteRD": {
    //         "ui:widget": "radio",
    //         "ui:options": {
    //             inline: true
    //         },
    //     },
    //     "otraFuenteIngresos": {
    //         "ui:widget": "radio",
    //         "ui:options": {
    //             inline: true
    //         },
    //     },
    //     "inscritoCedulaAnteDGII": {
    //         "ui:widget": "radio",
    //         "ui:options": {
    //             inline: true
    //         },
    //     },
    //     "sujetoObligadoLey155": {
    //         "ui:widget": "radio",
    //         "ui:options": {
    //             inline: true
    //         },
    //     },
    //     "tipoSujetoObligado": {
    //         "ui:widget": "radio",
    //         "ui:options": {
    //             inline: true
    //         },
    //     },
    // },
    "datosLaboralesSchema": {
        "classNames": "smallTwoRows",
        "fechaIngreso": { yearsRange: [1900, 2030], },
        "ui:showIf": {
            "expr": "tipoCliente = 1 or tipoCliente = 4",
        },
        "telefonoEmpresa": {
            "ui:widget": "PhoneWidget",
        },
        "faxEmpresa": {
            "ui:widget": "PhoneWidget",
        },
        "telefonoEmpleoAnterior": {
            "ui:widget": "PhoneWidget",
        },
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 3
            },
        },
    },
    "fatca": {
        "ui:showIf": {
            "expr": "tipoCliente = 1 or tipoCliente = 4",
        },
        "ui:field": "HideObjectField",
        "ui:className": "hideObjectField_title",
        "classNames": "smallTwoRows",
        "ui:order": [
            "nacionalidadOtroPais",
            "indicarPaises",
            "residenciaFisicaUSA",
            "estatusMigratorio",
            "numIDFiscal",
            "multiplesPaisesResidenciaFiscal",
            "paisesResidenciaFiscal",
            "*",
        ],
        "nacionalidadOtroPais": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "residenciaFisicaUSA": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "estatusMigratorio": {
            "classNames": "radio-column",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "numIDFiscal": {
            "ui:showIf": {
                "expr": "fatca.estatusMigratorio != 'No'"
            },
        },
        "indicarPaises": {
            "ui:showIf": {
                "expr": "fatca.nacionalidadOtroPais"
            },
        },
        "multiplesPaisesResidenciaFiscal": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "paisesResidenciaFiscal": {
            "ui:showIf": {
                "expr": "fatca.multiplesPaisesResidenciaFiscal"
            },
            "ui:widget": "textarea",
            "ui:options": {
                rows: 3
            },
        },
        "greenCard": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "direccionEnvioEstadosUnidos": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tieneDireccionResidenciaEstadosUnidos": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "telefonoFueraPais": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "telefonoEstadoUnidos": {
            "ui:widget": "PhoneWidget",
        },
    },
    "personaPoliticamenteExpuesta": {
        "ui:showIf": {
            "expr": "tipoCliente = 1 or tipoCliente = 4",
        },
        "classNames": "smallTwoRows",
        "ui:field": "HideObjectField",
        "ui:className": "hideObjectField_title",
        "personaExpuestaPoliticamente": {
            "classNames": "from-col-1 to-col-3",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "cargo": {
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.personaExpuestaPoliticamente"
            },
        },
        "pais": {
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.personaExpuestaPoliticamente"
            },
        },
        "fechaDesignacion": {
            yearsRange: [1900, 2030],
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.personaExpuestaPoliticamente"
            },
        },
        "fechaRemocion": {
            yearsRange: [1900, 2030],
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.personaExpuestaPoliticamente"
            },
        },
        "parentescoConPersonaExpuestaPoliticamente": {
            "classNames": "from-col-1 to-col-3",
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "nombrePEP": {
            "akc:requiredIfVisible": true,
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente"
            },
        },
        "parentescoPEP": {
            "akc:requiredIfVisible": true,
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente"
            },
        },
        "cargoPEP": {
            "akc:requiredIfVisible": true,
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente"
            },
        },
        "paisPEP": {
            "akc:requiredIfVisible": true,
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente"
            },
        },
        "fechaDesignacionPEP": {
            yearsRange: [1900, 2030],
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente"
            },
        },
        "fechaRemocionPEP": {
            "akc:requiredIfVisible": true,
            yearsRange: [1900, 2030],
            "ui:showIf": {
                "expr": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente"
            },
        },
    },
    "informacionIntencionVinculacion": {
        "ui:showIf": {
            "expr": "tipoCliente = 1 or tipoCliente = 4",
        },
        "ui:field": "HideObjectField",
        "ui:className": "hideObjectField_title",
        "classNames": "smallThreeRows",
        "institucionVincularse": {
            "ui:widget": "checkboxes",
            "ui:options": {
                inline: true
            },
        },
        "productosBanco": {
            "classNames": "from-col-1",
            "akc:requiredIfVisible": true,
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 1 })) > 0"
            },
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": {
                inline: false
            },
        },
        "transaccionesEsperadasSchema": {
            "ui:className": "hideObjectField_title",
            "ui:field": "HideObjectField",
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "cantidadOperacionesMensuales": {
                "classNames": "from-col-1 to-col-3",
                "ui:widget": "radio",
            },
            "cantidadOperacionesMensualesOtro": {
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.transaccionesEsperadasSchema.cantidadOperacionesMensuales = 'Otro'",
                },
                "classNames": "from-col-3",
            },
            "formaMasFrecuenteTransacciones": {
                "classNames": "from-col-1 to-col-4",
                "ui:widget": "radio",
            },
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 1 })) > 0 and $count($filter(informacionIntencionVinculacion.productosBanco, function($v) { $v = 17 })) > 0"
            },
        },
        "camposBancoSchema": {
            "ui:field": "HideObjectField",
            "classNames": "from-col-1 to-col-4",
            "beneficiariosDeTransaccionDiferentes": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "vinculadoJMMB": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 1 })) > 0 and $count($filter(informacionIntencionVinculacion.productosBanco, function($v) { $v = 17 })) > 0"
            },
            "nombreBeneficiario": {
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.camposBancoSchema.beneficiariosDeTransaccionDiferentes"
                },
            },
            "empresaVinculada": {
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.camposBancoSchema.vinculadoJMMB"
                },
            }

        },
        "cuentaAhorroBasica": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 1 })) > 0 and $count($filter(informacionIntencionVinculacion.productosBanco, function($v) { $v = 1 }))"
            },
            "ui:options": {
                inline: false
            },
        },
        "soloMantieneEstaCuenta": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:showIf": {
                "expr": "informacionIntencionVinculacion.cuentaAhorroBasica"
            },
            "ui:options": {
                inline: false
            },
        },
        "savingsAccountSchema": {
            "ui:field": "HideObjectField",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 1 })) > 0 and $count($filter(informacionIntencionVinculacion.productosBanco, function($v) { $v = 1 }))"
            },
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "depositoEstimadoRD": {
                "classNames": "from-col-1 title-2-lines",
                "akc:requiredIfVisible": true,
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.savingsAccountSchema.rdCurrency"
                },
            },
            "origenFondosRD": {
                "classNames": "title-2-lines",
                "akc:requiredIfVisible": true,
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.savingsAccountSchema.rdCurrency",
                },
            },
            "depositoEstimadoUSD": {
                "akc:requiredIfVisible": true,
                "classNames": "from-col-1 title-2-lines",
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.savingsAccountSchema.usCurrency",
                },
            },
            "origenFondosUSD": {
                "akc:requiredIfVisible": true,
                "classNames": "title-2-lines",
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.savingsAccountSchema.usCurrency",
                },
            },
            "origenFondosRDOtro": {
                "classNames": "title-2-lines",
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.savingsAccountSchema.rdCurrency and informacionIntencionVinculacion.savingsAccountSchema.origenFondosRD = 'Otro'",
                },
            },
            "origenFondosUSDOtro": {
                "akc:requiredIfVisible": true,
                "classNames": "title-2-lines",
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.savingsAccountSchema.usCurrency and informacionIntencionVinculacion.savingsAccountSchema.origenFondosUSD = 'Otro'",
                    "debug": "testing"
                },
            }
        },
        "financialCertSchema": {
            "ui:field": "HideObjectField",
            "ui:className": "hideObjectField_title",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.productosBanco, function($v) { $v = 3 })) > 0",
            },
            "origenFondosRDOtro": {
                "akc:requiredIfVisible": true,
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.financialCertSchema.origenFondosRD = 'Otro'"
                },
            },
            "inversionEstimadaRD": {
                "akc:requiredIfVisible": true,
            },
            "origenFondosRD": {
                "akc:requiredIfVisible": true,
            },
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "tasaCambio": {
                "akc:requiredIfVisible": true,
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "prestamos": {
            // "ui:field": "HideObjectField",
            "classNames": "from-col-1 to-col-4 smallTwoRows default-all-row",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 1 })) > 0 and $count($filter(informacionIntencionVinculacion.productosBanco, function($v) { $v = 4 or $v = 5 or $v = 6 or $v = 7 or $v = 17 })) > 0"
            },
            "montoDeseadoPrestamo": {
                "classNames": "from-col-1 to-col-2"
            },
            "tasaCambio": {
                "classNames": "from-col-2 to-col-3",
                "akc:requiredIfVisible": true,
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            },
            "proposito": {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 4
                },
            },
            "incluyeGarantia": {
                "ui:field": "HideObjectField",
                "garantiaSelection": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "garantiaSolidariaSchema": {
                    "ui:showIf": {
                        "expr": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSelection",
                    },
                    "garantiaSolidaria": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "descGarantias": {
                        "ui:field": "HideObjectField",
                        "descGarantia": {
                            "ui:widget": "textarea",
                            "ui:options": {
                                rows: 3
                            },
                        },
                        "tipoDeGarantia": {
                            "akc:requiredIfVisible": true,
                            "ui:widget": "checkboxes",
                        },
                        "contratoElaboradoPor": {
                            "akc:requiredIfVisible": true,
                            "ui:widget": "radio",
                            "ui:showIf": {
                                "expr": "$count($filter(informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias.tipoDeGarantia, function($v) { $v = 7 })) > 0",
                            },
                        },
                    }
                },
            },
            "codeudorFiador": {
                "ui:field": "HideObjectField",
                "codeudorFiadorSelection": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "datosCodeudorFiador": {
                    "ui:showIf": {
                        "expr": "informacionIntencionVinculacion.prestamos.codeudorFiador.codeudorFiadorSelection = 'Co-Deudor' or informacionIntencionVinculacion.prestamos.codeudorFiador.codeudorFiadorSelection = 'Fiador'",
                    },
                    "classNames": "smallTwoRows",
                    "cedula": {
                        "ui:widget": "CedulaWidget",
                    },
                    "sexo": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "estadoCivil": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "tipoVivienda": {
                        "ui:widget": "radio",
                        "ui:options": {
                            inline: true
                        },
                    },
                    "fechaNacimiento": { yearsRange: [1900, 2030], },
                    "celular": {
                        "ui:widget": "PhoneWidget",
                    },
                    "telefono": {
                        "ui:widget": "PhoneWidget",
                    },
                    "otro": {
                        "ui:widget": "PhoneWidget",
                    },
                },
            },
            "formaDesembolso": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "formaDesembolsoOptions": {
                "ui:field": "HideObjectField",
                "banco": {
                    "ui:showIf": {
                        "expr": "informacionIntencionVinculacion.prestamos.formaDesembolso = 'Transferencia'"
                    },
                },
                "tipoCuenta": {
                    "ui:showIf": {
                        "expr": "informacionIntencionVinculacion.prestamos.formaDesembolso = 'Transferencia'"
                    },
                },
                "numeroCuenta": {
                    "ui:showIf": {
                        "expr": "informacionIntencionVinculacion.prestamos.formaDesembolso = 'Transferencia'"
                    },
                }
            },
            "comentarios": {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 4
                },
            },
            "descFacilidades": {
                "ui:options": {
                    "orderable": false,
                }
            },
            "productosActuales": {
                "ui:widget": "checkboxes",
                "ui:options": {
                    inline: false
                },
            },
            "datosVivienda": {
                "tipoVivienda": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "nombrePropietario": {
                    "ui:showIf": {
                        "expr": "informacionIntencionVinculacion.prestamos.datosVivienda.tipoVivienda = 'Alquilada'"
                    },
                },
            },
            "refPersonales": {
                "refPersonalesArray": {
                    "ui:options": {
                        "orderable": false,
                    },
                    "items": {
                        "telefono": {
                            "ui:widget": "PhoneWidget",
                        },
                        "celular": {
                            "ui:widget": "PhoneWidget",
                        },
                    },
                }
            },
            "datosFamiliares": {
                "refFamiliares": {
                    "ui:options": {
                        "orderable": false,
                    },
                    "items": {
                        "telefono": {
                            "ui:widget": "PhoneWidget",
                        },
                        "celular": {
                            "ui:widget": "PhoneWidget",
                        },
                    },
                },
            },
            // "ui:order": [
            //     "*", "garantiaSelection", "garantiaSolidariaSchema", "codeudorFiador", "datosCodeudorFiador", "descGarantias", "datosVivienda", "refPersonales",
            //     "datosFamiliares", "formaDesembolso", "banco", "tipoCuenta", "numeroCuenta", "comentarios",
            // ],
        },

        "productosPuesto": {
            "classNames": "from-col-2",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 2 })) > 0"
            },
            "akc:requiredIfVisible": true,
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },

        "inversionesSchema": {
            "ui:field": "HideObjectField",
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 2 })) > 0"
            },
            "origenFondosUSDOtro": {
                "akc:requiredIfVisible": true,
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.inversionesSchema.origenFondosUSD = 'Otro'"
                },
            },
            "origenFondosRDOtro": {
                "akc:requiredIfVisible": true,
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.inversionesSchema.origenFondosRD = 'Otro'"
                },
            },
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },

        "productosSAFI": {
            "akc:requiredIfVisible": true,
            "classNames": "from-col-3",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 3 })) > 0"
            },
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },
        "safiSchema": {
            "ui:field": "HideObjectField",
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 3 })) > 0"
            },
            "inversionEstimadaRD": {
                "akc:requiredIfVisible": true,
                "classNames": "from-col-1",
            },
            "inversionEstimadaUSD": {
                "akc:requiredIfVisible": true,
            },
            "origenFondosUSD": {
                "akc:requiredIfVisible": true,
            },
            "origenFondosRD": {
                "akc:requiredIfVisible": true,
            },
            "tasaCambio": {
                "akc:requiredIfVisible": true,
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            },
            "origenFondosUSDOtro": {
                "akc:requiredIfVisible": true,
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.safiSchema.origenFondosUSD = 'Otro'"
                },
            },
            "origenFondosRDOtro": {
                "akc:requiredIfVisible": true,
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.safiSchema.origenFondosRD = 'Otro'"
                },
            },
        },
        "perfilInversionista": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 2 or $v = 3 })) > 0"
            },
            "ui:order": [
                "riskProdServTitle",
                "riesgoProductosServiciosEdad",
                "riesgoProductosServiciosObjetivo",
                "criteriosIdeasRentabilidad",
                "nivelAcademico",
                "*",
            ],
            "riesgoProductosServiciosEdad": {
                "classNames": "from-col-1 radio-column",
                "ui:widget": "radio",
            },
            "riesgoProductosServiciosObjetivo": {
                "classNames": "from-col-2 pad-right radio-column",
                "ui:widget": "radio",
            },
            "criteriosIdeasRentabilidad": {
                "classNames": "radio-column",
                "ui:widget": "radio",
            },
            "nivelAcademico": {
                "classNames": "from-col-1 to-col-4",
                "ui:widget": "radio",
            },
            "plazoDeseaCumplirObjetivos": {
                "ui:widget": "radio",
            },
            "plazoAnticipaNecesitarInversiones": {
                "ui:widget": "radio",
            },
            "capacidadAbsorverPerdidas": {
                "ui:widget": "radio",
            },
            "operacionesFinancierasNegociado": {
                "classNames": "from-col-1 to-col-4",
                "titles": {
                    "classNames": "smallFiveRows",
                    "col1": { "classNames": "from-col-3" },
                },
                "inversionCertificadosFinancieros": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "bonosSoberanos": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "bonosCorporativos": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "fondosInversionesMutuos": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "fondosCerrados": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1 to-col-3" },
                },
                "otrosDerivados": {
                    "classNames": "smallFiveRows",
                    "title": { "classNames": "from-col-1" },
                },
            },
        },
        "analisisPatrimonio": {
            "classNames": "from-col-1 to-col-4",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 2 or $v = 3 })) > 0"
            },
            PeriodosFiscales1: {
                "classNames": "smallFourRows",
            },
            PeriodosFiscales2: {
                "classNames": "smallFourRows",
            },
        },
        "resultadoPerfil": {
            "ui:field": "HideObjectField",
            "classNames": "from-col-1 to-col-4",
            "ui:showIf": {
                "expr": "$count($filter(informacionIntencionVinculacion.institucionVincularse, function($v) { $v = 2 or $v = 3 })) > 0"
            },
            "fechaActualizacionExpediente": { yearsRange: [1900, 2030], },
            "relacionCaraCara": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "tolerancia": {
                "akc:requiredIfVisible": true,
            },
            "canalNoCaraCara": {
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.resultadoPerfil.relacionCaraCara = false",
                },
            },
            "otroCanalNoCaraCara": {
                "ui:showIf": {
                    "expr": "informacionIntencionVinculacion.resultadoPerfil.relacionCaraCara = false and informacionIntencionVinculacion.resultadoPerfil.canalNoCaraCara = 'Otro'",
                },
            },
            "resumenCliente": {
                "ui:widget": "textarea",
                "ui:options": {
                    rows: 3
                },
            },
            "productoSolicitadoAjustaCliente": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteCorredorValores": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteActivosLiquidosMayor25m": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "actualizacionExpedienteCliente": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "acuerdoConPerfilObtenido": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteProfesional": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "clienteBancarizado": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
        },

    },
    "capacidadFinanciera": {
        "ui:showIf": {
            "expr": "tipoCliente = 1 or tipoCliente = 4",
        },
        "classNames": "from-col-1 to-col-4 smallThreeRows",
        "ui:order": [
            "ingresosAnuales",
            "totalActivosLiquidos",
            "patrimonioTotal",

            "capacidadAhorro",
            "porcientoCuentasAhorroOCorriente",
            "porcientoDeudas",
            "porcientoOtro",

            "referenciasBancarias",
            "referenciasComerciales",
        ],
        "ingresosAnuales": {
            "ui:widget": "radio",
        },
        "totalActivosLiquidos": {
            "ui:widget": "radio",
        },
        "patrimonioTotal": {
            "ui:widget": "radio",
        },
        "porcientoOtro": {
            "classNames": "from-col-2",
        },
        "referenciasBancarias": {
            "classNames": "from-col-1 to-col-4",
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
            },
        },
        "referenciasComerciales": {
            "classNames": "from-col-1 to-col-4",
            "ui:options": {
                "orderable": false,
            },
            "items": {
                "telefono": {
                    "ui:widget": "PhoneWidget",
                },
            },
        },
    },
    "datosVivienda": {
        "classNames": "smallTwoRows",
        "tipoVivienda": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "ui:order": [
            "*", "tipoVivienda", "nombrePropietario", "tiempoResidiendo",
        ],

    },
    "soporteDebidaDiligencia": {
        "ui:showIf": {
            "expr": "tipoCliente = 1 or tipoCliente = 4",
        },
        "ui:field": "HideObjectField",
        "classNames": "soporteDebidaDiligencia",
        "revisionListaOFAC": { "ui:field": "FileField" },
        "revisionBuroCredito": { "ui:field": "FileField" },
        "revisionListaONU": { "ui:field": "FileField" },
        "matrizRiesgo": { "ui:field": "FileField" },
        "tieneOFAC": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "tieneONU": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "sentBack": {
            "joli:hideAttr": "hide",
        },
        "additionalFiles": {
            "classNames": "additionalFiles",
            "items": { "document": { "ui:field": "FileField" } }
        },
    },
    "codeudorFiador": {
        "codeudorFiadorSelection": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "datosCodeudorFiador": {
            "classNames": "smallTwoRows",
            "sexo": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "estadoCivil": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "tipoVivienda": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
            "fechaNacimiento": { yearsRange: [1900, 2030], },
            "celular": {
                "ui:widget": "PhoneWidget",
            },
            "telefono": {
                "ui:widget": "PhoneWidget",
            },
            "otro": {
                "ui:widget": "PhoneWidget",
            },
        }
    },
}


form.objectMap = {
    // Fields not implemented:
    //  Fields with wrong type in db:
    // 
    //  
    "isJointly": "vinculacionMancomunada",
    "productInstitutions": "informacionIntencionVinculacion.institucionVincularse",
    "productIds.1": "informacionIntencionVinculacion.productosBanco",
    "productIds.2": "informacionIntencionVinculacion.productosPuesto",
    "productIds.3": "informacionIntencionVinculacion.productosSAFI",
    "clients[0].relationshipType": "tipoVinculacion",
    "clients[0].relationshipDuration": "duracionRelacion",
    "clients[0].clientTypeId": "tipoCliente",
    "clients[0].preferredBranchId": "oficinaPreferencia",
    "clients[0].retailClientBasicInfo.firstName": "clientSchema.nombre",
    "clients[0].retailClientBasicInfo.secondName": "clientSchema.segundoNombre",
    "clients[0].retailClientBasicInfo.lastName": "clientSchema.apellidos",
    "clients[0].retailClientBasicInfo.email": "clientSchema.email",
    "clients[0].retailClientBasicInfo.identificationType": "clientSchema.tipoIdentificacion",
    "clients[0].retailClientBasicInfo.identificationNumber": "clientSchema.numIdentificacionCedula",
    "clients[0].retailClientBasicInfo.passportNumber": "clientSchema.numIdentificacionPasaporte",
    "clients[0].retailClientBasicInfo.foreignIdNumber": "clientSchema.numIdentificacionIdExtranjero",
    "clients[0].retailClientBasicInfo.subscribedAgainstDGII": "clientSchema.inscritoCedulaAnteDGII",
    // "clients[0].retailClientBasicInfo.subscribedAgainstDGII": "clientAditionalInfoSchema.inscritoCedulaAnteDGII",
    "clients[0].retailClientBasicInfo.sex": "clientSchema.sexo",
    "clients[0].retailClientBasicInfo.civilStatus": "clientSchema.estadoCivil",
    "clients[0].retailClientBasicInfo.phone": "clientSchema.telefono",
    "clients[0].retailClientBasicInfo.phoneMobile": "clientSchema.celular",
    "clients[0].retailClientBasicInfo.phoneOther": "clientSchema.otro",

    "clients[0].retailClientBasicInfo.dateOfBirth": "clientSchema.fechaNacimiento",
    // "clients[0].retailClientBasicInfo.dateOfBirth": "clientAditionalInfoSchema.fechaNacimiento",
    "clients[0].retailClientBasicInfo.countryOfBirth": "clientSchema.lugarNacimiento",
    // "clients[0].retailClientBasicInfo.countryOfBirth": "clientAditionalInfoSchema.lugarNacimiento",
    "clients[0].retailClientBasicInfo.nationality": "clientSchema.nacionalidad",
    // "clients[0].retailClientBasicInfo.nationality": "clientAditionalInfoSchema.nacionalidad",
    "clients[0].retailClientBasicInfo.address": "clientSchema.direccionResidencia",
    // "clients[0].retailClientBasicInfo.address": "clientAditionalInfoSchema.direccionResidencia",
    "clients[0].retailClientBasicInfo.addressSector": "clientSchema.sector",
    // "clients[0].retailClientBasicInfo.addressSector": "clientAditionalInfoSchema.sector",
    "clients[0].retailClientBasicInfo.city": "clientSchema.ciudad",
    // "clients[0].retailClientBasicInfo.city": "clientAditionalInfoSchema.ciudad",
    "clients[0].retailClientBasicInfo.country": "clientSchema.pais",
    // "clients[0].retailClientBasicInfo.country": "clientAditionalInfoSchema.pais",
    "clients[0].retailClientBasicInfo.residentOfDominicanRepublic": "clientSchema.residenteRD",
    // "clients[0].retailClientBasicInfo.residentOfDominicanRepublic": "clientAditionalInfoSchema.residenteRD",
    "clients[0].retailClientBasicInfo.profession": "clientSchema.profesion",
    // "clients[0].retailClientBasicInfo.profession": "clientAditionalInfoSchema.profesion",
    "clients[0].retailClientBasicInfo.occupation": "clientSchema.ocupacion",
    // "clients[0].retailClientBasicInfo.occupation": "clientAditionalInfoSchema.ocupacion",
    "clients[0].retailClientBasicInfo.isSubjectToLaw15517": "clientSchema.sujetoObligadoLey155",
    // "clients[0].retailClientBasicInfo.isSubjectToLaw15517": "clientAditionalInfoSchema.sujetoObligadoLey155",
    "clients[0].retailClientBasicInfo.obligationType": "clientSchema.tipoSujetoObligado",
    // "clients[0].retailClientBasicInfo.obligationType": "clientAditionalInfoSchema.tipoSujetoObligado",
    "clients[0].retailClientBasicInfo.nationalGeographicZone": "clientSchema.zonaGeograficaNacional",
    // "clients[0].retailClientBasicInfo.nationalGeographicZone": "clientAditionalInfoSchema.zonaGeograficaNacional",
    "clients[0].retailClientBasicInfo.hasOtherSourcesOfIncome": "clientSchema.otraFuenteIngresos",
    // "clients[0].retailClientBasicInfo.hasOtherSourcesOfIncome": "clientAditionalInfoSchema.otraFuenteIngresos",
    "clients[0].retailClientBasicInfo.otherOccupation": "clientSchema.ocupacionOtro",
    // "clients[0].retailClientBasicInfo.otherOccupation": "clientAditionalInfoSchema.ocupacionOtro",
    "clients[0].retailClientBasicInfo.comments": "clientSchema.comentarios",
    "clients[0].retailClientBasicInfo.hasBeneficiaries": "informacionIntencionVinculacion.camposBancoSchema.beneficiariosDeTransaccionDiferentes",
    "clients[0].retailClientBasicInfo.isRelatedToFranchise": "informacionIntencionVinculacion.camposBancoSchema.vinculadoJMMB",
    "clients[0].retailClientInvestorProfile.uscClientNumber": "informacionIntencionVinculacion.camposBancoSchema.numClienteBancoUCS",
    "clients[0].retailClientBasicInfo.hasBasicSaving": "informacionIntencionVinculacion.cuentaAhorroBasica",
    "clients[0].retailClientBasicInfo.fundDestination": "informacionIntencionVinculacion.camposBancoSchema.destinoFondos",
    "clients[0].retailClientBasicInfo.onlyMaintainsThisAccount": "informacionIntencionVinculacion.soloMantieneEstaCuenta",
    "clients[0].retailClientBasicInfo.beneficiaryName": "informacionIntencionVinculacion.camposBancoSchema.nombreBeneficiario",
    "clients[0].retailClientBasicInfo.linkedBusiness": "informacionIntencionVinculacion.camposBancoSchema.empresaVinculada",
    "clients[0].retailClientBasicInfo.monthlyTransactions": "informacionIntencionVinculacion.transaccionesEsperadasSchema.cantidadOperacionesMensuales",
    "clients[0].retailClientBasicInfo.frequentTransactionTypes": "informacionIntencionVinculacion.transaccionesEsperadasSchema.formaMasFrecuenteTransacciones",
    "clients[0].retailClientBasicInfo.otherMonthlyTransactions": "informacionIntencionVinculacion.transaccionesEsperadasSchema.cantidadOperacionesMensualesOtro",

    // "clients[0].retailClientBasicInfo.clientNumber": "clientSchema.numeroCliente",
    "clients[0].retailClientBasicInfo.paymentCapacityIndicator": "informacionIntencionVinculacion.prestamos.indicCapacidadPago",
    "clients[0].retailClientBasicInfo.cem": "informacionIntencionVinculacion.prestamos.CEM",

    "clients[0].retailClientFatcaInfo.hasOtherNationality": "fatca.nacionalidadOtroPais",
    "clients[0].retailClientFatcaInfo.otherNationalities": "fatca.indicarPaises",
    "clients[0].retailClientFatcaInfo.isUnitedStatesResident": "fatca.residenciaFisicaUSA",
    "clients[0].retailClientFatcaInfo.unitedStatesImmigrationStatus": "fatca.estatusMigratorio",
    "clients[0].retailClientFatcaInfo.fiscalId": "fatca.numIDFiscal",
    "clients[0].retailClientFatcaInfo.hasOtherFiscalResidency": "fatca.multiplesPaisesResidenciaFiscal",
    "clients[0].retailClientFatcaInfo.otherFiscalResidencies": "fatca.paisesResidenciaFiscal",
    "clients[0].retailClientFatcaInfo.hasGreencard": "fatca.greenCard",
    "clients[0].retailClientFatcaInfo.hasUnitedStatesMailingAddress": "fatca.direccionEnvioEstadosUnidos",
    "clients[0].retailClientFatcaInfo.hasInternationalPhoneNumber": "fatca.telefonoFueraPais",
    "clients[0].retailClientFatcaInfo.unitedStatesPhone": "fatca.telefonoEstadoUnidos",
    "clients[0].retailClientFatcaInfo.comments": "fatca.comentarios",
    "clients[0].retailClientFatcaInfo.hasUnitedStatesResidenceAddress": "fatca.tieneDireccionResidenciaEstadosUnidos",
    "clients[0].retailClientFatcaInfo.unitedStatesAddress": "fatca.direccionResidenciaEstadosUnidos",


    "clients[0].retailClientFinancialCapacity.yearlyIncome": "capacidadFinanciera.ingresosAnuales",
    "clients[0].retailClientFinancialCapacity.savingCapacityPct": "capacidadFinanciera.capacidadAhorro",
    "clients[0].retailClientFinancialCapacity.liquidAssets": "capacidadFinanciera.totalActivosLiquidos",
    "clients[0].retailClientFinancialCapacity.liquidAssetsInCheckingsPct": "capacidadFinanciera.porcientoCuentasAhorroOCorriente",
    "clients[0].retailClientFinancialCapacity.netWorth": "capacidadFinanciera.patrimonioTotal",
    "clients[0].retailClientFinancialCapacity.debtPct": "capacidadFinanciera.porcientoDeudas",
    "clients[0].retailClientFinancialCapacity.otherPct": "capacidadFinanciera.porcientoOtro",
    "clients[0].retailClientFinancialCapacity.bankingReferences": {
        array: "capacidadFinanciera.referenciasBancarias",
        map: {
            "bank": "banco",
            "typeOfAccount": "tipoCuenta",
            "phone": "telefono",
            "oficial": "oficial",
        }
    },

    "clients[0].retailClientFinancialCapacity.commercialReferences": {
        array: "capacidadFinanciera.referenciasComerciales",
        map: {
            "name": "nombre",
            "phone": "telefono",
            "address": "direccion",
        }
    },

    "clients[0].retailClientInvestorProfile.clientAge": "informacionIntencionVinculacion.perfilInversionista.riesgoProductosServiciosEdad",
    "clients[0].retailClientInvestorProfile.mainGoal": "informacionIntencionVinculacion.perfilInversionista.riesgoProductosServiciosObjetivo",
    "clients[0].retailClientInvestorProfile.riskProfile": "informacionIntencionVinculacion.perfilInversionista.criteriosIdeasRentabilidad",
    "clients[0].retailClientInvestorProfile.academicLevel": "informacionIntencionVinculacion.perfilInversionista.nivelAcademico",
    // "clients[0].retailClientInvestorProfile.financialOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado",
    "clients[0].retailClientInvestorProfile.investmentInFinanceCertificatesYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.inversionCertificadosFinancieros.anosManejandoProducto",
    "clients[0].retailClientInvestorProfile.investmentInFinanceCertificatesYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.inversionCertificadosFinancieros.numOperacionesAnuales",
    "clients[0].retailClientInvestorProfile.investmentInFinanceCertificatesKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.inversionCertificadosFinancieros.nivelConocimiento",
    "clients[0].retailClientInvestorProfile.riskFreeBondsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosSoberanos.anosManejandoProducto",
    "clients[0].retailClientInvestorProfile.riskFreeBondsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosSoberanos.numOperacionesAnuales",
    "clients[0].retailClientInvestorProfile.riskFreeBondsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosSoberanos.nivelConocimiento",
    "clients[0].retailClientInvestorProfile.corporateBondsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosCorporativos.anosManejandoProducto",
    "clients[0].retailClientInvestorProfile.corporateBondsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosCorporativos.numOperacionesAnuales",
    "clients[0].retailClientInvestorProfile.corporateBondsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.bonosCorporativos.nivelConocimiento",
    "clients[0].retailClientInvestorProfile.mutualFundsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosInversionesMutuos.anosManejandoProducto",
    "clients[0].retailClientInvestorProfile.mutualFundsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosInversionesMutuos.numOperacionesAnuales",
    "clients[0].retailClientInvestorProfile.mutualFundsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosInversionesMutuos.nivelConocimiento",
    "clients[0].retailClientInvestorProfile.closedFundsYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosCerrados.anosManejandoProducto",
    "clients[0].retailClientInvestorProfile.closedFundsYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosCerrados.numOperacionesAnuales",
    "clients[0].retailClientInvestorProfile.closedFundsKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.fondosCerrados.nivelConocimiento",
    "clients[0].retailClientInvestorProfile.otherDerivatives": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.indicar",
    "clients[0].retailClientInvestorProfile.otherDerivativesYearManagingProduct": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.anosManejandoProducto",
    "clients[0].retailClientInvestorProfile.otherDerivativesYearlyOperations": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.numOperacionesAnuales",
    "clients[0].retailClientInvestorProfile.otherDerivativesKnowledgeLevel": "informacionIntencionVinculacion.perfilInversionista.operacionesFinancierasNegociado.otrosDerivados.nivelConocimiento",

    "clients[0].retailClientInvestorProfile.expectedGoalTerm": "informacionIntencionVinculacion.perfilInversionista.plazoDeseaCumplirObjetivos",
    "clients[0].retailClientInvestorProfile.needOfInvestmentTerm": "informacionIntencionVinculacion.perfilInversionista.plazoAnticipaNecesitarInversiones",
    "clients[0].retailClientInvestorProfile.abilityToAbsorbLoses": "informacionIntencionVinculacion.perfilInversionista.capacidadAbsorverPerdidas",

    // Garantias
    "caseLoanInfo.includesCollateral": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSelection",
    "caseLoanInfo.includesJointGuarrantee": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.garantiaSolidaria",

    "clients[0].retailClientLoanInfo.warrantyNumber": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias.noDeGarantia",
    "clients[0].retailClientLoanInfo.numAssociatedFacilities": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias.noFacilidadAsociada",
    "clients[0].retailClientLoanInfo.warrantyDescription": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias.descGarantia",
    "clients[0].retailClientLoanInfo.value": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias.valGarantia",

    "clients[0].retailClientLoanInfo.selectedWarranties": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias.tipoDeGarantia",
    "clients[0].retailClientLoanInfo.contractDrafter": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.descGarantias.contratoElaboradoPor",
    //

    // "clients[0].retailClientLoanInfo.warrantyType": "informacionIntencionVinculacion.prestamos.incluyeGarantia.garantiaSolidariaSchema.tipoGarantia",
    "clients[0].retailClientLoanInfo.typeOfRelationshipToOtherClient": "informacionIntencionVinculacion.prestamos.codeudorFiador.codeudorFiadorSelection",
    "clients[0].retailClientLoanInfo.relationshipToRequestClient": "informacionIntencionVinculacion.prestamos.incluyeGarantia.tipoVinculacion",
    "clients[0].retailClientLoanInfo.purpose": "informacionIntencionVinculacion.prestamos.proposito",
    "clients[0].retailClientLoanInfo.comments": "informacionIntencionVinculacion.prestamos.comentarios",
    "clients[0].retailClientBasicInfo.timeLivingInCountry": "informacionIntencionVinculacion.prestamos.tiempoPais",
    "clients[0].retailClientBasicInfo.dependants": "informacionIntencionVinculacion.prestamos.dependientes",

    "clients[0].retailClientBasicInfo.actualProductsArray": "informacionIntencionVinculacion.prestamos.productosActuales",

    "clients[0].retailClientLoanInfo.loanFacilities": {
        array: "informacionIntencionVinculacion.prestamos.descFacilidades",
        map: {
            "number": "numFacilidad",
            "description": "descripcion",
            "facilityType": "tipoFacilidad",
            "amountApproved": "montoAprobado",
            "amountUsed": "montoUtilizado",
            "amountBudgeted": "montoPropuesto",
            "rate": "tasa",
            "payment": "formaPago",
            "estimatedQuote": "cuotaEstimada",
            "willBeCanceled": "seraCancelado"
        }
    },
    "clients[0].retailClientLoanInfo.currentResidenceType": "informacionIntencionVinculacion.prestamos.datosVivienda.tipoVivienda",
    "clients[0].retailClientLoanInfo.residenceOwner": "informacionIntencionVinculacion.prestamos.datosVivienda.nombrePropietario",
    "clients[0].retailClientLoanInfo.timeInResidence": "informacionIntencionVinculacion.prestamos.datosVivienda.tiempoResidiendo",
    "clients[0].retailClientLoanInfo.spouseFirstName": "clientSchema.datosConyuge.nombres",
    "clients[0].retailClientLoanInfo.spouseLastName": "clientSchema.datosConyuge.apellidos",
    "clients[0].retailClientLoanInfo.spouseEmail": "clientSchema.datosConyuge.email",
    "clients[0].retailClientLoanInfo.spouseIdentificationType": "clientSchema.datosConyuge.tipoIdent", // Need to change tipoIdent to int
    "clients[0].retailClientLoanInfo.spouseIdentificationNumber": "clientSchema.datosConyuge.numIdent",
    "clients[0].retailClientLoanInfo.spousePhoneMobile": "clientSchema.datosConyuge.celular",
    "clients[0].retailClientLoanInfo.spousePhone": "clientSchema.datosConyuge.telefono",
    "clients[0].retailClientLoanInfo.spouseCompany": "clientSchema.datosConyuge.labora",
    "clients[0].retailClientLoanInfo.spouseJobTitle": "clientSchema.datosConyuge.cargo",
    "clients[0].retailClientLoanInfo.spouseMonthlyIncome": "clientSchema.datosConyuge.ingMens",
    "clients[0].retailClientLoanInfo.spouseAge": "clientSchema.datosConyuge.edad",
    "clients[0].retailClientLoanInfo.personalReferences": {
        array: "informacionIntencionVinculacion.prestamos.refPersonales.refPersonalesArray",
        map: {
            "name": "nombre",
            "relationship": "parentesco",
            "phone": "telefono",
            "phoneMobile": "celular",
        }
    },
    "clients[0].retailClientLoanInfo.familyReferences": {
        array: "informacionIntencionVinculacion.prestamos.datosFamiliares.refFamiliares",
        map: {
            "name": "nombre",
            "relationship": "parentesco",
            "address": "direccion",
            "phone": "telefono",
            "cellphone": "celular",
        }
    },

    "clients[0].retailClientLoanInfo.lifeInsurance": "informacionIntencionVinculacion.prestamos.seguroVida",
    "clients[0].retailClientLoanInfo.processing": "informacionIntencionVinculacion.prestamos.tramitacion",
    "clients[0].retailClientLoanInfo.executive": "informacionIntencionVinculacion.prestamos.ejecutivo",
    "clients[0].retailClientLoanInfo.referredBy": "informacionIntencionVinculacion.prestamos.referidoPor",
    "clients[0].retailClientLoanInfo.disbursementForm": "informacionIntencionVinculacion.prestamos.formaDesembolso",
    "clients[0].retailClientLoanInfo.transferBankName": "informacionIntencionVinculacion.prestamos.formaDesembolsoOptions.banco",
    "clients[0].retailClientLoanInfo.transferAccountType": "informacionIntencionVinculacion.prestamos.formaDesembolsoOptions.tipoCuenta",
    "clients[0].retailClientLoanInfo.transferAccountNumber": "informacionIntencionVinculacion.prestamos.formaDesembolsoOptions.numeroCuenta",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.coDebtorOrGuarantor": "informacionIntencionVinculacion.prestamos.codeudorFiador.codeudorFiadorSelection",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.firstName": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nombre",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.lastName": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.apellido",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.identificationCard": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.cedula",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.birthDate": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.fechaNacimiento",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.birthPlace": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.lugarNacimiento",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.nationality": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nacionalidad",
    // "clients[0].retailClientLoanInfo.coDebtorGuarantor.isUSANationality": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nacionalidadUSA",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.passportNumber": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.numeroPasaporte",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.isUSAResident": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.residenteUSA",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.timeLivingInCountry": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tiempoPais",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.phone": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.telefono",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.cellphone": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.celular",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.otherNumber": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.otro",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.email": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.email",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.address": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.direccion",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.sector": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.sector",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.city": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.ciudad",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.province": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.provincia",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.country": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.pais",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.timeResiding": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tiempoResidiendo",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.gender": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.sexo",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.civilStatus": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.estadoCivil",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.dependants": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.dependientes",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.typePlace": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tipoVivienda",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.academicLevel": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nivelAcademico",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.srcIncome": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.fuentesIngreso",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.monthlyIncome": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.ingresosMensuales",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.workingDuration": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tiempoLaborando",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.linkageType": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.tipoVinculacion",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.otherOccupation": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.ocupacionOtro",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.ownerName": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.nombrePropietario",
    "clients[0].retailClientLoanInfo.coDebtorGuarantor.debtPercentage": "informacionIntencionVinculacion.prestamos.codeudorFiador.datosCodeudorFiador.porcientoDeudas",

    "clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposure": "personaPoliticamenteExpuesta.personaExpuestaPoliticamente",
    "clients[0].retailClientPoliticalExposureInfo.jobTitle": "personaPoliticamenteExpuesta.cargo",
    "clients[0].retailClientPoliticalExposureInfo.country": "personaPoliticamenteExpuesta.pais",
    "clients[0].retailClientPoliticalExposureInfo.startDate": "personaPoliticamenteExpuesta.fechaDesignacion",
    "clients[0].retailClientPoliticalExposureInfo.endDate": "personaPoliticamenteExpuesta.fechaRemocion",
    "clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposureRelative": "personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente",
    "clients[0].retailClientPoliticalExposureInfo.relativeName": "personaPoliticamenteExpuesta.nombrePEP",
    "clients[0].retailClientPoliticalExposureInfo.relativeAfinity": "personaPoliticamenteExpuesta.parentescoPEP",
    "clients[0].retailClientPoliticalExposureInfo.relativeJobTitle": "personaPoliticamenteExpuesta.cargoPEP",
    "clients[0].retailClientPoliticalExposureInfo.relativeCountry": "personaPoliticamenteExpuesta.paisPEP",
    "clients[0].retailClientPoliticalExposureInfo.relativeStartDate": "personaPoliticamenteExpuesta.fechaDesignacionPEP",
    "clients[0].retailClientPoliticalExposureInfo.relativeEndDate": "personaPoliticamenteExpuesta.fechaRemocionPEP",

    "clients[0].retailClientWorkInfo.companyName": "datosLaboralesSchema.empresa",
    "clients[0].retailClientWorkInfo.companyPhone": "datosLaboralesSchema.telefonoEmpresa",
    "clients[0].retailClientWorkInfo.companyEmail": "datosLaboralesSchema.emailLaboral",
    "clients[0].retailClientWorkInfo.address": "datosLaboralesSchema.direccionEmpresa",
    "clients[0].retailClientWorkInfo.sector": "datosLaboralesSchema.sector",
    "clients[0].retailClientWorkInfo.city": "datosLaboralesSchema.ciudad",
    "clients[0].retailClientWorkInfo.country": "datosLaboralesSchema.pais",
    "clients[0].retailClientWorkInfo.mainActivity": "datosLaboralesSchema.actividadPrincipal",
    "clients[0].retailClientWorkInfo.position": "datosLaboralesSchema.cargo",
    "clients[0].retailClientWorkInfo.startDate": "datosLaboralesSchema.fechaIngreso",
    "clients[0].retailClientWorkInfo.comments": "datosLaboralesSchema.comentarios",
    "clients[0].retailClientWorkInfo.province": "datosLaboralesSchema.provincia",
    "clients[0].retailClientBasicInfo.monthlyIncome": "datosLaboralesSchema.salarioMensual",  // NOTE: RETAIL CLIENT BASIC INFO
    "clients[0].retailClientWorkInfo.companyFax": "datosLaboralesSchema.faxEmpresa",
    "clients[0].retailClientWorkInfo.previousEmploymentPosition": "datosLaboralesSchema.cargoEmpleoAnterior",
    "clients[0].retailClientWorkInfo.previousEmploymentDuration": "datosLaboralesSchema.tiempoEmpleoAnterior",
    "clients[0].retailClientWorkInfo.previousEmploymentPhone": "datosLaboralesSchema.telefonoEmpleoAnterior",
    "clients[0].retailClientWorkInfo.previousEmploymentEmail": "datosLaboralesSchema.emailEmpleoAnterior",
    "clients[0].retailClientWorkInfo.previousEmploymentName": "datosLaboralesSchema.nombreEmpleoAnterior",


    "clients[0].retailClientProfileResult.fileUpdated": "informacionIntencionVinculacion.resultadoPerfil.fechaActualizacionExpediente",
    "clients[0].retailClientProfileResult.formUpdates": "informacionIntencionVinculacion.resultadoPerfil.indicarInformacionActualizadaYFuente",
    "clients[0].retailClientProfileResult.isFaceToFace": "informacionIntencionVinculacion.resultadoPerfil.relacionCaraCara",
    "clients[0].retailClientProfileResult.relationshipChannel": "informacionIntencionVinculacion.resultadoPerfil.canalNoCaraCara",
    "clients[0].retailClientProfileResult.summary": "informacionIntencionVinculacion.resultadoPerfil.resumenCliente",
    "clients[0].retailClientProfileResult.isProductAdjusted": "informacionIntencionVinculacion.resultadoPerfil.productoSolicitadoAjustaCliente",
    "clients[0].retailClientProfileResult.isFinancialClient": "informacionIntencionVinculacion.resultadoPerfil.clienteCorredorValores",
    "clients[0].retailClientProfileResult.isHighNetWorth": "informacionIntencionVinculacion.resultadoPerfil.clienteActivosLiquidosMayor25m",
    "clients[0].retailClientProfileResult.typeOfProspect": "informacionIntencionVinculacion.resultadoPerfil.clienteProfesional",
    "clients[0].retailClientProfileResult.isNotProfessional": "informacionIntencionVinculacion.resultadoPerfil.consideradoNoProfesional",
    "clients[0].retailClientProfileResult.relationshipChannelOther": "informacionIntencionVinculacion.resultadoPerfil.otroCanalNoCaraCara",
    "clients[0].retailClientProfileResult.tolerance": "informacionIntencionVinculacion.resultadoPerfil.tolerancia",
    "clients[0].retailClientProfileResult.banked": "informacionIntencionVinculacion.resultadoPerfil.clienteBancarizado",
    "clients[0].retailClientProfileResult.acknowledgement": "informacionIntencionVinculacion.resultadoPerfil.acuerdoConPerfilObtenido",
    "clients[0].retailClientProfileResult.updatedFileClient": "informacionIntencionVinculacion.resultadoPerfil.actualizacionExpedienteCliente",

    "clients[0].retailClientDueDiligenceReport.inOFACList": "soporteDebidaDiligencia.tieneOFAC",
    "clients[0].retailClientDueDiligenceReport.inONUList": "soporteDebidaDiligencia.tieneONU",
    "clients[0].retailClientDueDiligenceReport.riskLevel": "soporteDebidaDiligencia.nivelRiesgo",

    "clients[0].retailClientDueDiligenceReport.ofacRevisionFileId": "soporteDebidaDiligencia.revisionListaOFAC",
    "clients[0].retailClientDueDiligenceReport.creditBuroRevisionFileId": "soporteDebidaDiligencia.revisionBuroCredito",
    "clients[0].retailClientDueDiligenceReport.listRevisionFileId": "soporteDebidaDiligencia.revisionListaONU",
    "clients[0].retailClientDueDiligenceReport.riskMatrixFileId": "soporteDebidaDiligencia.matrizRiesgo",
    "clients[0].retailClientDueDiligenceReport.dueDiligenceResult": "soporteDebidaDiligencia.sentBack",
    "clients[0].retailClientDueDiligenceReport.additionalFilesList.items": {
        array: "soporteDebidaDiligencia.additionalFiles",
        map: {
            "name": "documentName",
            "fileId": "document",
        }
    },

    // Puesto

    "caseInvestmentInfo.usdAmountPuesto": "informacionIntencionVinculacion.inversionesSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginPuesto": "informacionIntencionVinculacion.inversionesSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherPuesto": "informacionIntencionVinculacion.inversionesSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountPuesto": "informacionIntencionVinculacion.inversionesSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginPuesto": "informacionIntencionVinculacion.inversionesSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherPuesto": "informacionIntencionVinculacion.inversionesSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRatePuesto": "informacionIntencionVinculacion.inversionesSchema.tasaCambio",

    // Certificado Financiero

    "caseInvestmentInfo.rdsAmountCertificate": "informacionIntencionVinculacion.financialCertSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginCertificate": "informacionIntencionVinculacion.financialCertSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherCertificate": "informacionIntencionVinculacion.financialCertSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateCertificate": "informacionIntencionVinculacion.financialCertSchema.tasaCambio",

    // SAFI

    "caseInvestmentInfo.usdAmountSAFI": "informacionIntencionVinculacion.safiSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginSAFI": "informacionIntencionVinculacion.safiSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherSAFI": "informacionIntencionVinculacion.safiSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountSAFI": "informacionIntencionVinculacion.safiSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginSAFI": "informacionIntencionVinculacion.safiSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherSAFI": "informacionIntencionVinculacion.safiSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateSAFI": "informacionIntencionVinculacion.safiSchema.tasaCambio",
    "clients[0].retailClientInvestorProfile.t24safiClientId": "informacionIntencionVinculacion.safiSchema.numClienteSAFIUCS",

    // Cuenta Ahorro

    "clients[0].retailClientBasicInfo.rdsAmountSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.depositoEstimadoRD",
    "clients[0].retailClientBasicInfo.rdsFundsOriginSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.origenFondosRD",
    "clients[0].retailClientBasicInfo.rdsFundsOriginOtherSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.origenFondosRDOtro",
    "clients[0].retailClientBasicInfo.usdAmountSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.depositoEstimadoUSD",
    "clients[0].retailClientBasicInfo.usdFundsOriginSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.origenFondosUSD",
    "clients[0].retailClientBasicInfo.usdFundsOriginOtherSavingsAccount": "informacionIntencionVinculacion.savingsAccountSchema.origenFondosUSDOtro",
    "clients[0].retailClientBasicInfo.rdsSelection": "informacionIntencionVinculacion.savingsAccountSchema.rdCurrency",
    "clients[0].retailClientBasicInfo.usdSelection": "informacionIntencionVinculacion.savingsAccountSchema.usCurrency",

    "clients[0].retailClientBasicInfo.swornDeclarationArray[0]": "declaracionJurada.declaracionJurada1",
    "clients[0].retailClientBasicInfo.swornDeclarationArray[1]": "declaracionJurada.declaracionJurada2",
    "clients[0].retailClientBasicInfo.swornDeclarationArray[2]": "declaracionJurada.declaracionJurada3",
    "clients[0].retailClientBasicInfo.swornDeclarationArray[3]": "declaracionJurada.declaracionJurada4",

    "clients[0].retailClientNetWorthInfo.fiscalYearOne": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.anosPeriodosFiscales1",
    "clients[0].retailClientNetWorthInfo.yearOneRDSNetWorth": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.totalPatrimonio1",
    "clients[0].retailClientNetWorthInfo.yearOneRDSLiquidAssets": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.totalActivosLiquidos1",
    "clients[0].retailClientNetWorthInfo.yearOneEquityAnalisis": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales1.resultadoAnalisisPatrimonio1",

    "clients[0].retailClientNetWorthInfo.fiscalYearTwo": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales2.anosPeriodosFiscales2",
    "clients[0].retailClientNetWorthInfo.yearTwoRDSNetWorth": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales2.totalPatrimonio2",
    "clients[0].retailClientNetWorthInfo.yearTwoRDSLiquidAssets": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales2.totalActivosLiquidos2",
    "clients[0].retailClientNetWorthInfo.yearTwoEquityAnalisis": "informacionIntencionVinculacion.analisisPatrimonio.PeriodosFiscales2.resultadoAnalisisPatrimonio2",

    "caseLoanInfo.loanAmount": "informacionIntencionVinculacion.prestamos.montoDeseadoPrestamo",
    "caseLoanInfo.exchangeRate": "informacionIntencionVinculacion.prestamos.tasaCambio",
    "caseLoanInfo.loanDestination": "informacionIntencionVinculacion.prestamos.destinoFondos",
}


export default form;