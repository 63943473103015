import { readOnlyFileUI } from "./commons";


const form = {
    title: "Carga Documentos",
    displayName: 'ComplianceCaseReviewUploadPersonal',
    page: 'compliance-case-review-upload-personal',
};


const reportesDebidaDiligencia = {
    title: "Reportes Debida Diligencia",
    type: "object",
    properties: {
        "reporteBanco": { title: "Reporte de Banco", type: "number",},
        "reportePuesto": { title: "Reporte de Puesto", type: "number",},
        "reporteSAFI": { title: "Reporte de SAFI", type: "number",},
        "reporteBancoSimple": { title: "Reporte de Banco - DD Simplificada", type: "number",},
        "reportePuestoSimple": { title: "Reporte de Puesto - DD Simplificada", type: "number",},
        "reporteSAFISimple": { title: "Reporte de SAFI - DD Simplificada", type: "number",},
    }
}
const reportesDebidaDiligenciaFirmados = {
    title: "Reportes Debida Diligencia Firmados",
    type: "object",
    properties: {
        "reporteBancoFirmado": { title: "Informe de BANCO Firmado", type: "number", },
        "reportePuestoFirmado": { title: "Informe de PUESTO Firmado", type: "number", },
        "reporteSAFIFirmado": { title: "Informe de SAFI Firmado", type: "number", },
        "aprobacionAltaGerencia": { title: "Aprobación de Alta Gerencia PUESTO", type: "number", },
        "aprobacionAltaGerenciaSAFI": { title: "Aprobación de Alta Gerencia SAFI", type: "number", },
        "aprobacionAltaGerenciaBanco": { title: "Aprobación de Alta Gerencia BANCO", type: "number", },
    }
}

form.schema = {
    title: "Debida Diligencia Persona Física - Reportes",
    type: "object",
    properties: {
        "reportesDebidaDiligencia": reportesDebidaDiligencia,
        "reportesDebidaDiligenciaFirmados": reportesDebidaDiligenciaFirmados,
    },
    required: [],
    dependencies: {

    },
};

form.uiSchema = {

    "reportesDebidaDiligencia": {
        "classNames": "personalComplianceUploads-rdd",
        "ui:field": "HideObjectField",
        "reportePuesto":       { ...readOnlyFileUI, classNames: "reporte puesto"      , "ui:showIf": `
            2 in $formObject.productInstitutions
        `},
        "reporteSAFI":         { ...readOnlyFileUI, classNames: "reporte safi"        , "ui:showIf": `
            3 in $formObject.productInstitutions
        `},
        "reporteBanco":        { ...readOnlyFileUI, classNames: "reporte banco"       , "ui:showIf": `
            1 in $formObject.productInstitutions
        `},
        "reportePuestoSimple": { ...readOnlyFileUI, classNames: "reporteSimple puesto", "ui:showIf": `
            2 in $formObject.productInstitutions
        `},
        "reporteSAFISimple":   { ...readOnlyFileUI, classNames: "reporteSimple safi"  , "ui:showIf": `
            3 in $formObject.productInstitutions
        `},
        "reporteBancoSimple":  { ...readOnlyFileUI, classNames: "reporteSimple banco" , "ui:showIf": `
            1 in $formObject.productInstitutions
        `},
    },

    "reportesDebidaDiligenciaFirmados": {
        "classNames": "personalComplianceUploads-upload",
        "ui:field": "HideObjectField",
        "reportePuestoFirmado"       : { "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $isTruthy(reportesDebidaDiligencia.reportePuesto)
        `},
        "reporteBancoFirmado"        : { "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $isTruthy(reportesDebidaDiligencia.reporteBanco)
        `},
        "reporteSAFIFirmado"         : { "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $isTruthy(reportesDebidaDiligencia.reporteSAFI)
        `},
        "aprobacionAltaGerencia"     : { "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].retailClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy(reportesDebidaDiligencia.reportePuesto)
        `},
        "aprobacionAltaGerenciaSAFI" : { "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].retailClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy(reportesDebidaDiligencia.reporteSAFI)
        `},
        "aprobacionAltaGerenciaBanco": { "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].retailClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy(reportesDebidaDiligencia.reporteBanco)
        `},
    }
}

form.objectMap = {

    "generatedFiles.compliance_0_RevisionDeCumplimientoADdPuesto": "reportesDebidaDiligencia.reportePuesto",
    "generatedFiles.compliance_0_RevisionDeCumplimientoADdSafi": "reportesDebidaDiligencia.reporteSAFI",
    "generatedFiles.compliance_0_RevisionDeCumplimientoADdBanco": "reportesDebidaDiligencia.reporteBanco",

    "generatedFiles.compliance_0_RevisionDeCumplimientoADdPuestoSimplificada": "reportesDebidaDiligencia.reportePuestoSimple",
    "generatedFiles.compliance_0_RevisionDeCumplimientoADdSafiSimplificada": "reportesDebidaDiligencia.reporteSAFISimple",
    "generatedFiles.compliance_0_RevisionDeCumplimientoADdBancoSimplificada": "reportesDebidaDiligencia.reporteBancoSimple",

    "clients[0].retailClientDueDiligenceReport.signedPuestoReportFileId": "reportesDebidaDiligenciaFirmados.reportePuestoFirmado",
    "clients[0].retailClientDueDiligenceReport.signedBancoReportFileId": "reportesDebidaDiligenciaFirmados.reporteBancoFirmado",
    "clients[0].retailClientDueDiligenceReport.signedSAFIReportFileId": "reportesDebidaDiligenciaFirmados.reporteSAFIFirmado",
    "clients[0].retailClientDueDiligenceReport.highManagementApprovalFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerencia",
    "clients[0].retailClientDueDiligenceReport.highManagementApprovalSAFIFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaSAFI",
    "clients[0].retailClientDueDiligenceReport.highManagementApprovalBancoFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaBanco",
    //  Fields with wrong type in db:
}

export default form;