const form = {
    title: "Desembolso",
    displayName: 'LoanDisbursementConfirmation',
    page: 'loan-disbursement-confirmation',
    commentFieldsMap: {
        "Desembolso": "caseLoanInfo.loanDisbursementComments",
    },
    isSentBack(object) {
        return object.caseLoanInfo.loanDisbursementConfirmation === 2;
    } // Devuelto
};


form.schema = {
    title: "Desembolso",
    type: "object",
    properties: {
        // "enviarCorreoCliente": {
        //     "buttonText": "Enviar Correo a Cliente",
        //     subject: "¡Préstamo Desembolsado!",
        //     body: "Buenas tardes Sr/Sra. {nombreCliente} \n\n¡Su préstamo ha sido desembolsado!\n\nLe pedimos que se tome unos minutos y llene la siguiente encuesta para evaluar su experiencia de solicitud de préstamo en JMMB",
        //     type: "null",
        // },
        "formularioTramitacionFirmado": {
            title: "Cargar Formulario de Tramitación Firmado",
            type: "number",
        },
        "creacionSolicitudDesembolso": {
            title: " ",
            type: "object",
            properties: {
                "aprobacionDesembolso": {
                    title: "Aprobación de Desembolso",
                    type: "number",
                    enumNames: ["Aprobado en T24", "Devuelto"],
                    enum: [1, 2]
                },
                "comentarios": {
                    title: "Comentarios",
                    type: "string",
                },
            }
        },
    },
    required: []
};



form.uiSchema = {
    // "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "formularioTramitacionFirmado": { "ui:field": "FileField" },
    "creacionSolicitudDesembolso": {
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
    },
}

form.objectMap = {
    "caseOperationsReview.signedTransactionFileId": "formularioTramitacionFirmado",
    "caseLoanInfo.loanDisbursementConfirmation": "creacionSolicitudDesembolso.aprobacionDesembolso",
    "caseLoanInfo.loanDisbursementComments": "creacionSolicitudDesembolso.comentarios",

}


export default form;