import { STATIC_FILE_URLS } from "../constants";


const form = {
    title: "Elaboración de Contratos (Legal)",
    displayName: 'LegalContractElaboration',
    page: 'legal-contract-elaboration',
    commentFieldsMap: {
        "Contratos Legal": "clients[0].retailClientDueDiligenceReport.legalComments",
    },
};

form.schema = {
    title: "Creación de contratos",
    type: "object",
    properties: {
        // "campo1": {
        //     title: " ",
        //     description:"Campo 1",
        //     type:"string",

        // },
        // "campo2": {
        //     title: " ",
        //     description:"Campo 2",
        //     type:"string",

        // },
        // "campo3": {
        //     title: " ",
        //     description:"Campo 3",
        //     type:"string",

        // },
        // "campo4": {
        //     title: " ",
        //     description:"Campo 4",
        //     type:"string",

        // },
        "comentarios": {
            title: "Comentarios Internos",
            type: "string"
        },
        "formCliente": {
            title: " ",
            type: "string",
            const: STATIC_FILE_URLS.ContractTemplate,
        },
        "cargContrato": {
            title: "Cargar Contrato finalizado",
            type: "number"
        },
    },
    required: ["cargContrato"]
}

form.uiSchema = {
    "formCliente": {
        "ui:field": "FileField"
    },
    "cargContrato": {
        "ui:field": "FileField"
    },
}

form.objectMap = {
    "clients[0].retailClientDueDiligenceReport.legalField1": "campo1",
    "clients[0].retailClientDueDiligenceReport.legalField2": "campo2",
    "clients[0].retailClientDueDiligenceReport.legalField3": "campo3",
    "clients[0].retailClientDueDiligenceReport.legalField4": "campo4",
    "clients[0].retailClientDueDiligenceReport.legalComments": "comentarios",
    "clients[0].retailClientDueDiligenceReport.legalContractFileId": "cargContrato",
}


export default form;