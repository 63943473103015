import {
    boolYesNoType,
} from './commons';


const form = {
    title: "Análisis de Crédito",
    displayName: 'CreditAnalysisReport',
    page: 'business-credit-analysis-report',
    commentFieldsMap: {
        "Analisis Credito": "caseLoanInfo.creditAnalysisReportComments",
    },
    isSentBack(object) {
        return object.caseLoanInfo.creditAnalysisResult === "Devuelto";
    }
};


form.schema = {
    title: "Revisión de Análisis de Crédito - Comercial",
    type: "object",
    properties: {
        "resultadoAnalisisCredito": {
            title: " ",
            description: "Resultados Análisis de Crédito",
            type: "object",
            properties: {
                "informeAnalisis": {
                    title: "Informe de Análisis",
                    type: "number",
                }

            },
        },
        "condicionesPropuestas": {
            type: "array",
            title: "Condiciones propuestas según análisis de crédito",
            minItems: 1,
            items: {
                type: "object",
                properties: {
                    "numFacilidad": {
                        title: "No. de Facilidad",
                        type: "string",
                    },
                    "descripcion": {
                        title: "Descripción",
                        type: "string",
                    },
                    "tipoFacilidad": {
                        title: "Tipo de Facilidad",
                        type: "string",
                        enumNames: [
                            "Consumo",
                            "Comercial- Micro",
                            "Descuento nómina",
                            "Hipotecario",
                        ],
                        enum: [
                            "Consumo",
                            "Comercial- Micro",
                            "Descuento nomina",
                            "Hipotecario",
                        ],
                    },
                    "montoAprobado": {
                        title: "Monto Aprobado (si aplica)",
                        type: "string",
                    },
                    "montoUtilizado": {
                        title: "Monto Utilizado (si aplica)",
                        type: "string",
                    },
                    "montoPropuesto": {
                        title: "Monto Propuesto",
                        type: "string",
                    },
                    "tasa": {
                        title: "Tasa",
                        type: "number",
                    },
                    "formaPago": {
                        title: "Forma de Pago",
                        type: "string",
                        enumNames: [
                            "Ingresos por negocio",
                            "Ingresos por salario",
                            "Intereses generados",
                            "Otro",
                        ],
                        enum: [
                            "Ingresos por negocio",
                            "Ingresos por salario",
                            "Intereses generados",
                            "Otro",
                        ],
                    },
                    "cuotaEstimada": {
                        title: "Cuota Estimada",
                        type: "string",
                    },
                    "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
                },
            },
        },
        "resultadoAC": {
            title: "Resultado",
            type: "string",
            enum: [
                "Recomendado",
                "Devuelto",
                "Declinado",
                "Pospuesto"
            ]
        },
        "comentarios": {
            title: "Comentarios",
            type: "string"
        },
    },
    required: ["resultadoAC"]
}

form.uiSchema = {
    "resultadoAnalisisCredito": {
        "informeAnalisis": { "ui:field": "FileField" },
    },
    "comentarios": {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 4
        },
    },
    "condicionesPropuestas": {
        "ui:options": {
            "orderable": false,
        }
    }
}

form.objectMap = {
    "caseLoanInfo.analysisReportFileId": "resultadoAnalisisCredito.informeAnalisis",
    "caseLoanInfo.creditAnalysisResult": "resultadoAC",
    "caseLoanInfo.creditAnalysisReportComments": "comentarios",
    "clients[0].businessClientLoanInfo.businessLoanFacilities": {
        array: "condicionesPropuestas",
        map: {
            "number": "numFacilidad",
            "description": "descripcion",
            "facilityType": "tipoFacilidad",
            "amountApproved": "montoAprobado",
            "amountUsed": "montoUtilizado",
            "amountBudgeted": "montoPropuesto",
            "rate": "tasa",
            "payment": "formaPago",
            "estimatedQuote": "cuotaEstimada",
            "willBeCancelled": "seraCancelado"
        }
    },

}


export default form;