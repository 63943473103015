const form = {
    title: "Creación & Activación Cliente SAFI",
    displayName: 'InvestmentClientCreationConfirmationSAFI',
    page: 'investment-client-creation-confirmation-safi',
};

form.schema = {
    title: "Activación de cliente en SAFI",
    type: "object",
    properties: {
        // "enviarCorreoCliente": {
        //     "buttonText": "Enviar Correo a Cliente",
        //     subject: "¡Te damos la bienvenida a JMMB!",
        //     body: "¡TE DAMOS LA BIENVENIDA A LA FAMILIA JMMB! \n\n\n\n Estamos contentos porque contigo nuestro Grupo SAFI sigue creciendo. \n\nNuestra cultura está basada en el amor incondicional teniendo tu mejor interés en nuestros corazones. Somos tu socio financiero brindándote de soluciones integrales e innovadoras que junto a nuestra asesoría personalizada te empoderarán para lograr tus sueños. \n\n\n Le pedimos que se tome unos minutos y llene la siguiente encuesta para evaluar su experiencia de vinculación con el grupo JMMB.",
        //     type: "null",
        // },
        "infCliT24SAFI": {
            title: " ",
            description: "Informaciones Cliente T24 SAFI",
            type: "object",
            properties: {
                "cliT24SAFI": {
                    title: "Cliente creado en T24 SAFI",
                    type: "boolean",
                },
                "numCliT24SAFI":
                {
                    title: "Número de cliente en T24 SAFI",
                    type: "string"
                },
                "cliActivadoT24SAFI": {
                    title: "Cliente Activado en T24 SAFI",
                    type: "boolean",
                },
            },
            required: ["numCliT24SAFI"],
            dependencies: {
            }
        },
    }
}

form.uiSchema = {
    // "enviarCorreoCliente": { "ui:field": "SendEmail" },
}
form.objectMap = {
    "clients[$idx].retailClientInvestorProfile.clientCreatedT24SAFI": "infCliT24SAFI.cliT24SAFI",
    "clients[$idx].retailClientInvestorProfile.t24safiClientId": "infCliT24SAFI.numCliT24SAFI",
    "clients[$idx].retailClientInvestorProfile.clientActivatedT24SAFI": "infCliT24SAFI.cliActivadoT24SAFI",
}


export default form;