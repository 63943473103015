import React, { useState, useMemo, useEffect } from 'react'
import { withRouter } from 'react-router-dom';

import AuthApi from '../api/AuthApi';
import Loader from '../components/Loader';
import useLoader from '../util/useLoader';

export const AuthContext = React.createContext()


const UserStore = ({
    KEY: 'user',
    user: null,
    init() {
        const json = localStorage.getItem(this.KEY);
        this.user = json ? JSON.parse(json) : null;
        return this;
    },
    setUser(newUser) {
        this.user = newUser

        if (newUser) {
            localStorage.setItem(this.KEY, JSON.stringify(newUser));
        } else {
            localStorage.removeItem(this.KEY);
        }
    },
    isAdmin: false
}).init();


function _AuthProvider(props) {
    const { children } = props;
    const [loading, errorLoading, loadFn] = useLoader();
    const [updateCt, setUpdateCt] = useState(0);

    const value = useMemo(() => ({
        user: UserStore.user,
        updateCt,

        async login({ email, password }) {
            const response = await AuthApi.login({ email, password });
            const { token, user } = response;
            UserStore.setUser({
                email: user.email,
                token,
                user,
                permissions: (user.role.permissions || []).reduce((_, p) => {
                    _[p] = 1;
                    return _;
                }, {})
            });
            setUpdateCt(updateCt + 1);
        },

        async changePassword({ password, passwordConfirm }) {
            const user = await AuthApi.changeSelfPassword({ password, passwordConfirm });
            UserStore.setUser({
                email: user.email,
                token: UserStore?.user?.token,
                user,
                permissions: (user.role.permissions || []).reduce((_, p) => {
                    _[p] = 1;
                    return _;
                }, {})
            });
            setUpdateCt(updateCt + 1);
        },

        logout() {
            UserStore.setUser();
            setUpdateCt(updateCt + 1);
        },

        async verifyToken() {
            console.log("verifying user token...")
            try {
                const response = await AuthApi.verifyToken();
                if (!response.data) {
                    console.log("token is invalid or stale, user is not logged in.");
                    UserStore.setUser();
                    setUpdateCt(updateCt + 1);
                } else {
                    console.log("user is still logged in.");
                }
            } catch(e) {
                console.log("error while verifiyng token, user is not logged in.");
                UserStore.setUser();
                setUpdateCt(updateCt + 1);
            }
        },

        isAdmin: UserStore.user?.user?.role?.name === 'administrator'
    }), [UserStore, updateCt]);


    useEffect(() => {
        loadFn(() => value.verifyToken());
    }, []);


    return (
        <AuthContext.Provider value={value}>
            {loading ? <Loader fullscreen/> : children}
        </AuthContext.Provider>
    )
}


export function getToken() {
    return (UserStore.user || {}).token;
}

export const AuthConsumer = AuthContext.Consumer

export const AuthProvider = withRouter(_AuthProvider);
