function deduplicate(list) {
    return list.reduce((_, item) => {
        if (!_[1][item]) {
            _[0].push(item);
            _[1][item] = 1;
        }
        return _;
    }, [[], {}])[0];
}


export default deduplicate;
