import React, { useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { useZohoDeskWidgetCallFunction } from '../util/useZohoDeskWidget';


function Notification({ children, error, danger, ...props }) {
    const [show, setShow] = useState(true);

    if (error && !children) {
        props.color = 'danger';
        children = error.message || `${error}`;
    }
    if (children === "Failed to fetch") {
        children = (
            <span>Hubo un problema con la aplicación 
            <ZohoDeskButton style={{margin:'10px'}} color="primary">
                Crear Ticket
            </ZohoDeskButton>
            </span>)
    }
    return (
        <Alert show={`${show}`} {...props} onClick={() => setShow()}>
            {children}
        </Alert>
    );
}


function ZohoDeskButton({children, ...props}){
    const zdwcf = useZohoDeskWidgetCallFunction();
    return (<Button {...props} onClick={() => zdwcf()}>{children}</Button>);
}


export default Notification;