import React from "react"


function SortButton({
    tag = 'button',
    sortKey,
    sortBy,
    setSortBy,
    children
}) {
    const descSortKey = `-${sortKey}`;
    const Tag = tag;
    const sorting = (sortBy === sortKey ? 'asc' : (sortBy === descSortKey ? 'desc' : null));
    const chevron = ({ asc: 'glyphicon glyphicon-sort-by-alphabet', desc: 'glyphicon glyphicon-sort-by-alphabet-alt' })[sorting];
  
    return (<Tag className="sort-button" onClick={() => {
      setSortBy(sorting === 'asc' ? descSortKey : sortKey);
    }}>{children} {chevron ? (<i className={chevron} />) : null}</Tag>)
}


export default SortButton;