import {useState, useCallback, useEffect} from "react";


function useStateUpdateRequestPromise(
    stateValue,
    setStateValue,
    getValueToSet
){
    const [request, setRequest] = useState();

    const callback = useCallback((specifiedValue) => {
        if (!request || request.resolved) {
            const request = {};
            request.promise = new Promise(resolve => {
                request.resolve = () => {
                    request.resolved = true;
                    resolve();
                };
            });
            request.value = specifiedValue === undefined ? getValueToSet() : specifiedValue;
            setStateValue(request.value);
            setRequest(request);
            return request.promise;
        } else {
            return request.promise;
        }
    }, [request, setStateValue, getValueToSet]);

    useEffect(() => {
        if (
            request &&
            !request.resolved &&
            stateValue === request.value
        ) {
            request.resolve(stateValue);
        }
    }, [request, stateValue]);

    return callback;
}


export default useStateUpdateRequestPromise;