import queryString from 'query-string';


/** Fetch enchancing function for calling apis.
 * @param {String} url - url to get resource.
 * @param {Object} options - options object used in fetch.
 * @param {Object} options.json - options object used in fetch.
 * @param {Object} options.query - query parameters to append to url. 
 * @returns {Promise} resolved returned by fetch().
 */
function __fetchApi(url, _options) {
    const options = _options || {};
    options.headers = options.headers || {};

    if (options.return_url) {
        return url;
    }

    if (options.formdata) {
        const fd = new FormData();
        Object.entries(options.formdata).forEach(([name, value]) => {
            const args = value.file && value.filename ? [value.file, value.filename]: [value];

            fd.append(name, ...args);
        })

        options.body = fd
        delete options.formdata;
    } else if (options.data) {
        options.body = JSON.stringify(options.data);
        options.headers['Content-Type'] = 'application/json';
        delete options.data;
    }

    if (options.auth) {
        options.headers.Authorization = `${options.auth.type} ${options.auth.token}`;
        delete options.auth;
    }

    if (options.query) {
        const prefix = url.indexOf('?') === -1 ? '?' : '&';
        url = url + prefix + queryString.stringify(options.query);
        delete options.query;
    }

    if (options.baseurl) {
        url = `${options.baseurl}${url}`;
        delete options.baseurl;
    }

    const { json, cache, useCache: useCacheOption  } = options;
    const useCache = (typeof useCacheOption === 'function') ? useCacheOption(url, options) : useCacheOption;
    delete options.json;
    delete options.cache;
    delete options.useCache;
    const method = (options.method || 'get').toLowerCase();

    if (cache && useCache) {
        const cachedResponse = (cache[method] || {})[url];
        if (cachedResponse) {
            console.info("Fetching from cache: ", method, url);
            // console.log(cachedResponse);
            return Promise.resolve(cachedResponse);
        }
    }

    return fetch(url, options).then(response => {
        if (json) {
            return response.json();
        }

        return response;
    }).then(response => {
        if (useCache && cache) {
            (cache[method] || (cache[method] = {}))[url] = response;
        }
        return response;
    });
}


__fetchApi.cache = {};


function fetchApi(url, options) {
    return __fetchApi(url, {
        cache:__fetchApi.cache,
        ...fetchApi.DEFAULT_OPTIONS,
        ...options
    });
}


/* eslint-disable camelcase, no-param-reassign */
fetchApi.configure = function fetchApi_configure({
    defaults,
    verbs = ['get', 'post', 'put', 'delete'],
    helpers
}) {

    fetchApi.DEFAULT_OPTIONS = defaults || {};
    let { baseurl } = fetchApi.DEFAULT_OPTIONS;

    if (baseurl && baseurl[baseurl.length - 1] !== '/') {
        baseurl += '/';
    }

    fetchApi.DEFAULT_OPTIONS.baseurl = baseurl;

    [[], ...Object.entries(helpers)].forEach(([helperName, helperFn]) => {
        const helper = helperFn ? (url, options) => helperFn(url, options, fetchApi) : fetchApi;
        if (helperName) {
            fetchApi[helperName] = helper;
        }

        helper.url = (url) => helper(url, { return_url: true });

        verbs.forEach((verb) => {
            helper[verb] = (url, options) => helper(url, { method: verb, ...options });
        });
    });

    fetchApi.configured = true;
    return fetchApi;
}



export default fetchApi;
