import nonccdp from "./NonCreditContractsDownloadPersonalClientSchema";


const form = {
    title: "Carga Contratos / Firmas",
    displayName: 'NonCreditContractsUploadPersonalClient',
    page: 'non-credit-contracts-personal-client',
};

form.schema = {
    title: "Solicitud de Vinculación Cliente Personal",
    type: "object",
    properties: {
        "dct": nonccdp.schema.properties.dct,
        "dfp": {
            title: "Documentos firmados Puesto",
            type: "object",
            properties: {
                "tarjetaFirmaPuesto": {
                    title: "Tarjeta Firma",
                    type: "number",
                },
                "informeInversionistaPuesto": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoPuesto": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "contratoCuentaAperturaPuesto": {
                    title: "Contrato Apertura Cuenta",
                    type: "number",
                },
                "cartaAutoApertCuentaPuesto": {
                    title: "Carta Autorización Apertura Cuenta",
                    type: "number",
                },
                "formClientePuesto": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formW8BenPuesto": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formW9Puesto": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formMoneylinePuesto": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
            },
            required: [
            ]
        },
        "dfs": {
            title: "Documentos firmados SAFI",
            type: "object",
            properties: {
                "tarjetaFirmaSafi": {
                    title: "Tarjeta Firma",
                    type: "number",
                },
                "informeInversionistaSafi": {
                    title: "Informe Perfil de Inversionista",
                    type: "number",
                },
                "contratoMarcoSafi": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "formClienteSafi": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formW8BenSafi": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formW9Safi": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formMoneylineSafi": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
            },
            required: [
            ]
        },
        "dfb": {
            title: "Documentos firmados Banco",
            type: "object",
            properties: {
                "tarjetaFirmaBanco": {
                    title: "Tarjeta Firma",
                    type: "number",
                },
                "contratoMarcoBanco": {
                    title: "Contrato Marco",
                    type: "number",
                },
                "contratoCuentaAperturaBanco": {
                    title: "Contrato Apertura Cuenta",
                    type: "number",
                },
                "formClienteBanco": {
                    title: "Formulario Conozca su Cliente",
                    type: "number",
                },
                "formW8BenBanco": {
                    title: "Formulario W8-BEN",
                    type: "number",
                },
                "formW9Banco": {
                    title: "Formulario W9",
                    type: "number",
                },
                "formMoneylineBanco": {
                    title: "Formulario Solicitud Moneyline",
                    type: "number",
                },
                "solicitudPrestamo": {
                    title: "Solicitud de Préstamo",
                    type: "number",
                },
            },
            required: [
            ]
        },
        "sso": {
            title: "Soporte Sujeto Obligado",
            type: "object",
            properties: {
                "soporteSujetoObligadoFile": {
                    title: "Soporte de Sujeto Obligado No Financiero Personal",
                    type: "number",
                },
            }
        }
    },
    required: [],
};



form.uiSchema = {
    "dct": nonccdp.uiSchema.dct,
    dfp: {
        "classNames": "personalNonCCD-upload",
        "ui:field": "HideObjectField",
        "tarjetaFirmaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.tarjetaDeFirmaPuesto" } },
        "informeInversionistaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.informeInversionistaPuesto" } },
        "contratoMarcoPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoMarcoPuesto" } },
        "contratoCuentaAperturaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoCuentaPuesto" } },
        "cartaAutoApertCuentaPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.cartaAutorizacionCuentaPuesto" } },
        "formClientePuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioClientePuesto" } },
        "formW8BenPuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW8BenPuesto" } },
        "formW9Puesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW9Puesto" } },
        "formMoneylinePuesto": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioMoneylinePuesto" } },
    },
    dfs: {
        "classNames": "personalNonCCD-upload",
        "ui:field": "HideObjectField",
        "tarjetaFirmaSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.tarjetaFirmaSafi" } },
        "informeInversionistaSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.informeInversionistaSafi" } },
        "contratoMarcoSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoMarcoSafi" } },
        "formClienteSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioClienteSafi" } },
        "formW8BenSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW8BenSafi" } },
        "formW9Safi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW9Safi" } },
        "formMoneylineSafi": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioMoneylineSafi" } },
    },
    dfb: {
        "classNames": "personalNonCCD-upload",
        "ui:field": "HideObjectField",
        "tarjetaFirmaBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.tarjetaFirmaBanco" } },
        "contratoMarcoBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoMarcoBanco" } },
        "contratoCuentaAperturaBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.contratoCuentaBanco" } },
        "formClienteBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioClienteBanco" } },
        "formW8BenBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW8BenBanco" } },
        "formW9Banco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioW9Banco" } },
        "formMoneylineBanco": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioMoneylineBanco" } },
        "solicitudPrestamo": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.solicitudPrestamoDL" } },
    },
    sso: {
        "classNames": "personalNonCCD-upload",
        "ui:field": "HideObjectField",
        "soporteSujetoObligadoFile": { "ui:field": "FileField", "joli:hideAttr": { "ifEmpty": "dct.formularioSujetoObligado" } },
    }
};

form.objectMap = {
    ...nonccdp.objectMap,
    //    puesto
    "clients[$idx].retailClientDueDiligenceReport.puestoCardSignatureFileId": "dfp.tarjetaFirmaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoInvestorReportFileId": "dfp.informeInversionistaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoMarkContractFileId": "dfp.contratoMarcoPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoAccountContractFileId": "dfp.contratoCuentaAperturaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoAccountOpeningLetterFileId": "dfp.cartaAutoApertCuentaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoClientFormFileId": "dfp.formClientePuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoW8BenFileId": "dfp.formW8BenPuesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoW9FileId": "dfp.formW9Puesto",
    "clients[$idx].retailClientDueDiligenceReport.puestoMoneylineFileId": "dfp.formMoneylinePuesto",
    //    safi
    "clients[$idx].retailClientDueDiligenceReport.safiCardSignatureFileId": "dfs.tarjetaFirmaSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiInvestorReportFileId": "dfs.informeInversionistaSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiMarkContractFileId": "dfs.contratoMarcoSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiClientFormFileId": "dfs.formClienteSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiW8BenFileId": "dfs.formW8BenSafi",
    "clients[$idx].retailClientDueDiligenceReport.safiW9FileId": "dfs.formW9Safi",
    "clients[$idx].retailClientDueDiligenceReport.safiMoneylineFileId": "dfs.formMoneylineSafi",
    //    banco
    "clients[$idx].retailClientDueDiligenceReport.bancoCardsignatureFileId": "dfb.tarjetaFirmaBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoMarkContractFileId": "dfb.contratoMarcoBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoAccountContractFileId": "dfp.contratoCuentaAperturaPuesto",
    "clients[$idx].retailClientDueDiligenceReport.bancoClientFormFileId": "dfb.formClienteBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoW8BenFileId": "dfb.formW8BenBanco",
    "clients[$idx].retailClientDueDiligenceReport.bancoW9FileId": "dfb.formW9Banco",
    "clients[$idx].retailClientDueDiligenceReport.bancoMoneylineFileId": "dfb.formMoneylineBanco",

    "clients[$idx].retailClientDueDiligenceReport.soporteSujetoObligadoFileId": "sso.soporteSujetoObligadoFile",
    //    loan
    "clients[$idx].retailClientLoanInfo.applicationFileId": "sso.soporteSujetoObligadoFile",
}


export default form;
