import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input } from "reactstrap";
import { useDebouncedEffect } from "../../hooks/useDebounceEffect";


export default function TextArea({
    id,
    schema,
    uiSchema,
    value,
    placeholder,
    required,
    disabled,
    readonly,
    autofocus,
    onChange: onChangeForm,
    onKeyChange,
    onBlur,
    onFocus,
}) {
    const [current, setCurrent] = useState(value);

    useDebouncedEffect(() => {
        if (current !== value) {
            onChangeForm(current)
        }
    }, [current], 450);

    const rows = (uiSchema['ui:options'] || {}).rows || 3;
    useEffect(() => setCurrent(value), [value]);

    return (
        <FormGroup>
            <Input
                disabled={disabled}
                type="textarea"
                className="custom"
                value={current || ""}
                required={required}
                onChange={(e) => setCurrent(e.target.value)}
                readOnly={readonly}
                autoFocus={autofocus}
                id={id}
                rows={rows}
            />
        </FormGroup>
    );
}