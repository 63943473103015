import dropdownOptions from '../util/dropdownOptions';

import {
    clientTypeId,
    preferredBranchId,
    boolYesNoType,
    tipoIdentificacionComercial,
    financialCertBusinessSchema,
    leadChannel,
    tipoDeEntidad,
    contractSigner
} from './commons';

const form = {
    title: "Orientación",
    displayName: 'ShortKYCCompanyClient',
    page: 'short-kyc-business',
    commentFieldsMap: {
        "LoanComment": "caseLoanInfo.comments",
    },
};

const savingsAccountSchema = {
    title: " ",
    description: "Cuenta de Ahorro",
    type: "object",
    properties: {
        "rdCurrency": {
            title: "RD$",
            type: "boolean",
        },
        "usCurrency": {
            title: "US$",
            type: "boolean",
        }
    },
    dependencies:{
        "rdCurrency":{
            oneOf: [
                {
                    properties: {
                        "rdCurrency": {
                            const: true
                        },
                        "depositoEstimadoRD": {
                            title: "Monto estimado primer depósito en RD",
                            type: "number",
                        },
                        "origenFondosRD": {
                            title: "Origen de fondos en RD",
                            type: "string",
                            enumNames: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehículo",
                                "Liquidación de otra inversión",
                                "Otro",
                                "No aplica"
                            ],
                            enum: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehiculo",
                                "Liquidacion de otra inversion",
                                "Otro",
                                "No aplica"
                            ],
                        },
                    },
                    dependencies: {
                        "origenFondosRD": {
                            allOf: [
                                {
                                    if: {
                                        properties: {
                                            "origenFondosRD": {
                                                const: "Otro",
                                            },
                                        }
                                    },
                                    then: {
                                        properties: {
                                            "origenFondosRDOtro": {
                                                title: "Otro",
                                                type: "string"
                                            },
                                        },
                                        required: ["origenFondosRDOtro"]
                                    }
                                }
                            ]
                        },
                    },
                    required: ["depositoEstimadoRD", "origenFondosRD"]
                },
                {
                    properties: {
                        "rdCurrency": {
                            const: false
                        },
                    }
                }    
            ]
        },
        "usCurrency":{
            oneOf: [
                {
                    properties: {
                        "usCurrency": {
                            const: true
                        },
                        "depositoEstimadoUSD": {
                            title: "Monto estimado primer depósito en USD",
                            type: "number",
                        },
                        "origenFondosUSD": {
                            title: "Origen de fondos en USD",
                            type: "string",
                            enumNames: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehículo",
                                "Liquidación de otra inversión",
                                "Otro",
                                "No aplica"
                            ],
                            enum: [
                                "Ahorros provenientes del salario",
                                "Ahorros por ingresos regulares",
                                "Herencia",
                                "Regalo",
                                "Venta de inmueble",
                                "Venta de vehiculo",
                                "Liquidacion de otra inversion",
                                "Otro",
                                "No aplica"
                            ],
                        },
                    },
                    dependencies: {
                        "origenFondosUSD": {
                            allOf: [
                                {
                                    if: {
                                        properties: {
                                            "origenFondosUSD": {
                                                const: "Otro",
                                            },
                                        }
                                    },
                                    then: {
                                        properties: {
                                            "origenFondosUSDOtro": {
                                                title: "Otro",
                                                type: "string"
                                            },
                                        },
                                        required: ["origenFondosUSDOtro"]
                                    }
                                }
                            ]
                        },
                    },
                    required: ["depositoEstimadoUSD", "origenFondosUSD"]
                },
                {
                    properties: {
                        "usCurrency": {
                            const: false
                        },
                    }
                }    
            ]
        }
    }
}

const prestamosSchema = {
    title: "Préstamos",
    type: "object",
    properties: {

        "prestamoGarante": { title: "¿El préstamo tiene garante?", ...boolYesNoType },
        "montoPrestamo": {
            title: "Monto deseado de préstamo",
            type: "number",
        },
        "plazo": {
            title: "Plazo en meses",
            type: "number",
        },
        "destinoFondos": {
            title: "Destino de los fondos",
            type: "string",
        }
    },
}

const solicitudPrestamoSchema = {
    title: " ",
    description: "Solicitud Préstamo",
    type: "object",
    properties: {
        "facilidadesSolicitadas": {
            title: " ",
            description: "Facilidades solicitadas",
            type: "object",
            properties: {
                "proposito": {
                    title: "Propósito",
                    type: "string",
                },
                "descripcionFacilidades": {
                    title: " ",
                    description: "Descripción de facilidades (sólo aplica para líneas de crédito)",
                    type: "array",
                    maxItems: 5,
                    items: {
                        type: "object",
                        properties: {
                            "numFacilidad": {
                                title: "No. de Facilidad",
                                type: "string",
                            },
                            "descripcion": {
                                title: "Descripción",
                                type: "string",
                            },
                            "tipoFacilidad": {
                                title: "Tipo de Facilidad",
                                type: "string",
                                enumNames: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nómina",
                                    "Hipotecario",
                                ],
                                enum: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nomina",
                                    "Hipotecario",
                                ],
                            },
                            "montoAprobado": {
                                title: "Monto Aprobado (si aplica)",
                                type: "string",
                            },
                            "montoUtilizado": {
                                title: "Monto Utilizado (si aplica)",
                                type: "string",
                            },
                            "montoPropuesto": {
                                title: "Monto Propuesto",
                                type: "string",
                            },
                            "tasa": {
                                title: "Tasa",
                                type: "number",
                            },
                            "formaPago": {
                                title: "Forma de Pago",
                                type: "string",
                                enumNames: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                                enum: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                            },
                            "cuotaEstimada": {
                                title: "Cuota Estimada",
                                type: "string",
                            },
                            "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
                        },
                    },
                },
                "incluyeGarantia": { title: "¿Incluye garantía?", ...boolYesNoType },
                "comentarios": {
                    title: "Comentarios",
                    type: "string",
                }
            },
            dependencies: {
                "incluyeGarantia": {
                    allOf: [{
                        if: { properties: { "incluyeGarantia": { const: true } } },
                        then: {
                            properties: {
                                "incluyeGarantiaSolidaria": { title: "¿Incluye garantía solidaria?", ...boolYesNoType },
                                "descGarantias": {
                                    title: "Descripción de garantía",
                                    type: "object",
                                    properties: {
                                        "noDeGarantia": {
                                            title: "No. de Garantía",
                                            type: "string",
                                        },
                                        "noFacilidadAsociada":
                                        {
                                            title: "No. Facilidad asociada",
                                            type: "string"
                                        },
                                        "tipoDeGarantia": {
                                            title: " ",
                                            description: "Tipo de Garantía",
                                            type: "array",
                                            items: {
                                                type: "number",
                                                oneOf: [
                                                    { title: "Garantía Solidaria", const: 1 },
                                                    { title: "Descuento de Nómina", const: 2 },
                                                    { title: "Hipotecario", const: 3 },
                                                    { title: "Prendaria", const: 4 },
                                                    { title: "Certificado Financiero", const: 5 },
                                                    { title: "Vehículo", const: 6 },
                                                    { title: "Otra", const: 7 },
                                                ],
                                            },
                                            minItems: 1,
                                            uniqueItems: true,
                                            
                                        },
                                        "descGarantia":
                                        {
                                            title: "Descripción de garantía",
                                            type: "string",
                                        },
                                        "valGarantia":
                                        {
                                            title: "Valor de Garantía en Tasación",
                                            type: "string",
                                        },
                                    },
                                    dependencies: {
                                        "tipoDeGarantia": {
                                            allOf: [
                                                {
                                                    if: {
                                                        properties: { "tipoDeGarantia":{ contains: { const: 7 } }},
                                                    },
                                                    then: {
                                                        properties :{
                                                            "contratoElaboradoPor": contractSigner
                                                        },
                                                        required:["contratoElaboradoPor"]
                                                    }
                                                }
                                            ]
                                        },
                                    }
                                },
                            }
                        }
                    }]
                },
            }
        },
    },
}

const inversionesSchema = {
    title: "Inversiones en Puesto",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión Puesto en USD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para Puesto en USD",
            type: "string",
            enumNames: [
                "Ventas",
                "Tesorería",
                "Inversiones anteriores",
                "Otro",
            ],
            enum: [
                "Ventas",
                "Tesoreria",
                "Inversiones anteriores",
                "Otro",
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Puesto en RD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para Puesto en RD",
            type: "string",
            enumNames: [
                "Ventas",
                "Tesorería",
                "Inversiones anteriores",
                "Otro",
            ],
            enum: [
                "Ventas",
                "Tesoreria",
                "Inversiones anteriores",
                "Otro",
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            exclusiveMinimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Especifique",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Especifique",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const safiSchema = {
    title: "Inversiones en SAFI",
    description: "",
    type: "object",
    properties: {
        "inversionEstimadaUSD": {
            title: "Monto estimado inversión SAFI en USD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosUSD": {
            title: "Origen de fondos para SAFI en USD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "inversionEstimadaRD": {
            title: "Monto estimado inversión SAFI en RD",
            type: "number",
            exclusiveMinimum: 0
        },
        "origenFondosRD": {
            title: "Origen de fondos para SAFI en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            exclusiveMinimum: 0
        },
    },
    dependencies: {
        "origenFondosUSD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosUSD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosUSDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosUSDOtro"]
                    }
                }
            ]
        },
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        },
                        required: ["origenFondosRDOtro"]
                    }
                }
            ]
        },
    },
    required: ["tasaCambio", "inversionEstimadaUSD", "origenFondosUSD", "inversionEstimadaRD", "origenFondosRD"]
}

const perfilClienteSchema = {
    title: "Perfil del cliente",
    type: "object",
    properties: {
        "tipoEntidad": {
            ...tipoDeEntidad
        },
        "accionistaPersonaJuridica20PorcientoAcciones": {
            title: "¿Es alguno de los accionistas una Persona jurídica con más de 20% de acciones?",
            ...boolYesNoType
        },
        "accionistaPersonaExpuestaPoliticamente": {
            title: "¿Algún socio, accionista, miembro de la junta de directores o representante legal es o ha sido persona expuesta políticamente (PEP)?",
            ...boolYesNoType
        },
    },
    required: ["tipoEntidad"],
}

const kycCommercialClientSchema = {
    title: " ",
    type: "object",
    properties: {
        "institucionVincularse": {
            title: "Institución a vincularse",
            type: "array",
            items: {
                type: "number",
                oneOf: [
                    { title: "Banco", const: 1 },
                    { title: "Puesto", const: 2 },
                    { title: "SAFI", const: 3 },
                ],
            },
            minItems: 1,
            uniqueItems: true,
        },
    },
    dependencies: {
        "institucionVincularse": {
            allOf: [
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 1 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosBanco": {
                                title: "Productos de interés Banco",
                                type: "array",
                                lookup: {
                                    api: 'products/1',
                                    params: {
                                        clientType: { field: 'clientTypeId' }
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                items: { type: "number" },
                                minItems: 1,
                                uniqueItems: true,
                            },
                        },
                        dependencies: {
                            "productosBanco": {
                                allOf: [
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 1 } } // "Cuenta de Ahorro"
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "cuentaAhorroBasica": { title: "¿Es Cuenta de Ahorro Básica?", ...boolYesNoType },
                                                "savingsAccountSchema": savingsAccountSchema,
                                            },
                                            dependencies: {
                                                "cuentaAhorroBasica": {
                                                    oneOf: [{
                                                        properties: {
                                                            "cuentaAhorroBasica": { const: true },
                                                            "soloMantieneEstaCuenta": { title: "¿Cliente solo mantiene esta cuenta?", ...boolYesNoType }
                                                        }
                                                    },
                                                    {
                                                        properties: {
                                                            "cuentaAhorroBasica": { const: false },
                                                        }
                                                    }]
                                                }
                                            }
                                        },
                                        else: {
                                            properties: {
                                                "excepcionCuentaAhorro": {
                                                    title: "Razón por la que no desea cuenta de ahorro:",
                                                    type: "string",
                                                    enum: ["Ya posee cuenta en JMMB", "No desea el producto"]
                                                },
                                            }
                                        }
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 17 } } // "Prestamo Comercial"
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "prestamos": prestamosSchema,
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": {
                                                    contains: { const: 3 } // "Certificado Financiero"
                                                },
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "financialCertSchema": financialCertBusinessSchema,
                                            },
                                        },
                                    },
                                    {
                                        if: {
                                            properties: {
                                                "productosBanco": { contains: { const: 7 } },  // Línea de Crédito
                                            }
                                        },
                                        then: {
                                            properties: {
                                                "prestamos": prestamosSchema,
                                                "solicitudPrestamoSchema": solicitudPrestamoSchema,
                                            },
                                        },
                                    },
                                ]
                            }
                        }
                    }
                },
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 2 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosPuesto": {
                                title: "Productos de interés Puesto",
                                type: "array",
                                lookup: {
                                    api: 'products/2',
                                    params: {
                                        clientType: { field: 'clientTypeId' },
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                minItems: 1,
                                items: { type: "number" },
                                uniqueItems: true,
                            },
                            "inversionesSchema": inversionesSchema
                        }
                    }
                },
                {
                    if: {
                        properties: {
                            "institucionVincularse": {
                                contains: { const: 3 }
                            },
                        }
                    },
                    then: {
                        properties: {
                            "productosSAFI": {
                                title: "Productos de interés SAFI",
                                type: "array",
                                lookup: {
                                    api: 'products/3',
                                    params: {
                                        clientType: { field: 'clientTypeId' },
                                    },
                                    resource: "Productos",
                                    id: 'id',
                                    label: 'name'
                                },
                                minItems: 1,
                                items: { type: "number" },
                                uniqueItems: true,
                            },
                            safiSchema
                        },
                    }
                },
            ]
        }
    }
}

const comercialSchema = {
    title: "Información Cliente Empresa",
    type: "object",
    properties: {
        "nombreEmpresa": {
            title: "Nombre Empresa",
            type: "string",
        },
        "actividadPrincipal": {
            title: "Actividad Principal",
            type: "string",
            enum: dropdownOptions.actividades,
        },
        tipoIdentificacionComercial,
        "numIdentificacion": {
            title: "No. de identificación RNC",
            type: "string",
            minLength: 9,
            maxLength: 9,
        },
        "buscarDataClient":{
            title:"Buscar datos de cliente por ID",
            type:"string",
        },
        "nombreContacto": {
            title: "Nombre Contacto",
            type: "string",
        },
        "apellidoContacto": {
            title: "Apellido Contacto",
            type: "string",
        },
        "posicionContacto": {
            title: "Posición Contacto",
            type: "string",
        },
        "celular": {
            title: "Celular",
            type: "string",
            format: "phone-rd"
        },
        "telefono": {
            title: "Teléfono",
            type: "string",
            format: "phone-rd",
        },
        "email": {
            title: "Email",
            type: "string",
        },
        "comentarios": leadChannel
    },
    required: ["nombreEmpresa", "actividadPrincipal", "tipoIdentificacionComercial",
        "numIdentificacion", "nombreContacto", "apellidoContacto",
        "posicionContacto", "celular", "email"
    ],
}

form.schema = {
    title: "Solicitud de Vinculación Cliente Empresa",
    type: "object",
    properties: {
        clientTypeId,
        preferredBranchId
    },
    required: ["clientTypeId", "preferredBranchId"],
    dependencies: {
        "clientTypeId": {
            oneOf: [
                {
                    properties: {
                        "clientTypeId": {
                            const: 3,
                        },
                        "kycCommercialClientSchema": kycCommercialClientSchema,
                        "comercialSchema": comercialSchema,
                        "perfilClienteSchema": perfilClienteSchema
                    }
                },
                {
                    properties: {
                        "clientTypeId": {
                            const: 2,
                        },
                        "kycCommercialClientSchema": kycCommercialClientSchema,
                        "comercialSchema": comercialSchema,
                        "perfilClienteSchema": perfilClienteSchema
                    }
                },
            ]
        }
    }
};



form.uiSchema = {
    "preferredBranchId": { "ui:field": "LookupFormField", "ui:readonly": true },
    "clientTypeId": {
        "ui:widget": "radio",
        "ui:readonly": true,
        "ui:options": {
            inline: true
        },
    },
    "comercialSchema": {
        "classNames": "smallTwoRows",
        "ui:order": [
            "nombreEmpresa",
            "actividadPrincipal",
            "tipoIdentificacionComercial",
            "numIdentificacion",
            "numIdentificacionCedula",
            "buscarDataClient",
            "*"
        ],
        "tipoIdentificacionComercial": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "numIdentificacionCedula": {
            "ui:widget": "CedulaWidget",
        },
        "buscarDataClient":{
            "ui:field": "FetchClientInfoButton",
        },
        "nombreContacto": {
            "classNames": "from-col-1",
        },
        "celular": {
            "ui:widget": "PhoneWidget",
        },
        "telefono": {
            "ui:widget": "PhoneWidget",
        }
    },

    "perfilClienteSchema": {
        "sujetoObligadoLey155": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "inversionistaInstitucional": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "accionistaPersonaJuridica20PorcientoAcciones": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "accionistasCiudadanoResidenteNorteamericano": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
        "accionistaPersonaExpuestaPoliticamente": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "kycCommercialClientSchema": {
        "classNames": "smallThreeRows",
        "ui:order": [
            "institucionVincularse",
            "productosBanco",
            "productosPuesto",
            "productosSAFI",
            "*",
        ],
        "savingsAccountSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "depositoEstimadoRD": { "classNames": "from-col-1 title-2-lines" },
            "origenFondosRD": { "classNames": "title-2-lines" },
            "depositoEstimadoUSD": { "classNames": "from-col-1 title-2-lines" },
            "origenFondosUSD": { "classNames": "title-2-lines" },
            "origenFondosRDOtro": { "classNames": "title-2-lines" },
            "origenFondosUSDOtro": { "classNames": "title-2-lines" }
        },
        "institucionVincularse": {
            "classNames": "from-col-1 to-col-4",
            "ui:widget": "checkboxes",
            "ui:options": { inline: true },
        },
        "productosBanco": {
            "classNames": "from-col-1",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": {
                inline: false
            },
        },
        "cuentaAhorroBasica": {
            "classNames": "from-col-1",
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "soloMantieneEstaCuenta": {
            "ui:widget": "radio",
            "ui:options": {
                inline: false
            },
        },
        "productosPuesto": {
            "classNames": "from-col-2",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },
        "productosSAFI": {
            "classNames": "from-col-3",
            "ui:field": "LookupMultiSelectCheckboxesFormField",
            "ui:options": { inline: false },
        },
        "inversionesSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio",
            ],
            "inversionEstimadaUSD": { "classNames": "title-2-lines" },
            "origenFondosUSD": { "classNames": "title-2-lines" },
            "origenFondosUSDOtro": { "classNames": "title-2-lines" },
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "classNames": "from-col-1",
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "safiSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "ui:order": [
                "*",
                "inversionEstimadaUSD",
                "origenFondosUSD",
                "origenFondosUSDOtro",
                "inversionEstimadaRD",
                "origenFondosRD",
                "origenFondosRDOtro",
                "tasaCambio"
            ],
            "inversionEstimadaRD": {
                "classNames": "from-col-1",
            },
            "tasaCambio": {
                "classNames": "from-col-1",
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
        "prestamos": {
            "classNames": "smallTwoRows from-col-1 to-col-4",
            "prestamoGarante": {
                "ui:widget": "radio",
                "ui:options": {
                    inline: true
                },
            },
        },
        "solicitudPrestamoSchema": {
            "classNames": "from-col-1 to-col-4",
            "facilidadesSolicitadas": {
                "ui:order": [
                    "*",
                    "incluyeGarantiaSolidaria",
                    "comentarios",
                    "descGarantias"
                ],
                "proposito": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 3
                    },
                },
                "incluyeGarantia": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "comentarios": {
                    "ui:widget": "textarea",
                    "ui:options": {
                        rows: 3
                    },
                },
                "incluyeGarantiaSolidaria": {
                    "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
                },
                "descGarantias":{
                    "descGarantia":{
                        "ui:widget": "textarea",
                        "ui:options": {
                            rows: 3
                        },
                    },
                    "tipoDeGarantia":{
                        "ui:widget": "checkboxes",
                    },
                    "contratoElaboradoPor":{
                        "ui:widget": "radio"
                    },
                    "ui:order": [
                        "tipoDeGarantia",
                        "contratoElaboradoPor",
                        "*"
                    ],
                },
            },
        },
        "financialCertSchema": {
            "classNames": "from-col-1 to-col-4 smallThreeRows",
            "tasaCambio": {
                "ui:help": "Sólo poner valor numérico, sin símbolos."
            }
        },
    },
}
form.objectMap = {
    "clients[0].clientTypeId": "clientTypeId",
    "clients[0].preferredBranchId": "preferredBranchId",

    "productInstitutions": "kycCommercialClientSchema.institucionVincularse",
    "productIds.1": "kycCommercialClientSchema.productosBanco",
    "productIds.2": "kycCommercialClientSchema.productosPuesto",
    "productIds.3": "kycCommercialClientSchema.productosSAFI",

    "clients[0].businessClientBasicInfo.businessName": "comercialSchema.nombreEmpresa",
    "clients[0].businessClientBasicInfo.businessMainActivity": "comercialSchema.actividadPrincipal",
    "clients[0].businessClientBasicInfo.identificationType": "comercialSchema.tipoIdentificacionComercial",
    "clients[0].businessClientBasicInfo.contactFirstName": "comercialSchema.nombreContacto",
    "clients[0].businessClientBasicInfo.contactLastName": "comercialSchema.apellidoContacto",
    "clients[0].businessClientBasicInfo.contactPosition": "comercialSchema.posicionContacto",
    "clients[0].businessClientBasicInfo.phone": "comercialSchema.telefono",
    "clients[0].businessClientBasicInfo.phoneMobile": "comercialSchema.celular",
    "clients[0].businessClientBasicInfo.contactEmail": "comercialSchema.email",
    "clients[0].businessClientBasicInfo.comments": "comercialSchema.comentarios",
    "clients[0].businessClientBasicInfo.identificationNumber": "comercialSchema.numIdentificacion",
    "clients[0].businessClientBasicInfo.entityType": "perfilClienteSchema.tipoEntidad",
    "clients[0].businessClientBasicInfo.isLegalOwnerOf20Pct": "perfilClienteSchema.accionistaPersonaJuridica20PorcientoAcciones",
    "clients[0].businessClientBasicInfo.hasPoliticalExposure": "perfilClienteSchema.accionistaPersonaExpuestaPoliticamente",
    "clients[0].businessClientBasicInfo.rdsAmountSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.depositoEstimadoRD",
    "clients[0].businessClientBasicInfo.rdsFundsOriginSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosRD",
    "clients[0].businessClientBasicInfo.rdsFundsOriginOtherSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosRDOtro",
    "clients[0].businessClientBasicInfo.hasBasicSaving": "kycCommercialClientSchema.cuentaAhorroBasica",
    "clients[0].businessClientBasicInfo.onlyMaintainsThisAccount": "kycCommercialClientSchema.soloMantieneEstaCuenta",
    "clients[0].businessClientBasicInfo.savingsException": "kycCommercialClientSchema.excepcionCuentaAhorro",
    "clients[0].businessClientBasicInfo.rdsSelection": "kycCommercialClientSchema.savingsAccountSchema.rdCurrency",
    "clients[0].businessClientBasicInfo.usdSelection": "kycCommercialClientSchema.savingsAccountSchema.usCurrency",
    "clients[0].businessClientBasicInfo.usdAmountSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.depositoEstimadoUSD",
    "clients[0].businessClientBasicInfo.usdFundsOriginSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosUSD",
    "clients[0].businessClientBasicInfo.usdFundsOriginOtherSavingsAccount": "kycCommercialClientSchema.savingsAccountSchema.origenFondosUSDOtro",

    "caseLoanInfo.loanHasGuarantor": "kycCommercialClientSchema.prestamos.prestamoGarante",
    "caseLoanInfo.loanAmount": "kycCommercialClientSchema.prestamos.montoPrestamo",
    "caseLoanInfo.loanDestination": "kycCommercialClientSchema.prestamos.destinoFondos",
    "caseLoanInfo.includesCollateral": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.incluyeGarantia",
    "caseLoanInfo.comments": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.comentarios",
    "caseLoanInfo.includesJointGuarrantee": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.incluyeGarantiaSolidaria",

    "clients[0].businessClientLoanInfo.warrantyNumber": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.noDeGarantia",
    "clients[0].businessClientLoanInfo.numAssociatedFacilities": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.noFacilidadAsociada",
    "clients[0].businessClientLoanInfo.warrantyDescription": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.descGarantia",
    "clients[0].businessClientLoanInfo.value": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.valGarantia",

    "clients[0].businessClientLoanInfo.selectedWarranties": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.tipoDeGarantia",
    "clients[0].businessClientLoanInfo.contractDrafter": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descGarantias.contratoElaboradoPor",

    "clients[0].businessClientLoanInfo.termInMonths": "kycCommercialClientSchema.prestamos.plazo",
    "clients[0].businessClientLoanInfo.purpose": "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.proposito",

    "clients[0].businessClientLoanInfo.businessLoanFacilities": {
        array: "kycCommercialClientSchema.solicitudPrestamoSchema.facilidadesSolicitadas.descripcionFacilidades",
        map: {
            "number": "numFacilidad",
            "description": "descripcion",
            "facilityType": "tipoFacilidad",
            "amountApproved": "montoAprobado",
            "amountUsed": "montoUtilizado",
            "amountBudgeted": "montoPropuesto",
            "rate": "tasa",
            "payment": "formaPago",
            "estimatedQuote": "cuotaEstimada",
            "willBeCancelled": "seraCancelado"
        }
    },

    // Puesto

    "caseInvestmentInfo.usdAmountPuesto": "kycCommercialClientSchema.inversionesSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountPuesto": "kycCommercialClientSchema.inversionesSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherPuesto": "kycCommercialClientSchema.inversionesSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRatePuesto": "kycCommercialClientSchema.inversionesSchema.tasaCambio",

    // Certificado Financiero

    "caseInvestmentInfo.rdsAmountCertificate": "kycCommercialClientSchema.financialCertSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginCertificate": "kycCommercialClientSchema.financialCertSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherCertificate": "kycCommercialClientSchema.financialCertSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateCertificate": "kycCommercialClientSchema.financialCertSchema.tasaCambio",

    // SAFI

    "caseInvestmentInfo.usdAmountSAFI": "kycCommercialClientSchema.safiSchema.inversionEstimadaUSD",
    "caseInvestmentInfo.usdFundsOriginSAFI": "kycCommercialClientSchema.safiSchema.origenFondosUSD",
    "caseInvestmentInfo.usdFundsOriginOtherSAFI": "kycCommercialClientSchema.safiSchema.origenFondosUSDOtro",
    "caseInvestmentInfo.rdsAmountSAFI": "kycCommercialClientSchema.safiSchema.inversionEstimadaRD",
    "caseInvestmentInfo.rdsFundsOriginSAFI": "kycCommercialClientSchema.safiSchema.origenFondosRD",
    "caseInvestmentInfo.rdsFundsOriginOtherSAFI": "kycCommercialClientSchema.safiSchema.origenFondosRDOtro",
    "caseInvestmentInfo.exchangeRateSAFI": "kycCommercialClientSchema.safiSchema.tasaCambio",

}


export default form;