import React, { useState, useEffect, useContext } from 'react';
import { FormGroup, Label, Row, Col, Input, Button, Card, CardHeader, CardBody, Form } from "reactstrap";

import LookupApi from "../../api/LookupApi";
import CasesApi from "../../api/CasesApi";
import { AuthContext } from "../../context/AuthContext";
import useResourceLoader from "../../util/useResourceLoader";

const UserSearchBar = ({
    filters,
    onSearch,
    onClearSearch,
}) => {
    const { isAdmin } = useContext(AuthContext);
    const [current, setCurrent] = useState(filters);
    const [resetCt, setResetCt] = useState(0);

    useEffect(() => setCurrent(filters), [filters]);

    const [[institutions, clientTypes, roles, branches], loading, error] = useResourceLoader(() => Promise.all([
        LookupApi.getInstitutions(),
        LookupApi.getClientTypes(),
        LookupApi.getRoles(),
        LookupApi.getBranches()
    ]), [], [[], [], []]);

    const handleChange = (e) => {
        let returnedValue = e.target.value
        if (e.target.name === "before") {
            returnedValue += "T23:00:00Z"
        }
        const update = { [e.target.name]: returnedValue };
        setCurrent(Object.assign({}, current, update));
    }

    const handleCompleted = (e) => {
        const update = { 'completed': !current.completed };
        setCurrent(Object.assign({}, current, update));
    }

    const handleClosed = (e) => {
        const update = { 'closed': !current.closed };
        setCurrent(Object.assign({}, current, update));
    }

    const search = (e) => {
        e.preventDefault();
        onSearch(current);
    }

    function clearSearch(e) {
        e.preventDefault();
        setCurrent({});
        setResetCt(resetCt + 1);
        onClearSearch();
    }

    const products = institutions.reduce((p, c) => p.concat(c.products.map(prod => ({ id: prod.id, option: `${prod.name} (${c.name})` }))), []);
    return (<Form onSubmit={search}>
        <Card className="search-bar">
            <CardHeader>Filtrar Casos</CardHeader>
            <CardBody>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="firstName">Nombre</Label>
                            <Input key={resetCt} value={current.firstName} onChange={handleChange} id="firstName" name="firstName" />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="lastName">Apellido</Label>
                            <Input key={resetCt} value={current.lastName} onChange={handleChange} id="lastName" name="lastName" />
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <Label for="branch">Oficina</Label>
                            <Input value={current.branch} onChange={handleChange} id="branch" name="branch" type="select">
                                <option value="">Todos</option>
                                {(branches || []).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.name}</option>)}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {isAdmin && (
                        <Col md={4}>
                            <FormGroup>
                                <Label for="roleId">Rol</Label>
                                <Input key={resetCt} value={current.roleId} onChange={handleChange} id="roleId" name="roleId" type="select">
                                    <option value="">Todos</option>
                                    {(roles || []).map((ct, idx) => <option value={ct.id} key={ct.id}>{ct.displayName}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                    )}
                    {isAdmin && (
                        <Col md={4}>
                            <FormGroup>
                                <Label for="role">Activo</Label>
                                <Input key={resetCt} value={current.role} onChange={handleChange} id="active" name="active" type="select">
                                    {([
                                        { value: 1, label: 'Sí' },
                                        { value: 2, label: 'No' },
                                        { value: 0, label: 'Todos' },
                                    ]).map((ct, idx) => <option value={ct.value} key={ct.value}>{ct.label}</option>)}
                                </Input>
                            </FormGroup>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <Button color="primary" type="submit">Filtrar</Button>
                        {" "}
                        <Button color="secondary" onClick={clearSearch}>Limpiar Filtro</Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </Form>);
}

export default UserSearchBar;