import React, { useEffect, useContext } from 'react';
import CasesApi from "../../api/CasesApi";
import { WaitingForDocumentState } from "../../constants";
import RequestReloadContext from '../../context/RequestReloadContext';
import usePolledResource from '../../util/usePolledResource';
import Loader from './Loader';

let i = 0;
function incr(){ i+=1; return i;}

const WaitForCaseFiles = ({ currentCase, page }) => {
    const rrc = useContext(RequestReloadContext) || window.location;
    const [{status, readyCount, totalCount}] = usePolledResource({
        resourceFn: () => CasesApi.awaitDocuments({ caseId: currentCase.id, page }),
        dependencies: [currentCase.id, page],
        interval: 5000,
        initialValue: {}
    });

    useEffect(() => {
        if (status === WaitingForDocumentState.AVAILABLE) {
            rrc.reload();
        }
    }, [status, rrc]);

    return (
        <div className="d-flex justify-content-center flex-column text-center">
            <Loader big />
            <h4>
                Generando Documentos
            </h4>
        </div>
    )
}

export default WaitForCaseFiles;