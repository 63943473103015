import React, { useMemo, useContext } from 'react';
import { Card, CardBody, CardHeader, List, Button } from 'reactstrap';
import FileApi from "../api/FileApi";
import LookupApi from "../api/LookupApi";
import { DATE_FORMAT, TIME_FORMAT } from "../constants";
import { toDrDateTime } from "../util/dates";
import useResourceLoader from "../util/useResourceLoader";
import { AuthContext } from '../context/AuthContext';


const CaseDetailsPanel = ({current}) => {
    const [institutions] = useResourceLoader(() => LookupApi.getInstitutions(), [], []);
    const [files] = useResourceLoader(() => Promise.all(getKYCs().map(async ({ name, fId }) => {
        const file = await FileApi.getFile({ fileId: fId });
        return { name, file };
    })), [current], [])

    const getKYCs = () => {
        const kycs = [];
        ((current || {}).clients || []).forEach((c) => {
            const { clientTypeId, retailClientDueDiligenceReport: rcdd } = c;
            switch (clientTypeId) {
                case 2:
                case 3:
                    break;
                case 1:
                case 4:
                default:
                    if (rcdd.bancoClientFormFileId) kycs.push({
                        name: 'Conozca a su Cliente (Banco)',
                        fId: rcdd.bancoClientFormFileId
                    });
                    if (rcdd.safiClientFormFileId) kycs.push({
                        name: 'Conozca a su Cliente (SAFI)',
                        fId: rcdd.safiClientFormFileId
                    });
                    if (rcdd.puestoClientFormFileId) kycs.push({
                        name: 'Conozca a su Cliente (Puesto)',
                        fId: rcdd.puestoClientFormFileId,
                    });
                    break;
            }
        });
        return kycs;
    }

    if (!(current || {}).id) return null;

    const mappedProducts = useMemo(() => institutions.filter(
        inst => current.productIds[inst.id]
    ).reduce((_, inst) => {
        const productList = current.productIds[inst.id];
        _[inst.name] = inst.products.filter(p => productList.includes(p.id)).map(p => p.name);
        return _;
    }, {}), [current.productIds, institutions]);

    const showIdForRetail = (client) => {
        switch (client.retailClientBasicInfo.identificationType) {
            case 3:
                return `Id Extranjero: ${client.retailClientBasicInfo.foreignIdNumber}`;
            case 2:
                return `Pasaporte: ${client.retailClientBasicInfo.passportNumber}`;
            default:
            case 1:
                return `ID: ${client.retailClientBasicInfo.identificationNumber}`;
        }
    }

    const auth = useContext(AuthContext);
    const { limitColumnAndFilterView } = ((auth || {}).user || {}).permissions || {};


    return (
        <Card className="form-details">
            <CardHeader>
                Caso #{current.id}
            </CardHeader>
            <CardBody>
                <div className="d-flex align-items-center">
                    <div className="mr-2"><i className="glyphicon glyphicon-calendar" /></div>
                    <div>
                        <div>{toDrDateTime(current.createdAt).format(DATE_FORMAT)}</div>
                        <div>{toDrDateTime(current.createdAt).format(TIME_FORMAT)}</div>
                    </div>
                </div>
            </CardBody>
            <CardHeader>
                {current.clients.length > 1 ? 'Clientes': 'Cliente'}
            </CardHeader>
            <CardBody>
            {current.clients.map((c, idx) => <React.Fragment key={idx}>{(c.clientTypeId === 1 || c.clientTypeId === 4) ? (<>
                <div>{c.retailClientBasicInfo.firstName} {c.retailClientBasicInfo.secondName} {c.retailClientBasicInfo.lastName}</div>
                <div>{showIdForRetail(c)}</div>
            </>) : (<>
                <div>{c.businessClientBasicInfo.businessName}</div>
                <div>{c.businessClientBasicInfo.identificationNumber}</div>
            </>)}</React.Fragment>)}
            </CardBody>
            {!limitColumnAndFilterView ? (<>        
                {Object.keys(mappedProducts).length ? (<>
                    <CardHeader>Productos</CardHeader>
                    <CardBody>{Object.entries(mappedProducts).map(([k, products], idx) => (
                        <div key={idx}>
                            <div>{k}</div>
                            <div className="ml-4">{products.map((p) => (
                                <div key={p}>{p}</div>
                            ))}</div>
                        </div>
                    ))}</CardBody>
                </>) : null}
                {files.length ? (<>
                    <CardHeader>Documentos</CardHeader>
                    <CardBody>{files.map(({ name, file }) => (
                        <div className="btn btn-secondary btn-block">
                            <a href={file.url} target="_blank" rel="noreferrer" className="text-white">{name}</a>
                        </div>
                    ))}</CardBody>
                </>): null}
            </>) : null}
        </Card>
    );
}

export default CaseDetailsPanel;