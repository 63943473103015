import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import CasesApi from '../api/CasesApi';

import customFormats from '../util/customFormats';
import FormComponent from '../components/FormComponent';
import { getObject, interpolateMap } from '../util/mapObject';
import { useSideChannel } from '../util/useSideChannel';
import formValidation from '../util/formValidation';

import Effect from '../components/Effect';
import HandleCaseUpdate from '../components/HandleCaseUpdate';
import CaseFormNavigationPanel from "./CaseFormNavigationPanel";

import { FORM_SUBMIT_BUTTONS, FORM_ONLY_SUBMIT_BUTTON, FORM_BUTTONS_GENERATE_DOCS, FORM_ONLY_SAVE_BUTTON } from '../constants';
import CaseClientTabs from './CaseClientTabs';
import JointCaseSubmitButton from './JointCaseSubmitButton';
import { AuthContext } from '../context/AuthContext';


function CaseFormComponent({
    page,
    buildFormContext,
    isSentBack,
    secondaryClientForm,
    generateDocuments,
    renderFormSubmitted: propRenderFormSubmitted,
    noSubmit,
    canAddClients,
    hasSaveProgressButton = true,
    commentFieldsMap,
    enableOnlySave,
    ...props
}) {
    const submitButtons = noSubmit ? (
        {}
    ) : (hasSaveProgressButton ?
        FORM_SUBMIT_BUTTONS :
        (enableOnlySave ? FORM_ONLY_SAVE_BUTTON : FORM_ONLY_SUBMIT_BUTTON)
    );

    const hasSecondaryClients = !!secondaryClientForm;


    return FormComponent({
        noHtml5Validate: true,
        parseProps: ({ readonly, currentCase, onlySave }) => {
            const buttons = useMemo(() => {
                const btns = ({
                    ...(onlySave ? (
                        submitButtons.saveOnly ? { saveOnly: submitButtons.saveOnly } : {}
                    ) : submitButtons),
                    ...(generateDocuments ? FORM_BUTTONS_GENERATE_DOCS : {})
                });

                const { submit } = btns;
                if (submit && hasSecondaryClients) {
                    submit.Component = JointCaseSubmitButton;
                    submit.btnProps = {};
                }

                return btns;
            }, [onlySave]);

            const auth = useContext(AuthContext);

            return ({
                readonly: readonly || Object.keys(buttons).length === 0,
                currentCase,
                auth,
                submitButtons: buttons,
                generateDocuments
            });
        },
        loadData: ({ currentCase }) => Promise.resolve(currentCase),
        buildFormContext: (...args) => {
            const { props } = args[0];
            const curCase = props.currentCase || {};
            const { user: auth = {} } = props.auth || {};
            const { user = {} } = auth;
            return ({
                auth,
                user,
                fileField: { postArgs: { caseId: (curCase || {}).id } },
                clientEmail: getObject(curCase, 'clients[0].retailClientBasicInfo.email'),
                clientFirstName: getObject(curCase, 'clients[0].retailClientBasicInfo.firstName'),
                clientLastName: getObject(curCase, 'clients[0].retailClientBasicInfo.lastName'),
                ...(buildFormContext ? buildFormContext(...args) : {})
            });
        },
        beforeRenderHook({
            currentFormData,
            saveCurrentFormObject,
            setFormDefinition,
            getCurrentFormObject,
            setExtraErrors,
            setFormObject,
            submitButtons,
            navRef,
            onSubmitRef,
        }) {
            const [clientIdx, _setClientIdx] = useState(null);
            const [clientErrors, setClientErrors] = useState([]);
            const setClientIdx = useCallback((idx) => {
                saveCurrentFormObject().then(() => {
                    console.log("setting clientIdx to", idx);
                    _setClientIdx(idx | 0);
                });
            }, [_setClientIdx, saveCurrentFormObject]);

            useEffect(() => {
                if (clientIdx === null) return;
                if (!hasSecondaryClients || clientIdx === 0) {
                    setFormDefinition();
                    setExtraErrors();
                } else {
                    const { schema, uiSchema, objectMap, commentFieldsMap } = secondaryClientForm;
                    setFormDefinition(
                        schema,
                        uiSchema,
                        interpolateMap(objectMap, { idx: clientIdx }),
                        commentFieldsMap,
                        clientIdx
                    );
                    setExtraErrors();
                }
            }, [clientIdx]);

            const addNewClient = useCallback(() => {
                const caseObj = getCurrentFormObject();
                const clients = (caseObj.clients || []).length === 0 ? [{}] : (caseObj.clients || []);
                const idx = clients.length;
                return saveCurrentFormObject({
                    ...caseObj,
                    clients: [...clients, {}]
                }).then(() => _setClientIdx(idx));
            }, [getCurrentFormObject, saveCurrentFormObject]);

            const removeClient = useCallback((idx) => {
                const caseObj = getCurrentFormObject();
                const clients = (caseObj.clients || []).length === 0 ? [{}] : (caseObj.clients || []);
                return saveCurrentFormObject({
                    ...caseObj,
                    clients: [...clients.slice(0, idx)]
                }).then(() => {
                    _setClientIdx(idx - 1)
                });
            }, [getCurrentFormObject, saveCurrentFormObject]);

            const validateCurrentClient = useCallback((object) => {
                const errorSchema = validateClient(
                    object === undefined ? getCurrentFormObject() : object,
                    clientIdx | 0,
                    props, secondaryClientForm
                );
                const hasErrors = !!Object.keys(errorSchema).length;
                console.log("validate current client : ", errorSchema);

                const newClientErrors = [...clientErrors];
                newClientErrors[clientIdx | 0] = hasErrors;

                if (hasErrors) {
                    setExtraErrors(errorSchema);
                } else {
                    setExtraErrors();
                }

                setClientErrors(newClientErrors);


                return !hasErrors;
            }, [clientIdx, getCurrentFormObject]);

            const validateAllClients = useCallback((object) => {
                object = object === undefined ? getCurrentFormObject() : object;

                // Short circuit validation on Send Back
                if (isSentBack) {
                    const sendingBack = isSentBack(object);
                    if (sendingBack) return true;
                }

                const validations = object.clients.map((cl, idx) => validateClient(
                    object,
                    idx,
                    props, secondaryClientForm
                ));
                const curIdx = clientIdx | 0;

                console.log('validations', validations);
                const clientErrors = validations.map(v => !!Object.keys(v).length);
                const hasErrors = clientErrors.some(v => v);

                if (clientErrors[curIdx]) {
                    setExtraErrors(validations[curIdx]);
                } else {
                    setExtraErrors();
                }

                setClientErrors(clientErrors);

                return !hasErrors;
            }, [clientIdx, getCurrentFormObject]);

            const cfSideChannel = useSideChannel();
            useEffect(() => {
                cfSideChannel.publish({
                    clientIdx,
                    currentFormData,
                    addNewClient,
                    removeClient,
                    setClientIdx,
                    saveCurrentFormObject,
                    validateCurrentClient,
                    validateAllClients,
                    canAddClients,
                    navRef,
                    onSubmitRef,
                });
            }, [
                clientIdx,
                currentFormData,
                setClientIdx,
                addNewClient,
                removeClient,
                saveCurrentFormObject,
                validateCurrentClient,
                validateAllClients,
                canAddClients,
                navRef,
                onSubmitRef,
            ]);

            if (submitButtons.submit && submitButtons.submit.btnProps) {
                submitButtons.submit.btnProps.cfSideChannel = cfSideChannel;
            }

            return {
                addNewClient,
                clientIdx: clientIdx || 0,
                clientErrors,
                setClientIdx,
                removeClient
            };
        },
        renderFormDetails: hasSecondaryClients ? ({ brh: {
            addNewClient,
            clientErrors,
            clientIdx, setClientIdx, removeClient
        }, sideChannel }) => (<CaseClientTabs
            addNewClient={addNewClient}
            selectedIndex={clientIdx}
            canAddClients={canAddClients}
            setSelectedIndex={setClientIdx}
            clientErrors={clientErrors}
            sideChannel={sideChannel}
            removeClient={removeClient}
        />) : undefined,
        renderNavigation({ title, navRef, formRef, hasUnsavedChanges }) {
            return !props.withoutNavigation ? (
                <CaseFormNavigationPanel
                    formTitle={title}
                    ref={navRef}
                    formElement={formRef && formRef.current}
                    hasUnsavedChanges={hasUnsavedChanges}
                />
            ) : null;
        },
        submitButtons,
        async onSubmit(
            { object, clientIdx, commentFieldsMap: curFieldMap },
            scope
        ) {
            const { currentCase } = scope.props;
            const commentFM = curFieldMap || commentFieldsMap;
            const comments = (commentFM ? Object.values(commentFM).map(objPath => {
                const cleanedPath = objPath.replace('$idx', clientIdx);
                const initVal = getObject(currentCase, cleanedPath);
                const currentVal = getObject(object, cleanedPath);
                return (initVal !== currentVal) ? currentVal : null;
            }).filter(c => c) : null);

            return (
                (currentCase && currentCase.id) ? (
                    CasesApi.updateCase({
                        id: currentCase.id,
                        page,
                        formData: {
                            ...object,
                            comments,
                            isSentBack: isSentBack ? isSentBack(object) : undefined
                        },
                    })
                ) : (
                    CasesApi.createCase({
                        newCase: {
                            ...object,
                            comments,
                        },
                    })
                )
            );
        },
        renderFormSubmitted: propRenderFormSubmitted || ((props) => (
            props.formSubmitResult.case ? (
                <HandleCaseUpdate {...props} />
            ) : (
                <Effect>{() => {
                    props.history.replace(`/cases/${props.formSubmitResult.id}?from=case-intake`);
                }}</Effect>
            )
        )),
        customFormats,
        ...props
    });
}


function validateClient(formObject, idx, props, secondaryClientForm) {
    idx = idx | 0;
    let schema = null;
    let uiSchema = null;
    let objectMap = null;

    if (!secondaryClientForm || idx === 0) {
        schema = props.schema;
        uiSchema = props.uiSchema;
        objectMap = props.objectMap;
    } else {
        schema = secondaryClientForm.schema;
        uiSchema = secondaryClientForm.uiSchema;
        objectMap = interpolateMap(secondaryClientForm.objectMap, { idx });
    }

    return formValidation(formObject, schema, uiSchema, objectMap, customFormats);
}


export default CaseFormComponent

