

export const clientTypeId = {
    title: "Tipo de Cliente",
    type: "number",
    oneOf: [
        { title: "Comercial", const: 3 },
        { title: "PYME - RNC", const: 2 },
        { title: "PYME - Cédula", const: 4 },
        { title: "Personal", const: 1 },
    ]
};

export const leadChannel = {
        title: "Canal de entrada del lead",
        type: "string",
        enumNames: ["Instagram", "LinkedIn", "Página Web", "SMS", "Teléfono", "Twitter", "Visita Presencial", "Whatsapp", "Referido"],
        enum: ["Instagram", "LinkedIn", "Pagina Web", "SMS", "Telefono", "Twitter", "Visita Presencial", "Whatsapp", "Referido"],
}


export const multiFileUploadField = {
    schema: {
        title: " ",
        type: "array",
        items: {
            type: "object",
            properties: { file: { title: "", type: "number" }, }
        }
    },
    uiSchema: {
        "classNames": "array-field-vertical",
        "items": { "file": { "ui:field": "FileField" } }
    },
    mapping: (target) => ({
        array: target,
        map: { "fileId": "file" }
    })
};

export const tipoIdentificacionPersonal = {
    title: "Tipo de Identificación",
    type: "number",
    oneOf: [
        { title: "Cédula", const: 1 },
        { title: "Pasaporte", const: 2 },
        { title: "Id Extranjero", const: 3 },
    ],
};

export const tipoIdentificacionComercial = {
    title: "Tipo de Identificación",
    type: "number",
    oneOf: [
        { title: "RNC", const: 4 },
    ],
    default: 4
};


export const preferredBranchId = {
    title: "Oficina de Preferencia",
    type: "number",
    lookup: {
        api: 'branches',
        resource: "Oficinas",
        id: 'id',
        label: 'name'
    }
};



export const boolYesNoType = {
    type: "boolean",
    oneOf: [
        { title: "Si", const: true },
        { title: "No", const: false },
    ],
}

export const strYesNoNAType = {
    type: "string",
    oneOf: [
        { title: "Si", const: "Si" },
        { title: "No", const: "No" },
        { title: "N/A", const: "N/A" },
    ],
}



export const readOnlyFileUI = {
    'ui:field': 'FileField',
    'ui:hideOnEmpty': true,
    'ui:readonly': true,
    'ui:titleAsLink': true,
}

export const contractSigner = {
    title: "Contrato elaborado por:",
    type: "number",
    oneOf: [
        { title: "Oficial de Relaciones", const: 0 },
        { title: "Oficial de Legal", const: 1 },
    ]
};


export function mergeObjects(...objects) {
    return {
        title: objects[0].title,
        type: 'object',
        properties: objects.reduce((_, o) => {
            Object.assign(_, o.properties || {});
            return _;
        }, {}),
        required: objects.reduce((_, o) => {
            _.push(...(o.required || []));
            return _;
        }, []),
        dependencies: objects.reduce((_, o) => {
            Object.assign(_, o.dependencies || {});
            return _;
        }, {}),
    }
}

export const financialCertSchema = {
    title: "",
    description: "Certificado Financiero",
    type: "object",
    properties: {
        "inversionEstimadaRD": {
            title: "Monto estimado inversión Certificados en RD",
            type: "number",
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehículo",
                "Liquidación de otra inversión",
                "Otro",
                "No aplica"
            ],
            enum: [
                "Ahorros provenientes del salario",
                "Ahorros por ingresos regulares",
                "Herencia",
                "Regalo",
                "Venta de inmueble",
                "Venta de vehiculo",
                "Liquidacion de otra inversion",
                "Otro",
                "No aplica"
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            exclusiveMinimum: 0
        },
    },
    dependencies: {
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Otro",
                                type: "string"
                            },
                        }, required: ["origenFondosRDOtro"]

                    }
                }
            ]
        },
    },
    required: ["inversionEstimadaRD", "origenFondosRD", "tasaCambio"]
}

export const financialCertBusinessSchema = {
    title: "Certificado Financiero",
    type: "object",
    properties: {
        "inversionEstimadaRD": {
            title: "Monto estimado inversión en RD",
            type: "number",
        },
        "origenFondosRD": {
            title: "Origen de fondos en RD",
            type: "string",
            enumNames: [
                "Ventas",
                "Tesorería",
                "Inversiones anteriores",
                "Otro",
            ],
            enum: [
                "Ventas",
                "Tesoreria",
                "Inversiones anteriores",
                "Otro",
            ],
        },
        "tasaCambio": {
            title: "Tasa de cambio en RD",
            type: "number",
            exclusiveMinimum: 0
        },
    },
    dependencies: {
        "origenFondosRD": {
            allOf: [
                {
                    if: {
                        properties: {
                            "origenFondosRD": {
                                const: "Otro",
                            },
                        }
                    },
                    then: {
                        properties: {
                            "origenFondosRDOtro": {
                                title: "Especifique",
                                type: "string"
                            },
                        }, required: ["origenFondosRDOtro"]

                    }
                }
            ]
        },
    },
    required: ["inversionEstimadaRD", "origenFondosRD", "tasaCambio"]
}

export const solicitudPrestamoSchema = {
    title: " ",
    description: "Solicitud Préstamo",
    type: "object",
    properties: {
        "facilidadesSolicitadas": {
            title: " ",
            description: "Facilidades solicitadas",
            type: "object",
            properties: {
                "proposito": {
                    title: "Propósito",
                    type: "string",
                },
                "descripcionFacilidades": {
                    title: " ",
                    description: "Descripción de facilidades (sólo aplica para líneas de crédito)",
                    type: "array",
                    maxItems: 5,
                    "items": {
                        "type": "object",
                        properties: {
                            "numFacilidad": {
                                title: "No. de Facilidad",
                                type: "string",
                            },
                            "descripcion": {
                                title: "Descripción",
                                type: "string",
                            },
                            "tipoFacilidad": {
                                title: "Tipo de Facilidad",
                                type: "string",
                                enumNames: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nómina",
                                    "Hipotecario",
                                ],
                                enum: [
                                    "Consumo",
                                    "Comercial- Micro",
                                    "Descuento nomina",
                                    "Hipotecario",
                                ],
                            },
                            "montoAprobado": {
                                title: "Monto Aprobado (si aplica)",
                                type: "number",
                            },
                            "montoUtilizado": {
                                title: "Monto Utilizado (si aplica)",
                                type: "number",
                            },
                            "montoPropuesto": {
                                title: "Monto Propuesto",
                                type: "number",
                            },
                            "tasa": {
                                title: "Tasa",
                                type: "number",
                            },
                            "formaPago": {
                                title: "Forma de Pago",
                                type: "string",
                                enumNames: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                                enum: [
                                    "Ingresos por negocio",
                                    "Ingresos por salario",
                                    "Intereses generados",
                                    "Otro",
                                ],
                            },
                            "cuotaEstimada": {
                                title: "Cuota Estimada",
                                type: "string",
                            },
                            "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
                        },
                    },
                },
                "incluyeGarantia": { title: "¿Incluye garantía?", ...boolYesNoType },
                "comentarios": {
                    title: "Comentarios",
                    type: "string",
                }
            },
            dependencies: {
                "incluyeGarantia": {
                    allOf: [{
                        if: { properties: { "incluyeGarantia": { const: true } } },
                        then: {
                            properties: {
                                "incluyeGarantiaSolidaria": { title: "¿Incluye garantía solidaria?", ...boolYesNoType },
                                "tipoGarantia": {
                                    title: "Tipo de Garantía",
                                    type: "string",
                                    enumNames: ["Garantía Solidaria", "Descuento de Nómina", "Hipotecario", "Prendaria", "Múltiples", "Otra"],
                                    enum: ["Garantia Solidaria", "Descuento de Nomina", "Hipotecario", "Prendaria", "Multiples", "Otra"],
                                },
                            }
                        }
                    }]
                },
            }
        },
    },
}

export const tipoDeEntidad =  {
    title: "Tipo de Entidad",
    type: "string",
    enum: [
        "S.A., S.A.S. o S.R.L.",
        "Empresa extranjera",
        "E.I.R.L.",
        "Asociacion sin fines de lucro / ONG",
        "Cooperativa",
        "Empresa Gubernamental",
        "Consorcio",
        "Fideicomiso",
        "Fondos de inversion",
    ],
    enumNames: [
        "S.A., S.A.S. o S.R.L.",
        "Empresa extranjera",
        "E.I.R.L.",
        "Asociación sin fines de lucro / ONG",
        "Cooperativa",
        "Empresa Gubernamental",
        "Consorcio",
        "Fideicomiso",
        "Fondos de inversión",
    ],
}

export const descripcionFacilidadesBusiness = {
    title: " ",
    description: "Descripción de facilidades (sólo aplica para líneas de crédito)",
    type: "array",
    maxItems: 5,
    items: {
        type: "object",
        properties: {
            "numFacilidad": {
                title: "No. de Facilidad",
                type: "string",
            },
            "descripcion": {
                title: "Descripción",
                type: "string",
            },
            "tipoFacilidad": {
                title: "Tipo de Facilidad",
                type: "string",
                enumNames: [
                    "Consumo",
                    "Comercial- Micro",
                    "Descuento nómina",
                    "Hipotecario",
                ],
                enum: [
                    "Consumo",
                    "Comercial- Micro",
                    "Descuento nomina",
                    "Hipotecario",
                ],
            },
            "montoAprobado": {
                title: "Monto Aprobado (si aplica)",
                type: "string",
            },
            "montoUtilizado": {
                title: "Monto Utilizado (si aplica)",
                type: "string",
            },
            "montoPropuesto": {
                title: "Monto Propuesto",
                type: "string",
            },
            "tasa": {
                title: "Tasa",
                type: "number",
            },
            "formaPago": {
                title: "Forma de Pago",
                type: "string",
                enumNames: [
                    "Ingresos por negocio",
                    "Ingresos por salario",
                    "Intereses generados",
                    "Otro",
                ],
                enum: [
                    "Ingresos por negocio",
                    "Ingresos por salario",
                    "Intereses generados",
                    "Otro",
                ],
            },
            "cuotaEstimada": {
                title: "Cuota Estimada",
                type: "string",
            },
            "seraCancelado": { title: "¿Será Cancelado?", ...boolYesNoType },
        },
    },
}