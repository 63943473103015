import {
    boolYesNoType,
} from './commons';


const form = {
    title: "Elaboración / Carga de Contratos (Préstamo)",
    displayName: 'SalesContractElaborationUpload',
    page: 'sales-contract-elaboration-upload',
};


const prodBanco = {
    title: " ",
    type: "object",
    properties: {
        "productosBanco": {
            title: " ",
            type: "array",
        },
    },
    dependencies: {
        "productosBanco": {
            allOf: [
                {
                    if: {
                        properties: {
                            "productosBanco": {
                                contains: { const: 5 } // "Préstamo Hipotecario"
                            },
                        }
                    },
                    then: {
                        properties: {
                            "soporteGarantiaHipotecaria": {
                                title: "Soportes Garantía Hipotecaria",
                                type: "object",
                                properties: {
                                    "actMatDiv": {
                                        title: "Acta de Matrimonio o Divorcio",
                                        type: "number",
                                    },
                                    "docIdConyuge": {
                                        title: "Documento de identidad cónyugue",
                                        type: "number",
                                    },
                                    "sobreInmueble":{
                                        title: "Sobre Inmueble:",
                                        type: "null"
                                    },
                                    "certTitulo": {
                                        title: "Certificado de Título",
                                        type: "number",
                                    },
                                    "certEstJurInm": {
                                        title: "Certificado de Estado Jurídico del Inmueble",
                                        type: "number",
                                    },
                                    "certIPI": {
                                        title: "Certificado de IPI",
                                        type: "number",
                                    },
                                    "aplicaExencion": {
                                        title: "¿Aplica exencion de impuestos?",
                                        ...boolYesNoType
                                    },
                                    "tieneHipotecaCheckbox":{
                                        title: "Marcar si el inmueble tiene hipoteca",
                                        type: "boolean",
                                    },
                                    "acreedorNoBancoCheckbox":{
                                        title: "Marcar si el acreedor NO es un banco",
                                        type: "boolean",
                                    },
                                    "sobreAcreedor":{
                                        title: "Sobre Acreedor:",
                                        type: "null"
                                    },
                                    "estatutosSociales": {
                                        title: "Estatutos Sociales registrados por la Cámara de Comercio y Producción",
                                        type: "number",
                                    },
                                    "actaLevantHipoteca": {
                                        title: "Acta que autoriza el levantamiento de hipoteca y otorga poderes a estos fines registrada por la Cámara de Comercio y Producción",
                                        type: "number",
                                    },
                                    "docIdentPersAutorizada": {
                                        title: "Documento Identidad de la persona autorizada",
                                        type: "number",
                                    },
                                    "sobreVendedor":{
                                        title: "Sobre Vendedor:",
                                        type: "null"
                                    },
                                    "ventaPersJuridica": {
                                        title: "¿Venta es Persona jurídica?",
                                        ...boolYesNoType
                                    },
                                },
                                dependencies:{
                                    "aplicaExencion": {
                                        oneOf:[
                                            {
                                                properties: {
                                                    "aplicaExencion": {
                                                        const: true,
                                                    },
                                                    "certHacienda":
                                                    {
                                                        title:"Certificación de Hacienda",
                                                        type: "number",
                                
                                                    }
                                                },   
                                            },
                                            {
                                                properties: {
                                                    "aplicaExencion": {
                                                        const: false,
                                                    },
                                                }
                                            }
                                        ],
                                    },
                                    "tieneHipotecaCheckbox":{
                                        allOf:[
                                            {
                                                if: {
                                                    properties: {
                                                        "tieneHipotecaCheckbox":{
                                                            const: true
                                                        },
                                                    }
                                                },
                                                then: {
                                                    properties: {
                                                        "certRegistroAcreedor": {
                                                            title: "Certificado de Registro de Acreedor Hipotecario",
                                                            type: "number",
                                    
                                                        },
                                                        "actaCancelacionHipoteca": {
                                                            title: "Acta de Cancelación de Hipoteca Notarizado",
                                                            type: "number",
                                    
                                                        },
                                                        "hipotecaReinscrita": {
                                                            title: "¿Hipoteca será reinscrita?",
                                                            ...boolYesNoType
                                                        }
                                                    },
                                                    dependencies:{
                                                        "hipotecaReinscrita": {
                                                            oneOf:[
                                                                {
                                                                    properties: {
                                                                        "hipotecaReinscrita": {
                                                                            const: true,
                                                                        },
                                                                        "requiresSettlement": {
                                                                            title: "¿Se requiere saldo en otra Institución Financiera?",
                                                                            ...boolYesNoType
                                                                        },
                                                                        "contratoHipotecaSaldar":
                                                                        {
                                                                            title:"Contrato de Hipoteca a Saldar",
                                                                            type: "number",
                                                    
                                                                        },
                                                                        "cartaSaldo":
                                                                        {
                                                                            title:"Carta de saldo",
                                                                            type: "number",
                                                    
                                                                        },
                                                                        "reciboPagoHipotecaAnterior":
                                                                        {
                                                                            title:"Recibo de pago de hipoteca anterior",
                                                                            type: "number",
                                                    
                                                                        }
                                                                    },   
                                                                },
                                                                {properties: {"hipotecaReinscrita": {const: false}}}
                                                            ],
                                                        }, 
                                                    }
                                                },
                                            },
                                        ]
                                    },
                                    "acreedorNoBancoCheckbox":{
                                        allOf:[
                                            {
                                                if: {
                                                    properties: {
                                                        "acreedorNoBancoCheckbox":{
                                                            const: true
                                                        },
                                                    }
                                                },
                                                then: {
                                                    properties: {
                                                        "certRegistroMercantil": {
                                                            title: "Certificado de Registro Mercantil",
                                                            type: "number",
                                    
                                                        },
                                                        "certRNCVigente": {
                                                            title: "Certificación RNC Vigente",
                                                            type: "number",
                                    
                                                        },
                                                    }
                                                }
                                            }
                                        ]
                                    },
                                    "ventaPersJuridica": {
                                        oneOf:[
                                            {
                                                properties: {
                                                    "ventaPersJuridica": {
                                                        const: true,
                                                    },
                                                    "estatutosSociales2": {
                                                        title: "Estatutos Sociales registrados por la Cámara de Comercio y Producción",
                                                        type: "number",
                                
                                                    },
                                                    "actaVentaInmueble": {
                                                        title: "Acta que autoriza venta del (los) inmueble(s) y designa personas autorizadas a firmar registrada por la Cámara de Comercio y Producción",
                                                        type: "number",
                                
                                                    },
                                                    "docIdentPersAutorizada2": {
                                                        title: "Documento Identidad de la persona(s) autorizada(s) a firmar",
                                                        type: "number",
                                
                                                    },
                                                    "certRegistroMercantil2": {
                                                        title: "Certificado de Registro Mercantil",
                                                        type: "number",
                                
                                                    },
                                                    "certRNCVigente2": {
                                                        title: "Certificación RNC Vigente",
                                                        type: "number",
                                
                                                    },
                                                    "actaDesignanGerencia": {
                                                        title: "Acta donde designan Consejo de Administración/Gerencia registrada por la Cámara de Comercio y Producción",
                                                        type: "number",
                                
                                                    },
                                                },
                                            },
                                            {
                                                properties:{
                                                    "ventaPersJuridica": {
                                                        const: false,
                                                    },
                                                    "docIdentVendedor": {
                                                        title: "Documento de identidad vendedor",
                                                        type: "number",
                                
                                                    },
                                                    "ventaFamiliares": {
                                                        title: "¿Venta es entre familiares?",
                                                        ...boolYesNoType
                                                    }
                                                },
                                                dependencies:{
                                                    "ventaFamiliares": {
                                                        oneOf:[
                                                            {
                                                                properties: {
                                                                    "ventaFamiliares": {
                                                                        const: true,
                                                                    },
                                                                    "certNoDonacion": {
                                                                        title: "Certificacion de presuncion de No Donación emitida por la Dirección General de Impuestos Internos (DGII)",
                                                                        type: "number",
                                                
                                                                    },
                                                                }
                                                            },
                                                            {properties: {"ventaFamiliares": { const: false}}}
                                                        ]
                                                    }
                                                }
                    
                                            }
                                        ]
                                    },
                                }
                            },
                        },
                    },
                },
            ]
        }
    }
}
                            

form.schema = {
    type: "object",
    properties: {
        "contPrestamo": {
            title: "Elaboración / Carga de Contratos (Préstamo)",
            type: "object",
            properties: {
                "contFirmado": {
                    title: "Contrato del préstamo o pagaré",
                    type: "number",
                },
                "solicitudPrestamo": {
                    title: "Solicitud de Préstamo",
                    type: "number",
                },
                "tablaAmortizacion": {
                    title: "Tabla de amortización",
                    type: "number",
                },
                "formularioDelSeguro": {
                    title: "Formulario del seguro",
                    type: "number",
                },
                "tablaDelSeguro": {
                    title: "Tabla del seguro",
                    type: "number",
                },
                "tarifario": {
                    title: "Tarifario",
                    type: "number",
                },
                "guiaDeInfo": {
                    title: "Guía de información",
                    type: "number",
                },
                "hojaDeberesDerecho": {
                    title: "Hoja de deberes y derechos",
                    type: "number",
                },
            },
        },
        "prodBanco":prodBanco,
    }
}

form.uiSchema = {
    "contPrestamo": {
        "contFirmado": { "ui:field": "FileField" },
        "solicitudPrestamo":  { "ui:field": "FileField" },
        "tablaAmortizacion": { "ui:field": "FileField" },
        "formularioDelSeguro": { "ui:field": "FileField" }, 
        "tablaDelSeguro": { "ui:field": "FileField" },
        "tarifario": { "ui:field": "FileField" },
        "guiaDeInfo": { "ui:field": "FileField" },
        "hojaDeberesDerecho": { "ui:field": "FileField" },
    },
    "prodBanco":{
        "ui:field": "HideObjectField",
        "productosBanco":{
            "joli:hideAttr": "hide",
        },
        "soporteGarantiaHipotecaria": {
            "actMatDiv": { "ui:field": "FileField"},
            "docIdConyuge": { "ui:field": "FileField"},
            "certTitulo": { "ui:field": "FileField"},
            "certEstJurInm": { "ui:field": "FileField"},
            "certIPI": { "ui:field": "FileField"},
            "estatutosSociales": { "ui:field": "FileField"},
            "actaLevantHipoteca": { "ui:field": "FileField"},
            "docIdentPersAutorizada": { "ui:field": "FileField"},
            "certHacienda": { "ui:field": "FileField"},
            "certRegistroAcreedor": { "ui:field": "FileField"},
            "actaCancelacionHipoteca": { "ui:field": "FileField"},
            "contratoHipotecaSaldar": { "ui:field": "FileField"},
            "cartaSaldo": { "ui:field": "FileField"},
            "reciboPagoHipotecaAnterior": { "ui:field": "FileField"},
            "certRegistroMercantil": { "ui:field": "FileField"},
            "certRNCVigente": { "ui:field": "FileField"},
            "estatutosSociales2": { "ui:field": "FileField"},
            "actaVentaInmueble": { "ui:field": "FileField"},
            "docIdentPersAutorizada2": { "ui:field": "FileField"},
            "certRegistroMercantil2": { "ui:field": "FileField"},
            "certRNCVigente2": { "ui:field": "FileField"},
            "actaDesignanGerencia": { "ui:field": "FileField"},
            "docIdentVendedor": { "ui:field": "FileField"},
            "certNoDonacion": { "ui:field": "FileField"},
    
            "aplicaExencion":{
                "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
            },
            "hipotecaReinscrita":{
                "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
            },
            // "acreedorNoBancoCheckbox":{
            //     "ui:widget": "checkboxes",
            //     "ui:options": {
            //     inline: false
            //     },
            // },
            "ventaPersJuridica":{
                "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
            },
            "ventaFamiliares":{
                "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
            },

            "requiresSettlement":{
                "ui:widget": "radio",
                    "ui:options": {
                        inline: true
                    },
            },
            

            "ui:order":[
                "*",
                "aplicaExencion",
                "certHacienda", 
                "tieneHipotecaCheckbox", 
                "certRegistroAcreedor", 
                "actaCancelacionHipoteca", 
                "hipotecaReinscrita", 
                "requiresSettlement",
                "contratoHipotecaSaldar", 
                "cartaSaldo", 
                "reciboPagoHipotecaAnterior",
                "acreedorNoBancoCheckbox",
                "sobreAcreedor",
                "certRegistroMercantil",
                "certRNCVigente",
                "estatutosSociales",
                "actaLevantHipoteca",
                "docIdentPersAutorizada",
                "sobreVendedor",
                "ventaPersJuridica",
                "estatutosSociales2",
                "actaVentaInmueble",
                "docIdentPersAutorizada2",
                "certRegistroMercantil2",
                "certRNCVigente2",
                "actaDesignanGerencia",
                "docIdentVendedor",
                "ventaFamiliares",
                "certNoDonacion"
            ],
        },  
    }
    
}

form.objectMap = {
    "productIds.1": "prodBanco.productosBanco",
    "caseLoanInfo.signedContractFileId": "contPrestamo.contFirmado",

    "caseLoanInfo.loanApplicationFileId": "contPrestamo.solicitudPrestamo",
    "caseLoanInfo.amortizationTableFileId": "contPrestamo.tablaAmortizacion",
    "caseLoanInfo.insuranceFormFileId": "contPrestamo.formularioDelSeguro",
    "caseLoanInfo.insuranceTableFileId": "contPrestamo.tablaDelSeguro",
    "caseLoanInfo.tariffFileId": "contPrestamo.tarifario",
    "caseLoanInfo.infoGuideFileId": "contPrestamo.guiaDeInfo",
    "caseLoanInfo.lawHomeworkSheetFileId": "contPrestamo.hojaDeberesDerecho",

    "caseLoanInfo.marriageDivorceCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.actMatDiv",
    "caseLoanInfo.spouseIdFileId": "prodBanco.soporteGarantiaHipotecaria.docIdConyuge",
    "caseLoanInfo.titleCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.certTitulo",
    "caseLoanInfo.propertyLegalStateCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.certEstJurInm",
    "caseLoanInfo.ipiCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.certIPI",
    "caseLoanInfo.socialSatutesFileId": "prodBanco.soporteGarantiaHipotecaria.estatutosSociales",
    "caseLoanInfo.mortgageLiftingFileId": "prodBanco.soporteGarantiaHipotecaria.actaLevantHipoteca",
    "caseLoanInfo.authorizedPersonIdFileId": "prodBanco.soporteGarantiaHipotecaria.docIdentPersAutorizada",
    "caseLoanInfo.sellerEnvelopeFileId": "prodBanco.soporteGarantiaHipotecaria.certHacienda",
    "caseLoanInfo.mortgageCreditorRegistrationCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.certRegistroAcreedor",
    "caseLoanInfo.notarizedMortgageCancellationCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.actaCancelacionHipoteca",
    "caseLoanInfo.requiresSettlement": "prodBanco.soporteGarantiaHipotecaria.requiresSettlement",
    "caseLoanInfo.mortgageContractToBeSettledFileId": "prodBanco.soporteGarantiaHipotecaria.contratoHipotecaSaldar",
    "caseLoanInfo.mortgageSettlementLetterFileId": "prodBanco.soporteGarantiaHipotecaria.cartaSaldo",
    "caseLoanInfo.previousMortgagePaymentReceiptFileId": "prodBanco.soporteGarantiaHipotecaria.reciboPagoHipotecaAnterior",
    "caseLoanInfo.commercialRegistrationCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.certRegistroMercantil",
    "caseLoanInfo.validRNCCertificationFileId": "prodBanco.soporteGarantiaHipotecaria.certRNCVigente",
    "caseLoanInfo.socialStatutes2FileId": "prodBanco.soporteGarantiaHipotecaria.estatutosSociales2",
    "caseLoanInfo.propertySalesCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.actaVentaInmueble",
    "caseLoanInfo.authorizedPersonId2FileId": "prodBanco.soporteGarantiaHipotecaria.docIdentPersAutorizada2",
    "caseLoanInfo.commercialRegistrationCertificate2FileId": "prodBanco.soporteGarantiaHipotecaria.certRegistroMercantil2",
    "caseLoanInfo.validRNCCertification2FileId": "prodBanco.soporteGarantiaHipotecaria.certRNCVigente2",
    "caseLoanInfo.managementAppointmentCertificationFileId": "prodBanco.soporteGarantiaHipotecaria.actaDesignanGerencia",
    "caseLoanInfo.salespersonIdFileId": "prodBanco.soporteGarantiaHipotecaria.docIdentVendedor",
    "caseLoanInfo.nonDonationCertificateFileId": "prodBanco.soporteGarantiaHipotecaria.certNoDonacion",
    "caseLoanInfo.doesExceptionApply": "prodBanco.soporteGarantiaHipotecaria.aplicaExencion",
    "caseLoanInfo.isSalesLegalPerson": "prodBanco.soporteGarantiaHipotecaria.ventaPersJuridica",
    "caseLoanInfo.mortgageWillBeReRegistered": "prodBanco.soporteGarantiaHipotecaria.hipotecaReinscrita",
    "caseLoanInfo.isFamilyToFamilySale": "prodBanco.soporteGarantiaHipotecaria.ventaFamiliares",
    "caseLoanInfo.hasMortgage": "prodBanco.soporteGarantiaHipotecaria.tieneHipotecaCheckbox",
    "caseLoanInfo.creditorIsNotABank": "prodBanco.soporteGarantiaHipotecaria.acreedorNoBancoCheckbox",
}


export default form;