import SendEmail from "./SendEmail";
import FileField from "./FileField";
import CaseZipDownloadField from "./CaseZipDownloadField";
import HideObjectField from "./HideObjectField";
import RiskMatrixField from "./RiskMatrixField";
import RiskMatrixFieldBusiness from "./RiskMatrixFieldBusiness";
import ComputedField from "./ComputedField";
import LookupFormField from "./LookupFormField";
import LookupMultiSelectCheckboxesFormField from "./LookupMultiSelectCheckboxesFormField";
import TextInput from "./TextInput";
import PhoneInput from "./PhoneInput";
import MoneyInput from "./MoneyInput";
import CedulaInput from "./CedulaInput";
import FetchClientInfoButton from "./FetchClientInfoButton";


const customFields = {
    SendEmail,
    FileField,
    CaseZipDownloadField,
    FetchClientInfoButton,
    RiskMatrixField,
    RiskMatrixFieldBusiness,
    ComputedField,
    HideObjectField,
    LookupFormField,
    LookupMultiSelectCheckboxesFormField,
    TextInput,
    PhoneInput,
    MoneyInput,
    CedulaInput
}


export default customFields;