import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const CasesApi = {
    async getCases({ offset, size, sortBy, ...filters }) {
        return checkErrors(await fetchApi.api.get(`cases/`, { query: { offset, size, sortBy, ...filters } }));
    },

    async getCase({ caseId }) {
        return checkErrors(
            await fetchApi.api.get(`cases/${caseId}`)
        )
    },

    async getCaseHistory({ caseId }) {
        return checkErrors(
            await fetchApi.api.get(`cases/${caseId}/history`)
        )
    },

    async getWorkflowStates({ caseId } = {}) {
        const query = { caseId };
        return checkErrors(
            await fetchApi.lookup.get(`workflow-states`, { query })
        )
    },

    async getWorkflowStatusMap({ caseId } = {}) {
        const states = await CasesApi.getWorkflowStates({ caseId });
        return states.reduce((_, { name, title }) => {
            (_[name] = title || name);
            return _;
        }, {});
    },

    async getDataClient({ clientType, idString, idType, id }) {
        return checkErrors(
            await fetchApi.api.get(`cases/client-info/${clientType}/${idString}/${idType}/${id}`)
        )
    },

    async teleportCaseToHistoryStep({ caseId, stepIdx }) {
        return checkErrors(
            await fetchApi.api.post(`cases/${caseId}/history/teleport`, { data: { index: stepIdx } })
        )
    },

    async getCaseAssignments({ caseId }) {
        return checkErrors(
            await fetchApi.api.get(`cases/${caseId}/assignments`)
        )
    },

    async assignUserToCase({ caseId, userId, page }) {
        return checkErrors(
            await fetchApi.api.post(`cases/${caseId}/assign`, {
                data: {
                    userId, page
                }
            })
        );
    },

    async createCase({ newCase }) {
        return checkErrors(await fetchApi.api.post(`cases/`, { data: newCase }));
    },

    async updateCase({ id, page, formData }) {
        return checkErrors(await fetchApi.api.put(`cases/${id}/${page}`, { data: formData }));
    },

    async addProducts({ caseId, productIds }) {
        return checkErrors(
            await fetchApi.api.post(`cases/${caseId}/products`, { data: { productIds } })
        );
    },

    async awaitDocuments({ caseId, page }) {
        return checkErrors(
            await fetchApi.api.put(`cases/${caseId}/await-documents`, {
                data: {
                    page
                }
            })
        );
    },

    async createCaseNote({ caseId, note }) {
        return checkErrors(await fetchApi.api.post(`cases/${caseId}/notes`, {
            data: note, json: true
        }));
    },

    async getCaseNotes({ caseId }) {
        return checkErrors(await fetchApi.api.get(`cases/${caseId}/notes`));
    },

    async getRoleAssignments({ caseId }) {
        return checkErrors(await fetchApi.api.get(`cases/${caseId}/role-assignments`));
    },

    async getCaseZipDownloadUrl({ caseId, filter }) {
        return (await checkErrors(await fetchApi.api.get(`cases/${caseId}/download-documents`, { query: { filter } }))).data;
    },

    async getCaseDDDecision({ caseId }) {
        return checkErrors(await fetchApi.api.get(`form-lookup/determine/${caseId}/approvalRequirement, compliance`));
    }

}


export default CasesApi;
