import roleNameMatches from "./roleNameMatches";


function canViewStatus(status, auth){
    const {
        user: {
            user: {role: {name: roleName}},
            permissions: {workUnassignedCases} = {},
        },
        isAdmin
    } = auth;

    return isAdmin || ((roleNameMatches(status.role, roleName) || workUnassignedCases) && status.canEdit !== false);
}


export default canViewStatus;