import React, { useContext, useEffect } from 'react';
import { AuthContext } from "../context/AuthContext";
import RequestReloadContext from '../context/RequestReloadContext';

const HandleCaseUpdate = ({
    history, formSubmitResult
}) => {
    const auth = useContext(AuthContext);
    const rrc = useContext(RequestReloadContext);
    const { case: curCase, status } = formSubmitResult;
    const statusParsed = JSON.parse(status);
    const role = auth.user.user.role;
    const roleRegEx = new RegExp(role.status);

    useEffect(() => {
        if (roleRegEx.test(role.name) || role.name === 'administrator') {
            // Can continue the flow of the case
            if (rrc) {
                rrc.reload();
            } else {
                window.location.reload();
            }
        } else {
            history.replace('/')
        }
    }, []);

    return null;
}

export default HandleCaseUpdate;