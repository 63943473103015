import React, { useState, useContext } from 'react';
import {
    Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink,
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Nav, NavbarText
} from 'reactstrap';
import { Link } from 'react-router-dom';

import { AuthContext } from '../context/AuthContext';
import { Logo } from "./_general/Logo";

import './NavMenu.css';
import UserIcon from './UserIcon';
import { useZohoDeskWidgetCallFunction } from '../util/useZohoDeskWidget';


function NavMenu() {
    const auth = useContext(AuthContext);
    const permissions = ((auth || {}).user || {}).permissions || {};
    const [collapsed, setCollapsed] = useState(true);
    function toggleNavbar() {
        setCollapsed(!collapsed);
    }

    const zdwcf = useZohoDeskWidgetCallFunction();

    const isLoggedIn = auth.user && !auth.user?.user?.changePasswordOnNextLogin;

    return (
        <header><Navbar
            className="navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 bg-white"
            fixed="top"
            expand="sm"
            light
        >
            <NavbarBrand tag={Link} to="/"> <Logo width={42} height={42} /></NavbarBrand>
            <NavbarToggler onClick={toggleNavbar} className="mr-2" />
            <Collapse isOpen={!collapsed} navbar>
                <Nav className="navbar-nav">
                    {isLoggedIn && !permissions.limitColumnAndFilterView ? (
                        <NavbarText><NavLink tag={Link} to="/">Casos Pendientes</NavLink></NavbarText>
                    ) : null}
                    {isLoggedIn ? (
                        <NavbarText><NavLink tag={Link} to="/universal-filter">Búsqueda</NavLink></NavbarText>
                    ) : null}
                    {/* <NavbarText><NavLink to="/case-intake" tag={Link}>Crear Caso Nuevo</NavLink></NavbarText> */}
                    {isLoggedIn && permissions.manageUser ? (<>
                        <NavbarText className="d-flex align-items-center">
                            <NavLink tag={Link} to="/users" >Usuarios</NavLink>
                        </NavbarText>
                    </>) : null}
                    {isLoggedIn && permissions.manageSystem ? (<>
                        <NavbarText className="d-flex align-items-center">
                            <NavLink tag={Link} to="/system-settings" >Configuraci&oacute;n</NavLink>
                        </NavbarText>
                    </>) : null}
                </Nav>
                <Nav className="navbar-nav">
                    {isLoggedIn ? (<NavbarText className="d-flex align-items-center">
                        <NavLink tag={Link} to="/support" >Ayuda</NavLink>
                    </NavbarText>) : null}
                    {isLoggedIn ? (<NavItem><UncontrolledDropdown className="user-nav-dropdown">
                        <DropdownToggle caret nav>
                            <UserIcon user={auth?.user?.user} />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="user-info d-flex align-items-center" tag={Link} to="/profile" ><div>
                                <div className="user">{`${auth.user.user.firstName || ""}  ${auth.user.user.lastName || ""}`}</div>
                                <div className="role">{auth?.user?.user?.role?.displayName || "----"}</div>
                            </div></DropdownItem>
                            <hr className="dropdown-divider" />
                            <DropdownItem className="d-flex align-items-center" tag={Link} to="/" onClick={() => auth.logout()}>
                                Log Out
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown></NavItem>) : null}
                </Nav>
            </Collapse>
        </Navbar></header>
    );
}


export default NavMenu;