import {
    boolYesNoType,
} from './commons';


const form = {
    title: "DD Gerente",
    displayName: 'ManagerCaseReviewReport',
    page: 'manager-case-review-report',
    commentFieldsMap: {
        "Debida Diligencia Gerente": "clients[0].retailClientDueDiligenceReport.dueDiligenceComments",
    },
    isSentBack(object) {
        return [
            "Devuelto",
            "Requerimiento de información adicional"
        ].indexOf(object.clients[0].retailClientDueDiligenceReport.dueDiligenceResult) !== -1;
    }
};


form.schema = {
    title: "Debida Diligencia - Gerente de Negocios",
    type: "object",
    properties: {
        "tipoPersona": {
            title: "Tipo de Persona - Gerente de Negocio",
            type: "number",
            enum: [0, 1, 2, 3, 4],
            defaultValue: 0,
            'ui:hideDefault': true,
            enumNames: ["---", "Nacional", "Menores de Edad", "Extranjero Residente", "Extranjero No Residente"],
        },
        "tipoPersonaMM": {
            title: "Tipo de Persona - Gerente de Mercado",
            type: "number",
            readOnly: true,
            enum: [0, 1, 2, 3, 4],
            defaultValue: 0,
            'ui:hideDefault': true,
            enumNames: ["---", "Nacional", "Menores de Edad", "Extranjero Residente", "Extranjero No Residente"],
        },
        "nivelRiesgo": {
            title: "Nivel de Riesgo - Gerente de Negocio",
            type: "number",
            enumNames: ["---", "Alto", "Medio", "Bajo"],
            defaultValue: 0,
            'ui:hideDefault': true,
            enum: [0, 3, 2, 1],
        },
        "nivelRiesgoMM": {
            title: "Nivel de Riesgo - Gerente de Mercado",
            type: "number",
            readOnly: true,
            enumNames: ["---", "Alto", "Medio", "Bajo"],
            defaultValue: 0,
            'ui:hideDefault': true,
            enum: [0, 3, 2, 1]
        },
        "perPoExp": {
            title: "Persona Políticamente Expuesta (PEP) - Gerente de Negocio",
            ...boolYesNoType
        },
        "perPoExpMM": {
            title: "Persona Políticamente Expuesta (PEP) - Gerente de Mercado",
            readOnly: true,
            ...boolYesNoType
        },
        "validacionDocumentosRequeridos": {
            title: "Validación de Documentos Requeridos",
            type: "object",
            properties: {
                "nacional": {
                    title: "Nacional - Gerente de Negocio",
                    type: "object",
                    properties: {
                        "copiaCedula": {
                            title: "Copia de cédula de Identidad y Electoral (ambos lados)",
                            type: "boolean",
                        },
                        "documentosFuenteIngresos": {
                            title: "Documentos que comprueben la fuente de ingresos",
                            type: "boolean",
                        },
                        "reporteInfoCredenticia": {
                            title: "Reporte de Información Crediticia",
                            type: "boolean",
                        },
                    },
                },
                "menoresEdad": {
                    title: "Menores de Edad - Gerente de Negocio",
                    type: "object",
                    properties: {
                        "cedulaIdentidadMenor": { title: "Copia de cédula de Identidad de menor", type: "boolean" },
                        "numUnicoIdentidad": { title: "Entre 14 y 17 años, Número Unico de Identidad (NIIU)", type: "boolean" },
                        "actaNacimiento": { title: "Acta de Nacimiento del Menor u otro documento que evidencie la relación con el menor", type: "boolean" },
                        "poderRepresentativo": { title: "Documento Legal donde el tutor legal otorga poder de representación", type: "boolean" },
                        "sentenciaAcreditada": { title: "Sentencia que acredita al representante como tutor", type: "boolean" }
                    },
                },
                "extranjeroResidente": {
                    title: "Extranjero Residente - Gerente de Negocio",
                    type: "object",
                    properties: {
                        "cedulaIdentidad": { title: "Copia de cédula de Identidad de residente permanente o temporal", type: "boolean" },
                        "pasaporteVigente": { title: "Pasaporte vigente, incluyendo sellos de entrada y salida", type: "boolean" },
                        "imagenIdentificacion": { title: "Imagen de una o más documentos de identificación de su país de origen", type: "boolean" },
                        "declaracionImpuesto": { title: "Declaración de Impuesto de su país de origen", type: "boolean" },
                        "fuenteIngresos": { title: "Documentos que comprueben la fuente de sus ingresos", type: "boolean" }
                    },
                },
                "extranjeroNoResidente": {
                    title: "Extranjero No Residente - Gerente de Negocio",
                    type: "object",
                    properties: {
                        "estatusMigratorio": { title: "Documento emitido por el Organismo Oficial correspondiente a su estatus migratorio", type: "boolean" },
                        "pasaporteVigente": { title: "Pasaporte vigente, incluyendo sellos de entrada y salida", type: "boolean" },
                        "imagenIdentificacion": { title: "Imagen de una o más documentos de identificación de su país de origen", type: "boolean" },
                        "declaracionImpuesto": { title: "Declaración de Impuesto de su país de origen", type: "boolean" },
                        "fuenteIngresos": { title: "Documentos que comprueben la fuente de sus ingresos", type: "boolean" }
                    },
                },
                "nacionalMM": {
                    title: "Nacional - Gerente de Mercado",
                    type: "object",
                    readOnly: true,
                    properties: {
                        "copiaCedula": {
                            title: "Copia de cédula de Identidad y Electoral (ambos lados)",
                            type: "boolean",
                        },
                        "documentosFuenteIngresos": {
                            title: "Documentos que comprueben la fuente de ingresos",
                            type: "boolean",
                        },
                        "reporteInfoCredenticia": {
                            title: "Reporte de Información Crediticia",
                            type: "boolean",
                        },
                    },
                },
                "menoresEdadMM": {
                    title: "Menores de Edad - Gerente de Mercado",
                    type: "object",
                    readOnly: true,
                    properties: {
                        "cedulaIdentidadMenor": { title: "Copia de cédula de Identidad de menor", type: "boolean" },
                        "numUnicoIdentidad": { title: "Entre 14 y 17 años, Número Unico de Identidad (NIIU)", type: "boolean" },
                        "actaNacimiento": { title: "Acta de Nacimiento del Menor u otro documento que evidencie la relación con el menor", type: "boolean" },
                        "poderRepresentativo": { title: "Documento Legal donde el tutor legal otorga poder de representación", type: "boolean" },
                        "sentenciaAcreditada": { title: "Sentencia que acredita al representante como tutor", type: "boolean" }
                    },
                },
                "extranjeroResidenteMM": {
                    title: "Extranjero Residente - Gerente de Mercado",
                    type: "object",
                    readOnly: true,
                    properties: {
                        "cedulaIdentidad": { title: "Copia de cédula de Identidad de residente permanente o temporal", type: "boolean" },
                        "pasaporteVigente": { title: "Pasaporte vigente, incluyendo sellos de entrada y salida", type: "boolean" },
                        "imagenIdentificacion": { title: "Imagen de una o más documentos de identificación de su país de origen", type: "boolean" },
                        "declaracionImpuesto": { title: "Declaración de Impuesto de su país de origen", type: "boolean" },
                        "fuenteIngresos": { title: "Documentos que comprueben la fuente de sus ingresos", type: "boolean" }
                    },
                },
                "extranjeroNoResidenteMM": {
                    title: "Extranjero No Residente - Gerente de Mercado",
                    type: "object",
                    readOnly: true,
                    properties: {
                        "estatusMigratorio": { title: "Documento emitido por el Organismo Oficial correspondiente a su estatus migratorio", type: "boolean" },
                        "pasaporteVigente": { title: "Pasaporte vigente, incluyendo sellos de entrada y salida", type: "boolean" },
                        "imagenIdentificacion": { title: "Imagen de una o más documentos de identificación de su país de origen", type: "boolean" },
                        "declaracionImpuesto": { title: "Declaración de Impuesto de su país de origen", type: "boolean" },
                        "fuenteIngresos": { title: "Documentos que comprueben la fuente de sus ingresos", type: "boolean" }
                    },
                },
            }
        },
        "beneficiarioFinal": {
            title: "Beneficiario Final",
            type: "object",
            properties: {
                "identificacionBeneficiarioFinal": {
                    title: "Identificación de Beneficiario Final - Gerente de Negocio",
                    type: "number",
                    enum: [0, 1, 2, 3],
                    defaultValue: 0,
                    'ui:hideDefault': true,
                    enumNames: ["---", "Si", "No", "No Aplica- Cliente es el único beneficiario"],
                },
                "identificacionBeneficiarioFinalMM": {
                    title: "Identificación de Beneficiario Final - Gerente de Mercado",
                    readOnly: true,
                    type: "number",
                    enum: [0, 1, 2, 3],
                    'ui:hideDefault': true,
                    enumNames: ["---", "Si", "No", "No Aplica- Cliente es el único beneficiario"],
                },
            },
            //   required: ["identificacionBeneficiarioFinal"]
        },
        "procedenciaDestinoFondos": {
            title: "Procedencia y destino de Fondos",
            type: "object",
            properties: {
                "origenFondos": {
                    title: "Origen de los Fondos - Gerente de Negocio",
                    ...boolYesNoType
                },
                "origenFondosMM": {
                    title: "Origen de los Fondos - Gerente de Mercado",
                    readOnly: true,
                    ...boolYesNoType
                },
                "destinoFondos": {
                    title: "Destino de Fondos - Gerente de Negocio",
                    type: "number",
                    enum: [0, 1, 2, 3],
                    defaultValue: 0,
                    'ui:hideDefault': true,
                    enumNames: ["---", "Si", "No", "No Aplica"],
                },
                "destinoFondosMM": {
                    title: "Destino de Fondos - Gerente de Mercado",
                    type: "number",
                    readOnly: true,
                    enum: [0, 1, 2, 3],
                    'ui:hideDefault': true,
                    enumNames: ["---", "Si", "No", "No Aplica"],
                },
            },
            required: ["origenFondos", "destinoFondos"],
        },
        "validacionAnexos": {
            title: "Validación de anexos",
            type: "object",
            properties: {
                "consultasListas": { title: "Consultas en Listas - Gerente de Negocio", type: "boolean" },
                "matrizRiesgo": { title: "Matriz de Riesgo - Gerente de Negocio", type: "boolean" },
                "consultasListasMM": { title: "Consultas en Listas - Gerente de Mercado", type: "boolean", readOnly: true },
                "matrizRiesgoMM": { title: "Matriz de Riesgo - Gerente de Mercado", type: "boolean", readOnly: true }
            },
            required: ["consultasListas", "matrizRiesgo"],
        },
        "debDilGerNeg": {
            title: "Debida Diligencia - Gerente de Negocios",
            type: "object",
            properties: {
                "obsGerNeg": {
                    title: "Observaciones Gerente de Negocios",
                    type: "string"
                },
                "fechaRevision": {
                    title: "Fecha de revisión",
                    type: "string",
                    format: "date"
                },
                "resDebDil": {
                    title: "Resultado Debida Diligencia",
                    type: "string",
                    enum: [
                        "Aprobado",
                        "Rechazado",
                        "Aprobado con excepción",
                        "Devuelto",
                        "Requerimiento de información adicional"
                    ]
                },
                "comentarios": {
                    title: "Comentarios",
                    type: "string"
                },
                "razonDevolucion": {
                    title: "Razón de Devolución",
                    type: "string",
                    enum: ["Documento cargado no cumple con requerimientos",
                        "Documento o información faltante",
                        "Error en digitación de información",
                        "Información Incorrecta",
                        "Otros"
                    ],
                },
            },
            required: ["obsGerNeg", "fechaRevision", "resDebDil"],
        },
        "debDilGerNegMM": {
            title: "Debida Diligencia - Gerente de Mercado",
            readOnly: true,
            type: "object",
            properties: {
                "obsGerNegMM": {
                    title: "Observaciones",
                    type: "string"
                },
                "fechaRevisionMM": {
                    title: "Fecha de revisión",
                    type: "string",
                    format: "date"
                },
                "resDebDilMM": {
                    title: "Resultado Debida Diligencia",
                    type: ["string"],
                    enum: [
                        "Aprobado",
                        "Rechazado",
                        "Aprobado con excepción",
                        "Devuelto",
                        "Requerimiento de información adicional"
                    ]
                },
                "comentariosMM": {
                    title: "Comentarios",
                    type: "string"
                },
                "razonDevolucionMM": {
                    title: "Razón de Devolución",
                    type: "string",
                    enum: [
                        null,
                        "Documento cargado no cumple con requerimientos",
                        "Documento o información faltante",
                        "Error en digitación de información",
                        "Información Incorrecta",
                        "Otros"
                    ],
                    enumNames: [
                        "---",
                        "Documento cargado no cumple con requerimientos",
                        "Documento o información faltante",
                        "Error en digitación de información",
                        "Información Incorrecta",
                        "Otros"
                    ],
                },
            }
        },
        "tipoCliente": {
            title: "Tipo de Cliente - Gerente de Negocio",
            type: "number",
            enum: [0, 1, 2],
            defaultValue: 0,
            'ui:hideDefault': true,
            enumNames: ["---", "Persona Física", "Persona Jurídica"],
        },
        "tipoClienteMM": {
            title: "Tipo de Cliente - Gerente de Mercado",
            readOnly: true,
            type: "number",
            enum: [0, 1, 2],
            'ui:hideDefault': true,
            enumNames: ["---", "Persona Física", "Persona Jurídica"],
        },
    }
};

form.uiSchema = {
    "ui:field": "HideObjectField",
    "ui:className": "hideObjectField_title",
    "tipoPersona": {
        "akc:validate": {
            "expr": "$value = 0 ? 'es requerido' : null"
        },
    },
    "nivelRiesgo": {
        "akc:validate": {
            "expr": "$value = 0 ? 'es requerido' : null"
        },
    },
    "tipoPersonaMM": {
        "ui:showIf": {
            expr: "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false"
        }
    },
    "tipoClienteMM": {
        "ui:showIf": {
            expr: "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false"
        }
    },
    "validacionDocumentosRequeridos": {
        "ui:field": "HideObjectField",
        "ui:className": "hideObjectField_title",
        "nacional": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersona = 1' },
        },
        "nacionalMM": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersonaMM = 1' },
        },
        "menoresEdad": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersona = 2' },
        },
        "menoresEdadMM": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersonaMM = 2' },
        },
        "extranjeroResidente": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersona = 3' },
        },
        "extranjeroResidenteMM": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersonaMM = 3' },
        },
        "extranjeroNoResidente": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersona = 4' },
        },
        "extranjeroNoResidenteMM": {
            "ui:widget": "checkboxes",
            "ui:showIf": { expr: 'tipoPersonaMM = 4' },
        },
    },
    "nivelRiesgoMM": { "ui:showIf": "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false" },
    "perPoExpMM": { "ui:showIf": "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false" },
    "beneficiarioFinal": {
        "ui:className": "hideObjectField_title",
        "ui:field": "HideObjectField",
        "identificacionBeneficiarioFinal": {
            "akc:validate": {
                "expr": "$value = 0 ? 'es requerido' : null"
            },
        },
        "identificacionBeneficiarioFinalMM": {
            "ui:showIf": {
                expr: "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false"
            }
        }
    },
    "procedenciaDestinoFondos": {
        "ui:className": "hideObjectField_title",
        "ui:field": "HideObjectField",
        "origenFondos": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            }
        },
        "origenFondosMM": {
            "ui:widget": "radio",
            "ui:showIf": {
                expr: "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false"
            },
            "ui:options": {
                inline: true
            },
        },
        "destinoFondos": {
            "akc:validate": {
                "expr": "$value = 0 ? 'es requerido' : null"
            },
        },
        "destinoFondosMM": {
            "ui:showIf": {
                expr: "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false"
            }
        }
    },
    "validacionAnexos": {
        "ui:className": "hideObjectField_title",
        "ui:field": "HideObjectField",
        "consultasListasMM": {
            "ui:showIf": {
                expr: "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false"
            }
        },
        "matrizRiesgoMM": {
            "ui:showIf": {
                expr: "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false"
            }
        }
    },
    "debDilGerNeg": {
        "ui:className": "hideObjectField_title",
        "ui:field": "HideObjectField",
        "obsGerNeg": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "razonDevolucion": {
            "ui:showIf": {
                expr: "debDilGerNeg.resDebDil = 'Devuelto' ? 'es requerido' : ''"
            },
        }
    },
    "debDilGerNegMM": {
        "ui:showIf": "$formObject != null ? $fromJson($formObject.state).`requiresApproval.marketManager` : false",
        "obsGerNeg": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "razonDevolucionMM": {
            "ui:showIf": {
                expr: "debDilGerNegMM.resDebDilMM = 'Devuelto' ? 'es requerido' : ''"
            },
        }
    }
}

form.objectMap = {
    "clients[0].retailClientDueDiligenceReport.personType": "tipoPersona",
    "clients[0].retailClientDueDiligenceMarketManagerReport.personType": "tipoPersonaMM",
    "clients[0].retailClientDueDiligenceReport.clientType": "tipoCliente",
    "clients[0].retailClientDueDiligenceMarketManagerReport.clientType": "tipoClienteMM",
    "clients[0].retailClientDueDiligenceReport.riskLevel": "nivelRiesgo",
    "clients[0].retailClientDueDiligenceMarketManagerReport.riskLevel": "nivelRiesgoMM",
    "clients[0].retailClientPoliticalExposureInfo.hasPoliticalExposure": "perPoExp",
    "clients[0].retailClientDueDiligenceMarketManagerReport.hasPoliticalExposure": "perPoExpMM",

    "clients[0].retailClientDueDiligenceReport.nationalCopyOfId": "validacionDocumentosRequeridos.nacional.copiaCedula", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nationalCopyOfId": "validacionDocumentosRequeridos.nacionalMM.copiaCedula", // true
    "clients[0].retailClientDueDiligenceReport.nationalIncomeDocument": "validacionDocumentosRequeridos.nacional.documentosFuenteIngresos", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nationalIncomeDocument": "validacionDocumentosRequeridos.nacionalMM.documentosFuenteIngresos", // true
    "clients[0].retailClientDueDiligenceReport.nationalSecondFormOfId": "validacionDocumentosRequeridos.nacional.segundaIdentificacion", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nationalSecondFormOfId": "validacionDocumentosRequeridos.nacionalMM.segundaIdentificacion", // true
    "clients[0].retailClientDueDiligenceReport.nationalCreditReport": "validacionDocumentosRequeridos.nacional.reporteInfoCredenticia", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nationalCreditReport": "validacionDocumentosRequeridos.nacionalMM.reporteInfoCredenticia", // true

    "clients[0].retailClientDueDiligenceReport.residentAlienCopyOfResidenceCard": "validacionDocumentosRequeridos.extranjeroResidente.cedulaIdentidad", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.residentAlienCopyOfResidenceCard": "validacionDocumentosRequeridos.extranjeroResidenteMM.cedulaIdentidad", // true
    "clients[0].retailClientDueDiligenceReport.residentAlienCopyOfPassport": "validacionDocumentosRequeridos.extranjeroResidente.pasaporteVigente", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.residentAlienCopyOfPassport": "validacionDocumentosRequeridos.extranjeroResidenteMM.pasaporteVigente", // true
    "clients[0].retailClientDueDiligenceReport.residentAlienNationalityId": "validacionDocumentosRequeridos.extranjeroResidente.imagenIdentificacion", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.residentAlienNationalityId": "validacionDocumentosRequeridos.extranjeroResidenteMM.imagenIdentificacion", // true
    "clients[0].retailClientDueDiligenceReport.residentAlienCountryOfOriginTaxes": "validacionDocumentosRequeridos.extranjeroResidente.declaracionImpuesto", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.residentAlienCountryOfOriginTaxes": "validacionDocumentosRequeridos.extranjeroResidenteMM.declaracionImpuesto", // true
    "clients[0].retailClientDueDiligenceReport.residentAlientIncomeReport": "validacionDocumentosRequeridos.extranjeroResidente.fuenteIngresos", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.residentAlientIncomeReport": "validacionDocumentosRequeridos.extranjeroResidente.MMfuenteIngresos", // true

    "clients[0].retailClientDueDiligenceReport.underageCopyOfId": "validacionDocumentosRequeridos.menoresEdad.cedulaIdentidadMenor", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.underageCopyOfId": "validacionDocumentosRequeridos.menoresEdadMM.cedulaIdentidadMenor", // true
    "clients[0].retailClientDueDiligenceReport.underageNIIU": "validacionDocumentosRequeridos.menoresEdad.numUnicoIdentidad", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.underageNIIU": "validacionDocumentosRequeridos.menoresEdadMM.numUnicoIdentidad", // true
    "clients[0].retailClientDueDiligenceReport.underageBirthCertificate": "validacionDocumentosRequeridos.menoresEdad.actaNacimiento", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.underageBirthCertificate": "validacionDocumentosRequeridos.menoresEdadMM.actaNacimiento", // true
    "clients[0].retailClientDueDiligenceReport.underagePowerOfAttorney": "validacionDocumentosRequeridos.menoresEdad.poderRepresentativo", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.underagePowerOfAttorney": "validacionDocumentosRequeridos.menoresEdadMM.poderRepresentativo", // true
    "clients[0].retailClientDueDiligenceReport.underageTutorRepresentation": "validacionDocumentosRequeridos.menoresEdad.sentenciaAcreditada", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.underageTutorRepresentation": "validacionDocumentosRequeridos.menoresEdadMM.sentenciaAcreditada", // true

    "clients[0].retailClientDueDiligenceReport.nonResidentAlienCopyOfResidenceCard": "validacionDocumentosRequeridos.extranjeroNoResidente.estatusMigratorio", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nonResidentAlienCopyOfResidenceCard": "validacionDocumentosRequeridos.extranjeroNoResidenteMM.estatusMigratorio", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlienCopyOfPassport": "validacionDocumentosRequeridos.extranjeroNoResidente.pasaporteVigente", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nonResidentAlienCopyOfPassport": "validacionDocumentosRequeridos.extranjeroNoResidenteMM.pasaporteVigente", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlienNationalityId": "validacionDocumentosRequeridos.extranjeroNoResidente.imagenIdentificacion", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nonResidentAlienNationalityId": "validacionDocumentosRequeridos.extranjeroNoResidenteMM.imagenIdentificacion", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlienCountryOfOriginTaxes": "validacionDocumentosRequeridos.extranjeroNoResidente.declaracionImpuesto", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nonResidentAlienCountryOfOriginTaxes": "validacionDocumentosRequeridos.extranjeroNoResidenteMM.declaracionImpuesto", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlientIncomeReport": "validacionDocumentosRequeridos.extranjeroNoResidente.fuenteIngresos", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.nonResidentAlientIncomeReport": "validacionDocumentosRequeridos.extranjeroNoResidenteMM.fuenteIngresos", // true

    "clients[0].retailClientDueDiligenceReport.finalBeneficiary": "beneficiarioFinal.identificacionBeneficiarioFinal",
    "clients[0].retailClientDueDiligenceMarketManagerReport.finalBeneficiary": "beneficiarioFinal.identificacionBeneficiarioFinalMM",

    "clients[0].retailClientDueDiligenceReport.isFundsOrigin": "procedenciaDestinoFondos.origenFondos", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.isFundsOrigin": "procedenciaDestinoFondos.origenFondosMM", // true
    "clients[0].retailClientDueDiligenceReport.fundsDestination": "procedenciaDestinoFondos.destinoFondos",// must be number
    "clients[0].retailClientDueDiligenceMarketManagerReport.fundsDestination": "procedenciaDestinoFondos.destinoFondosMM",// must be number

    "clients[0].retailClientDueDiligenceReport.anexedListConsultations": "validacionAnexos.consultasListas", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.anexedListConsultations": "validacionAnexos.consultasListasMM", // true
    "clients[0].retailClientDueDiligenceReport.anexedRiskMatrix": "validacionAnexos.matrizRiesgo", // true
    "clients[0].retailClientDueDiligenceMarketManagerReport.anexedRiskMatrix": "validacionAnexos.matrizRiesgoMM", // true

    "clients[0].retailClientDueDiligenceReport.bussinesManagerObservations": "debDilGerNeg.obsGerNeg",
    "clients[0].retailClientDueDiligenceMarketManagerReport.bussinesManagerObservations": "debDilGerNegMM.obsGerNegMM",
    "clients[0].retailClientDueDiligenceReport.revisionDate": "debDilGerNeg.fechaRevision",
    "clients[0].retailClientDueDiligenceMarketManagerReport.revisionDate": "debDilGerNegMM.fechaRevisionMM",
    "clients[0].retailClientDueDiligenceReport.dueDiligenceResult": "debDilGerNeg.resDebDil",
    "clients[0].retailClientDueDiligenceMarketManagerReport.dueDiligenceResult": "debDilGerNegMM.resDebDilMM",
    "clients[0].retailClientDueDiligenceReport.dueDiligenceComments": "debDilGerNeg.comentarios",
    "clients[0].retailClientDueDiligenceMarketManagerReport.dueDiligenceComments": "debDilGerNegMM.comentariosMM",
    "clients[0].retailClientDueDiligenceReport.sendBackReason": "debDilGerNeg.razonDevolucion",
    "clients[0].retailClientDueDiligenceMarketManagerReport.sendBackReason": "debDilGerNegMM.razonDevolucionMM",

}


export default form;