export const documentosRepAccionistas = {
    title: "Documentos de identificación de representantes, accionistas o firmantes individuales",
    type: "object",
    properties: {
        "documentoIdentRepFirmantes": {
            title: "Documento de Identidad representantes y firmantes (ambos lados)",
            type: "number",
        },
        "segundaIdentRepFirmantes": {
            title: "Segunda identificación representantes y firmantes extranjeros",
            type: "number",

        },
    }
};

export const documentosAccionistasJuridicas = {
    title: "Documentos de identificación accionistas personas jurídicas",
    type: "object",
    properties: {
        "certInc": {
            title: "Certificado de incorporación (Reg. Mercantil o equiv.)",
            type: "number",

        },
        "fechaDeVencimientoCertInc": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "copiaIdAccInd": {
            title: "Copia identificación de accionistas individuales",
            type: "number",

        },
        "fechaDeVencimientoCopiaIdAccInd": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "estatutos": {
            title: "Estatutos",
            type: "number",

        },
        "listaAccionistas": {
            title: "Lista de accionistas",
            type: "number",

        },
    }
}

export const entitySADocuments = {
    title: "Documentos de identificación de Empresa - S.A. , S.A.S. o S.R.L.",
    type: "object",
    properties: {
        "registroMercantil": {
            title: "Registro Mercantil Vigente",
            type: "number",
        },
        "fechaDeVencimientoRegistroMercantil": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "estatutosSociales": {
            title: "Estatutos Sociales y modificaciones",
            type: "number",
        },
        "actaNominaAsambleaOrdinaria": {
            title: "Acta y Nómina última Asamblea Ordinaria que desiga autoridades",
            type: "number",

        },
        "fechaDeEmisionActaNomina": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "listadoSuscriptores": {
            title: "Listado de Suscriptores y/o socios",
            type: "number",
        },
        "fechaDeEmisionListadoSuscriptores": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "certRegistroMercantil": {
            title: "Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero).",
            type: "number",
        },
        "actaAsamblea": {
            title: "Acta de Asamblea que designe la persona física representante.",
            type: "number",
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },
        "certRNCporDGII": {
            title: "Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales.",
            type: "number",
        },
    }
}

export const entityForeignDocuments = {
    title: "Documentos de identificación de Empresa - Empresa Extranjera",
    type: "object",
    properties: {
        "incorporationDocument": {
            title: "Documentos de incorporación (Acta Constitutiva, Estatutos Sociales, Lista de accionistas)",
            type: "number",
        },
        "incorporationCertificate": {
            title: "Certificado de Incorporación, Good Standing o su equivalente, atendiendo a la legislación de origen.",
            type: "number",
        },
        "actaDirectores": {
            title: "Acta que designa los Directores y sus poderes de representación",
            type: "number",

        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acto que designa las personas autorizadas a firmar",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "listadoSuscriptores": {
            title: "Lista de Suscriptores y/o socios vigente",
            type: "number",
        },
        "fechaDeEmisionListadoSuscriptores": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "certRegistroMercantil": {
            title: "Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero).",
            type: "number",
        },
        "actaAsamblea": {
            title: "Acta y Nómina de Asamblea Constitutiva",
            type: "number",
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },
        "certRNCporDGII": {
            title: "Copia de la identificación tributaria de acuerdo a la legislación de origen y Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales",
            type: "number",
        },
    }
}

export const entityEIRLDocuments = {
    title: "Documentos de identificación de Empresa - E.I.R.L",
    type: "object",
    properties: {
        "registroMercantil": {
            title: "Registro Mercantil Vigente",
            type: "number",
        },
        "fechaDeVencimientoRegistroMercantil": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "estatutosSociales": {
            title: "Acto donde consten los Estatutos Sociales y sus modificaciones",
            type: "number",
        },
        "actaDesignacionGerencia": {
            title: "Acto que designa la Gerencia, vigente.",
            type: "number",

        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "actaPropietarios": {
            title: "Acto Constitutivo donde conste el Propietario",
            type: "number",
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },
        "certRNCporDGII": {
            title: "Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales.",
            type: "number",
        },
    }
}

export const entityNonProfit = {
    title: "Documentos de identificación de Empresa - Sin Fines de Lucro",
    type: "object",
    properties: {
        "registroEnRepublica": {
            title: "Resolución o Autorización del Registro de Incorporación de la Procuraduría General de la República.",
            type: "number",
        },
        "estatutosSociales": {
            title: "Estatutos y sus modificaciones",
            type: "number",
        },
        "actaDesignacionGerencia": {
            title: "Acta de la última reunión de la Junta General que designa los miembros actuales de la Junta Directiva",
            type: "number",

        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "actaConstitutiva": {
            title: "Acta Constitutiva",
            type: "number"
        },
        "listaDeMiembros": {
            title: "Lista de Miembros",
            type: "number"
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },
        "certRNCporDGII": {
            title: "Copia del documento emitido por la DGII, mediante el cual se asigna el RNC.",
            type: "number",
        },
    }
}

export const entityCooperative = {
    title: "Documentos de identificación de Empresa - Cooperativa",
    type: "object",
    properties: {
        "registroEnRepublica": {
            title: "Resolución o Autorización del Registro de Incorporación de la Procuraduría General de la República.",
            type: "number",
        },
        "estatutosSociales": {
            title: "Estatutos y sus modificaciones",
            type: "number",
        },
        "actaDesignacionGerencia": {
            title: "Acta de la última reunión de la Junta General que designa los miembros actuales de la Junta Directiva",
            type: "number",

        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "actaConstitutiva": {
            title: "Acta Constitutiva",
            type: "number"
        },
        "listaDeMiembros": {
            title: "Lista de Miembros",
            type: "number"
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },

        "certRNCporDGII": {
            title: "Copia del documento emitido por la DGII, mediante el cual se asigna el RNC.",
            type: "number",
        },
        "certificadoIncorporacionIDECOOP": {
            title: "Certificado de Incorporación IDECOOP",
            type: "number",

        },
        "fechaDeEmisionCertificadoIncorporacionIDECOOP": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
    }
}

export const entityGovAgency = {
    title: "Documentos de identificación de Empresa - Empresa Gubernamental",
    type: "object",
    properties: {
        "incorporationDocument": {
            title: "Copia del Decreto que evidencia su creación o incorporación.",
            type: "number",
        },
        "actaDirectores": {
            title: "Certificación de la resolución del ente u órgano competente mediante el cual se designa el representante legal, Ministro en curso o Director, según sea el caso",
            type: "number",
        },
        "actaDesignacionGerencia": {
            title: "Según el tipo de entidad (si posee órganos de gestión internos), Certificación de la resolución del ente u órgano competente mediante el cual se autoriza la apertura de cuentas de valores a través de los intermediarios de valores y las personas autorizadas a dar instrucciones al efecto, o copia del Decreto que consigna dichos poderes.",
            type: "number",

        },
        "actaDesignaFirmantesAutorizados": {
            title: "De los representantes legales y otros firmantes, copia de copia de Cédula (ambos lados)",
            type: "number",

        },
        "certRNCporDGII": {
            title: "Copia del documento emitido por la DGII, mediante el cual se asigna el RNC.",
            type: "number",
        },
    }
}

export const entityConsorcio = {
    title: "Documentos de identificación de Empresa - Consorcio",
    type: "object",
    properties: {
        "incorporationDocument": {
            title: "Acta de inscripción al RNC",
            type: "number",
        },
        "estatutosSociales": {
            title: "Estatutos Sociales y modificaciones",
            type: "number",
        },
        "actaNominaAsambleaOrdinaria": {
            title: "Acta de la última Asamblea Ordinaria Anual que designa los miembros del Consejo de Administración, Gerentes o Junta de Miembros, vigente.",
            type: "number",

        },
        "fechaDeEmisionActaNomina": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "listadoSuscriptores": {
            title: "Listado de Suscriptores y/o socios",
            type: "number",
        },
        "fechaDeEmisionListadoSuscriptores": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "certRegistroMercantil": {
            title: "Copia del Certificado de Registro Mercantil o su equivalente (si es extranjero).",
            type: "number",
        },

        "actaAsamblea": {
            title: "Acta de Asamblea que designe la persona física representante",
            type: "number",
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },
        "certRNCporDGII": {
            title: "Copia de la identificación tributaria de acuerdo a la legislación de origen y Certificación del RNC emitida por la DGII, en la cual se establezca que la sociedad se encuentra al día en sus obligaciones fiscales",
            type: "number",
        },
    }
}

export const entityFideicomiso = {
    title: "Documentos de identificación de Empresa - Fideicomiso",
    type: "object",
    properties: {
        "contratoConsorcioFideicomiso": {
            title: "Contrato del Consorcio o Fideicomiso",
            type: "number",

        },
        "fechaDeVencimientoContratoConsorcioFideicomiso": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "documentosSocietariosFiduciaria": {
            title: "Documentos Societarios Fiduciaria",
            type: "number",

        },
        "añoDocumentosSocietariosFiduciaria": {
            title: "Año",
            type: "number",
        },
        "estatutosSociales": {
            title: "Estatutos Sociales y modificaciones",
            type: "number",
        },
        "actaNominaAsambleaOrdinaria": {
            title: "Acta de la última Asamblea Ordinaria Anual que designa los miembros del Consejo de Administración, Gerentes o Junta de Miembros, vigente",
            type: "number",

        },
        "fechaDeEmisionActaNomina": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },
        "actaDesignaFirmantesAutorizados": {
            title: "Acta que designa firmantes autorizados",
            type: "number",

        },
        "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
            title: "Fecha de vencimiento",
            type: "string",
            format: "date"
        },
        "listadoSuscriptores": {
            title: "Listado de Suscriptores y/o socios",
            type: "number",
        },
        "fechaDeEmisionListadoSuscriptores": {
            title: "Fecha de emisión",
            type: "string",
            format: "date"
        },

        "actaAsamblea": {
            title: "Acta de Asamblea que designe la persona física representante",
            type: "number",
        },
        "estadosFinancieros": {
            title: "Estados financieros auditados de los últimos 2 años y/o Declaraciones de Renta.",
            type: "number",
        },
    }
}

export const UIDocumentosIdentificacionEmpresa = {
    "classNames": "smallTwoRows",
    "firmantesAutorizadosNestedList": {
        "ui:options": {
            "orderable": false
        },
        "classNames": "from-col-1 to-col-3"
    },
    "registroMercantil": { "ui:field": "FileField", "joli:required": true },
    "certificacionRNC": { "ui:field": "FileField", "joli:required": true },
    "estatutosSociales": { "ui:field": "FileField", "joli:required": true },
    "actaNominaAsambleaOrdinaria": { "ui:field": "FileField", "joli:required": true, "classNames": "from-col-1 to-col-2" },
    "actaDesignaFirmantesAutorizados": { "ui:field": "FileField", "joli:required": true },
    "actaAsambleaConstitutiva": { "ui:field": "FileField", "joli:required": true },
    "listadoSuscriptores": { "ui:field": "FileField", "joli:required": true },
    "actaAsambleaRepresentanteLegal": { "ui:field": "FileField", "joli:required": true },
    "certificadoIncorporacionIDECOOP": { "ui:field": "FileField", "joli:required": true },
    "contratoConsorcioFideicomiso": { "ui:field": "FileField", "joli:required": true },
    "documentosSocietariosFiduciaria": { "ui:field": "FileField", "joli:required": true },
    "actaDecretoPoderes": { "ui:field": "FileField", "joli:required": true },
    "estadosFinancieros": { "ui:field": "FileField", "joli:required": true },
    "certRegistroMercantil": { "ui:field": "FileField", "joli:required": true },
    "actaAsamblea": { "ui:field": "FileField", "joli:required": true },
    "certRNCporDGII": { "ui:field": "FileField", "joli:required": true },
    "incorporationDocument": { "ui:field": "FileField", "joli:required": true },
    "incorporationCertificate": { "ui:field": "FileField", "joli:required": true },
    "actaDesignacionGerencia": { "ui:field": "FileField", "joli:required": true },
    "actaPropietarios": { "ui:field": "FileField", "joli:required": true },
    "actaConstitutiva": { "ui:field": "FileField", "joli:required": true },
    "listDeMiembros": { "ui:field": "FileField", "joli:required": true },
    "actaDirectores": { "ui:field": "FileField", "joli:required": true },
    "registroEnRepublica": { "ui:field": "FileField", "joli:required": true },
    "listaDeMiembros": { "ui:field": "FileField", "joli:required": true },
}

     // "documentosIdentificacionEmpresa": {
        //     title: "Documentos de identificación de Empresa",
        //     type: "object",
        //     properties: {
        //         "registroMercantil": {
        //             title: "Registro Mercantil o documento de incorporación",
        //             type: "number",
        //         },
        //         // "numeroRegistroMercantil":{
        //         //     title: "No. Registro Mercantil",
        //         //     type: "string"
        //         // },
        //         "fechaDeVencimientoRegistroMercantil": {
        //             title: "Fecha de vencimiento",
        //             type: "string",
        //             format: "date"
        //         },
        //         "certificacionRNC": {
        //             title: "Certificación RNC",
        //             type: "number",

        //         },
        //         "fechaDeVencimientoCertificacionRNC": {
        //             title: "Fecha de vencimiento",
        //             type: "string",
        //             format: "date"
        //         },
        //         "estatutosSociales": {
        //             title: "Estatutos sociales y modificaciones",
        //             type: "number",

        //         },
        //         "actaNominaAsambleaOrdinaria": {
        //             title: "Acta y Nómina última Asamblea Ordinaria que desiga autoridades",
        //             type: "number",

        //         },
        //         "fechaDeEmisionActaNomina": {
        //             title: "Fecha de emisión",
        //             type: "string",
        //             format: "date"
        //         },
        //         "actaDesignaFirmantesAutorizados": {
        //             title: "Acta que designa firmantes autorizados",
        //             type: "number",

        //         },
        //         "fechaDeVencimientoActaDesignaFirmantesAutorizados": {
        //             title: "Fecha de vencimiento",
        //             type: "string",
        //             format: "date"
        //         },

        //         "actaAsambleaConstitutiva": {
        //             title: "Acta de Asamblea Constitutiva",
        //             type: "number",

        //         },
        //         "fechaDeEmisionActaAsambleaConstitutiva": {
        //             title: "Fecha de emisión",
        //             type: "string",
        //             format: "date"
        //         },
        //         "listadoSuscriptores": {
        //             title: "Listado de Suscriptores y/o socios",
        //             type: "number",

        //         },
        //         "fechaDeEmisionListadoSuscriptores": {
        //             title: "Fecha de emisión",
        //             type: "string",
        //             format: "date"
        //         },
        //         "actaAsambleaRepresentanteLegal": {
        //             title: "Acta Asamblea que designa representante legal",
        //             type: "number",

        //         },
        //         "fechaDeEmisionActaAsambleaRepresentanteLegal": {
        //             title: "Fecha de emisión",
        //             type: "string",
        //             format: "date"
        //         },
        //         "certificadoIncorporacionIDECOOP": {
        //             title: "Certificado de Incorporación IDECOOP",
        //             type: "number",

        //         },
        //         "fechaDeEmisionCertificadoIncorporacionIDECOOP": {
        //             title: "Fecha de emisión",
        //             type: "string",
        //             format: "date"
        //         },
        //         "contratoConsorcioFideicomiso": {
        //             title: "Contrato del Consorcio o Fideicomiso",
        //             type: "number",

        //         },
        //         "fechaDeVencimientoContratoConsorcioFideicomiso": {
        //             title: "Fecha de vencimiento",
        //             type: "string",
        //             format: "date"
        //         },
        //         "documentosSocietariosFiduciaria": {
        //             title: "Documentos Societarios Fiduciaria",
        //             type: "number",

        //         },
        //         "añoDocumentosSocietariosFiduciaria": {
        //             title: "Año",
        //             type: "number",
        //         },
        //         "actaDecretoPoderes": {
        //             title: "Acta o decreto que consigna poderes",
        //             type: "number",

        //         },
        //         "fechaDeVencimientoActaDecretoPoderes": {
        //             title: "Fecha de vencimiento",
        //             type: "string",
        //             format: "date"
        //         },
        //         "firmantesAutorizadosNestedList": {
        //             type: "array",
        //             title: " ",
        //             description: "Firmantes autorizados",
        //             minItems: 1,
        //             maxItems: 3,
        //             items: {
        //                 type: "object",
        //                 properties: {
        //                     "nombre": {
        //                         title: "Nombre",
        //                         type: "string",
        //                     },
        //                     "posicion": {
        //                         title: "Posición",
        //                         type: "string",
        //                     },
        //                     // "cedula":{
        //                     //     title: "Cédula de identidad",
        //                     //     type: "string",
        //                     // },
        //                     // "nacionalidad":{
        //                     //     title: "Nacionalidad",
        //                     //     type: "string",
        //                     // }
        //                 }
        //             }
        //         },
        //     }
        // },