import React, { useMemo, useState } from 'react';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Table,
} from 'reactstrap';
import { useParams } from "react-router-dom";
import dayjs from 'dayjs';

import useResourceLoader from '../../util/useResourceLoader';
import CasesApi from '../../api/CasesApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';

export function CaseDDDecision({
    id: caseId
}) {
    const [decision, loading, error] = useResourceLoader(
        () => CasesApi.getCaseDDDecision({ caseId }),
        [caseId],
        {}
    );

    return (<>
        {loading ? <Loader fullscreen /> : null}
        {error ? <Notification error={error} /> : null}
        <Table>
            <tbody>
                <th>result</th><td>{JSON.stringify(decision.result)}</td>
                {Object.entries(decision).filter(([k]) => k !== 'result').map(([k, v]) => (<tr key={k}>
                    <th>{k}</th><td>{JSON.stringify(v)}</td>
                </tr>))}
            </tbody>
        </Table>
    </>);
}

export default CaseDDDecision;