import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import Select from "react-select";

import useResourceLoader from '../util/useResourceLoader';
import Loader from '../components/Loader';
import Notification from '../components/Notification';
import LookupApi from '../api/LookupApi';
import useLoader from '../util/useLoader';


function ReassignModal({
    page,
    roleAssignment,
    assignedUser,
    onReassign,
    onCloseModal,
}){
    const [users, loadingUsers, errorLoadingUsers] = useResourceLoader(() => LookupApi.getUsers({roles: roleAssignment.roles}), []);
    const [selectedUser, setSelectedUser] = useState(assignedUser);
    const [loadingReassign, errorOnReassign, loadFn] = useLoader();

    function select(userId){
        loadFn(() => onReassign(page, userId));
    }

    return (<Modal isOpen>
        <ModalHeader>Reasignar {(roleAssignment || {}).assignment}</ModalHeader>
        {loadingReassign ? <Loader fullscreen /> : null}
        <ModalBody>
            {loadingUsers ? (
                <Loader />
            ) : (errorLoadingUsers ? (
                <Notification error={errorLoadingUsers} />
            ) : (
                <Select
                    autoFocus
                    isSearchable
                    filterOption={(data, search) => {
                        const {
                            firstName,
                            lastName,
                            role,
                            branch,
                        } = data || {};
                        return !search || new RegExp(search).test([
                            firstName,
                            lastName,
                            (role || {}).displayName,
                            (branch || {}).name,
                        ].join(' '));
                    }}
                    isLoading={loadingUsers}
                    value={selectedUser}
                    options={users}
                    onChange={user => setSelectedUser(user)}
                    noOptionsMessage={() => "No hay usuarios para escoger"}
                    formatOptionLabel={({
                        firstName, lastName, role, branch
                    }) => (<div className="user">
                        <span className="name">{`${firstName} ${lastName}`}</span>
                        <span className="role">{(role || {}).displayName}</span>
                        <span className="branch">{(branch || {}).name}</span>
                    </div>)}
                    isOptionSelected={option => selectedUser && option.id === selectedUser.id}
                />
            ))}
            {errorOnReassign ? <Notification error={errorOnReassign} /> : null}
        </ModalBody>
        <ModalFooter>
            <Button color="primary" disabled={!selectedUser} onClick={() => select(selectedUser.id)} >Reasignar</Button>
            <Button color="secondary" onClick={() => onCloseModal()} >Cancelar</Button>
        </ModalFooter>
    </Modal>)
}


export default ReassignModal;