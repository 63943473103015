import { Trans } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Form, Nav, NavItem, NavLink } from 'reactstrap';
import { Link, useHistory, useParams } from "react-router-dom";

import useResourceLoader from '../../util/useResourceLoader';
import CasesApi from '../../api/CasesApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import forms from "../forms/forms";

import CaseHistory from './CaseHistory';
import CaseAssignments from './CaseAssignments';
import CaseNotes from './CaseNotes';
import CaseDDDecision from './CaseDDDecision';
import CasePreviousForms from './CasePreviousForms';
import WaitForCaseFiles from "../../components/WaitForCaseFiles";
import RequestReloadContext from '../../context/RequestReloadContext';

import { toDrDateTime } from '../../util/dates';
import { AuthContext } from '../../context/AuthContext';
import roleNameMatches from '../../util/roleNameMatches';
import canViewStatus from '../../util/canViewStatus';
import { NO_FORM } from '../../constants';
import useSearchParams from '../../util/useSearchParams';
import CaseDetailsPanel from '../../blc/CaseDetailsPanel';
import CurrentCaseForm from "../../blc/CurrentCaseForm";

const getFileFormComponent = (clientTypeId) => {
    switch (clientTypeId) {
        case 2:
        case 3:
            return 'case-files-business';
        default:
            return 'case-files';
    }
}


export function CaseDetails({
    history,
    location
}) {
    const { id } = useParams();
    const [
        [show, setShow],
        [readonlyParam],
        [sentFrom]
    ] = useSearchParams({
        show: 'form',
        readonly: undefined,
        from: undefined
    });

    const [reloadCount, setReloadCount] = useState(sentFrom === 'case-intake' ? 1 : 0);

    const [currentCase, loading, errorLoadingCases] = useResourceLoader(() => {
        // console.log("reloadCount", reloadCount);
        return CasesApi.getCase({ caseId: id });
    }, [reloadCount], null);

    const isClosed = (currentCase || {}).isOpen === false;
    const isComplete = (currentCase || {}).completed === true;
    const readonly = !!(readonlyParam * 1) || isClosed;
    const clientTypeId = ((((currentCase || {}).clients || [])[0] || {}).clientTypeId || null);
    const [statusIdx, setStatusIdx] = useState(0);

    const auth = useContext(AuthContext);
    const { closeCase, viewPreviousForms , limitColumnAndFilterView, reassignCases} = ((auth || {}).user || {}).permissions || {};
    const statuses = useMemo(() => currentCase && JSON.parse(currentCase.status).filter(status => canViewStatus(status, auth)), [currentCase]);

    const currentStatus = useMemo(() => {
        if (!statuses) return null;
        return Number.isFinite(statusIdx) ? statuses[statusIdx] : (statuses.length === 1 ? statuses[0] : null);
    }, [statuses, statusIdx]);

    const rrcState = RequestReloadContext.useHook(() => {
        console.log('rrcState hook', reloadCount);
        setReloadCount(reloadCount + 1);
    }, [reloadCount]);

    const [showDebugControls, setShowDebugControls] = useState();

    // console.log('clienttypeid ->', clientTypeId);

    const formComponentPage = getFileFormComponent(clientTypeId);
    const { page } = currentStatus || {};
    const { name } = currentStatus || {};
    
    const FormComponent = forms[page];
    const hasNoForm = FormComponent === NO_FORM;
    const FileFormComponent = forms[formComponentPage];
    const CloseCaseFormComponent = forms['case-close'];
    const pagesWithoutFileShow = [
        'case-intake',
        'short-kyc-personal',
        'short-kyc-business',
        'requirements-upload-personal-client',
        'requirements-upload-business-client',
        'waiting-for-document-generation-full-kyc',
        'waiting-for-document-generation-compliance',
        'waiting-for-document-generation-credit-approval',
        'waiting-for-document-generation-pre-disbursement',
    ];
    const showFilesControls = !pagesWithoutFileShow.includes(page)

    useEffect(() => {
        if (reloadCount > 0 && !page) {
            history.push("/");
        }
    }, [reloadCount, page])

    const tabs = useMemo(() => ([
        ...(!readonly && !hasNoForm ? [
            { tab: "form", title: `Formulario` },
        ] : []),
        ...(viewPreviousForms ? [
            { tab: "previousForms", title: "Formularios Anteriores" }
        ] : []),
        ...(showFilesControls ? [
            { tab: "files", title: "Documentos" },
        ] : []),
        ...(showDebugControls ? ([
            { tab: "history", title: "Log de Estados" },
            { tab: "dd", title: "Decisión DD" },
        ]) : []),
        ...(!limitColumnAndFilterView ? ([
            { tab: "assignments", title: "Asignaciones" },
            { tab: "notes", title: "Notas" },
        ]) : []),
        ...(!isComplete && (!isClosed && closeCase) ? [
            { tab: "close", title: "Desestimar Caso", side: "right" }
        ] : []),
    ]).reduce((_, t) => {
        _[t.tab] = t;
        return _;
    }, {}), [
        hasNoForm,
        showDebugControls,
        showFilesControls,
        isComplete
    ])

    const activeTab = tabs[show] || tabs.form || tabs.previousForms || Object.values(tabs)[0];
    const { awaitDocuments } = currentStatus || {};
    const showControls = !awaitDocuments;

    return (loading ? (
        <Loader fullscreen />
    ) : (errorLoadingCases ? (
        <Notification color="danger">{JSON.stringify(errorLoadingCases)}</Notification>
    ) : (!currentCase ? (
        <Notification color="danger">Caso no encontrado. Esto debe ser un error, por favor contacte a la administraci&oacute;n).</Notification>
    ) : (<div>
        <RequestReloadContext.Provider value={rrcState}>
            <button style={{
                position: 'absolute',
                top: 60,
                right: 0,
                opacity: '1%'
            }} onClick={() => setShowDebugControls(!showDebugControls)}>
                {showDebugControls ? 'hide' : 'show'} debug
            </button>

            {showControls ? (<>

                <CaseDetailsPanel current={currentCase} />

                {isClosed ? <div className="stamp-across">Desestimado</div> : null}

                <Nav tabs className="form-tabs">{Object.entries(tabs).map(([key, tab]) => (
                    <NavItem key={key} className={tab.side ? `side-${tab.side}` : ''}><NavLink
                        active={activeTab === tab}
                        onClick={() => setShow(key)}
                    >{tab.title}</NavLink></NavItem>
                ))}</Nav>

                {activeTab ? (activeTab.tab === "files" ? (
                    <FileFormComponent readonly={readonly} currentCase={currentCase} page='case-files' />
                ) : (activeTab.tab === "previousForms" ? (
                    <CasePreviousForms readonly={readonly} currentCase={currentCase} />
                ) : (activeTab.tab === "history" ? (
                    <CaseHistory readonly={readonly} id={id} />
                ) : (activeTab.tab === "assignments" ? (
                    <CaseAssignments readonly={readonly} id={id} />
                ) : (activeTab.tab === "notes" ? (
                    <CaseNotes readonly={readonly} id={id} />
                ) : (activeTab.tab === "dd" ? (
                    <CaseDDDecision id={id} />
                ) : (activeTab.tab === "close" ? (
                    <CloseCaseFormComponent currentCase={currentCase} />
                ) : (activeTab.tab === "form" ? (
                        <> {statuses.length > 1 ? (<>
                            <select value={statusIdx} onChange={({ target: { value } }) => setStatusIdx(value | 0)}>{statuses.map(({ name }, index) => (
                                <option key={index} value={index}>{name}</option>
                            ))}</select>
                        </>) : null}</>
                ) : (<>
                    {showDebugControls ? <DebugControls
                        id={id}
                        readonly={readonly}
                        currentCase={currentCase}
                        statuses={statuses}
                        currentStatus={currentStatus}
                        statusIdx={statusIdx}
                        setStatusIdx={setStatusIdx}
                    /> : null}
                </>))))))))) : null}
            </>) : null}

            

            {hasNoForm ? null : (
                <CurrentCaseForm hide={activeTab !== tabs.form} currentCase={currentCase} currentStatus={currentStatus} />
            )}

        </RequestReloadContext.Provider>
    </div>))));
}


function DebugControls({
    id, currentCase,
    statuses,
    currentStatus,
    statusIdx, setStatusIdx,
}) {
    const history = useHistory();
    const { page, role, canSendBack } = currentStatus || {};

    async function simulateFormSubmit() {
        await CasesApi.updateCase({ id, page, formData: currentCase });
        history.push("/");
    }

    async function simulateFormSendBack() {
        await CasesApi.updateCase({ id, page, formData: { ...currentCase, sentBack: 1 } });
        history.push("/");
    }

    return (<>
        {statuses.length > 1 ? (<>
            View Form
            <select value={statusIdx} onChange={({ target: { value } }) => setStatusIdx(value | 0)}>{statuses.map(({ page, role }, index) => (
                <option key={index} value={index}>{page} ({role})</option>
            ))}</select>
        </>) : null}
        <div>Current Form page {JSON.stringify(page)} (role: {JSON.stringify(role)})</div>
        <Button color="primary" onClick={simulateFormSubmit}>Simulate Form Submit</Button>
        {canSendBack ? (
            <Button color="primary" onClick={simulateFormSendBack}>Simulate Form Send Back</Button>
        ) : null}
        <hr />
    </>);
}


export default CaseDetails;