import secondaryClientForm from "../secondary/InvestmentClientCreationConfirmationSchema";

const form = {
    title: "Creación Cliente Sistemas",
    displayName: 'InvestmentClientCreationConfirmation',
    page: 'investment-client-creation-confirmation',
    secondaryClientForm,
};


form.schema = {
    title: "Creación de cliente JMMB Puesto de Bolsa y JMMB SAFI",
    type: "object",
    properties: {
        // "infCliUCS": {
        //     title: " ",
        //     description:"Informaciones Cliente UCS",
        //     type:"object",
        //     properties:{
        //         "cliUCS":{
        //             title: "Cliente creado en UCS",
        //             type: "boolean",
        //         },
        //         "numCliUCS":
        //         {
        //             title: "Número de cliente en UCS",
        //             type: "string"
        //         },
        //     },

        // },
        "infCliDIMSA": {
            title: " ",
            description: "Informaciones Cliente DIMSA",
            type: "object",
            properties: {
                "cliDIMSA": {
                    title: "Cliente creado en DIMSA",
                    type: "boolean",
                },
                "numCliDIMSA":
                {
                    title: "Número de cliente en DIMSA",
                    type: "string"
                },
            },

        },
        "infCliT24SAFI": {
            title: " ",
            description: "Informaciones Cliente T24SAFI",
            type: "object",
            properties: {
                "cliT24SAFI": {
                    title: "Cliente creado en T24 SAFI",
                    type: "boolean",
                },
                "numCliT24SAFI":
                {
                    title: "Número de cliente en T24 SAFI",
                    type: "string"
                },

            },

        },
        "dineroDOP": {
            title: "Encargo FM Mercado de Dinero (DOP) creado",
            type: "boolean",
            oneOf: [
                { title: "Si", const: true },
                { title: "No", const: false },
            ],
        },
        "dineroUSD": {
            title: "Encargo FM Mercado de Dinero (USD) creado",
            type: "boolean",
            oneOf: [
                { title: "Si", const: true },
                { title: "No", const: false },
            ],
        },
        "plazoDOP": {
            title: "Encargo FM Plazo 90 días (DOP) creado",
            type: "boolean",
            oneOf: [
                { title: "Si", const: true },
                { title: "No", const: false },
            ],
        },
    },
    dependencies: {
        "dineroDOP": {
            oneOf: [
                {
                    properties: {
                        "dineroDOP": { const: true },
                        "numEncargoDineroDOP": {
                            title: "Número de Aportante FM Mercado de Dinero (DOP)",
                            type: "string",
                        },
                    }
                },
                {
                    properties: {
                        "dineroDOP": { const: false },
                    }
                },
            ]
        },
        "dineroUSD": {
            oneOf: [
                {
                    properties: {
                        "dineroUSD": { const: true },
                        "numEncargoDineroUSD": {
                            title: "Número de Aportante FM Mercado de Dinero (USD)",
                            type: "string",
                        },
                    }
                },
                {
                    properties: {
                        "dineroUSD": { const: false },
                    }
                },
            ]
        },
        "plazoDOP": {
            oneOf: [
                {
                    properties: {
                        "plazoDOP": { const: true },
                        "numEncargoPlazoDOP": {
                            title: "Número de Aportante FM Plazo 90 días (DOP)",
                            type: "string",
                        },
                    }
                },
                {
                    properties: {
                        "plazoDOP": { const: false },
                    }
                },
            ]
        },
    },
}

form.uiSchema = {
    "ui:field": "HideObjectField",
    "ui:order": [
        "*",
        "dineroDOP",
        "numEncargoDineroDOP",
        "dineroUSD",
        "numEncargoDineroUSD",
        "plazoDOP",
        "numEncargoPlazoDOP",
    ],
    "infCliUCS": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 1
            }
        }
    },
    "infCliDIMSA": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 2
            }
        }
    },
    "infCliT24SAFI": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 3
            }
        }
    },
    "dineroDOP": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 3
            }
        }
    },
    "dineroUSD": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 3
            }
        }
    },
    "plazoDOP": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 3
            }
        }
    },
}

form.objectMap = {
    "clients[0].retailClientInvestorProfile.moneyMarketDOP": "dineroDOP",
    "clients[0].retailClientInvestorProfile.moneyMarketUSD": "dineroUSD",
    "clients[0].retailClientInvestorProfile.moneyMarketUSDNumber": "numEncargoDineroUSD",
    "clients[0].retailClientInvestorProfile.term90DaysDOP": "plazoDOP",
    "clients[0].retailClientInvestorProfile.moneyMarketDOPNumber": "numEncargoDineroDOP",
    "clients[0].retailClientInvestorProfile.clientCreatedDIMSA": "infCliDIMSA.cliDIMSA",
    "clients[0].retailClientInvestorProfile.dimsaClientId": "infCliDIMSA.numCliDIMSA",
    "clients[0].retailClientInvestorProfile.clientCreatedT24SAFI": "infCliT24SAFI.cliT24SAFI",
    "clients[0].retailClientInvestorProfile.t24safiClientId": "infCliT24SAFI.numCliT24SAFI",
    "clients[0].retailClientInvestorProfile.term90DayDOPsNumber": "numEncargoPlazoDOP"
}


export default form;