

const dropdownOptions = {
    'pais': [
        "República Dominicana",
        "Afganistán",
        "Albania",
        "Alemania",
        "Andorra",
        "Angola",
        "Anguila",
        "Antigua y Barbuda",
        "Arabia Saudita",
        "Argelia",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaiyán",
        "Bahamas",
        "Bangladesh",
        "Barbados",
        "Baréin",
        "Belice",
        "Benín",
        "Bielorrusia",
        "Birmania",
        "Bolivia",
        "Bonaire, San Eustaquio y Saba",
        "Bosnia-Herzegovina",
        "Botswana - en la Lista de Definición AML del GAFI",
        "Brasil",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso - en la Lista de Definición AML del GAFI",
        "Burundi",
        "Bután",
        "Bélgica",
        "Cabo Verde",
        "Camboya",
        "Camerún",
        "Canadá",
        "Chad",
        "Chile",
        "China",
        "Chipre",
        "Cisjordania (Territorio Palestino, O",
        "Colombia",
        "Comoras",
        "Congo (Brazzaville)",
        "Congo, República Democrática",
        "Corea del Norte",
        "Corea del Sur",
        "Costa Rica",
        "Costa de Marfil",
        "Croacia",
        "Cuba",
        "Curazao",
        "Dinamarca",
        "Dominica",
        "Ecuador",
        "Egipto",
        "El Salvador",
        "Emiratos Árabes Unidos",
        "Eritrea",
        "Eslovaquia",
        "Eslovenia",
        "España",
        "Estado de la Ciudad del Vaticano (Santa Sede)",
        "Estados Unidos",
        "Estonia",
        "Etiopía",
        "Federación Rusa",
        "Fiji",
        "Filipinas",
        "Finlandia",
        "Francia",
        "Franja de Gaza",
        "Gabón",
        "Gambia",
        "Georgia",
        "Ghana",
        "Gibraltar",
        "Granada",
        "Grecia",
        "Groenlandia",
        "Guadalupe",
        "Guam",
        "Guatemala",
        "Guayana",
        "Guayana Francesa",
        "Guernesey",
        "Guinea",
        "Guinea Ecuatorial",
        "Guinea-Bisáu",
        "Haití",
        "Honduras",
        "Hong Kong",
        "Hungría",
        "India",
        "Indonesia",
        "Irak",
        "Irlanda",
        "Irán, República Islámica de",
        "Isla Norfolk",
        "Isla de Navidad",
        "Isla del Hombre",
        "Islandia",
        "Islas Aland",
        "Islas Bermudas",
        "Islas Caimán",
        "Islas Cocos (Keeling)",
        "Islas Cook",
        "Islas Faroe",
        "Islas Malvinas (Malvinas)",
        "Islas Marianas del Norte",
        "Islas Marshall",
        "Islas Salomón",
        "Islas Turcas y Caicos",
        "Islas Vírgenes Británicas",
        "Islas Vírgenes de los Estados Unidos",
        "Israel",
        "Italia",
        "Jamaica",
        "Japón",
        "Jersey",
        "Jordán",
        "Katar",
        "Kazajstán",
        "Kenia",
        "Kirguistán",
        "Kiribati",
        "Kosovo",
        "Kuwait",
        "Lesoto",
        "Letonia",
        "Liberia",
        "Libia",
        "Liechtenstein",
        "Lituania",
        "Luxemburgo",
        "Líbano",
        "Macao",
        "Macedonia",
        "Madagascar",
        "Malasia",
        "Malaui",
        "Maldivas",
        "Malta",
        "Malí",
        "Marruecos",
        "Martinica",
        "Mauricio - en la lista de definiciones ALD del GAFI",
        "Mauritania",
        "Mayotte",
        "Micronesia",
        "Moldavia",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Mozambique",
        "México",
        "Mónaco",
        "Namibia",
        "Nauru",
        "Nepal",
        "Nicaragua",
        "Nigeria",
        "Niue",
        "Noruega",
        "Nueva Caledonia",
        "Nueva Zelanda",
        "Níger",
        "Omán",
        "Pakistán",
        "Palaos",
        "Panamá",
        "Papúa Nueva Guinea",
        "Paraguay",
        "Países Bajos",
        "Perú",
        "Pitcairn",
        "Polinesia francesa",
        "Polonia",
        "Portugal",
        "Puerto Rico",
        "Reino Unido",
        "Rep Centroafricana",
        "Republica Checa",
        "República Democrática Popular Lao",
        "Reunión",
        "Ruanda",
        "Rumania",
        "Sahara Occidental",
        "Samoa",
        "Samoa Americana",
        "San Berthélemy",
        "San Cristóbal y Nieves",
        "San Marino",
        "San Martín",
        "San Martín (parte francesa)",
        "San Pedro y Miquelón",
        "San Vicente y Granadinas",
        "Santa Elena, Ascensión y Trista",
        "Santa Lucía",
        "Santo Tomé y Prin.",
        "Seicheles",
        "Senegal",
        "Serbia",
        "Sierra Leona",
        "Singapur",
        "Siria",
        "Somalia",
        "Sri Lanka",
        "Suazilandia (Esuatini)",
        "Sudáfrica",
        "Sudán",
        "Sudán del Sur",
        "Suecia",
        "Suiza",
        "Surinam",
        "Svalbard y Mayen",
        "Tailandia",
        "Taiwán",
        "Tanzania",
        "Tayikistán",
        "Territorio Británico del Océano Índico",
        "Timor Oriental",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad y Tobago",
        "Turkmenistán",
        "Turquía",
        "Tuvalu",
        "Túnez",
        "Ucrania",
        "Uganda",
        "Uruguay",
        "Uzbekistán",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Wallis y Futuna",
        "Yemen",
        "Yibuti",
        "Zambia",
        "Zimbabue"
    ],
    'zonaGeograficaNacional': [
        "Distrito Nacional",
        "Santo Domingo",
        "Santiago",
        "Punta Cana",
        "Azua",
        "Bahoruco",
        "Dajabón",
        "Duarte",
        "Elias Piña",
        "El Seibo",
        "Espaillat",
        "Hato Mayor",
        "Hermanas Mirabal",
        "Iniependencia",
        "La Altagracia",
        "La Romana",
        "La Vega",
        "María Trinidad Sánchez",
        "Monseñor Nouel",
        "Monte Cristo",
        "Monte Plata",
        "Pedernales",
        "Peravia",
        "Puerto Plata",
        "Samaná",
        "San Cristóbal",
        "San José de Ocoa",
        "San Juan de la Maguana",
        "San Pedro de Macorís",
        "Sánchez Ramírez",
        "Santiago Rodríguez",
        "Valverde",
    ],
    'actividades': [
        "Abogados, Notarios, Otros profesionales jurídicos independientes, Contadores",
        "Acabado de muebles, tales como la fumigación, pintura y pulido",
        "Acabado de productos textiles",
        "Acabados, tales como tapicería de sillas y asientos",
        "Aclareo de bosques",
        "Acondicionamiento de edificios",
        "Actividades de acuicultura de agua salada en tanques o reservorios",
        "Actividades de acuicultura en aguas salobre",
        "Actividades de aeropuertos y de control de tráfico aéreo",
        "Actividades de agencias de noticias y de consorcios que proveen noticias, imágenes y reportajes en los medios de comunicación",
        "Actividades de agentes que organizan la venta de energía",
        "Actividades de ambulancias para animales",
        "Actividades de apoyo para el deporte o actividades recreativas",
        "Actividades de arquitectura e ingeniería y actividades conexas de asesoramiento técnico",
        "Actividades de artistas individuales",
        "Actividades de asesoramiento",
        "Actividades de asistentes de veterinarios u otro personal auxiliar",
        "Actividades de asociaciones cuyos miembros son empleados interesados principalmente en la representación de sus opiniones",
        "Actividades de astrología y espiritismo",
        "Actividades de aviación en general",
        "Actividades de bibliotecas de archivos fotográficos",
        "Actividades de casas de subastas",
        "Actividades de casas para personas con problemas mentales que no requieren internación",
        "Actividades de centros de grupos de rehabilitación para personas con problemas sociales o personales",
        "Actividades de chóferes, cuidadores, institutrices, niñeras y tutores",
        "Actividades de cine clubes",
        "Actividades de cinematografía, radio y televisión y servicios de espectáculos artísticos y de diversión N. C. P.",
        "Actividades de compañías de convenios de liquidación por adelantado",
        "Actividades de consultores que no sean las de arquitectura, ingeniería y gestión de consultores",
        "Actividades de crédito para financiar otras actividades económicas (incluye las empresas de factoring y otras formas de adelanto)",
        "Actividades de diagnósticos clínicos patológicos y otros diagnósticos referentes a animales",
        "Actividades de diseñadores gráficos",
        "Actividades de domesticas, cocineros, camareros, vallets, mayordomos, jardineros y guardianes",
        "Actividades de escuelas de arte que entregan educación superior",
        "Actividades de establos, perreras y garajes para carrera",
        "Actividades de estibas",
        "Actividades de expedidores de carga marítima y agentes de carga aérea",
        "Actividades de factoring",
        "Actividades de faro",
        "Actividades de federaciones de tales organizaciones",
        "Actividades de grupo, circos o compañías, orquestas o bandas",
        "Actividades de guía de montaña",
        "Actividades de hincado de pilotes, cimentación y otros trabajos de hormigón armado",
        "Actividades de impresión",
        "Actividades de impresión y reproducción de grabaciones",
        "Actividades de impresión y servicios conexos",
        "Actividades de informática N. C .P.",
        "Actividades de informática N. C. P.",
        "Actividades de instalaciones para el retardado mental",
        "Actividades de investigación y seguridad",
        "Actividades de las sociedades de holding",
        "Actividades de ligas deportivas y la regulación de los órganos",
        "Actividades de logística, planificación, diseño y apoyo a las operaciones de transporte, almacenaje y distribución",
        "Actividades de los agentes de aduanas",
        "Actividades de navegación, pilotaje y de amarraderos",
        "Actividades de oficinas de cambio",
        "Actividades de ópticas",
        "Actividades de organizaciones de servicio de equipamiento de monasterio y conventos",
        "Actividades de organizaciones políticas",
        "Actividades de organizaciones que buscan el desarrollo y prosperidad de empresas",
        "Actividades de organizaciones y órganos extraterritoriales",
        "Actividades de otras agencias",
        "Actividades de otras asesorías y representación además de la legal",
        "Actividades de radio y televisión",
        "Actividades de radiodifusión a través del Internet",
        "Actividades de recuperación conforme a la información solicitada",
        "Actividades de retiro religioso",
        "Actividades de salud para el personal militar en el campo",
        "Actividades de servicios agrícolas y a la ganadería excepto los veterinarios",
        "Actividades de servicios en aeropuertos",
        "Actividades de servicios relacionados con la extracción de petróleo y gas, excepto las actividades de prospección",
        "Actividades de servicios relacionados con la impresión",
        "Actividades de servicios religioso fúnebre",
        "Actividades de subastadores independientes",
        "Actividades de subastas no realizadas en almacenes",
        "Actividades de venta al por mayor en casas de subasta",
        "Actividades especializadas de construcción N. C. P. (incluye el alquiler e instalación de andamios)",
        "Actividades inmobiliarias realizadas a cambio de una retribución o por cuenta",
        "Actividades jurídicas",
        "Actividades ligadas al gas o agentes intermediarios",
        "Actividades ortodoncistas",
        "Actividades para promover la propagación, el crecimiento y la producción de animales",
        "Actividades por correo distintas a las actividades postales nacionales",
        "Actividades postales nacionales",
        "Actividades se salvamento",
        "Actividades teatrales y musicales y otras actividades artísticas",
        "Actores",
        "Acuicultura en agua dulce",
        "Administración de bienes raíces por comisión o por contrato",
        "Administración de los programas de protección del medio ambiente",
        "Administración de políticas de investigación y desarrollo relacionadas con la defensa y la administración de fondos pertinentes",
        "Administración de prisiones y provisión de servicios correccionales",
        "Administración de programas de fidelización",
        "Administración de riesgo de salud",
        "Administración de salvamento",
        "Administración del estado y aplicación de la política económica y social de la comunidad",
        "Administración y operación de brigadas contra incendio auxiliares y regulares",
        "Administración y operación de cortes de leyes civiles y criminales",
        "Administración y operación de las fuerzas policiales y auxiliares apoyadas por autoridades públicas y de puerto, fronteras y guardacostas",
        "Administración y operación de las otras fuerzas policiales especiales incluso regulación del tráfico, inscripción de extranjeros y mantenimiento de registro de arresto",
        "Administración y operación de tribunales militares",
        "Administración y operación del sistema judicial, incluso representación legal y asesoría en representación del gobierno",
        "Administradoras de fondos de pensiones",
        "Adobo y teñido de pieles, fabricación de artículos de piel",
        "Adquisición de los derechos de distribución de películas, cintas de video y DVD",
        "Afeitado de barba y el corte de pelo",
        "Agentes de cambio",
        "Agentes inmobiliarios",
        "Agentes inmobiliarios fideicomisos",
        "Aglomeración de coque",
        "Aglomeración de minerales de hierro",
        "Aglomeración de turba",
        "Agricultura, ganadería, caza y actividades de servicios conexos.",
        "Aislamiento térmico, acústico, hídrico y anti vibratorio",
        "Ajustes de las reclamaciones",
        "Albergue para enfermos mentales",
        "Albergue y cuidado de animales de terceros",
        "Albergues juveniles",
        "Alineación de dirección y balanceo de ruedas",
        "Almacenamiento y actividades complementarias al transporte",
        "Almacenamientos de productos en zonas francas",
        "Alojamiento",
        "Alquiler de andamios y plataformas de trabajo, sin montaje y desmontaje",
        "Alquiler de aspersores de uso agrícola",
        "Alquiler de automóviles privados con conductor",
        "Alquiler de camiones grúa",
        "Alquiler de computadoras y equipos periféricos",
        "Alquiler de copiadoras, máquina de escribir y maquinas de tratamiento de texto",
        "Alquiler de efectos personales y enseres domésticos N. C. P.",
        "Alquiler de efectos personales y enseres domésticos N. C. P. (incluye el alquiler de artículos deportivos y de video)",
        "Alquiler de embarcaciones de recreo con tripulación",
        "Alquiler de embarcaciones de recreo con tripulación para la navegación marítima y las aguas costeras de transporte",
        "Alquiler de equipo de construcción o demolición dotado de operario",
        "Alquiler de equipo de transporte para vía acuática, sin operario ni tripulación",
        "Alquiler de equipo de transporte para vía aérea, sin operario ni tripulación",
        "Alquiler de equipo de transporte para vía terrestre, sin operarios ni tripulación",
        "Alquiler de equipos de comunicación profesional de radio y televisión",
        "Alquiler de equipos de construcción o demolición dotados de operarios",
        "Alquiler de equipos de medición y control",
        "Alquiler de equipos de producción cinematográficas",
        "Alquiler de equipos de transporte",
        "Alquiler de equipos de transporte aéreo con un operador con el fin de transportar pasajeros",
        "Alquiler de equipos electrónicos de uso domésticos",
        "Alquiler de espacios equipados como salas fúnebres",
        "Alquiler de ferrocarriles",
        "Alquiler de flores y plantas",
        "Alquiler de globos de aire caliente",
        "Alquiler de grúas con operador",
        "Alquiler de instalaciones como baños, taquillas, sillas, etc.",
        "Alquiler de instrumentos musicales, estenografías y vestuario",
        "Alquiler de libros, revistas y periódicos",
        "Alquiler de maquinaria agrícola diversa",
        "Alquiler de maquinaria N. C. P.",
        "Alquiler de maquinaria y equipo agropecuario, sin operario",
        "Alquiler de maquinaria y equipo de construcción e ingeniería civil, sin operario (incluye el alquiler de andamios sin montaje)",
        "Alquiler de maquinaria y equipo de oficina, incluso computadora",
        "Alquiler de maquinaria y equipo minero y petrolero, sin personal",
        "Alquiler de maquinaria y equipo N. C. P. sin personal",
        "Alquiler de maquinaria y equipo para la industria manufacturera, sin personal",
        "Alquiler de maquinaria y equipo utilizado por aficionados o como un hobby por ejemplo herramientas para reparación del hogar",
        "Alquiler de maquinaria y equipos contables, cajas registradoras y calculadoras electrónicas",
        "Alquiler de maquinaria y equipos sin operador ",
        "Alquiler de maquinarias y utensilios para fiestas",
        "Alquiler de maquinas para la recolección y trilla",
        "Alquiler de maquinas para ordeñar",
        "Alquiler de maquinas utilizadas para preparar el suelo, plantar y abonar",
        "Alquiler de mobiliario de oficina",
        "Alquiler de motocicleta, caravanas y campers",
        "Alquiler de otras maquinarias científicas, comerciales e industriales",
        "Alquiler de remolques y semirremolques de carga y descarga automática",
        "Alquiler de ropa",
        "Alquiler de segadoras",
        "Alquiler o venta de tumbas",
        "Análisis y control en relación con la producción de alimentos",
        "Análisis y la medición de los indicadores ambientales",
        "Apicultura (incluye la producción de miel, jalea real, polen, propolen, etc.)",
        "Aplicación de arcilla, aislante de calor, aislante de sonido",
        "Aplicación de yeso y estuco en edificios y otras obras de construcción de interior y exterior",
        "Apoyo para la elaboración de planes de contingencia y la realización de ejercicios con la participación de instituciones y civiles",
        "Arbitraje de acciones civiles",
        "Arenas de boxeo",
        "Arenas y estadios para deportes de invierno",
        "Arquitectos",
        "Artistas plásticos",
        "Aserraderos y cepillado de madera",
        "Aserrado y cepillado de madera",
        "Asesoramiento y representación en casos civiles",
        "Asesoramiento y representación en casos penales",
        "Asesoramiento y representación en los conflictos laborales",
        "Asociaciones de ahorros y préstamos",
        "Asociaciones de consumidores",
        "Asociaciones de contadores",
        "Asociaciones de escritores, pintores, artistas, intérpretes, periodistas, etc.",
        "Asociaciones de ingenieros y arquitectos",
        "Asociaciones jurídicas",
        "Asociaciones medicas",
        "Asociaciones para facilitar el contacto con otras personas con intereses similares como clubes de rotarios, logias, etc.",
        "Asociaciones para fines patrióticos, incluyendo veteranos de guerra",
        "Atención de la salud animal y las actividades de control para los animales de granja",
        "Atención de la salud animal y las actividades de control para los animales domésticos",
        "Autodromos",
        "Autores",
        "Bancas de apuestas",
        "Bancas deportivas",
        "Banco mundial",
        "Bancos de ahorros y crédito",
        "Bancos múltiples",
        "Blanqueo de jeans",
        "Boutiques",
        "Cabañas y departamentos para visitantes, vivienda para vacacionar",
        "Cabinas de foto",
        "Cableado de telecomunicación",
        "Cableado eléctrico y accesorios",
        "Café soluble",
        "Cafeterías",
        "Camiones, remolques de utilidad y vehículos de recreo",
        "Campamentos disciplinarios",
        "Campos de golf",
        "Canchas de Bowling",
        "Captación, depuración y distribución de agua de fuentes subterráneas",
        "Captación, depuración y distribución de agua de fuentes superficiales",
        "Captación, tratamiento y suministro de agua",
        "Captura de animales de agua dulce",
        "Captura de animales vivos para zoológicos o como animales de compañía",
        "Captura de tortugas, erizos de mar, etc.",
        "Captura en tierra de mamíferos marinos como la foca o la morsa",
        "Carbonizado de la lana y el tenido de la lana vellón",
        "Carga de automóviles",
        "Carga y descarga de vagones ferroviarios",
        "Carros móviles de suministro de comida",
        "Casas de convalecencia",
        "Casas de empeño",
        "Casas de reposo con atención de enfermería",
        "Casinos",
        "Casinos de apuesta",
        "Caza de ballena, foca, lobo marino y otros mamíferos marinos",
        "Caza y captura con fines comerciales",
        "Caza y captura de animales vivos (incluye la caza de animales para obtener carne, pieles, cueros, etc.)",
        "Caza y captura de animales vivos para la extracción de pieles, cueros o para el uso en investigaciones científicas",
        "Caza y captura de animales vivos, repoblación de animales de caza y servicios conexos",
        "Centro de acopio de leche de ganado bovino",
        "Centros de aprendizajes que ofrecen cursos de recuperación",
        "Centros de rehabilitación para delincuentes y transgresores",
        "Centros de telecomunicaciones",
        "Chalets, casas hogar y cabinas",
        "Clubes de entretenimientos para adultos",
        "Colecciones de catálogos",
        "Colmadones",
        "Colmados",
        "Colocación de cristales en obra (incluye la instalación y revestimiento de vidrio, espejos y otros artículos de vidrio)",
        "Colocación de ladrillos y piedras",
        "Colocación de subtítulos ocultos",
        "Combinaciones de metales preciosos y piedras o de otros materiales preciosos o semipreciosos",
        "Comerciantes de piedras preciosas y comerciantes de metales preciosos",
        "Comercio al por mayor y al por menor, reparación de vehículos automotores y motocicletas",
        "Comercio al por menor no realizado en almacenes, puestos de venta o mercados",
        "Comercio al por menor, excepto el comercio de vehículos automotores y motocicletas",
        "Composición y representación de obras teatrales, musicales y artísticas",
        "Compositores",
        "Compra de vino a granel y embotellado sin transformación",
        "Compra y venta de artículos nuevos y usados",
        "Compra y venta de residuos que tienen un valor restante",
        "Comunidad europea y la asociación de libre comercio europea",
        "Concentración de esos minerales",
        "Condominios",
        "Confección de indumentaria de trabajo, uniformes, guardapolvos y sus accesorios",
        "Confección de indumentaria deportiva",
        "Confección de indumentaria para bebés y niños",
        "Confección de pilotos e impermeables",
        "Confección de prendas de vestir de cuero",
        "Confección de prendas de vestir de piel y sucedáneos",
        "Confección de prendas de vestir N. C. P. excepto las de piel, cuero y sucedáneos, pilotos e impermeables",
        "Confección de ropa interior, prendas de vestir para dormir y para la playa",
        "Confección de trajes de baño",
        "Confección y venta de mapas",
        "Conferencistas",
        "Conservación de frutas, frutos secos u hortalizas: congelación, secado, inmersión en aceite o en vinagre, enlatado, etc.",
        "Construcción de aerodeslizadores",
        "Construcción de almacenes",
        "Construcción de barcos a motor",
        "Construcción de buques y otras embarcaciones",
        "Construcción de chimeneas y hornos industriales",
        "Construcción de diques flotantes, boyas, tanques flotantes, barcazas, grúas flotantes",
        "Construcción de edificios",
        "Construcción de edificios de aeropuertos",
        "Construcción de edificios religiosos",
        "Construcción de embalses",
        "Construcción de estaciones de bombeo",
        "Construcción de garajes incluyendo los garajes subterráneos",
        "Construcción de hospitales, escuelas y edificios de oficinas",
        "Construcción de instalaciones deportivas",
        "Construcción de línea de agua principal",
        "Construcción de obras marítimas",
        "Construcción de plantas de eliminación de aguas residuales",
        "Construcción de plantas de energía",
        "Construcción de plantas químicas",
        "Construcción de plataformas de perforación, flotantes o sumergidas",
        "Construcción de refinerías",
        "Construcción de sistemas de alcantarillado, incluyendo la reparación",
        "Construcción de tuberías en zonas urbanas de comunicación y líneas eléctricas",
        "Construcción de túneles",
        "Construcción de viviendas unifamiliares",
        "Construcción e instalación de equipos de climatización",
        "Construcción, mecanismo, forma, color y acabados superficiales del producto",
        "Construcción, reforma y reparación de edificios no residenciales (incluye la construcción, reforma y reparación de restaurantes, bares, etc.)",
        "Construcción, reforma y reparación de edificios residenciales (incluye construcción, reforma y reparación de viviendas unifamiliares)",
        "Construcción, reforma y reparación de edificios turísticos",
        "Construcción, reforma y reparación de las calles, caminos, puentes, túneles",
        "Construcción, reforma y reparación de obras de infraestructuras de transporte",
        "Construcción, reforma y reparación de obras hidráulicas (incluye obras fluviales y canales, acueductos, diques, etc.)",
        "Construcción, reforma y reparación de redes (incluye la construcción, reforma y reparación de redes de electricidad, gas)",
        "Consultaría de marketing",
        "Consultaría en agronomía",
        "Consultaría en seguridad",
        "Consultoría ambiental",
        "Contracción y reparación de buques y embarcaciones N. C. P.",
        "Contrato de intercambio de futuros y comoditis",
        "Control de plaga (incluidos los conejos) en relación a la agricultura",
        "Control de plagas forestales",
        "Cooperativas",
        "Corredores de seguro",
        "Correos y servicios de mensajería",
        "Corretaje de buques y aeronaves espaciales",
        "Corretaje de contratos de comoditis",
        "Corretaje de valores",
        "Corretaje de vino",
        "Corte en rodaja de la madera",
        "Corte, tallado y acabado de la piedra",
        "Corte, tallado y acabado de la piedra (incluye mármoles y granitos, etc.)",
        "Creación de stand y otras estructuras de visualización",
        "Creación y desarrollo de diseños y especificaciones que optimizan el uso, el valor y la apariencia de los productos",
        "Cría de animales",
        "Cría de animales para la obtención de pelos",
        "Cría de animales para la obtención de pieles y cueros",
        "Cría de animales para la obtención de plumas",
        "Cría de animales y obtención de productos de origen animal N. C. P. (incluye ciervo, conejo excepto para pelo, lombriz, etc.)",
        "Cría de aves como pericos, etc.",
        "Cría de aves para la producción de carne",
        "Cría de aves para la producción de huevos (incluye pollitos BB para postura)",
        "Cría de avestruces y emus",
        "Cría de crustáceos, moluscos y bivalvos",
        "Cría de ganado bovino en cabañas (incluye la producción de semen)",
        "Cría de ganado bovino –excepto en cabañas y para la producción de leche",
        "Cría de ganado caprino, excepto en cabañas para la producción de leche",
        "Cría de ganado equino en haras (incluye la producción de semen)",
        "Cría de ganado equino, excepto en haras (incluye cría de equinos de trabajo)",
        "Cría de ganado N. C. P. (incluye cría de alpaca, asno, guacano, llama, vicuña, etc.)",
        "Cría de ganado ovino, excepto en cabañas y para la producción de lana",
        "Cría de ganado ovino, porcino y caprino en cabañas (incluye la producción de semen)",
        "Cría de ganado porcino, excepto en cabañas",
        "Cría de ganado vacuno y de ovejas, cabras caballo, asnos, mulas y burgedanos, cría de ganado lechero",
        "Cría de gatos y perros",
        "Cría de hámster, etc.",
        "Cría de insectos",
        "Cría de otras aves excepto las de corral",
        "Cría de otros animales acuáticos",
        "Cría de otros animales y elaboración de productos para animales N.C.P.",
        "Cría de peces ornamentales",
        "Cría de peces ornamentales de agua dulce",
        "Cría en cabañas de ganado N. C. P,",
        "Criaderos de gusanos marinos",
        "Criaderos de peces de agua dulce",
        "Criaderos de peces marinos",
        "Criaderos de ranas",
        "Cubrimiento con tejado de los edificios residenciales",
        "Cultivo de abacá, ramio y demás fibras textiles vegetales",
        "Cultivo de aguacate",
        "Cultivo de ajo",
        "Cultivo de ajonjolí",
        "Cultivo de albaricoque",
        "Cultivo de alcachofas",
        "Cultivo de algodón",
        "Cultivo de almendra",
        "Cultivo de anís, badianas e hinojos",
        "Cultivo de árboles de caucho",
        "Cultivo de árboles de navidad",
        "Cultivo de árboles para la extracción de savia",
        "Cultivo de arroz",
        "Cultivo de avellanas",
        "Cultivo de avena",
        "Cultivo de batatas",
        "Cultivo de berenjenas",
        "Cultivo de blueberries",
        "Cultivo de brócolis y coliflor",
        "Cultivo de bulbos, brotes, raíces y hortalizas de frutos N. C. P. (incluye ají, ajo, alcaparra, etc.)",
        "Cultivo de cacahuetes ",
        "Cultivo de cacao",
        "Cultivo de café y cacao",
        "Cultivo de caña de azúcar",
        "Cultivo de canela",
        "Cultivo de castaña de caju",
        "Cultivo de cebada",
        "Cultivo de cebolla",
        "Cultivo de centeno",
        "Cultivo de cereales excepto los forrajeros y las semillas N. C. P.",
        "Cultivo de cereales forrajeros N. C. P. (incluye alpiste, cebada forrajera, mijo)",
        "Cultivo de cereales y otros cultivos N. C. P.",
        "Cultivo de chiles y pimientos",
        "Cultivo de ciruela",
        "Cultivo de coles",
        "Cultivo de colza",
        "Cultivo de corintos",
        "Cultivo de espárragos",
        "Cultivo de especias (de hojas, de semillas, de flor y de fruto) y de plantas aromáticas y medicinales",
        "Cultivo de espinaca",
        "Cultivo de flores",
        "Cultivo de frambuesa",
        "Cultivo de fresa",
        "Cultivo de frijoles",
        "Cultivo de frutas cítricas (incluye kinoto, etc.)",
        "Cultivo de frutas con pepita N. C. P. (incluye membrillo, níspero,  etc.)",
        "Cultivo de frutas de carozo (incluye ciruela, damasco, pelón, etc.)",
        "Cultivo de frutas N. C. P. (incluye cultivo de mamón)",
        "Cultivo de frutas, nueces, plantas cuyas hojas se utilizan para preparar bebidas y especies",
        "Cultivo de girasol",
        "Cultivo de granos de mostaza",
        "Cultivo de grosella",
        "Cultivo de guisantes",
        "Cultivo de habas",
        "Cultivo de higo",
        "Cultivo de hortalizas de hoja y de otras hortalizas frescas (incluye acelga, apio, choclo, etc.)",
        "Cultivo de hortalizas, legumbres y productos de vivero",
        "Cultivo de jengibre",
        "Cultivo de kiwi",
        "Cultivo de laver y otros tipos de algas comestibles",
        "Cultivo de lechosa",
        "Cultivo de lechuga",
        "Cultivo de legumbres frescas (incluye arveja, garbanzos, poroto, judía, etc.)",
        "Cultivo de legumbres secas",
        "Cultivo de lentejas",
        "Cultivo de limones y lima",
        "Cultivo de lino y cáñamo",
        "Cultivo de mago",
        "Cultivo de maíz",
        "Cultivo de mandarinas",
        "Cultivo de manzana",
        "Cultivo de materias vegetales de las especies utilizadas principalmente para el trenzado",
        "Cultivo de melocotones",
        "Cultivo de melones y otros frutales",
        "Cultivo de monte",
        "Cultivo de nabos",
        "Cultivo de ñame",
        "Cultivo de naranjas",
        "Cultivo de nueces",
        "Cultivo de nueces de anacardo",
        "Cultivo de nueces y frutas secas ",
        "Cultivo de nuez moscada",
        "Cultivo de oleaginosas N. C. P. (incluye cultivo de oleaginosas para aceites comestibles y/o uso industrial)",
        "Cultivo de otras bayas",
        "Cultivo de otras fibras",
        "Cultivo de otras frutas secas comestible",
        "Cultivo de otras hortalizas",
        "Cultivo de otras leguminosas",
        "Cultivo de otras raíces y tubérculos",
        "Cultivo de otros productos para la preparación de bebidas",
        "Cultivo de papas",
        "Cultivo de pastos forrajeros (incluye alfalfa, moho, sorgo azucarado y forrajero, etc.)",
        "Cultivo de pepinos y pepinillos",
        "Cultivo de pera",
        "Cultivo de pimienta",
        "Cultivo de piña",
        "Cultivo de pistacho",
        "Cultivo de plantas cuyas hojas se utilizan para preparar bebidas (infusiones)",
        "Cultivo de plantas de interior y viveros",
        "Cultivo de plantas para fines ornamentales, incluyendo césped para trasplantar",
        "Cultivo de plantas para la elaboración de mate",
        "Cultivo de plantas para la elaboración de te ",
        "Cultivo de plantas para la obtención de fibras N. C. P. (incluye cultivo de formio, maíz de guinea, ramio, etc.)",
        "Cultivo de plantas sacaríferas N. C. P. (incluye el cultivo de remolacha azucarera, etc.)",
        "Cultivo de plantas vivas para los bulbos, tubérculos, y raíces, esquejes e injertos",
        "Cultivo de puerros y demás hortalizas albaceas",
        "Cultivo de sandias",
        "Cultivo de semilla de cártamo",
        "Cultivo de semillas de frutas",
        "Cultivo de semillas de hortalizas, excepto la semilla de remolacha",
        "Cultivo de semillas de remolacha (excluye la remolacha azucarera) y semillas de plantas forrajeras",
        "Cultivo de semillas de soja",
        "Cultivo de setas y trufas",
        "Cultivo de sisal y demás fibras textiles del genero agave",
        "Cultivo de sorgo en grano",
        "Cultivo de tabaco",
        "Cultivo de tomates",
        "Cultivo de toronjas",
        "Cultivo de trigo",
        "Cultivo de uvas",
        "Cultivo de vainilla",
        "Cultivo de vegetales para la plantación",
        "Cultivo de vid para vinificar",
        "Cultivo de yuca",
        "Cultivo de yute, el kenaf y otras fibras textiles",
        "Cultivo de zanahorias",
        "Cultivos en General; cultivo de productos de mercado; Horticultura. ",
        "Cultivos industriales N. C. P. (incluye el cultivo de olivo para conserva, palmitos, etc.)",
        "Cursos de repaso para exámenes profesionales",
        "Curtido y adobo de cueros",
        "Curtido y terminación de cueros",
        "Demolición y voladura de edificios y sus partes (incluye los trabajos de limpieza de escombros asociados a la demolición)",
        "Desalinización de agua de mar o subterránea para producir agua como principal producto de interés",
        "Desarrollo y la transformación de películas de cine",
        "Desmontado de algodón y preparación de fibras de algodón",
        "Destilación de alcohol etílico",
        "Destilación, rectificación y mezcla de bebidas alcohólicas",
        "Destilado de whisky",
        "Desulfurización de gas",
        "Devanado y lavado de seda",
        "Dibujantes",
        "Difusión de la información, representación ante agencias estatales",
        "Diplomáticos y/o cónsules",
        "Diseñadores de alta costura",
        "Diseñadores de calzados",
        "Diseñadores de joyería",
        "Diseñadores de muebles y demás decoraciones de interiores",
        "Diseñadores de otros bienes de moda así como otros efectos personales o enseres domésticos",
        "Diseñadores de prendas de vestir",
        "Diseño de productos de imprenta, bocetos y maniquíes",
        "Diseño e instalación de letreros y vallas",
        "Diseño, desarrollo e instalación de software",
        "Disposición de lino, uniformes de trabajo y prendas conexas por las lavanderías",
        "Distribución de agua a través de la red, por camiones u otros medios",
        "Distribución de cintas de video, DVD",
        "Distribución de energía eléctrica",
        "Distribución de espacios publicitarios en las vallas",
        "Distribución de filmes y videocintas",
        "Distribución de producciones similares a los teatros de cine, televisión y redes de estaciones",
        "Distribución o entrega de material publicitario",
        "Distribución y entrega de correspondencias y paquetes",
        "Drenaje de los productos agrícolas y forestales",
        "Drenaje y separación de hidrocarburos líquido",
        "Edición de grabaciones",
        "Edición de libros, folletos, partituras y otras publicaciones",
        "Edición de periódicos, revistas y publicaciones periódicas",
        "Edición N. C. P.",
        "Edición, titilación, subtitulación y colocación de los créditos",
        "Educación escolar general en la segunda etapa del nivel secundario",
        "Educación especial para estudiantes discapacitados en el nivel secundario",
        "Educación especial para estudiantes discapacitados en este nivel",
        "Educación especial para estudiantes discapacitados en formación técnica o profesional",
        "Educación post secundaria no terciaria",
        "Educación primaria",
        "Educación que no es definida por niveles",
        "Ejecución y mantenimiento de instalaciones eléctricas y electrónicas N. C.P. (incluye la instalación de antenas, pararrayos)",
        "Elaboración de aceite y grasa animal no comestible",
        "Elaboración de aceites y grasas de origen vegetal y animal",
        "Elaboración de aceites y grasas vegetales refinadas (no incluye aceite de maíz)",
        "Elaboración de aceites y grasas vegetales sin refinar y sus subproductos; elaboración de aceite virgen",
        "Elaboración de alcoholes cíclicos y acíclicos",
        "Elaboración de alimentos a base de cereales",
        "Elaboración de alimentos preparados para animales",
        "Elaboración de almidones y productos derivados del almidón",
        "Elaboración de almidones y productos derivados del almidón (incluye la elaboración de glucosa, gluten, aceites de maíz)",
        "Elaboración de aluminio primario y semielaborados de aluminio",
        "Elaboración de amoniaco, cloruro de amonio, carbonato de amonio, nitritos y nitratos de potasio",
        "Elaboración de azúcar",
        "Elaboración de azúcar de la remolacha",
        "Elaboración de bebidas no alcohólicas y aguas minerales",
        "Elaboración de cigarrillos y cigarros",
        "Elaboración de combustible nuclear",
        "Elaboración de compuestos orgánicos de nitrógeno, incluyendo la amina",
        "Elaboración de extractos, jarabes y concentrados",
        "Elaboración de fiambres y embutidos",
        "Elaboración de frutas, hortalizas y legumbres congeladas",
        "Elaboración de frutas, hortalizas y legumbres deshidratadas o disecadas",
        "Elaboración de glicerina sintética",
        "Elaboración de hidrocarburos acíclicos saturados y no saturados",
        "Elaboración de hidrocarburos cíclicos saturados y no saturados",
        "Elaboración de hielo, jugos envasados para diluir y otras bebidas no alcohólicas",
        "Elaboración de leches, productos lácteos deshidratados (incluye la estandarización, homogenización, pasterización y esterilizado)",
        "Elaboración de macarrones, fideos alcuzcuz y productos farináceos",
        "Elaboración de margarinas y grasas vegetales",
        "Elaboración de masas y productos de pastelería",
        "Elaboración de otros compuestos de función de oxigeno, incluyendo aldehídos, cetona y quinonas",
        "Elaboración de otros productos alimenticios N. C. P.",
        "Elaboración de pastas alimentarias frescas",
        "Elaboración de pastas alimentarias secas",
        "Elaboración de productos alimenticios N. C. P. (incluye la elaboración de polvos para preparar postres y gelatinas)",
        "Elaboración de productos alimenticios y bebidas",
        "Elaboración de productos de confitería de cacao y chocolate",
        "Elaboración de productos de confiterías N. C. P. (incluye caramelos, frutas confitadas, pastillas, etc.)",
        "Elaboración de productos de molinería",
        "Elaboración de productos de panadería",
        "Elaboración de productos de pescados de mar, crustáceos y productos marinos N. C. P",
        "Elaboración de productos de pescados de río y lagunas y otros productos fluviales y lacustres",
        "Elaboración de productos de tabaco",
        "Elaboración de productos de tabaco N. C. P.",
        "Elaboración de productos lácteos",
        "Elaboración de queso (incluye la producción de suero)",
        "Elaboración de sidra, y otras bebidas alcohólicas fermentadas a partir de frutas",
        "Elaboración de té y yerba mate",
        "Elaboración de tofu",
        "Elaboración de vinos",
        "Elaboración de vinos (incluye el fraccionamiento)",
        "Elaboración de yerba mate",
        "Elaboración industrial de productos de panadería, excluidas galletas y bizcochos",
        "Elaboración primaria de minerales no metálicos N. C. P.",
        "Elaboración y conservación de frutas, legumbres y hortalizas",
        "Elaboración y envasado de dulces, mermeladas y jaleas",
        "Elaboración y molienda de hierbas aromáticas y especias",
        "Eliminación de desperdicios y aguas residuales, saneamiento y servicios similares",
        "Embajadas, Consulados, y Misiones extranjeras",
        "Embalaje de paquetes y regalos",
        "Embalaje de seguridad o preparados farmacéuticos",
        "Embalaje, envasado y almacenamiento de residuos y desechos",
        "Embarcado y cuidado de animales de granja",
        "Embasado de líquidos, incluidos las bebidas y los alimentos",
        "Embotellado de aguas naturales y minerales",
        "Emisión de programas de televisión",
        "Emisión y administración de dinero",
        "Empleados (asalariados)",
        "Empleados y obreros",
        "Empresas de empaque",
        "Engorde en corrales (fed-lot)",
        "Enmarcados",
        "Enrutamiento de correo",
        "Ensaladas",
        "Ensamblado de pieles de peletería, placas, esteras, etc.",
        "Ensamblaje de maquinarias y equipos (incluye las eléctricas)",
        "Ensamblaje de vehículos automotores",
        "Ensayos y análisis técnico",
        "Enseñanza inicial y primaria",
        "Enseñanza para adultos y servicios de enseñanza N. C. P.",
        "Enseñanza para adultos y servicios de enseñanza N. C. P. (incluye instrucción impartida mediante programas de radio, televisión)",
        "Enseñanza primaria",
        "Enseñanza secundaria",
        "Enseñanza secundaria de formación general",
        "Enseñanza secundaria de formación técnica o profesional",
        "Enseñanza superior y formación de posgrado",
        "Enseñanza terciaria (escuelas o institutos técnicos, profesionales o comerciales)",
        "Enseñanza universitaria excepto formación de postgrado",
        "Enterramiento o la prestación de servicios de cremación",
        "Entidades públicas de intermediación financiera",
        "Entrenamiento computacional",
        "Entrenamiento de supervivencia",
        "Entrenamiento para oratoria",
        "Entrenamiento para preparar computadoras",
        "Equipos de producción grafica, animación y efectos especiales",
        "Escritura de constituciones, contratos de sociedades colectivas y documentos similares para la formación de sociedades",
        "Escuela de vuelo y entrenamiento de salvavidas",
        "Escuelas de conducir",
        "Escuelas de conducir para chóferes profesionales",
        "Escuelas de cosmetología y barbería",
        "Escuelas especializadas en minusválidos",
        "Espigado de tela",
        "Estabilización de cultivos",
        "Estadios de futbol, hockey, cricket, baseball",
        "Estadios de pista y campo",
        "Estudio de mercado, realización de encuestas de opinión publica",
        "Estudios de grabación o producción musical",
        "Etiquetado, estampado e impreso",
        "Evaluación de daños",
        "Evaluación de madera",
        "Evaluación de riesgo",
        "Excavación de zanjas, eliminación de rocas y voladuras",
        "Exhibición de filmes y videotecas",
        "Expedición y obtención de documentos de transporte y conocimiento de embarque",
        "Expendio de helados",
        "Explotación de criaderos de peces, granjas piscícolas y otros frutos acuáticos (acuicultura)",
        "Explotación de minas y canteras N. C. P. (incluye aminato, baritina, cuarzo, diatomita, piedra pómez, ágata, agua marina)",
        "Explotación de otras minas y canteras N. C. P.",
        "Explotación de viveros forestales (incluye la propagación de especies forestales)",
        "Extracción de aceites bituminosos o de esquistos bituminosos o de arena de alquitrán",
        "Extracción de arcilla y caolín (incluye andalucita, arcilla, bentonita, caolín, piriofilita, silimanita, mullita, tierra de chamota o de dinas, etc.)",
        "Extracción de arena, canto rodado y triturado pétreos (incluye la arena para la construcción, arena silícea, otras arenas naturales, canto rodado, etc.)",
        "Extracción de asfalto naturales, asfaltito y roca; betún natural sólido",
        "Extracción de boratos naturales y sulfato de magnesio natural",
        "Extracción de cobre",
        "Extracción de condensados",
        "Extracción de guano",
        "Extracción de magnesio, cromo, cobalto, molibdeno, tantalio, etc.",
        "Extracción de materiales abrasivos, asbesto, silicios fósiles, granito natural, esteatita, feldespato, etc.",
        "Extracción de metales preciosos: oro, plata y platino",
        "Extracción de minerales de hierro",
        "Extracción de minerales de hierro (incluye hematitas, limonitas, magnetitas, siderita, etc.)",
        "Extracción de minerales metalíferos no ferrosos, excepto minerales de uranio y torio",
        "Extracción de minerales metalíferos no ferrosos, excepto minerales de uranio y torio (incluye aluminio, níquel)",
        "Extracción de minerales para la fabricación de abonos excepto turba (incluye silvita, silvinita y otras sales de potasio naturales)",
        "Extracción de minerales para la fabricación de fertilizantes y productos químicos",
        "Extracción de minerales para la fabricación de productos químicos (incluye azufre, boracita e hidroboracita, calcita, celestina, celestina, etc.)",
        "Extracción de minerales y concentrados de uranio y torio",
        "Extracción de minerales y concentrados uranio y torio",
        "Extracción de petróleo crudo y gas natural",
        "Extracción de petróleo crudo y gas natural (incluye gas natural licuado y gaseoso, arenas alquitraniferas, esquistos bituminosos, etc.)",
        "Extracción de pichtblende",
        "Extracción de piedra caliza y yeso (incluye caliza, castina, conchilla, riolita, etc.)",
        "Extracción de piedra, arena y arcilla",
        "Extracción de piedras preciosas, cuarzo, mica, etc.",
        "Extracción de plomo, zinc y estaño",
        "Extracción de productos forestales de bosques cultivados (incluye la tala de árboles, devaste de troncos y producción de madera en bruto)",
        "Extracción de productos forestales de bosques nativos (incluye tala de árboles, devaste de troncos y producción de madera en bruto, leña)",
        "Extracción de rocas ornamentales (incluye areniscas, cuarcita, dolomita, granito, mármol, piedra laja, pizarra, pórfido, serpentina, etc.)",
        "Extracción de sal",
        "Extracción de sal de roca incluida la molienda",
        "Extracción de sal en salinas y de roca",
        "Extracción de tiza y dolomita",
        "Extracción y aglomeración de carbón (incluye la producción de hulla no aglomerada, antracita, carbón bituminoso no aglomerado, briqueta)",
        "Extracción y aglomeración de carbón a través de métodos de licuefacción",
        "Extracción y aglomeración de carbón de piedra",
        "Extracción y aglomeración de carbón mediante explotación minera a cielo abierto",
        "Extracción y aglomeración de lignito (carbón marrón)",
        "Extracción y aglomeración de lignito (incluye la producción de lignito aglomerado y no aglomerado)",
        "Extracción y aglomeración de lignito a través de métodos de licuefacción",
        "Extracción y aglomeración de lignito mediante explotación minera a cielo abierto",
        "Extracción y aglomeración de turba",
        "Extracción y aglomeración de turba (incluye la producción de turba utilizada como corrector de suelo)",
        "Extracción y dragado de arena industrial",
        "Extracción y dragado de arena para la construcción y grava",
        "Extracción y preparación de piritas y pirrotina",
        "Extractos y concentrados de café",
        "Fabricación cuerda, cordeles, bramante",
        "Fabricación de  bancos, sillas y otros asientos para laboratorios y mobiliarios de laboratorios",
        "Fabricación de abonos y compuestos de nitrógeno",
        "Fabricación de abrazaderas",
        "Fabricación de abridores de lata",
        "Fabricación de abrigos, trajes, conjuntos, chaquetas, pantalones, faldas, etc.",
        "Fabricación de abrillantadores y cremas para cristales y metales",
        "Fabricación de abrillantadores y cremas para maderas",
        "Fabricación de abrillantadores y cremas para pieles",
        "Fabricación de accesorios de vestir de cuero",
        "Fabricación de accesorios de vestir excepto de cuero",
        "Fabricación de accesorios para la iluminación de calle",
        "Fabricación de accesorios sanitarios de cerámica",
        "Fabricación de aceite de frijol refinado",
        "Fabricación de aceite de frijol, soja",
        "Fabricación de aceite de maíz",
        "Fabricación de aceite de oliva",
        "Fabricación de aceite de oliva refinado",
        "Fabricación de aceite de palma",
        "Fabricación de aceite de semilla de algodón",
        "Fabricación de aceite de semilla de girasol",
        "Fabricación de aceite de soja refinado",
        "Fabricación de aceite lubricante a base de aceites o grasas incluidos aceite usado",
        "Fabricación de aceite térmico",
        "Fabricación de aceites y grasas de origen animal comestible",
        "Fabricación de acordeones e instrumentos similares incluyendo los órganos de boca",
        "Fabricación de acumuladores eléctricos",
        "Fabricación de acumuladores y de pilas y baterías primarias e inversores",
        "Fabricación de acumuladores y pilas y baterías primarias",
        "Fabricación de aditivos preparados para cemento",
        "Fabricación de adornos para automóviles",
        "Fabricación de aerodeslizadores para la recreación",
        "Fabricación de aeronaves, naves espaciales y de maquinaria conexa",
        "Fabricación de agua destilada",
        "Fabricación de agua destilada aromática",
        "Fabricación de aguas endulzadas y/o aromatizadas",
        "Fabricación de agujas, revestimientos para el suelo",
        "Fabricación de aisladores eléctricos y piezas aislantes de cerámica",
        "Fabricación de alambre de acero",
        "Fabricación de alambre de púa",
        "Fabricación de álbumes y similares",
        "Fabricación de álcalis, lejía y otros compuestos inorgánicos de bases, salvo el amoniaco",
        "Fabricación de aleaciones de cobre",
        "Fabricación de aleaciones de plomo, zinc y estaño",
        "Fabricación de alfombras",
        "Fabricación de alfombras, tapetes y baldosas",
        "Fabricación de alimentos de frutos y pastas",
        "Fabricación de alimentos para aves, peces, etc.",
        "Fabricación de alimentos para perros y gatos",
        "Fabricación de alquídicas y resinas de poliéster",
        "Fabricación de ametralladoras ligeras",
        "Fabricación de ametralladoras pesadas",
        "Fabricación de amplificadores de instrumentos musicales",
        "Fabricación de anillos de goma y sus accesorios",
        "Fabricación de animales de juguete",
        "Fabricación de antenas de transmisión y recepción",
        "Fabricación de antibióticos",
        "Fabricación de anticongelantes",
        "Fabricación de antisueros y otras fracciones de sangre",
        "Fabricación de aparatos de control de limite hidrónicos",
        "Fabricación de aparatos de destilación, centrifugadoras de laboratorio",
        "Fabricación de aparatos de distribución y control de la energía eléctrica",
        "Fabricación de aparatos de iluminación para árboles de navidad",
        "Fabricación de aparatos eléctricos y otros industriales de laboratorios",
        "Fabricación de aparatos no eléctricos de soldadura y equipos de soldadura",
        "Fabricación de aparatos para control de llamas y quemadores",
        "Fabricación de aparatos para controles ambientales y controles automáticos",
        "Fabricación de aparatos para uso domésticos",
        "Fabricación de aparatos y accesorios eléctricos N. C. P.",
        "Fabricación de aparatos y accesorios para fotografía excepto películas, placas y papeles sensibles",
        "Fabricación de arcos y ballestas",
        "Fabricación de armas y municiones",
        "Fabricación de arnés de cableado de encendido",
        "Fabricación de artefactos para la iluminación excepto los eléctricos",
        "Fabricación de artículos confeccionados de materiales textiles excepto prendas de vestir N. C. P.",
        "Fabricación de artículos confeccionados de materiales textiles, tejeduras de productos textiles, excepto prendas de vestir",
        "Fabricación de artículos de asfalto o material similar",
        "Fabricación de artículos de cemento y fibrocemento",
        "Fabricación de artículos de cestería, caña y mimbre",
        "Fabricación de artículos de cuchillería y utensilios de mesa y de cocina",
        "Fabricación de artículos de cuchillería, herramientas de mano y artículos de ferretería",
        "Fabricación de artículos de deporte",
        "Fabricación de artículos de deporte (incluye equipos de deporte, para gimnasios y campos de juego, equipos de pesca y camping)",
        "Fabricación de artículos de guarnicionaría o talabartería",
        "Fabricación de artículos de hormigón, de cemento y de yeso",
        "Fabricación de artículos de la riqueza natural o aglomerado de corcho, incluida revestimiento para suelo",
        "Fabricación de artículos de lonas y sucedáneos de lona",
        "Fabricación de artículos de madera en tornería",
        "Fabricación de artículos de papel y cartón de uso domestico e higiénico sanitario",
        "Fabricación de artículos de papel y cartón N. C. P.",
        "Fabricación de artículos de piel N. C. P.",
        "Fabricación de artículos de talabartería y artículos de cuero N. C. P.",
        "Fabricación de artículos de vestir de caucho",
        "Fabricación de artículos higiénicos de goma",
        "Fabricación de artículos para afeitar, incluidos los pre-afeitados y para después del afeitado",
        "Fabricación de artículos para la caza, el montañismo",
        "Fabricación de artículos para la pesca deportiva",
        "Fabricación de artillería",
        "Fabricación de ascensores, escaleras mecánicas y pasillos móviles",
        "Fabricación de asientos de automóviles",
        "Fabricación de asientos de avión",
        "Fabricación de aspiradoras",
        "Fabricación de ataúdes",
        "Fabricación de audífonos",
        "Fabricación de autobuses",
        "Fabricación de autopartes de caucho excepto cámaras y cubiertas",
        "Fabricación de aviones para el uso de las fuerzas de defensa",
        "Fabricación de azucares químicamente puros",
        "Fabricación de balones inflables",
        "Fabricación de bandas de relojes no metálicas",
        "Fabricación de bandejas de pulpa moldeada",
        "Fabricación de bandera, pancartas, banderines, etc.",
        "Fabricación de baños plásticos y artículos para el baño de plástico",
        "Fabricación de barcos de pesca y barcos factorías para el procesamiento del pescado",
        "Fabricación de barras de acero",
        "Fabricación de barriles, cubos, tubas y otros productos de madera",
        "Fabricación de baterías de acido de plomo",
        "Fabricación de baterías de celdas húmedas",
        "Fabricación de baterías de litio",
        "Fabricación de baterías de NiCad",
        "Fabricación de baterías de NiMH",
        "Fabricación de bebidas gaseosas exceptuando la soda",
        "Fabricación de bebidas no alcohólicas excepto cervezas sin alcohol y vino",
        "Fabricación de becarán y telas rígidas",
        "Fabricación de bicicletas y sillas de ruedas para inválidos",
        "Fabricación de bisutería y accesorios",
        "Fabricación de bloqueadores solares",
        "Fabricación de bloques de pisos en arcilla cocida",
        "Fabricación de bloques de suelo de parquet montados en paneles",
        "Fabricación de bloques de vidrio",
        "Fabricación de bolas y cojinetes de rodillos y sus partes",
        "Fabricación de bolsas de materiales textiles para productos a granel",
        "Fabricación de bombas hidráulicas",
        "Fabricación de bombas manuales",
        "Fabricación de bombas para aceite y de agua, bombas de combustibles de los vehículos de motor",
        "Fabricación de bombas para líquidos que estén o no provistos de un dispositivo de medición",
        "Fabricación de bombas; compresores; grifos y válvulas",
        "Fabricación de botellas y otros recipientes de vidrio o cristal",
        "Fabricación de botes inflables y balsas",
        "Fabricación de botones, sujetadores de equipos y cremalleras",
        "Fabricación de brandy",
        "Fabricación de brindas planas y brindas forjadas con anillos",
        "Fabricación de briquetas de petróleo",
        "Fabricación de briquete de combustible",
        "Fabricación de brocas, fresas, punzones, etc.",
        "Fabricación de brochas, pinceles, escobas y cepillos que constituyan entre ellos partes de maquinas",
        "Fabricación de buques de guerra",
        "Fabricación de buscadores de rango",
        "Fabricación de cabañas, elementos modulares para exposición, etc.",
        "Fabricación de cable de metal, trenzas y artículos similares",
        "Fabricación de cacao, chocolate y productos a base de cacao",
        "Fabricación de cadenas de eslabones articulados",
        "Fabricación de cadenas de transmisión de potencia",
        "Fabricación de cajas de archivos para oficinas y artículos similares",
        "Fabricación de cajas de cables eléctricos ",
        "Fabricación de cajas de música",
        "Fabricación de cajas de seguridad",
        "Fabricación de cajas registradoras operadas mecánicamente",
        "Fabricación de cajas y moldes, excepto lingoteras",
        "Fabricación de cajas y otras plataformas de carga",
        "Fabricación de cajas, sobres y presentaciones similares con un surtido de artículos de papelería",
        "Fabricación de cal",
        "Fabricación de calandrias u otras maquinas laminadoras (a excepción de metal y vidrio)",
        "Fabricación de calculadoras, eléctricas o no",
        "Fabricación de calderas de calefacción central y radiadores",
        "Fabricación de caldera-turbina",
        "Fabricación de calefactores eléctricos de resistencia",
        "Fabricación de calentadores de agua eléctricos",
        "Fabricación de calzado de cuero, excepto el ortopédico",
        "Fabricación de calzado de encaje",
        "Fabricación de calzado de tela, plástico, goma, caucho y otros materiales, excepto los ortopédicos y de asbesto",
        "Fabricación de calzado y sus partes",
        "Fabricación de cama de bronceado",
        "Fabricación de cámaras de películas y cámaras digitales",
        "Fabricación de camas de hospitales con ajustes mecánicos",
        "Fabricación de camines mezcladores de hormigón",
        "Fabricación de camiones bomberos, barredores de calle y vehículos blindados",
        "Fabricación de camiones de obras sean o no equipados con equipos de elevación o manipulación",
        "Fabricación de camisas, camisetas y blusas",
        "Fabricación de campanas eléctricas",
        "Fabricación de candados, cerraduras, llaves, bisagras y similares, de hardware para los edificios, mobiliarios, vehículos, etc.",
        "Fabricación de cañones móviles",
        "Fabricación de cañones y pistolas de aire o gas",
        "Fabricación de carbón activado, aditivos para aceite lubricante, aceleradores y catalizadores",
        "Fabricación de carbón duro y lignito y briquetas combustibles",
        "Fabricación de carburadores",
        "Fabricación de cargadores de batería de estado sólido",
        "Fabricación de carpintería metálica",
        "Fabricación de carretes de madera para cables",
        "Fabricación de carrocería de vehículos automotores; fabricación de remolques y semirremolques",
        "Fabricación de carrocería para vehículos automotores, fabricación de remolques y semirremolque",
        "Fabricación de carrocerías de vehículos automotores; fabricación de remolques y semirremolques",
        "Fabricación de cartuchos de tóner",
        "Fabricación de casas móviles",
        "Fabricación de cascos de realidad virtual",
        "Fabricación de casetes magnéticos de video y de audio",
        "Fabricación de células que contienen dióxido de magnesio, dióxido de mercurio, oxido de plata",
        "Fabricación de celulosas y sus derivados químicos",
        "Fabricación de cemento de la cal y del yeso",
        "Fabricación de cementos",
        "Fabricación de central telefónica privada, equipo (PBX)",
        "Fabricación de centrifugadoras (excepto separadores de crema y secadoras de ropa)",
        "Fabricación de cera de parafina",
        "Fabricación de cerámica no refractaria",
        "Fabricación de cerámica refractaria",
        "Fabricación de cerámica y los imanes de ferrita",
        "Fabricación de ceras",
        "Fabricación de ceras artificiales y ceras preparadas",
        "Fabricación de cercas de alambre",
        "Fabricación de cerraduras de tiempo",
        "Fabricación de cerraduras, herraje y artículos de ferretería N. C. P. (no incluye clavos, productos de buhonería, vajilla de mesa)",
        "Fabricación de cerveza negra",
        "Fabricación de cerveza, bebidas malteadas y de malta",
        "Fabricación de chalecos",
        "Fabricación de chales",
        "Fabricación de champú, lacas para el cabello",
        "Fabricación de chocolates y confitería de chocolate",
        "Fabricación de cigarros",
        "Fabricación de cilindros hidráulicos y neumáticos",
        "Fabricación de cintas de tela sensibles a la presión",
        "Fabricación de cintas magnéticas de video y de audio",
        "Fabricación de cintas transportadoras",
        "Fabricación de cintas transportadoras de goma o correas de transmisión o de bandas",
        "Fabricación de cinturones",
        "Fabricación de cinturones de conducción, envases etc.",
        "Fabricación de cinturones de seguridad, airbags, puertas y parachoques",
        "Fabricación de circuitos impresos",
        "Fabricación de circuitos integrados (análogos, digitales o híbridos)",
        "Fabricación de coches de viajeros, vagones de mercancía y vagones cisternas",
        "Fabricación de cocinas, estufas y calefactores de uso domestico no eléctricos",
        "Fabricación de cojines de descarga",
        "Fabricación de cojinetes; engranajes; trenes de engranaje y piezas de transmisión",
        "Fabricación de cola, adhesivos y cementos excepto los odontológicos obtenidos de sustancias minerales y vegetales",
        "Fabricación de colores preparados de pigmentos y colorantes ",
        "Fabricación de combustible nuclear",
        "Fabricación de combustibles de la refinación del petróleo",
        "Fabricación de combustibles gaseosos con un valor calorífico",
        "Fabricación de comparadores ópticos",
        "Fabricación de componentes de cuero",
        "Fabricación de componentes de pantallas",
        "Fabricación de compuesto de calafateo y similares",
        "Fabricación de compuestos para diagnósticos de laboratorio",
        "Fabricación de computadoras de escritorio",
        "Fabricación de computadoras portátiles",
        "Fabricación de concentrados de frutas y hortalizas frescas",
        "Fabricación de condensadores eléctricos",
        "Fabricación de condensadores, economizadores y colectores de vapor",
        "Fabricación de conductos de admisión y válvulas de escapes de los motores de combustión interna",
        "Fabricación de conductos de los aparatos eléctricos",
        "Fabricación de conductos eléctricos",
        "Fabricación de conservas o congelados de pastas",
        "Fabricación de consolas de videojuegos",
        "Fabricación de contenedores de papel o cartón corrugado",
        "Fabricación de contenedores metálicos para gases comprimidos o licuados",
        "Fabricación de contenedores para el transporte",
        "Fabricación de coque y de productos de refinación del petróleo",
        "Fabricación de corbatas",
        "Fabricación de cordón de cuero",
        "Fabricación de cortinas estéril, cadenas y tejidos quirúrgicos",
        "Fabricación de cortinas, doseles, colchas, cubre muebles, etc.",
        "Fabricación de cosechadoras, trilladoras y clasificadoras",
        "Fabricación de cosméticos, perfumes  y productos de higiene y tocador",
        "Fabricación de crema de leche fresca esterilizada",
        "Fabricación de crema de leche fresca homogeneizada",
        "Fabricación de crema de leche fresca pasteurizada",
        "Fabricación de cremas depiladoras",
        "Fabricación de crisoles, espátulas, ánodos de galvanoplasta",
        "Fabricación de cristales electrónicos",
        "Fabricación de cubiertas y cámaras de caucho",
        "Fabricación de cuchillas",
        "Fabricación de cuchillos y hojas cortantes para maquinas o aparatos mecánicos",
        "Fabricación de cuerdas o cables equipados con aro de metal",
        "Fabricación de cuerdas, cordeles, bramantes y redes",
        "Fabricación de curtientes naturales y sintéticos",
        "Fabricación de cuscús",
        "Fabricación de dentífrico y artículos para la higiene bucal",
        "Fabricación de desinfectantes (para la agricultura y otros usos)",
        "Fabricación de desodorantes y sales de baño",
        "Fabricación de detectores de mina, detectores de metales",
        "Fabricación de detectores de movimientos",
        "Fabricación de detergentes en polvo en estado sólido o líquido",
        "Fabricación de dientes artificiales, puentes realizados en laboratorios dentales",
        "Fabricación de diodos emisores de luz (LED)",
        "Fabricación de diodos, transistores y dispositivos discretos",
        "Fabricación de dirigibles y globos aerostáticos",
        "Fabricación de discos duros",
        "Fabricación de discos magnéticos, unidades flash y otros dispositivos de almacenamiento",
        "Fabricación de discos ópticos en blanco",
        "Fabricación de diskettes",
        "Fabricación de disolventes y diluyentes de compuestos orgánicos",
        "Fabricación de dispensadores de cinta",
        "Fabricación de dispositivos de propulsión de vehículos",
        "Fabricación de dispositivos GPS",
        "Fabricación de disyuntores de potencia (fusibles-breakers)",
        "Fabricación de diversos productos de plástico",
        "Fabricación de dolomita calcinada",
        "Fabricación de edredones, cojines, almohadas, sacos de dormir, etc.",
        "Fabricación de ejes de transmisión y manivelas, árboles de levas, cigüeñales, bielas, etc.",
        "Fabricación de ejes, ruedas, frenos, partes de freno, amortiguadores y partes de amortiguación",
        "Fabricación de ejes, ruedas, suspensiones y amortiguadores",
        "Fabricación de electrodomésticos del tipo de hornos",
        "Fabricación de elementos químicos",
        "Fabricación de eliminadores de residuos",
        "Fabricación de embragues y órganos de acoplamientos",
        "Fabricación de empastes dentales, cementos, cera dental y otras preparaciones dentales en yeso",
        "Fabricación de encendedoras, pulidoras, batidoras, licuadoras y similares",
        "Fabricación de encendedores de cigarrillos",
        "Fabricación de engranajes, cajas de engranajes y otros variadores de velocidad",
        "Fabricación de envases a partir de residuos sólidos de cartón",
        "Fabricación de envases de cartón",
        "Fabricación de envases de papel",
        "Fabricación de envases de vidrio",
        "Fabricación de envases metálicos",
        "Fabricación de envases plásticos",
        "Fabricación de envases plegables de cartón",
        "Fabricación de equipamientos de todos los tipos de vehículos de motor, remolques y semirremolques",
        "Fabricación de equipo de comunicación móvil",
        "Fabricación de equipo de elevación y manipulación (incluye la fabricación de ascensores, escaleras mecánicas, montacargas, etc.)",
        "Fabricación de equipo de transporte N. C. P.",
        "Fabricación de equipo deportivo para gimnasio",
        "Fabricación de equipo electrónico N. C. P.",
        "Fabricación de equipo médico y quirúrgico y aparatos ortopédicos ",
        "Fabricación de equipo médico y quirúrgico y de aparatos ortopédicos",
        "Fabricación de equipo óptico de posicionamiento",
        "Fabricación de equipos de comunicaciones de datos",
        "Fabricación de equipos de conmutación de potencia",
        "Fabricación de equipos de conmutación para oficina",
        "Fabricación de equipos de control de incendio",
        "Fabricación de equipos de controles de procesos industriales",
        "Fabricación de equipos de iluminación para equipos de transporte",
        "Fabricación de equipos de inspección y pruebas de las propiedades físicas",
        "Fabricación de equipos de karaoke",
        "Fabricación de equipos de medición y pruebas de electricidad y señales eléctricas",
        "Fabricación de equipos de preparación de aire para su uso en sistema neumático",
        "Fabricación de equipos de radar",
        "Fabricación de equipos de sistemas de grabación de sonido",
        "Fabricación de equipos de transmisión hidráulica",
        "Fabricación de equipos estereos",
        "Fabricación de equipos ópticos de observación para armas",
        "Fabricación de equipos para verificación de emisiones automotriz",
        "Fabricación de escaleras y barandillas",
        "Fabricación de escáneres",
        "Fabricación de escobillas de goma",
        "Fabricación de escopetas",
        "Fabricación de esmaltes y barnices vitrificables, engobes y preparaciones similares",
        "Fabricación de espectrómetros",
        "Fabricación de espejos ópticos",
        "Fabricación de espejos y cristales",
        "Fabricación de esquís, bastones y botas de esquí",
        "Fabricación de estatuas, muebles, bajorrelieves, floreros y macetas",
        "Fabricación de estatuillas de madera y objetos de adornos",
        "Fabricación de estatuillas y otros artículos ornamentales de cerámica",
        "Fabricación de esteras",
        "Fabricación de esterilizadores de laboratorios",
        "Fabricación de estructuras metálicas para la construcción",
        "Fabricación de etiquetas (labels)",
        "Fabricación de etiquetas, insignias, etc.",
        "Fabricación de excavadoras",
        "Fabricación de explosivos como bombas, minas y torpedos",
        "Fabricación de explosivos, municiones y productos de pirotecnia",
        "Fabricación de extractos de glándulas y tratamientos de glándulas",
        "Fabricación de extractos de productos aromáticos naturales",
        "Fabricación de facticio",
        "Fabricación de fenólicas y resinas epoxi y poliuretanos",
        "Fabricación de fibra de vidrio",
        "Fabricación de fibras artificiales, excepto el vidrio",
        "Fabricación de fibras de carbono, de grafito y sus productos",
        "Fabricación de fibras manufacturadas",
        "Fabricación de figuras de acción",
        "Fabricación de figuras de cristal",
        "Fabricación de filamentos sintéticos o artificiales de hilo",
        "Fabricación de fósforos",
        "Fabricación de fraguas y yunques",
        "Fabricación de frazadas, mantas, ponchos, colchas, cobertores, etc.",
        "Fabricación de frenos hidráulicos, martillos de caída",
        "Fabricación de furgonetas, camiones, tractores de carretera para semirremolque",
        "Fabricación de fusibles eléctricos",
        "Fabricación de fusibles o tiras",
        "Fabricación de galletas",
        "Fabricación de galletitas y biscochos",
        "Fabricación de ganchos y dispositivos de acoplamiento",
        "Fabricación de gas, distribución de combustible gaseoso por tubería",
        "Fabricación de gases comprimidos y licuados",
        "Fabricación de generadores de energía (excepto alternadores de carga de baterías)",
        "Fabricación de generadores de motor (excepto el generador de turbina)",
        "Fabricación de generadores de vapor",
        "Fabricación de generadores y motores eléctricos",
        "Fabricación de generadores, alternadores y bujías",
        "Fabricación de ginebra",
        "Fabricación de glicerol crudo",
        "Fabricación de glucosa, jarabe de glucosa, maltosa, inulina, etc.",
        "Fabricación de gluten",
        "Fabricación de go-karts y similares incluyendo carros de carrera",
        "Fabricación de goma de mascar",
        "Fabricación de grabadoras de video",
        "Fabricación de grapadoras y saca grapas",
        "Fabricación de grifos y válvulas para los sistemas de calefacción",
        "Fabricación de grifos y válvulas sanitarios",
        "Fabricación de grúas, bastidores y elevadores móviles",
        "Fabricación de guantes",
        "Fabricación de guata de celulosa y napa de fibras de celulosa",
        "Fabricación de habitaciones de metal",
        "Fabricación de harina de papa",
        "Fabricación de harina, aceite y grasa a base de pescado N. C. P.",
        "Fabricación de heladeras, frezar, lavaplatos y secarropas",
        "Fabricación de helicópteros",
        "Fabricación de herramientas agrícolas de mano sin motor",
        "Fabricación de herramientas de mano y sus accesorios",
        "Fabricación de herramientas de prensado",
        "Fabricación de herramientas, piezas y accesorios para maquinas de trabajar los metales y otros materiales",
        "Fabricación de hilados de algodón y sus mezclas",
        "Fabricación de hilados de filamentos sintéticos o artificiales de remolque",
        "Fabricación de hilados de lana y sus mezclas",
        "Fabricación de hilados metalizados o entorchado",
        "Fabricación de hilados textiles excepto de lana y algodón",
        "Fabricación de hilo y cuerda de goma",
        "Fabricación de hilos de estos metales antes mencionados",
        "Fabricación de hilos y cables aislados",
        "Fabricación de hojas corrugadas y otras hojas, paneles y losetas",
        "Fabricación de hojas de madera para enchapado",
        "Fabricación de hojas de madera para enchapado fabricación de tableros y contrachapados, tableros laminados y paneles ",
        "Fabricación de hornos de laboratorio dental",
        "Fabricación de hornos y quemadores",
        "Fabricación de impresoras",
        "Fabricación de indicadores de nivel neumático",
        "Fabricación de inductores",
        "Fabricación de inhibidores de germinación y reguladores de crecimiento de plantas",
        "Fabricación de instrumentos analíticos de laboratorios",
        "Fabricación de instrumentos de cuerda con teclado, incluidos los pianos automáticos",
        "Fabricación de instrumentos de detección y seguimiento de radiación",
        "Fabricación de instrumentos de topografía",
        "Fabricación de instrumentos de viento",
        "Fabricación de instrumentos dentales",
        "Fabricación de instrumentos meteorológicos",
        "Fabricación de instrumentos musicales",
        "Fabricación de instrumentos musicales de juguete",
        "Fabricación de instrumentos musicales de percusión",
        "Fabricación de instrumentos musicales que el sonido se produce por vía electrónica",
        "Fabricación de instrumentos ópticos de aumento",
        "Fabricación de instrumentos ópticos y equipo fotografito",
        "Fabricación de instrumentos para motores de aeronaves",
        "Fabricación de instrumentos y aparatos para medir, verificar, ensayar, navegar y otros fines, excepto el equipo de control de procesos industriales",
        "Fabricación de instrumentos, piezas y accesorios",
        "Fabricación de intercambiadores a base de polímeros",
        "Fabricación de interruptores de cables eléctricos",
        "Fabricación de interruptores de energía eléctrica",
        "Fabricación de inversores de estado sólido, pilas de combustibles",
        "Fabricación de jabones y detergentes",
        "Fabricación de jabones y detergentes, preparados para limpiar y pulir, perfumes y preparados de tocador",
        "Fabricación de jarabe de azúcar",
        "Fabricación de jerseys",
        "Fabricación de joyas que contienen piedras de imitación tales como imitación de diamantes",
        "Fabricación de joyas y artículos conexos",
        "Fabricación de juegos de mesa y similares",
        "Fabricación de juegos electrónicos",
        "Fabricación de juegos que funcionan con monedas, billares, mesas especiales para juego de casino",
        "Fabricación de juegos y juguetes",
        "Fabricación de jugos naturales y sus concentrados de frutas y hortalizas",
        "Fabricación de juntas y uniones similares hechas de una combinación de materiales o capas del mismo material",
        "Fabricación de juntas, hojas y paneles de yeso",
        "Fabricación de la maquinaria para uso en general",
        "Fabricación de la turbina-generador",
        "Fabricación de ladrillos",
        "Fabricación de laminados con papel y cartón",
        "Fabricación de laminados de acero",
        "Fabricación de laminados de acero en caliente, barras y varillas de acero",
        "Fabricación de laminados de metales preciosos",
        "Fabricación de lámparas",
        "Fabricación de lámparas de carburo, eléctricas, de gas, queroseno",
        "Fabricación de lámparas de mesa",
        "Fabricación de lámparas eléctrica y equipos de iluminación ",
        "Fabricación de lámparas eléctricas contra insectos",
        "Fabricación de lana de madera, harina de madera, astillas",
        "Fabricación de lanzadores de cohetes",
        "Fabricación de lápices conduce",
        "Fabricación de lápices, lapiceras, bolígrafos, sellos y artículos similares para oficinas",
        "Fabricación de las partes de calzados",
        "Fabricación de las partes internas y externas de los calzados",
        "Fabricación de latas para productos alimenticios, tubos plegables",
        "Fabricación de lava platos",
        "Fabricación de lavavajillas",
        "Fabricación de leche líquida fresca esterilizada",
        "Fabricación de leche líquida fresca homogeneizada",
        "Fabricación de leche líquida fresca pasteurizada",
        "Fabricación de leche o nata en estado sólido",
        "Fabricación de lectores de tarjetas inteligentes",
        "Fabricación de lentes y otros artículos oftálmicos",
        "Fabricación de levadura",
        "Fabricación de lienzos preparados para el uso del pintor",
        "Fabricación de locomotoras y de material rodante",
        "Fabricación de locomotoras y de material rodante para ferrocarriles y tranvía con sistemas eléctricos, diesel o vapor",
        "Fabricación de los productos de la refinación del petróleo",
        "Fabricación de maceta con suelo de turba como principal componente",
        "Fabricación de macetas con mezcla de suelo natural",
        "Fabricación de madera desinfectada",
        "Fabricación de maletas, bolsos de mano y artículos de talabartería y guarnicionaría",
        "Fabricación de maletas, bolsos de mano, y similares, artículos de talabartería y artículos de cuero N. C. P.",
        "Fabricación de maltas",
        "Fabricación de mangos de madera para paraguas, bastones y similares",
        "Fabricación de mangueras y accesorios hidráulicos y neumáticos",
        "Fabricación de manipuladores mecánicos y robots industriales diseñados específicamente para elevación, carga y descarga",
        "Fabricación de mantas eléctricas",
        "Fabricación de mantequilla",
        "Fabricación de maquillaje",
        "Fabricación de maquina de lavado y secado de ropa",
        "Fabricación de máquina para la producción de artículos de papel o cartón",
        "Fabricación de maquinaria agropecuaria y forestal",
        "Fabricación de maquinaria agropecuaria y forestal, excepto tractores",
        "Fabricación de maquinaria de impresión textil",
        "Fabricación de maquinaria de oficina, contabilidad e informática",
        "Fabricación de maquinaria de pulverización para uso agrícola",
        "Fabricación de maquinaria de uso especial",
        "Fabricación de maquinaria de uso especial N. C. P.",
        "Fabricación de maquinaria de uso general N. C. P.",
        "Fabricación de maquinaria metalúrgica",
        "Fabricación de maquinaria para el lavado, blanqueo, teñido, acabado y recubrimiento de tejidos",
        "Fabricación de maquinaria para el tratamiento de materiales mediante cribado, selección, clasificación, lavado, trituración, etc.",
        "Fabricación de maquinaria para embasado de moneda y conteo de moneda",
        "Fabricación de maquinaria para hacer confitería de cacao o chocolate",
        "Fabricación de maquinaria para la avicultura, apicultura, equipos para la preparación de forraje",
        "Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco",
        "Fabricación de maquinaria para la elaboración de productos textiles, prendas de vestir y cueros",
        "Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción",
        "Fabricación de maquinaria para la industria del papel y las artes graficas",
        "Fabricación de maquinaria para la limpieza de laboratorios de ultrasonido",
        "Fabricación de maquinaria para la perforación, corte, hundimiento de túneles",
        "Fabricación de maquinaria para la preparación de alimentos en hoteles y restaurantes",
        "Fabricación de maquinaria para la preparación de tabaco",
        "Fabricación de maquinaria para limpiar, seleccionar o clasificar huevos",
        "Fabricación de maquinaria para preparar frutas y vegetales",
        "Fabricación de maquinaria para preparar pescado y mariscos",
        "Fabricación de maquinaria para procesar carne de aves de corral",
        "Fabricación de maquinaria y equipo de oficina (excepto computadoras y equipos periféricos)",
        "Fabricación de maquinaria y equipo N. C. P.",
        "Fabricación de maquinarias para la extracción o preparación de aceites o grasas animales o vegetales",
        "Fabricación de maquinas auxiliares o equipos para maquinaria textil",
        "Fabricación de maquinas comerciales de lavado y secado",
        "Fabricación de maquinas de afeitar eléctricas, cepillos de dientes eléctricos y otros aparatos eléctricos del cuidado personal",
        "Fabricación de maquinas de afeitar y hojas de afeitar",
        "Fabricación de maquinas de coser y tejer",
        "Fabricación de maquinas de escribir",
        "Fabricación de maquinas de hilado",
        "Fabricación de maquinas de lavado en seco",
        "Fabricación de maquinas de limpieza por ultrasonidos",
        "Fabricación de maquinas de planchar incluyendo las prensas de fusión",
        "Fabricación de maquinas de polígrafos",
        "Fabricación de maquinas de protección de cheques",
        "Fabricación de maquinas de taquigrafía",
        "Fabricación de maquinas de telares, incluidos los telares de mano",
        "Fabricación de maquinas de ventas automáticas de productos",
        "Fabricación de maquinas estacionaria para clavar, grapar madera, corcho,  hueso, plástico",
        "Fabricación de maquinas fotocopiadoras",
        "Fabricación de maquinas herramienta, piezas para maquina de trabajar los metales y la madera (no eléctrica)",
        "Fabricación de maquinas ópticas de herramientas de precisión",
        "Fabricación de maquinas para el devanado de tejido",
        "Fabricación de maquinas para fabricar o reparar calzado u otro artículos de cuero, pieles",
        "Fabricación de maquinas para hacer nudo",
        "Fabricación de maquinas para la preparación de fibras textiles",
        "Fabricación de maquinas para la preparación de hilados textiles",
        "Fabricación de maquinas para la preparación, curtido o trabajo de cuero y pieles",
        "Fabricación de maquinas para la producción o acabado de fieltro o tela sin tejer",
        "Fabricación de maquinas y herramientas para el estampado y pulsado",
        "Fabricación de maquinas y herramientas para el torneado, taladrado y fresado",
        "Fabricación de marcadoras de fecha y hora",
        "Fabricación de marcos de madera para espejos y fotos",
        "Fabricación de marcos de madera para obras de arte",
        "Fabricación de masillas",
        "Fabricación de materia química inorgánica básica N. C. P.",
        "Fabricación de materiales a base de sustancias radioactiva para el diagnostico",
        "Fabricación de materiales de construcción de sustancias vegetales aglomerados con cemento, yeso u otros minerales",
        "Fabricación de materiales de fricción y artículos con una base de sustancias minerales o de celulosa",
        "Fabricación de materiales de vía férrea de acero",
        "Fabricación de materiales para pavimento y techado",
        "Fabricación de materiales utilizados en el acabado de los productos textiles y de cuero",
        "Fabricación de materias colorantes básicas, excepto pigmentos preparados",
        "Fabricación de materias plásticas en formas primarias N. C. P.",
        "Fabricación de materias químicas orgánicas básicas N. C. P. (incluye fabricación de alcoholes excepto el etílico, sustancias químicas para laboratorios",
        "Fabricación de mecanismos de filtrado y purificación",
        "Fabricación de mechas textiles y manguitos de incandescencia",
        "Fabricación de medias",
        "Fabricación de medicamentos de uso humano y productos farmacéuticos",
        "Fabricación de medicamentos para uso veterinario",
        "Fabricación de medidores de consumo",
        "Fabricación de medidores de flujos y aparatos para contar",
        "Fabricación de medidores de luz fotográfica",
        "Fabricación de melaza",
        "Fabricación de mesas de operación",
        "Fabricación de metales básicos",
        "Fabricación de mezcla de caucho sintético y caucho natural",
        "Fabricación de mezclas odoríferas de productos para la fabricación de perfumes o alimentos",
        "Fabricación de micrófonos",
        "Fabricación de microprocesadores",
        "Fabricación de microscopios de electrones y protones",
        "Fabricación de microscopios ópticos, binoculares y telescopios",
        "Fabricación de mobiliario para oficina",
        "Fabricación de molduras, tejas y listones de madera",
        "Fabricación de molinos, mezcladores, exprimidores de zumo",
        "Fabricación de monitores",
        "Fabricación de monitores de televisión",
        "Fabricación de monofilamentos o tiras sintéticas o artificiales",
        "Fabricación de morteros en polvo",
        "Fabricación de mosaicos",
        "Fabricación de mostaza",
        "Fabricación de motocicleta",
        "Fabricación de motores de ferrocarril",
        "Fabricación de motores de los vehículos automotores",
        "Fabricación de motores hidráulicos",
        "Fabricación de motores para motocicleta",
        "Fabricación de motores y turbinas, excepto motores para aeronaves, vehículos automotores y motocicletas",
        "Fabricación de motores, generadores y transformadores eléctricos",
        "Fabricación de motos de nieve, carritos de golf, vehículos anfibios",
        "Fabricación de mueblas para cocina",
        "Fabricación de muebles de piedra",
        "Fabricación de muebles especiales para tiendas, mostradores, vitrinas y estanterías",
        "Fabricación de muebles para iglesias y escuelas",
        "Fabricación de muebles y colchones",
        "Fabricación de muebles y marcos de ventana",
        "Fabricación de muebles y partes de muebles, principalmente de madera",
        "Fabricación de muebles y partes de muebles, principalmente de otros materiales (metal, plástico)",
        "Fabricación de múltiples unidades aislantes de paredes de vidrio",
        "Fabricación de muñecas, partes y accesorios",
        "Fabricación de municiones para guerra",
        "Fabricación de naipes",
        "Fabricación de neumáticos de rodaduras intercambiables, camelback tiras para el recauchutado de neumáticos",
        "Fabricación de neumáticos macizos o huecos",
        "Fabricación de objetos cerámicos excepto revestimiento de pisos y paredes N.C.P.",
        "Fabricación de objetos cerámicos para uso domestico excepto artefactos sanitario",
        "Fabricación de objetos de barro, loza, porcelana, etc.",
        "Fabricación de objetos de platería y artículos enchapados",
        "Fabricación de ojos de vidrio",
        "Fabricación de ollas de cerámica, frascos y artículos similares",
        "Fabricación de ordenadores de bolsillos",
        "Fabricación de órganos de tubo y teclado",
        "Fabricación de otras embarcaciones de recreo y deporte",
        "Fabricación de otros artículos de papel y cartón",
        "Fabricación de otros artículos similares",
        "Fabricación de otros cables eléctricos y electrónicos",
        "Fabricación de otros compuestos inorgánicos",
        "Fabricación de otros productos agroquímicos N. C. P.",
        "Fabricación de otros productos de caucho natural o sintético ",
        "Fabricación de otros productos de madera, fabricación de artículos de corcho, paja y materiales trenzables",
        "Fabricación de otros productos de metal N. C. P.",
        "Fabricación de otros productos minerales N. C. P.",
        "Fabricación de otros productos minerales no metálicos",
        "Fabricación de otros productos químicos N. C. P.",
        "Fabricación de otros textiles N. C. P.",
        "Fabricación de otros vinos de frutas y bebidas mezcladas con contenido alcohólico",
        "Fabricación de pagers (buscadores)",
        "Fabricación de pan y panecillos",
        "Fabricación de paneles de control para la distribución de la energía",
        "Fabricación de paneles de instrumentos, reguladores de voltaje",
        "Fabricación de panqueques",
        "Fabricación de pantallas, etc.",
        "Fabricación de papel a mano",
        "Fabricación de papel carbón",
        "Fabricación de papel crêped y papel plegado",
        "Fabricación de papel engomado o papel adhesivo",
        "Fabricación de papel higiénico",
        "Fabricación de papel para equipos de impresión",
        "Fabricación de papel para la impresión",
        "Fabricación de papel pintado y revestimientos similares de paredes",
        "Fabricación de papel prensa",
        "Fabricación de papel y cartón",
        "Fabricación de papel y cartón corrugado",
        "Fabricación de papel y cartón excepto envases",
        "Fabricación de papel y cartón filtro",
        "Fabricación de papel y cartón ondulado y envases de papel y cartón",
        "Fabricación de papel y de los productos de papel",
        "Fabricación de papel, guata, fieltro recubierto o revestido de jabón o detergente",
        "Fabricación de paraguas, sombrillas de sol, bastones",
        "Fabricación de parquímetros",
        "Fabricación de parrilla",
        "Fabricación de partes de mantas eléctricas",
        "Fabricación de partes de turborreactores para las aeronaves",
        "Fabricación de partes tales como fuselajes, alas, puertas, superficies de control, tren de aterrizaje y tanques de combustible",
        "Fabricación de partes y accesorios para bicicletas",
        "Fabricación de partes y accesorios para motores de vehículos",
        "Fabricación de partes y piezas de carpintería para edificios y construcciones",
        "Fabricación de partes; piezas y accesorios para vehículos automotores y sus motores",
        "Fabricación de pastas y polvos para el desengrasado",
        "Fabricación de pastas y polvos utilizados en soldadura",
        "Fabricación de patines de hielo, patines",
        "Fabricación de pegamento para madera estratificada, laminado de chapas madera",
        "Fabricación de películas y proyectores de diapositivas",
        "Fabricación de pelotas inflables duras y suaves",
        "Fabricación de pelucas",
        "Fabricación de peptonas, derivados de peptonas, otras sustancias proteicas y sus derivados N. C. P.",
        "Fabricación de perforadoras de papel",
        "Fabricación de pesticidas y otros productos químicos de uso agropecuario",
        "Fabricación de piedra artificial",
        "Fabricación de piezas de repuestos y accesorios para motocicleta",
        "Fabricación de piezas especializadas para el levantamiento y manejo de equipo",
        "Fabricación de piezas móviles de todo tipo de relojes",
        "Fabricación de piezas para maquinaria de uso general",
        "Fabricación de piezas y accesorios para radio y televisión",
        "Fabricación de pilas secas",
        "Fabricación de pintura, barnices y productos similares de revestimiento",
        "Fabricación de pinturas, barnices y productos de revestimiento similares; tintas de imprenta y masillas",
        "Fabricación de piso, paredes o techos en rollo o en forma de baldosa de plástico",
        "Fabricación de pisos de madera",
        "Fabricación de placas de cocción",
        "Fabricación de placas fotográficas, películas, papel sensibilizado",
        "Fabricación de placas, hojas, tiras, barras y perfiles de caucho",
        "Fabricación de placas, laminas, bloques, películas, cintas y tiras de plástico",
        "Fabricación de plaguicidas, y productos químicos de uso agropecuario",
        "Fabricación de plancha eléctrica",
        "Fabricación de planchas, calefactores, hornos eléctricos, tostadoras y otros aparatos generadores de calor",
        "Fabricación de planeadores",
        "Fabricación de plástico actual no portadora de dispositivos de cableado",
        "Fabricación de plástico y caucho sintético en forma primaria",
        "Fabricación de polainas, botines y artículos análogos",
        "Fabricación de poliamidas",
        "Fabricación de polvos propulsores (pólvora)",
        "Fabricación de pozos de agua",
        "Fabricación de premoldeados para la construcción",
        "Fabricación de prendas de vestir",
        "Fabricación de prendas de vestir, excepto prendas de piel",
        "Fabricación de prensas de troquelado",
        "Fabricación de prensas hidráulicas",
        "Fabricación de prensas para la fabricación de tableros de partículas y similares",
        "Fabricación de preparado para limpieza, pulido y saneamiento",
        "Fabricación de preparados para perfumar o desodorizar habitaciones",
        "Fabricación de preparados para quitar pintura o barniz",
        "Fabricación de preparados químicos para uso fotográfico",
        "Fabricación de procesadoras de temporizadores",
        "Fabricación de producto de horno de coque",
        "Fabricación de productos anticonceptivos para uso externo y medicamentos anticonceptivos hormonales",
        "Fabricación de productos aromáticos sintéticos",
        "Fabricación de productos cerámicos para uso industrial y de laboratorio",
        "Fabricación de productos de aperitivos",
        "Fabricación de productos de arcilla y cerámica no refractaria para uso estructural",
        "Fabricación de productos de arcilla y cerámica no refractaria para uso estructural N. C. P.",
        "Fabricación de productos de caucho N. C. P.",
        "Fabricación de productos de caucho y plástico",
        "Fabricación de productos de cerámica refractaria",
        "Fabricación de productos de cerámicas no refractaria para uso no industrial",
        "Fabricación de productos de corcho",
        "Fabricación de productos de herrería y soldadura",
        "Fabricación de productos de hornos de coque",
        "Fabricación de productos de laboratorio, sustancias químicas medicinales y productos botánicos N. C. P.",
        "Fabricación de productos de madera N. C. P.",
        "Fabricación de productos de panadería congelados: panqueques, wafles, rollos, etc.",
        "Fabricación de productos de panadería N. C. P. (incluye elaboración de churros, prepizas, masas fritas, hojaldre, etc.)",
        "Fabricación de productos de plástico",
        "Fabricación de productos de plástico en su forma básica y artículos de plástico N.C.P.",
        "Fabricación de productos de tejeduría N. C. P.",
        "Fabricación de productos de vidrio N. C. P.",
        "Fabricación de productos derivados del metal, excepto maquinaria y equipo",
        "Fabricación de productos farmacéuticos, sustancias químicas medicinales y de productos botánicos",
        "Fabricación de productos lácteos N. C. P. (incluye la producción de caseintos lácteos, cremas, manteca, etc.)",
        "Fabricación de productos médicos de diagnósticos, incluyendo las pruebas de embarazo",
        "Fabricación de productos metálicos de tornería y/o matricera",
        "Fabricación de productos metálicos N. C. P. (incluye clavos, productos de bulonería, vajilla de mesa y de cocina, etc.)",
        "Fabricación de productos metálicos para uso estructural",
        "Fabricación de productos metálicos para uso estructural, tanques, depósitos y los generadores de vapor",
        "Fabricación de productos minerales no metálicos N. C. P.",
        "Fabricación de productos oftálmicos, anteojos, lentes de sol, lentes de terreno para la prescripción, lentes de contacto",
        "Fabricación de productos para el aislamiento térmico",
        "Fabricación de productos para el manicure y el pedicura",
        "Fabricación de productos para el revestimiento de carretera",
        "Fabricación de productos para la industria petroquímica",
        "Fabricación de productos primarios de metales preciosos y metales no ferrosos",
        "Fabricación de productos químicos N. C. P.",
        "Fabricación de productos químicos N. C. P. (incluye la producción de aceites esenciales, etc.)",
        "Fabricación de proyectores",
        "Fabricación de puertas de apertura y cierre con dispositivos eléctricos",
        "Fabricación de puertas y ventanas de madera para la construcción",
        "Fabricación de puertas, ventanas, marcos, postigos, persianas y zócalos de plástico",
        "Fabricación de puertas, ventanas, persianas y sus marcos en metal",
        "Fabricación de pulpa a partir de la borra de algodón",
        "Fabricación de pulpa de madera",
        "Fabricación de pulpa, papel y cartón",
        "Fabricación de puré de papas deshidratado",
        "Fabricación de radiadores, silenciadores y tubos de escapes",
        "Fabricación de ramos, coronas y cestas de flores, flores, frutas y follaje artificiales",
        "Fabricación de ratones, joystick, TrackBall",
        "Fabricación de reactores nucleares",
        "Fabricación de receptores de radio y televisión, aparatos de grabación y reproducción de sonido y video y productos conexos",
        "Fabricación de recipientes de madera",
        "Fabricación de redes anudadas de cordeles, cuerdas o cordajes",
        "Fabricación de redes para pesca",
        "Fabricación de registros y libros, carpeta",
        "Fabricación de relojes",
        "Fabricación de relojes de metales preciosos",
        "Fabricación de relojes de metales preciosos, muñequeras y correas de relojes",
        "Fabricación de relojes de tiempo",
        "Fabricación de remolques para el transporte de mercancías",
        "Fabricación de remolques para el transporte de pasajeros",
        "Fabricación de replicas, crisoles, mufles, boquillas, tubos, caños, etc.",
        "Fabricación de reproductores de CD y DVD",
        "Fabricación de resinas y caucho sintético",
        "Fabricación de resistencias electrónicas",
        "Fabricación de resortes y otras piezas",
        "Fabricación de revestimiento cerámico",
        "Fabricación de revestimiento de suelo",
        "Fabricación de revólveres y pistolas",
        "Fabricación de rieles eléctrica",
        "Fabricación de ron",
        "Fabricación de ropa de cama, para mesa y de tocador o cocina",
        "Fabricación de roscado",
        "Fabricación de rotores de helicópteros y palas del rotor",
        "Fabricación de sables, espadas, bayonetas, etc.",
        "Fabricación de sacapuntas",
        "Fabricación de sacos y bolsas de papel",
        "Fabricación de sake",
        "Fabricación de salsa de tomate",
        "Fabricación de sartenes, asadores y parrillas",
        "Fabricación de secadores de pelo, peines y cepillos",
        "Fabricación de secadores para madera, pasta de papel o cartón",
        "Fabricación de segadoras, incluidas las cortadoras de césped",
        "Fabricación de selonoides, interruptores para aplicaciones electrónicas",
        "Fabricación de semiconductores acabados o semiacabados",
        "Fabricación de señales no eléctricas de plástico",
        "Fabricación de separadores y contenedores",
        "Fabricación de servidores informáticos",
        "Fabricación de sidecar",
        "Fabricación de sidra",
        "Fabricación de sierras",
        "Fabricación de siliconas",
        "Fabricación de sillas de ruedas, partes y accesorios",
        "Fabricación de sillas odontológicas",
        "Fabricación de sillas para bebes",
        "Fabricación de sillas y asientos de jardín",
        "Fabricación de sillas y asientos para oficinas, talleres, hoteles y restaurantes",
        "Fabricación de sillas y asientos para teatros y cines",
        "Fabricación de sistemas de alimentación interrumpida UPS",
        "Fabricación de sistemas de altavoces",
        "Fabricación de sistemas de energía de fluidos",
        "Fabricación de snacks de papas",
        "Fabricación de sobres y tarjetas",
        "Fabricación de sodas y agua tónica",
        "Fabricación de sofás, sofás camas",
        "Fabricación de somieres y colchones",
        "Fabricación de soportes de rodamiento y cojinetes",
        "Fabricación de suavizantes textiles",
        "Fabricación de sucedáneos de café",
        "Fabricación de suéteres y artículos similares de punto",
        "Fabricación de sustancias químicas",
        "Fabricación de sustancias químicas básicas, abonos, compuestos de nitrógeno, plásticos y caucho",
        "Fabricación de sustancias químicas básicas, excepto abonos y compuestos de nitrógeno",
        "Fabricación de tabaco para masticar",
        "Fabricación de tabiques de madera",
        "Fabricación de Tabla de examinación",
        "Fabricación de tableros contrachapados",
        "Fabricación de tableros de partículas de fibras",
        "Fabricación de tableros laminados",
        "Fabricación de tableros y paneles N. C. P.",
        "Fabricación de tacones, etc.",
        "Fabricación de tanques",
        "Fabricación de tanques, depósitos y recipientes de metal",
        "Fabricación de tapices y alfombras",
        "Fabricación de tarjetas de interfaz",
        "Fabricación de tazas, platos y bandejas",
        "Fabricación de techo de luminaria",
        "Fabricación de teclados",
        "Fabricación de tejidos de alambre",
        "Fabricación de tejidos de carbono y los hilos de aramida",
        "Fabricación de tejidos de fibra de vidrio",
        "Fabricación de tejidos de punto y artículos de punto y ganchillo",
        "Fabricación de tejidos estrecho, incluidos los tejidos que consisten en deformar sin trama reunidos por medio de un adhesivo",
        "Fabricación de tejidos para cuerdas de neumáticos de alta tenacidad",
        "Fabricación de tejidos planos de algodón y sus mezclas",
        "Fabricación de tejidos planos de fibras manufacturadas y de seda",
        "Fabricación de tejidos planos de fibras textiles N. C. P. (incluye heladerías y tenedurías integradas)",
        "Fabricación de tejidos planos de lana y sus mezclas",
        "Fabricación de tejidos y artículos de punto N. C. P.",
        "Fabricación de telas impregnadas, recubiertas, revestidas o estratificadas con plásticos",
        "Fabricación de telas recubiertas con goma de mascar o de materiales amiláceas",
        "Fabricación de teléfonos celulares",
        "Fabricación de teléfonos inalámbricos",
        "Fabricación de teléfonos y equipos de fax, incluyendo los contestadores",
        "Fabricación de terminales de ordenadores especializados",
        "Fabricación de termómetros líquidos en vidrio y tipos bimétalicos",
        "Fabricación de termómetros médicos",
        "Fabricación de tijeras",
        "Fabricación de tintas",
        "Fabricación de tisúes",
        "Fabricación de toallas",
        "Fabricación de toallas sanitarias, tampones, pañales y mantillas para bebes",
        "Fabricación de todo tipo de relojes",
        "Fabricación de tomas de electricidad",
        "Fabricación de tornillos, jeringas, agujas, catéteres y cánulas",
        "Fabricación de tortas, pasteles, tartas, etc.",
        "Fabricación de tortillas",
        "Fabricación de tostadoras y cafeteras eléctricas",
        "Fabricación de tractores",
        "Fabricación de tractores (controlado peatonalmente)",
        "Fabricación de tractores usados en la construcción y minería",
        "Fabricación de transmisores de radio y televisión y de aparatos para la telefonía y telegrafía con hilos",
        "Fabricación de transportadores, teleféricos",
        "Fabricación de trenzas, borlas, pompones, etc.",
        "Fabricación de tubo de goma dura",
        "Fabricación de tubos con soldaduras",
        "Fabricación de tubos de plástico, mangueras y accesorios para tuberías",
        "Fabricación de tubos de torpedos",
        "Fabricación de tubos sin soldadura, tubos y perfiles huecos de acero",
        "Fabricación de tubos y neumáticos de caucho, reconstrucción de neumáticos de caucho",
        "Fabricación de tubos, caños y mangueras",
        "Fabricación de tubos, caños, embalses, canales, cuencas y sumideros",
        "Fabricación de tubos, válvulas y otros componentes electrónicos",
        "Fabricación de tules y otros tejidos",
        "Fabricación de turbinas de gas, excepto turborreactores o turbo hélices para aviones de propulsión",
        "Fabricación de turbinas de vapor",
        "Fabricación de turbinas eólicas",
        "Fabricación de turbinas hidráulicas, ruedas hidráulicas y los reguladores del mismo",
        "Fabricación de unidades ópticas como CD-RW, CD ROM, DVD ROM",
        "Fabricación de utensilios de madera para el hogar y utensilios de cocina",
        "Fabricación de vacunas",
        "Fabricación de vajillas de plástico, utensilios de cocina y artículos de tocador",
        "Fabricación de vajillas, cubiertos, artículos de tocador, artículos de oficina o de escritorio y artículos para uso religioso",
        "Fabricación de válvulas hidráulicas y neumáticas",
        "Fabricación de vaselina",
        "Fabricación de vehículos automotores",
        "Fabricación de vehículos automotores, remolques y semirremolques",
        "Fabricación de vehículos de mantenimiento",
        "Fabricación de vehículos tirados por animales",
        "Fabricación de velas y Tablas de surf",
        "Fabricación de velas, cirios y similares",
        "Fabricación de veleros con o sin motor auxiliar",
        "Fabricación de ventiladores (ventiladores de techo, etc.)",
        "Fabricación de ventiladores, extractores y acondicionadores de aire, aspiradores y similares",
        "Fabricación de vermut y similares",
        "Fabricación de vestidos de gamuza, vestidos pergamino, patente o cueros metalizados",
        "Fabricación de vidrio en barra o tubos",
        "Fabricación de vidrio para reloj, para gafas, vidrios ópticos",
        "Fabricación de vidrio plano templado o laminado",
        "Fabricación de vidrio y productos de vidrio",
        "Fabricación de vidrios y parabrisas para automóviles",
        "Fabricación de vigas y estructuras para techo",
        "Fabricación de vinagre",
        "Fabricación de vinilo recubierto de textil y papel tapiz",
        "Fabricación de vino a base de concentrado de mosto de uva",
        "Fabricación de vinos espumosos",
        "Fabricación de vitaminas básicas",
        "Fabricación de viviendas prefabricadas de madera",
        "Fabricación de volantes y poleas",
        "Fabricación de volantes, columnas de dirección y cajas de dirección",
        "Fabricación de yeso",
        "Fabricación de yogurt",
        "Fabricación en industrias básicas de productos de hierro y acero N. C. P.",
        "Fabricación industrial de grifos y válvulas incluyendo la regulación de grifos y válvulas de admisión",
        "Fabricación industrial de helados (no incluye las heladerías artesanales)",
        "Fabricación industrial de marcos de metal (marcos para los altos hornos, aparatos elevadores y de manejo de equipos, etc.)",
        "Fabricación y distribución de combustibles gaseosos N. C. P.",
        "Fabricación y distribución de gas (no incluye el transporte por gaseoductos)",
        "Fabricación y el montaje permanente de calentadores eléctricos, calentadores eléctricos de piscina",
        "Fabricación y elaboración de vidrio plano y templado",
        "Fabricación y montaje de calefacción de vapor",
        "Fabricación y montaje de calefacción solar",
        "Fabricación y montaje de hornos y equipos de calefacción",
        "Fabricación y reparación de aviones",
        "Fabricación y reparación de baterías",
        "Fabricación y reparación de buques (incluye la construcción de motores y piezas para navíos, etc.)",
        "Fabricación y reparación de maquinaria de la industria petrolera",
        "Fabricación, blanqueo y semi-blanqueado de pasta de papel por medios mecánicos o químicos",
        "Fabricaron de instrumento de óptica",
        "Factoría de arroz",
        "Ferias y exposiciones",
        "Fermentación de la caña de azúcar, maíz y similares para producir alcohol y esteres",
        "Fideicomisos",
        "Financiación del comercio internacional",
        "Financiación por enfermedad, accidentes de trabajo y seguros de desempleo",
        "financieros excepto los de la banca central y las entidades financieras",
        "Fondo monetario internacional",
        "Fondos de inversión abierto",
        "Fondos de inversión cerrado",
        "Fondos de inversiones de portafolio",
        "Forjado, prensado, estampado y laminados de metales; pulvimetalurgia",
        "Formación de postgrado",
        "Fotograbado, flexográfica y otras prensas, maquinas de duplicación, impresoras de computadora",
        "Fotografía aérea",
        "Fotografía de publicidad, editoriales, moda, inmobiliarias o turísticas",
        "Fuerzas de combate de ejército, marina y fuerza aérea",
        "Funcionamiento de cantinas y cafeterías sobre una base de concesión",
        "Fundición de acero",
        "Fundición de acero en hornos de alta temperatura, producción de lingotes o planchas de acero",
        "Fundición de grafito esferoidal",
        "Fundición de hierro gris",
        "Fundición de hierro maleable",
        "Fundición de hierro y acero",
        "Fundición de metales",
        "Fundición de metales ligeros",
        "Fundición de metales no ferrosos",
        "Fundición de piezas de metales pesados",
        "Fundición de piezas de metales preciosos",
        "Fundición de productos semiacabados de aluminio, magnesio, titanio, zinc, etc.",
        "Fundición despiezas de acero",
        "Fundición y refinación de uranio",
        "Generación de energía hidráulica (incluye la producción de energía eléctrica mediante centrales de bombeo)",
        "Generación de energía N. C. P. (incluye la producción de energía eléctrica mediante fuentes de energía solar, biomosa, eólica, etc.)",
        "Generación de energía térmica convencional (incluye la producción de energía eléctrica mediante maquinas turbogas, turbo vapor)",
        "Generación de energía térmica nuclear (incluye la producción de energía mediante combustible nuclear)",
        "Generación de informes especializados a partir de los datos suministrados por los clientes",
        "Gestión de cobro",
        "Gimnasios",
        "Giros postales y actividades postales de bancos",
        "Hipódromo",
        "Hogares de ancianos con cuidado de enfermería",
        "Hogares para ciegos",
        "Hogares y albergues infantiles",
        "Hoteles Resorts",
        "Humidificación de los edificios",
        "Hundimiento de ejes",
        "Impregnación o revestimiento de prendas de vestir para la impermeabilización",
        "Impresión de libros y folletos",
        "Impresión de manuscritos, mapas, atlas, carteles, catalogo de publicidad y otros impresos publicitarios",
        "Impresión de periódicos, revistas",
        "Impresión directa sobre materiales textiles, plásticos, vidrio, metal, madera y cerámica",
        "Impresión en las etiquetas o tags",
        "Impresión excepto de periódicos, revistas",
        "Impresión y ampliado de los negativos de rollo de cine",
        "Impuesto sucesoral",
        "Industria básica de hierro y acero",
        "Industrias de explotación de recursos naturales y minería",
        "Ingeniería acústica",
        "Ingeniería de sistemas y de seguridad",
        "Ingeniería química",
        "Ingeniero agrónomo",
        "Ingeniero calculista",
        "Ingeniero comercial",
        "Ingeniero electrónico",
        "Ingeniero mecánico",
        "Ingenieros, transporte, comunicaciones, inteligencia, personal y otras fuerzas no de combate y comandos",
        "Iniciativas ciudadanas y movimientos de protesta",
        "Inseminación artificial y servicios N. C. P. para mejorar la reproducción de los animales y el rendimiento de sus productos",
        "Instalación (configuración) de computadoras personales",
        "Instalación de alarmas contra incendios",
        "Instalación de alfombras y linóleo para suelos, incluidos los de caucho o de plástico",
        "Instalación de antenas parabólicas",
        "Instalación de ascensores, montacargas y escaleras mecánicas",
        "Instalación de barreras, señales de tráfico",
        "Instalación de carpintería, herrería de obra y artística",
        "Instalación de cerámica, hormigón, paredes talladas en piedras o baldosas",
        "Instalación de cocinas equipadas",
        "Instalación de colectores de energía solar no eléctricos",
        "Instalación de equipos de red, cableado de televisión por cable",
        "Instalación de hornos, torres de enfriamiento",
        "Instalación de mobiliarios",
        "Instalación de papel tapiz",
        "Instalación de pararrayos",
        "Instalación de puertas giratorias automatizadas",
        "Instalación de redes informáticas",
        "Instalación de sistema de riego de césped",
        "Instalación de sistemas de alarmas de seguridad",
        "Instalación de sistemas de iluminación de pistas de aterrizajes en aeropuertos",
        "Instalación de sistemas de iluminación, control y señalización eléctrica para el transporte",
        "Instalación de sistemas de limpieza",
        "Instalación de sistemas de rociadores contra incendio",
        "Instalación de tacones",
        "Instalación de terrazo, mármol, granito o revestimiento de paredes",
        "Instalación de tuberías de vapor",
        "Instalación de ventilador, refrigeración o aire acondicionado o conductos",
        "Instalación y reparación de caños de escape",
        "Instalación y reparación de lunetas y ventanillas, alarmas, cerraduras,  radios, sistemas de climatización automotor",
        "Instalación, reparación, reconstrucción de cajas fuertes y bóvedas de seguridad",
        "Instalación, reparación, reconstrucción de dispositivos de bloqueo mecánico o electrónico",
        "Instalaciones de carpintería, herrería de obra y artística (incluye la instalación de puertas y ventanas, carpintería metálica y no metálica)",
        "Instalaciones de cuidado de enfermería",
        "Instalaciones de gas, agua y sanitarios con sus artefactos conexos (incluyendo las instalaciones de compactadores, calderas)",
        "Instalaciones de procesamiento de datos y servicios de soporte relacionado",
        "Instalaciones para edificios y obras de ingeniería N. C. P.",
        "Instalaciones para el tratamiento de alcoholismo y adicción a las drogas",
        "Institutos meteorológicos",
        "Instrucción en habilidades para conversar e idiomáticas, cursos de lectura  rápida, instrucción religiosa",
        "Instrucción para chef, hoteleros y dueños de restaurantes",
        "Instrucción para guías turísticos",
        "Intercambio de opciones de comoditis o acciones",
        "Intercambio de valores",
        "Intermediación monetaria y financiera de la banca central",
        "Intermediación monetaria y financiera de las entidades financieras y bancarias y no bancarias",
        "Interpretar el rol de banquero del gobierno",
        "Inventarios forestales",
        "Invernada de ganado bovino excepto el engorde en corrales (fed-lot)",
        "Investigación de aceptación y la familiaridad de los productos",
        "Investigación de ciencias biológicas",
        "Investigación de los hábitos de compra de los consumidores con el propósito de la promoción y desarrollo de nuevos productos",
        "Investigación interdisciplinaria predominantemente en ciencias sociales y humanas",
        "Investigación y desarrollo científico",
        "Investigación y desarrollo experimental en el campo de la ingeniería y de las ciencias exactas y naturales",
        "Investigación y desarrollo experimental en el campo de la ingeniería y la tecnología",
        "Investigación y desarrollo experimental en el campo de las ciencias agropecuarias",
        "Investigación y desarrollo experimental en el campo de las ciencias exactas y naturales N. C. P.",
        "Investigación y desarrollo experimental en el campo de las ciencias humanas",
        "Investigación y desarrollo experimental en el campo de las ciencias médicas",
        "Investigación y desarrollo experimental en el campo de las ciencias sociales",
        "Investigación y desarrollo experimental en el campo de las ciencias sociales y las humanas",
        "Laboratorios de revelado e impresión de fotos",
        "Laminación y estirado",
        "Lana de escoria, lana de roca y lanas minerales similares",
        "Lanzamiento de satélites y vehículos espaciales",
        "Lavado automático y manual",
        "Lavado de alfombras y tapices",
        "Lavado y limpieza de artículos de tela, cuero y/o de piel, incluso la limpieza en seco",
        "Lavandería y servicio relacionado a las actividades de apoyo",
        "Leasing automotor",
        "Licuefacción y regasificación del gas natural con fines de transporte",
        "Limpiabotas, portadores de maleta y personas encargadas de estacionar vehículos",
        "Limpieza de casas o apartamentos",
        "Limpieza de fábricas",
        "Limpieza de los edificios nuevos después de la construcción",
        "Limpieza de obras de construcción",
        "Limpieza de oficinas e instituciones",
        "Limpieza de tiendas",
        "Limpieza general de interior, mantenimiento y eliminación de basura",
        "Líneas ciudad – aeropuerto y viceversa",
        "Logística militar (suministro de equipos y estructuras)",
        "Lotería y otros juegos de azar",
        "Lucha contra los incendios forestales",
        "Manejo de concesiones de alimentos en instalaciones de deportes y similares",
        "Mantenimiento de la tierra para uso agrícola",
        "Mantenimiento de tumbas y mausoleos",
        "mantenimiento y reparación de frenos",
        "Mantenimiento y reparación de maquinaria de oficina, contabilidad e informática",
        "mantenimiento y reparación de maquinaria de oficina, contabilidad e informática",
        "Mantenimiento y reparación de motocicletas",
        "Mantenimiento y reparación de vehículos de motor",
        "Mantenimiento y reparación del motor N. C. P. mecánica integral (incluye auxilio y servicio de grúa)",
        "Maquinas para el control de la presión arterial que funciona con moneda",
        "Marketing de los puntos de venta",
        "Matadero de ganado bovino",
        "Matadero de ganado excepto el bovino y el procesamiento de su carne (incluye ganado ovino, porcino, equino, búfalo, etc.)",
        "Matadero y procesamiento de carne de aves",
        "Matanza de animales N. C. P. y procesamiento de su carne; elaboración de subproductos cárnicos N. C. P.",
        "Mezcla de bebidas alcohólicas",
        "Mezcla de biocarburantes, es decir mezcla de alcohol con petróleo",
        "Mezcla de té y yerba mate",
        "Minería de sulfato de bario y carbonato (baritina y witherita)",
        "Minería de tierras colorantes y fluorita",
        "Minería de yeso y anhidrita",
        "Modificación química de aceites y grasas",
        "Molienda de trigo",
        "Monitorio de sistemas de seguridad de alarmas electrónicas",
        "Montaje de diapositivas Montaje de diapositivas",
        "Montaje y desmontaje de andamios y plataformas de trabajo excluyendo el alquiler de andamios y plataformas de trabajo",
        "Movimiento de suelos y preparación de terrenos para obras N. C. P. (incluye el drenaje, remoción de rocas, excavación de zanjas)",
        "Movimientos ecologistas y de medio ambiente",
        "Mudanzas y acarreo",
        "Museo de historia natural, museos tecnológicos, museos históricos incluyendo los museos militares",
        "Museos al aire libre",
        "Museos de arte, Museos de joyas, muebles, trajes, cerámica y platería",
        "Músicos",
        "Naciones Unidas y sus organismos especializados y órganos regionales",
        "Negocios de canje de cheques",
        "Negocios de juegos de azar (Bancas de Lotería y Apuestas)",
        "Obras artísticas incluidas la preparación de piedra litográfica",
        "Obras de drenaje",
        "Obras de ingeniería civil N. C.P. (incluye los trabajos generales de construcción para la minería y la industria y centrales eléctricas)",
        "Obras de vías férreas",
        "Obreros",
        "Obtención y dotación de personal",
        "Oficinas de organismos internacionales",
        "Operación (explotación) de juegos que funcionan con monedas",
        "Operación de almacenes generales de mercancías",
        "Operación de almacenes refrigerados",
        "Operación de autobuses para el transporte de los empleados",
        "Operación de coches camas o coches comedores como parte integrada de operaciones de compañías ferroviarias",
        "Operación de electricidad y capacidad de transmisión para los intercambios de energía eléctrica",
        "Operación de equipos de riego agrícola",
        "Operación de excursión, cruceros o embarcaciones turísticas",
        "Operación de funiculares, teleféricos si son parte del sistema de transito urbano o suburbano",
        "Operación de infraestructura ferroviaria",
        "Operación de instalaciones de la terminal tales como la estación de tren",
        "Operación de instalaciones de la terminal, estación de autobuses",
        "Operación de instalaciones de la terminal, estaciones para la manipulación de mercancías",
        "Operación de intermediación de carne (consignatario directo)",
        "Operación de intermediación de carne excepto consignatario directo",
        "Operación de intermediación de ganado en pie (incluye consignatarios de hacienda y ferieros)",
        "Operación de los canales de riego",
        "Operación de pesca deportiva y de caza de preservación",
        "Operación de portales de Internet de apuestas",
        "Operación de reservas naturales, incluida la preservación de la fauna silvestre, etc.",
        "Operación de silos de granos",
        "Operación de tanque de almacenamiento",
        "Operación de teleféricos, funiculares, telesillas",
        "Operación de una variedad de atracciones mecánicas",
        "Operación de una variedad de juegos",
        "Operaciones de desembalaje",
        "Operaciones de embalaje temporal con el único proteger las mercancías durante el transito",
        "Operaciones de esclusas",
        "Operaciones de ferries, taxis acuáticos, etc.",
        "Operaciones de intermediación de lanas, cueros y productos afines de terceros",
        "Operaciones de vehículos de motor",
        "Operaciones inmobiliarias de inversionistas extranjeros",
        "Operadoras de franquicias",
        "Operadoras de restaurantes",
        "Operadoras de zonas francas",
        "Operadores de hoteles",
        "Orfanatos",
        "Organización de colección, sean estas especializadas o no",
        "Organización de envíos grupales o individuales",
        "Organización de países exportadores de petróleo",
        "Organización y montaje de tours con transporte, alojamiento y comida",
        "Organizaciones de apoyo a la comunidad y centros de enseñanzas N. C. P.",
        "Organizaciones genealógicas",
        "Organizaciones no Gubernamentales, Organizaciones sin fines de lucro, y Asociaciones Civiles con fines caritativos o religiosos",
        "Organizaciones para la cooperación y el desarrollo económico",
        "Organizaciones para la protección y mejoramiento de los grupos especiales",
        "Ostricultura",
        "Otorgamiento de crédito para viviendas por entidades especializadas no depositarias",
        "Otras actividades de desarrollo y preparación de los minerales y propiedades de los sitios",
        "Otras actividades de edición",
        "Otras actividades de entretenimiento N. C. P.",
        "Otras actividades de esparcimiento",
        "Otras actividades de servicios N. C. P.",
        "Otras actividades empresariales",
        "Otras actividades financieras, excepto seguros y fondos de pensiones",
        "Otras actividades recreativas no clasificadas en otra parte",
        "Otras actividades relacionadas con la silvicultura N. C. P.",
        "Otras consultarías técnicas",
        "Otras industrias manufactureras",
        "Otras industrias manufactureras N. C. P.",
        "Otras industrias manufactureras N. C. P. (incluye la fabricación de cochecitos de bebe, termos, etc.)",
        "Otros cultivos de frutas cítricas",
        "Otros cultivos de frutas con hueso y con pepa",
        "Otros cultivos de frutas tropicales y subtropicales",
        "Otros cultivos de hojas o tallos vegetales",
        "Otros cultivos de raíces y bulbos",
        "Otros museos especializados",
        "Otros no clasificados",
        "Otros servicios (estudiantes, amas de casa, pensionados)",
        "Otros servicios de ingeniería",
        "Otros tipos de transporte por vía terrestre",
        "Participación en empresas",
        "Patentes y derechos de autor",
        "Payasos",
        "Pedicuros (podólogos)",
        "Pelado y cortado de verduras",
        "Peluquería y otros tratamientos de belleza",
        "Pensiones de jubilación",
        "Perforación y prueba de pozos taladrados",
        "Perforación y sondeo excepto la perforación de pozos de petróleo, de gas, de minas",
        "Periodistas",
        "Personas físicas designadas como directivos o representantes de partidos políticos",
        "Pesca continental, fluvial y lacustre",
        "Pesca de altamar, costera y en estuarios",
        "Pesca de camarones ",
        "Pesca marítima (incluye peces, crustáceos, moluscos, mariscos excepto camarones)",
        "Pesca y servicios conexos",
        "Pintores",
        "Pintura de estructuras de ingeniería civil",
        "Pintura sobre el pavimento y otro tipo de marcado",
        "Pintura y trabajos de decoración",
        "Piscinas y centros de fitness",
        "Planeación de bosques",
        "Planeamiento de pueblos y ciudades (urbano) y arquitectura de paisajes",
        "Planificación y diseño de los sistemas informáticos",
        "Plegado de acero",
        "Pompas fúnebres y servicios conexos",
        "Preparación de alimentos con ingredientes homogeneizados",
        "Preparación de alimentos en puestos de mercado",
        "Preparación de arroz",
        "Preparación de conserva de frutas, hortalizas y legumbres",
        "Preparación de declaraciones de ingresos para personas y empresas",
        "Preparación de fibras animales de uso textil, incluso lavado de lana",
        "Preparación de fibras textiles vegetales excepto de algodón (incluye la preparación de fibras de yute, ramio, cáñamo y limo)",
        "Preparación de hoja de tabaco",
        "Preparación de hojas de te",
        "Preparación de la turba",
        "Preparación de mezcla (única) para la alimentación de animales de granja",
        "Preparación de planchas o troqueles para la impresión o estampación",
        "Preparación de productos botánicos para uso farmacéutico",
        "Preparación de terrenos para obras",
        "Preparación e hilatura de fibras textiles, tejeduras de productos textiles",
        "Preparación o auditoría de las cuentas financieras",
        "Preparación para procesos de admisión",
        "Preparación y congelación de papas",
        "Preparación y molienda de legumbres y cereales N. C. P. (excepto trigo)",
        "Preparación y venta de comidas para llevar N. C. P. (incluye casas de comidas, rosticerías, etc.)",
        "Preparación, curtido y acabado del cuero, fabricación de maletas, bolsos de mano, artículos de talabartería y guarnicionería, y calzado",
        "Preparados para lactantes",
        "Preservación en azúcar de frutas, nueces, cáscaras de frutas",
        "Prestación de dinero fuera del sistema financiero",
        "Prestación de los fallos y de la interpretación de la ley",
        "Prestación de servicios de entrada de datos",
        "Prestación de servicios ordinarios",
        "Prestación publica de servicios a la comunidad en general",
        "Prestamos y almacenaje de libros, mapas, periódicos, revistas, películas, expedientes, cintas y obras de arte",
        "Procesamiento de carne de ganado bovino (incluye los mataderos y frigoríficos que sacrifican principalmente ganado bovino)",
        "Procesamiento de carne fresca refrigerada o congelada en trozos",
        "Procesamiento de carne fresca refrigeradas o congeladas en porciones individuales",
        "Procesamiento de datos",
        "Procesamiento de la sangre",
        "Procesamiento de peces, crustáceos y moluscos: pescado cocido, filetes de pescado, huevas, caviar, sucedáneos de caviar, etc.)",
        "Procesamiento y conservación de pescado, crustáceos y moluscos",
        "Procesos para obtener aceites crudos: decantación, desalinización, deshidratación, estabilización, etc.",
        "procuradores y alguaciles",
        "Producción de acero en lingotes o demás formas primarias",
        "Producción de aire comprimido",
        "Producción de aleación de aluminio",
        "Producción de aleaciones de acero",
        "Producción de aleaciones de cromo, manganeso y níquel",
        "Producción de aleaciones de metales preciosos",
        "Producción de aluminio a base del refinado electrolítico de aluminio",
        "Producción de avena",
        "Producción de bananas",
        "Producción de brea y coque de brea",
        "Producción de carbón de leña en los bosques (utilizando los métodos tradicionales)",
        "Producción de cobre a base de refinado electrolítico del cobre",
        "Producción de comida a base de pescado y otros animales acuáticos no aptas para el consumo humano",
        "Producción de coque en horno de gas",
        "Producción de coque y semi coque",
        "Producción de cromo, manganeso y níquel a base de refinado electrolítico de cromo, manganeso y níquel",
        "Producción de cromo, manganeso y níquel de los minerales u óxidos",
        "Producción de crudo de alquitrán, de carbón y de lignito alquitranes",
        "Producción de crudo de hidrocarburos gaseosos (gas natural)",
        "Producción de espectáculos teatrales y musicales",
        "Producción de filmes y videocintas",
        "Producción de fuel oil ligero, medio y pesado, refinería de gases como el etano, butano, propano, etc.",
        "Producción de gases inertes como el dióxido de carbono",
        "Producción de gases mixtos industriales",
        "Producción de gases refrigerantes",
        "Producción de harina de arroz",
        "Producción de harina de centeno",
        "Producción de harina o sémola de frutos secos comestible",
        "Producción de hielo",
        "Producción de hierro de pureza excepcional por electrolisis u otros procesos químicos",
        "Producción de hierro granulado y polvo de hierro",
        "Producción de hierro por reducción directa de productos de hierro y otros productos férreos esponjosos",
        "Producción de huevo",
        "Producción de jarabe de arce y azúcar",
        "Producción de laminados de oro",
        "Producción de laminados de plata a base de metales",
        "Producción de láminas de aluminio",
        "Producción de lana",
        "Producción de lana ovina y caprina",
        "Producción de larvas de camarones y alevines",
        "Producción de leche de ganado bovino",
        "Producción de leche de ganado N. C. P. (incluye la cría de búfalos, cabra, etc. Para la producción de leche)",
        "Producción de madera en rollo basado en las industrias manufactureras",
        "Producción de madera y fabricación de productos de madera y corcho, excepto muebles",
        "Producción de metales no ferrosos N. C. P. y sus semielaborados",
        "Producción de minerales de cobre",
        "Producción de otras formas de propagación de cultivos agrícolas (incluye gajos, bulbos, estacas enraizadas o no, esquejes, plantines, etc.)",
        "Producción de oxido de aluminio",
        "Producción de pelos (incluye la cría de caprinos, camélidos, etc. Para la obtención de pelos)",
        "Producción de petróleo extraído del esquito bituminoso y arena de alquitrán",
        "Producción de platino y metales del grupo del platino",
        "Producción de plomo, zinc y estaño",
        "Producción de plomo, zinc y estaño a base de refinado electrolítico de aluminio",
        "Producción de programas de televisión o comerciales de televisión",
        "Producción de semillas de cultivos agrícolas N. C. P. ",
        "Producción de semillas de hortalizas y legumbres, flores y plantas ornamentales y árboles frutales",
        "Producción de semillas hibridas de cereales y oleaginosas",
        "Producción de semillas varietales o autofecundadas de cereales, oleaginosas y forrajeras",
        "Producción de uranio metálico de pitchblende u otros materiales",
        "Producción de videos",
        "Producción y distribución de agua fría para refrigeración",
        "Producción y distribución de aire frío",
        "Producción y distribución por televisión (no incluye la transmisión)",
        "Producción y refinado de metales preciosos como el oro, plata, platino, etc.",
        "Producción, procesamiento y conservación de carnes y productos cárnicos",
        "Producción, transmisión y distribución de energía eléctrica",
        "Productos agrícolas en general",
        "Productos básicos y la capacidad de transporte de los combustibles gaseosos",
        "Programación informática, consultarías y actividades relacionadas",
        "Programas que cubren la pérdida de ingresos debido a la maternidad, incapacidad temporal y viudez",
        "Promedio y ajuste por pérdida",
        "Promoción de los productos",
        "Promoción y producción de espectáculos deportivos",
        "Promotores de espectáculos",
        "Proveedores de servicios societarios y fideicomisos",
        "Proveedores e intermediarios de Servicios de Activos Virtuales (PSAV)",
        "Provisión de comidas preparadas para empresas (incluye el servicio de suministro de comidas para banquetes, etc.)",
        "Provisión de programas de alfabetización para adultos",
        "Proyecto de gestión de las actividades relacionadas con la construcción",
        "Prueba de humedad y agua",
        "Prueba y pesaje de productos",
        "Pruebas de calificación y fiabilidad",
        "Pruebas de composición y de pureza de los minerales",
        "Pruebas de las características físicas y el rendimiento de los materiales tales como fuerza, espesor, durabilidad y radioactividad",
        "Pruebas de rendimiento de maquinaria completa: motores, automóviles y equipos electrónicos",
        "Pruebas en el ámbito de la higiene de los alimentos, incluidos los veterinarios",
        "Pruebas radiográficas de las soldaduras y las articulaciones, análisis de falla",
        "Publicidad aérea",
        "Publicidad directa por correo",
        "Purificación de agua para fines de abastecimiento de agua",
        "Radiodifusión de datos integrada",
        "Raspado, curtido, decoloración y teñido de pieles",
        "Reaseguros",
        "Reciclamiento de desperdicios y desechos no metálicos",
        "Recogida de desperdicios y desechos metálicos",
        "Recogida del agua de lluvia",
        "Recolección de bálsamos",
        "Recolección de bayas",
        "Recolección de bellotas",
        "Recolección de cartas y paquetes de correos públicos, de buzones o de las oficinas de correo",
        "Recolección de crustáceos y moluscos de agua dulce",
        "Recolección de frutos secos",
        "Recolección de lacas y resinas",
        "Recolección de musgos y líquenes",
        "Recolección de productos marinos (incluye la recolección de algas marinas y otras plantas acuáticas, corales, esponjas)",
        "Recolección de productos silvestres",
        "Recolección y distribución de ropas por lavanderías",
        "Recolección, reducción y eliminación de desperdicios",
        "Reconstrucción y recauchutado de neumáticos",
        "Recopilación y producción de leña",
        "Rectificación de motores",
        "Recuperación de datos ante desastres computacionales",
        "Reformatorios",
        "Refugios temporales para indigentes",
        "Refundición de lingotes de chatarra de hierro o acero",
        "Remodelación o renovación de estructuras residenciales existentes",
        "Renta y venta de videos",
        "Rentista de valores mobiliarios",
        "Reparación de amortiguadores, alineación de dirección y balanceo de ruedas",
        "Reparación de aparatos de video (VCR), lectores de CD",
        "Reparación de artículos deportivos",
        "Reparación de artículos eléctricos de uso domestico",
        "Reparación de artículos electrodomésticos",
        "Reparación de bicicletas",
        "Reparación de cámaras de video tipo domésticas",
        "Reparación de cámaras y cubiertas (incluye reparación de llantas)",
        "Reparación de computadoras hand-held (PDA)",
        "Reparación de cortadoras de césped, sopladores de hojas",
        "Reparación de disco magnético, flash drives y otros dispositivos de almacenaje",
        "Reparación de discos ópticos (CD-RW, CD-ROM, DVD-ROM, DVD-RW)",
        "Reparación de equipaje y similares",
        "Reparación de equipo de transmisión de comunicaciones",
        "Reparación de escáneres, incluyendo los escáneres de código de barras, lectores de tarjetas",
        "Reparación de impresoras, monitores, teclados, ratones, palancas de mando, y accesorios de TrackBall",
        "Reparación de instrumentos musicales y de artículos de pasatiempo, afinación de piano",
        "Reparación de libros",
        "Reparación de máquinas fax",
        "Reparación de módems internos y externos de la computadora",
        "Reparación de otros bienes personales y domésticos N. C. P.",
        "Reparación de otros enseres personales y domésticos",
        "Reparación de piezas de vehículos de motor",
        "Reparación de portadores de equipos MODEM",
        "Reparación de servidores de computadora",
        "Reparación de teléfonos inalámbricos y celulares",
        "Reparación de televisión, receptores de radio",
        "Reparación de terminales como cajeros automáticas y de puntos de venta, que funcionan en forma no mecánica",
        "Reparación de zapatos, botas, artículos de marroquería, etc.",
        "Reparación y modificación de joyas, relojes y sus partes",
        "Reparación y modificación de la ropa",
        "Reparación y pintura de carrocería; colocación de guardabarros y protecciones de exteriores",
        "Reparación y venta de artículos de refrigeración",
        "Reparaciones de sistemas de inyección electrónica",
        "Reparaciones eléctricas, del tablero e instrumental, reparación y recarga de baterías",
        "Replantación de árboles",
        "Repoblación de animales",
        "Repoblación y conservación de bosques nativos y zonas forestadas",
        "Representación de medios y venta de tiempo y espacio para varios medios solicitantes de publicidad",
        "Representantes de sucesiones y/o donaciones",
        "Reproducción de copias masters de software y datos",
        "Reproducción de DVD, cintas con imágenes en movimiento y otras grabaciones de video",
        "Reproducción de grabaciones",
        "Reproducción de películas de cine para la distribución teatral",
        "Reservas y fuerzas auxiliares de defensa",
        "Restaurantes de comida rápida",
        "Restaurantes y cantinas sin espectáculos",
        "Retapizado, reacabado, reparación y restauración de los muebles y mobiliarios caseros incluyendo los muebles de oficinas",
        "Revelado de rollos de películas",
        "Revelados de fotos en una hora",
        "Revestimiento y la impregnación de papel y cartón",
        "Saladero y peladero de cueros de ganado bovino",
        "Sastrerías y atelier",
        "Secado de madera",
        "Secado, vaporizado, reducción y reparación de productos textiles",
        "Segunda etapa de educación terciaria",
        "Seguro de responsabilidad civil y pérdida pecuniaria",
        "Seguro para transporte con motor, marina, la aviación",
        "Seguros de salud (incluye medicina prepagada)",
        "Seguros de viaje",
        "Seguros, reaseguros, fondos de pensiones, excepto los planes de seguridad social de afiliación obligatoria",
        "Sericultura (cría de gusanos de seda)",
        "Serigrafiado de prendas de vestir",
        "Servicio de alimento y bebida",
        "Servicio de atención ambulatoria (incluye las actividades de consultorios médicos de establecimiento sin internación)",
        "Servicio de atención domiciliaria (incluye las actividades llevadas a cabo en establecimiento que ofrecen atención por módulos a domicilio)",
        "Servicio de organización, dirección y gestión de prácticas deportivas y explotación de las instalaciones",
        "Servicio de taquimecanografía",
        "Servicio de transporte de pasajeros por líneas férreas interurbanas",
        "Servicio de transporte escolar (incluye el servicio de transporte para colonias de vacaciones y clubes)",
        "Servicios actuariales",
        "Servicios agrícolas N. C. P. (incluye planificación y diseño paisajista, plantación y mantenimiento de jardines, parques y cementerios)",
        "Servicios auxiliares a la administración de fondos jubilados y pensiones",
        "Servicios auxiliares a la intermediación financiera N. C. P. excepto a los servicios de seguros y de administración de fondos de jubilaciones",
        "Servicios auxiliares a los servicios de seguros N. C. P.",
        "Servicios auxiliares a los servicios de seguros y administradoras de fondos de jubilaciones y pensiones",
        "Servicios auxiliares para los servicios generales de la administración pública N. C. P. (incluye las actividades de servicios generales y de personal)",
        "Servicios bursátiles de mediación o por cuenta de terceros (incluye la actividad de agentes y sociedades de bolsas)",
        "Servicios complementarios de apoyo turístico",
        "Servicios complementarios para el transporte",
        "Servicios complementarios para el transporte aéreo N. C. P. (incluye servicio de prevención y extinción de incendios)",
        "Servicios complementarios para el transporte por agua N. C. P. (incluye explotación de servicios de terminales como puertos y muelles)",
        "Servicios complementarios para el transporte terrestre N. C. P.",
        "Servicios conexos a la producción de espectáculos teatrales y musicales (incluyendo diseño y manejo de escenografía, montaje de iluminación)",
        "Servicios de acompañantes, servicios de citas",
        "Servicios de administración de fondos de pensiones",
        "Servicios de agencias de noticias",
        "Servicios de agencias de viaje y otras actividades complementarias de apoyo turístico",
        "Servicios de agencias matrimoniales",
        "Servicios de agentes de mercado abierto puros (incluye las transacciones extrabursátiles por cuenta propia)",
        "Servicios de almacenamiento y depósito",
        "Servicios de alojamiento en camping (incluye refugios de montaña)",
        "Servicios de alojamiento en hoteles, campamentos y otros tipos de hospedaje temporal",
        "Servicios de alojamiento en hoteles, hosterías y residencias similares, excepto por hora que incluyen servicios de restaurantes",
        "Servicios de alojamiento en hoteles, hosterías y residencias similares, excepto por hora que no incluyen servicios de restaurantes",
        "Servicios de alojamiento en pensiones",
        "Servicios de alojamiento por hora, moteles y cabañas",
        "Servicios de alquiler y explotación de inmuebles para fiestas, convenciones y otros eventos similares",
        "Servicios de arquitectura e ingeniería y servicios conexos de asesoramiento técnico N. C. P.",
        "Servicios de asesoramiento, dirección y gestión empresarial N. C. P.",
        "Servicios de asesoramiento, dirección y gestión empresarial realizados por integrantes de los órganos de administración y/o fiscalización en sociedades anónimas",
        "Servicios de asesoramiento, dirección y gestión empresarial realizados por integrantes de órganos de dirección en sociedades excepto las sociedades anónimas",
        "Servicios de asociaciones de especialistas en disciplinas científicas, prácticas profesionales y esferas técnicas",
        "Servicios de asociaciones N. C. P.",
        "Servicios de asuntos exteriores",
        "Servicios de atención a mujeres con alojamiento",
        "Servicios de atención a personas minusválidas con alojamiento",
        "Servicios de autos blindados",
        "Servicios de bares y confiterías (incluye locales de expendio de bebida con servicio de mesa y/o mostrador)",
        "Servicios de bibliotecas y archivos",
        "Servicios de bibliotecas, archivos y museos y servicios culturales N. C. P.",
        "Servicios de bolsas de comercio",
        "Servicios de buses de larga distancias regulares",
        "Servicios de búsqueda de información a cambio de una retribución o por contrato",
        "Servicios de camarería",
        "Servicios de centros de planificación familiar que provee tratamiento medico",
        "Servicios de comunicación por medio de teléfono, beeper, telégrafo y telex",
        "Servicios de consultaría en informática y suministros de programas de informática",
        "Servicios de consultaría sobre gestión de bosques",
        "Servicios de consultores en equipo de informática",
        "Servicios de consultores en equipos de informática",
        "Servicios de consultores en informática y suministro de programas de informática",
        "Servicios de contabilidad y teneduría de libros, auditoría y asesoría fiscal",
        "Servicios de contratistas de mano de obra agrícola (incluye la poda de árboles, trasplante, fumigación y desinfección manual, etc.)",
        "Servicios de contratistas de mano de obra pecuaria (incluye el arreo, castración de ovejas, recolección de estiércol, etc.)",
        "Servicios de correo",
        "Servicios de cosecha mecánica (incluye la cosecha mecánica de granos, caña de azúcar, algodón, forrajes, el enfardado, enrollado, etc.)",
        "Servicios de crédito N. C. P. (incluye el otorgamiento de préstamos por entidades que no reciben depósitos)",
        "Servicios de cuidado y entrenamiento de animales domésticos",
        "Servicios de defensa",
        "Servicios de depuración de aguas residuales, alcantarillado y cloacas",
        "Servicios de detección de huellas dactilares",
        "Servicios de diagnósticos (incluye las actividades de laboratorios de análisis clínicos y patológicos)",
        "Servicios de direccionamiento de código de barras",
        "Servicios de drenaje y bombeo por comisión o por contrato",
        "Servicios de drenaje y bombeo por contrato o por comisión",
        "Servicios de elaboración de placas",
        "Servicios de elaboración de trofeos",
        "Servicios de electromecánica",
        "Servicios de emergencias y traslados",
        "Servicios de entidades de tarjetas de compra y/o crédito",
        "Servicios de entregas a domicilio",
        "Servicios de envases y empaque",
        "Servicios de espectáculos artísticos y de diversión N. C. P. (incluye parques de diversión, centros similares)",
        "Servicios de expendio de comida y bebida en establecimientos con mesa y/o mostrador",
        "Servicios de exploración",
        "Servicios de explotación de infraestructura para el transporte por agua",
        "Servicios de explotación de infraestructuras para el transporte terrestre, peaje y otros derechos",
        "Servicios de federaciones, asociaciones, cámaras, gremios y organizaciones similares",
        "Servicios de financiación y actividades financieras N. C. P. incluye actividades de inversión en acciones, títulos, fondos comunes)",
        "Servicios de fotografía",
        "Servicios de gestión y logística para el transporte de mercancías",
        "Servicios de gravado en cilindros y placas",
        "Servicios de guardaespaldas",
        "Servicios de guardería náuticas",
        "Servicios de guardias de seguridad",
        "Servicios de hangares, estacionamiento y remolque de aeronaves",
        "Servicios de hospedajes temporales N. C. P. (incluye hospedaje en estancias, residencias para estudiantes y albergues juveniles)",
        "Servicios de hospital de día (incluye las actividades de tratamiento que no necesitan hospitalización a tiempo completo)",
        "Servicios de impresión de códigos de barras",
        "Servicios de impresión heliográfica, fotocopia y otras formas de reproducciones",
        "Servicios de información por teléfono",
        "Servicios de ingeniería civil",
        "Servicios de intermediación financiera realizada por caja de crédito",
        "Servicios de intermediación financiera realizada por las compañías financieras",
        "Servicios de internación",
        "Servicios de investigación N. C. P.",
        "Servicios de jardines botánicos, zoológicos y de parques nacionales",
        "Servicios de justicia",
        "Servicios de la administración publica",
        "Servicios de la banca central 9incluye las actividades del banco central)",
        "Servicios de la banca de inversión",
        "Servicios de la banca mayorista",
        "Servicios de la banca minorista o comerciales",
        "Servicios de la seguridad social obligatoria",
        "Servicios de labranza, siembra, trasplante y cuidados culturales",
        "Servicios de limpieza, conservación, mantenimiento y pintura de edificios",
        "Servicios de los hogares privados que contratan servicios domésticos",
        "Servicios de manejadoras y/o representantes de las aerolíneas",
        "Servicios de manipulación de carga",
        "Servicios de manipulación de carga (incluye los servicios de carga y descarga de mercancías o equipajes de pasajeros)",
        "Servicios de maquinaria agrícola N. C. P. excepto los de cosecha mecánica (incluye enfardado, enrollado, envasado silo pack, etc.)",
        "Servicios de mensajería (courrier)",
        "Servicios de mercados a término",
        "Servicios de mercados y cajas de valores",
        "Servicios de museos y preservación de lugares de edificios históricos",
        "Servicios de organización de colectas de fondos a cambio de una retribución o contrato",
        "Servicios de organización de eventos",
        "Servicios de organizaciones empresariales, profesionales y de empleados",
        "Servicios de organizaciones religiosas",
        "Servicios de organizaciones y órganos extraterritoriales",
        "Servicios de peluquería",
        "Servicios de perforación direccional y redireccionado, reparación y desmantelamiento, cementación de petróleo y gas, bombeo de los pozos, taponamiento y abandono de los pozos",
        "Servicios de personal médico y paramédico",
        "Servicios de pizzería, Fast food y locales de venta de comidas y bebidas al paso",
        "Servicios de plomería y afines",
        "Servicios de polígrafos",
        "Servicios de preselección de correos",
        "Servicios de productores y asesores de seguros",
        "Servicios de protección civil",
        "Servicios de prueba de perforación en relación a la extracción de petróleo o gas",
        "Servicios de publicidad",
        "Servicios de pulverización, desinfección y fumigación de áreas y terrenos",
        "Servicios de radio (no incluye la transmisión)",
        "Servicios de recibos de artículos valiosos con el personal y equipo para proteger tales propiedades",
        "Servicios de recolección de monedas en los parquímetros",
        "Servicios de recortes de noticias y prensa",
        "Servicios de rehabilitación, realizada por unidades del gobierno o por unidades privadas a cambio de una retribución o por contrato",
        "Servicios de restaurantes y cantinas con espectáculos",
        "Servicios de restaurantes y cantinas sin espectáculos",
        "Servicios de salines de baile, discotecas y similares",
        "Servicios de salones de juegos (incluye salones de billar, pool, Bowling)",
        "Servicios de saneamiento público N. C. P.",
        "Servicios de seguridad N. C. P.",
        "Servicios de seguro",
        "Servicios de seguros a las personas excepto los de salud y de vida (incluye los seguros de accidente)",
        "Servicios de seguros de vida (incluye los seguros de vida, retiro y sepelio)",
        "Servicios de seguros patrimoniales",
        "Servicios de sindicatos",
        "Servicios de sociedades calificadoras de riesgo",
        "Servicios de suministro de comidas para contratistas",
        "Servicios de suministros de pañales",
        "Servicios de tasación de propiedades inmobiliaria",
        "Servicios de telecomunicaciones",
        "Servicios de telemarketing y/o profesional mercadeo",
        "Servicios de transferencia de dinero o valores; Intermediarios cambiarios aprobados e inscriptos en la SIB; Cooperativas de ahorro y crédito",
        "Servicios de transmisión de radio y televisión",
        "Servicios de transmisión N. C. P. de sonido, imágenes, datos u otra información",
        "Servicios de transporte automotor",
        "Servicios de transporte automotor de oferta libre de pasajero excepto mediante taxis, alquiler de autos con chofer y transporte escolar",
        "Servicios de transporte automotor de pasajeros mediante taxis y remises; alquiler de autos con chofer",
        "Servicios de transporte automotor de pasajeros para el turismo",
        "Servicios de transporte automotor interurbano de pasajeros (incluye los servicios de transporte regular de más de 50km)",
        "Servicios de transporte de caudales y objetos de valor",
        "Servicios de transporte ferroviario urbano y suburbano de pasajeros (incluye el servicio de subterráneo)",
        "Servicios de tratamiento (incluye las actividades de centros de cobaltoterapia, de radiología convencional, de acelerador lineal)",
        "Servicios de tratamiento de belleza, excepto los de peluquería",
        "Servicios de tutoría académica",
        "Servicios directos al sistema minero",
        "Servicios empresariales N. C. P.",
        "Servicios forestales de extracción de madera (incluye tala de árboles, acarreo y transporte en el interior del bosque, servicios realizados de terceros)",
        "Servicios forestales excepto los relacionados con la extracción de madera (incluye la protección contra incendios, evaluación de masas forestales)",
        "Servicios generales de la administración pública (incluye el desempeño de funciones ejecutivas y legislativas de administración por parte de las entidades públicas)",
        "Servicios geológicos y de prospección",
        "Servicios hospitalarios N. C. P.",
        "Servicios inmobiliarios realizados a cambio de una retribución o por contrata (incluye compra, venta, alquiler, reate, tasación)",
        "Servicios inmobiliarios realizados a cuenta propia, con bienes propios o arrendados",
        "Servicios inmobiliarios realizados por cuenta propia, con bienes propios o arrendados N. C. P.",
        "Servicios jurídicos",
        "Servicios jurídicos y de contabilidad, teneduría de libro y auditoria; asesoramiento en materia de impuestos",
        "Servicios mayoristas de agencias de viaje",
        "Servicios minoristas de agencias de viaje",
        "Servicios N. C. P.",
        "Servicios náuticos (escuelas de buceo y alquiler de equipos náuticos)",
        "Servicios notariales",
        "Servicios odontológicos",
        "Servicios para el control de plagas, baños parasiticidas, etc.",
        "Servicios para el mantenimiento físico-corporal (incluye baños turcos, saunas, solarios, centros de masajes y adelgazamiento, etc.)",
        "Servicios para el orden público y la seguridad",
        "Servicios para la aeronavegación",
        "Servicios para la caza",
        "Servicios para la navegación (incluye servicio de practicaje y pilotaje, atraque)",
        "Servicios para la pesca",
        "Servicios para la práctica deportiva y de entretenimiento N. C. P.",
        "Servicios para la regulación de la actividad económica (incluye la administración pública y la regulación de varios sectores económicos)",
        "Servicios para la regulación de las actividades sanitarias, educación y servicios sociales",
        "Servicios pecuarios N. C. P. excepto los veterinarios",
        "Servicios personales en general",
        "Servicios prestados por parqueos de estacionamiento y garajes",
        "Servicios prestados por profesionales y técnicos, para la realización de prácticas deportivas (incluye las realizadas por deportistas)",
        "Servicios relacionados con bases de datos",
        "Servicios relacionados con la electrónica y las comunicaciones",
        "Servicios relacionados con la impresión",
        "Servicios relacionados con la prevención de incendios",
        "Servicios relacionados con la salud humana",
        "Servicios relacionados con la salud humana N. C. P.",
        "Servicios sociales con alojamiento",
        "Servicios sociales con alojamiento N. C. P.",
        "Servicios veterinarios",
        "Silvicultura y extracción de madera",
        "Sociedades de inversión",
        "Subastas de coches",
        "Subtitulación en tiempo real de televisión, de reuniones y conferencias",
        "Suite y apartahoteles",
        "Suministro de acceso a Internet por el operador de la infraestructura del cableado",
        "Suministro de comidas basado en acuerdos contractuales",
        "Suministro de comidas para banquetes, bodas, fiestas y otras celebraciones",
        "Suministro de electricidad, gas, vapor y aire acondicionado",
        "Suministro de vapor y agua caliente",
        "Supervisión de las operaciones bancarias",
        "Suplidores de equipos y materiales para hoteles y restaurantes",
        "Swaps, opciones y otros acuerdos de coberturas",
        "Tapizado y retapizado",
        "Técnicos de suelo",
        "Tenedoras de las reservas internacionales de un país",
        "Terminación de edificios",
        "Terminación de obras de ingeniería civil N. C. P. (incluye trabajos de ornamentación, limpieza de exterior de edificios)",
        "Terminación y revestimiento de paredes y pisos (incluye la instalación de yesería, salpicré, pulido de pisos y colocación de revestimiento de cerámica)",
        "Terminación y teñido de cueros",
        "Tierras",
        "Toma de depósitos que son utilizados para compensaciones entre instituciones financieras",
        "Torrefacción de café",
        "Tostado, torrado, y molienda de café",
        "Trabajo en mica y articulo de mica, de turba, grafito (que no sean artículos eléctricos)",
        "Trabajos sobre estructuras altas",
        "Traductores",
        "Transferencia de películas/cintas",
        "Transformación de aceite vegetal mediante deshidratación o hidrogenación",
        "Transformación de aceite vegetal mediante ebullición",
        "Transformación de aceite vegetal mediante soplado",
        "Transmisión de programaciones radiales",
        "Transporte a granel, incluido los transportes en camiones cisterna",
        "Transporte automotor de pasajeros N. C. P.",
        "Transporte automotor urbano regular de pasajeros",
        "Transporte de agregados para la construcción (arena, grava, entre otros)",
        "Transporte de agua",
        "Transporte de animales vivos",
        "Transporte de carga pesada N. C. P. (incluye servicio de carga de refrigerada, automotores, transporte)",
        "Transporte de carga por líneas férreas de corto recorrido",
        "Transporte de carga por líneas férreas principales",
        "Transporte de carga por vía aérea",
        "Transporte de energía eléctrica",
        "Transporte de gases a través de gaseoductos",
        "Transporte de líquidos",
        "Transporte de mercadería y materiales pesados",
        "Transporte de mercancías por vía aérea en rutas regulares y en horarios regulares",
        "Transporte de otros productos básicos a través de gaseoductos",
        "Transporte de pasajeros por vía aérea",
        "Transporte de residuos y materiales de desechos, sin recogida o eliminación",
        "Transporte de troncos",
        "Transporte de troncos dentro del bosque",
        "Transporte de valores",
        "Transporte espacial",
        "Transporte fluvial de carga",
        "Transporte fluvial de pasajeros",
        "Transporte marítimo de pasajeros",
        "Transporte marítimo y cabotaje",
        "Transporte marítimo y de cabotaje de carga",
        "Transporte no regulares de carga por vía aérea",
        "Transporte por oleoductos y poliductos",
        "Transporte por remolque o tracción de barcazas, plataformas petroleras, etc.",
        "Transporte por tuberías",
        "Transporte por vía acuática",
        "Transporte por vía férrea",
        "Transporte por vía terrestre y transporte por tuberías",
        "Transporte refrigerado de productos",
        "Transporte terrestre de pasajeros por autobús",
        "Transporte terrestre de pasajeros por metro y ferrocarriles elevados",
        "Transporte terrestre de pasajeros por tranvía",
        "Transporte urbano de carga N. C. P. (incluye el transporte realizado por fleteros y distribuidores dentro del Egido urbano)",
        "Transporte y venta al por menor de combustible",
        "Trasplante de arroz",
        "Trasporte de carga por vías de navegación interiores",
        "Tratamiento antióxido",
        "Tratamiento de agua industrial y para otros fines",
        "Tratamiento de cultivos",
        "Tratamiento y revestimiento de metales; obras de ingeniería mecánica realizadas a cambio de una retribución o por contrato",
        "Trituración de piedra y grava",
        "Trituración, perforación y refinado de sal",
        "Unidades (establecimientos) con cama y desayuno",
        "Uniones y/o corporaciones de crédito",
        "Urea, fosfatos naturales en bruto y crudo, potasio de sales naturales",
        "Venta al por mayor a cambio de una retribución o contrata",
        "Venta al por mayor d tubo para neumáticos y cubiertas",
        "Venta al por mayor de aberturas (ventanas, puertas, cortinas enrollables)",
        "Venta al por mayor de aceites esenciales",
        "Venta al por mayor de aceites lubricantes y grasas, excepto para automotores, leña y carbón",
        "Venta al por mayor de ácidos y azufres",
        "Venta al por mayor de alimentos N. C. P. (incluye la venta de miel y derivados, productos congelados, etc.)",
        "Venta al por mayor de alimentos para animales",
        "Venta al por mayor de almidón, fécula",
        "Venta al por mayor de anilina, parafina",
        "Venta al por mayor de animales vivos",
        "Venta al por mayor de aparatos de iluminación",
        "Venta al por mayor de aparatos fotográficos e instrumentos de óptica (incluye la venta de lentes de contacto, líquidos oftalmológicos, armazones, etc.)",
        "Venta al por mayor de arados, esparcidor de estiércol y sembradoras",
        "Venta al por mayor de aromas y perfumes",
        "Venta al por mayor de artefactos eléctricos, gas, kerosene u otros combustibles para el hogar",
        "Venta al por mayor de artículos de bazar, menaje excepto de vidrio",
        "Venta al por mayor de artículos de cuero y accesorios de viaje",
        "Venta al por mayor de artículos de ferretería",
        "Venta al por mayor de artículos de librería y papelería",
        "Venta al por mayor de artículos de loza, porcelana y cerámica de uso en la construcción",
        "Venta al por mayor de artículos de marroquería, paraguas y productos similares N. C. P.",
        "Venta al por mayor de artículos de mercería (incluye la venta de puntillas, galones, hombreras, agujas, botones, etc.)",
        "Venta al por mayor de artículos de mimbre y corcho; colchones y Simones",
        "Venta al por mayor de artículos de plásticos",
        "Venta al por mayor de artículos de plomería, electricidad, calefacción, obras sanitarias, etc.",
        "Venta al por mayor de artículos de uso domestico y/o personal N. C. P.",
        "Venta al por mayor de artículos de vidrio",
        "Venta al por mayor de artículos deportivos (incluye embarcaciones deportivas, armas y municiones, equipo de pesca)",
        "Venta al por mayor de artículos regionales",
        "Venta al por mayor de aves, huevos y productos de granjas y de la caza N. C. P.",
        "Venta al por mayor de azúcar",
        "Venta al por mayor de baños, lavado, inodoros y otros sanitarios de porcelana",
        "Venta al por mayor de bebidas alcohólicas N. C. P. (incluye la venta de aperitivos con alcohol, cerveza, sidra, etc.)",
        "Venta al por mayor de bebidas espiritosas",
        "Venta al por mayor de bebidas no alcohólicas (incluye la venta de aguas, sodas, bebidas refrescantes, jarabes, extractos, etc.)",
        "Venta al por mayor de bicicletas, sus partes y accesorios",
        "Venta al por mayor de café, té, yerba mate y otras infusiones ",
        "Venta al por mayor de calentadores de agua",
        "Venta al por mayor de calzados de todo tipo excepto los ortopédicos (incluye venta de calzado de cuero, tela, plástico, etc.)",
        "Venta al por mayor de carbón de leña, carbón de coque, combustible nafta",
        "Venta al por mayor de carnes y productos derivados excepto las de aves",
        "Venta al por mayor de caucho y productos de caucho excepto de calzado y autopartes",
        "Venta al por mayor de cereales (arroz), oleaginosas y forrajeras excepto semillas",
        "Venta al por mayor de cerraduras",
        "Venta al por mayor de chocolates, golosinas y productos de confitería para quioscos",
        "Venta al por mayor de cigarrillos y productos de tabaco",
        "Venta al por mayor de colas químicas, resinas sintéticas",
        "Venta al por mayor de combustibles sólidos, líquidos, gaseosos y de productos conexos",
        "Venta al por mayor de combustibles y lubricantes para automotores",
        "Venta al por mayor de condimentos y especias",
        "Venta al por mayor de corbatas, tirantes y guantes",
        "Venta al por mayor de desperdicios y desechos de papel y cartón",
        "Venta al por mayor de desperdicios y desechos textiles",
        "Venta al por mayor de diarios y revistas",
        "Venta al por mayor de envases de papel y cartón",
        "Venta al por mayor de equipo profesional y científico",
        "Venta al por mayor de equipos controlados por maquinaria para la industria textil",
        "Venta al por mayor de equipos de sonido, radio y televisión, comunicaciones y sus componentes",
        "Venta al por mayor de equipos e instrumentos de medida y control",
        "Venta al por mayor de equipos informáticos y maquinas electrónicas de escribir y calcular",
        "Venta al por mayor de equipos y materiales eléctricos",
        "Venta al por mayor de fertilizantes y productos agroquímicos",
        "Venta al por mayor de fiambres y quesos",
        "Venta al por mayor de fibras textiles",
        "Venta al por mayor de frutas, legumbres y cereales secos en conserva",
        "Venta al por mayor de gases industriales, metanol",
        "Venta al por mayor de gases licuados de petróleo, butano y gas propano",
        "Venta al por mayor de herrajes y accesorios",
        "Venta al por mayor de instrumental médico, odontológico y artículos ortopédicos (incluye venta de vaporizadores, nebulizadores, etc.)",
        "Venta al por mayor de instrumentos musicales, discos y casetes de audio y video",
        "Venta al por mayor de juegos y juguetes",
        "Venta al por mayor de ladrillos, cemento, cal, arena, piedra, mármol y materiales para la construcción N. C. P.",
        "Venta al por mayor de lanas, cueros en bruto y productos afines",
        "Venta al por mayor de leche",
        "Venta al por mayor de libros y publicaciones",
        "Venta al por mayor de madera en bruto",
        "Venta al por mayor de mantelería y artículos textiles para el hogar",
        "Venta al por mayor de maquinaria, equipo y materiales conexos",
        "Venta al por mayor de maquinas avícolas y apicultoras",
        "Venta al por mayor de maquinas de ordeño",
        "Venta al por mayor de maquinas y equipos de comunicación, control y seguridad (incluye la venta de teléfonos y equipos de comunicación)",
        "Venta al por mayor de maquinas y equipos de oficina",
        "Venta al por mayor de maquinas, equipos e implementos de uso en imprentas, artes graficas y actividades conexas",
        "Venta al por mayor de maquinas, equipos e implementos de uso en la construcción",
        "Venta al por mayor de maquinas, equipos e implementos de uso en la elaboración de alimentos, bebidas y tabacos",
        "Venta al por mayor de maquinas, equipos e implementos de uso en la industria de caucho y plástico",
        "Venta al por mayor de maquinas, equipos e implementos de uso en los sectores agropecuario, jardinería, silvicultura, pesca y caza",
        "Venta al por mayor de maquinas, equipos e implementos de uso especial N.C.P.",
        "Venta al por mayor de maquinas, equipos e implementos de uso médico y paramédico",
        "Venta al por mayor de maquinas, equipos y materiales conexos",
        "Venta al por mayor de maquinas-herramientas de uso general",
        "Venta al por mayor de mariscos",
        "Venta al por mayor de materia prima agrícola y de silvicultura N. C. P. (incluye el acopio y venta al por mayor de frutas, flores, etc.)",
        "Venta al por mayor de materiales de construcción, artículos de ferretería y equipos y materiales de fontanería",
        "Venta al por mayor de materiales y productos para la limpieza",
        "Venta al por mayor de materias primas agropecuarias y animales vivos",
        "Venta al por mayor de medias y prendas de puntos",
        "Venta al por mayor de mercancías N. C. P.",
        "Venta al por mayor de metales ferrosos y no ferrosos en formas primaria",
        "Venta al por mayor de metales ferrosos y no ferrosos semi-acabado de productos metálicos N. C. P.",
        "Venta al por mayor de metales y minerales metalíferos",
        "Venta al por mayor de muebles metálicos excepto de oficina",
        "Venta al por mayor de muebles N. C. P. excepto de oficinas, artículos de mimbre y corcho",
        "Venta al por mayor de oro y otros metales preciosos",
        "Venta al por mayor de otros enseres domésticos",
        "Venta al por mayor de otros productos",
        "Venta al por mayor de otros productos intermedios desperdicios y desechos",
        "Venta al por mayor de pan, productos de confitería y pastas frescas",
        "Venta al por mayor de papel a granel",
        "Venta al por mayor de papel y productos de papel y cartón excepto envases",
        "Venta al por mayor de papeles para pared, revestimiento de suelo",
        "Venta al por mayor de partes y piezas y accesorios de vehículos automotores",
        "Venta al por mayor de pescado",
        "Venta al por mayor de pieles, cueros curtidos y salados",
        "Venta al por mayor de pinturas y productos conexos",
        "Venta al por mayor de prendas de vestir de cuero",
        "Venta al por mayor de prendas de vestir N. C. P.",
        "Venta al por mayor de prendas de vestir y ropa de cama",
        "Venta al por mayor de productos cosméticos, de artículos de tocador y de perfumería",
        "Venta al por mayor de productos de primera transformación de la madera",
        "Venta al por mayor de productos del petróleo refinado",
        "Venta al por mayor de productos en almacenes y supermercados mayoristas con predominio de alimentos y bebidas",
        "Venta al por mayor de productos farmacéuticos (incluye venta de medicamentos y kits de diagnósticos)",
        "Venta al por mayor de productos intermedios de papel N. C. P.",
        "Venta al por mayor de productos intermedios N. C. P. desperdicios y desechos metálicos (incluye chatarra, viruta de metales diversos)",
        "Venta al por mayor de productos intermedios N. C. P. desperdicios y desechos textiles",
        "Venta al por mayor de productos intermedios, desperdicios y desechos N. C. P.",
        "Venta al por mayor de productos lácteos",
        "Venta al por mayor de productos textiles, excepto telas, tejidos, prendas y accesorios de vestir",
        "Venta al por mayor de productos veterinarios",
        "Venta al por mayor de productos y subproductos de molinería",
        "Venta al por mayor de relojes y joyas",
        "Venta al por mayor de ropa",
        "Venta al por mayor de ropa para deportes",
        "Venta al por mayor de semillas",
        "Venta al por mayor de soda, sal industrial",
        "Venta al por mayor de software",
        "Venta al por mayor de suelas y afines (incluye talabartería, artículos regionales de cuero, almacenes de suela, etc.)",
        "Venta al por mayor de tabaco sin manufacturar",
        "Venta al por mayor de tapices y alfombras de materiales textiles",
        "Venta al por mayor de tejidos (telas)",
        "Venta al por mayor de textiles, prendas de vestir y calzado",
        "Venta al por mayor de tinta de imprenta y colorante",
        "Venta al por mayor de tractores utilizados en la agricultura y silvicultura",
        "Venta al por mayor de trilladoras",
        "Venta al por mayor de vehículos, equipos y maquinas para el transporte ferroviario, aéreo y de navegación",
        "Venta al por mayor de vidrio plano y templado",
        "Venta al por mayor de vino",
        "Venta al por mayor e instalación de muebles de oficinas",
        "Venta al por mayor e instalación de muebles para industrias, comercios",
        "Venta al por mayor en comisión o consignación de alimentos, bebidas y tabaco N. C. P.",
        "Venta al por mayor en comisión o consignación de cereales (incluye arroz), oleaginosas y forrajeras",
        "Venta al por mayor en comisión o consignación de energía eléctrica, gas y combustibles",
        "Venta al por mayor en comisión o consignación de madera y materiales de construcción",
        "Venta al por mayor en comisión o consignación de maquinaria, equipo profesional industrial y comercial, embarcaciones y aeronaves",
        "Venta al por mayor en comisión o consignación de materiales N. C. P.",
        "Venta al por mayor en comisión o consignación de minerales, metales y productos químicos industriales",
        "Venta al por mayor en comisión o consignación de papel, cartón, libros, revistas, diarios y materiales de embalaje",
        "Venta al por mayor en comisión o consignación de productos agrícolas N. C. P.",
        "Venta al por mayor en comisión o consignación de productos textiles, prendas de vestir, calzados, paraguas",
        "Venta al por mayor en comisión o consignación de semillas",
        "Venta al por mayor o al por menor por comisión de agentes",
        "Venta al por mayor y al por menor de partes y piezas de motocicletas",
        "Venta al por mayor y empaque de frutas, legumbres y hortalizas frescas (incluye la conservación en cámaras frigoríficas)",
        "Venta al por menor a través de maquinas expendedoras",
        "Venta al por menor a través de pedio por correo o vía Internet",
        "Venta al por menor de agujas, hilo de cocer, etc. (mercerías)",
        "Venta al por menor de alfombras y tapetes",
        "Venta al por menor de alimenticios, bebidas o tabaco en almacenes especializados",
        "Venta al por menor de alimentos para animales de compañía",
        "Venta al por menor de alimentos, bebidas y productos de tabaco en puestos de ventas ambulantes",
        "Venta al por menor de antigüedades",
        "Venta al por menor de aparatos para el hogar y equipo N. C. P.",
        "Venta al por menor de armas y artículos de cuchillería, artículos para la caza y pesca",
        "Venta al por menor de armas y municiones",
        "Venta al por menor de artefactos para el hogar electrónicos, gas, a kerosene u otros combustibles",
        "Venta al por menor de artefactos y juguetes",
        "Venta al por menor de artículos de caucho excepto tubos para neumáticos y cubiertas",
        "Venta al por menor de artículos de colección y objetos de arte",
        "Venta al por menor de artículos de cuero",
        "Venta al por menor de artículos de deporte",
        "Venta al por menor de artículos de fantasía",
        "Venta al por menor de artículos de ferretería",
        "Venta al por menor de artículos de ferretería, pinturas y productos de vidrio",
        "Venta al por menor de artículos de iluminación",
        "Venta al por menor de artículos de limpieza",
        "Venta al por menor de artículos de plomería e instalación de gas",
        "Venta al por menor de artículos deportivos",
        "Venta al por menor de artículos nuevos N. C. P. (incluye casas de regalos, de artesanías y artículos regionales)",
        "Venta al por menor de artículos para acampar",
        "Venta al por menor de artículos para el hogar y equipo de uso domestico N.C.P.",
        "Venta al por menor de artículos para la pesca deportiva",
        "Venta al por menor de artículos regionales y de talabartería",
        "Venta al por menor de artículos relacionados con la salud",
        "Venta al por menor de artículos religiosos",
        "Venta al por menor de artículos textiles N. C. P. excepto prendas de vestir",
        "Venta al por menor de audífonos",
        "Venta al por menor de baldosas",
        "Venta al por menor de baterías",
        "Venta al por menor de bebidas",
        "Venta al por menor de bebidas alcohólicas (licor store)",
        "Venta al por menor de bebidas no alcohólicas",
        "Venta al por menor de bebidas y productos del tabaco",
        "Venta al por menor de bicicletas",
        "Venta al por menor de bombones, golosinas y demás artículos de confitería",
        "Venta al por menor de botes",
        "Venta al por menor de café y te",
        "Venta al por menor de calzados excepto los ortopédicos",
        "Venta al por menor de carne y productos cárnicos",
        "Venta al por menor de carteras",
        "Venta al por menor de cigarros y cigarrillos",
        "Venta al por menor de colchones y Simones",
        "Venta al por menor de combustible para uso domestico, gas embasado, carbón y leña",
        "Venta al por menor de combustible para vehículos automotores y motocicletas (incluye estaciones de servicios)",
        "Venta al por menor de combustibles para vehículos en almacenes especializados",
        "Venta al por menor de computadoras",
        "Venta al por menor de confecciones para el hogar (incluye la venta al por menor de cortinas, sabanas y toallas)",
        "Venta al por menor de consolas de videojuegos",
        "Venta al por menor de cortadoras de césped",
        "Venta al por menor de cosméticos",
        "Venta al por menor de cristales, espejos, mamparas y cerramientos",
        "Venta al por menor de cualquier tipo de producto a través de Internet",
        "Venta al por menor de cualquier tipo de producto por correo",
        "Venta al por menor de electrodomésticos",
        "Venta al por menor de electrodomésticos y electrónica de consumo",
        "Venta al por menor de embutidos y fiambre",
        "Venta al por menor de equipos de telecomunicaciones",
        "Venta al por menor de equipos profesionales y científicos e instrumentos de medidas de control",
        "Venta al por menor de equipos sanitarios",
        "Venta al por menor de equipos y materiales del tipo hágalo usted mismo",
        "Venta al por menor de equipos y materiales eléctricos",
        "Venta al por menor de flores y plantas",
        "Venta al por menor de frutas y hortalizas frescas o en conserva",
        "Venta al por menor de grava, cascajo en estado natural",
        "Venta al por menor de hilados de tejidos de punto",
        "Venta al por menor de huevo, carne de aves y productos de granja y de la caza N. C. P.",
        "Venta al por menor de indumentaria para bebes y niños",
        "Venta al por menor de indumentaria para trabajo, uniformes y guardapolvos",
        "Venta al por menor de instrumentos musicales, equipos de sonido, casetes de audio y video",
        "Venta al por menor de joyas",
        "Venta al por menor de juegos y juguetes",
        "Venta al por menor de juegos y juguetes hechos de cualquier material",
        "Venta al por menor de ladrillos",
        "Venta al por menor de lana",
        "Venta al por menor de lápices, bolígrafos, papel, etc.",
        "Venta al por menor de libros",
        "Venta al por menor de libros usados",
        "Venta al por menor de madera",
        "Venta al por menor de madera, artículos de madera y corcho excepto muebles",
        "Venta al por menor de maquinas y equipos para oficinas y sus componentes y repuestos",
        "Venta al por menor de maquinas y motores y sus repuestos",
        "Venta al por menor de marroquerías, paraguas y similares",
        "Venta al por menor de material fotográfico, óptico y de precisión",
        "Venta al por menor de materiales básicos para hacer alfombras, tapices o bordados",
        "Venta al por menor de materiales de construcción N. C. P.",
        "Venta al por menor de mercancías de segunda mano",
        "Venta al por menor de metales y similares",
        "Venta al por menor de mobiliario domestico",
        "Venta al por menor de muebles",
        "Venta al por menor de muebles usados",
        "Venta al por menor de música y grabaciones de video",
        "Venta al por menor de otros bienes de segunda mano N. C. P.",
        "Venta al por menor de pan y de productos de panadería",
        "Venta al por menor de papel pintado y revestimiento de piso y artículos similares para la decoración",
        "Venta al por menor de papel, cartón y materiales de embalaje y artículos de librería",
        "Venta al por menor de partes, piezas y accesorios excepto neumáticos, cubiertas, parabrisas",
        "Venta al por menor de perfumes y cosméticos",
        "Venta al por menor de periódicos y artículos de papelería",
        "Venta al por menor de pescados, mariscos y otros productos",
        "Venta al por menor de pintura, barnices y lacas",
        "Venta al por menor de pinturas",
        "Venta al por menor de prendas de vestir",
        "Venta al por menor de prendas y accesorios de vestir N. C. P. excepto calzados, artículos de marroquería, paraguas, etc.",
        "Venta al por menor de productos alimenticios N. C. P. en comercios especializados",
        "Venta al por menor de productos de almacén y dietética",
        "Venta al por menor de productos de herbostería",
        "Venta al por menor de productos farmacéuticos",
        "Venta al por menor de productos farmacéuticos, medicinales, cosméticos y artículos de tocador en almacenes especializados",
        "Venta al por menor de productos lácteos",
        "Venta al por menor de productos lubricantes y productos de refrigeración para vehículos de motor",
        "Venta al por menor de productos médicos y ortopédicos",
        "Venta al por menor de productos nuevos en almacenes especializados",
        "Venta al por menor de productos textiles",
        "Venta al por menor de productos textiles, prendas de vestir, calzados y artículos de cuero en almacenes especializados",
        "Venta al por menor de productos veterinario y animales domésticos",
        "Venta al por menor de puertas, ventanas, cortinas enrollables y puertas corredizas",
        "Venta al por menor de relojes",
        "Venta al por menor de rodados excepto bicicletas",
        "Venta al por menor de ropa interior, medias, prendas para dormir y prendas para la playa",
        "Venta al por menor de saunas",
        "Venta al por menor de sellos y monedas",
        "Venta al por menor de semillas y fertilizantes",
        "Venta al por menor de software no personalizados y juegos de video",
        "Venta al por menor de tabaco",
        "Venta al por menor de tejidos",
        "Venta al por menor de telas",
        "Venta al por menor de todo tipo de libros",
        "Venta al por menor en almacenes con predominio de productos alimenticios y bebidas",
        "Venta al por menor en almacenes no especializados",
        "Venta al por menor en almacenes no especializados, con surtido compuesto principalmente de alimentos, bebidas y tabaco",
        "Venta al por menor en hipermercados con predominio en productos alimentarios y bebidas, grandes tiendas, plazas",
        "Venta al por menor en kioscos, poli rubros y comercios no especializados N.C.P.",
        "Venta al por menor en puestos móviles",
        "Venta al por menor en subastas por Internet",
        "Venta al por menor en supermercados con predominio de productos alimenticios y bebidas",
        "Venta al por menor excepto la especializada, sin predominio de productos alimenticios y bebidas",
        "Venta al por menor no realizada en establecimientos N. C. P. (incluye venta mediante maquinas expendedoras, vendedores ambulantes)",
        "Venta al por menor por comisionistas",
        "Venta al por menor por correo, televisión, Internet y otros medios de comunicación",
        "Venta de animales y aves vivas",
        "Venta de autos, camionetas y utilitarios, nuevos (incluye taxis, jeeps, 4 X 4 y vehículos similares)",
        "Venta de autos, camionetas y utilitarios, usados (incluye taxis, jeeps, 4 X 4 y vehículos similares)",
        "Venta de combustible, minerales, metales y productos químicos industriales incluyendo los fertilizantes",
        "Venta de combustibles entregado directamente a los clientes",
        "Venta de consolas de videojuego",
        "Venta de electricidad para el usuario",
        "Venta de equipos estéreos",
        "Venta de equipos y aparatos de transporte aéreo y marítimo",
        "Venta de gas a los usuarios",
        "Venta de hielo",
        "Venta de llantas de goma y tubos",
        "Venta de maquinaria incluyendo de oficina y computadoras, equipos industriales, barcos y aviones",
        "Venta de materias primas agrícolas, animales vivos, materias primas textiles y productos semielaborados",
        "Venta de motocicleta y de sus partes, piezas y accesorios",
        "Venta de muebles y enseres domésticos",
        "Venta de partes, piezas y accesorios de vehículos automotores",
        "Venta de productos para la fabricación de equipos para la generación de energía alternativa",
        "Venta de reproductores y grabadoras de CD y DVD",
        "Venta de sombreros, guantes, pañuelo y corbata",
        "Venta de tapices, cortinas y alfombras",
        "Venta de vehículos automotores de pasajeros y vehículos de motor especializados como ambulancias y minibuses",
        "Venta de vehículos automotores, nuevos N. C. P. (incluye casas rodantes, tráiler, remolques, ambulancias, ómnibus)",
        "Venta de vehículos automotores, usados N. C. P. (incluye casas rodantes, tráiler, camiones, remolques, ambulancias, ómnibus)",
        "Venta de vehículos de camping, como caravanas y autocaravanas",
        "Venta directa a través de la televisión, radio y teléfono",
        "Venta y distribución de fósforos y materiales para su fabricación",
        "Venta y distribución de fuegos artificiales",
        "Venta y distribución de tarjetas de llamadas telefónicas",
        "Venta, mantenimiento y reparación de motocicletas y de sus partes y piezas",
        "Venta, reparación o alquilar de plantas eléctricas",
        "Ventas al por mayor de aceites y grasas",
        "Ventas al por mayor de alimentos, bebidas y tabaco",
        "Ventas al por mayor de artículos de cuero",
        "Ventas al por mayor de flores y plantas naturales y artificiales",
        "Ventas al por menor de artículos de bazar y menaje",
        "Ventas de vehículos automotores, excepto motocicleta",
        "Verificación de las cuentas y la certificación de su exactitud",
        "Video grabación de eventos como bodas, reuniones, etc.",
        "Vigilancia y seguridad",
        "Visitas a museos históricos o culturales, teatrales, musicales o eventos deportivos",
        "Viviendas residenciales para el perturbado emocionalmente",
        "Vuelos charter de pasajeros",
        "Vuelos de exhibición y de turismo"
    ]
}

export default dropdownOptions;