import { readOnlyFileUI } from "./commons";


const form = {
    title: "Carga Documentos",
    displayName: 'ComplianceCaseReviewUploadBusiness',
    page: 'compliance-case-review-upload-business',
};


const reportesDebidaDiligencia = {
    title: "Reportes Debida Diligencia",
    type: "object",
    properties: {
        "titlePuesto": { title: "Puesto", type: "null", const: null },
        "titleBanco": { title: "Banco", type: "null", const: null },
        "titleSafi": { title: "Safi", type: "null", const: null },
        "informeONGBanco": { title: "Informe ONG", type: "number" },
        "infPersJurAltRiesgoBanco": { title: "Informe de persona jurídica de alto riesgo", type: "number" },
        "infPersJurAltRiesgoConsBanco": { title: "Informe de persona jurídica de alto riesgo consorcios", type: "number" },
        "infPersJurAltRiesgoFideBanco": { title: "Informe de persona jurídica de alto riesgo fideicomisos", type: "number" },
        "infPersJurAltRiesgoFondInvBanco": { title: "Informe de persona jurídica de alto riesgo fondos de inversión", type: "number" },
        "infPersJurRiesgoBajoMedioBanco": { title: "Revisión de Cumplimiento a la DD - Personas Jurídicas", type: "number" },
        "informeONGPuesto": { title: "Informe ONG", type: "number" },
        "infPersJurAltRiesgoPuesto": { title: "Informe de persona jurídica de alto riesgo", type: "number" },
        "infPersJurAltRiesgoConsPuesto": { title: "Informe de persona jurídica de alto riesgo consorcios", type: "number" },
        "infPersJurAltRiesgoFidePuesto": { title: "Informe de persona jurídica de alto riesgo fideicomisos", type: "number" },
        "infPersJurAltRiesgoFondInvPuesto": { title: "Informe de persona jurídica de alto riesgo fondos de inversión", type: "number" },
        "infPersJurRiesgoBajoMedioPuesto": { title: "Revisión de Cumplimiento a la DD - Personas Jurídicas", type: "number" },
        "informeONGSafi": { title: "Informe ONG", type: "number" },
        "infPersJurAltRiesgoSafi": { title: "Informe de persona jurídica de alto riesgo", type: "number" },
        "infPersJurAltRiesgoConsSafi": { title: "Informe de persona jurídica de alto riesgo consorcios", type: "number" },
        "infPersJurAltRiesgoFideSafi": { title: "Informe de persona jurídica de alto riesgo fideicomisos", type: "number" },
        "infPersJurAltRiesgoFondInvSafi": { title: "Informe de persona jurídica de alto riesgo fondos de inversión", type: "number" },
        "infPersJurRiesgoBajoMedioSafi": { title: "Revisión de Cumplimiento a la DD - Personas Jurídicas", type: "number" },
    }
}
const reportesDebidaDiligenciaFirmados = {
    title: "Reportes Debida Diligencia Firmados",
    type: "object",
    properties: {
        "titlePuesto": { title: "Puesto", type: "null", const: null },
        "titleBanco": { title: "Banco", type: "null", const: null },
        "titleSafi": { title: "Safi", type: "null", const: null },
        "informeONGFirmadoBanco": { title: "Informe ONG firmado", type: "number", },
        "infPersJurAltRiesgoFirmadoBanco": { title: "Informe de persona jurídica de alto riesgo firmado", type: "number", },
        "infPersJurAltRiesgoConsFirmadoBanco": { title: "Informe de persona jurídica de alto riesgo consorcios firmado", type: "number", },
        "infPersJurAltRiesgoFideFirmadoBanco": { title: "Informe de persona jurídica de alto riesgo fideicomisos firmado", type: "number", },
        "infPersJurAltRiesgoFondInvFirmadoBanco": { title: "Informe de persona jurídica de alto riesgo fondos de inversión firmado", type: "number", },
        "infPersJurRiesgoBajoMedioFirmadoBanco": { title: "Revisión de Cumplimiento a la DD - Personas Jurídicas firmado", type: "number", },
        "informeONGFirmadoPuesto": { title: "Informe ONG firmado", type: "number", },
        "infPersJurAltRiesgoFirmadoPuesto": { title: "Informe de persona jurídica de alto riesgo firmado", type: "number", },
        "infPersJurAltRiesgoConsFirmadoPuesto": { title: "Informe de persona jurídica de alto riesgo consorcios firmado", type: "number", },
        "infPersJurAltRiesgoFideFirmadoPuesto": { title: "Informe de persona jurídica de alto riesgo fideicomisos firmado", type: "number", },
        "infPersJurAltRiesgoFondInvFirmadoPuesto": { title: "Informe de persona jurídica de alto riesgo fondos de inversión firmado", type: "number", },
        "infPersJurRiesgoBajoMedioFirmadoPuesto": { title: "Revisión de Cumplimiento a la DD - Personas Jurídicas firmado", type: "number", },
        "informeONGFirmadoSafi": { title: "Informe ONG firmado", type: "number", },
        "infPersJurAltRiesgoFirmadoSafi": { title: "Informe de persona jurídica de alto riesgo firmado", type: "number", },
        "infPersJurAltRiesgoConsFirmadoSafi": { title: "Informe de persona jurídica de alto riesgo consorcios firmado", type: "number", },
        "infPersJurAltRiesgoFideFirmadoSafi": { title: "Informe de persona jurídica de alto riesgo fideicomisos firmado", type: "number", },
        "infPersJurAltRiesgoFondInvFirmadoSafi": { title: "Informe de persona jurídica de alto riesgo fondos de inversión firmado", type: "number", },
        "infPersJurRiesgoBajoMedioFirmadoSafi": { title: "Revisión de Cumplimiento a la DD - Personas Jurídicas firmado", type: "number", },
        "aprobacionAltaGerenciaBanco": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaBancoFide": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaBancoFondInv": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaBancoCons": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaBancoONG": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaPuesto": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaPuestoFide": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaPuestoFondInv": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaPuestoCons": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaPuestoONG": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaSafi": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaSafiFide": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaSafiFondInv": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaSafiCons": { title: "Aprobación de Alta Gerencia", type: "number", },
        "aprobacionAltaGerenciaSafiONG": { title: "Aprobación de Alta Gerencia", type: "number", },
    }
}

form.schema = {
    title: "Debida Diligencia Persona Jurídica - Reportes",
    type: "object",
    properties: {
        "reportesDebidaDiligencia": reportesDebidaDiligencia,
        "reportesDebidaDiligenciaFirmados": reportesDebidaDiligenciaFirmados,
    },
    required: [],
    dependencies: {

    },
};

form.uiSchema = {
    "reportesDebidaDiligencia": {
        "classNames": "businessComplianceUploads-bdd",
        "ui:field": "HideObjectField",
        "titlePuesto" : { classNames: "title col1", "joli:showAttr": { "jnx": "2 in $formObject.productInstitutions" } },
        "titleBanco"  : { classNames: "title col2", "joli:showAttr": { "jnx": "1 in $formObject.productInstitutions" } },
        "titleSafi"   : { classNames: "title col3", "joli:showAttr": { "jnx": "3 in $formObject.productInstitutions" } },
        "informeONGBanco"                  : { ...readOnlyFileUI, classNames: "row2 col2", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel = 3 and
            $formObject.clients[0].businessClientBasicInfo.entityType = 'Asociacion sin fines de lucro / ONG' and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGBanco)
        `},
        "infPersJurAltRiesgoBanco"         : { ...readOnlyFileUI, classNames: "row2 col2", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["S.A., S.A.S. o S.R.L.", "Empresa extranjera", "E.I.R.L.", "Cooperativa", "Empresa Gubernamental"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoBanco)
        `},
        "infPersJurAltRiesgoConsBanco"     : { ...readOnlyFileUI, classNames: "row2 col2", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Consorcio"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsBanco)
        `},
        "infPersJurAltRiesgoFideBanco"     : { ...readOnlyFileUI, classNames: "row2 col2", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fideicomiso"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFideBanco)
        `},
        "infPersJurAltRiesgoFondInvBanco"  : { ...readOnlyFileUI, classNames: "row2 col2", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fondos de inversion"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvBanco)
        `},
        "infPersJurRiesgoBajoMedioBanco"   : { ...readOnlyFileUI, classNames: "row2 col2", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [1, 2] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurRiesgoBajoMedioBanco)
        `},

        "informeONGPuesto"                 : { ...readOnlyFileUI, classNames: "row2 col1", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Asociacion sin fines de lucro / ONG"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGPuesto)
        `},
        "infPersJurAltRiesgoPuesto"        : { ...readOnlyFileUI, classNames: "row2 col1", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["S.A., S.A.S. o S.R.L.", "Empresa extranjera", "E.I.R.L.", "Cooperativa", "Empresa Gubernamental"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoPuesto)
        `},
        "infPersJurAltRiesgoConsPuesto"    : { ...readOnlyFileUI, classNames: "row2 col1", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Consorcio"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsPuesto)
        `},
        "infPersJurAltRiesgoFidePuesto"    : { ...readOnlyFileUI, classNames: "row2 col1", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fideicomiso"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFidePuesto)
        `},
        "infPersJurAltRiesgoFondInvPuesto" : { ...readOnlyFileUI, classNames: "row2 col1", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fondos de inversion"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvPuesto)
        `},
        "infPersJurRiesgoBajoMedioPuesto"  : { ...readOnlyFileUI, classNames: "row2 col1", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [1, 2] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurRiesgoBajoMedioPuesto)
        `},

        "informeONGSafi"                   : { ...readOnlyFileUI, classNames: "row2 col3", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Asociacion sin fines de lucro / ONG"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGSafi)
        `},
        "infPersJurAltRiesgoSafi"          : { ...readOnlyFileUI, classNames: "row2 col3", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["S.A., S.A.S. o S.R.L.", "Empresa extranjera", "E.I.R.L.", "Cooperativa", "Empresa Gubernamental"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoSafi)
        `},
        "infPersJurAltRiesgoConsSafi"      : { ...readOnlyFileUI, classNames: "row2 col3", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Consorcio"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsSafi)
        `},
        "infPersJurAltRiesgoFideSafi"      : { ...readOnlyFileUI, classNames: "row2 col3", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fideicomiso"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFideSafi)
        `},
        "infPersJurAltRiesgoFondInvSafi"   : { ...readOnlyFileUI, classNames: "row2 col3", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fondos de inversion"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvSafi)
        `},
        "infPersJurRiesgoBajoMedioSafi"    : { ...readOnlyFileUI, classNames: "row2 col3", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [1, 2] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurRiesgoBajoMedioSafi)
        `},
    },

    "reportesDebidaDiligenciaFirmados": {
        "classNames": "businessComplianceUploads-upload",
        "ui:field": "HideObjectField",
        "titlePuesto" : { classNames: "title col1", "joli:showAttr": { "jnx": "2 in $formObject.productInstitutions" } },
        "titleBanco"  : { classNames: "title col2", "joli:showAttr": { "jnx": "1 in $formObject.productInstitutions" } },
        "titleSafi"   : { classNames: "title col3", "joli:showAttr": { "jnx": "3 in $formObject.productInstitutions" } },

        // Signed Docs Banco
        "informeONGFirmadoBanco":                  { classNames: "row2 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Asociacion sin fines de lucro / ONG"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGBanco)
        `},
        "infPersJurAltRiesgoFirmadoBanco":         { classNames: "row2 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["S.A., S.A.S. o S.R.L.", "Empresa extranjera", "E.I.R.L.", "Cooperativa", "Empresa Gubernamental"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoBanco)
        `},
        "infPersJurAltRiesgoConsFirmadoBanco":     { classNames: "row2 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Consorcio"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsBanco)
        `},
        "infPersJurAltRiesgoFideFirmadoBanco":     { classNames: "row2 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fideicomiso"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFideBanco)
        `},
        "infPersJurAltRiesgoFondInvFirmadoBanco":  { classNames: "row2 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fondos de inversion"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvBanco)
        `},
        "infPersJurRiesgoBajoMedioFirmadoBanco":   { classNames: "row2 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [1, 2] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurRiesgoBajoMedioBanco)
        `},
        // High Risk Uploads Banco
        "aprobacionAltaGerenciaBanco":             { classNames: "row3 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGBanco)
        `},
        "aprobacionAltaGerenciaBancoONG":          { classNames: "row3 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoBanco)
        `},
        "aprobacionAltaGerenciaBancoCons":         { classNames: "row3 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsBanco)
        `},
        "aprobacionAltaGerenciaBancoFide":         { classNames: "row3 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFideBanco)
        `},
        "aprobacionAltaGerenciaBancoFondInv":      { classNames: "row3 col2", "ui:field": "FileField", "ui:showIf": `
            1 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvBanco)
        `},
        // Signed Docs Puesto
        "informeONGFirmadoPuesto":                 { classNames: "row2 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Asociacion sin fines de lucro / ONG"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGPuesto)
        `},
        "infPersJurAltRiesgoFirmadoPuesto":        { classNames: "row2 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["S.A., S.A.S. o S.R.L.", "Empresa extranjera", "E.I.R.L.", "Cooperativa", "Empresa Gubernamental"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoPuesto)
        `},
        "infPersJurAltRiesgoConsFirmadoPuesto":    { classNames: "row2 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Consorcio"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsPuesto)
        `},
        "infPersJurAltRiesgoFideFirmadoPuesto":    { classNames: "row2 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fideicomiso"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFidePuesto)
        `},
        "infPersJurAltRiesgoFondInvFirmadoPuesto": { classNames: "row2 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fondos de inversion"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvPuesto)
        `},
        "infPersJurRiesgoBajoMedioFirmadoPuesto":  { classNames: "row2 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [1, 2] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurRiesgoBajoMedioPuesto)
        `},
        // High Risk Uploads Puesto
        "aprobacionAltaGerenciaPuesto":            { classNames: "row3 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGPuesto)
        `},
        "aprobacionAltaGerenciaPuestoONG":         { classNames: "row3 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoPuesto)
        `},
        "aprobacionAltaGerenciaPuestoCons":        { classNames: "row3 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsPuesto)
        `},
        "aprobacionAltaGerenciaPuestoFide":        { classNames: "row3 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFidePuesto)
        `},
        "aprobacionAltaGerenciaPuestoFondInv":     { classNames: "row3 col1", "ui:field": "FileField", "ui:showIf": `
            2 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvPuesto)
        `},
        // Signed Docs Safi
        "informeONGFirmadoSafi":                   { classNames: "row2 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Asociacion sin fines de lucro / ONG"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGSafi)
        `},
        "infPersJurAltRiesgoFirmadoSafi":          { classNames: "row2 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["S.A., S.A.S. o S.R.L.", "Empresa extranjera", "E.I.R.L.", "Cooperativa", "Empresa Gubernamental"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoSafi)
        `},
        "infPersJurAltRiesgoConsFirmadoSafi":      { classNames: "row2 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Consorcio"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsSafi)
        `},
        "infPersJurAltRiesgoFideFirmadoSafi":      { classNames: "row2 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fideicomiso"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFideSafi)
        `},
        "infPersJurAltRiesgoFondInvFirmadoSafi":   { classNames: "row2 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $formObject.clients[0].businessClientBasicInfo.entityType in ["Fondos de inversion"] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvSafi)
        `},
        "infPersJurRiesgoBajoMedioFirmadoSafi":    { classNames: "row2 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [1, 2] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurRiesgoBajoMedioSafi)
        `},
        // High Risk Uploads Safi
        "aprobacionAltaGerenciaSafi":              { classNames: "row3 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.informeONGSafi)
        `},
        "aprobacionAltaGerenciaSafiONG":           { classNames: "row3 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoSafi)
        `},
        "aprobacionAltaGerenciaSafiCons":          { classNames: "row3 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoConsSafi)
        `},
        "aprobacionAltaGerenciaSafiFide":          { classNames: "row3 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFideSafi)
        `},
        "aprobacionAltaGerenciaSafiFondInv":       { classNames: "row3 col3", "ui:field": "FileField", "ui:showIf": `
            3 in $formObject.productInstitutions and
            $formObject.clients[0].businessClientDueDiligenceReport.riskLevel in [3] and
            $isTruthy($rootFormData.reportesDebidaDiligencia.infPersJurAltRiesgoFondInvSafi)
        `},
    }
}

form.objectMap = {
    
    // GENERATED BY SPONDYR
    "generatedFiles.compliance_0_InformeOngBanco": "reportesDebidaDiligencia.informeONGBanco",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoBanco": "reportesDebidaDiligencia.infPersJurAltRiesgoBanco",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoConsorciosBanco": "reportesDebidaDiligencia.infPersJurAltRiesgoConsBanco",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFideicomisosBanco": "reportesDebidaDiligencia.infPersJurAltRiesgoFideBanco",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFondosDeInversionBanco": "reportesDebidaDiligencia.infPersJurAltRiesgoFondInvBanco",
    "generatedFiles.compliance_0_RevisiónDeCumplimientoDdPersonasJurídicasBanco": "reportesDebidaDiligencia.infPersJurRiesgoBajoMedioBanco",
    
    "generatedFiles.compliance_0_InformeOngPuesto": "reportesDebidaDiligencia.informeONGPuesto",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoPuesto": "reportesDebidaDiligencia.infPersJurAltRiesgoPuesto",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoConsorciosPuesto": "reportesDebidaDiligencia.infPersJurAltRiesgoConsPuesto",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFideicomisosPuesto": "reportesDebidaDiligencia.infPersJurAltRiesgoFidePuesto",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFondosDeInversionPuesto": "reportesDebidaDiligencia.infPersJurAltRiesgoFondInvPuesto",
    "generatedFiles.compliance_0_RevisiónDeCumplimientoDdPersonasJurídicasPuesto": "reportesDebidaDiligencia.infPersJurRiesgoBajoMedioPuesto",
    
    "generatedFiles.compliance_0_InformeOngSafi": "reportesDebidaDiligencia.informeONGSafi",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoSafi": "reportesDebidaDiligencia.infPersJurAltRiesgoSafi",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoConsorciosSafi": "reportesDebidaDiligencia.infPersJurAltRiesgoConsSafi",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFideicomisosSafi": "reportesDebidaDiligencia.infPersJurAltRiesgoFideSafi",
    "generatedFiles.compliance_0_InformePersonaJurídicaAltoRiesgoFondosDeInversionSafi": "reportesDebidaDiligencia.infPersJurAltRiesgoFondInvSafi",
    "generatedFiles.compliance_0_RevisiónDeCumplimientoDdPersonasJurídicasSafi": "reportesDebidaDiligencia.infPersJurRiesgoBajoMedioSafi",

    // UPLOADED FILES MAPPING
    "clients[0].businessClientDueDiligenceReport.signedONGReportBancoFileId": "reportesDebidaDiligenciaFirmados.informeONGFirmadoBanco",
    "clients[0].businessClientDueDiligenceReport.signedONGReportPuestoFileId": "reportesDebidaDiligenciaFirmados.informeONGFirmadoPuesto",
    "clients[0].businessClientDueDiligenceReport.signedONGReportSafiFileId": "reportesDebidaDiligenciaFirmados.informeONGFirmadoSafi",

    "clients[0].businessClientDueDiligenceReport.signedLegalReportBancoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFirmadoBanco",
    "clients[0].businessClientDueDiligenceReport.signedLegalReportPuestoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFirmadoPuesto",
    "clients[0].businessClientDueDiligenceReport.signedLegalReportSafiFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFirmadoSafi",

    "clients[0].businessClientDueDiligenceReport.signedLegalConsortiumReportBancoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoConsFirmadoBanco",
    "clients[0].businessClientDueDiligenceReport.signedLegalConsortiumReportPuestoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoConsFirmadoPuesto",
    "clients[0].businessClientDueDiligenceReport.signedLegalConsortiumReportSafiFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoConsFirmadoSafi",

    "clients[0].businessClientDueDiligenceReport.signedLegalEscrowReportBancoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFideFirmadoBanco",
    "clients[0].businessClientDueDiligenceReport.signedLegalEscrowReportPuestoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFideFirmadoPuesto",
    "clients[0].businessClientDueDiligenceReport.signedLegalEscrowReportSafiFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFideFirmadoSafi",

    "clients[0].businessClientDueDiligenceReport.signedLegalInvestmentFundsReportBancoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFondInvFirmadoBanco",
    "clients[0].businessClientDueDiligenceReport.signedLegalInvestmentFundsReportPuestoFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFondInvFirmadoPuesto",
    "clients[0].businessClientDueDiligenceReport.signedLegalInvestmentFundsReportSafiFileId": "reportesDebidaDiligenciaFirmados.infPersJurAltRiesgoFondInvFirmadoSafi",

    "clients[0].businessClientDueDiligenceReport.dueDiligenceComplianceRevisionBancoFileId": "reportesDebidaDiligenciaFirmados.infPersJurRiesgoBajoMedioFirmadoBanco",
    "clients[0].businessClientDueDiligenceReport.dueDiligenceComplianceRevisionPuestoFileId": "reportesDebidaDiligenciaFirmados.infPersJurRiesgoBajoMedioFirmadoPuesto",
    "clients[0].businessClientDueDiligenceReport.dueDiligenceComplianceRevisionSafiFileId": "reportesDebidaDiligenciaFirmados.infPersJurRiesgoBajoMedioFirmadoSafi",

    "clients[0].businessClientDueDiligenceReport.highManagementApprovalBancoFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaBanco",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalBancoONGFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaBancoONG",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalBancoConsortFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaBancoCons",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalBancoTrustsFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaBancoFide",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalBancoInvFundsFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaBancoFondInv",

    "clients[0].businessClientDueDiligenceReport.highManagementApprovalPuestoFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaPuesto",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalPuestoONGFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaPuestoONG",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalPuestoConsortFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaPuestoCons",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalPuestoTrustsFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaPuestoFide",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalPuestoInvFundsFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaPuestoFondInv",

    "clients[0].businessClientDueDiligenceReport.highManagementApprovalSAFIFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaSafi",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalSAFIONGFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaSafiONG",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalSAFIConsortFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaSafiCons",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalSAFITrustsFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaSafiFide",
    "clients[0].businessClientDueDiligenceReport.highManagementApprovalSAFIInvFundsFileId": "reportesDebidaDiligenciaFirmados.aprobacionAltaGerenciaSafiFondInv",
    
}


export default form;