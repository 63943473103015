import fetchApi from "../util/fetchApi";
import checkErrors from './checkErrors';


export const LookupApi = {
    async getInstitutions() {
        return checkErrors(await fetchApi.lookup.get('institutions'));
    },
    async getProducts({ institution }) {
        return checkErrors(await fetchApi.lookup.get(`products/${institution}`));
    },
    async getClientTypes() {
        return checkErrors(await fetchApi.lookup.get('client-types'));
    },
    async getRoles() {
        return checkErrors(await fetchApi.lookup.get('roles'));
    },
    async getSLASetting() {
        return checkErrors(await fetchApi.lookup.get('sla'));
    },
    async getUsers({
        roles,
        inOffice,
        canOwn
    }) {
        return checkErrors(await fetchApi.lookup.get(`users`, {
            useCache: false,
            query: {
                roles: roles ? roles.join(',') : undefined,
                inOffice,
                canOwn
            }
        }));
    },
    async getBranches() {
        return checkErrors(await fetchApi.lookup.get('branches'));
    },
    async getStatuses() {
        return checkErrors((await fetchApi.lookup.get('statuses', {
            useCache: false
        }))?.data);
    },
}


export default LookupApi;
