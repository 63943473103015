import {
    boolYesNoType, strYesNoNAType, multiFileUploadField, tipoDeEntidad,
} from './commons';

const form = {
    title: "DD Cumplimiento",
    displayName: 'ComplianceCaseReviewBusiness',
    page: 'compliance-case-review-business',
    commentFieldsMap: {
        "Comentarios Area": "clients[0].businessClientDueDiligenceReport.areaComments",
        "Comentarios Cumplimiento": "clients[0].businessClientDueDiligenceReport.comments",
    },
    isSentBack(object) {
        return [
            "Devuelto",
            "Requerimiento de información adicional"
        ].indexOf(object.clients[0].businessClientDueDiligenceReport.dueDiligenceResult) !== -1;
    }
};



form.schema = {
    title: "Debida Diligencia Persona Jurídica - Cumplimiento",
    type: "object",
    properties: {
        "fechaSolicitud": {
            title: "Fecha de solicitud",
            type: "string",
            format: "date"
        },
        "tipoRequerimiento": {
            title: "Tipo de requerimiento",
            type: "string",
        },
        "tipoEntidad": {
            ...tipoDeEntidad,
            readOnly: true
        },
        "tipoCliente": {
            title: "Tipo de Cliente",
            type: "number",
            enum: [0, 1, 2],
            enumNames: [" ", "Persona Física", "Persona Jurídica"],
        },
        "nivelRiesgo": {
            title: "Nivel de Riesgo",
            type: "number",
            enumNames: ["Alto", "Medio", "Bajo"],
            enum: [3, 2, 1]
        },
        "validacionDocumentosRequeridos": {
            title: "Validación de Documentos Requeridos",
            type: "object",
        },
        "personaJuridicaNacional": {
            title: " ",
            description: "Persona Juridica Nacional",
            type: "object",
            properties: {
                "nacional": {
                    title: " ",
                    type: "object",
                    properties: {
                        "registroMercantil": {
                            title: "Registro mercantil o documento de incorporación",
                            type: "boolean",
                        },
                        "certRNC": {
                            title: "Certificación del RNC",
                            type: "boolean",
                        },
                        "estatusSociales": {
                            title: "Estatus sociales y sus modificaciones",
                            type: "boolean",
                        },
                        "actaAsamblea": {
                            title: "Acta y nómina de asamblea que designa la gerencia o consejo de administración",
                            type: "boolean",
                        },
                        "actaDecreto": {
                            title: "Acta o decreto que consigna poderes",
                            type: "boolean",
                        },
                        "listaSuscriptores": {
                            title: "Lista de suscriptores de la última asamblea general extraordinaria o actualizada",
                            type: "boolean",
                        },
                        "estadosFinancieros": {
                            title: "Copia estados financieros auditados de los últimos 2 años o IR2 del último año",
                            type: "boolean",
                        },
                        "validacionEstatutoSociales": {
                            title: "Validación en estatutos sociales de no emisiones de cuotas o acciones al portador o a la orden",
                            type: "boolean",
                        },
                    },
                },
            }
        },
        "beneficiarioFinal": {
            title: " ",
            description: "Beneficiario Final",
            type: "object",
            properties: {
                "benefFinal": {
                    title: " ",
                    type: "object",
                    properties: {
                        "estatusSociales": {
                            title: "Estatus sociales y sus modificaciones",
                            type: "boolean",
                        },
                        "actaAsamblea": {
                            title: "Acta y nómina de asamblea que designa la gerencia o consejo de administración",
                            type: "boolean",
                        },
                        "actaDecreto": {
                            title: "Acta o decreto que consigna poderes",
                            type: "boolean",
                        },
                        "listaSuscriptores": {
                            title: "Lista de suscriptores de la última asamblea general extraordinaria o actualizada",
                            type: "boolean",
                        },
                        "estadosFinancieros": {
                            title: "Copia estados financieros auditados de los últimos 2 años o IR2 del último año",
                            type: "boolean",
                        },
                    },
                },
            }
        },
        "procedenciaDestinoFondos": {
            title: "Procedencia y destino de Fondos",
            type: "object",
            properties: {
                "origenFondos": {
                    title: "Origen de los Fondos",
                    ...boolYesNoType
                },
                "destinoFondos": {
                    title: "Destino de Fondos",
                    type: "number",
                    enum: [0, 1, 2, 3],
                    enumNames: [" ","Si", "No", "No Aplica"],
                },
            },
        },
        "validacionAnexos": {
            title: " ",
            description: "Validación de Anexos",
            type: "object",
            properties: {
                "valAnex": {
                    title: " ",
                    type: "object",
                    properties: {
                        "consultaListas": {
                            title: "Consultas en Listas",
                            type: "boolean",
                        },
                        "matrizRiesgo": {
                            title: "Matriz de Riesgo",
                            type: "boolean",
                        },
                        "sujetosObligados": {
                            title: "Sujetos Obligados",
                            type: "boolean",
                        },
                        "formularioDD": {
                            title: "Formulario DD",
                            type: "boolean",
                        },
                        "fatca": {
                            title: "FATCA",
                            type: "boolean",
                        },
                        "beneficiarioFinal": {
                            title: "Beneficiario Final",
                            type: "boolean",
                        },
                    },
                },
            }
        },
        "debidaDiligenciaCumplimiento": {
            title: "Debida Diligencia Cumplimiento",
            type: "object",
            properties: {
                "revisionEnListas": {
                    title: " ",
                    description: "Revisión en listas",
                    type: "object",
                    properties: {
                        "title1": { title: "Compliance Screening (World Check)", type: "null", },
                        "complianceScreening": multiFileUploadField.schema,
                        "resultadoConsultaWorldCheck": {
                            title: "Resultado Consulta World Check",
                            type: "string",
                            enum: [
                                "Sin novedad",
                                "Resultados son Falsos Positivos",
                                "Los resultados son positivos, se requiere documentacion adicional",
                                "Los resultados indican que es una PEP"
                            ],
                        },
                        "title2": { title: "Open Corporates", type: "null", },
                        "openCorporates": multiFileUploadField.schema,
                        "resultadoConsultaOpenCorporates": {
                            title: "Resultado Consulta Open Corporates",
                            type: "string",
                            enum: [
                                "Sin novedad",
                                "Resultados son Falsos Positivos",
                                "Los resultados son positivos, se requiere documentacion adicional",
                                "Los resultados indican que es una PEP"
                            ],
                        },
                        "title3": { title: "Consulta Web", type: "null", },
                        "consultaWeb": multiFileUploadField.schema,
                        "resultadoConsultaWeb": {
                            title: "Resultado Consulta Web",
                            type: "string",
                            enum: [
                                "Sin novedad",
                                "Resultados son Falsos Positivos",
                                "Los resultados son positivos, se requiere documentacion adicional",
                                "Los resultados indican que es una PEP"
                            ],
                        },
                    },
                },
                "observacionesAreaCumplimiento": {
                    title: "Observaciones Área de Cumplimiento",
                    type: "string",
                },
                "fechaRevision": {
                    title: "Fecha de revisión",
                    type: "string",
                    format: "date",
                },
                "resultadoDebidaDiligencia": {
                    title: "Resultado Debida Diligencia",
                    type: "string",
                    enum: [
                        "Aprobado",
                        "Rechazado",
                        "Aprobado con excepción",
                        "Devuelto",
                        "Requerimiento de información adicional",
                    ],
                },
            },
            dependencies: {
                "resultadoDebidaDiligencia": {
                    oneOf: [
                        {
                            properties: {
                                "resultadoDebidaDiligencia": {
                                    const: "Devuelto"
                                },
                                "razonDevolucion": {
                                    title: "Razon de Devolución",
                                    type: "string",
                                    enum: [
                                        "Documento cargado no cumple con requerimientos",
                                        "Documento o información faltante",
                                        "Error en digitación de información",
                                        "Información incorrecta",
                                        "Otros",
                                    ],
                                },
                                "comentarios": {
                                    title: "Comentarios",
                                    type: "string",
                                },
                            },
                        },
                        {
                            properties: {
                                "resultadoDebidaDiligencia": {
                                    const: "Aprobado con excepción"
                                },
                                "comentarios": {
                                    title: "Comentarios",
                                    type: "string",
                                },
                            },
                        },
                        {
                            properties: {
                                "resultadoDebidaDiligencia": {
                                    const: "Aprobado"
                                },
                            },
                        },
                        {
                            properties: {
                                "resultadoDebidaDiligencia": {
                                    const: "Rechazado"
                                },
                                "comentarios": {
                                    title: "Comentarios",
                                    type: "string",
                                },
                            },
                        },
                        {
                            properties: {
                                "resultadoDebidaDiligencia": {
                                    const: "Requerimiento de información adicional",
                                },
                                "comentarios": {
                                    title: "Información adicional solicitada",
                                    type: "string",
                                },
                            },
                        },
                    ],
                },
            },
        },
    },
    required: [],
};



form.uiSchema = {
    "personaJuridicaNacional": {
        "nacional": { "ui:widget": "checkboxes", },
    },

    "beneficiarioFinal": {
        "benefFinal": { "ui:widget": "checkboxes", },
    },
    "procedenciaDestinoFondos": {
        "origenFondos": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "validacionAnexos": {
        "ui:widget": "checkboxes",
    },
    "debidaDiligenciaCumplimiento": {
        "observacionesAreaCumplimiento": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "revisionEnListas": {
            "complianceScreening": multiFileUploadField.uiSchema,
            "openCorporates": multiFileUploadField.uiSchema,
            "consultaWeb": multiFileUploadField.uiSchema,
        }
    },
}
form.objectMap = {

    "clients[0].businessClientBasicInfo.entityType": "tipoEntidad",

    "clients[0].businessClientDueDiligenceReport.clientType": "tipoCliente",
    "clients[0].businessClientDueDiligenceReport.applicationDate": "fechaSolicitud",
    "clients[0].businessClientDueDiligenceReport.requirementType": "tipoRequerimiento",
    "clients[0].businessClientDueDiligenceReport.riskLevel": "nivelRiesgo",
    "clients[0].businessClientDueDiligenceReport.validationDocumentsRequired": "validacionDocumentosRequeridos",
    // Persona Juridica Nacional
    "clients[0].businessClientDueDiligenceReport.businessRegistration": "personaJuridicaNacional.nacional.registroMercantil", // true
    "clients[0].businessClientDueDiligenceReport.rncCert": "personaJuridicaNacional.nacional.certRNC", // true
    "clients[0].businessClientDueDiligenceReport.legalPersonSocialStat": "personaJuridicaNacional.nacional.estatusSociales", // true
    "clients[0].businessClientDueDiligenceReport.legalPersonAssemblyRecord": "personaJuridicaNacional.nacional.actaAsamblea", // true
    "clients[0].businessClientDueDiligenceReport.legalPersonDecreeRecord": "personaJuridicaNacional.nacional.actaDecreto", // true
    "clients[0].businessClientDueDiligenceReport.legalPersonSubscribersList": "personaJuridicaNacional.nacional.listaSuscriptores", // true
    "clients[0].businessClientDueDiligenceReport.legalPersonFinancialState": "personaJuridicaNacional.nacional.estadosFinancieros", // true
    "clients[0].businessClientDueDiligenceReport.socialStatValidation": "personaJuridicaNacional.nacional.validacionEstatutoSociales", // true
    // Beneficiario Final
    "clients[0].businessClientDueDiligenceReport.finalBenefSocialStat": "beneficiarioFinal.benefFinal.estatusSociales", // true
    "clients[0].businessClientDueDiligenceReport.finalBenefAssemblyRecord": "beneficiarioFinal.benefFinal.actaAsamblea", // true
    "clients[0].businessClientDueDiligenceReport.finalBenefDecreeRecord": "beneficiarioFinal.benefFinal.actaDecreto", // true
    "clients[0].businessClientDueDiligenceReport.finalBenefSubscribersList": "beneficiarioFinal.benefFinal.listaSuscriptores", // true
    "clients[0].businessClientDueDiligenceReport.finalBenefFinancialState": "beneficiarioFinal.benefFinal.estadosFinancieros", // true

    // Validacion de Anexos
    // Beneficiario Final
    "clients[0].businessClientDueDiligenceReport.queryLists": "validacionAnexos.valAnex.consultaListas", // true
    "clients[0].businessClientDueDiligenceReport.riskMatrix": "validacionAnexos.valAnex.matrizRiesgo", // true
    "clients[0].businessClientDueDiligenceReport.obligedSubjects": "validacionAnexos.valAnex.sujetosObligados", // true
    "clients[0].businessClientDueDiligenceReport.ddForm": "validacionAnexos.valAnex.formularioDD", // true
    "clients[0].businessClientDueDiligenceReport.fatcaAnnex": "validacionAnexos.valAnex.fatca", // true
    "clients[0].businessClientDueDiligenceReport.finalBeneficiaryAnnex": "validacionAnexos.valAnex.beneficiarioFinal", // true


    "clients[0].businessClientDueDiligenceReport.proofOfFundOrigins": "procedenciaDestinoFondos.origenFondos",
    "clients[0].businessClientDueDiligenceReport.fundsDestination": "procedenciaDestinoFondos.destinoFondos",

    "clients[0].businessClientDueDiligenceReport.validationAnnexes": "validacionAnexos",

    "clients[0].businessClientDueDiligenceReport.complianceScreeningFileList.items": multiFileUploadField.mapping(
        "debidaDiligenciaCumplimiento.revisionEnListas.complianceScreening"
    ),
    "clients[0].businessClientDueDiligenceReport.openCorporatesFileList.items": multiFileUploadField.mapping(
        "debidaDiligenciaCumplimiento.revisionEnListas.openCorporates"
    ),
    "clients[0].businessClientDueDiligenceReport.webSearchFileList.items": multiFileUploadField.mapping(
        "debidaDiligenciaCumplimiento.revisionEnListas.consultaWeb"
    ),
    "clients[0].businessClientDueDiligenceReport.worldCheckResult": "debidaDiligenciaCumplimiento.revisionEnListas.resultadoConsultaWorldCheck",
    "clients[0].businessClientDueDiligenceReport.openCorporateResult": "debidaDiligenciaCumplimiento.revisionEnListas.resultadoConsultaOpenCorporates",
    "clients[0].businessClientDueDiligenceReport.webSearchResult": "debidaDiligenciaCumplimiento.revisionEnListas.resultadoConsultaWeb",
    "clients[0].businessClientDueDiligenceReport.areaComments": "debidaDiligenciaCumplimiento.observacionesAreaCumplimiento",
    "clients[0].businessClientDueDiligenceReport.revisionDate": "debidaDiligenciaCumplimiento.fechaRevision",
    "clients[0].businessClientDueDiligenceReport.dueDiligenceResult": "debidaDiligenciaCumplimiento.resultadoDebidaDiligencia",
    "clients[0].businessClientDueDiligenceReport.dueDiligenceCaseReturnReason": "debidaDiligenciaCumplimiento.razonDevolucion",
    "clients[0].businessClientDueDiligenceReport.comments": "debidaDiligenciaCumplimiento.comentarios"


}


export default form;