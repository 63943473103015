import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Table, Button, Row, Col, Badge } from 'reactstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import useResourceLoader from '../../util/useResourceLoader';
import CasesApi from '../../api/CasesApi';
import Loader from '../../components/Loader';
import Notification from '../../components/Notification';
import useTitle from '../../util/useTitle';
import { DATE_FORMAT, DD_SENT_BACK_MAP, TIME_FORMAT, SLACOLOR } from '../../constants';
import Pagination from '../../components/Pagination';
import useSearchParams from '../../util/useSearchParams';
import { toDrDateTime, getBusinessDatesCount } from "../../util/dates";
import CaseSearchBar from "../../components/Search/CaseSearchBar";
import SortButton from "../../components/SortButton";
import { AuthContext } from '../../context/AuthContext';
import canViewStatus from '../../util/canViewStatus';
import deduplicate from '../../util/deduplicate';
import LookupApi from '../../api/LookupApi';


export function CaseUniversalFilter({ history }) {
  useTitle("JOLI");

  const auth = useContext(AuthContext);
  const {
    user: {
      permissions: {
        createCase: canCreateCases
      } = {},
      user: {
        role: {
          extras
        } = {}
      } = {},
    } = {}
  } = auth;
  const permissions = ((auth || {}).user || {}).permissions || {};

  const [showFilter, setShowFilter] = useState(false);
  const [
    [offset, setOffset],
    [size, setSize],
    [sortBy, setSortBy],
    [searchParams, setSearchParams]
  ] = useSearchParams({
    offset: 0,
    size: 10,
    sortBy: '-date'
  });

  const filters = useMemo(() => ({
    ...searchParams
  }), [searchParams]);

  const setFilters = useCallback((filters) => {
    setSearchParams({...searchParams, ...filters});
  }, [searchParams, setSearchParams])
  
  filters.all = "1"; // Universal Filter Default View: all cases
  filters.disableShowAllAssigned = true; // Disable Show All Assigned checkbox for this page
  filters.showAllAssigned = true; // Set Show All Assigned to true to see all cases

  useEffect(() => setFilters({ ...(filters || {}) }), []);

  const [slaSetting] = useResourceLoader(() => LookupApi.getSLASetting(), []);


  const [paging, loadingCases, errorLoadingCases] = useResourceLoader(async () => {
    const cases = await CasesApi.getCases({ offset, size, sortBy, ...filters });
    return Array.isArray(cases) ? {
      from: 0,
      to: cases.length,
      count: cases.length,
      items: cases
    } : cases;
  }, [size, offset, sortBy, filters]);

  const {
    from = 0,
    to = 0,
    count = 0,
    items: cases = [],
  } = paging || {};

  const toggleFilter = (e) => {
    e.preventDefault();
    setShowFilter(!showFilter);
  }

  const onSearch = (update) => {
    setFilters({ ...filters, ...update });
  }

  const onClearSearch = useCallback(() => setSearchParams({offset, size, sortBy}), [setSearchParams, offset, size, sortBy]);

  return (
    <div className="inbox-table">
      <div className="d-flex justify-content-between align-items-center">
        <h1>Búsqueda</h1>
      </div>
      <Row>
        <Col xs="11" sm="6" className="order-sm-2">
          {canCreateCases ? (
            <Link tag="button" to="/case-intake" className="btn btn-primary">
              <i className="glyphicon glyphicon-plus text-white" />
              {" "} Crear Caso Nuevo
            </Link>
          ) : null}
        </Col>
        <Col xs="1" className="order-sm-3">
          <Button onClick={toggleFilter} type="button" outline color="secondary" active={showFilter}>
            <i className="glyphicon glyphicon-filter" />
          </Button>
        </Col>
        <Col sm="5" className="order-sm-1">
          <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
        </Col>
      </Row>
      {showFilter && <CaseSearchBar filters={filters} onSearch={onSearch} onClearSearch={onClearSearch} />}
      {permissions.limitColumnAndFilterView ? (<>
        <Table hover>
          <thead><tr>
            <SortButton tag="th" sortKey="id" sortBy={sortBy} setSortBy={setSortBy}>ID Caso</SortButton>
            <SortButton tag="th" sortKey="date" sortBy={sortBy} setSortBy={setSortBy}>Fecha</SortButton>
            <SortButton tag="th" sortKey="client" sortBy={sortBy} setSortBy={setSortBy}>Cliente(s)</SortButton>
            <SortButton tag="th" sortKey="owner" sortBy={sortBy} setSortBy={setSortBy}>Encargado Caso</SortButton>
            <SortButton tag="th" sortKey="timeLapse" sortBy={sortBy} setSortBy={setSortBy}>Días Abierto</SortButton>
            <th colSpan="2" />
          </tr></thead>
          <tbody>
            {loadingCases ? <tr><td colSpan="6"><Loader>Cargando Casos</Loader></td></tr> : (<>
              {errorLoadingCases ? <tr><td colSpan="6"><Notification color="danger">{JSON.stringify(errorLoadingCases)}</Notification></td></tr> : null}
              {(cases && cases.length) ? cases.map((caseObj, idx) => (
                <CaseRow key={idx} caseObj={caseObj} slaSetting={slaSetting} filters={filters}/>
              )) : (
                <tr><td colSpan="6"><Notification color="warning">
                  La lista de casos esta vacia.
                </Notification></td></tr>
              )}
            </>)}
          </tbody>
        </Table>
      </>) : 
        <Table hover>
          <thead><tr>
            <SortButton tag="th" sortKey="id" sortBy={sortBy} setSortBy={setSortBy}>ID Caso</SortButton>
            <SortButton tag="th" sortKey="date" sortBy={sortBy} setSortBy={setSortBy}>Fecha</SortButton>
            <SortButton tag="th" sortKey="client" sortBy={sortBy} setSortBy={setSortBy}>Cliente(s)</SortButton>
            <SortButton tag="th" sortKey="institution" sortBy={sortBy} setSortBy={setSortBy}>Entidad</SortButton>
            <SortButton tag="th" sortKey="product" sortBy={sortBy} setSortBy={setSortBy}>Productos</SortButton>
            <SortButton tag="th" sortKey="stage" sortBy={sortBy} setSortBy={setSortBy}>Etapa</SortButton>
            <SortButton tag="th" sortKey="owner" sortBy={sortBy} setSortBy={setSortBy}>Encargado Caso</SortButton>
            <SortButton tag="th" sortKey="timeLapse" sortBy={sortBy} setSortBy={setSortBy}>Días Abierto</SortButton>
            <th colSpan="2" />
          </tr></thead>
          <tbody>
            {loadingCases ? <tr><td colSpan="6"><Loader>Cargando Casos</Loader></td></tr> : (<>
              {errorLoadingCases ? <tr><td colSpan="6"><Notification color="danger">{JSON.stringify(errorLoadingCases)}</Notification></td></tr> : null}
              {(cases && cases.length) ? cases.map((caseObj, idx) => (
                <CaseRow key={idx} caseObj={caseObj} slaSetting={slaSetting} filters={filters}/>
              )) : (
                <tr><td colSpan="6"><Notification color="warning">
                  La lista de casos esta vacia.
                </Notification></td></tr>
              )}
            </>)}
          </tbody>
        </Table>
      }
      <Pagination offset={from} count={count} size={size} setSize={setSize} setOffset={setOffset} />
    </div>
  );
}


function CaseRow({
  slaSetting,
  caseObj: {
    id,
    clients,
    products,
    institutions,
    createdAt,
    status: statusStr,
    clientType,
    ddSendBack,
    owner,
    completed
  },
  filters
}) {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const status = useMemo(() => JSON.parse(statusStr), [statusStr]);
  const canSeeDetails = useMemo(() => status.filter(st => canViewStatus(st, auth)).length > 0, [status]);
  const sentBackTxt = (DD_SENT_BACK_MAP[ddSendBack] || {}).title;
  const currTime = new Date();
  const createdTime = new Date(toDrDateTime(createdAt).format("YYYY/MM/DD h:mm A"));
  const lapsedTime = getBusinessDatesCount(createdTime, currTime)
  const permissions = ((auth || {}).user || {}).permissions || {};
  return (<tr>
    <td>{id}</td>
    <td>
      <div>{toDrDateTime(createdAt).format(TIME_FORMAT)}</div>
      <div>{toDrDateTime(createdAt).format(DATE_FORMAT)}</div>
    </td>
    <td>
      <div className="client-type">{clientType}</div>
      {clients.length ? (
        deduplicate(clients).map((cn, idx) => (
          <div key={idx} className="client-name">{cn}</div>
        ))
      ) : "----"}
    </td>
    {permissions.limitColumnAndFilterView ? (<>
      {/* Hide fields */}
    </>) : <><td>{deduplicate(institutions).join(", ") || "----"}</td><td>{products.length ? deduplicate(products.map((p, idx) => <div key={idx}>{p}</div>)) : "----"}</td><td>
        {status.map((st, idx) => (<div key={idx}>{st.name}</div>))}
        {sentBackTxt || null}
      </td></>}
    <td>
      {owner ? `${owner.firstName} ${owner.lastName}` : "----"}
    </td>
    <td style={{ textAlign: "center" }}>
      {completed ?
        <Badge color="default" style={{ fontSize: "100%" }} pill>
          <i className="fa fa-check" />
        </Badge>
        :
        <Badge color={slaSetting ? SLACOLOR(lapsedTime, slaSetting) : undefined} style={{ fontSize: "100%" }} pill>
          {lapsedTime}
        </Badge>
      }
    </td>
    <td>
      <Button color="primary" onClick={() => history.push(`/cases/${id}?readonly=1`)} className="nowrap">Ver</Button>
    </td>
    <td>
      <Button color="primary" disabled={!canSeeDetails || completed} onClick={() => history.push(`/cases/${id}`)}>Trabajar</Button>
    </td>
  </tr>);
}


export default CaseUniversalFilter;
