import { Trans } from '@lingui/react';
import React from 'react';
import { Table, Button, Col, Container, Row, CardBody, Card } from 'reactstrap';

import useResourceLoader from '../util/useResourceLoader';
import CasesApi from '../api/CasesApi';
import Loader from '../components/Loader';
import Notification from '../components/Notification';
import useTitle from '../util/useTitle';
import { FileIcon, FolderPlus, SearchIcon } from "../components/Icons";


export function Home({ history }) {
  useTitle("JOLI");

  return (<>
    <div>
      <h1>Bienvenido</h1>
      <p>¿Qué te gustaría hacer hoy?</p>
      <Row>
        <Col>
          <Card className="clickable" onClick={() => history.push("/case-intake")}>
            <CardBody>
              <div className="d-flex p-4 justify-content-center"><FolderPlus width={72} height={72} /></div>
              <Button block color="primary" >Crear un Caso</Button>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="clickable" onClick={() => history.push("/cases")}>
            <CardBody>
              <div className="d-flex p-4 justify-content-center"><FileIcon width={72} height={72} /></div>
              <Button block color="primary" >Ver Casos Pendientes</Button>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="clickable" onClick={() => history.push("/cases")}>
            <CardBody>
              <div className="d-flex p-4 justify-content-center"><SearchIcon width={72} height={72} /></div>
              <Button block color="primary" >Consultar Casos</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  </>);
}

export default Home;