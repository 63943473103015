import {useMemo} from "react";


function useEvalFunction(
    args,
    evalExpression
){
    evalExpression = evalExpression.trim();

    const fn = useMemo(() => {
        if (evalExpression.substr(0, 6) !== '!!eval') {
            return () => evalExpression;
        }

        evalExpression = minDedent(evalExpression.substr(6));

        /* eslint-disable no-new-func */
        return new Function(
            ...args,
            `return (${evalExpression});`
        )
    }, [evalExpression]);

    return fn
}


function minDedent(text){
    const lines = text.trim().split("\n");
    const minIndent = lines.slice(1).reduce((_, l) => {
        return Math.min(_, ((/ +/.exec(l) || [])[0] || '').length)
    }, Number.POSITIVE_INFINITY);
    return lines.map((l, idx) => idx ? l.substr(minIndent) : l).join("\n");
}


export default useEvalFunction;