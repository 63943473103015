import {useEffect, useMemo} from "react";
import { mapObject } from "../../util/mapObject";
import { useSideChannelSubscription } from "../../util/useSideChannel";

function ComputedField(props) {
    const {
        formContext: {
            sideChannel,
        },
        name,
        schema: {
            references,
            computation,
        },
        onChange,
        formData: formDataFieldValue,
    } = props;

    const formData = useSideChannelSubscription(sideChannel) || {};

    const computeFn = useMemo(() => {
        /* eslint-disable no-new-func */
        return new Function('references', `return (${computation});`);
    }, [computation]);

    useEffect(() => {
        if (formData && references) {
            const refMap = mapObject(formData, references);
            const refValue = computeFn(refMap);
            if (refValue !== formDataFieldValue) {
                console.log(`Update ComputedField ${name} to ${refValue}`);
                onChange(refValue);
            }
        }
    }, [
        references,
        computeFn,
        formDataFieldValue,
        formData,
    ]);

    return (null);
}


export default ComputedField;