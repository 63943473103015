import React, { useEffect, useState } from "react";
import { Table, Button, Input, Badge } from "reactstrap";
import LookupApi from "../../api/LookupApi";
import Loader from "../../components/Loader";
import Notification from "../../components/Notification";
import useResourceLoader from "../../util/useResourceLoader";
import SettingsManagementApi from "../../api/SettingsManagementApi";
import useLoader from "../../util/useLoader";


function AlarmSetting() {
    
    const [statuses, loadingStatuses, errorLoadingStatuses] = useResourceLoader(
        () => LookupApi.getStatuses(),
        [],
        []
    );

    const [loadingAlarms, errorLoadingSetSla, loadFn] = useLoader();

    const [arrModified, setArrModified] = useState([]);

    const addOrUpdateObject = (newObj) => {
        setArrModified((prevArr) => {

            const existingIndex = prevArr.findIndex(
                (obj) => obj.status === newObj.status
            );

            if (existingIndex !== -1) {
                const updatedArr = prevArr.map((obj, index) =>
                    index === existingIndex ? { ...obj, ...newObj } : obj
                );
                return updatedArr;
            } else {
                return [...prevArr, newObj];
            }
        });
    };

    function setModify(status, property, value) {

        if (!value) return;

        const obj = { status };

        obj[property] = parseInt(value, 10);

        addOrUpdateObject(obj);

    }

    useEffect(() => {

        if (statuses) {
            setArrModified(statuses)
            return;
        }

        setArrModified([])

    }, [statuses]);



    function save() {
        loadFn(() => SettingsManagementApi.setAlarms(arrModified));
    }

    return loadingStatuses || loadingAlarms ? (
        <Loader />
    ) : (errorLoadingStatuses ? (
        <Notification error={errorLoadingStatuses} />
    ) : (<>
        <Table>
            <thead>
                <tr>
                    <th>Estado</th>
                    <th>Horas</th>
                </tr>
            </thead>
            <tbody>
                {(statuses || [])?.map((_, idx) => {

                    const selfValue = arrModified.find(x => x.status === _.status);

                    return (
                        <tr key={idx}>
                            <td>{_?.title}</td>
                            <td>
                                <Input type="number" value={selfValue?.quantity} onChange={(e) => { setModify(_.status, 'quantity', e.target.value) }} />
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </Table>
        <Button color="primary" onClick={save}>Grabar</Button>
    </>));
}


export default AlarmSetting;