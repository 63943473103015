import secondaryClientForm from "../secondary/InvestmentClientCreationConfirmationSAFISchema";

const form = {
    title: "Creación & Activación Cliente SAFI",
    displayName: 'InvestmentClientCreationConfirmationSAFI',
    page: 'investment-client-creation-confirmation-safi',
    commentFieldsMap: {
        "comentarios": "caseInvestmentInfo.commentsSAFI",
    },
    secondaryClientForm,
    isSentBack(object) {
        return object.clients[0].retailClientInvestorProfile.resultSAFI === 1; // Devuelto
    },
};

form.schema = {
    title: "Activación de cliente en SAFI",
    type: "object",
    properties: {
        // "enviarCorreoCliente": {
        //     "buttonText": "Enviar Correo a Cliente",
        //     subject: "¡Te damos la bienvenida a JMMB!",
        //     body: "¡TE DAMOS LA BIENVENIDA A LA FAMILIA JMMB! \n\n\n\n Estamos contentos porque contigo nuestro Grupo SAFI sigue creciendo. \n\nNuestra cultura está basada en el amor incondicional teniendo tu mejor interés en nuestros corazones. Somos tu socio financiero brindándote de soluciones integrales e innovadoras que junto a nuestra asesoría personalizada te empoderarán para lograr tus sueños. \n\n\n Le pedimos que se tome unos minutos y llene la siguiente encuesta para evaluar su experiencia de vinculación con el grupo JMMB.",
        //     type: "null",
        // },
        "infCliT24SAFI": {
            title: " ",
            description: "Informaciones Cliente T24 SAFI",
            type: "object",
            properties: {
                "cliT24SAFI": {
                    title: "Cliente creado en T24 SAFI",
                    type: "boolean",
                },
                "numCliT24SAFI":
                {
                    title: "Número de cliente en T24 SAFI",
                    type: "string"
                },
                "dineroDOP": {
                    title: "Encargo FM Mercado de Dinero (DOP) creado",
                    type: "boolean",
                },
                "dineroUSD": {
                    title: "Encargo FM Mercado de Dinero (USD) creado",
                    type: "boolean",
                },
                "plazoDOP": {
                    title: "Encargo FM Plazo 90 días (DOP) creado",
                    type: "boolean",
                },
                "cliActivadoT24SAFI": {
                    title: "Cliente Activado en T24 SAFI",
                    type: "boolean",
                },
                "resultado": {
                    title: "Resultado",
                    type: "number",
                    enum: [2, 1],
                    enumNames: ["Aprobado", "Devuelto"]
                },
            },
            required: ["numCliT24SAFI"],
            dependencies: {
                "dineroDOP": {
                    properties: {
                        "numEncargoDineroDOP": {
                            title: "Número de Aportante FM Mercado de Dinero (DOP)",
                            type: "string",
                        },
                    }
                },
                "dineroUSD": {
                    properties: {
                        "numEncargoDineroUSD": {
                            title: "Número de Aportante FM Mercado de Dinero (USD)",
                            type: "string",
                        },
                    }
                },
                "plazoDOP": {
                    properties: {
                        "numEncargoPlazoDOP": {
                            title: "Número de Aportante FM Plazo 90 días (DOP)",
                            type: "string",
                        },
                    }
                },
                "resultado": {
                    oneOf: [
                        {
                            properties: {
                                "resultado": {
                                    const: 1,
                                },
                                "comentarios": {
                                    title: "Comentarios Internos o Devolución",
                                    type: "string"
                                }
                            }
                        },
                        {
                            properties: {
                                "resultado": {
                                    const: 2,
                                },

                            }
                        }]
                }
            }
        },
        "razonDevolucion": {
            title: "Razón de devolución?",
            type: "string",
            oneOf: [
                {
                    title: "Documento cargado no cumple con requerimientos",
                    const: "Documento cargado no cumple con requerimientos"
                },
                {
                    title: "Documento o información faltante",
                    const: "Documento o informacion faltante"
                },
                {
                    title: "Error en digitación de información",
                    const: "Error en digitacion de informacion"
                },
                {
                    title: "Información incorrecta",
                    const: "Informacion incorrecta"
                },
                {
                    title: "Otros",
                    const: "Otros"
                },
            ],
        },
    }
}

form.uiSchema = {
    // "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "infCliT24SAFI": {
        "ui:order": [
            "*",
            "dineroDOP",
            "numEncargoDineroDOP",
            "dineroUSD",
            "numEncargoDineroUSD",
            "plazoDOP",
            "numEncargoPlazoDOP",
            "resultado",
            "comentarios"
        ],
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 3
            },
        }
    },
    
}
form.objectMap = {
    "clients[0].retailClientInvestorProfile.moneyMarketDOP": "infCliT24SAFI.dineroDOP",
    "clients[0].retailClientInvestorProfile.moneyMarketUSD": "infCliT24SAFI.dineroUSD",
    "clients[0].retailClientInvestorProfile.term90DaysDOP": "infCliT24SAFI.plazoDOP",
    "clients[0].retailClientInvestorProfile.moneyMarketDOPNumber": "infCliT24SAFI.numEncargoDineroDOP",
    "clients[0].retailClientInvestorProfile.moneyMarketUSDNumber": "infCliT24SAFI.numEncargoDineroUSD",
    "clients[0].retailClientInvestorProfile.term90DayDOPsNumber": "infCliT24SAFI.numEncargoPlazoDOP",
    "clients[0].retailClientInvestorProfile.clientCreatedT24SAFI": "infCliT24SAFI.cliT24SAFI",
    "clients[0].retailClientInvestorProfile.t24safiClientId": "infCliT24SAFI.numCliT24SAFI",
    "clients[0].retailClientInvestorProfile.clientActivatedT24SAFI": "infCliT24SAFI.cliActivadoT24SAFI",
    "clients[0].retailClientInvestorProfile.commentsSAFI": "infCliT24SAFI.comentarios",
    "clients[0].retailClientInvestorProfile.resultSAFI": "infCliT24SAFI.resultado",
    "clients[0].retailClientInvestorProfile.sentBackReason": "razonDevolucion",
}


export default form;