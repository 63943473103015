import Home from './views/Home';
import Login from './views/Login';
// import ViewCurrentCase from './views/ViewCurrentCase';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import ChangePasswordOnLogin from './views/ChangePasswordOnLogin';

// import CreateUser from "./views/CreateUser";
import CaseInbox from "./views/case/CaseInbox";
import CaseUniversalFilter from "./views/case/CaseUniversalFilter";
import CaseDetails from "./views/case/CaseDetails";
import CaseHistory from "./views/case/CaseHistory";
import CaseAssignments from "./views/case/CaseAssignments";
import CreateNewUser from "./views/users/CreateNewUser";
import UsersTable from './views/users/UsersTable';
import EditUser from './views/users/EditUser';
import Profile from './views/Profile';

import SystemSettings from './views/SystemSettings/SystemSettings';

// import forms from "./views/forms/forms";
import forms from './views/forms/forms';
import CaseNotes from "./views/case/CaseNotes";
import { Help } from "./views/Help";


const routes = {

    '/login': { component: Login, default: 1 },
    '/forgot-password': { component: ForgotPassword },
    '/reset-password/:token': { component: ResetPassword },
    '/register': { component: ResetPassword },
    '/#': { path: '/', component: ChangePasswordOnLogin, default: 18, requires: 'changePassword' },
    '/': { exact: true, component: CaseInbox, default: 10, requires: 'login' },
    '/cases': { exact: true, component: CaseInbox, requires: 'login' },
    '/cases/:id': { exact: true, component: CaseDetails, requires: 'login' },
    '/cases/:id/history': { exact: true, component: CaseHistory, requires: 'login' },
    '/cases/:id/assignments': { exact: true, component: CaseAssignments, requires: 'login' },
    '/cases/:id/notes': { exact: true, component: CaseNotes, requires: 'login' },

    ...(Object.entries(forms).reduce((_, [path, component]) => {
        _[`/${path}`] = {
            component,
        };
        return _;
    }, {})),

    '/users/create': { component: CreateNewUser, exact: true, requires: 'login' },
    '/users/:id': { component: EditUser, exact: true, requires: 'login' },
    '/users': { component: UsersTable, exact: true, requires: 'login' },

    '/universal-filter': { exact: true, component: CaseUniversalFilter, requires: 'login' },

    '/profile': { component: Profile, exact: true, requires: 'login' },

    '/system-settings': { component: SystemSettings, exact: true, requires: 'login' },
    '/system-settings/:setting': { component: SystemSettings, requires: 'login' },
    '/support': { component: Help, requires: 'login' }
}


export default routes;