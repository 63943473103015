import React, { useContext } from 'react';
import { Button, CardBody, Card, ListGroup, ListGroupItem } from 'reactstrap';
import {AuthContext} from '../context/AuthContext';
import useTitle from "../util/useTitle";
import { useZohoDeskWidgetCallFunction } from "../util/useZohoDeskWidget";

export function Help(props) {
    useTitle("Ayuda | JOLI");

    const zdwcf = useZohoDeskWidgetCallFunction();
    const { isAdmin } = useContext(AuthContext) || {};
    return (<>
        <div>
            <h1>Documentaci&oacute;n</h1><br />
            <p>Aquí puedes encontrar los documentos de apoyo para JOLI. </p>
            <h3>Documentos</h3>
            <ListGroup>
                <ListGroupItem>
                    <a href="https://joli-public.s3.amazonaws.com/Manual+de+Usuario+JOLI.pdf" target="_blank" rel="noreferrer">Manual de Usuario</a>
                </ListGroupItem>
                <ListGroupItem>
                    <a href="https://joli-public.s3.amazonaws.com/Preguntas+Frecuentes+JOLI.pdf" target="_blank" rel="noreferrer">Preguntas Frecuentes</a>
                </ListGroupItem>
            </ListGroup>
            <br />
            <h3>Videos de Entrenamiento</h3>
            <div className="d-flex flex-wrap flex-row">
                <Card className="mb-4">
                    <CardBody>
                        <h4 className="text-default">Mancomunado</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ySN-IZu0Pig" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Manejo desde Celular</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/EwyglCkyLvI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Cómo crear caso personal individual</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/Tk0PdkAoKb8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Cómo crear casos mancomunados</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/tyhqTJYdnwg" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Cómo crear caso Comercial o PYME</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/KCuB8kJiQgs" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Cómo reasignar un caso</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/ic-XulAnHdc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Cómo agregar documentos adicionales</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/tpR8IjmPtg8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Cómo filtrar tus casos pendientes</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/T4jIpBRCUKA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Cómo filtrar casos completados</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/UnwYv70gxww" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
                <Card className="">
                    <CardBody>
                        <h4 className="text-default">Crear Ticket desde error en Caso</h4>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/1wWjA9zT1Ts" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                    </CardBody>
                </Card>
            </div>
            <br />
            {isAdmin ? (
                <div>
                    <h3>Crear un Ticket</h3>
                    <p>¿Necesitas ayuda directo al equipo? Crea un ticket.</p>
                    <Button onClick={() => zdwcf()} color="primary">
                        Crear Ticket
                    </Button>
                </div>
            ) : null}
        </div>
    </>);
}

export default Help;