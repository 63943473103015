const form = {
    title: "Creación de Préstamo",
    displayName: 'LoanCreationConfirmation',
    page: 'loan-creation-confirmation',
    commentFieldsMap: {
        "Prestamo": "caseLoanInfo.loanCreationComments",
    },
    isSentBack(object) {
        return object.caseLoanInfo.loanCreationConfirmation === 2;
    }, // Devuelto
};


form.schema = {
    type: "object",
    properties: {
        "usoExclusivoOperaciones": {
            title: "Para uso Exclusivo de Operaciones",
            type: "object",
            properties: {
                "montoDesembolsado": {
                    title: "Monto Aprobado:",
                    type: "string"
                },
                "numeroPrestamo": {
                    title: "No. de Préstamo:",
                    type: "string"
                },
                "CIIU": {
                    title: "CIIU:",
                    type: "string"
                },
                "garantiaObtenida": {
                    title: "Garantía Obtenida:",
                    type: "string"
                },
                "numeroGarantia": {
                    title: "No. de Garantía:",
                    type: "string"
                },
                "otrasObsevaciones": {
                    title: "Otras Observaciones:",
                    type: "string"
                },
                "scad": {
                    title: "Forma de desembolso",
                    type: "object",
                    properties: { "check": { title: "Cuenta a desembolsar", type: "boolean" } },
                    dependencies: {
                        "check": {
                            allOf: [{
                                if: { properties: { "check": { const: true } } },
                                then: {
                                    properties: {
                                        "infoDesembolso": {
                                            title: "Cuenta a desembolsar:",
                                            type: "string"
                                        }
                                    }
                                }
                            }]
                        }
                    }
                },
                "stranf": {
                    title: "",
                    type: "object",
                    properties: { "check": { title: "Transferencia", type: "boolean" } },
                    dependencies: {
                        "check": {
                            allOf: [{
                                if: { properties: { "check": { const: true } } },
                                then: {
                                    properties: {
                                        "infoTransferencia": {
                                            title: "Transferencia:",
                                            type: "string"
                                        }
                                    }
                                }
                            }]
                        }
                    }
                },
                "scheck": {
                    title: "",
                    type: "object",
                    properties: { "check": { title: "Cheque", type: "boolean" } },
                    dependencies: {
                        "check": {
                            allOf: [{
                                if: { properties: { "check": { const: true } } },
                                then: {
                                    properties: {
                                        "infoCheque": {
                                            title: "Cheque:",
                                            type: "string"
                                        }
                                    }
                                }
                            }]
                        }
                    }
                },
            },
            required: ["montoDesembolsado", "numeroPrestamo"],
        },
        "creacionSolicitudDesembolso": {
            title: " ",
            type: "object",
            properties: {
                "confirmacionCreacion": {
                    title: "Confirmación de creación",
                    type: "number",
                    enumNames: ["Creado en T24", "Devuelto"],
                    enum: [1, 2],
                },
                "comentarios": {
                    title: "Comentarios",
                    type: "string",
                },
            }
        },
    },
    required: []
};



form.uiSchema = {
    "usoExclusivoOperaciones": {
        "selecciones": {
            "ui:widget": "checkboxes",
            "ui:options": {
                inline: false
            },
        },
        "otrasObsevaciones": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 2
            },
        }
    },
    "creacionSolicitudDesembolso": {
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
    }
}

form.objectMap = {
    // Fields not implemented:
    //      
    //      
    //  
    //  
    //  Fields with wrong type in db:
    //      
    //
    "caseLoanInfo.loanCreationConfirmation": "creacionSolicitudDesembolso.confirmacionCreacion",
    "caseLoanInfo.loanCreationComments": "creacionSolicitudDesembolso.comentarios",
    "clients[0].retailClientLoanInfo.operationsExclusive.disbursedAmount": "usoExclusivoOperaciones.montoDesembolsado",
    "clients[0].retailClientLoanInfo.operationsExclusive.loanNumber": "usoExclusivoOperaciones.numeroPrestamo",
    "clients[0].retailClientLoanInfo.operationsExclusive.ciiu": "usoExclusivoOperaciones.CIIU",
    "clients[0].retailClientLoanInfo.operationsExclusive.obtainedWarranty": "usoExclusivoOperaciones.garantiaObtenida",
    "clients[0].retailClientLoanInfo.operationsExclusive.warrantyNumber": "usoExclusivoOperaciones.numeroGarantia",
    "clients[0].retailClientLoanInfo.operationsExclusive.otherObservations": "usoExclusivoOperaciones.otrasObsevaciones",
    "clients[0].retailClientLoanInfo.operationsExclusive.disbursementInfo": "usoExclusivoOperaciones.scad.infoDesembolso",
    "clients[0].retailClientLoanInfo.operationsExclusive.disbursementInfoCheck": "usoExclusivoOperaciones.scad.check",
    "clients[0].retailClientLoanInfo.operationsExclusive.transferInfo": "usoExclusivoOperaciones.stranf.infoTransferencia",
    "clients[0].retailClientLoanInfo.operationsExclusive.transferInfoCheck": "usoExclusivoOperaciones.stranf.check",
    "clients[0].retailClientLoanInfo.operationsExclusive.checkInfo": "usoExclusivoOperaciones.scheck.infoCheque",
    "clients[0].retailClientLoanInfo.operationsExclusive.checkInfoCheck": "usoExclusivoOperaciones.scheck.check",
}


export default form;