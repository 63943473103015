// import { Trans } from '@lingui/react';
import React from 'react';
import { Redirect, useParams } from 'react-router';
import UserApi from "../../api/UserApi";
import FormComponent from '../../components/FormComponent';
import { FORM_UPDATE_SUBMIT_BUTTON } from '../../constants';

const EditUser = FormComponent({
    displayName: 'EditUser',
    withoutNavigation: true,
    schema: {
        title: "Usuario",
        type: "object",
        properties: {
            firstName: { title: "Nombre", type: "string" },
            lastName: { title: "Apellido", type: "string" },
            email: { title: "Email", type: "string" },
            password: { title: "Password", type: "string" },
            passwordConfirm: { title: "Confirm Password", type: "string" },
            changePasswordOnNextLogin: { title: "Cambiar contraseña en próximo login", type: "boolean" },
            roleId: {
                title: "Rol",
                type: "number",
                lookup: {
                    api: 'roles',
                    resource: "Roles",
                    id: 'id',
                    label: 'displayName'
                }
            },
            branchId: {
                title: "Oficina",
                type: "number",
                lookup: {
                    api: 'branches',
                    resource: "Oficinas",
                    id: 'id',
                    label: 'name'
                }
            },
            isActive: {
                title: "Activo",
                type: "boolean"
            }
        },
        required: ["firstName", "lastName", "email", "roleId", "branchId"],
        dependencies: {
            password: ["passwordConfirm"]
        }
    },
    uiSchema: {
        "classNames": "smallTwoRows",
        "password": { "classNames": "from-col-1", "ui:widget": "password" },
        "passwordConfirm": { "ui:widget": "password" },
        "branchId": { "ui:field": "LookupFormField" },
        "changePasswordOnNextLogin": { "classNames": "from-col-1 to-col-3" },
        "roleId": { "classNames": "from-col-1", "ui:field": "LookupFormField" },
        "isActive": { "classNames": "from-col-1" },
    },
    validate({password, passwordConfirm}, errors){
        if (password && password !== passwordConfirm) {
            errors.passwordConfirm.addError("Password es diferente.");
        }
        return errors;
    },
    parseProps: () => ({
        params: useParams()
    }),
    loadData: ({ params: { id } }) => UserApi.getUser({ id }),
    async onSubmit({ formData }, { props: { params: { id } } }) {
        await UserApi.editUser({id, ...formData});
    },
    submitButtons: FORM_UPDATE_SUBMIT_BUTTON, 
    renderFormSubmitted: () => (
        <Redirect to="/users" />
    )
});

export default EditUser;