const form = {
    title: "Creación Cliente Sistemas",
    displayName: 'InvestmentClientCreationConfirmation',
    page: 'investment-client-creation-confirmation',
};


form.schema = {
    title: "Creación de cliente JMMB Puesto de Bolsa y JMMB SAFI",
    type: "object",
    properties: {
        "infCliDIMSA": {
            title: " ",
            description: "Informaciones Cliente DIMSA",
            type: "object",
            properties: {
                "cliDIMSA": {
                    title: "Cliente creado en DIMSA",
                    type: "boolean",
                },
                "numCliDIMSA":
                {
                    title: "Número de cliente en DIMSA",
                    type: "string"
                },
            },

        },
        "infCliT24SAFI": {
            title: " ",
            description: "Informaciones Cliente T24SAFI",
            type: "object",
            properties: {
                "cliT24SAFI": {
                    title: "Cliente creado en T24 SAFI",
                    type: "boolean",
                },
                "numCliT24SAFI":
                {
                    title: "Número de cliente en T24 SAFI",
                    type: "string"
                },

            },

        },
    },
    dependencies: {
    },
}

form.uiSchema = {
    "ui:field": "HideObjectField",
    "infCliDIMSA": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 2
            }
        }
    },
    "infCliT24SAFI": {
        "joli:hideAttr": {
            "caseData": {
                "productInstitutions": 3
            }
        }
    },
}

form.objectMap = {
    "clients[$idx].retailClientInvestorProfile.clientCreatedDIMSA": "infCliDIMSA.cliDIMSA",
    "clients[$idx].retailClientInvestorProfile.dimsaClientId": "infCliDIMSA.numCliDIMSA",
    "clients[$idx].retailClientInvestorProfile.clientCreatedT24SAFI": "infCliT24SAFI.cliT24SAFI",
    "clients[$idx].retailClientInvestorProfile.t24safiClientId": "infCliT24SAFI.numCliT24SAFI",
}


export default form;