import React, {useMemo, useContext} from 'react';
import {Switch, Redirect, Route} from 'react-router';
import {AuthContext} from './context/AuthContext';
import Layout from './components/Layout';
import routes from './routes';


function App() {
    const auth = useContext(AuthContext);

    const allowedRoutes = useMemo(() => Object.entries(routes).filter(
      entry => checkRequirements(auth, entry[1].requires)
    ), [auth]);

    const defaultRoute = useMemo(() => (
        allowedRoutes.filter(entry => entry[1].default).sort(
          (a, b) => b[1].default - a[1].default
        )[0]
    ), [allowedRoutes]);

    return (
      <Layout><Switch>
        {allowedRoutes.map(([path, {path: path2, requires, ...route}], idx) => (
          <Route key={idx} path={path2 || path} {...route} />
        ))}
        {defaultRoute ? (
          <Redirect to={defaultRoute[0]} />
        ) : null}
      </Switch></Layout>
    );
}


function checkRequirements({user}, requires){
    if (requires === 'login') {
        return !!user;
    } else if (requires === 'changePassword') {
      return user?.user?.changePasswordOnNextLogin;
    }

    return true;
}


export default App;