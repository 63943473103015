import {
    boolYesNoType, multiFileUploadField,
} from '../commons';
import secondaryClientForm from '../secondary/ComplianceCaseReviewSchema';


const form = {
    title: "DD Cumplimiento",
    displayName: 'ComplianceCaseReview',
    page: 'compliance-case-review',
    commentFieldsMap: {
        "Comentarios Area": "clients[0].retailClientDueDiligenceReport.areaComments",
        "Comentarios Cumplimiento": "clients[0].retailClientDueDiligenceReport.comments",
    },
    secondaryClientForm,
    isSentBack(object) {
        return [
            "Devuelto",
            "Requerimiento de información adicional"
        ].indexOf(object.clients[0].retailClientDueDiligenceReport.dueDiligenceResult) !== -1;
    }
};


form.schema = {
    title: "Debida Diligencia Persona Física - Cumplimiento",
    type: "object",
    properties: {
        "fechaSolicitud": {
            title: "Fecha de solicitud",
            type: "string",
            format: "date"
        },
        "tipoRequerimiento": {
            title: "Tipo de requerimiento",
            type: "string",
        },
        "tipoDebidaDiligencia": {
            title: "Tipo de Debida Diligencia",
            type: "string",
            enumNames: ["Debida Diligencia Ampliada", "Persona Física con ingresos anuales inferiores o iguales a DOP 1,000,000.00", "Personas Físicas con ingresos anuales mayores a DOP 1,000,001.00", "Empleados Grupo JMMB"],
            enum: ["Debida Diligencia Ampliada", "Persona Fisica con ingresos anuales inferiores o iguales a DOP 1,000,000.00", "Personas Fisicas con ingresos anuales mayores a DOP 1,000,001.00", "Empleados Grupo JMMB"]
        },
        "tipoPersona": {
            title: "Tipo de Persona",
            type: "number",
            enum: [0, 1, 2, 3, 4],
            enumNames: [" ","Nacional", "Menores de Edad", "Extranjero Residente", "Extranjero No Residente",],
        },
        "tipoCliente": {
            title: "Tipo de Cliente",
            type: "number",
            enum: [0, 1, 2],
            enumNames: [" ","Persona Física", "Persona Jurídica"],
        },
        "nivelRiesgo": {
            title: "Nivel de Riesgo",
            type: "number",
            enumNames: ["Alto", "Medio", "Bajo"],
            enum: [3, 2, 1]
        },
        "PEP": {
            title: "Persona Políticamente Expuesta (PEP)",
            type: "string",
            enum: ["Si, es PEP y proporcionó DJP", "Si, es PEP por Vinculación",
                "No es PEP", "Dejo de ser PEP acorde a la Ley",
                "La coincidencia PEP no es acorde a la Ley, No sera considerado una PEP",],
        },
        "beneficiarioFinal": {
            title: "Beneficiario Final",
            type: "object",
            properties: {
                "identificacionBeneficiarioFinal": {
                    title: "Identificación de Beneficiario Final",
                    type: "number",
                    enum: [0, 1, 2, 3],
                    enumNames: [" ","Si", "No", "No Aplica- Cliente es el único beneficiario",],
                },
            }
        },
        "procedenciaDestinoFondos": {
            title: "Procedencia y destino de Fondos",
            type: "object",
            properties: {
                "origenFondos": {
                    title: "Origen de los Fondos",
                    ...boolYesNoType
                },
                "destinoFondos": {
                    title: "Destino de Fondos",
                    type: "number",
                    enum: [0, 1, 2, 3],
                    enumNames: [" ","Si", "No", "No Aplica"],

                },
            },
        },
        "validacionAnexos": {
            title: "Validación de anexos",
            type: "object",
            properties: {
                "consultasListas": { title: "Consultas en Listas", type: "boolean" },
                "matrizRiesgo": { title: "Matriz de Riesgo", type: "boolean" }
            },
        },
        "debidaDiligenciaCumplimiento": {
            title: "Debida Diligencia Cumplimiento",
            type: "object",
            properties: {
                "revisionEnListas": {
                    title: " ",
                    description: "Revisión en listas",
                    type: "object",
                    properties: {
                        "title1": { title: "Compliance Screening (World Check)", type: "null", },
                        "complianceScreening": multiFileUploadField.schema,
                        "resultadoConsultaWorldCheck": {
                            title: "Resultado Consulta World Check",
                            type: "string",
                            enum: [
                                "Sin novedad",
                                "Resultados son Falsos Positivos",
                                "Los resultados son positivos, se requiere documentacion adicional",
                                "Los resultados indican que es una PEP"
                            ],
                        },
                        "title2": { title: "Open Corporates", type: "null", },
                        "openCorporates": multiFileUploadField.schema,
                        "resultadoConsultaOpenCorporates": {
                            title: "Resultado Consulta Open Corporates",
                            type: "string",
                            enum: [
                                "Sin novedad",
                                "Resultados son Falsos Positivos",
                                "Los resultados son positivos, se requiere documentacion adicional",
                                "Los resultados indican que es una PEP"
                            ],
                        },
                        "title3": { title: "Consulta Web", type: "null", },
                        "consultaWeb": multiFileUploadField.schema,
                        "resultadoConsultaWeb": {
                            title: "Resultado Consulta Web",
                            type: "string",
                        },
                    },
                },
                "observacionesAreaCumplimiento": {
                    title: "Observaciones Área de Cumplimiento",
                    type: "string",
                },
                "fechaRevision": {
                    title: "Fecha de revisión",
                    type: "string",
                    format: "date",
                },
                "resultadoDebidaDiligencia": {
                    title: "Resultado Debida Diligencia",
                    type: "string",
                    enum: [
                        "Aprobado",
                        "Rechazado",
                        "Aprobado con excepción",
                        "Devuelto",
                        "Requerimiento de información adicional",
                    ],
                },
            },
            dependencies: {
                "resultadoDebidaDiligencia": {
                    oneOf: [
                        {
                            properties: {
                                "resultadoDebidaDiligencia": {
                                    const: "Devuelto"
                                },
                                "razonDevolucion": {
                                    title: "Razon de Devolución",
                                    type: "string",
                                    enum: [
                                        "Documento cargado no cumple con requerimientos",
                                        "Documento o información faltante",
                                        "Error en digitación de información",
                                        "Información incorrecta",
                                        "Otros",
                                    ],
                                },
                                "comentarios": {
                                    title: "Comentarios Internos o Devolución",
                                    type: "string",
                                },
                            },
                        },
                        {
                            properties: {
                                "resultadoDebidaDiligencia": {
                                    const: "Aprobado con excepción"
                                },
                                "comentarios": {
                                    title: "Comentarios Internos o Devolución",
                                    type: "string",
                                },
                            },
                        },
                        { properties: { "resultadoDebidaDiligencia": { const: "Aprobado" } } },
                        { properties: { "resultadoDebidaDiligencia": { const: "Rechazado" } } },
                        {
                            properties: {
                                "resultadoDebidaDiligencia": { const: "Requerimiento de información adicional" },
                                "comentarios": {
                                    title: "Información adicional solicitada",
                                    type: "string",
                                },
                            }
                        },
                    ],
                },
            },
            required: ["resultadoDebidaDiligencia", "fechaRevision"]
        },
    },
    required: [],
    dependencies: {
        "tipoPersona": {
            oneOf: [
                {
                    properties: {
                        "tipoPersona": {
                            const: 0
                        },
                    }
                },
                {
                    properties: {
                        "tipoPersona": {
                            const: 1
                        },
                        "validacionDocumentosRequeridos": {
                            title: "Validación de Documentos Requeridos",
                            type: "object",
                            properties: {
                                "nacional": {
                                    title: "Nacional",
                                    type: "object",
                                    properties: {
                                        "copiaCedula": {
                                            title: "Copia de cédula de Identidad y Electoral (ambos lados)",
                                            type: "boolean",
                                        },
                                        "documentosFuenteIngresos": {
                                            title: "Documentos que comprueben la fuente de ingresos",
                                            type: "boolean",
                                        },
                                        "segundaIdentificacion": {
                                            title: "Segunda Identificación (si aplica DDA)",
                                            type: "boolean",
                                        },
                                        "reporteInfoCredenticia": {
                                            title: "Reporte de Información Crediticia",
                                            type: "boolean",
                                        },
                                    },
                                },
                            }
                        },
                    }
                },
                {
                    properties: {
                        "tipoPersona": {
                            const: 2
                        },
                        "validacionDocumentosRequeridos": {
                            title: "Validación de Documentos Requeridos",
                            type: "object",
                            properties: {
                                "menoresEdad": {
                                    title: " ",
                                    description: "Menores de Edad",
                                    type: "object",
                                    properties: {
                                        "cedulaIdentidadMenor": { title: "Copia de cédula de Identidad de menor", type: "boolean" },
                                        "numUnicoIdentidad": { title: "Entre 14 y 17 años, Número Unico de Identidad (NIIU)", type: "boolean" },
                                        "actaNacimiento": { title: "Acta de Nacimiento del Menor u otro documento que evidencie la relación con el menor", type: "boolean" },
                                        "poderRepresentativo": { title: "Documento Legal donde el tutor legal otorga poder de representación", type: "boolean" },
                                        "sentenciaAcreditada": { title: "Sentencia que acredita al representante como tutor", type: "boolean" }
                                    },
                                },
                            }
                        },
                    }
                },
                {
                    properties: {
                        "tipoPersona": {
                            const: 3
                        },
                        "validacionDocumentosRequeridos": {
                            title: "Validación de Documentos Requeridos",
                            type: "object",
                            properties: {
                                "extranjeroResidente": {
                                    title: " ",
                                    description: "Extranjero Residente",
                                    type: "object",
                                    properties: {
                                        "cedulaIdentidad": { title: "Copia de cédula de Identidad de residente permanente o temporal", type: "boolean" },
                                        "pasaporteVigente": { title: "Pasaporte vigente, incluyendo sellos de entrada y salida", type: "boolean" },
                                        "imagenIdentificacion": { title: "Imagen de una o más documentos de identificación de su país de origen", type: "boolean" },
                                        "declaracionImpuesto": { title: "Declaración de Impuesto de su país de origen", type: "boolean" },
                                        "fuenteIngresos": { title: "Documentos que comprueben la fuente de sus ingresos", type: "boolean" }
                                    },
                                },
                            }
                        },
                    }
                },
                {
                    properties: {
                        "tipoPersona": {
                            const: 4
                        },
                        "validacionDocumentosRequeridos": {
                            title: "Validación de Documentos Requeridos",
                            type: "object",
                            properties: {
                                "extranjeroNoResidente": {
                                    title: " ",
                                    description: "Extranjero No Residente",
                                    type: "object",
                                    properties: {
                                        "estatusMigratorio": { title: "Documento emitido por el Organismo Oficial correspondiente a su estatus migratorio", type: "boolean" },
                                        "pasaporteVigente": { title: "Pasaporte vigente, incluyendo sellos de entrada y salida", type: "boolean" },
                                        "imagenIdentificacion": { title: "Imagen de una o más documentos de identificación de su país de origen", type: "boolean" },
                                        "declaracionImpuesto": { title: "Declaración de Impuesto de su país de origen", type: "boolean" },
                                        "fuenteIngresos": { title: "Documentos que comprueben la fuente de sus ingresos", type: "boolean" }
                                    },
                                },
                            }
                        },
                    }
                },
            ]
        },
        "PEP": {
            oneOf: [
                {
                    properties: {
                        "PEP": {
                            const: "Si, es PEP y proporcionó DJP"
                        },
                        "personasPoliticamenteExpuestas": {
                            title: "Personas Políticamente Expuestas",
                            type: "object",
                            properties: {
                                "documentosTipoPersona": { title: "Documento requeridos de acuerdo al tipo de persona", type: "boolean" },
                                "declaracionJurada": { title: "Declaración Jurada de Patrimonio o decreto que lo designa al cargo público", type: "boolean" }
                            },
                        }
                    }
                },
                {
                    properties: {
                        "PEP": {
                            const: "Si, es PEP por Vinculación"
                        },
                        "personasPoliticamenteExpuestas": {
                            title: "Personas Políticamente Expuestas",
                            type: "object",
                            properties: {
                                "documentosTipoPersona": { title: "Documento requeridos de acuerdo al tipo de persona", type: "boolean" },
                                "declaracionJurada": { title: "Declaración Jurada de Patrimonio o decreto que lo designa al cargo público", type: "boolean" }
                            },
                        }
                    }
                },
                {
                    properties: {
                        "PEP": {
                            const: "No es PEP"
                        }
                    }
                },
                {
                    properties: {
                        "PEP": {
                            const: "Dejo de ser PEP acorde a la Ley"
                        }
                    }
                },
                {
                    properties: {
                        "PEP": {
                            const: "La coincidencia PEP no es acorde a la Ley, No sera considerado una PEP"
                        }
                    }
                },
            ]
        },
    },
};



form.uiSchema = {
    "ui:order": [
        "*",
        "beneficiarioFinal",
        "procedenciaDestinoFondos",
        "validacionAnexos",
        "debidaDiligenciaCumplimiento",
    ],
    "validacionDocumentosRequeridos": {
        "nacional": { "ui:widget": "checkboxes", },
        "menoresEdad": { "ui:widget": "checkboxes", },
        "extranjeroResidente": { "ui:widget": "checkboxes", },
        "extranjeroNoResidente": { "ui:widget": "checkboxes", },
    },
    "procedenciaDestinoFondos": {
        "origenFondos": {
            "ui:widget": "radio",
            "ui:options": {
                inline: true
            },
        },
    },
    "validacionAnexos": {
        "ui:widget": "checkboxes",
    },
    "debidaDiligenciaCumplimiento": {
        "observacionesAreaCumplimiento": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 4
            },
        },
        "revisionEnListas": {
            "complianceScreening": multiFileUploadField.uiSchema,
            "openCorporates": multiFileUploadField.uiSchema,
            "consultaWeb": multiFileUploadField.uiSchema,
        }

    },
}

form.objectMap = {
    // Fields not implemented:
    "clients[0].retailClientDueDiligenceReport.complianceScreeningFileList.items": multiFileUploadField.mapping(
        'debidaDiligenciaCumplimiento.revisionEnListas.complianceScreening'
    ),
    "clients[0].retailClientDueDiligenceReport.openCorporatesFileList.items": multiFileUploadField.mapping(
        "debidaDiligenciaCumplimiento.revisionEnListas.openCorporates"
    ),
    "clients[0].retailClientDueDiligenceReport.webSearchFileList.items": multiFileUploadField.mapping(
        "debidaDiligenciaCumplimiento.revisionEnListas.consultaWeb"
    ),
    "clients[0].retailClientDueDiligenceReport.webSearchResult": "debidaDiligenciaCumplimiento.revisionEnListas.resultadoConsultaWeb",

    "clients[0].retailClientDueDiligenceReport.inOFACList": true,
    "clients[0].retailClientDueDiligenceReport.inONUList": true,
    "clients[0].retailClientDueDiligenceReport.riskLevel": "nivelRiesgo",
    "clients[0].retailClientDueDiligenceReport.personType": "tipoPersona",
    "clients[0].retailClientDueDiligenceReport.clientType": "tipoCliente",
    "clients[0].retailClientDueDiligenceReport.politicalExposedPerson": "PEP",
    "clients[0].retailClientDueDiligenceReport.applicationDate": "fechaSolicitud",
    "clients[0].retailClientDueDiligenceReport.requirementType": "tipoRequerimiento",
    "clients[0].retailClientDueDiligenceReport.dueDiligenceType": "tipoDebidaDiligencia",

    "clients[0].retailClientDueDiligenceReport.nationalCopyOfId": "validacionDocumentosRequeridos.nacional.copiaCedula", // true
    "clients[0].retailClientDueDiligenceReport.nationalIncomeDocument": "validacionDocumentosRequeridos.nacional.documentosFuenteIngresos", // true
    "clients[0].retailClientDueDiligenceReport.nationalSecondFormOfId": "validacionDocumentosRequeridos.nacional.segundaIdentificacion", // true
    "clients[0].retailClientDueDiligenceReport.nationalCreditReport": "validacionDocumentosRequeridos.nacional.reporteInfoCredenticia", // true

    "clients[0].retailClientDueDiligenceReport.residentAlienCopyOfResidenceCard": "validacionDocumentosRequeridos.extranjeroResidente.cedulaIdentidad", // true
    "clients[0].retailClientDueDiligenceReport.residentAlienCopyOfPassport": "validacionDocumentosRequeridos.extranjeroResidente.pasaporteVigente", // true
    "clients[0].retailClientDueDiligenceReport.residentAlienNationalityId": "validacionDocumentosRequeridos.extranjeroResidente.imagenIdentificacion", // true
    "clients[0].retailClientDueDiligenceReport.residentAlienCountryOfOriginTaxes": "validacionDocumentosRequeridos.extranjeroResidente.declaracionImpuesto", // true
    "clients[0].retailClientDueDiligenceReport.residentAlientIncomeReport": "validacionDocumentosRequeridos.extranjeroResidente.fuenteIngresos", // true

    "clients[0].retailClientDueDiligenceReport.underageCopyOfId": "validacionDocumentosRequeridos.menoresEdad.cedulaIdentidadMenor", // true
    "clients[0].retailClientDueDiligenceReport.underageNIIU": "validacionDocumentosRequeridos.menoresEdad.numUnicoIdentidad", // true
    "clients[0].retailClientDueDiligenceReport.underageBirthCertificate": "validacionDocumentosRequeridos.menoresEdad.actaNacimiento", // true
    "clients[0].retailClientDueDiligenceReport.underagePowerOfAttorney": "validacionDocumentosRequeridos.menoresEdad.poderRepresentativo", // true
    "clients[0].retailClientDueDiligenceReport.underageTutorRepresentation": "validacionDocumentosRequeridos.menoresEdad.sentenciaAcreditada", // true

    "clients[0].retailClientDueDiligenceReport.nonResidentAlienCopyOfResidenceCard": "validacionDocumentosRequeridos.extranjeroNoResidente.estatusMigratorio", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlienCopyOfPassport": "validacionDocumentosRequeridos.extranjeroNoResidente.pasaporteVigente", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlienNationalityId": "validacionDocumentosRequeridos.extranjeroNoResidente.imagenIdentificacion", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlienCountryOfOriginTaxes": "validacionDocumentosRequeridos.extranjeroNoResidente.declaracionImpuesto", // true
    "clients[0].retailClientDueDiligenceReport.nonResidentAlientIncomeReport": "validacionDocumentosRequeridos.extranjeroNoResidente.fuenteIngresos", // true

    "clients[0].retailClientDueDiligenceReport.pepDocumentsRequired": "validacionDocumentosRequeridos.personasPoliticamenteExpuestas.documentosTipoPersona", // true
    "clients[0].retailClientDueDiligenceReport.pepNetWorthDeclaration": "validacionDocumentosRequeridos.personasPoliticamenteExpuestas.declaracionJurada", // true

    "clients[0].retailClientDueDiligenceReport.finalBeneficiary": "beneficiarioFinal.identificacionBeneficiarioFinal",// must be number
    "clients[0].retailClientDueDiligenceReport.isFundsOrigin": "procedenciaDestinoFondos.origenFondos", // true
    "clients[0].retailClientDueDiligenceReport.fundsDestination": "procedenciaDestinoFondos.destinoFondos",// must be number

    "clients[0].retailClientDueDiligenceReport.anexedListConsultations": "validacionAnexos.consultasListas", // true
    "clients[0].retailClientDueDiligenceReport.anexedRiskMatrix": "validacionAnexos.matrizRiesgo", // true

    "clients[0].retailClientDueDiligenceReport.worldCheckResult": "debidaDiligenciaCumplimiento.revisionEnListas.resultadoConsultaWorldCheck",
    "clients[0].retailClientDueDiligenceReport.openCorporateResult": "debidaDiligenciaCumplimiento.revisionEnListas.resultadoConsultaOpenCorporates",
    "clients[0].retailClientDueDiligenceReport.areaComments": "debidaDiligenciaCumplimiento.observacionesAreaCumplimiento",
    "clients[0].retailClientDueDiligenceReport.revisionDate": "debidaDiligenciaCumplimiento.fechaRevision",
    "clients[0].retailClientDueDiligenceReport.dueDiligenceResult": "debidaDiligenciaCumplimiento.resultadoDebidaDiligencia",
    "clients[0].retailClientDueDiligenceReport.dueDiligenceCaseReturnReason": "debidaDiligenciaCumplimiento.razonDevolucion",
    "clients[0].retailClientDueDiligenceReport.comments": "debidaDiligenciaCumplimiento.comentarios"
}


export default form;