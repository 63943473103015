import React, {useMemo} from "react";
import {
    FormGroup,
    Table
} from "reactstrap";
import { useSideChannelSubscription } from "../../util/useSideChannel";
import LookupApi from "../../api/LookupApi";
import { clientTypeId } from "../../form_schemas/commons";
import { mapObject } from "../../util/mapObject";
import useResourceLoader from "../../util/useResourceLoader";

function CalcField(props) {
    const {
        formContext: {
            sideChannel,
            // formData
        },
        schema: { title },
    } = props;

    const [products] = useResourceLoader(async () => {
        const inst = await LookupApi.getInstitutions();
        const instprods = await Promise.all(inst.map(i => LookupApi.getProducts({institution: i.id})));
        return instprods.reduce((_, prods) => {
            prods.forEach(p => {_[p.id] = p;});
            return _;
        }, {});
    }, [], {});

    const formData = useSideChannelSubscription(sideChannel) || {};

    const mapped = mapObject(formData, {
        'tipoCliente': 'tipoCliente',
        'zonaGeograficaNacional': 'datosEmpresa.zonaGeograficaNacional',
        'pep': 'personaPoliticamenteExpuesta.personaExpuestaPoliticamente',
        'pepParentesco': 'personaPoliticamenteExpuesta.parentescoConPersonaExpuestaPoliticamente',
        'categoria': 'kycCommercialClientSchema.resultadoPerfil.clienteProfesional',
        'ocupacion': 'datosEmpresa.ocupacion',
        'cargoIndependiente': 'datosLaboralesSchema.cargo',
        'paisOrigen': 'datosEmpresa.pais',
        'paisResidencia': 'datosEmpresa.pais',
        'productsBanco': 'kycCommercialClientSchema.productosBanco',
        'productsPuesto': 'kycCommercialClientSchema.productosPuesto',
        'productsSAFI': 'kycCommercialClientSchema.productosSAFI',
        'duracionRelacion': 'duracionRelacion',
        'ingresosAnuales': 'situacionFinanciera.ingresosAnuales',

        'cii_rdsAmtPuesto': 'kycCommercialClientSchema.inversionesSchema.inversionEstimadaRD',
        'cii_usdAmtPuesto': 'kycCommercialClientSchema.inversionesSchema.inversionEstimadaUSD',
        'cci_exRatePuesto': 'kycCommercialClientSchema.inversionesSchema.tasaCambio',
        
        'cii_rdsAmtFinCert': 'kycCommercialClientSchema.financialCertSchema.inversionEstimadaRD',
        
        'cii_usdAmtSAFI': 'kycCommercialClientSchema.safiSchema.inversionEstimadaUSD',
        'cii_rdsAmtSAFI': 'kycCommercialClientSchema.safiSchema.inversionEstimadaRD',
        'cci_exRateSAFI': 'kycCommercialClientSchema.safiSchema.tasaCambio',
        
        'client_rcbi_savAccDepAmt': 'kycCommercialClientSchema.savingsAccountSchema.depositoEstimadoRD',
        'cli_loanAmount': 'kycCommercialClientSchema.solicitudPrestamoSchema.montoDeseadoPrestamo',
        
        'relacionCaraCara': 'resultadoPerfil.relacionCaraCara',
    });

    const actividadEconomica = useMemo(() => (
        mapped.ocupacion !== 'Independiente' ? mapped.ocupacion : mapped.cargoIndependiente
    ), [mapped.ocupacion, mapped.cargoIndependiente]);
    const pep = useMemo(() => (mapped.pep ? 'PEP': mapped.pepParentesco ? 'PEP': 'No PEP'), [mapped.pep, mapped.pepParentesco]);
    const duracionDeLaRelacion = useMemo(() => (
        mapped.duracionRelacion
    ), [mapped.duracionRelacion]);
    const productoSolicitado = useMemo(() => {
        // (clientTypeId.oneOf.find(ct => ct.const === mapped.tipoCliente) || {}).title
        let result = []
        if (mapped.productsBanco){
            result = result.concat(mapped.productsBanco.map(pid => (products[pid] || {}).name))
        }
        if (mapped.productsPuesto){
            result = result.concat(mapped.productsPuesto.map(pid => (products[pid] || {}).name))
        }
        if (mapped.productsSAFI){
            result = result.concat(mapped.productsSAFI.map(pid => (products[pid] || {}).name))
        }
        return result.filter(p => !!p);
    }, [mapped.productsBanco, mapped.productsPuesto, mapped.productsSAFI, products]);
    
    const montoProyectado = useMemo(() => (
        (mapped.cii_usdAmtPuesto * mapped.cci_exRatePuesto)+
        (mapped.cii_usdAmtSAFI * mapped.cci_exRateSAFI)+ 
        mapped.cli_loanAmount + mapped.client_rcbi_savAccDepAmt +
        mapped.cii_rdsAmtPuesto + mapped.cii_rdsAmtSAFI + mapped.cii_rdsAmtFinCert
    ), [mapped.cii_rdsAmtPuesto, mapped.cii_usdAmtPuesto, mapped.cci_exRatePuesto,
        mapped.cli_loanAmount, mapped.cii_rdsAmtFinCert, mapped.cii_usdAmtSAFI,
        mapped.cii_rdsAmtSAFI, mapped.cci_exRateSAFI, mapped.client_rcbi_savAccDepAmt]);

    const nivelIngresos = useMemo(() => (
        mapped.ingresosAnuales
    ), [mapped.ingresosAnuales]);
    const {zonaGeograficaNacional} = mapped;
    const paisDeOrigen = useMemo(() => (
        mapped.paisOrigen
    ), [mapped.paisOrigen]);
    const paisDeResidencia = useMemo(() => (
        mapped.paisResidencia
    ), [mapped.paisResidencia]);
    const canalDeDistribucion = useMemo(() => (
        mapped.relacionCaraCara ? 'Canal cara a cara':'Canal no cara a cara'
    ), [mapped.relacionCaraCara]);

    
    return (
        <FormGroup>
            <legend>
                {title}
            </legend>
            <Table hover size="sm"><tbody>
                <tr><td>Duración de la relación</td><td>{duracionDeLaRelacion}</td></tr>
                <tr><td>Producto Solicitado</td><td>{productoSolicitado}</td></tr>
                <tr><td>Monto proyectado</td><td>{montoProyectado || 0}</td></tr>
                <tr><td>Nivel Ingresos</td><td>{nivelIngresos}</td></tr>
                <tr><td>Zona Geográfica Nacional</td><td>{zonaGeograficaNacional}</td></tr>
                <tr><td>País de Origen</td><td>{paisDeOrigen}</td></tr>
                <tr><td>País de Residencia</td><td>{paisDeResidencia}</td></tr>
                <tr><td>Canal de Distribución</td><td>{canalDeDistribucion}</td></tr>
            </tbody></Table>
        </FormGroup>
    );
}


export default CalcField;