import {useMemo} from "react";


function useUID(prefix='id'){
    const uid = useMemo(() => {
        useUID.__count__ += 1;
        return prefix + useUID.__count__;
    }, [prefix]);

    return uid;
}

useUID.__count__ = 0;


export default useUID;