import { Trans } from '@lingui/react';
import React, { useContext, useMemo, useState } from 'react';
import { Input } from 'reactstrap';

import CasesApi from '../../api/CasesApi';
import useResourceLoader from '../../util/useResourceLoader';
import Notification from '../../components/Notification';
import Loader from '../../components/Loader';
import forms from "../forms/forms";
import { NO_FORM } from '../../constants';
import useSearchParams from '../../util/useSearchParams';
import { AuthContext } from '../../context/AuthContext';


export function CasePreviousForms({
    currentCase,
    readonly
}) {
    const auth = useContext(AuthContext);
    const [workflowStates, loadingWS, errorLoadingWS] = useResourceLoader(
        () => CasesApi.getWorkflowStates({ caseId: currentCase.id }),
        [currentCase.id],
        []
    );

    const [[_formIdx, setFormIdx]] = useSearchParams({
        form: 0
    })
    const formIdx = _formIdx | 0;

    const state = useMemo(() => JSON.parse(currentCase.state), [currentCase.state]);
    const caseForms = useMemo(() => (workflowStates.filter(
        ws => ws.id && ws.page && state[ws.id]
    ).map(
        ({ page, title }) => ({ page, title })
    )), [workflowStates, state]);

    const currentForm = caseForms[formIdx] || caseForms[0];
    const FormComponent = forms[(currentForm || {}).page];

    const canEditOldCaseForms = useMemo(() => {
        const {determineCanEdit} = FormComponent || {};
        return (!readonly && determineCanEdit && determineCanEdit({
            currentCase,
            auth,
        }) || auth.isAdmin);
    }, [readonly, FormComponent, currentCase]);

    console.log(currentForm);

    return loadingWS ? (
        <Loader fullscreen />
    ) : (errorLoadingWS ? (
        <Notification error={errorLoadingWS} />
    ) : ((caseForms.length > 0 ? (<>
        <Input type="select" value={formIdx} onChange={({ target: { value } }) => setFormIdx(value | 0)}>{caseForms.map(({ page, title }, idx) => (
            <option key={page} value={idx}>{title}</option>
        ))}</Input>
        {FormComponent && FormComponent !== NO_FORM ? (
            <FormComponent
                onlySave
                readonly={!canEditOldCaseForms}
                currentCase={currentCase}
                page={currentForm.page}
                generateDocuments={currentForm.generateDocuments}
            />
        ) : null}
    </>) : (
        <Notification>No hay formularios anteriores para ense&ntilde;ar.</Notification>
    ))));
}

export default CasePreviousForms;