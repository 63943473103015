import secondaryClientForm from '../secondary/RequirementsUploadPersonalClientSchema';

const form = {
    title: "Carga Documentos",
    displayName: 'RequirementsUploadPersonalClient',
    page: 'requirements-upload-personal-client',
    determineCanEdit: ({ currentCase }) => (true),
    secondaryClientForm
};


const formularios = {
    title: "Formularios",
    type: "object",
    properties: {
        "conozcaSuClienteBanco": {
            title: "Formulario Conozca Su Cliente (Banco)",
            type: "number",
        },
        "conozcaSuClientePuesto": {
            title: "Formulario Conozca Su Cliente (Puesto)",
            type: "number",
        },
        "conozcaSuClienteSafi": {
            title: "Formulario Conozca Su Cliente (Safi)",
            type: "number",
        },
        "sujetoObligadoNoFinanciero": {
            title: "Formulario Sujeto Obligado No Financiero",
            type: "number",
        },
        "listadoRequisitos": {
            title: "Listado de Requisitos",
            type: "string",
            staticFile: "requirements"
        },
        "enviarCorreoCliente": {
            "buttonText": "Enviar Correo a Cliente",
            subject: "Documentos requeridos para vinculación en JMMB",
            body: `
                !!eval[].concat([
                    \`Buenas tardes Sr/Sra. \${formContext.clientFirstName} \${formContext.clientLastName}\`,
                    "",
                    (
                        "Gracias por su interés en visitarnos, de verdad para nosotros es un placer que inicien con nosotros y " +
                        "poder rentabilizar sus fondos. Para iniciar primero debemos crearlos como cliente. Anexo documentos " + 
                        "requeridos para firmar y los requisitos según el tipo de cliente:"
                    )
                ], ((
                    formData.needsId                ||
                    formData.needsPassport          ||
                    formData.needsEmigrationStatus
                ) ? [
                    "- Documento de identidad",
                    (formData.needsId               ? "    Cedula Identidad Anverso"      : null),
                    (formData.needsId               ? "    Cedula Identidad Reverso"      : null),
                    (formData.needsPassport         ? "    Pasaporte"                   : null),
                    (formData.needsPassport         ? "    Documento Identidad Pais de Origen": null),
                    (formData.needsEmigrationStatus ? "    Documento Gestion Migratoria"  : null),    
                ] : []), ((
                    formData.needsEmpEvidence       ||
                    formData.needsTaxDecl           ||
                    formData.needsIR1               ||
                    formData.needsIncomeStatement
                ) ? [
                    "- Soportes de fuente de ingresos",
                    (formData.needsEmpEvidence      ? "    Evidencia Ingresos Regulares"   : null),
                    (formData.needsTaxDecl          ? "    Declaración de Impuestos del ultimo año": null),
                    (formData.needsTaxDecl          ? "    Soporte de naturaleza de actividades" : null),
                    (formData.needsIR1              ? "    Declaración IR1- Ingresos adicionales"               : null),
                    (formData.needsIncomeStatement  ? "    Estados de ingresos y gastos - Act. informales"        : null),
                ] : []), [
                "- Soportes origen de fondos (de donde se reciben los fondos a utilizar)",
                "    Soporte Origen de Fondos",
                "    Soporte Procedencia de Fondos",
                ], (
                    (formData.needsPEPDocs) ? [
                    "- Soportes para Personas Políticamente Expuestas por designación o vinculación",
                    "    Declaración Jurada de Patrimonio o decreto de designación",
                    "    Declaración IR-1 - Participación en empresas",
                ] : []), (
                    (formData.needsGuarantSup) ? [
                    "- Soporte Solicitud de Préstamo",
                    "    Soporte de Valor de Garantía",
                ] : [])
            ).filter(l => l !== null).join("\\n")`,
            type: "null",
        },
    }
}

const documentosIdentificacion = {
    title: "Documentos de Identificación",
    type: "object",
    properties: {
        "cedulaIdentidadAnverso": {
            title: "Cédula de Identidad (anverso)",
            type: "number"
        },
        // "cedulaIdentidadAnversoFecha": {
        //     title: "Fecha de vencimiento",
        //     type: "string",
        //     format: "date",
        // },
        "cedulaIdentidadReverso": {
            title: "Cédula de Identidad (reverso)",
            type: "number"
        },
        "pasaporte": {
            title: "Pasaporte",
            type: "number",
        },
        // "pasaporteFecha": {
        //     title: "Fecha de vencimiento",
        //     type: "string",
        //     format: "date",
        // },
        "documentoIdentidadPaisOrigen": {
            title: "Documento de Identidad Pais de Origen",
            type: "number",
        },
        // "documentoIdentidadPaisOrigenFecha": {
        //     title: "Fecha de vencimiento",
        //     type: "string",
        //     format: "date",
        // },
        "documentoGestionMigratoria": {
            title: "Documento Gestión Migratoria",
            type: "number",
        },
        // "documentoGestionMigratoriaFecha": {
        //     title: "Fecha de vencimiento",
        //     type: "string",
        //     format: "date",
        // },
    },
    // required: ["cedulaIdentidadAnverso","cedulaIdentidadAnversoFecha", "cedulaIdentidadReverso",
    //     "pasaporte", "pasaporteFecha", "documentoIdentidadPaisOrigen", "documentoIdentidadPaisOrigenFecha",
    //     "documentoGestionMigratoria", "documentoGestionMigratoriaFecha"],
}

const soporteFuenteIngresos = {
    title: "Soporte Fuente de Ingresos",
    type: "object",
    properties: {
        "evidenciaIngresosRegulares": {
            title: "Evidencia Ingresos Regulares",
            type: "number",
        },
        // "evidenciaIngresosRegularesFecha": {
        //     title: "Fecha de emisión",
        //     type: "string",
        //     format: "date",
        // },
        "declaracionImpuestosUltimoAno": {
            title: "Declaración de Impuestos del ultimo año",
            type: "number",
        },
        // "declaracionImpuestosUltimoAnoFecha": {
        //     title: "Fecha de declaración",
        //     type: "string",
        //     format: "date",
        // },
        "soporteNaturalezaActividades": {
            title: "Soporte de naturaleza de actividades",
            type: "number",
        },
        // "soporteNaturalezaActividadesFecha": {
        //     title: "Fecha de emisión",
        //     type: "string",
        //     format: "date",
        // },
        "declaracionIR1": {
            title: "Declaración IR1- Ingresos adicionales",
            type: "number",
        },
        // "declaracionIR1Fecha": {
        //     title: "Fecha de declaración",
        //     type: "string",
        //     format: "date",
        // },
        "estadosIngresosGastos": {
            title: "Estados de ingresos y gastos - Act. informales",
            type: "number",
        },
        // "estadosIngresosGastosFecha": {
        //     title: "Fecha de declaración",
        //     type: "string",
        //     format: "date",
        // },
    },
    // required: ["evidenciaIngresosRegulares","evidenciaIngresosRegularesFecha", "declaracionImpuestosUltimoAno",
    //     "declaracionImpuestosUltimoAnoFecha","soporteNaturalezaActividades", "soporteNaturalezaActividadesFecha",
    //     "declaracionIR1", "declaracionIR1Fecha", "estadosIngresosGastos", "estadosIngresosGastosFecha"],
}

const soporteOrigenProcedenciaFondos = {
    title: "Soporte Origen y procedencia de fondos",
    type: "object",
    properties: {
        "soporteOrigenFondos": {
            title: "Soporte origen de fondos",
            type: "number",
        },
        // "soporteOrigenFondosFecha": {
        //     title: "Fecha de emisión",
        //     type: "string",
        //     format: "date",
        // },
        "soporteProcedenciaFondos": {
            title: "Soporte procedencia de fondos",
            type: "number",
        },
        // "soporteProcedenciaFondosFecha": {
        //     title: "Fecha de emisión",
        //     type: "string",
        //     format: "date",
        // },
    },
    required: ["soporteOrigenFondos",
        "soporteProcedenciaFondos"]
}

const soportesAdicionalesPEP = {
    title: "Soportes Adicionales Personas Expuestas Políticamente",
    type: "object",
    properties: {
        "declaracionJuradaPatrimonioDesignacion": {
            title: "Declaración Jurada de Patrimonio o decreto de designación",
            type: "number",
        },
        // "declaracionJuradaPatrimonioDesignacionFecha": {
        //     title: "Fecha de emisión",
        //     type: "string",
        //     format: "date",
        // },
        "declaracionIRParticipacionEmpresas": {
            title: "Declaración IR-1 - Participación en empresas",
            type: "number",
        },
        // "declaracionIRParticipacionEmpresasFecha": {
        //     title: "Fecha de emisión",
        //     type: "string",
        //     format: "date",
        // },
    },
    // required: ["declaracionJuradaPatrimonioDesignacion","declaracionJuradaPatrimonioDesignacionFecha",
    // "declaracionIRParticipacionEmpresas","declaracionIRParticipacionEmpresasFecha"]
}

const soporteSolicitudPrestamo = {
    title: " ",
    type: "object",
    properties: {
        "incluyeGarantia": {
            title: " ",
            type: "boolean",
        },
    },
    dependencies: {
        "incluyeGarantia": {
            allOf: [{
                if: { properties: { "incluyeGarantia": { const: true } } },
                then: {
                    properties: {
                        "soporteSolicitudPrestamoTitle": {
                            title: " ",
                            description: "Soporte Solicitud de Préstamo",
                            type: "null"
                        },
                        "soporteGarantia": {
                            title: "Soporte de Valor de Garantía",
                            type: "number",
                        },
                        // "soporteGarantiaFecha": {
                        //     title: "Fecha de emisión",
                        //     type: "string",
                        //     format: "date",
                        // },
                    }
                }
            }]
        },
    }
    // title: "Soporte Solicitud de Préstamo",
    // type: "object",
    // properties: {
    //     "soporteGarantia": {
    //         title: "Soporte de Valor de Garantía",
    //         type: "number",
    //     },
    //     "soporteGarantiaFecha": {
    //         title: "Fecha de emisión",
    //         type: "string",
    //         format: "date",
    //     },

    // },
    // required: ["soporteGarantia","soporteGarantiaFecha"]
}

form.schema = {
    type: "object",
    properties: {
        "documentosClienteTitular": {
            title: "Documentos Cliente Titular",
            type: "object",
            properties: {
                "formularios": formularios,
                "documentosIdentificacion": documentosIdentificacion,
                "soporteFuenteIngresos": soporteFuenteIngresos,
                "soporteOrigenProcedenciaFondos": soporteOrigenProcedenciaFondos,
                "soportesAdicionalesPEP": soportesAdicionalesPEP,
                "soporteSolicitudPrestamo": soporteSolicitudPrestamo,
                "soporteAprobacionExcepcion": {
                    title: "Si el cliente no desea abrir una cuenta o ya la tiene, se debe proveer soporte de la aprobación de esta excepción o evidencia de la existencia de la cuenta",
                    type: "number"
                },
                "additionalFiles": {
                    title: " ",
                    description: "Archivos Adicionales",
                    type: "array",
                    items: {
                        "properties": {
                            "documentName": {
                                title: "Nombre Archivo",
                                type: "string"
                            },
                            "document": {
                                title: "Archivo",
                                type: "number"
                            },
                        }
                    },
                }
            },
        },
        // "documentosClienteAdicional": {
        //     title: "Documentos Cliente Adicional",
        //     type: "object",
        //     properties: {

        //     },
        // },
    },
};



form.uiSchema = {
    "documentosClienteTitular": {
        "ui:field": "HideObjectField",
        "formularios": {
            "ui:field": "HideObjectField",
            "conozcaSuClienteBanco": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
            "conozcaSuClientePuesto": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
            "conozcaSuClienteSafi": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true },
            "sujetoObligadoNoFinanciero": { "ui:field": "FileField", "ui:hideOnEmpty": true, "ui:readonly": true, "joli:hideAttr": { "ifEmpty": "needs155Docs" } },
            "listadoRequisitos": { "ui:field": "FileField" },
            "enviarCorreoCliente": { "ui:field": "SendEmail" },
        },
        "documentosIdentificacion": {
            "classNames": "smallTwoRows",
            "ui:field": "HideObjectField",
            "cedulaIdentidadAnverso": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsId" } },
            "cedulaIdentidadReverso": { "ui:field": "FileField", "joli:required": true, "classNames": "from-col-1 to-col-3", "joli:hideAttr": { "ifEmpty": "needsId" } },
            "cedulaIdentidadAnversoFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsId" } },
            "pasaporte": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPassport" } },
            "pasaporteFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPassport" } },
            "documentoIdentidadPaisOrigen": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPassport" } },
            "documentoIdentidadPaisOrigenFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPassport" } },
            "documentoGestionMigratoria": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsEmigrationStatus" } },
            "documentoGestionMigratoriaFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsEmigrationStatus" } },
        },
        "soporteFuenteIngresos": {
            "classNames": "smallTwoRows",
            "ui:field": "HideObjectField",
            "evidenciaIngresosRegulares": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsEmpEvidence" } },
            "evidenciaIngresosRegularesFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsEmpEvidence" } },
            "declaracionImpuestosUltimoAno": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsTaxDecl" } },
            "declaracionImpuestosUltimoAnoFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsTaxDecl" } },
            "soporteNaturalezaActividades": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsTaxDecl" } },
            "soporteNaturalezaActividadesFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsTaxDecl" } },
            "declaracionIR1": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsIR1" } },
            "declaracionIR1Fecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsIR1" } },
            "estadosIngresosGastos": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsIncomeStatement" } },
            "estadosIngresosGastosFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsIncomeStatement" } },
        },
        "soporteOrigenProcedenciaFondos": {
            "classNames": "smallTwoRows",
            "soporteOrigenFondos": { "ui:field": "FileField" },
            "soporteProcedenciaFondos": { "ui:field": "FileField" },
        },
        "soportesAdicionalesPEP": {
            "classNames": "smallTwoRows",
            "ui:field": "HideObjectField",
            "declaracionJuradaPatrimonioDesignacion": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPEPDocs" } },
            "declaracionJuradaPatrimonioDesignacionFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPEPDocs" } },
            "declaracionIRParticipacionEmpresas": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPEPDocs" } },
            "declaracionIRParticipacionEmpresasFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsPEPDocs" } },
        },
        "soporteSolicitudPrestamo": {
            "classNames": "smallTwoRows",
            "ui:field": "HideObjectField",
            "incluyeGarantia": { "joli:hideAttr": "hide", },
            "soporteGarantia": { "ui:field": "FileField", "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsGuarantSup" }, "classNames": "soporteGarantiaFile" },
            "soporteGarantiaFecha": { "joli:required": true, "joli:hideAttr": { "ifEmpty": "needsGuarantSup" }, "classNames": "soporteGarantiaFecha" },
        },
        "soporteAprobacionExcepcion": {
            "ui:field": "FileField",
            "joli:hideAttr": {
                "caseData": {
                    "productIds[1]": 1
                }
            }
        },
        "additionalFiles": {
            "classNames": "additionalFiles",
            "items": { "document": { "ui:field": "FileField" } }
        },
    },
}

form.objectMap = {
    "clients[0].retailClientBasicInfo.idCardFrontFileId": "documentosClienteTitular.documentosIdentificacion.cedulaIdentidadAnverso",
    // "clients[0].retailClientBasicInfo.idCardDate": "documentosClienteTitular.documentosIdentificacion.cedulaIdentidadAnversoFecha",
    "clients[0].retailClientBasicInfo.idCardBackFileId": "documentosClienteTitular.documentosIdentificacion.cedulaIdentidadReverso",
    "clients[0].retailClientBasicInfo.passportFileId": "documentosClienteTitular.documentosIdentificacion.pasaporte",
    // "clients[0].retailClientBasicInfo.passportDate": "documentosClienteTitular.documentosIdentificacion.pasaporteFecha",
    "clients[0].retailClientBasicInfo.countryOfOriginIdFileId": "documentosClienteTitular.documentosIdentificacion.documentoIdentidadPaisOrigen",
    // "clients[0].retailClientBasicInfo.countryOfOriginIdDate": "documentosClienteTitular.documentosIdentificacion.documentoIdentidadPaisOrigenFecha",
    "clients[0].retailClientBasicInfo.emmigrationDocumentFileId": "documentosClienteTitular.documentosIdentificacion.documentoGestionMigratoria",
    // "clients[0].retailClientBasicInfo.emmigrationDocumentDate": "documentosClienteTitular.documentosIdentificacion.documentoGestionMigratoriaFecha",

    "clients[0].retailClientBasicInfo.regularIncomeEvidenceFileId": "documentosClienteTitular.soporteFuenteIngresos.evidenciaIngresosRegulares",
    // "clients[0].retailClientBasicInfo.regularIncomeEvidenceDate": "documentosClienteTitular.soporteFuenteIngresos.evidenciaIngresosRegularesFecha", // evidenciaIngresosRegularesFecha
    "clients[0].retailClientBasicInfo.lastYearTaxDecarationFileId": "documentosClienteTitular.soporteFuenteIngresos.declaracionImpuestosUltimoAno",
    // "clients[0].retailClientBasicInfo.lastYearTaxDecarationDate": "documentosClienteTitular.soporteFuenteIngresos.declaracionImpuestosUltimoAnoFecha",
    "clients[0].retailClientBasicInfo.naturalActivitiesSupportFileId": "documentosClienteTitular.soporteFuenteIngresos.soporteNaturalezaActividades",
    // "clients[0].retailClientBasicInfo.naturalActivitiesSupportDate": "documentosClienteTitular.soporteFuenteIngresos.soporteNaturalezaActividadesFecha",
    "clients[0].retailClientBasicInfo.ir1DeclarationFileId": "documentosClienteTitular.soporteFuenteIngresos.declaracionIR1",
    // "clients[0].retailClientBasicInfo.ir1DeclarationDate": "documentosClienteTitular.soporteFuenteIngresos.declaracionIR1Fecha",
    "clients[0].retailClientBasicInfo.incomeExpenseStateFileId": "documentosClienteTitular.soporteFuenteIngresos.estadosIngresosGastos",
    // "clients[0].retailClientBasicInfo.incomeExpenseStateDate": "documentosClienteTitular.soporteFuenteIngresos.estadosIngresosGastosFecha",

    "clients[0].retailClientBasicInfo.fundsOriginSupportFileId": "documentosClienteTitular.soporteOrigenProcedenciaFondos.soporteOrigenFondos",
    // "clients[0].retailClientBasicInfo.fundsOriginSupportDate": "documentosClienteTitular.soporteOrigenProcedenciaFondos.soporteOrigenFondosFecha",
    "clients[0].retailClientBasicInfo.fundsProcedenceSupportFileId": "documentosClienteTitular.soporteOrigenProcedenciaFondos.soporteProcedenciaFondos",
    // "clients[0].retailClientBasicInfo.fundsProcedenceSupportDate": "documentosClienteTitular.soporteOrigenProcedenciaFondos.soporteProcedenciaFondosFecha",

    "clients[0].retailClientBasicInfo.designationHeritageFileId": "documentosClienteTitular.soportesAdicionalesPEP.declaracionJuradaPatrimonioDesignacion",
    // "clients[0].retailClientBasicInfo.designationHeritageDate": "documentosClienteTitular.soportesAdicionalesPEP.declaracionJuradaPatrimonioDesignacionFecha",
    "clients[0].retailClientBasicInfo.enterpriseParticipationIRDeclarationFileId": "documentosClienteTitular.soportesAdicionalesPEP.declaracionIRParticipacionEmpresas",
    // "clients[0].retailClientBasicInfo.enterpriseParticipationIRDeclarationDate": "documentosClienteTitular.soportesAdicionalesPEP.declaracionIRParticipacionEmpresasFecha",

    "clients[0].retailClientBasicInfo.guarranteeSupportFileId": "documentosClienteTitular.soporteSolicitudPrestamo.soporteGarantia",
    // "clients[0].retailClientBasicInfo.guarranteeSupportDate": "documentosClienteTitular.soporteSolicitudPrestamo.soporteGarantiaFecha",

    "clients[0].retailClientDueDiligenceReport.additionalFilesList.items": {
        array: "documentosClienteTitular.additionalFiles",
        map: {
            "name": "documentName",
            "fileId": "document",
        }
    },

    "generatedFiles.shortkyc_0_FormularioConozcaSuClientePuesto": "documentosClienteTitular.formularios.conozcaSuClientePuesto",
    "generatedFiles.shortkyc_0_FormularioConozcaSuClienteSafi": "documentosClienteTitular.formularios.conozcaSuClienteSafi",
    "generatedFiles.shortkyc_0_FormularioConozcaSuClienteBanco": "documentosClienteTitular.formularios.conozcaSuClienteBanco",
    "generatedFiles.shortkyc_0_SujetoObligadoPersonal": "documentosClienteTitular.formularios.sujetoObligadoNoFinanciero",

    // "documentosClienteAdicional"
    "flags.needsId": "needsId",
    "flags.needsPassport": "needsPassport",
    "flags.needsEmigrationStatus": "needsEmigrationStatus",
    "flags.needsTaxDecl": "needsTaxDecl",
    "flags.needsIncomeStatement": "needsIncomeStatement",
    "flags.needsEmpEvidence": "needsEmpEvidence",
    "flags.needsIR1": "needsIR1",
    "flags.needsPEPDocs": "needsPEPDocs",
    "flags.needsGuarantSup": "needsGuarantSup",
    "flags.needs155Docs": "needs155Docs",
}


export default form;