// import { Trans } from '@lingui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { AuthApi } from '../api/AuthApi';
import FormComponent from '../components/FormComponent';
import MessageView from '../components/MessageView';


const ResetPassword = FormComponent({
    displayName: 'ResetPassword',
    schema: {
        title: "Restablecer contraseña",
        type: "object",
        properties: {
            password: {title: "New Password", type: "string"},
            passwordConfirm: {title: "Confirm Password", type: "string"},
        },
        required: ["password", "passwordConfirm"],
    },
    uiSchema: {
        password: {"ui:widget": "password"},
        passwordConfirm: {"ui:widget": "password"},
    },
    parseProps({ match: {params: {token}}}) {
        return {token};
    },
    async onSubmit({formData: {password, passwordConfirm}}, {props: {token}}) {
        await AuthApi.resetPassword({ token, password, passwordConfirm });
    },
    validate({password, passwordConfirm}, errors){
        if (password !== passwordConfirm) {
            errors.passwordConfirm.addError("Las contraseñas no son iguales");
        }
        return errors;
    },
    renderFormChildren: () => (
        <Row>
            <Col><Button color="primary" type="submit">Restablecer contraseña.</Button></Col>
        </Row>
    ),
    renderFormSubmitted: () => (
        <MessageView title="Contraseña olvidada">
            <div>Su contraseña ha sido restablecida exitosamente.</div>
            <br />
            <Link component={Button} color="primary" to="/"> Regresar a Pantalla de Login</Link>
        </MessageView>
    )
})


export default ResetPassword;