import CaseIntakeSchema from '../../form_schemas/primary/CaseIntakeSchema';
import ShortKYCPersonalClientSchema from '../../form_schemas/primary/ShortKYCPersonalClientSchema';
import ShortKYCCompanyClientSchema from '../../form_schemas/ShortKYCCompanyClientSchema';
import KYCPersonalClientSchema from '../../form_schemas/primary/KYCPersonalClientSchema';
import ComplianceCaseReviewSchema from '../../form_schemas/primary/ComplianceCaseReviewSchema';
import ComplianceCaseReviewUploadPersonalSchema from '../../form_schemas/ComplianceCaseReviewUploadPersonalSchema';
import ComplianceCaseReviewUploadBusinessSchema from '../../form_schemas/ComplianceCaseReviewUploadBusinessSchema';
import ComplianceCaseReviewBusinessSchema from '../../form_schemas/ComplianceCaseReviewBusinessSchema';
import RequirementsUploadPersonalClientSchema from '../../form_schemas/primary/RequirementsUploadPersonalClientSchema';
import RequirementsUploadBusinessClientSchema from '../../form_schemas/RequirementsUploadBusinessClientSchema';
import NonCreditContractsDownloadPersonalClientSchema from '../../form_schemas/primary/NonCreditContractsDownloadPersonalClientSchema';
import NonCreditContractsUploadBusinessClientSchema from '../../form_schemas/NonCreditContractsUploadBusinessClientSchema';
import NonCreditContractsUploadPersonalClientSchema from '../../form_schemas/primary/NonCreditContractsUploadPersonalClientSchema';
import FullKYCBusinessClientSchema from '../../form_schemas/FullKYCBusinessClientSchema';
import CollateralInscriptionSchema from '../../form_schemas/CollateralInscriptionSchema';
import RiskCaseReviewReportSchema from '../../form_schemas/RiskCaseReviewReportSchema';
import LoanCreationConfirmationSchema from '../../form_schemas/LoanCreationConfirmationSchema';
import LoanDisbursementConfirmationSchema from '../../form_schemas/LoanDisbursementConfirmationSchema';
import OperationsCaseReviewSchema from '../../form_schemas/OperationsCaseReviewSchema';
import ManagerCaseReviewReportSchema from '../../form_schemas/ManagerCaseReviewReportSchema';
import MarketManagerCaseReviewReportSchema from '../../form_schemas/MarketManagerCaseReviewReportSchema';
import LegalCaseReviewReportSchema from '../../form_schemas/LegalCaseReviewReportSchema';
import CreditAnalysisReportSchema from '../../form_schemas/CreditAnalysisReportSchema';
import BusinessCreditAnalysisReportSchema from '../../form_schemas/BusinessCreditAnalysisReportSchema';
import CreditAnalysisDecisionSchema from '../../form_schemas/CreditAnalysisDecisionSchema';
import InvestmentClientCreationConfirmationSchema from '../../form_schemas/primary/InvestmentClientCreationConfirmationSchema';
import InvestmentClientCreationConfirmationPuestoSchema from '../../form_schemas/primary/InvestmentClientCreationConfirmationPuestoSchema';
import InvestmentClientCreationConfirmationSAFISchema from '../../form_schemas/primary/InvestmentClientCreationConfirmationSAFISchema';
import BankClientCreationConfirmationActivationSchema from '../../form_schemas/primary/BankClientCreationConfirmationActivationSchema';
import SalesContractElaborationUploadSchema from '../../form_schemas/SalesContractElaborationUploadSchema';
import LegalContractElaborationSchema from '../../form_schemas/LegalContractElaborationSchema';
import NonCreditContractsDownloadBusinessClientSchema from '../../form_schemas/NonCreditContractsDownloadBusinessClientSchema';
import filesSchema from '../../form_schemas/primary/filesSchema';
import filesSchemaBusiness from '../../form_schemas/filesSchemaBusiness';
import LastScreenSchema from '../../form_schemas/primary/LastScreenSchema';
import CaseFormComponent from '../../blc/CaseFormComponent';

import CloseCaseSchema from '../../form_schemas/CloseCaseSchema';


const forms = {};
[
    filesSchema,
    filesSchemaBusiness,
    CaseIntakeSchema,
    ShortKYCPersonalClientSchema,
    ShortKYCCompanyClientSchema,
    RequirementsUploadPersonalClientSchema,
    RequirementsUploadBusinessClientSchema,
    KYCPersonalClientSchema,
    FullKYCBusinessClientSchema,
    NonCreditContractsDownloadPersonalClientSchema,
    NonCreditContractsDownloadBusinessClientSchema,
    NonCreditContractsUploadPersonalClientSchema,
    NonCreditContractsUploadBusinessClientSchema,
    ComplianceCaseReviewSchema,
    ComplianceCaseReviewUploadPersonalSchema,
    ComplianceCaseReviewBusinessSchema,
    ComplianceCaseReviewUploadBusinessSchema,
    CollateralInscriptionSchema,
    RiskCaseReviewReportSchema,
    LoanCreationConfirmationSchema,
    LoanDisbursementConfirmationSchema,
    OperationsCaseReviewSchema,
    ManagerCaseReviewReportSchema,
    MarketManagerCaseReviewReportSchema,
    LegalCaseReviewReportSchema,
    CreditAnalysisReportSchema,
    BusinessCreditAnalysisReportSchema,
    CreditAnalysisDecisionSchema,
    InvestmentClientCreationConfirmationSchema,
    InvestmentClientCreationConfirmationPuestoSchema,
    InvestmentClientCreationConfirmationSAFISchema,
    BankClientCreationConfirmationActivationSchema,
    SalesContractElaborationUploadSchema,
    LegalContractElaborationSchema,
    LastScreenSchema,
    CloseCaseSchema
].forEach(({
    title,
    displayName,
    page,
    schema,
    uiSchema,
    objectMap,
    ...props
}) => {
    forms[page] = CaseFormComponent({
        title,
        displayName,
        page,
        schema,
        uiSchema,
        objectMap,
        ...props
    });
});


export default forms;