import React, {
    useState,
    useEffect,
    forwardRef,
    useRef,
    useImperativeHandle,
    useMemo
} from 'react';

import {
    Nav,
    NavLink,
    NavItem,
    Button
} from "reactstrap";

import _ from 'lodash';
import useScrollHandler from '../util/useScrollHandler';
import { getScrollParent } from '../util/scrolling';
import binarySearch from '../util/binarySearch';
import useUnmountDetection from '../util/useUnmountDetection';

const CaseFormNavigationPanel = forwardRef(({
    formTitle,
    formElement,
    hasUnsavedChanges,
}, ref) => {
    const [selectedIdx, setSelectedIdx] = useState(null);
    const [expanded, setExpanded] = useState();
    const [updateCt, setUpdateCt] = useState(0);
    const {scrollParent, viewportHeight} = useMemo(() => {
        const scrollParent = getScrollParent(formElement);
        return {
            scrollParent,
            viewportHeight: scrollParent === window ? window.innerHeight : scrollParent.getBoundingClientRect().height,

        }
    }, [formElement]);
    const threshold = viewportHeight * .5;

    const navigation = useMemo(() => {
        const clientTabs = formElement && formElement.querySelector('.form-clients-tabs');
        const elements = Array.prototype.slice.call((formElement && formElement.querySelectorAll('legend[id^="root_"][id$="__title"]')) || []);
        const buttons = formElement ? formElement.querySelector('.form-submit-buttons') : null;
        const nav = [
            ...(clientTabs ? [{
                title: "Clients",
                elem: clientTabs,
                block: 'center',
                icon: 'fa fa-user',
            }] : []),
            ...elements.filter(e => !!e.outerText).map(elem => ({
                title: elem.outerText,
                elem
            })),
            {
                title: 'Someter',
                block: 'start',
                elem: buttons,
            },
        ].filter(e => !!e.elem);

        const { scrollY } = window;

        nav.forEach((e, idx) => {
            e.idx = idx;
            e.pos = e.elem.getBoundingClientRect().top + scrollY;
        });

        const posSort = [...nav].sort((a, b) => a.pos < b.pos);

        return {
            list: nav,
            posList: posSort.map(({pos}, pidx) => {
                // const nextPos = (posSort[pidx + 1] || {}).pos;
                return pos; // Number.isFinite(nextPos) ? (pos * 0.9 + nextPos * 0.1) : pos;
            }),
            posIndex: posSort.map(({idx}) => idx),
        };
    }, [formElement, updateCt]);

    function manageScroll(e, idx) {
        e.preventDefault();
        const { elem, block } = navigation.list[idx] || {elem: formElement};
        if (elem) {
            setTimeout(() => {
                elem.scrollIntoView({ block: block || 'start', behavior: 'smooth' });
            }, 200);
            setSelectedIdx(idx);
        }
    }

    const unmountFlag = useUnmountDetection();

    useImperativeHandle(ref, () => ({
        navigation,
        updateNav: _.debounce(() => {
            if (unmountFlag.unmounted) return;
            setUpdateCt(new Date().getTime())
        }, 500),
    }), [navigation]);

    useEffect(() => {
        setUpdateCt(new Date().getTime());
    }, [formElement]);

    useScrollHandler(scrollParent, _.debounce((scrollY) => {
        const posIdx = binarySearch(scrollY + threshold, navigation.posList) - 1;
        setSelectedIdx(navigation.posIndex[posIdx]);
    }, 50), [navigation])

    useEffect(() => {
        if (navigation.list.length && !Number.isFinite(selectedIdx)) {
            setSelectedIdx(navigation.posIndex[0].idx);
        }
    }, [navigation, selectedIdx]);

    const lastIdx = navigation.list.length - 1;

    return (
        <div className={`case-form-nav-panel ${expanded ? 'expanded': ''}`}>
            <Button className="expand-button" onClick={() => setExpanded(!expanded)}>
                <i className={`fa fa-chevron-${expanded ? 'down': 'right'}`}/>
            </Button>
            <div className="scroller">
                {formTitle ? (<h5>{formTitle}{hasUnsavedChanges ? "*" :""}</h5>) : null}
                <Nav pills vertical >
                    {navigation.list.map(({id, elem, title, icon}, idx) => (<NavEntry
                        key={idx}
                        idx={idx}
                        elem={elem}
                        icon={icon}
                        title={title}
                        isSelected={idx === selectedIdx}
                        isLast={idx === lastIdx}
                        onClick={manageScroll}
                    />))}
                </Nav>
            </div>
        </div >
    )
});


function NavEntry({
    idx, title,
    icon,
    isSelected,
    isLast,
    onClick
}){
    const ref = useRef();
    useEffect(() => { if (isSelected && ref.current){
        ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }}, [ref.current, isSelected]);
    return (<div ref={ref}>
        <NavItem
            className={`${isSelected ? 'active' : ''}`}
            title={title}
        >
            {icon ? <i className={`icon ${icon}`} /> : null}
            <NavLink
                onClick={e => onClick(e, idx)}
                className={`selectedIdx-title ${isSelected ? 'active' : ''}`}
            >{title}</NavLink>
        </NavItem>
        {!isLast ? <div className="separator" /> : null}
    </div>);
}


CaseFormNavigationPanel.displayName = "CaseFormNavigationPanel";
export default CaseFormNavigationPanel;