const form = {
    title: "Inscripción Garantía",
    displayName: 'CollateralInscription',
    page: 'collateral-inscription',
    commentFieldsMap: {
        "Garantia": "caseCollateralInscription.comments",
    },
    isSentBack(object) {
        return object.caseCollateralInscription.registrationResult === 3  // "Devuelto"
    }
};


form.schema = {
    title: " ",
    type: "object",
    properties: {
        "informacionPreviaInscripcionGarantia": {
            title: "Informacion y documentación previa para la inscripción de la Garantía",
            type: "object",
            properties: {

            }
        },
        "solicitudChequeContabilidad": {
            title: "Solicitud de Cheque a Contabilidad",
            type: "object",
            properties: {
                "status": {
                    title: "Status",
                    type: "number",
                    enum: [1, 2],
                    enumNames: ["Solicitado", "Recibido"],
                },
                "fechaSolicitud": {
                    title: "Fecha de solicitud",
                    type: "string",
                    format: "date"
                },
                "correoDeSolicitud": {
                    title: "Correo de Solicitud",
                    type: "number",
                },
                "fechaRecepcion": {
                    title: "Fecha de recepción",
                    type: "string",
                    format: "date"
                },
            }
        },
        "pagoImpuestosGarantia": {
            title: "Pago de Impuestos/Inscripción de Garantía",
            type: "object",
            properties: {
                "reciboPagoDGII": {
                    title: "Recibo de pago de DGII",
                    type: "number",
                },
                "reciboDepositoGarantia": {
                    title: "Recibo Depósito Garantía",
                    type: "number",
                },
                "tituloGarantia": {
                    title: "Titulo de Garantía",
                    type: "number",
                },
                "resultado": {
                    title: "Resultado",
                    type: "number",
                    enum: [1, 3],
                    enumNames: ["Resuelto", "Devuelto"],
                },
            }
        },
        "comentarios": {
            title: "Comentarios Internos o Devolución",
            type: "string",
        },
    },
    required: [],
};



form.uiSchema = {
    "solicitudChequeContabilidad": {
        "correoDeSolicitud": { "ui:field": "FileField" }
    },
    "pagoImpuestosGarantia": {
        "reciboPagoDGII": { "ui:field": "FileField" },
        "reciboDepositoGarantia": { "ui:field": "FileField" },
        "tituloGarantia": { "ui:field": "FileField" },
    },
    "informacionPreviaInscripcionGarantia": {

    },
    "comentarios": {
        "ui:widget": "textarea",
        "ui:options": {
            rows: 4
        },
    },
}

form.objectMap = {
    // Fields not implemented:
    "caseCollateralInscription.checkStatus": "solicitudChequeContabilidad.status",
    "caseCollateralInscription.requestDate": "solicitudChequeContabilidad.fechaSolicitud",
    "caseCollateralInscription.mailApplicationFileId": "solicitudChequeContabilidad.correoDeSolicitud",
    "caseCollateralInscription.receivedDate": "solicitudChequeContabilidad.fechaRecepcion",

    "caseCollateralInscription.paymentReceiptFileId": "pagoImpuestosGarantia.reciboPagoDGII",
    "caseCollateralInscription.depositReceiptFileId": "pagoImpuestosGarantia.reciboDepositoGarantia",
    "caseCollateralInscription.titleGuaranteeFileId": "pagoImpuestosGarantia.tituloGarantia",
    "caseCollateralInscription.registrationResult": "pagoImpuestosGarantia.resultado",

    "caseCollateralInscription.comments": "comentarios",
}


export default form;