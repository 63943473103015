const form = {
    title: "Bank Client Creation Confirmation Activation",
    displayName: 'BankClientCreationConfirmationActivation',
    page: 'bank-client-creation-confirmation-activation',
};


form.schema = {
    title: "Creación de cliente JMMB Bank",
    type: "object",
    properties: {
        // "enviarCorreoCliente": {
        //     "buttonText": "Enviar Correo a Cliente",
        //     subject: "¡Te damos la bienvenida a JMMB!",
        //     body: "¡TE DAMOS LA BIENVENIDA A LA FAMILIA JMMB! \n\n\n\n Estamos contentos porque contigo nuestro Grupo Banco sigue creciendo. \n\nNuestra cultura está basada en el amor incondicional teniendo tu mejor interés en nuestros corazones. Somos tu socio financiero brindándote de soluciones integrales e innovadoras que junto a nuestra asesoría personalizada te empoderarán para lograr tus sueños. \n\n\n Le pedimos que se tome unos minutos y llene la siguiente encuesta para evaluar su experiencia de vinculación con el grupo JMMB.",
        //     type: "null",
        // },
        "infCliUCS": {
            title: " ",
            description: "Informaciones Cliente UCS",
            type: "object",
            properties: {
                "cliUCS": {
                    title: "Creación en UCS",
                    type: "boolean",
                    oneOf: [
                        {
                            title: "Cliente creado en UCS",
                            const: true
                        },
                    ]
                },
                "numCliUCS": {
                    title: "Número de cliente en UCS",
                    type: "string"
                },
            },
            required: ["cliUCS", "numCliUCS"]

        },
        "infCliT24Banco": {
            title: " ",
            description: "Informaciones Cliente T24 Banco",
            type: "object",
            properties: {
                "cliT24SAFI": {
                    title: "Creación en T24 Banco",
                    type: "boolean",
                    oneOf: [
                        {
                            title: "Cliente creado en T24 Banco",
                            const: true
                        },
                    ]
                },
                "numCliT24SAFI":
                {
                    title: "Número de cliente en T24 Banco",
                    type: "string"
                },
            },
            required: ["cliT24SAFI", "numCliT24SAFI"]
        },
        "actCliT24": {
            title: " ",
            description: "Activación del Cliente en T24",
            type: "object",
            properties: {
                "pantConfT24": {
                    title: "Pantalla Confirmación T24",
                    type: "number"
                },
                "cliActivo": {
                    title: "Activación en T24 Banco",
                    type: "boolean",
                    oneOf: [
                        {
                            title: "Cliente activo en T24 Banco",
                            const: true
                        },
                    ]
                }
            },
            required: ["pantConfT24", "cliActivo"]
        },

    }
}

form.uiSchema = {
    // "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "actCliT24": {
        "pantConfT24": { "ui:field": "FileField" },
        "cliActivo": {
            "ui:widget": "radio",
        },
    },
    "infCliUCS": {
        "cliUCS": {
            "ui:widget": "radio",
        },
    },
    "infCliT24Banco": {
        "cliT24SAFI": {
            "ui:widget": "radio",
        },
    },
}

form.objectMap = {
    "clients[$idx].retailClientInvestorProfile.wasCreatedInUSC": "infCliUCS.cliUCS",
    "clients[$idx].retailClientInvestorProfile.uscClientNumber": "infCliUCS.numCliUCS",
    "clients[$idx].retailClientInvestorProfile.clientCreatedT24Bank": "infCliT24Banco.cliT24SAFI",
    "clients[$idx].retailClientInvestorProfile.t24BankClientId": "infCliT24Banco.numCliT24SAFI",
    "clients[$idx].retailClientInvestorProfile.t24BankAccountNumber": "infCliT24Banco.numCtaT24SAFI",
    "clients[$idx].retailClientInvestorProfile.clientActivatedT24Bank": "actCliT24.cliActivo",
    "clients[$idx].retailClientInvestorProfile.t24ConfirmationFileId": "actCliT24.pantConfT24"
}


export default form;