import secondaryClientForm from "../secondary/InvestmentClientCreationConfirmationPuestoSchema";

const form = {
    title: "Creación & Activación Cliente PB",
    displayName: 'InvestmentClientCreationConfirmationPuesto',
    page: 'investment-client-creation-confirmation-puesto',
    commentFieldsMap: {
        "comentarios": "caseInvestmentInfo.commentsDIMSA",
        "user-comment": "clients[0].retailClientInvestorProfile.commentsDIMSA"
    },
    secondaryClientForm,
    isSentBack(object) {
        return object.clients[0].retailClientInvestorProfile.resultDIMSA === 1; // Devuelto
    },
};

form.schema = {
    title: "Activación de cliente en Puesto",
    type: "object",
    properties: {
        // "enviarCorreoCliente": {
        //     "buttonText": "Enviar Correo a Cliente",
        //     subject: "¡Te damos la bienvenida a JMMB!",
        //     body: "¡TE DAMOS LA BIENVENIDA A LA FAMILIA JMMB! \n\n\n\n Estamos contentos porque contigo nuestro Grupo Puesto de Bolsa sigue creciendo. \n\nNuestra cultura está basada en el amor incondicional teniendo tu mejor interés en nuestros corazones. Somos tu socio financiero brindándote de soluciones integrales e innovadoras que junto a nuestra asesoría personalizada te empoderarán para lograr tus sueños. \n\n\n Le pedimos que se tome unos minutos y llene la siguiente encuesta para evaluar su experiencia de vinculación con el grupo JMMB.",
        //     type: "null",
        // },
        "infCliDIMSA": {
            title: " ",
            description: "Informaciones Cliente DIMSA",
            type: "object",
            properties: {
                "cliDIMSA": {
                    title: "Cliente creado en DIMSA",
                    type: "boolean",
                },
                "numCliDIMSA":
                {
                    title: "Número de cliente en DIMSA",
                    type: "string"
                },
                "numRNT":
                {
                    title: "Número RNT",
                    type: "string"
                },
                "ctaValoresPosturaPropia":
                {
                    title: "Cuenta Valores Postura Propia",
                    type: "string"
                },
                "ctaValoresSureInv":
                {
                    title: "Cuenta Valores Sure Investor",
                    type: "string"
                },
                "titleDIMSA": {
                    title: " ",
                    description: "Activación del Cliente en DIMSA",
                    type: "null"
                },
                "pantConfDIMSA": {
                    title: "Pantalla Confirmación DIMSA",
                    type: "number"
                },
                "cliActivadoDIMSA": {
                    title: "Cliente Activado en DIMSA",
                    type: "boolean",
                },
                "resultado": {
                    title: "Resultado",
                    type: "number",
                    enum: [2, 1],
                    enumNames: ["Aprobado", "Devuelto"]
                },
            },
            required: ["numCliDIMSA"],
            dependencies: {
                "resultado": {
                    oneOf: [
                        {
                            properties: {
                                "resultado": {
                                    const: 1,
                                },
                                "razonDevolucion": {
                                    title: "Razón de devolución?",
                                    type: "string",
                                    oneOf: [
                                        {
                                            title: "Documento cargado no cumple con requerimientos",
                                            const: "Documento cargado no cumple con requerimientos"
                                        },
                                        {
                                            title: "Documento o información faltante",
                                            const: "Documento o informacion faltante"
                                        },
                                        {
                                            title: "Error en digitación de información",
                                            const: "Error en digitacion de informacion"
                                        },
                                        {
                                            title: "Información incorrecta",
                                            const: "Informacion incorrecta"
                                        },
                                        {
                                            title: "Otros",
                                            const: "Otros"
                                        },
                                    ],
                                },
                                "comentarios": {
                                    title: "Comentarios Internos o Devolución",
                                    type: "string"
                                }
                            }
                        },
                        {
                            properties: {
                                "resultado": {
                                    const: 2,
                                },

                            }
                        }]
                }
            }
        },

    }
}

form.uiSchema = {
    // "enviarCorreoCliente": { "ui:field": "SendEmail" },
    "infCliDIMSA": {
        "pantConfDIMSA": {
            "ui:field": "FileField"
        },

        "comentarios": {
            "ui:widget": "textarea",
            "ui:options": {
                rows: 3
            },
        }
    },
}
form.objectMap = {
    "clients[0].retailClientInvestorProfile.ownAccordSecuritiesAccount": "infCliDIMSA.ctaValoresPosturaPropia",
    "clients[0].retailClientInvestorProfile.clientCreatedDIMSA": "infCliDIMSA.cliDIMSA",
    "clients[0].retailClientInvestorProfile.dimsaClientId": "infCliDIMSA.numCliDIMSA",
    "clients[0].retailClientInvestorProfile.clientActivatedDIMSA": "infCliDIMSA.cliActivadoDIMSA",
    "clients[0].retailClientInvestorProfile.commentsDIMSA": "infCliDIMSA.comentarios",
    "clients[0].retailClientInvestorProfile.resultDIMSA": "infCliDIMSA.resultado",
    "clients[0].retailClientInvestorProfile.sureInvestorAccountDIMSA": "infCliDIMSA.ctaValoresSureInv",
    "clients[0].retailClientInvestorProfile.rntNumber": "infCliDIMSA.numRNT",
    "clients[0].retailClientInvestorProfile.confDIMSAFileId": "infCliDIMSA.pantConfDIMSA",
    "clients[0].retailClientInvestorProfile.sentBackReason": "infCliDIMSA.razonDevolucion",

    "caseInvestmentInfo.commentsDIMSA": "infCliDIMSA.comentarios"
}


export default form;